import { Fragment, ReactNode, useMemo, useState } from "react";

import {
    ExpandedBsMeta,
    getSearchFromMetadata,
    AbfPermissionForCustodian,
    BsMetaUtil,
    TokenBalanceExpanded,
    useGroup,
    checkStakeInputErrors,
    TokenBalance,
    useActiveWallet,
    useBsPrincipalTokens,
    isStakedSol,
    useAllAvailablePrices
} from "@bridgesplit/abf-react";
import {
    Checkbox,
    Column,
    ConditionalLink,
    EmptyPlaceholder,
    FONT_SIZES,
    Icon,
    Image,
    OverlappingImages,
    Row,
    SearchInput,
    SkeletonRounded,
    TableRow,
    Text,
    TokenInput,
    TooltipText,
    repeatElement,
    useAppPalette,
    Tooltip,
    OverlappingImagesProps,
    VerticalScroll,
    MEDIA,
    SxType,
    SelectOption,
    Select
} from "@bridgesplit/ui";
import { AbfCustodian, BsMetadata, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { FormInputType } from "@bridgesplit/react";
import {
    bsMath,
    formatAddress,
    formatUsd,
    greaterThan,
    removeDuplicatesByProperty,
    textContains
} from "@bridgesplit/utils";
import { Divider, useMediaQuery } from "@mui/material";
import { ASSET_SLUG } from "app/constants";
import { AppDialog, useAppDialog } from "app/utils";

import { BAXUS_IDENTIFIER } from "../../constants/external";
import { TokenAmountInput } from "./util";
import { WhirlpoolPositionInfo } from "./whirlpool";

export const getAssetPath = (asset: { assetMint: string } | undefined) => `${ASSET_SLUG}/${asset?.assetMint ?? ""}`;

export function getSearchFromMetadataRow<T>(row: TableRow<ExpandedBsMeta<T>>) {
    return getSearchFromMetadata(row.data.metadata);
}

export function findExternalAssetUrl(asset: BsMetadata | undefined, custodian: AbfCustodian | undefined) {
    if (!asset || !custodian) return undefined;
    if (custodian.groupIdentifier === BAXUS_IDENTIFIER) {
        return `https://app.baxus.co/asset/${asset.assetMint}`;
    }
    return undefined;
}

type SelectAssetsProps = {
    assets: TokenBalanceExpanded[] | undefined;
    selected: Map<string, number | undefined>;
    setSelected: FormInputType<Map<string, number | undefined>>;
    requiredPermission?: AbfPermissionForCustodian;
    overflowAThreshold?: number;
    maxNameLength?: number;
    allowTokenInput?: boolean;
    validateAmounts?: boolean;
    maxText?: string;
};
export function getSelectAssetsErrorMessage({ selected, assets }: Pick<SelectAssetsProps, "assets" | "selected">) {
    const withSelections = assets
        ?.map((asset) => ({ ...asset, selectedAmount: selected.get(asset.key) ?? 0 }))
        .filter((s) => !!s.selectedAmount);

    if (!withSelections?.length) return "Select assets";
    const insufficientBalances = withSelections?.filter(({ amount, selectedAmount }) => selectedAmount > amount);
    if (insufficientBalances?.length) {
        if (insufficientBalances.length === 1)
            return `Insufficient ${BsMetaUtil.getSymbol(insufficientBalances[0].metadata)}`;
        return "Insufficient balances";
    }
    const invalidStakeAmounts = withSelections?.filter(({ stakeAccount, selectedAmount }) =>
        stakeAccount ? !!checkStakeInputErrors(stakeAccount, selectedAmount) : false
    );

    if (invalidStakeAmounts?.length) return "Invalid stake amount";
    return undefined;
}

export function SelectAssets({
    assets: rawAssets,
    selected,
    setSelected,
    requiredPermission,
    overflowAThreshold = 5,
    maxNameLength,
    allowTokenInput,
    maxText,
    validateAmounts
}: SelectAssetsProps) {
    const { permissions } = useGroup();
    const { activeWallet } = useActiveWallet();
    const [search, setSearch] = useState<string>("");

    const { isPrincipalMint } = useBsPrincipalTokens();

    const showFilters = !!rawAssets && rawAssets.length > 3;

    const showSections =
        !activeWallet?.mpcIdentifier && !!rawAssets?.find((a) => !!a.escrowed) && !!rawAssets?.find((a) => !a.escrowed);

    const assets = rawAssets
        ?.filter((a) => {
            if (search === a.key) return true;
            const searchFilter = textContains(getSearchFromMetadata(a.metadata), search);
            if (!searchFilter) return false;
            return true;
        })
        .sort((a, b) => {
            // show escrows first
            if (!a.escrowed && b.escrowed) return 1;
            if (a.escrowed && !b.escrowed) return -1;
            if (isPrincipalMint(a.mint) && !isPrincipalMint(b.mint)) return -1;
            if (!isPrincipalMint(a.mint) && isPrincipalMint(b.mint)) return 1;

            // sort fungible first
            if (a.metadata.decimals !== b.metadata.decimals) return b.metadata.decimals - a.metadata.decimals;
            return BsMetaUtil.getName(a.metadata).localeCompare(BsMetaUtil.getName(b.metadata));
        });

    const selectedValues = Array.from(selected.values()).filter((v) => !!v).length;
    const allSelected = assets ? selectedValues >= assets?.length : false;
    const someSelected = !allSelected && !!selectedValues;

    function updateAsset(key: string, amount: number | undefined) {
        const copy = new Map(selected);
        copy.set(key, amount);

        setSelected(copy);
    }

    function toggleAll() {
        const copy = new Map(selected);

        if (selectedValues) {
            for (const key of copy.keys()) {
                copy.set(key, 0);
            }
        } else {
            assets?.forEach((asset) => {
                copy.set(asset.key, asset.amount);
            });
        }

        setSelected(copy);
    }

    const singleAsset = rawAssets?.length === 1 && rawAssets[0];
    if (
        allowTokenInput &&
        singleAsset &&
        BsMetaUtil.isFungible(singleAsset.metadata) &&
        !isStakedSol(singleAsset.mint)
    ) {
        const symbol = BsMetaUtil.getSymbol(singleAsset.metadata);
        return (
            <TokenInput
                label="Amount"
                maxText={maxText}
                symbol={symbol}
                maxAmount={singleAsset.amount}
                value={selected.get(singleAsset.key)}
                setValue={(val) => updateAsset(singleAsset.key, val)}
            />
        );
    }

    return (
        <>
            {showFilters && (
                <>
                    <Row onClick={toggleAll} sx={{ cursor: "pointer" }} spaceBetween>
                        <Text color="caption">{selectedValues} selected </Text>
                        <Checkbox indeterminate={someSelected} checked={allSelected} onChange={toggleAll} />
                    </Row>
                    <SearchInput
                        placeholder="Search by token or address"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                </>
            )}
            <VerticalScroll maxHeight={overflowAThreshold * 50 + (showSections ? 100 : 0)} spacing={0}>
                {assets?.length === 0 && <Text color="disabled"> No matching assets </Text>}
                {!assets && repeatElement(<SkeletonRounded height="35px" />, 3)}
                {assets?.map(({ metadata, amount, key, stakeAccount, escrowed }, i) => {
                    const insufficientPerms =
                        requiredPermission &&
                        !!permissions &&
                        !permissions.validateForCustodian(metadata.assetOriginator, requiredPermission);
                    const stakeError = stakeAccount ? checkStakeInputErrors(stakeAccount, selected.get(key)) : null;

                    const warningMessage = (() => {
                        if (insufficientPerms) return "Insufficient permissions";
                        if (stakeError) return stakeError;
                        return undefined;
                    })();

                    return (
                        <Fragment key={key}>
                            {showSections && !!assets[i - 1]?.escrowed && !escrowed && (
                                <SelectAssetSectionHeader
                                    header={
                                        activeWallet?.mpcIdentifier ? "MPC Wallet" : formatAddress(activeWallet?.wallet)
                                    }
                                />
                            )}
                            {showSections && i === 0 && <SelectAssetSectionHeader header="Loopscale" />}

                            <TokenAmountInput
                                validateAmounts={validateAmounts}
                                maxNameLength={maxNameLength}
                                warningMessage={warningMessage}
                                disabled={insufficientPerms}
                                metadata={metadata}
                                value={selected.get(key)}
                                maxAmount={amount}
                                setValue={(val) => updateAsset(key, val)}
                            />
                        </Fragment>
                    );
                })}
            </VerticalScroll>
        </>
    );
}
function SelectAssetSectionHeader({ header }: { header: string }) {
    return (
        <>
            <Text color="caption" sx={{ pt: 1 }}>
                {header}
            </Text>
            <Divider />
        </>
    );
}

export function EmptyWalletAssets() {
    const { open } = useAppDialog();

    return (
        <EmptyPlaceholder
            header="You don't own any supported assets"
            description="Send assets to your wallet"
            cta={{ text: "Deposit", onClick: () => open(AppDialog.DepositToWallet, {}) }}
        />
    );
}

export function AssetRows({
    assets,
    isLoading
}: {
    assets:
        | (TokenBalance & { alertTooltip?: string; whirlpoolPosition?: WhirlpoolPositionExpanded | null })[]
        | undefined;
    isLoading?: boolean;
}) {
    const { getPriceByMint } = useAllAvailablePrices(assets?.map((c) => c.mint));

    const { hoverBackground } = useAppPalette();

    const pricesAvailable = assets?.map((c) => getPriceByMint(c.mint)).some((price) => !!price);

    if (assets?.length === 0 && !isLoading) return <Text color="disabled"> All assets have been withdrawn </Text>;

    return (
        <Column sx={{ mx: -1 }}>
            {(!assets || isLoading) && repeatElement(<SkeletonRounded height={40} sx={{ mx: 1, my: 1 }} />)}
            {!isLoading &&
                assets?.map(({ whirlpoolPosition, mint, metadata, amount, alertTooltip }, i) => {
                    return (
                        <ConditionalLink key={i} to={getAssetPath(metadata)}>
                            <Row
                                sx={{ width: "100%", px: 1, py: 1, ":hover": { background: hoverBackground } }}
                                spaceBetween
                            >
                                <Row spacing={1}>
                                    <TokenImage size="lg" metadata={metadata} />
                                    <Column>
                                        <TooltipText icon={false} helpText={alertTooltip}>
                                            {BsMetaUtil.getName(metadata)}
                                            {alertTooltip && (
                                                <Icon
                                                    sx={{ color: ({ palette: { error } }) => error.main }}
                                                    type="warning"
                                                />
                                            )}
                                        </TooltipText>
                                        {pricesAvailable && <PriceComponent price={getPriceByMint(mint)} />}
                                    </Column>
                                </Row>
                                {(() => {
                                    if (whirlpoolPosition) {
                                        return (
                                            <Column alignItems="flex-end">
                                                <WhirlpoolPositionInfo position={whirlpoolPosition} />
                                                <PriceComponent price={whirlpoolPosition.totalPrice} />
                                            </Column>
                                        );
                                    }
                                    if (BsMetaUtil.isFungible(metadata)) {
                                        return (
                                            <Column alignItems="flex-end">
                                                <Text>
                                                    {BsMetaUtil.formatAmount(metadata, amount, {
                                                        hideSymbol: false
                                                    })}
                                                </Text>
                                                {pricesAvailable && (
                                                    <PriceComponent price={bsMath.mul(getPriceByMint(mint), amount)} />
                                                )}
                                            </Column>
                                        );
                                    }

                                    return null;
                                })()}
                            </Row>
                        </ConditionalLink>
                    );
                })}
        </Column>
    );
}

function PriceComponent({ price }: { price: number | undefined }) {
    return (
        <Text color="caption" variant="body2">
            {price ? formatUsd(price) : `No price found`}
        </Text>
    );
}

export function TokensTooltipDisplay({
    tokens: rawMetadata,
    sort = true,
    label,
    skeletonsCount
}: {
    tokens: { metadata: BsMetadata; rowEnd?: ReactNode }[] | undefined;
    label?: ReactNode;
    sort?: boolean;
    skeletonsCount?: number;
}) {
    const [search, setSearch] = useState<string>();
    const isMobile = useMediaQuery(MEDIA.SM.below);

    const metadataSorted = useMemo(
        () =>
            sort
                ? rawMetadata?.sort((a, b) =>
                      BsMetaUtil.getSymbolUnique(a.metadata).localeCompare(BsMetaUtil.getSymbolUnique(b.metadata))
                  )
                : rawMetadata,
        [rawMetadata, sort]
    );

    const metadata = useMemo(
        () =>
            metadataSorted?.filter(({ metadata }) => {
                if (!search) return true;
                return textContains(getSearchFromMetadata(metadata), search);
            }),
        [metadataSorted, search]
    );

    return (
        <Column spacing={1}>
            {!isMobile && greaterThan(rawMetadata?.length, 2) ? (
                <SearchInput
                    sx={{ ".MuiInputBase-root": { px: 0 }, svg: { fontSize: "inherit" } }}
                    hideBorder
                    forceFocus
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />
            ) : (
                <Column />
            )}
            {label}

            <VerticalScroll maxHeight={300}>
                {metadata?.map(({ metadata, rowEnd }) => (
                    <Row spaceBetween key={metadata.assetMint} spacing={3}>
                        <Row sx={{ minWidth: "max-content" }} spacing={1}>
                            <TokenImage size="sm" metadata={metadata} />
                            <Text variant="body2">{BsMetaUtil.getSymbol(metadata)} </Text>
                        </Row>
                        {rowEnd}
                    </Row>
                ))}
                {!metadata && repeatElement(<SkeletonRounded width="100%" height={20} />, skeletonsCount)}
            </VerticalScroll>
        </Column>
    );
}

export function OverlappingMetadataImages({
    metadata,
    disableTooltip,
    sort = true,
    ...props
}: { metadata: BsMetadata[] | undefined; sort?: boolean; disableTooltip?: boolean } & Omit<
    OverlappingImagesProps,
    "images"
>) {
    return (
        <Tooltip
            reverseColors
            title={
                disableTooltip ? (
                    ""
                ) : (
                    <TokensTooltipDisplay sort={sort} tokens={metadata?.map((m) => ({ metadata: m }))} />
                )
            }
        >
            <OverlappingImages
                {...props}
                images={metadata?.map((m) => ({ src: BsMetaUtil.getImage(m), variant: getImageStyle(m) }))}
            />
        </Tooltip>
    );
}

export type TokenSize = "xs" | "sm" | "md" | "lg" | "xl";

type TokenImageProps = {
    metadata: BsMetadata | undefined;
    size?: TokenSize;
    sx?: SxType;
};

export function getTokenImageSize(size: TokenSize) {
    switch (size) {
        case "xl":
            return 40;
        case "lg":
            return 30;
        case "md":
            return 20;
        case "sm":
            return FONT_SIZES.body1;
        case "xs":
            return 10;
        default:
            return 0;
    }
}
/**
 * sm=16, md=20, lg=30, xl=40
 */
export function TokenImage({ metadata, size: propsSize = "md", sx }: TokenImageProps) {
    const size = getTokenImageSize(propsSize);

    return (
        <Image
            skeletonVariant="circular"
            variant={getImageStyle(metadata)}
            size={size + "px"}
            src={BsMetaUtil.getImage(metadata)}
            sx={sx}
        />
    );
}

const ROUNDED_TOKEN_CUSTODIANS = [
    "meTjtQikYwez7tL3LEi4zPPh4u5awMfXbprLZ18S1QS",
    "whUB5g126xgBTHYCr7EAACC7jT8snB7mexTKEWHSWGo"
];

function getImageStyle(metadata: BsMetadata | undefined): "rounded" | "circle" {
    if (metadata && ROUNDED_TOKEN_CUSTODIANS.includes(metadata?.assetOriginator)) return "rounded";

    return "circle";
}

export function TokenSelect({
    tokens,
    value,
    setValue
}: {
    tokens: BsMetadata[] | undefined;
    value: string | undefined;
    setValue: (value: string | undefined) => void;
}) {
    return (
        <Select value={value ?? ""} setValue={setValue} options={getFilterOptions(tokens ?? [])} loading={!tokens} />
    );
}
const getFilterOptions = (tokens: BsMetadata[]): SelectOption<string>[] => {
    const tokenOptions = removeDuplicatesByProperty(tokens, "assetMint").map((metadata) => ({
        value: metadata.assetMint,
        label: (
            <Row spacing={1}>
                <TokenImage metadata={metadata} /> <Text> {BsMetaUtil.getSymbol(metadata)} </Text>
            </Row>
        )
    }));
    return [{ value: "", label: "All" }, ...tokenOptions];
};
