import { ReactNode } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    canLenderSellLoan,
    getLoanName,
    isLoanActive,
    isLoanComplete,
    isLoanDefaulted,
    isLoopLoan,
    isSimpleLoan,
    isWhirlpoolMetadata,
    loanPayFeesRequired,
    LoopPositionExpanded,
    useUserIsPrime
} from "@bridgesplit/abf-react";
import {
    Button,
    ButtonProps,
    Column,
    MenuItemProps,
    MenuItems,
    PopoverWrapper,
    Row,
    SPACING,
    Text,
    usePopover,
    Icon,
    DialogWrapper
} from "@bridgesplit/ui";
import { abbreviateString } from "@bridgesplit/utils";
import {
    ArrowOutwardOutlined,
    ExpandMore,
    FeedOutlined,
    HealthAndSafetyOutlined,
    MoreTimeOutlined,
    PaymentOutlined,
    TransferWithinAStationOutlined,
    CheckOutlined
} from "@mui/icons-material";
import { AppDialog, useAppDialog } from "app/utils";
import { useNavigate } from "react-router-dom";
import { COPY } from "app/constants";
import { NAV_BREAKPOINT } from "app/components/navbar";

import ClaimPayments from "./ClaimPayments";
import { getLoanAssetPath, useUserLoanStatus } from "../../common";
import PayFees from "./payments/PayFees";
import MakeRepayment from "./MakeRepayment";
import CompleteLoan from "./CompleteLoan";
import TopUp from "./TopUp";
import DefaultedLoan from "./DefaultedLoan";
import RefinanceLoan from "./RefinanceLoan";
import SellLoan from "./SellLoan";
import TransferDebt from "./TransferDebt";
import CollateralWithdraw from "./CollateralWithdraw";
import LoopUnwind from "./LoopUnwind";
import IncreasePrincipal from "./IncreasePrincipal";
import { ManageWhirlpool } from "./whirlpool";

type LoanAction = {
    label: string;
    component: ReactNode;
    icon?: ReactNode;
    hide?: boolean;
    hideDialogHeader?: boolean;
    isDrawer?: boolean;
};

type LoanActions = LoanAction[] | null;

export function useLoanActions({
    loanExpanded,
    loopPosition
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    loopPosition?: LoopPositionExpanded;
}): LoanActions {
    const userLoanStatus = useUserLoanStatus(loanExpanded);
    const { isAdmin } = useUserIsPrime();

    // hide on loading
    if (!loanExpanded || !userLoanStatus) return null;

    const {
        canBorrowerClaimAssets,
        canLenderClaimAssets,
        canLenderClaimPayments,
        isBorrower,
        isLender,
        isSyndicateMember
    } = userLoanStatus;

    // hide, since actions are user specific
    if (!isBorrower && !isLender && !isSyndicateMember) return null;

    if (isLoanActive(loanExpanded)) {
        if (isLender) {
            const actions: LoanAction[] = [];

            if (canLenderClaimPayments) {
                actions.push({ component: <ClaimPayments loanExpanded={loanExpanded} />, label: "Claim repayments" });
            }
            if (canLenderSellLoan(loanExpanded)) {
                actions.push({ component: <SellLoan loanExpanded={loanExpanded} />, label: "Early withdraw" });
            }
            return actions;
        }

        if (isBorrower) {
            const isWhirlpool = isWhirlpoolMetadata(loanExpanded.collateral?.[0]?.metadata);
            const isLoop = isLoopLoan(loanExpanded) && !!loopPosition;
            const repay: LoanAction = {
                icon: <PaymentOutlined />,
                label: isLoop ? `Repay ${BsMetaUtil.getSymbol(loanExpanded.principalMetadata)}` : "Repay",
                component: <MakeRepayment loanExpanded={loanExpanded} />
            };
            if (isSimpleLoan(loanExpanded)) {
                const actions: LoanAction[] = [
                    {
                        label: COPY.UNWIND_TERM,
                        icon: <CheckOutlined />,
                        component: <LoopUnwind loanExpanded={loanExpanded} loopPosition={loopPosition} />,
                        hide: !isLoop,
                        hideDialogHeader: true
                    },
                    repay,
                    {
                        label: "Manage position",
                        icon: <HealthAndSafetyOutlined />,
                        component: <ManageWhirlpool loanExpanded={loanExpanded} />,
                        hide: !isWhirlpool,
                        hideDialogHeader: true,
                        isDrawer: true
                    },
                    {
                        label: "Refinance",
                        icon: <MoreTimeOutlined />,
                        component: <RefinanceLoan loanExpanded={loanExpanded} />,
                        hide: isLoop
                    },
                    {
                        label: "Borrow more",
                        icon: <Icon type="add" />,
                        component: <IncreasePrincipal loanExpanded={loanExpanded} />,
                        hide: isLoop
                    },
                    {
                        label: "Top-up collateral",
                        icon: <HealthAndSafetyOutlined />,
                        component: <TopUp loanExpanded={loanExpanded} />,
                        hide: isWhirlpool
                    },
                    {
                        label: "Withdraw collateral",
                        icon: <ArrowOutwardOutlined />,
                        component: <CollateralWithdraw loanExpanded={loanExpanded} />,
                        hide: isLoop || isWhirlpool
                    }
                ];

                if (isAdmin) {
                    actions.push({
                        label: "Transfer debt",
                        icon: <TransferWithinAStationOutlined />,
                        component: <TransferDebt loanExpanded={loanExpanded} />,
                        hide: isLoop
                    });
                }

                return actions.filter((a) => !a.hide);
            }
            if (loanPayFeesRequired(loanExpanded)) {
                return [repay, { label: "Pay fees", component: <PayFees loanExpanded={loanExpanded} /> }];
            }
            return [repay];
        }
        return null;
    }

    if (isLoanDefaulted(loanExpanded)) {
        if (canLenderClaimAssets) {
            return [
                {
                    label: "Claim collateral",
                    component: <DefaultedLoan loanExpanded={loanExpanded} view="lender" />
                }
            ];
        }
        return null;
    }

    if (isLoanComplete(loanExpanded)) {
        if (canBorrowerClaimAssets) {
            return [
                {
                    label: "Claim collateral",
                    component: <CompleteLoan loanExpanded={loanExpanded} view="borrower" />
                }
            ];
        }
        if (canLenderClaimPayments) {
            return [
                {
                    label: "Claim payments",
                    component: <CompleteLoan loanExpanded={loanExpanded} view="lender" />
                }
            ];
        }
    }

    return null;
}

export function LoanActions({
    loanExpanded,
    loopPosition,
    linkToDetail,
    isCondensed,
    maxItems = 2
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    loopPosition?: LoopPositionExpanded | undefined;
    linkToDetail: boolean;
    isCondensed: boolean;
    maxItems?: number;
}) {
    const maxButtons = isCondensed ? 1 : maxItems;
    const fullWidth = isCondensed ? true : false;
    const { open } = useAppDialog();
    const ctas = useLoanActions({ loanExpanded, loopPosition });

    if (!ctas?.length && !linkToDetail) return null;

    return (
        <Row spacing={1}>
            {ctas?.slice(0, maxButtons).map((cta, i) => (
                <Button
                    fullWidth={fullWidth}
                    sx={{ minWidth: "max-content" }}
                    key={i}
                    variant={(() => {
                        if (isCondensed) return "contained";
                        if (i === 0) return "contained";
                        return "outlined";
                    })()}
                    disabled={!loanExpanded}
                    onClick={() => {
                        if (!loanExpanded) return;
                        open(AppDialog.LoanActions, {
                            loan: loanExpanded,
                            action: cta,
                            hideHeader: cta.hideDialogHeader,
                            isDrawer: cta.isDrawer
                        });
                    }}
                >
                    {cta.label}
                </Button>
            ))}
            <LoanActionButtons
                buttonProps={{
                    fullWidth,
                    variant: "outlined",
                    sx: { minWidth: "max-content" }
                }}
                linkToDetail={linkToDetail}
                loan={loanExpanded}
                actions={ctas?.slice(maxButtons) ?? []}
            />
        </Row>
    );
}

export function LoanActionDialog() {
    const { getData } = useAppDialog();
    const data = getData<AppDialog.LoanActions>();

    const name = abbreviateString(getLoanName(data?.loan), 40);

    return (
        <DialogWrapper drawerQuery={NAV_BREAKPOINT} isDrawer={!!data?.isDrawer}>
            {(() => {
                if (data?.hideHeader) return null;
                if (data?.includeLoanHeader) {
                    return (
                        <Column spacing={0.5}>
                            <Text fontWeight="normal" variant="h3">
                                {name}
                            </Text>
                            <Text color="caption">{data?.action?.label}</Text>
                        </Column>
                    );
                }
                return <Text variant="h3">{data?.action?.label}</Text>;
            })()}

            {data?.action?.component}
        </DialogWrapper>
    );
}

export function LoanActionButtons({
    loan,
    actions,
    linkToDetail,
    buttonProps,
    maxItems
}: {
    loan: AbfLoanExpanded | undefined;
    actions: LoanAction[];
    linkToDetail: boolean;
    buttonProps?: Omit<ButtonProps, "onClick" | "disabled">;
    maxItems?: number;
}) {
    const { open } = useAppDialog();
    const navigate = useNavigate();

    const { open: openPopover, popoverProps, close } = usePopover();

    const allMenuItems: MenuItemProps[] = actions.map((a) => ({
        text: a.label,
        icon: a.icon,
        onClick: () => {
            if (!loan) return;
            open(AppDialog.LoanActions, {
                loan,
                includeLoanHeader: true,
                action: a,
                hideHeader: a.hideDialogHeader,
                isDrawer: a.isDrawer
            });
        }
    }));
    if (linkToDetail) {
        allMenuItems.push({
            text: "More details",
            icon: <FeedOutlined />,
            onClick: () => {
                if (!loan) return;
                navigate(getLoanAssetPath(loan.loan));
            }
        });
    }
    const menuItems = maxItems ? allMenuItems.slice(0, maxItems) : allMenuItems;
    if (menuItems.length === 1) {
        return (
            <Button disabled={!loan} {...buttonProps} onClick={menuItems[0].onClick}>
                {menuItems[0].text}
            </Button>
        );
    }

    if (menuItems.length === 0) return null;

    return (
        <>
            <Button disabled={!loan} {...buttonProps} onClick={openPopover}>
                More <ExpandMore />
            </Button>
            <PopoverWrapper
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ horizontal: "left", vertical: -SPACING }}
                {...popoverProps}
            >
                <MenuItems close={close} items={menuItems} />
            </PopoverWrapper>
        </>
    );
}
