import { useCallback, useMemo } from "react";

import { Column, FormLabel, Row, Slider, Text } from "@bridgesplit/ui";
import debounce from "lodash.debounce";
import { COPY } from "app/constants";
import { formatLeverage } from "@bridgesplit/abf-react";

import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";

export default function WindMultiplier() {
    const {
        form: { multiplier, multiplierWithoutDebounce },
        setForm
    } = useWindContext();
    const { loopExpanded } = useLoopContext();

    const handleChange = useCallback(
        (multiplier: number) =>
            setForm((prev) => ({
                ...prev,
                multiplier
            })),
        [setForm]
    );

    const setValueDebounced = useMemo(() => debounce(handleChange, 300), [handleChange]);

    const minValue = 1;
    const maxValue = loopExpanded?.loopVault.maxLeverage ?? 1;
    return (
        <Column>
            <Row spaceBetween>
                <FormLabel label={COPY.LEVERAGE_TERM} />
                <Text color={multiplier && multiplier > minValue ? "body" : "disabled"} variant="body2">
                    {formatLeverage(multiplier ?? minValue)}
                </Text>
            </Row>
            <Slider
                marks={Array.from({ length: maxValue + 1 }, (_, i) => ({
                    value: i,
                    label: formatLeverage(i)
                }))}
                step={0.1}
                min={minValue}
                max={maxValue}
                value={multiplierWithoutDebounce}
                setValue={(multiplier) => {
                    setForm((prev) => ({
                        ...prev,
                        multiplierWithoutDebounce: multiplier
                    }));
                    setValueDebounced(multiplier);
                }}
            />
        </Column>
    );
}
