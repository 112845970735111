import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    canLenderReclaimCollateral,
    getLoanDefaultedDate,
    isLoanLiquidated,
    useLoanWithdrawTransaction,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { Button, Column, StatColumn, Text } from "@bridgesplit/ui";
import { formatDate, formatNum } from "@bridgesplit/utils";
import { SUPPORT_EMAIL } from "app/constants";

import { ActionProps } from "./types";
import { AppButton } from "../../common";
import { useDefaultedLoanDescription, useLoanCompleteData, useLoanLockboxAssets, useWithdrawLoanToken } from "../util";

export default function DefaultedLoan({ loanExpanded, view }: ActionProps) {
    const { collateralCount, claimedAssetsCount, claimNeeded } = useLoanLockboxAssets(loanExpanded);
    const { isSyndicated } = useLoanCompleteData(loanExpanded);

    const wasLiquidated = isLoanLiquidated(loanExpanded);

    const canLenderReclaim = canLenderReclaimCollateral(loanExpanded);

    const description = useDefaultedLoanDescription(loanExpanded);
    return (
        <>
            {description && (
                <Text variant="body2" color="error">
                    {description}
                </Text>
            )}
            <StatColumn
                stats={[
                    {
                        caption: "Defaulted",
                        value: formatDate(getLoanDefaultedDate(loanExpanded), "relative"),
                        hide: wasLiquidated
                    },
                    {
                        caption: "Collateral",
                        value:
                            collateralCount === 1
                                ? `${claimedAssetsCount ? "Claimed" : "Unclaimed"}`
                                : `${formatNum(claimedAssetsCount)} of ${formatNum(collateralCount)} claimed`,
                        hide: canLenderReclaim
                    }
                ]}
            />
            {view === "lender" &&
                claimNeeded &&
                loanExpanded &&
                canLenderReclaim &&
                (isSyndicated ? (
                    <LenderClaimSyndicatedCollateral />
                ) : (
                    <LenderClaimCollateral loanExpanded={loanExpanded} />
                ))}
        </>
    );
}

function LenderClaimSyndicatedCollateral() {
    return (
        <Column spacing={0.5}>
            <Text variant="body2" color="caption">
                Contact Loopscale support for further instructions
            </Text>
            <Button variant="contained" onClick={() => window.open(`mailto:${SUPPORT_EMAIL}`)}>
                Contact Loopscale Support
            </Button>
        </Column>
    );
}

function LenderClaimCollateral({ loanExpanded }: { loanExpanded: AbfLoanExpanded }) {
    const loanWithdraw = useLoanWithdrawTransaction();

    const withdraw = useWithdrawLoanToken({ loanExpanded, withdrawTransaction: loanWithdraw, side: "credit" });

    return (
        <AppButton
            isTransaction
            authentication={{
                permission: AbfPermissionForCustodian.WithdrawCreditNote,
                custodians: getLoanCustodianIdentifiers(loanExpanded)
            }}
            fullWidth
            asyncCta={{ onClickWithResult: withdraw }}
            variant="contained"
        >
            Claim collateral
        </AppButton>
    );
}
