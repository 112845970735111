import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { PaginatedData } from "@bridgesplit/react";
import { AbfLedgerAccountResponse, PaymentEvent } from "@bridgesplit/abf-sdk";

import { LedgerAccountFilter, PaymentEventsFilter } from "../types";
import { GET_LEDGER_ACCOUNTS_ROUTE, LEDGER_ACCOUNTS_SYNC_ROUTE, PAYMENT_EVENTS_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const LEDGERS_TAG = "LedgerAccounts";
const LEDGERS_SYNC_TAG = "LedgerAccountsSync";

export const abfLedgerAccountsApi = createApi({
    reducerPath: "abfLedgerAccountsApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [LEDGERS_TAG, LEDGERS_SYNC_TAG],
    endpoints: (builder) => ({
        ledgerAccounts: builder.query<PaginatedData<AbfLedgerAccountResponse>, LedgerAccountFilter>({
            providesTags: [LEDGERS_TAG],
            query(body) {
                return {
                    url: GET_LEDGER_ACCOUNTS_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        ledgerAccountsSync: builder.query<boolean, string>({
            providesTags: [LEDGERS_SYNC_TAG],
            query(orderAddress) {
                return {
                    url: `${LEDGER_ACCOUNTS_SYNC_ROUTE}/${orderAddress}`,
                    method: "GET"
                };
            }
        }),
        paymentEvents: builder.query<PaginatedData<PaymentEvent[]>, PaymentEventsFilter>({
            providesTags: [LEDGERS_TAG],
            query(body) {
                return {
                    body,
                    url: PAYMENT_EVENTS_ROUTE,
                    method: "POST"
                };
            }
        })
    })
});

export const { useLedgerAccountsQuery, useLedgerAccountsSyncQuery, usePaymentEventsQuery } = abfLedgerAccountsApi;

export const useAbfLedgerAccountsApi = () => {
    const api = abfLedgerAccountsApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchLedgerAccounts] = api.ledgerAccounts.useLazyQuery();
    const [checkLedgerAccountsSync] = api.ledgerAccountsSync.useLazyQuery();

    return {
        resetLedgerAccounts: () => dispatch(abfLedgerAccountsApi.util.invalidateTags([LEDGERS_TAG])),
        resetLedgerAccountsSync: () => dispatch(abfLedgerAccountsApi.util.invalidateTags([LEDGERS_SYNC_TAG])),
        fetchLedgerAccounts,
        checkLedgerAccountsSync
    };
};
