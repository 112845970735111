import { formatAddress } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";
import { ContentCopyOutlined, LinkOffOutlined } from "@mui/icons-material";
import { useUserPublicKey } from "@bridgesplit/react";

import { BORDER_RADIUS, useAppPalette } from "../../theme";
import { Row } from "../containers";
import { Icon } from "../icons";
import { Text, TextVariant, TooltipText } from "../typography";
import { LabelWrapper } from "../input";
import { useCopyAddress } from "../../util";

export function WalletDisplay({ address }: { address: string | undefined }) {
    const { disconnect, publicKey } = useWallet();
    const { border } = useAppPalette();

    const copy = useCopyAddress();

    return (
        <Row spaceBetween sx={{ border, borderRadius: BORDER_RADIUS, p: 1 }}>
            <Row spacing={1}>
                <TooltipText icon={false} helpText="Connected Wallet" variant="h4" color="disabled">
                    <Icon type="wallet" />
                </TooltipText>
                <Text> {formatAddress(address)}</Text>
            </Row>
            <Row spacing={1}>
                <TooltipText
                    helpText="Copy address"
                    icon={false}
                    isLink
                    onClick={() => copy(address)}
                    variant="caption"
                    color="caption"
                >
                    <ContentCopyOutlined />
                </TooltipText>
                {publicKey?.toString() === address && (
                    <TooltipText icon={false} helpText="Disconnect" color="caption" onClick={disconnect} isLink>
                        <LinkOffOutlined />
                    </TooltipText>
                )}
            </Row>
        </Row>
    );
}

export function WalletDisplaySmall({ address, variant }: { address: string | undefined; variant?: TextVariant }) {
    return (
        <Text variant={variant}>
            <Icon type="wallet" /> {formatAddress(address)}
        </Text>
    );
}

export function ChangeWalletText() {
    const { disconnect } = useWallet();
    const pubkey = useUserPublicKey();

    if (!pubkey) return null;
    return (
        <Text variant="body2" color="caption" isLink onClick={disconnect}>
            <Icon type="back" /> Disconnect from {formatAddress(pubkey)}
        </Text>
    );
}

export function ConnectedWalletDisplay({ label }: { label: string }) {
    const { disconnect } = useWallet();
    const pubkey = useUserPublicKey();
    const { border } = useAppPalette();
    return (
        <LabelWrapper label={label}>
            <Row spaceBetween sx={{ border, borderRadius: BORDER_RADIUS, p: 1 }}>
                <Row spacing={1}>
                    <Text>
                        <Icon type="wallet" /> {formatAddress(pubkey)}
                    </Text>
                </Row>
                <Text color="disabled" onClick={disconnect} isLink>
                    <LinkOffOutlined />
                </Text>
            </Row>
        </LabelWrapper>
    );
}
