import {
    BORDER_RADIUS,
    Column,
    HeaderCardWithAction,
    IconWithBackground,
    Row,
    Text,
    TextButton,
    useAppPalette,
    useCopyAddress
} from "@bridgesplit/ui";
import { ContentCopyOutlined, VpnKeyOutlined } from "@mui/icons-material";
import { AbfRole } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";
import { CUSTODIAN_CODE_INVITE_SLUG, ROLE_METADATA } from "app/constants";

import { useCustodianCodesForAdmin } from "../util";

export default function CustodianGenerateCodes() {
    const { open } = useAppDialog();
    const codes = useCustodianCodesForAdmin();

    return (
        <HeaderCardWithAction
            actionElement={
                <TextButton onClick={() => open(AppDialog.CreateCustodianInvite, undefined)}>Generate Code</TextButton>
            }
            spacing={2}
            header="Invite Codes"
        >
            <Text color="caption">
                Generate unlimited-use codes that grant lender or borrower permissions to your users. To deactivate a
                code, please contact the Loopscale team
            </Text>

            <Column spacing={1}>
                <Text variant="h4">Your codes</Text>
                {codes?.map((props) => (
                    <CodeDisplay key={props.code} {...props} />
                ))}
                {codes && codes.length === 0 && <Text color="disabled">You haven't made any codes</Text>}
            </Column>
        </HeaderCardWithAction>
    );
}

function CodeDisplay({ code, roles }: { code: string; roles: AbfRole[] }) {
    const { border } = useAppPalette();
    const codeWithLink = `${window.location.origin}${CUSTODIAN_CODE_INVITE_SLUG}/${code}`;

    const copy = useCopyAddress();

    return (
        <Row
            onClick={() => copy(codeWithLink, { customText: code })}
            spaceBetween
            sx={{ borderRadius: BORDER_RADIUS, border, p: 1, cursor: "pointer" }}
            key={code}
        >
            <Row spacing={2}>
                <IconWithBackground variant="h4" size={40}>
                    <VpnKeyOutlined />
                </IconWithBackground>

                <Column spacing={0.5}>
                    <Row>
                        <Text color="caption">
                            {window.location.host}
                            {CUSTODIAN_CODE_INVITE_SLUG}/
                        </Text>
                        <Text variant="h4">{code}</Text>
                    </Row>

                    <Row spacing={1}>
                        {roles.map((r) => (
                            <Text variant="body2" key={r}>
                                {ROLE_METADATA[r].icon} {ROLE_METADATA[r].name} Permissions
                            </Text>
                        ))}
                    </Row>
                </Column>
            </Row>
            <Text variant="h3" color="caption">
                <ContentCopyOutlined />
            </Text>
        </Row>
    );
}
