import { useMemo, useState } from "react";

import { Result, generateNonce, parseErrorMessage } from "@bridgesplit/utils";

import { useAlert } from "../reducers";

export function useHandleRuntimeError() {
    const { alert } = useAlert();

    function sendErrorMessage(message: string) {
        alert(message, "error");
    }

    return (e: any) => sendErrorMessage(parseErrorMessage(e));
}

export type AsyncHandlerOptions = {
    customSnackbarId?: string;
    alertOnError?: boolean;
    onSuccessMessage?: string | { message: string; description: string };
    loadingMessage?: string | { message: string; description: string };
    onSuccessColor?: "success" | "info";
    onSuccess?: () => void;
    onFail?: () => void;
};

export function useAsyncResultHandler<T>() {
    const [data, setData] = useState<T | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // to allow for undefined results
    const [success, setSuccess] = useState<boolean>(false);

    const { alert } = useAlert();

    function reset() {
        setData(undefined);
        setErrorMessage(undefined);
        setIsLoading(false);
        setSuccess(false);
    }

    async function resultHandler(asyncFn: () => Promise<Result<T>>, options?: AsyncHandlerOptions): Promise<Result<T>> {
        setIsLoading(true);
        setErrorMessage(undefined);
        setSuccess(false);

        const customSnackbarId = options?.customSnackbarId ?? generateNonce();

        if (options?.loadingMessage) {
            if (typeof options.loadingMessage === "string") {
                alert(options.loadingMessage, "spinner", { customSnackbarId });
            } else {
                alert(options.loadingMessage.message, "spinner", {
                    description: options.loadingMessage.description,
                    customSnackbarId
                });
            }
        }

        const result = await asyncFn();

        if (result.isOk()) {
            setData(result.unwrap());
            setIsLoading(false);
            setSuccess(true);
            setErrorMessage(undefined);
            options?.onSuccess?.();
            if (options?.onSuccessMessage) {
                if (typeof options.onSuccessMessage === "string") {
                    alert(options.onSuccessMessage, options.onSuccessColor ?? "success", { customSnackbarId });
                } else {
                    alert(options.onSuccessMessage.message, options.onSuccessColor ?? "success", {
                        description: options.onSuccessMessage.description,
                        customSnackbarId
                    });
                }
            }
            return result;
        }

        const newErrorMessage = result.isErr() ? result.unwrapErrMessage() : undefined;

        options?.onFail?.();

        if (options?.alertOnError !== false && newErrorMessage) {
            alert(newErrorMessage, "error", { customSnackbarId });
        }

        setErrorMessage(newErrorMessage);
        setData(undefined);
        setIsLoading(false);

        return result;
    }

    async function handler(asyncFn: () => Promise<T>, options?: AsyncHandlerOptions): Promise<Result<T>> {
        async function wrappedWithResult(): Promise<Result<T>> {
            try {
                const res = await asyncFn();
                return Result.ok(res);
            } catch (error) {
                return Result.err(error);
            }
        }

        return await resultHandler(wrappedWithResult, options);
    }

    function syncHandler(syncFn: () => Result<T>, options?: AsyncHandlerOptions): Result<T> {
        setErrorMessage(undefined);

        const result = syncFn();
        if (result.isOk()) {
            setData(result.unwrap());

            if (options?.onSuccessMessage) {
                if (typeof options.onSuccessMessage === "string") {
                    alert(options.onSuccessMessage, options.onSuccessColor ?? "success");
                } else {
                    alert(options.onSuccessMessage.message, options.onSuccessColor ?? "success", {
                        description: options.onSuccessMessage.description
                    });
                }
            }

            return result;
        }

        const newErrorMessage = result.isErr() ? result.unwrapErrMessage() : undefined;

        if (options?.alertOnError && newErrorMessage) {
            alert(newErrorMessage, "error");
        }

        setErrorMessage(newErrorMessage);
        setData(undefined);

        return result;
    }

    const skipHook = useMemo(
        () => !!errorMessage || !!data || isLoading || success,
        [data, errorMessage, isLoading, success]
    );
    const status = !errorMessage && success;

    return {
        handler,
        resultHandler,
        syncHandler,
        isLoading,
        skipHook,
        errorMessage,
        data,
        reset,
        status
    };
}
