import { useState } from "react";

import { AbfPermission, TokenBalanceExpanded, useUserEscrowAssets } from "@bridgesplit/abf-react";
import { StatColumn } from "@bridgesplit/ui";
import { MISSING_PARAM_ERROR, Result } from "@bridgesplit/utils";

import { useEscrowWithdraw } from "../util";
import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { useSelectAddressController } from "../common";

export default function WithdrawEscrows({ mints }: { mints: string[] | undefined }) {
    const selectedMints = new Set(mints);
    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());
    const { AddressComponent, address, stats, errorMessage: addressErrorMessage } = useSelectAddressController();

    const deposits = useUserEscrowAssets({ includeStakedSol: true, includeLpPositions: true })?.filter((m) =>
        mints?.length ? selectedMints.has(m.mint) : true
    );

    const selectedDeposits = deposits
        ?.map((d): TokenBalanceExpanded & { selectedAmount: number } => ({
            ...d,
            selectedAmount: selected.get(d.key) || 0
        }))
        .filter((d) => !!d.selectedAmount);

    const withdraw = useEscrowWithdraw();

    async function onClickWithResult() {
        if (!selectedDeposits || !address) return Result.errFromMessage(MISSING_PARAM_ERROR);
        return withdraw({
            wallet: address,
            withdraws: selectedDeposits.map((d) => ({
                uiAmount: d.selectedAmount,
                metadata: d.metadata,
                stakeAccount: d.stakeAccount?.stakeAccount
            }))
        });
    }

    const errorMessage = getSelectAssetsErrorMessage({ selected, assets: deposits }) ?? addressErrorMessage;

    return (
        <>
            <AddressComponent />

            <SelectAssets
                allowTokenInput={mints?.length === 1}
                selected={selected}
                setSelected={setSelected}
                assets={deposits}
            />
            <StatColumn stats={stats} />

            <AppButton
                isTransaction
                authentication={{ permission: AbfPermission.WithdrawTokens }}
                fullWidth
                disabled={!!errorMessage}
                asyncCta={{ onClickWithResult }}
                variant="contained"
            >
                {errorMessage ?? "Withdraw"}
            </AppButton>
        </>
    );
}
