import { ReactNode, useEffect, useMemo, useState } from "react";

import {
    AbfRole,
    AbfGroupType,
    useGroup,
    useGroupByAdminEmailsQuery,
    useUsersByEmailsQuery,
    useUpdateRolesTransaction,
    convertRolesToRoleNumbers,
    useGenerateCustodianSignupCodesMutation,
    useUserProfile
} from "@bridgesplit/abf-react";
import {
    Checkbox,
    Column,
    DialogWrapper,
    FormDebouncedStringInput,
    RadioOption,
    Row,
    Text,
    TooltipText,
    emailFormAdornments,
    useAppPalette
} from "@bridgesplit/ui";
import {
    EMAIL_REGEX,
    Result,
    LOADING_ERROR,
    combineCollections,
    removeDuplicates,
    getReadableErrorMessage
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { CircularProgress } from "@mui/material";
import { useAsyncResultHandler } from "@bridgesplit/react";
import { ROLE_METADATA } from "app/constants";
import { AppDialog, useAppDialog } from "app/utils";

import { MemberPermissionDisplay, getMemberPermissionName } from "../common";
import { MemberPermission } from "../types";
import { editMemberPermission, useCustodianMembers, useOrganizationMembers, usePutPermissionChange } from "../util";
import { AppButton, DialogHeader } from "../../common";
import { useTransactionSender } from "../../transactions";

export function AddGroupDialog() {
    return (
        <DialogWrapper>
            <AddCustodianGroup />
        </DialogWrapper>
    );
}

export function AddMemberDialog() {
    return (
        <DialogWrapper>
            <AddOrganizationMember />
        </DialogWrapper>
    );
}

export function ModifyAdminRolesDialog() {
    const { getData } = useAppDialog();
    const member = getData<AppDialog.ModifyAdminRoles>();
    const name = getMemberPermissionName(member);

    return (
        <DialogWrapper>
            <DialogHeader
                header="Make admin"
                description={`Promote ${name} to an ${ROLE_METADATA.Admin.name} or ${ROLE_METADATA.SuperAdmin.name}`}
            />
            <MemberPermissionDisplay member={member} />
            <ModifyMember member={member} allowedRoles={[AbfRole.Admin, AbfRole.SuperAdmin]} />
        </DialogWrapper>
    );
}

export function ModifyLoanRolesDialog() {
    const { getData } = useAppDialog();
    const member = getData<AppDialog.ModifyLoanRoles>();
    const allowedRoles = [AbfRole.Lender, AbfRole.Borrower];
    const name = getMemberPermissionName(member);

    if (member?.granter === "organization") {
        allowedRoles.push(AbfRole.EscrowManager);
    }

    return (
        <DialogWrapper>
            <DialogHeader header="Modify roles" description={`Add or remove roles for ${name}`} />
            <MemberPermissionDisplay member={member} />
            <ModifyMember member={member} allowedRoles={allowedRoles} />
        </DialogWrapper>
    );
}

export function ModifyMember({
    allowedRoles,
    member
}: {
    allowedRoles: AbfRole[];
    member: MemberPermission | undefined;
}) {
    const [rolesInitialized, setRolesInitialized] = useState<boolean>(false);

    const [roles, setRoles] = useState<Set<AbfRole>>(new Set());

    useEffect(() => {
        if (rolesInitialized || !member) return;
        setRoles(member.roles);
        setRolesInitialized(true);
    }, [member, rolesInitialized]);

    const rolesDiff = allowedRoles.filter((r) => member?.roles.has(r) !== roles.has(r));

    return (
        <MemberModifyWrapper member={member} roles={Array.from(roles)} disabled={!rolesDiff.length}>
            <Column spacing={1}>
                {allowedRoles.map((r) => {
                    const meta = ROLE_METADATA[r];
                    return (
                        <Row spaceBetween key={r}>
                            <TooltipText helpText={meta.description}>
                                {meta.icon} {meta.name}
                            </TooltipText>
                            <Checkbox
                                onChange={(_, checked) => {
                                    const newRoles = new Set(roles);
                                    if (checked) setRoles(newRoles.add(r));
                                    else {
                                        newRoles.delete(r);
                                        setRoles(newRoles);
                                    }
                                }}
                                checked={roles.has(r)}
                            />
                        </Row>
                    );
                })}
            </Column>
        </MemberModifyWrapper>
    );
}

export function DeleteMemberDialog() {
    const { getData } = useAppDialog();
    const member = getData<AppDialog.DeleteMember>();
    const { activeGroup } = useGroup();

    const name = getMemberPermissionName(member);

    const nonMemberRoles = member
        ? Array.from(member?.roles).filter((r) => (member.granter === "custodian" ? true : r !== AbfRole.Member))
        : [];

    return (
        <DialogWrapper>
            <DialogHeader
                header={`Remove ${member?.type === "group" ? "Organization" : "User"}`}
                description={`${name} will no longer have access to ${activeGroup?.groupName}`}
            />
            <MemberPermissionDisplay member={member} />
            <MemberModifyWrapper member={member} roles={[]}>
                {!!nonMemberRoles.length && (
                    <Text color="error">
                        {name} will lose {nonMemberRoles.length} role{nonMemberRoles.length === 1 ? "" : "s"}
                    </Text>
                )}
            </MemberModifyWrapper>
        </DialogWrapper>
    );
}

/**
 * Used to "verify transactions"
 * Displays any diffs between their web2/web3 perms and sends txns to fix
 */
export function VerifyMemberDialog() {
    const { getData } = useAppDialog();
    const member = getData<AppDialog.VerifyMember>();

    const name = getMemberPermissionName(member);
    const updateRoles = useUpdateRolesTransaction("roleUpdate");
    const send = useTransactionSender();
    const relevantRoles = removeDuplicates(
        combineCollections([member?.rolesDiff?.addRoles, member?.rolesDiff?.removeRoles, member?.roles])
    ).filter((r) => r !== AbfRole.Member);

    return (
        <DialogWrapper>
            <DialogHeader
                header={"Finalize Roles"}
                description={`Please finalize the roles that you previously set for ${name}. This will allow them to take actions with their roles`}
            />
            <MemberPermissionDisplay member={member} />
            <Column spacing={1}>
                <Text color="caption"> Roles</Text>
                {relevantRoles.map((r) => {
                    const toAdd = member?.rolesDiff?.addRoles.has(r);
                    const toRemove = member?.rolesDiff?.removeRoles.has(r);
                    return (
                        <Row spaceBetween spacing={1} key={r}>
                            <Text>{ROLE_METADATA[r].name}</Text>
                            {toAdd && <Text color="success"> New </Text>}
                            {toRemove && <Text color="error"> Remove </Text>}
                            {!toAdd && !toRemove && <Text color="disabled"> No change </Text>}
                        </Row>
                    );
                })}
            </Column>
            <AppButton
                isTransaction
                asyncCta={{ onClickWithResult: () => send(updateRoles, { user: member?.wallet?.wallet }) }}
            >
                Finalize roles
            </AppButton>
        </DialogWrapper>
    );
}

function useAddController() {
    const [email, setEmail] = useState<string>();

    function reset() {
        setEmail("");
    }

    return { email, setEmail, reset };
}

type AddState = ReturnType<typeof useAddController>;

function AddOrganizationMember() {
    const members = useOrganizationMembers();
    const state = useAddController();

    const emailSearch = state.email && EMAIL_REGEX.test(state.email) ? state.email.toLowerCase() : undefined;

    const { data: users, isLoading } = useUsersByEmailsQuery(emailSearch ? [emailSearch] : skipToken, {
        skip: !emailSearch
    });
    const user = users && emailSearch ? users[emailSearch] : undefined;

    const foundUser: MemberPermission | undefined = useMemo(() => {
        if (!state.email || !emailSearch) return undefined;
        const commonNewFields = { roles: new Set<AbfRole>([AbfRole.Member]) };
        if (user) {
            return {
                ...commonNewFields,
                key: user.identifier,
                type: "user",
                user,
                granter: "organization",
                isSignedInUser: false
            };
        } else {
            return {
                ...commonNewFields,
                key: state.email,
                type: "invite",
                email: state.email,
                granter: "organization"
            };
        }
    }, [emailSearch, state.email, user]);

    return (
        <AddEmailsInput
            userQueryLoading={isLoading}
            existingMembers={members}
            header="Add members"
            caption="Invite new users to join your organization"
            state={state}
            foundMembers={foundUser ? [foundUser] : foundUser}
            inputPlaceholder="Your teammate's email"
            buttonCtaText="Add Members"
        />
    );
}

function AddCustodianGroup() {
    const members = useCustodianMembers();
    const state = useAddController();

    const emailSearch = state.email && EMAIL_REGEX.test(state.email) ? state.email : undefined;

    const { data: adminEmailMap, isLoading } = useGroupByAdminEmailsQuery(emailSearch ? [emailSearch] : skipToken, {
        skip: !emailSearch
    });
    const matchingGroups = adminEmailMap && emailSearch ? adminEmailMap[emailSearch] : undefined;

    const foundGroups: MemberPermission[] | undefined = useMemo(() => {
        if (!state.email || !emailSearch || isLoading) return undefined;
        const commonNewFields = { roles: new Set<AbfRole>([AbfRole.Member]) };

        if (!matchingGroups?.length)
            return [
                {
                    ...commonNewFields,
                    granter: "custodian",
                    email: state.email,
                    key: state.email,
                    type: "invite"
                }
            ];

        return matchingGroups?.map((group) => ({
            ...commonNewFields,
            key: group.groupIdentifier,
            type: "group",
            group,
            granter: "custodian"
        }));
    }, [emailSearch, isLoading, matchingGroups, state.email]);

    return (
        <AddEmailsInput
            userQueryLoading={isLoading}
            header="Add organization"
            caption="Invite organizations by the email of their owner"
            state={state}
            existingMembers={members}
            foundMembers={foundGroups}
            inputPlaceholder="Email of organization owner"
            buttonCtaText="Add Organization"
        />
    );
}

function AddEmailsInput({
    header,
    caption,
    state,
    foundMembers,
    inputPlaceholder,
    userQueryLoading,
    buttonCtaText,
    existingMembers
}: {
    header: string;
    caption: string;
    state: AddState;
    foundMembers: MemberPermission[] | undefined;
    inputPlaceholder: string;
    buttonCtaText: string;
    userQueryLoading: boolean;
    existingMembers: Record<string, MemberPermission> | undefined;
}) {
    const [memberIndex, setMemberIndex] = useState(0);
    const put = usePutPermissionChange();

    const { user } = useUserProfile();

    const member = foundMembers?.length ? foundMembers[memberIndex] : undefined;

    async function add() {
        const name = getMemberPermissionName(member);
        if (!member || !existingMembers) return Result.errFromMessage(LOADING_ERROR);
        if (existingMembers[member.key]) return Result.errFromMessage(`${name} is already added`);
        if (member.type === "user" && member.user.identifier === user.identifier)
            return Result.errFromMessage("You can't modify your own permissions");

        if (member.type === "group" && member.group.groupType === AbfGroupType.Custodian)
            return Result.errFromMessage(`${name} is a custodian and can't receive other roles`);

        const result = await put(member);
        if (result.isOk()) {
            state.reset();
            setMemberIndex(0);
        }
        return result;
    }

    const isValidEmail = state.email ? EMAIL_REGEX.test(state.email) : false;
    const foundValidUsers = !!foundMembers?.filter((f) => f.type !== "invite").length;

    return (
        <>
            <Column>
                <Text variant="h4">{header}</Text>
                <Text color="caption">{caption}</Text>
            </Column>

            <FormDebouncedStringInput
                InputProps={
                    userQueryLoading
                        ? { endAdornment: <CircularProgress size={10} /> }
                        : emailFormAdornments({ isValidEmail: isValidEmail || !state.email })
                }
                fullWidth
                placeholder={inputPlaceholder}
                variant="string"
                value={state.email}
                setValue={state.setEmail}
            />

            {(userQueryLoading || foundMembers?.length) && (
                <Column spacing={1}>
                    <Text color="caption" variant="body2">
                        {foundValidUsers ? "Found" : "Invite"}
                    </Text>
                    {userQueryLoading ? (
                        <MemberPermissionDisplay member={undefined} />
                    ) : (
                        foundMembers?.map((member, i) =>
                            foundMembers.length > 1 ? (
                                <RadioOption
                                    onClick={() => setMemberIndex(i)}
                                    isSelected={i === memberIndex}
                                    key={member.key}
                                >
                                    <MemberPermissionDisplay member={member} />
                                </RadioOption>
                            ) : (
                                <MemberPermissionDisplay member={member} />
                            )
                        )
                    )}
                </Column>
            )}

            <AppButton
                isTransaction={false}
                keyListener="Enter"
                asyncCta={{ onClickWithResult: add, closeDialog: "onSuccess" }}
                fullWidth
                variant="contained"
                disabled={!member}
            >
                {buttonCtaText}
            </AppButton>
        </>
    );
}

function MemberModifyWrapper({
    member,
    roles,
    disabled,
    children
}: {
    member: MemberPermission | undefined;
    roles: AbfRole[];
    disabled?: boolean;
    children?: ReactNode;
}) {
    const putChange = usePutPermissionChange();

    const { close } = useAppDialog();
    const updateRoles = useUpdateRolesTransaction("roleUpdate");
    const { resultHandler, isLoading } = useAsyncResultHandler();
    const txnSendNeeded = member?.type === "user" && member.wallet;
    const userHasNoWallets = member?.granter === "organization" && (member?.type !== "user" || !member.wallet);
    const name = getMemberPermissionName(member);

    const sender = useTransactionSender();

    async function submit() {
        const res = await resultHandler(() => putChange(editMemberPermission(member, roles)));
        if (res.isErr()) return res;
        close();
        return res;
    }

    // if sender needed, require user to connect wallet first and wrap the CTA
    if (txnSendNeeded) {
        return (
            <>
                {children}
                <AppButton
                    isTransaction
                    asyncCta={{
                        onClickWithResult: async () => {
                            const res = await putChange(editMemberPermission(member, roles), { skipRefresh: true });
                            if (!res.isOk()) return Result.err(res);
                            return await sender(updateRoles, { user: member?.wallet?.wallet });
                        }
                    }}
                    disabled={disabled}
                >
                    Confirm
                </AppButton>
            </>
        );
    }

    return (
        <>
            {children}
            <Column spacing={1}>
                {userHasNoWallets && !!roles.length && (
                    <Text variant="body2" color="error">
                        {name} will have view-only permissions until they register a wallet
                    </Text>
                )}

                <AppButton
                    isTransaction
                    loading={isLoading}
                    fullWidth
                    disabled={disabled}
                    asyncCta={{ onClickWithResult: submit }}
                    variant="contained"
                >
                    Confirm
                </AppButton>
            </Column>
        </>
    );
}

export function CreateCustodianCode() {
    const [generateCodes] = useGenerateCustodianSignupCodesMutation();
    const { hoverBackground } = useAppPalette();
    const { close } = useAppDialog();

    const [roles, setRoles] = useState<Set<AbfRole>>(new Set());
    async function generate() {
        if (!roles.size) return Result.errFromMessage(LOADING_ERROR);
        const res = await generateCodes(convertRolesToRoleNumbers(Array.from(roles)));
        if ("error" in res) {
            return Result.errWithDebug(getReadableErrorMessage("create invite code"), res);
        }

        close();
        return Result.ok();
    }

    return (
        <DialogWrapper>
            <DialogHeader
                header="Generate new code"
                description="Create unlimited use codes that will grant lender or borrower permissions to your users"
            />
            <Column>
                <Text variant="h4">Permissions </Text>
                <Text variant="body2" color="caption">
                    This code will grant access to all roles you select
                </Text>
            </Column>
            <Column sx={{ mx: -2 }}>
                {[AbfRole.Borrower, AbfRole.Lender].map((role, i) => (
                    <Row
                        sx={{ cursor: "pointer", px: 2, py: 0.5, ":hover": { background: hoverBackground } }}
                        key={i}
                        spaceBetween
                        onClick={() => {
                            const dupe = new Set(roles);
                            if (roles.has(role)) {
                                dupe.delete(role);
                            } else {
                                dupe.add(role);
                            }
                            setRoles(dupe);
                        }}
                    >
                        <Text> {ROLE_METADATA[role].name} </Text>
                        <Checkbox checked={roles.has(role)} />
                    </Row>
                ))}
            </Column>

            <AppButton
                isTransaction={false}
                fullWidth
                variant="contained"
                asyncCta={{ onClickWithResult: generate, options: { onSuccessMessage: "Code created!" } }}
            >
                Create code
            </AppButton>
        </DialogWrapper>
    );
}
