import { useCallback, useMemo, useState } from "react";

import { LOADING_ERROR, MISSING_PARAM_ERROR, Result } from "@bridgesplit/utils";
import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    TokenBalanceExpanded,
    isStakedSol,
    useActiveEscrow,
    useActiveWallet,
    useActiveGroup,
    LockboxCollateralWithdraw,
    useLockboxWithdrawCollateralTransaction,
    useRefinanceInfo,
    RefinanceInfoParams,
    getLoanCustodianIdentifiers,
    LoanCollateral,
    summarizeLedgerAccounts
} from "@bridgesplit/abf-react";
import { LockboxAssetUpdateArgs, ManageSide, StakeAccountArgs, StakedSolLockboxArgs } from "@bridgesplit/abf-sdk";
import { TrackTransactionEvent } from "app/types";
import { EmptyPlaceholder } from "@bridgesplit/ui";
import { LockClockOutlined } from "@mui/icons-material";

import { ActionProps } from "./types";
import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { useTransactionSender } from "../../transactions";
import { CollateralLoanHealthChangeStats } from "./common";
import { useBackfillLoan } from "../util";
import { useCalculateNewLoanHealth } from "./util";

export default function CollateralWithdraw({ loanExpanded: rawLoanExpanded }: ActionProps) {
    const loanExpanded = useBackfillLoan(rawLoanExpanded);

    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());

    const collateralWithMaxWithdrawableAmounts = useCollateralWithMaxWithdrawableAmounts(loanExpanded);

    if (!collateralWithMaxWithdrawableAmounts?.some((c) => c.amount > 0))
        return (
            <EmptyPlaceholder
                icon={<LockClockOutlined />}
                header="Withdraw not available"
                description="You don't have any collateral that can be withdrawn"
            />
        );

    return (
        <>
            <SelectAssets
                allowTokenInput
                maxText="Max withdrawal:"
                assets={collateralWithMaxWithdrawableAmounts}
                selected={selected}
                setSelected={setSelected}
            />
            <WithdrawCollateralCta
                selected={selected}
                loanExpanded={loanExpanded}
                collateralWithMaxWithdrawableAmounts={collateralWithMaxWithdrawableAmounts}
            />
        </>
    );
}

function WithdrawCollateralCta({
    selected,
    loanExpanded,
    collateralWithMaxWithdrawableAmounts
}: {
    selected: Map<string, number | undefined>;
    loanExpanded: AbfLoanExpanded | undefined;
    collateralWithMaxWithdrawableAmounts: TokenBalanceExpanded[] | undefined;
}) {
    const selectedAssets = loanExpanded?.collateral
        ?.map((asset) => ({ asset, selectedAmount: selected.get(asset.key) ?? 0 }))
        .filter((a) => !!a.selectedAmount);

    const healthChange = useCalculateNewLoanHealth(Array.from(selected.keys()))(
        loanExpanded,
        selectedAssets?.map((c) => ({ collateralMint: c.asset.mint, change: -c.selectedAmount }))
    );

    const send = useTransactionSender();
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce, activeEscrow } = useActiveEscrow();
    const { activeWallet } = useActiveWallet();

    const lockboxWithdraw = useLockboxWithdrawCollateralTransaction();

    const submit = useCallback(async () => {
        if (!groupIdentifier || !escrowNonce || !loanExpanded || !activeEscrow || !activeWallet)
            return Result.errFromMessage(LOADING_ERROR);

        if (!selectedAssets?.length) return Result.errFromMessage(MISSING_PARAM_ERROR);
        const lockbox = loanExpanded.lockboxAddress;

        const stakeAssets = selectedAssets.filter((s) => isStakedSol(s.asset.metadata.assetMint));

        const standardTransfers = selectedAssets
            .filter((a) => !isStakedSol(a.asset.metadata.assetMint))
            .map(
                ({ selectedAmount, asset: { mint } }): LockboxAssetUpdateArgs => ({
                    user: activeWallet.wallet,
                    assetMint: mint,
                    amount: selectedAmount,
                    lockbox,
                    escrowDeposit: false,
                    escrowAccount: activeEscrow,
                    side: ManageSide.Withdraw,
                    assetType: "SplToken"
                })
            );

        const stakeAccounts = stakeAssets.map(
            (s): StakeAccountArgs => ({
                address: s.asset.key,
                amountToTransfer: s.selectedAmount
            })
        );

        const stakedSolArgs: StakedSolLockboxArgs = {
            stakeAccounts: stakeAccounts,
            lockbox,
            groupIdentifier,
            escrowNonce,
            escrow: false,
            side: ManageSide.Withdraw
        };

        const params: LockboxCollateralWithdraw = { lockbox: standardTransfers, stakedSol: stakedSolArgs };

        return await send(lockboxWithdraw, params, {
            description: "Withdrawing collateral",
            mixpanelEvent: { key: TrackTransactionEvent.CollateralWithdraw, params }
        });
    }, [activeEscrow, activeWallet, escrowNonce, groupIdentifier, loanExpanded, lockboxWithdraw, selectedAssets, send]);

    const assetSelectionError = getSelectAssetsErrorMessage({ selected, assets: collateralWithMaxWithdrawableAmounts });

    return (
        <>
            <CollateralLoanHealthChangeStats healthChange={healthChange} />
            <AppButton
                isTransaction
                disabled={!!assetSelectionError}
                asyncCta={{ onClickWithResult: submit }}
                authentication={{
                    permission: AbfPermissionForCustodian.WithdrawCreditNote,
                    custodians: getLoanCustodianIdentifiers(loanExpanded)
                }}
                fullWidth
            >
                {assetSelectionError ?? " Withdraw "}
            </AppButton>
        </>
    );
}

function generateRefinanceParams(loanExpanded: AbfLoanExpanded | undefined): RefinanceInfoParams | undefined {
    if (!loanExpanded) return undefined;
    const principalMint = loanExpanded.principalMetadata.assetMint;
    const collateralMints = loanExpanded.collateral.map((c) => c.mint);

    return {
        [principalMint]: collateralMints
    };
}

export function useCollateralWithMaxWithdrawableAmounts(loanExpanded: AbfLoanExpanded | undefined) {
    const { getRefinanceInfo } = useRefinanceInfo(generateRefinanceParams(loanExpanded));

    return useMemo(() => {
        const totalOutstanding = loanExpanded ? summarizeLedgerAccounts(loanExpanded).totalOutstanding : 0;

        return loanExpanded?.collateral.map((collateral) => {
            if (collateral.whirlpoolPosition) return { ...collateral, amount: 0 };
            const refinanceInfo = getRefinanceInfo(
                loanExpanded.principalMetadata.assetMint,
                collateral.metadata.assetMint
            );

            const ltv = refinanceInfo?.ltv ?? 1;
            const requiredValueUsd = (loanExpanded.principalUsdPrice * totalOutstanding) / ltv;
            const requiredValue = requiredValueUsd / (collateral.usdPrice ?? 0);
            const withdrawableAmount = Math.max(collateral.amount - requiredValue, 0);

            return { ...collateral, amount: Math.min(collateral.amount, withdrawableAmount) };
        });
    }, [getRefinanceInfo, loanExpanded]);
}
interface AllowedUserAssetProps {
    loanExpanded: AbfLoanExpanded | undefined;
    assets: LoanCollateral[] | undefined;
    allowedCollateral: Map<string, number>;
}

export const useAllowedUserAssets = ({ loanExpanded, assets, allowedCollateral }: AllowedUserAssetProps) => {
    const allowedUserAssets: TokenBalanceExpanded[] = useMemo(() => {
        if (!loanExpanded || !assets) return [];

        return assets
            .filter((asset) => {
                const maxWithdrawable = allowedCollateral.get(asset.key);
                return maxWithdrawable !== undefined && maxWithdrawable > 0;
            })
            .map(
                (asset): TokenBalanceExpanded => ({
                    metadata: asset.metadata,
                    amount: asset.amount,
                    mint: asset.mint,
                    key: asset.key
                })
            );
    }, [loanExpanded, assets, allowedCollateral]);

    return allowedUserAssets;
};
