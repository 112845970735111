import { BackCta, Button, Column, Icon, IconHeader, Link } from "@bridgesplit/ui";
import { PRIME_SLUG } from "app/constants";
import { useAppDialog } from "app/utils";

import { DialogHeader } from "../../common";

export default function WalletSetupMpcPrimeGated({ back }: { back: () => void }) {
    const { close } = useAppDialog();
    return (
        <>
            <IconHeader tagColor="info">
                <Icon type="passkey" />
            </IconHeader>
            <DialogHeader
                centerAlign
                header="Only Loopscale Prime users have access to passkey wallets"
                description="Passkey wallets let you authenticate with your device or biometrics instead of a self-custodial wallet"
            />
            <Column spacing={1}>
                <Link to={PRIME_SLUG}>
                    <Button onClick={close} fullWidth variant="contained">
                        Learn more
                    </Button>
                </Link>
                <BackCta back={back} />
            </Column>
        </>
    );
}
