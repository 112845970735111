import { AbfRole, getAvatarFromUser } from "@bridgesplit/abf-react";
import { Column, Image, OutlinedCard, Row, SxType, Text, TooltipText } from "@bridgesplit/ui";
import { getAvatarFromString } from "@bridgesplit/react";
import { ROLE_METADATA } from "app/constants";

import { MemberPermission } from "./types";
import { getGroupType } from "../common";

export function RoleDisplay({ role, sx }: { role: AbfRole; sx?: SxType }) {
    const { icon, name, description } = ROLE_METADATA[role];
    return (
        <OutlinedCard padding={1.5} spacing={0.5} sx={sx}>
            <Text variant="h3"> {icon} </Text>
            <Text variant="h4"> {name} </Text>
            <Text variant="body2" color="caption">
                {description}
            </Text>
        </OutlinedCard>
    );
}

export function RolesDisplay({ roles }: { roles: AbfRole[] | undefined }) {
    return (
        <Row sx={{ width: "100%", overflowX: "auto" }} alignItems="stretch" spacing={1}>
            {roles?.map((r) => (
                <RoleDisplay sx={{ minWidth: "200px", width: "100%" }} role={r} key={r} />
            ))}
        </Row>
    );
}

export function getMemberPermissionName(member: MemberPermission | undefined) {
    if (!member) return "";
    if (member.type === "group") return member.group.groupName;
    if (member.type === "invite") return member.email;
    return member.user.name ?? member.user.email;
}

export function MemberPermissionDisplay({ member }: { member: MemberPermission | undefined }) {
    const name = (function () {
        if (!member) return "";
        if (member.type === "group") return member.group.groupName;
        if (member.type === "invite") return member.email;
        return member.user.email;
    })();

    const tooltip = (function () {
        if (!member) return "";
        if (member.type === "invite")
            return `The permissions you set will automatically be assigned to ${name} once they create an account`;
        return undefined;
    })();
    const description = (function () {
        if (!member) return "";
        if (member.type === "group") return getGroupType(member.group);
        if (member.type === "invite") return "Pending";
        return member.user.name;
    })();
    const image = (function () {
        if (!member) return "";
        if (member.type === "group") return member.group.groupImage;
        if (member.type === "invite") return getAvatarFromString(member.email);
        return getAvatarFromUser(member.user);
    })();

    return (
        <Row spacing={1}>
            <Image
                sx={{
                    filter: member?.type === "invite" ? "saturate(0)" : undefined,
                    opacity: member?.type === "invite" ? 0.2 : 1
                }}
                size="35px"
                loading={!image}
                src={image}
            />
            <Column>
                <Text loading={!member}>{name}</Text>
                <TooltipText loading={!member} loadingWidth="150px" color="caption" variant="body2" helpText={tooltip}>
                    {description}
                </TooltipText>
            </Column>
        </Row>
    );
}
