import { DurationUnit } from "./time";

export interface PayFeeInputs {
    ledgerId: number;
    feeType: number;
    amountFromWallet: number;
    amountFromEscrow: number;
    orderAddress: string;
    loanVaultAddress: string;
    feeMint: string;
}

export interface NewFeeSchedule {
    feeRule: number;
    feeType: number;
    feeAmount: number;
    feeMetadata: FeeMetadata;
}

export interface NewFeeScheduleWithOrder {
    schedule: NewFeeSchedule;
    orderAddress: string;
}

export interface NewLatePaymentFee {
    gracePeriodLength: number;
}

export interface NewEarlyPaymentFee {
    paymentWindow: number;
    duration: number;
    durationType: DurationUnit;
}

export interface FeeAccountFilter {
    loanAddresses: string[];
    ledgerAccounts: string[];
    feeTypes: FeeType[];
}

export enum FeeType {
    EarlyRepayment,
    LateRepayment
}

export enum FeeRuleType {
    /// apply fee on only interest payments
    InterestOnly,
    /// apply fee on payments w both principal and interest
    InterestAndPrincipal,
    /// apply fee on only principal (not currently used)
    PrincipalOnly
}

export type FeeMetadata =
    | {
          lateFee: NewLatePaymentFee;
      }
    | {
          earlyFee: NewEarlyPaymentFee;
      };

export interface AbfFeeSchedule {
    id: number;
    identifier: string;
    ruleType: FeeRuleType;
    feeAmount: number;
    feeType: FeeType;
}

export interface AbfFeeScheduleToOrder {
    id: number;
    feeScheduleIdentifier: string;
    orderAddress: string;
}

export interface AbfLatePaymentFee {
    id: number;
    feeScheduleIdentifier: string;
    gracePeriodLength: number;
}

export interface AbfEarlyPaymentFee {
    id: number;
    feeScheduleIdentifier: string;
    paymentWindow: number;
    duration: number;
    durationType: number;
}

export interface AbfFeeAccount {
    id: number;
    address: string;
    ledgerId: number;
    loan: string;
    amountDue: number;
    amountPaid: number;
    feeType: number;
}

export interface FeeScheduleWithMetadata {
    feeSchedule: AbfFeeSchedule;
    feeMetadata: FeeMetadata[];
}

export interface EarlyFeeSchedule {
    feeSchedule: AbfFeeSchedule;
    feeMetadata: AbfEarlyPaymentFee;
}

export interface LateFeeSchedule {
    feeSchedule: AbfFeeSchedule;
    feeMetadata: AbfLatePaymentFee;
}

export interface FeeScheduleInfo {
    lateFees: LateFeeSchedule[];
    earlyFees: EarlyFeeSchedule[];
}

export const DEFAULT_FEE_SCHEDULE: FeeScheduleInfo = {
    lateFees: [],
    earlyFees: []
};

export enum PrepaymentStatus {
    NotAllowed,
    NoPenalty,
    WithPenalty
}

export function convertFeeScheduleToNewFeeSchedule(feeScheduleWithMetadata: FeeScheduleWithMetadata): NewFeeSchedule {
    const { feeSchedule, feeMetadata } = feeScheduleWithMetadata;
    const newFeeSchedule: NewFeeSchedule = {
        feeRule: feeSchedule.ruleType,
        feeType: feeSchedule.feeType,
        feeAmount: feeSchedule.feeAmount,
        feeMetadata: feeMetadata[0] || {}
    };
    return newFeeSchedule;
}
