import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    BsMetaUtil,
    getMostRecentPaymentDate,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { StatColumn } from "@bridgesplit/ui";
import { formatDate } from "@bridgesplit/utils";

import { AppButton } from "../../common";
import { useLoanRepaymentDetails, useWithdrawLoanCredit } from "../util";
import { ActionProps } from "./types";

export default function ClaimPayments(props: ActionProps) {
    const { loanExpanded } = props;
    const symbol = BsMetaUtil.getSymbol(props.loanExpanded?.principalMetadata);
    const details = useLoanRepaymentDetails(loanExpanded);

    return (
        <>
            <StatColumn
                stats={[
                    { value: details?.claimableBalance, caption: "Claimable balance", symbol },
                    { value: details?.outstandingBalance, caption: "Outstanding repayments", symbol },
                    {
                        value: details?.totalClaimed,
                        caption: "Reclaimed",
                        symbol,
                        tooltip: "Repayments you have previously claimed"
                    },
                    { caption: "Last payment", value: formatDate(getMostRecentPaymentDate(loanExpanded), "relative") }
                ]}
            />

            {!!loanExpanded && <ClaimPaymentsButton loanExpanded={loanExpanded} />}
        </>
    );
}

function ClaimPaymentsButton({ loanExpanded }: { loanExpanded: AbfLoanExpanded }) {
    const details = useLoanRepaymentDetails(loanExpanded);
    const withdraw = useWithdrawLoanCredit(loanExpanded);

    return (
        <AppButton
            isTransaction
            authentication={{
                permission: AbfPermissionForCustodian.ClaimRepayment,
                custodians: getLoanCustodianIdentifiers(loanExpanded)
            }}
            tooltip={details?.claimableBalance ? undefined : "No payments to claim"}
            asyncCta={{ onClickWithResult: withdraw }}
            disabled={!details?.claimableBalance}
            fullWidth
            variant="contained"
        >
            Claim payments
        </AppButton>
    );
}
