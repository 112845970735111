import { useState } from "react";

import { VerticalScrollContainer, repeatElement, SkeletonRounded, Text } from "@bridgesplit/ui";
import { StakeAccountExpanded, useActiveEscrow, useActiveWallet, useStakedSolByWallet } from "@bridgesplit/abf-react";
import { DispatchType, FormInputType, useCurrentEpochQuery } from "@bridgesplit/react";
import debounce from "lodash.debounce";
import { RPC_URL, SOL_DECIMALS, bsMath } from "@bridgesplit/utils";

import { TokenAmountInput } from "../../common";

const MAX_SELECT = 3;
export function useStakeSelect(options?: { showWallet?: boolean; requireCurrentEpoch?: boolean }) {
    const [selected, setSelectedRaw] = useState<Map<string, number | undefined>>(new Map());

    const { activeWallet } = useActiveWallet();
    const { activeEscrow } = useActiveEscrow();
    const { data: escrowAccounts } = useStakedSolByWallet(activeEscrow, { skip: !!options?.showWallet });
    const { data: epochInfo } = useCurrentEpochQuery(RPC_URL, { skip: !options?.requireCurrentEpoch });

    const { data: walletAccounts } = useStakedSolByWallet(activeWallet?.wallet, {
        skip: !options?.showWallet
    });
    const accounts = options?.showWallet ? walletAccounts : escrowAccounts;

    const selectedAccounts = accounts
        ?.map((a) => ({ ...a, amount: selected.get(a.stakeAccount) ?? 0 }))
        .filter((a) => !!a.amount);

    const setSelected = debounce((map: Map<string, number | undefined>) => setSelectedRaw(map), 1_000);

    const Select = () => (
        <StakeSelect
            accounts={accounts}
            selected={selected}
            setSelected={setSelected}
            setSelectedRaw={setSelectedRaw}
            currentEpoch={epochInfo?.epoch}
        />
    );

    return { Select, selectedAccounts };
}

function StakeSelect({
    accounts,
    selected,
    setSelected,
    setSelectedRaw,
    currentEpoch
}: {
    accounts: StakeAccountExpanded[] | undefined;
    selected: Map<string, number | undefined>;
    setSelected: FormInputType<Map<string, number | undefined>>;
    setSelectedRaw: DispatchType<Map<string, number | undefined>>;
    currentEpoch: number | undefined;
}) {
    const selectedAccounts = new Map(Array.from(selected?.entries() ?? []).filter(([, v]) => !!v));
    const maxSelected = selectedAccounts.size >= MAX_SELECT;
    return (
        <>
            {maxSelected && <Text color="error">Select up to {MAX_SELECT} accounts</Text>}
            <VerticalScrollContainer maxHeight={400} isOverflowing={!!accounts && accounts.length > 8} spacing={1}>
                {accounts?.length === 0 && <Text color="disabled">No accounts found</Text>}

                {!accounts && repeatElement(<SkeletonRounded height="35px" />, 3)}
                {accounts?.map(({ metadata, amount, rentExemptReserve, stakeAccount, delegationActivationEpoch }) => {
                    const maxAmount = bsMath.tokenAdd(amount, rentExemptReserve, SOL_DECIMALS);
                    const inactiveStake = !!currentEpoch && currentEpoch < delegationActivationEpoch;
                    return (
                        <TokenAmountInput
                            key={stakeAccount}
                            disabled={(maxSelected && !selectedAccounts.has(stakeAccount)) || inactiveStake}
                            metadata={metadata}
                            warningMessage={inactiveStake ? "Stake must be activated to unstake" : undefined}
                            value={selected?.get(stakeAccount)}
                            maxAmount={maxAmount}
                            setValue={(val) => {
                                const copy = new Map(selected);
                                copy.set(stakeAccount, val);

                                // don't debounce on "max" select
                                if (val === maxAmount) {
                                    setSelectedRaw(copy);
                                } else {
                                    setSelected(copy);
                                }
                            }}
                        />
                    );
                })}
            </VerticalScrollContainer>
        </>
    );
}
