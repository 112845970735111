import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    JupiterSwapQuote,
    JupiterQuoteParams,
    JupiterPriceResponse,
    JupiterPriceQuoteParams,
    JupiterPerpsPoolResponse
} from "@bridgesplit/abf-sdk";
import { useDispatch } from "react-redux";
import { PANDORA_API } from "@bridgesplit/utils";

const SWAP_QUOTE_TAG = "SWAP_QUOTE_TAG";
export const jupiterApi = createApi({
    reducerPath: "jupiterApi",
    tagTypes: [SWAP_QUOTE_TAG],
    baseQuery: fetchBaseQuery({ baseUrl: "" }),
    endpoints: (builder) => ({
        jupiterSwapQuote: builder.query<JupiterSwapQuote, JupiterQuoteParams>({
            providesTags: [SWAP_QUOTE_TAG],
            query: (params) => ({
                url: "https://quote-api.jup.ag/v6/quote",
                method: "GET",
                params
            })
        }),
        jupiterPriceQuote: builder.query<JupiterPriceResponse, JupiterPriceQuoteParams>({
            providesTags: [SWAP_QUOTE_TAG],
            query: ({ mints, includeExtraInfo }) => ({
                url: "https://api.jup.ag/price/v2",
                method: "GET",
                params: { ids: mints.join(","), includeExtraInfo }
            })
        }),
        jlpPerps: builder.query<JupiterPerpsPoolResponse, void>({
            providesTags: [SWAP_QUOTE_TAG],
            query: () => ({
                url: `${PANDORA_API}/jupiter_perps/pool`,
                method: "GET"
            })
        })
    })
});

export const { useJupiterSwapQuoteQuery, useJupiterPriceQuoteQuery, useJlpPerpsQuery } = jupiterApi;

export function useJupiterApi() {
    const dispatch = useDispatch();
    return {
        resetJupiterSwapApi: () => dispatch(jupiterApi.util.invalidateTags([SWAP_QUOTE_TAG]))
    };
}
