import { ReactNode } from "react";

import { Button, Column } from "@bridgesplit/ui";
import { RoleView } from "@bridgesplit/abf-react";

import { HeaderWithClose } from "../../common";
import { useMarketContext } from "../common";
import { MobileBottomCta } from "../../wrappers";

export default function MarketLayoutMobile({
    card,
    header,
    quotes
}: {
    card: ReactNode;
    header: ReactNode;
    quotes: ReactNode;
}) {
    const { view, isActionOpen, setActionOpen } = useMarketContext();

    return (
        <Column spacing={2}>
            {isActionOpen ? (
                <>
                    <HeaderWithClose variant="h4" close={() => setActionOpen(false)} header={view} />
                    {card}
                </>
            ) : (
                <>
                    {header}
                    {quotes}
                    <MobileBottomCta>
                        <CtaButtons
                            open={() => {
                                window.scrollTo(0, 0);
                                setActionOpen(true);
                            }}
                        />
                    </MobileBottomCta>
                </>
            )}
        </Column>
    );
}

function CtaButtons({ open }: { open: () => void }) {
    const { setView } = useMarketContext();

    return (
        <>
            <Button
                fullWidth
                onClick={() => {
                    open();
                    setView?.(RoleView.Borrow);
                }}
                variant="contained"
                color="secondary"
            >
                Borrow
            </Button>
            <Button
                fullWidth
                onClick={() => {
                    open();
                    setView?.(RoleView.Lend);
                }}
                variant="outlined"
            >
                Lend
            </Button>
        </>
    );
}
