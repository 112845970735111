import { fetchAndDeserializeLegacyTransaction, PANDORA_API } from "@bridgesplit/utils";
import { Meteora } from "@bridgesplit/bs-protos";

import { createAbfInstance } from "./auth";
import { AbfTransactionAuthentication, METEORA_DEPOSIT_TXN, METEORA_WITHDRAW_TXN } from "../types";

export async function getMeteoraDepositTransaction(
    authentication: AbfTransactionAuthentication,
    params: Meteora.DynamicPoolDepositParams
) {
    return [
        await fetchAndDeserializeLegacyTransaction(
            createAbfInstance(authentication, PANDORA_API),
            METEORA_DEPOSIT_TXN,
            params
        )
    ];
}

export async function getMeteoraWithdrawTransaction(
    authentication: AbfTransactionAuthentication,
    params: Meteora.DynamicPoolWithdrawParams
) {
    return [
        await fetchAndDeserializeLegacyTransaction(
            createAbfInstance(authentication, PANDORA_API),
            METEORA_WITHDRAW_TXN,
            params
        )
    ];
}
