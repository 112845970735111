import { QuestionToggleProps } from "@bridgesplit/ui";
import { COPY } from "app/constants";
import { BsMetaUtil } from "@bridgesplit/abf-react";

import { useLoopContext } from "../../LoopContext";

export function useBaseFaq() {
    const { loopExpanded } = useLoopContext();

    if (!loopExpanded) return undefined;

    const collateralSymbol = BsMetaUtil.getSymbol(loopExpanded.collateralToken);
    const principalSymbol = BsMetaUtil.getSymbol(loopExpanded.principalToken);

    const intro: QuestionToggleProps = {
        label: `What are ${COPY.LOOP_TERM_PLURAL.toLowerCase()}?`,
        answer: [
            `${COPY.LOOP_TERM_PLURAL} are a one-click solution to amplify your ${collateralSymbol} yield. It works by borrowing ${principalSymbol} from Loopscale's market and using it to acquire more ${collateralSymbol}. This increases your ${collateralSymbol} exposure, potentially boosting your overall yield. When you close the position, you repay the ${principalSymbol} loan and keep any extra ${collateralSymbol} as profit.`
        ]
    };

    const uniqueFeatures: QuestionToggleProps = {
        label: `What sets Loopscale's ${COPY.LOOP_TERM_PLURAL.toLowerCase()} apart?`,
        answer: [
            "Isolated risk: Each loan is directly matched with a lender, reducing systemic risks.",
            "Flexible rate locking: While rates adjust daily by default, you can also choose to lock in for a week or a month to reduce volatility.",
            "Competitive costs: Loopscale has some of the best borrowing rates in the market.",
            "Diverse assets: Loopscale supports a wide range of collateral and strategies, including JLP and Meteora."
        ]
    };

    const management: QuestionToggleProps = {
        label: "Do I need to manage my position?",
        answer: [
            `You don't need to hover over it 24/7, but regular check-ins are recommended. You can manage your position health by topping up more ${collateralSymbol} or making partial ${principalSymbol} repayments.`
        ]
    };

    return { intro, uniqueFeatures, management };
}
