import { StrategyTemplateExpanded } from "@bridgesplit/abf-react";
import { createRows, TableColumn, Table } from "@bridgesplit/ui";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { Radio } from "@mui/material";
import { COPY } from "app/constants";

import { strategyTemplateColumns } from "../../common";
import { useMarketContext } from "../common";
import { useMarketLendContext } from "./MarketLendContext";
import { useSelectStrategyTemplate } from "./util";

type TemplateRow = StrategyTemplateExpanded & {
    principalMetadata: BsMetadata | undefined;
    isSelected: boolean;
    select: () => void;
};
export default function LendSelectTemplate() {
    const { templates, form } = useMarketLendContext();
    const { token } = useMarketContext();
    const select = useSelectStrategyTemplate();

    const data = templates?.map(
        (t): TemplateRow => ({
            ...t,
            principalMetadata: token,
            select: () => select(t),
            isSelected: form?.templateId === t.template.identifier
        })
    );

    return (
        <Table
            onRowClick={({ data }) => data.select()}
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: "limit"
            }}
            emptyPlaceholder={`No ${COPY.STRATEGY_TEMPLATE_TERM_PLURAL_LOWER} found`}
            loading={!data}
            rows={createRows(data, (s) => s.template.identifier)}
            columns={columns}
        />
    );
}

const columns: TableColumn<TemplateRow>[] = [
    {
        dataIndex: "select",
        title: "",
        width: "10%",
        render: ({ data: { isSelected, select } }) => (
            <Radio checked={isSelected} onChange={isSelected ? select : undefined} />
        )
    },
    ...strategyTemplateColumns
];
