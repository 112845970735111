import { useCallback } from "react";

import {
    DEFAULT_PUBLIC_KEY,
    IS_LOCAL_NX_DEV,
    NullableRecord,
    TIME,
    USD_PRICING_QUOTE_ASSET,
    bsMath,
    filterNullableRecord,
    filterTruthy
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap, useObjectToMap } from "@bridgesplit/ui";
import { OracleQuote, OracleQuoteInfo } from "@bridgesplit/abf-sdk";

import {
    useExternalPricesQuery,
    useLatestExternalPricesQuery,
    useOraclePricesQuery,
    useQuoteAssetsQuery
} from "../reducers";
import { ExternalPricingSource, PricingDataWithQuote } from "../types";
import { useSkipUnauthenticated } from "./auth";

// mints are unused in query but include mints in params for future refactors
export function useOraclePrices(mints: (string | undefined)[] | undefined) {
    const validMints = mints?.filter(filterTruthy);
    const {
        data: prices,
        isLoading,
        isError
    } = useOraclePricesQuery(undefined, {
        pollingInterval: IS_LOCAL_NX_DEV ? undefined : 15_000,
        skip: !validMints
    });

    const priceMap = useObjectToMap(prices);

    const getOracle = useCallback(
        function getOracle(mint: string | undefined): OracleQuote | undefined {
            if (!priceMap || !mint) return undefined;
            const quote = priceMap.get(mint);
            if (!quote) return undefined;
            const baseQuote = quote.quoteMint === DEFAULT_PUBLIC_KEY ? undefined : priceMap.get(quote.quoteMint);
            const usdPrice = quote.price * (baseQuote?.price ?? 1);

            const baseQuotes: OracleQuoteInfo[] = baseQuote ? [quote, baseQuote] : [quote];

            return {
                baseQuotes,
                usdPrice,
                getUsdAmount: (...params) => getUsdAmountFromUsdPrice(usdPrice, ...params)
            };
        },
        [priceMap]
    );

    const getUsdPrice = useCallback(
        function getUsdPrice(mint: string | undefined): number | undefined {
            return getOracle(mint)?.usdPrice;
        },
        [getOracle]
    );

    return { getOracle, getUsdPrice, isLoading, isError };
}

export function getUsdAmountFromUsdPrice<T extends number | undefined>(usdPrice: number, amount: T, decimals?: number) {
    if (decimals) {
        return bsMath.tokenAMul(amount, usdPrice, decimals) as T;
    }
    return bsMath.mul(amount, usdPrice) as T;
}

const PRICING_SOURCE_MAP = new Map([["3Ma9bxLRjD6qSx6KonpUADuoAwpbu2cTXvtVmwb8ixqs", ExternalPricingSource.BAXUS]]);

export function useExternalPricesByMint(assetMint: string | undefined, custodianIdentifier: string | undefined) {
    const pricingSource = custodianIdentifier ? PRICING_SOURCE_MAP.get(custodianIdentifier) : undefined;

    const { data: prices, isLoading: pricesLoading } = useExternalPricesQuery(
        {
            assetMints: assetMint ? [assetMint] : [],
            sources: pricingSource ? [pricingSource] : undefined,
            intervalSeconds: TIME.DAY
        },
        { skip: !assetMint || pricingSource === undefined }
    );

    const quoteToPricesMap =
        !!assetMint && pricingSource !== undefined ? prices?.[assetMint]?.[pricingSource] : undefined;

    const quotes = quoteToPricesMap ? Object.keys(quoteToPricesMap) : [];
    const { data: quoteAssetsMap, isFetching: quotesLoading } = useQuoteAssetsQuery(quotes, { skip: !quotes.length });

    const isLoading = quotesLoading || pricesLoading;

    const data = Object.entries(quoteToPricesMap ?? {})
        .map(
            ([quoteIdentifier, prices]): NullableRecord<PricingDataWithQuote> => ({
                prices,
                quote: quoteAssetsMap?.[quoteIdentifier]
            })
        )
        .filter(filterNullableRecord);

    return { data: isLoading ? undefined : data, isLoading };
}

export function useExternalUsdPriceForMints(assetMints: string[] | undefined, skip?: boolean) {
    const skipIfUnauthenticated = useSkipUnauthenticated();
    const {
        data: prices,
        isLoading,
        isFetching,
        isError
    } = useLatestExternalPricesQuery(
        assetMints ? { assetMints, intervalSeconds: TIME.DAY, quoteAssets: [USD_PRICING_QUOTE_ASSET] } : skipToken,
        {
            skip: !assetMints?.length || skipIfUnauthenticated || skip
        }
    );

    const mintToPrice = useMemoizedKeyMap(prices ?? [], (p) => p.assetMint);

    const getUsdExternalPrice = useCallback(
        function getUsdExternalPrice(assetMint: string | undefined) {
            if (!assetMint || !mintToPrice) return undefined;

            return mintToPrice.get(assetMint)?.price;
        },
        [mintToPrice]
    );

    return { getUsdExternalPrice, isLoading, isFetching, isError };
}

export function useAllAvailablePrices(mints: string[] | undefined) {
    const { getUsdPrice, isLoading: oracleLoading } = useOraclePrices(mints);

    const { getUsdExternalPrice, isLoading: externalLoading } = useExternalUsdPriceForMints(mints);

    const getPriceByMint = useCallback(
        (mint: string | undefined) => {
            return getUsdPrice(mint) ?? getUsdExternalPrice(mint);
        },
        [getUsdExternalPrice, getUsdPrice]
    );

    return { getPriceByMint, isLoading: externalLoading || oracleLoading };
}

export function isOracleStale(oracleInfo: OracleQuoteInfo) {
    const timeSinceLastUpdate = oracleInfo.nowTimestamp - oracleInfo.lastUpdateTime;
    return timeSinceLastUpdate > oracleInfo.maxStaleness;
}
