import { useEffect, useState } from "react";

import { ButtonBase, FONT_WEIGHTS, LabelWrapper, Select, TagTextAlert, TextNestedLink } from "@bridgesplit/ui";
import {
    AbfLoanExpanded,
    BsMetaUtil,
    summarizeFeeAccounts,
    isSimpleLoan,
    findUnfilledEmptyLedgers,
    summarizeLedgerAccounts,
    getNextLedgerAccount
} from "@bridgesplit/abf-react";
import { bsMath } from "@bridgesplit/utils";

import { ActionProps } from "./types";
import { RepaymentActionType } from "./payments/types";
import { useLedgerSyncNeeded } from "./payments/util";
import SyncLedgers from "./payments/SyncLedgers";
import RepayPrincipal from "./payments/RepayPrincipal";
import SinglePayment from "./payments/SinglePayment";
import FillEmptyLedgers from "./payments/FillEmptyLedgers";
import PayFees from "./payments/PayFees";

export default function MakeRepayment({ loanExpanded }: ActionProps) {
    const [repaymentType, setRepaymentType] = useState<RepaymentActionType>(RepaymentActionType.SinglePayment);

    const ledgerSyncNeeded = useLedgerSyncNeeded(loanExpanded);
    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);
    const { principalOutstanding } = summarizeLedgerAccounts(loanExpanded);

    const nextInterestDue = bsMath.sub(nextLedgerAccount?.interestDue, nextLedgerAccount?.interestPaid);
    const { feesOutstanding } = summarizeFeeAccounts(loanExpanded?.feeAccounts);

    const options = Object.values(RepaymentActionType).filter((o) => {
        if (o === RepaymentActionType.RepayPrincipal) return !!principalOutstanding;
        if (o === RepaymentActionType.PayFees) return !!feesOutstanding;
        return true;
    });

    // reset select if options no longer valid
    useEffect(() => {
        if (!repaymentType || !options.includes(repaymentType)) {
            setRepaymentType(RepaymentActionType.SinglePayment);
        }
    }, [options, repaymentType]);

    if (ledgerSyncNeeded) {
        return <SyncLedgers loanExpanded={loanExpanded} />;
    }

    if (findUnfilledEmptyLedgers(loanExpanded)?.length && (nextInterestDue ?? 0) <= 0) {
        return <FillEmptyLedgers loanExpanded={loanExpanded} />;
    }

    return (
        <>
            {isSimpleLoan(loanExpanded) ? null : (
                <LabelWrapper label="Repayment type">
                    <Select
                        value={repaymentType}
                        setValue={setRepaymentType}
                        options={options.map((value) => ({ value }))}
                    />
                </LabelWrapper>
            )}

            {!!feesOutstanding && repaymentType !== RepaymentActionType.PayFees && (
                <UnpaidFeesAlert
                    open={() => setRepaymentType(RepaymentActionType.PayFees)}
                    loanExpanded={loanExpanded}
                />
            )}
            <RepaymentActions loanExpanded={loanExpanded} repaymentType={repaymentType} />
        </>
    );
}

function RepaymentActions({
    repaymentType,
    loanExpanded
}: {
    repaymentType: RepaymentActionType;
    loanExpanded: AbfLoanExpanded | undefined;
}) {
    switch (repaymentType) {
        case RepaymentActionType.SinglePayment:
            return <SinglePayment loanExpanded={loanExpanded} />;
        case RepaymentActionType.RepayPrincipal:
            return <RepayPrincipal loanExpanded={loanExpanded} />;
        // case RepaymentActionType.PayInFull:
        //     return <PayInFull loanExpanded={loanExpanded} />;
        case RepaymentActionType.PayFees:
            return <PayFees loanExpanded={loanExpanded} />;
        default:
            return null;
    }
}

function UnpaidFeesAlert({ open, loanExpanded }: { open: () => void; loanExpanded: AbfLoanExpanded | undefined }) {
    const { feesOutstanding } = summarizeFeeAccounts(loanExpanded?.feeAccounts);

    // ZC loans will repay fees automatically
    if (isSimpleLoan(loanExpanded)) return null;

    return (
        <ButtonBase px={0} py={0} onClick={open}>
            <TagTextAlert sx={{ textAlign: "left" }} textSx={{ display: "inline" }} color="error">
                You have {BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, feesOutstanding)} unpaid fees.
                Failure to pay these fees before maturity will result in a default.
                <TextNestedLink
                    sx={{ mx: 0.5, fontWeight: FONT_WEIGHTS.bold, color: ({ palette }) => palette.error.main }}
                >
                    Pay now
                </TextNestedLink>
            </TagTextAlert>
        </ButtonBase>
    );
}
