import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { UserLoopInfo, UserLoopInfoParams } from "@bridgesplit/abf-sdk";

import { abfSerializeQueryArgs, napoleonBaseQuery } from "./util";
import {
    ABF_GROUP_HEADER,
    ROUTE_GET_IS_LOOP_VAULT_SETUP,
    ROUTE_GET_LOOP_POSITION_STATS,
    ROUTE_GET_USER_LOOP_INFO
} from "../constants";
import { LoopPositionStats } from "../types";

const LOOP_POSITIONS_TAG = "LoopPositions";

// ... (getHeaders and getAbfBaseQuery functions remain the same)

export const loopApi = createApi({
    reducerPath: "loopApi",
    tagTypes: [LOOP_POSITIONS_TAG],
    baseQuery: napoleonBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        userLoops: builder.query<UserLoopInfo[], { userLoopInfo: UserLoopInfoParams; groupIdentifier: string }>({
            providesTags: [LOOP_POSITIONS_TAG],
            query: (params) => ({
                url: ROUTE_GET_USER_LOOP_INFO,
                method: "POST",
                body: params.userLoopInfo,
                headers: { [ABF_GROUP_HEADER]: params.groupIdentifier }
            })
        }),
        loopPositionStats: builder.query<LoopPositionStats, string>({
            providesTags: [LOOP_POSITIONS_TAG],
            query: (groupIdentifier) => ({
                url: ROUTE_GET_LOOP_POSITION_STATS,
                method: "GET",
                headers: { [ABF_GROUP_HEADER]: groupIdentifier }
            })
        }),
        loopVaultSetupCompleted: builder.query<boolean, string>({
            query: (loopVaultAddress) => ({
                url: `${ROUTE_GET_IS_LOOP_VAULT_SETUP}/${loopVaultAddress}`,
                method: "GET"
            })
        })
    })
});

export const { useUserLoopsQuery, useLoopPositionStatsQuery } = loopApi;

export const useLoopApi = () => {
    const dispatch = useDispatch();

    return {
        resetLoopApi: () => dispatch(loopApi.util.invalidateTags([LOOP_POSITIONS_TAG]))
    };
};
