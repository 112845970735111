import {
    PeopleAltOutlined,
    AutorenewOutlined,
    SyncLockOutlined,
    SettingsSuggestOutlined,
    FavoriteBorderOutlined,
    LoopOutlined,
    LegendToggleOutlined,
    EventAvailableOutlined
} from "@mui/icons-material";
import { COPY } from "app/constants";

export interface OnboardingBullet {
    Icon: React.ElementType;
    header: string;
    text: string;
}

export const LEND_BULLETS: OnboardingBullet[] = [
    {
        Icon: SettingsSuggestOutlined,
        header: "Open positions with custom risk profiles",
        text: `Choose ${COPY.ALLOWED_COLLATERAL_TERM}, ${COPY.STRATEGY_APY_TERM}, and max loan duration for prospective borrowers.`
    },
    {
        Icon: PeopleAltOutlined,
        header: "Match to peer-to-peer loans",
        text: "Earn interest from fixed-duration loans. Positions with low rates and flexible terms will match to more loans."
    },
    {
        Icon: AutorenewOutlined,
        header: "Auto-reinvest repayments",
        text: "Receive repayments back to your position. If a borrower defaults, the collateral is liquidated to repay the loan."
    },
    {
        Icon: SyncLockOutlined,
        header: "Withdraw early",
        text: "Collect partial interest by selling the loan back to the market before the due date."
    }
];

export const BORROW_BULLETS: OnboardingBullet[] = [
    {
        Icon: LoopOutlined,
        header: "Borrow peer-to-peer",
        text: "Supply collateral and maintain health for each separate borrow. No need to manage a global position."
    },
    {
        Icon: LegendToggleOutlined,
        header: "Enjoy fixed rates",
        text: "Pay the same APY for your entire loan. Repay only accrued interest when ending a loan early."
    },
    {
        Icon: FavoriteBorderOutlined,
        header: "Monitor loan health",
        text: "If your collateral value drops, avoid liquidation by topping up or partially repaying."
    },
    {
        Icon: EventAvailableOutlined,
        header: "Repay on time",
        text: "Repay by the due date or your loan will auto-extend. If a new offer isn't available, collateral will be liquidated."
    }
];
