import {
    AbfLedgerAccount,
    AbfLoanVault,
    AbfOrder,
    getRepayLoanTransaction,
    RepaymentType,
    getSyncLedgersTransactions,
    SyncLedgersArgs,
    RepayLoanArgs,
    LockboxAssetUpdateArgs,
    SetupLoanArgs,
    StakedSolLockboxArgs,
    getDepositLockboxStakedSolTransaction,
    getDepositLockboxTransaction,
    getLoanWithdrawVersionedTransaction,
    getSetupLoanTransactions,
    getPayFeeTransaction,
    PayFeeInputs,
    getRepayZcLoanTransactions,
    getLoanEndVersionedTransaction,
    FeeType,
    getIncreasePrincipalTransaction,
    IncreaseCreditTransactionArgs
} from "@bridgesplit/abf-sdk";
import { OrderedTransactions, combineTransactionPromises } from "@bridgesplit/react";
import {
    DEFAULT_PRINCIPAL_MINT,
    Result,
    SOL_DECIMALS,
    TransactionGenerationType,
    emptyPromise,
    uiAmountToLamports
} from "@bridgesplit/utils";

import { useAbfFetches, useGetTransactionGenerationType } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useDecimalsByMint } from "../utils";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithSetup } from "./common";
import { TRANSACTION_DEFAULT_BATCH_COMMITMENT } from "../constants";

export type LoanRepaymentParam = {
    amount: number;
    overpay: boolean;
    repaymentType: RepaymentType;
    ledger: AbfLedgerAccount;
};

export type RepayParams = {
    order: AbfOrder;
    loan: AbfLoanVault;
    escrowNeeded: boolean;
    repayerEscrowAccount: string;
    payments: LoanRepaymentParam[];
    fullRepay: boolean;
};

export type RepayZcLoanParams = RepayParams & {
    feeAmount: number;
};

export function useRepayLoanTransaction(): AbfTransactionDetails<RepayParams> {
    const generate = useAbfGenerateTransaction();

    const { resetLoanApi, resetEscrowedApi, resetLockboxApi, resetFeesApi, resetLedgerAccounts, resetLoopApi } =
        useAbfFetches();
    const { fetchDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams(params: RepayParams): AbfGeneratorResult {
        try {
            const principalDecimals = await fetchDecimals(params.order.principalMint);

            const repay = consolidateLoanRepaymentParam(principalDecimals, params).map(
                ({ loan_vault, order, principal_mint, repayment_args }) =>
                    generate({
                        generateFunction: getRepayLoanTransaction,
                        identifier: "Repay Loan",
                        params: {
                            loan_vault,
                            principal_mint,
                            order,
                            repayment_args
                        }
                    })
            );

            const generatedTxnsRes = await Promise.all(repay);
            const generatedTxns = Result.combine(generatedTxnsRes);
            if (!generatedTxns.isOk()) return Result.err(generatedTxns);

            const ordered: OrderedTransactions = [];

            // custom txn batch generation so that repay txn will be split into sep batch
            for (const ledgerTxns of generatedTxns.unwrap()) {
                // standard ledger payments
                if (ledgerTxns.length === 1) {
                    ordered.push(...ledgerTxns.map((t) => ({ transactions: [t] })));
                }
                // early payments of principal will return an array
                else {
                    // repay txn always comes first
                    const [repayTransaction, ...editLedgerTransactions] = ledgerTxns;

                    ordered.push({ transactions: [repayTransaction] });
                    ordered.push(...editLedgerTransactions.map((t) => ({ transactions: [t] })));
                }
            }

            return Result.ok(ordered);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        allowBatchFailure: false,
        refetch: () => {
            resetLedgerAccounts();
            resetLoanApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetFeesApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Repaying Loan" };
}

function consolidateLoanRepaymentParam(principalDecimals: number, params: RepayParams): RepayLoanArgs[] {
    const groupedRepayments: { [key: string]: RepayLoanArgs } = {};
    const { order, loan, escrowNeeded } = params;

    params.payments.forEach(({ ledger, amount, overpay, repaymentType }) => {
        const key = `${loan.address}-${order.principalMint}-${order.address}`;
        if (!groupedRepayments[key]) {
            groupedRepayments[key] = {
                loan_vault: loan.address,
                principal_mint: order.principalMint,
                order: order.address,
                repayment_args: []
            };
        }

        const repayAmount = uiAmountToLamports(amount, principalDecimals);

        const amountFromWallet = escrowNeeded ? 0 : repayAmount;
        const amountFromEscrow = escrowNeeded ? repayAmount : 0;

        groupedRepayments[key].repayment_args.push({
            amount_from_escrow: amountFromEscrow,
            amount_from_wallet: amountFromWallet,
            ledger_id: ledger.ledgerId,
            overpay,
            repayment_type: repaymentType,
            full_repay: {
                full_repay: !!params.fullRepay,
                repay_from_wallet: !escrowNeeded
            }
        });
    });

    return Object.values(groupedRepayments);
}

export function useRepayZcLoanTransaction(): AbfTransactionDetails<RepayZcLoanParams> {
    const generate = useAbfGenerateTransaction();

    const { resetLoanApi, resetEscrowedApi, resetLockboxApi, resetFeesApi, resetLedgerAccounts, resetLoopApi } =
        useAbfFetches();
    const { fetchDecimals } = useDecimalsByMint();
    const getTransactionGenerationType = useGetTransactionGenerationType();

    async function getTransactionsWithParams(params: RepayZcLoanParams): AbfGeneratorResult {
        try {
            const principalDecimals = await fetchDecimals(params.order.principalMint);
            const escrowNeeded = params.escrowNeeded;

            const transactionGenerationType = await getTransactionGenerationType();

            const feeAmount = uiAmountToLamports(params.feeAmount, principalDecimals);
            const repay = generate({
                generateFunction: getRepayZcLoanTransactions,
                identifier: "Repay Loan",
                params: {
                    loan_vault: params.loan.address,
                    principal_mint: params.order.principalMint,
                    order: params.order.address,

                    repayment_args: params.payments.map(({ ledger, amount, repaymentType, overpay }) => {
                        const repayAmount = uiAmountToLamports(amount, principalDecimals);

                        const amountFromWallet = escrowNeeded ? 0 : repayAmount;
                        const amountFromEscrow = escrowNeeded ? repayAmount : 0;

                        return {
                            amount_from_escrow: amountFromEscrow,
                            amount_from_wallet: amountFromWallet,
                            ledger_id: ledger.ledgerId,
                            overpay,
                            repayment_type: repaymentType,
                            full_repay: {
                                repay_from_wallet: !escrowNeeded,
                                full_repay: params.fullRepay
                            }
                        };
                    }),
                    full_repay: {
                        repay_from_wallet: !escrowNeeded,
                        full_repay: params.fullRepay
                    },
                    fee_repayment_args: {
                        [FeeType.EarlyRepayment]: {
                            amount_from_wallet: escrowNeeded ? 0 : feeAmount,
                            amount_from_escrow: escrowNeeded ? feeAmount : 0
                        }
                    }
                },
                queryParams: { transaction_generation_type: transactionGenerationType }
            });

            if (transactionGenerationType === TransactionGenerationType.Jito) {
                const txns = await repay;

                if (txns.isOk()) {
                    return Result.ok([
                        {
                            transactions: txns.unwrap(),
                            bundle: true,
                            skipBundleSim: true
                        }
                    ]);
                }
                return Result.err(txns);
            }

            return await combineTransactionPromises([repay], { order: "parallel" });
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        allowBatchFailure: false,
        refetch: () => {
            resetLedgerAccounts();
            resetLoanApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetFeesApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Repaying Loan" };
}

export function useSyncLedgersTransaction(): AbfTransactionDetails<SyncLedgersArgs> {
    const generate = useAbfGenerateTransaction();
    const {
        resetLoanApi,
        resetEscrowedApi,
        resetLockboxApi,
        resetFeesApi,
        resetLedgerAccounts,
        resetLedgerAccountsSync
    } = useAbfFetches();

    async function getTransactionsWithParams(params: SyncLedgersArgs): AbfGeneratorResult {
        try {
            const transactions = generate({
                generateFunction: getSyncLedgersTransactions,
                params,
                identifier: "Update Payment"
            });
            return await combineTransactionPromises([transactions], { order: "parallel" });
        } catch (error) {
            return Result.err(error);
        }
    }
    const sendOptions = {
        refetch: () => {
            resetLedgerAccounts();
            resetLoanApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetFeesApi();
            resetLedgerAccountsSync();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating Payments" };
}

type SetupLoanParams = {
    setup: SetupLoanArgs;
    lockbox: LockboxAssetUpdateArgs[];
    stakedSol: StakedSolLockboxArgs | null;
};
export function useSetupLoanTransaction(): AbfTransactionDetails<SetupLoanParams> {
    const generate = useAbfGenerateTransaction();
    const generateWithSetup = useAbfGenerateTransactionWithSetup();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    const {
        resetLoanApi,
        resetLedgerAccounts,
        resetVerifyOrdersAsLoans,
        resetOrderApi,
        resetLoanRequests,
        resetLockboxApi,
        resetStakeApi
    } = useAbfFetches();

    async function getTransactionsWithParams({ setup, lockbox, stakedSol }: SetupLoanParams): AbfGeneratorResult {
        try {
            const setupLoan = generateWithSetup({
                generateFunction: getSetupLoanTransactions,
                identifier: "Create Payment",
                params: setup
            });

            const getDecimals = await setupFetchMultipleDecimals(lockbox.map((l) => l.assetMint));

            const lockboxDeposit = lockbox.length
                ? generate({
                      generateFunction: getDepositLockboxTransaction,
                      identifier: "Deposit Asset",
                      params: lockbox.map((arg) => ({
                          ...arg,
                          amount: uiAmountToLamports(arg.amount, getDecimals(arg.assetMint))
                      }))
                  })
                : emptyPromise;

            // will get ignored if user doesn't withdraw staked sol
            const stakeDeposit = stakedSol?.stakeAccounts.length
                ? generate({
                      generateFunction: getDepositLockboxStakedSolTransaction,
                      identifier: "Deposit Staked SOL",
                      params: {
                          ...stakedSol,
                          stakeAccounts: stakedSol.stakeAccounts.map(({ amountToTransfer, address }) => ({
                              address,
                              amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
                          }))
                      }
                  })
                : emptyPromise;

            const generatedTxns = await Promise.all([setupLoan, lockboxDeposit, stakeDeposit]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [setupTxn, depositTxns, stakeDepositTxns] = [
                generatedTxns[0].unwrap(),
                generatedTxns[1]?.unwrapOr([]),
                generatedTxns[2]?.unwrapOr([])
            ];

            const txns: OrderedTransactions = [
                {
                    transactions: [...setupTxn.setupTransactions, ...(depositTxns ?? []), ...(stakeDepositTxns ?? [])],
                    commitmentLevel: "confirmed"
                },
                { transactions: setupTxn.transactions, commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT }
            ];
            return Result.ok(txns);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetStakeApi();
            resetOrderApi();
            resetVerifyOrdersAsLoans();
            resetLedgerAccounts();
            resetLoanApi();
            resetLoanRequests();
            resetLockboxApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Setting up loan" };
}

export type WithdrawLoanParams = {
    order: AbfOrder;
    loan: AbfLoanVault;
    creditorAccount: string;
    debtorAccount: string;
};
export function useLoanWithdrawTransaction(): AbfTransactionDetails<WithdrawLoanParams> {
    const generate = useAbfGenerateTransaction();

    const { resetLoanApi, resetEscrowedApi, resetLockboxApi } = useAbfFetches();

    async function getTransactionsWithParams({ loan, order }: WithdrawLoanParams): AbfGeneratorResult {
        try {
            const loanWithdraw = generate({
                generateFunction: getLoanWithdrawVersionedTransaction,
                identifier: "Closing Loan",
                params: {
                    loan_vault: loan?.address,
                    order: order.address,
                    principal_mint: order?.principalMint ?? DEFAULT_PRINCIPAL_MINT,
                    collateral_mint: loan?.collateral,
                    debt_note: loan.debtNote,
                    credit_note: loan.creditNote,
                    debtor_account: loan.borrower, // set as borrower until debt tokens are tradebale
                    creditor_account: loan.lender // set as lender until debt tokens are tradebale
                }
            });

            const transactions = await combineTransactionPromises([loanWithdraw], {
                order: "sequential"
            });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetEscrowedApi();
            resetLockboxApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing Loan" };
}

export function useLoanEndTransaction(): AbfTransactionDetails<WithdrawLoanParams> {
    const generate = useAbfGenerateTransaction();

    const { resetLoanApi, resetEscrowedApi, resetLockboxApi, resetLoopApi } = useAbfFetches();

    async function getTransactionsWithParams({ loan, order }: WithdrawLoanParams): AbfGeneratorResult {
        try {
            const loanWithdraw = generate({
                generateFunction: getLoanEndVersionedTransaction,
                identifier: "Closing Loan",
                params: {
                    loan_vault: loan?.address,
                    order: order.address,
                    principal_mint: order?.principalMint ?? DEFAULT_PRINCIPAL_MINT,
                    collateral_mint: loan?.collateral,
                    debt_note: loan.debtNote,
                    credit_note: loan.creditNote,
                    debtor_account: loan.borrower, // set as borrower until debt tokens are tradebale
                    creditor_account: loan.lender // set as lender until debt tokens are tradebale
                }
            });

            return await combineTransactionPromises([loanWithdraw], {
                order: "sequential"
            });
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetEscrowedApi();
            resetLockboxApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing Loan" };
}

export function usePayFeesTransaction(): AbfTransactionDetails<PayFeeInputs[]> {
    const generate = useAbfGenerateTransaction();
    const { resetFeesApi, resetLoanApi } = useAbfFetches();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams(payFeeInputs: PayFeeInputs[]): AbfGeneratorResult {
        const getDecimals = await setupFetchMultipleDecimals(payFeeInputs.map((f) => f.feeMint));
        try {
            const pay = payFeeInputs.map((input) =>
                generate({
                    generateFunction: getPayFeeTransaction,
                    identifier: "Pay Fee",
                    params: {
                        ...input,
                        amountFromWallet: uiAmountToLamports(input.amountFromWallet, getDecimals(input.feeMint)),
                        amountFromEscrow: uiAmountToLamports(input.amountFromEscrow, getDecimals(input.feeMint))
                    }
                })
            );

            const transactions = await combineTransactionPromises(pay, { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetFeesApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Pay Fees" };
}

export interface IncreaseCreditTransactionParams extends IncreaseCreditTransactionArgs {
    decimals: number;
}
export function useIncreasePrincipalTransaction(): AbfTransactionDetails<IncreaseCreditTransactionParams> {
    const generate = useAbfGenerateTransaction();
    const { resetEscrowedApi, resetLoanApi, resetNapoleonApi, resetNapoleonPublicApi } = useAbfFetches();

    async function getTransactionsWithParams({
        increaseAmount,
        decimals,
        ...params
    }: IncreaseCreditTransactionParams): AbfGeneratorResult {
        try {
            const increasePrincipal = generate({
                generateFunction: getIncreasePrincipalTransaction,
                identifier: "Borrow more",
                params: { ...params, increaseAmount: uiAmountToLamports(increaseAmount, decimals) }
            });

            const transactions = await combineTransactionPromises([increasePrincipal], {
                order: "parallel"
            });

            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    // Added because escrow patterns above
    const sendOptions = {
        refetch: () => {
            resetEscrowedApi();
            resetLoanApi();
            resetNapoleonApi();
            resetNapoleonPublicApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Increasing borrow" };
}
