import { useEffect, useMemo } from "react";

import { PayloadAction, SerializedError, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppCookie, clearBearerToken, DEBUG_WALLET, getBearerToken, setBearerToken } from "@bridgesplit/react";
import {
    DEFAULT_PUBLIC_KEY,
    Result,
    TIME,
    base64url,
    convertAnyDate,
    generateNonce,
    parseJwt
} from "@bridgesplit/utils";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAuth0 } from "@auth0/auth0-react";

import {
    ABF_GROUP_COOKIE_PREFIX,
    AUTH0_COOKIES_PREFIX,
    SKIP_AUTH0_COOKIE,
    USER_ID_COOKIE,
    USER_WALLET_COOKIE
} from "../constants";

type AuthTokenWithExpiry = { token: string; expires: Date };
export type AuthSliceState = {
    idToken: AuthTokenWithExpiry | undefined;
    groupIdentifier: string | undefined;
    escrowNonce: string;
    idempotentKey: string;
    authError: string | null;
    skipAuth0: boolean;
    apiVersionUpdateNeeded: boolean;
};

function getAuth0Cookie(): AuthTokenWithExpiry | undefined {
    const token = getBearerToken(AUTH0_COOKIES_PREFIX);

    if (!token) return undefined;
    base64url.decode(token);
    const parsed = parseJwt(token) as { exp: number };
    if (!parsed.exp) return undefined;
    return { token, expires: convertAnyDate(parsed.exp) };
}

const initialState: AuthSliceState = {
    idToken: getAuth0Cookie(),
    groupIdentifier: getBearerToken(ABF_GROUP_COOKIE_PREFIX),
    escrowNonce: DEFAULT_PUBLIC_KEY,
    authError: null,
    apiVersionUpdateNeeded: false,
    // set once on page load
    idempotentKey: generateNonce(),
    skipAuth0: !!AppCookie.get(SKIP_AUTH0_COOKIE)
};
const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setAuthIdToken: (state, action: PayloadAction<AuthTokenWithExpiry | undefined>) => {
            state.idToken = action.payload;
        },
        resetAuthState: () => {
            return {
                idToken: undefined,
                groupIdentifier: undefined,
                escrowNonce: DEFAULT_PUBLIC_KEY,
                skipAuth0: false,
                authError: null,
                apiVersionUpdateNeeded: false,
                idempotentKey: generateNonce()
            };
        },
        setSkipAuth0: (state, action: PayloadAction<boolean>) => {
            state.skipAuth0 = action.payload;
        },
        setAuthGroupIdentifier: (state, action: PayloadAction<string | undefined>) => {
            state.groupIdentifier = action.payload;
        },
        setActiveEscrowNonce: (state, action: PayloadAction<string>) => {
            state.escrowNonce = action.payload;
        },
        setAuthError: (state, action: PayloadAction<string | null>) => {
            state.authError = action.payload;
        },
        updateApiVersionNeeded: (state, action: PayloadAction<boolean>) => {
            state.apiVersionUpdateNeeded = action.payload;
        }
    }
});

export default authSlice.reducer;

const { setActiveEscrowNonce, setAuthError, setSkipAuth0, resetAuthState } = authSlice.actions;
export const { setAuthGroupIdentifier, updateApiVersionNeeded, setAuthIdToken } = authSlice.actions;

const getSlice = (state: { authSlice: AuthSliceState }): AuthSliceState => state.authSlice;

/**
 * Set values in cookies and reducer
 * Cookie storage allows credentials to be used in authenticated RTK queries
 * Reducer storage allows credentials to be used to trigger hook re-renders
 */
export function useAuthStorage() {
    const state: AuthSliceState = useSelector(getSlice);
    const dispatch = useDispatch();

    function setGroupIdentifier(identifier: string) {
        setBearerToken(ABF_GROUP_COOKIE_PREFIX, identifier);
        dispatch(setAuthGroupIdentifier(identifier));
    }

    function clearGroupIdentifier() {
        clearBearerToken(ABF_GROUP_COOKIE_PREFIX);
        dispatch(setAuthGroupIdentifier(undefined));
    }

    function setEscrowNonce(escrowNonce: string) {
        dispatch(setActiveEscrowNonce(escrowNonce));
    }

    function setIdTokenWithExpiry(rawIdToken: string, expiresInSeconds: number | undefined) {
        if (state.skipAuth0) return;
        const halfDayFromNow = Date.now() + 1000 * TIME.HOUR * 12;
        const expires = expiresInSeconds ? new Date(expiresInSeconds * 1000) : new Date(halfDayFromNow);
        setBearerToken(AUTH0_COOKIES_PREFIX, rawIdToken, expires);
        dispatch(setAuthIdToken({ token: rawIdToken, expires }));
    }

    function resetCookies() {
        clearBearerToken(AUTH0_COOKIES_PREFIX);
        clearBearerToken(ABF_GROUP_COOKIE_PREFIX);
        AppCookie.remove(USER_WALLET_COOKIE);
        AppCookie.remove(USER_ID_COOKIE);
    }

    function _setAuthError(errorMessage: string | null) {
        dispatch(setAuthError(errorMessage));
    }

    function resetAuth() {
        dispatch(resetAuthState());
    }

    function _setSkipAuth0(skipAuth0: boolean) {
        dispatch(setSkipAuth0(skipAuth0));
        if (skipAuth0) {
            AppCookie.set(SKIP_AUTH0_COOKIE, "true");
        } else {
            AppCookie.remove(SKIP_AUTH0_COOKIE);
        }
    }

    const idToken = useMemo(
        () => (state.idToken && state.idToken.expires.getTime() > Date.now() ? state.idToken : undefined),
        [state.idToken]
    );

    return {
        state: { ...state, idToken },
        resetAuth,
        setGroupIdentifier,
        clearGroupIdentifier,
        setIdTokenWithExpiry,
        setEscrowNonce,
        resetCookies,
        setAuthError: _setAuthError,
        setSkipAuth0: _setSkipAuth0
    };
}

export function useCatchAppAuthError(error: FetchBaseQueryError | SerializedError | undefined) {
    const state: AuthSliceState = useSelector(getSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!error || state.authError) return;
        dispatch(setAuthError(Result.err(error).unwrapErrMessage()));
    }, [dispatch, error, state.authError]);
}

export function usePageLoadIdempotentKey() {
    const { state } = useAuthStorage();
    return function getKey(...seeds: string[]) {
        return state.idempotentKey + seeds.join();
    };
}

export function useAuth() {
    const wallet = useWallet();
    const { state } = useAuthStorage();
    const auth0 = useAuth0();

    const isEmailBased = auth0.isAuthenticated && !state.skipAuth0;
    const isEmailAuthenticated = isEmailBased && !!state.idToken && !!auth0.user?.email_verified;
    const isWalletBased = !isEmailBased && (wallet.connected || DEBUG_WALLET);
    const isAuthenticated = isEmailAuthenticated || isWalletBased;

    const isLoading = useMemo(() => {
        if (state.idToken) return auth0.isLoading;
        return wallet.connecting;
    }, [auth0.isLoading, state.idToken, wallet.connecting]);

    return { isAuthenticated, isEmailBased, isWalletBased, isLoading };
}
