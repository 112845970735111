import { DialectSolanaSdk } from "@dialectlabs/react-sdk-blockchain-solana";
import { Notifications } from "@dialectlabs/react-ui";
import "./dialect.css";
import { NotificationsNone } from "@mui/icons-material";
import { usePopover, PopoverWrapper, SPACING, MEDIA, IconButton } from "@bridgesplit/ui";

import { DIALECT_DAPP_ADDRESS } from "./common";
import { NAV_ICON_BUTTON_SX } from "../navbar";

export default function DialectNotificationComponent() {
    const { popoverProps, open } = usePopover();

    return (
        <DialectSolanaSdk dappAddress={DIALECT_DAPP_ADDRESS}>
            <PopoverWrapper
                {...popoverProps}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ horizontal: "right", vertical: -SPACING }}
                sx={{ [MEDIA.MD.above]: { width: "400px" }, maxHeight: "90vh", overflowY: "scroll" }}
            >
                <Notifications />
            </PopoverWrapper>
            <IconButton onClick={open} sx={NAV_ICON_BUTTON_SX} jsxIcon={<NotificationsNone />} />
        </DialectSolanaSdk>
    );
}
