import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    QueryDefinition
} from "@reduxjs/toolkit/dist/query";
import axios from "axios";
import {
    ABF_API,
    ARRAKIS_API,
    BS_METADATA_API,
    CAVEMAN_API,
    doesApiVersionMatch,
    IDENTITY_API,
    MESSAGING_API,
    MPC_API,
    NAPOLEON_API,
    ORACLES_API,
    PANDORA_API,
    PRICING_API,
    Result,
    RUBICON_API,
    RUBY_API,
    safeJSONParse,
    SYNDICATED_API,
    TYGRIS_API,
    ZEPHYR_API
} from "@bridgesplit/utils";
import { AppCookie, getBearerToken } from "@bridgesplit/react";
import {
    ParsedBsMetadata,
    BsMetadata,
    USER_WALLET_HEADER,
    USER_ID_HEADER,
    API_VERSION_HEADER
} from "@bridgesplit/abf-sdk";

import {
    ABF_GROUP_COOKIE_PREFIX,
    ABF_GROUP_HEADER,
    AUTH0_COOKIES_PREFIX,
    USER_ID_COOKIE,
    USER_WALLET_COOKIE
} from "../constants";
import { AuthSliceState, setAuthIdToken, updateApiVersionNeeded } from "./authSlice";
import { AbfUserWithPermissionsAndWallets } from "..";

export enum AccessLevel {
    NotConnected = "NOT_CONNECTED",
    Unregistered = "UNREGISTERED",
    Waitlist = "WAITLIST",
    BetaAccess = "BETA_ACCESS"
}
export interface AuthConfig {
    authLevel: AccessLevel;
}

interface HeadersResult {
    skip: boolean;
    headers: Record<string, string | undefined>;
}
export const DEFAULT_AUTH_CONFIG: AuthConfig = {
    authLevel: AccessLevel.BetaAccess
};

function getHeaders(config: AuthConfig): HeadersResult {
    const idToken = getBearerToken(AUTH0_COOKIES_PREFIX);
    const groupIdentifier = getBearerToken(ABF_GROUP_COOKIE_PREFIX);
    const userWallet = AppCookie.get(USER_WALLET_COOKIE);
    const userId = AppCookie.get(USER_ID_COOKIE);

    const skip = config.authLevel === AccessLevel.Unregistered && (!userWallet || !idToken);
    const headers: Record<string, string | undefined> = {
        Authorization: idToken ? `Bearer ${idToken}` : undefined,
        [ABF_GROUP_HEADER]: groupIdentifier,
        [USER_WALLET_HEADER]: userWallet,
        [USER_ID_HEADER]: userId
    };

    return {
        skip,
        headers
    };
}

export type AbfBaseQueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

interface EndpointDefinition {
    authConfig?: AuthConfig;
}

export const getAbfBaseQuery = (
    baseUrl: string,
    defaultAuthConfig: AuthConfig = DEFAULT_AUTH_CONFIG,
    inviteRequired = true
): AbfBaseQueryFn => {
    const rawBaseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { endpoint }) => {
            const endpointDef = endpoint as EndpointDefinition;
            const endpointAuthConfig = endpointDef.authConfig || defaultAuthConfig;
            const { headers: cookieHeaders } = getHeaders(endpointAuthConfig);

            Object.entries(cookieHeaders).forEach(([key, value]) => {
                if (value !== undefined) {
                    headers.set(key, value);
                }
            });

            return headers;
        }
    });

    return async (args, api, extraOptions) => {
        const endpoint = api.endpoint as EndpointDefinition;
        const endpointAuthConfig = endpoint.authConfig || defaultAuthConfig;
        const { skip, headers } = getHeaders(endpointAuthConfig);

        if (inviteRequired) {
            const queries = api.getState() as {
                abfUserApi: { queries: Record<string, { data: AbfUserWithPermissionsAndWallets }> };
            };

            const userBetaCodeExists = Object.entries(queries.abfUserApi.queries).some(
                ([key, value]) =>
                    (key.includes("userMe") && value.data?.beta_borrow_access) || value.data?.beta_lend_access
            );

            if (!userBetaCodeExists && skip) {
                api.abort();
            }
        }

        // prevent misfires without auth
        if (skip) {
            // clear redux cache if cookies are mismatched
            const state = api.getState() as { authSlice: AuthSliceState };
            if (!headers.Authorization && !!state.authSlice.idToken) {
                api.dispatch(setAuthIdToken(undefined));
            }
            api.abort();
        }

        const result = await rawBaseQuery(args, api, extraOptions);

        // manually reset in cases where JWT is invalid (ie from user returning after 1+ days)
        if (result.error?.data === "Invalid JWT") {
            api.dispatch(setAuthIdToken(undefined));
        }

        if (result.meta?.response) {
            const apiVersion = result.meta.response.headers.get(API_VERSION_HEADER);

            const url = result.meta.request.url;

            if (apiVersion && !doesApiVersionMatch(url, apiVersion)) {
                api.dispatch(updateApiVersionNeeded(true));
            }
        }

        if (result.error) {
            logError(result.error.data);
        }

        return result;
    };
};

const ALLOWED_ERRORS = [
    "Invalid JWT",
    "User does not have access",
    "User-Wallet: Value not in header",
    "User does not have beta access",
    "User has invalid roles"
];
function logError(error: unknown) {
    if (typeof error !== "string") return;
    for (const allowedError of ALLOWED_ERRORS) {
        if (error.toLowerCase().includes(allowedError.toLowerCase())) {
            return Result.err(error);
        }
    }
}

// serialize with header to force each query to be user specific in case of account change
export function abfSerializeQueryArgs({
    queryArgs,
    endpointName
}: {
    endpointName: string;
    queryArgs: FetchArgs | string;
}) {
    const params = JSON.stringify(queryArgs);

    return `${endpointName}(${params})`;
}

// to override query args for endpoints uncorrelated to user
export function unauthenticatedSerializeQueryArgs<T>({
    queryArgs,
    endpointName
}: {
    endpointName: string;
    queryArgs: T;
}) {
    const params = JSON.stringify(queryArgs);
    return `${endpointName}(${params})`;
}

export const abfBaseQuery = getAbfBaseQuery(ABF_API);
export const abfBaseQueryPublic = getAbfBaseQuery(ABF_API, { authLevel: AccessLevel.NotConnected });

export const syndicatedBaseQuery = getAbfBaseQuery(SYNDICATED_API);
export const pricingBaseQuery = getAbfBaseQuery(PRICING_API);
export const lockboxBaseQuery = getAbfBaseQuery(ABF_API);
export const messagingBaseQuery = getAbfBaseQuery(MESSAGING_API);
export const identityBaseQuery = getAbfBaseQuery(IDENTITY_API);

export const bsMetadataBaseQuery = getAbfBaseQuery(BS_METADATA_API, { authLevel: AccessLevel.NotConnected });

export const zephyrMetadataBaseQuery = getAbfBaseQuery(ZEPHYR_API);
export const mpcBaseQuery = getAbfBaseQuery(MPC_API);
export const cavemanBaseQuery = getAbfBaseQuery(CAVEMAN_API);
export const rubiconBaseQuery = getAbfBaseQuery(RUBICON_API);
export const tygrisBaseQuery = getAbfBaseQuery(TYGRIS_API);

export const napoleonBaseQuery = getAbfBaseQuery(NAPOLEON_API);
export const napoleonPublicBaseQuery = getAbfBaseQuery(NAPOLEON_API, { authLevel: AccessLevel.NotConnected });

export const arrakisBaseQuery = getAbfBaseQuery(ARRAKIS_API);

export const oracleBaseQuery = getAbfBaseQuery(ORACLES_API, { authLevel: AccessLevel.NotConnected }, false);
export const rubyBaseQuery = getAbfBaseQuery(RUBY_API, { authLevel: AccessLevel.NotConnected }, false);
export const pandoraBaseQuery = getAbfBaseQuery(PANDORA_API, { authLevel: AccessLevel.NotConnected }, false);

export function axiosCreateAbf(baseURL = ABF_API) {
    const headers = getHeaders(DEFAULT_AUTH_CONFIG).headers;
    return axios.create({ baseURL, headers });
}

export function convertParsedBsMetadataToBsMetadata(parsedBsMetadata: ParsedBsMetadata): BsMetadata {
    const offchainMetadata = safeJSONParse(parsedBsMetadata?.extended?.value || "", JSON.parse("{}"));
    return { ...parsedBsMetadata, offchainMetadata };
}

type EndpointWithAuth<T> = T & { authConfig?: AuthConfig };

export function buildEndpointWithAuth<Args, ResultType>(
    definition: QueryDefinition<Args, AbfBaseQueryFn, string, ResultType>,
    authConfig?: AuthConfig
): EndpointWithAuth<QueryDefinition<Args, AbfBaseQueryFn, string, ResultType>> {
    return {
        ...definition,
        authConfig
    };
}
