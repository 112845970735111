export const S3_PATH = "https://bridgesplit-app.s3.amazonaws.com";
export const S3_LOOPSCALE = "https://loopscale-site.s3.amazonaws.com";
export const S3_ILLUSTRATIONS_PATH = `${S3_PATH}/illustrations`;
export const S3_ILLUSTRATIONS_2_PATH = `${S3_PATH}/illustrations-2`;
export const S3_ILLUSTRATIONS_3_PATH = `${S3_PATH}/illustrations-3`;

export const S3_ICONS_PATH = `${S3_PATH}/icons`;

export const LOGO_URL = `${S3_PATH}/logo/500.png`;

const ILLUSTRATIONS_EXTENSION = ".png";
export const ILLUSTRATIONS = {
    prime: `${S3_ILLUSTRATIONS_PATH}/bs-prime-3d${ILLUSTRATIONS_EXTENSION}`,
    loops: `${S3_ILLUSTRATIONS_3_PATH}/loops.svg`
};

export const ICONS = {
    bills: `${S3_ICONS_PATH}/bills.svg`,
    checklist: `${S3_ICONS_PATH}/checklist.svg`,
    globe: `${S3_ICONS_PATH}/globe.svg`,
    mail: `${S3_ICONS_PATH}/mail.svg`,
    box: `${S3_ICONS_PATH}/box.png`
};

export const getScreenshotsV2 = (folder: "light" | "dark") => {
    const base = `${S3_PATH}/screenshots-2/${folder}`;
    return {
        terms: `${base}/terms.png`,
        rates: `${base}/rates.png`,
        categories: `${base}/categories.png`,
        duration: `${base}/duration.png`
    };
};

export const EMAIL_ICONS = {
    gmail: `${S3_PATH}/organizations/gmail.png`,
    outlook: `${S3_PATH}/organizations/outlook.png`
};

export const DEFAULT_AVATAR = `${S3_PATH}/avatars/default.png`;
export const SYNDICATE_AVATAR = `${S3_PATH}/avatars/syndicate.png`;

export const S3_ORGANIZATIONS_PATH = `${S3_PATH}/organizations`;
export const TELLER_LOGO = `${S3_ORGANIZATIONS_PATH}/teller.png`;
export const STRIPE_LOGO = `${S3_ORGANIZATIONS_PATH}/stripe.png`;
export const SPHERE_LOGO = `${S3_ORGANIZATIONS_PATH}/sphere.png`;
export const ORCA_LOGO = `${S3_ORGANIZATIONS_PATH}/orca-logo.png`;
export const METEORA_SVG = `${S3_ORGANIZATIONS_PATH}/meteora.svg`;

export const METEORA_LOGO = `${S3_ORGANIZATIONS_PATH}/meteora-logo.jpeg`;
export const SANCTUM_LOGO = `${S3_ORGANIZATIONS_PATH}/sanctum-logo.jpeg`;
export const SOLANA_HUB_LOGO = `${S3_ORGANIZATIONS_PATH}/solanahub-logo.png`;
export const JLP_LOGO = `${S3_PATH}/tokens/JLP.png`;
export const MARGIN_FI_LOGO = `${S3_PATH}/icons/mfi.jpg`;
export const SOLANA_LOGO = `${S3_PATH}/tokens/sol.png`;

export const LOOPSCALE_POINTS_LOGO = `${S3_LOOPSCALE}/logo/primary.png`;
