import { useMemo } from "react";

import {
    LoopscalePointSource,
    POINTS_BASIS,
    PointSource,
    StrategyExpanded,
    useCollateralPointsSourcesUtil
} from "@bridgesplit/abf-react";
import {
    Column,
    FONT_SIZES,
    Icon,
    IconWithBackground,
    MARGIN_FI_LOGO,
    RewardIcon,
    RewardImage,
    Row,
    StatColumn,
    StatProps,
    StatWithIcon,
    Text,
    Tooltip,
    useAppPalette
} from "@bridgesplit/ui";
import { formatNum, formatPercent, TIME } from "@bridgesplit/utils";
import { Divider } from "@mui/material";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { PointSourceMap, TooltipContent } from "./common";

export function StrategyRateAndRewardsBreakdown({
    strategyExpanded,
    isStat
}: {
    strategyExpanded: StrategyExpanded;
    isStat?: boolean;
}) {
    const isIdleYield = isStrategyMarginFi(strategyExpanded);
    const pointSources = isIdleYield ? [PointSource.Loopscale, PointSource.MarginFi] : [PointSource.Loopscale];

    const {
        isLoading,
        hasLoopPointMultiplierAboveBase,
        getSpecificMultipliers,
        getTruePointsPerDollarPerHour,
        getLoopscaleMultipliers
    } = useCollateralPointsSourcesUtil();
    const { secondary, prime } = useAppPalette();

    // Logic can be extracted from Strategy stats
    const { utilization, apyWeighted, totalDeployed } = useMemo(() => {
        if (!strategyExpanded) return { apyWeighted: 0, utilization: 0, totalDeployed: 0 };
        const { loanStats, externalYieldInfo } = strategyExpanded;

        const totalDeployed = loanStats.principalDeployed + loanStats.interestAccrued;

        const utilization = totalDeployed / (strategyExpanded.totalBalance + totalDeployed);

        let apyWeighted = loanStats.wAvgApy * totalDeployed;
        if (externalYieldInfo) {
            apyWeighted += externalYieldInfo.apy * externalYieldInfo.balance;
        }
        apyWeighted = apyWeighted / (totalDeployed + strategyExpanded.totalBalance);

        return { apyWeighted, utilization, totalDeployed };
    }, [strategyExpanded]);

    const pointsMap = getSpecificMultipliers(strategyExpanded.principalMetadata.assetMint);

    //Check the loopscale point soruces of the strategy, first one will always be lend, second always id
    const lendMultiplier =
        (pointsMap.get(LoopscalePointSource.Lend) ?? 1) *
        (getLoopscaleMultipliers([LoopscalePointSource.Lend])?.[0].multiplier ?? 1);

    const idleCapMultiplier = isIdleYield ? 1 : pointsMap.get(LoopscalePointSource.IdleCap) ?? 1;

    const isBoosted = hasLoopPointMultiplierAboveBase(
        [strategyExpanded.principalMetadata],
        [LoopscalePointSource.Lend, LoopscalePointSource.IdleCap]
    );
    const background = isBoosted ? prime : secondary;

    const apyFixed = strategyExpanded.loanStats.wAvgApy;
    const apyVariable = strategyExpanded?.externalYieldInfo?.apy || 0;

    const currentlyDeployedUsd = totalDeployed * strategyExpanded.principalUsdPrice;
    const baseRate = (getTruePointsPerDollarPerHour(LoopscalePointSource.IdleCap) ?? 0) * TIME.HOUR;
    const totalDailyRewards =
        lendMultiplier * idleCapMultiplier * (currentlyDeployedUsd / POINTS_BASIS) * baseRate * (TIME.DAY / TIME.HOUR);

    return (
        <Tooltip
            reverseColors
            title={
                <StrategyRateAndRewardTooltip
                    apyWeighted={apyFixed}
                    utilization={utilization}
                    lendMultiplier={lendMultiplier}
                    allocation={1 - utilization}
                    apyVariable={apyVariable}
                    idleMultiplier={idleCapMultiplier}
                    totalDailyRewards={totalDailyRewards}
                    netApy={apyWeighted}
                    isBoosted={isBoosted}
                    isIdleCapEligible={isIdleYield}
                />
            }
        >
            <Row spacing={0.5}>
                <PointSourceMap pointSources={pointSources} isLoading={isLoading} background={background} />
                <Text underline>
                    {formatPercent(apyWeighted)} {isStat ? "" : "APY"}
                </Text>
            </Row>
        </Tooltip>
    );
}

export function isStrategyMarginFi(strategyExpanded: StrategyExpanded | undefined) {
    if (!strategyExpanded) return false;
    return (
        strategyExpanded.externalYieldInfo?.externalYieldSource === ExternalYieldSource.MarginFi &&
        strategyExpanded.externalYieldInfo.balance > 0
    );
}

interface StrategyRateAndRewardTooltipProps extends TooltipLoansProps, TooltipIdleCapitalProps, TooltipSummaryProps {
    isBoosted: boolean;
    isIdleCapEligible: boolean;
}
function StrategyRateAndRewardTooltip(props: StrategyRateAndRewardTooltipProps) {
    const {
        apyWeighted,
        utilization,
        lendMultiplier,
        allocation,
        apyVariable,
        idleMultiplier,
        totalDailyRewards,
        netApy,
        isBoosted,
        isIdleCapEligible
    } = props;

    return (
        <TooltipContent>
            <TooltipLoans apyWeighted={apyWeighted} utilization={utilization} lendMultiplier={lendMultiplier} />
            <Divider />
            {isIdleCapEligible && (
                <>
                    <TooltipIdleCapital
                        allocation={allocation}
                        apyVariable={apyVariable}
                        idleMultiplier={idleMultiplier}
                    />
                    <Divider />
                </>
            )}

            <TooltipSummary totalDailyRewards={totalDailyRewards} netApy={netApy} isBoosted={isBoosted} />
        </TooltipContent>
    );
}
interface TooltipLoansProps {
    apyWeighted: number;
    utilization: number;
    lendMultiplier: number;
}

function TooltipLoans({ apyWeighted, utilization, lendMultiplier }: TooltipLoansProps) {
    const { secondary } = useAppPalette();

    const stats: StatProps[] = [
        {
            value: formatPercent(utilization),
            caption: "Utilization"
        },
        {
            value: `${lendMultiplier}x`,
            caption: "Lend boost"
        },
        {
            value: formatPercent(apyWeighted),
            caption: "APY (fixed rate)"
        }
    ];
    return (
        <Column spacing={2}>
            <Column spacing={1}>
                <Row spaceBetween>
                    <Text>Loans</Text>
                    <IconWithBackground size={FONT_SIZES.body1} sx={{ background: secondary, color: "white" }}>
                        <Icon type="logo-icon-filled" />
                    </IconWithBackground>
                </Row>
                <Row>
                    <Text color="caption" variant="caption">
                        Set low APYs to ensure high utilization and maximize your rewards
                    </Text>
                </Row>
            </Column>
            <StatColumn stats={stats} captionVariant="body1" />
        </Column>
    );
}

interface TooltipIdleCapitalProps {
    allocation: number;
    apyVariable: number;
    idleMultiplier: number;
}

function TooltipIdleCapital({ allocation, idleMultiplier, apyVariable }: TooltipIdleCapitalProps) {
    const { secondary } = useAppPalette();
    const stats: StatProps[] = [
        {
            value: formatPercent(allocation),
            caption: "Allocation"
        },
        {
            value: `${idleMultiplier}x`,
            caption: "Idle capital boost"
        },
        {
            value: formatPercent(apyVariable),
            caption: "APY (variable)"
        }
    ];
    return (
        <Column spacing={2}>
            <Column spacing={1}>
                <Row spaceBetween>
                    <Text>Idle capital</Text>
                    <Row spacing={1}>
                        <IconWithBackground size={FONT_SIZES.body1} sx={{ background: secondary, color: "white" }}>
                            <Icon type="logo-icon-filled" />
                        </IconWithBackground>
                        <RewardImage src={MARGIN_FI_LOGO} />
                    </Row>
                </Row>
                <Row>
                    <Text color="caption" variant="caption">
                        Earn mrgn rewards and variable rate APY while waiting for loans
                    </Text>
                </Row>
            </Column>
            <StatColumn stats={stats} captionVariant="body1" />
        </Column>
    );
}

interface TooltipSummaryProps {
    totalDailyRewards: number;
    netApy: number;
}
function TooltipSummary({ totalDailyRewards, netApy, isBoosted }: TooltipSummaryProps & { isBoosted: boolean }) {
    const stats: StatProps[] = [
        {
            value: (
                <StatWithIcon
                    value={formatNum(totalDailyRewards)}
                    icon={<RewardIcon hasMultiplier={isBoosted} />}
                    captionColor="body"
                />
            ),
            caption: "Total daily rewards"
        },
        {
            value: formatPercent(netApy),
            caption: "Net APY",
            textColor: netApy > 0 ? "success" : "disabled"
        }
    ];
    return <StatColumn stats={stats} captionVariant="body1" />;
}
