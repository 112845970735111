import { createApi } from "@reduxjs/toolkit/query/react";
import { filterNullableRecord } from "@bridgesplit/utils";

import { EVM_BALANCE_ROUTE, EVM_METADATA_ROUTE, EVM_NFTS_BY_OWNER_ROUTE } from "../constants";
import { abfSerializeQueryArgs, cavemanBaseQuery } from "./util";
import { EvmBalance, EvmLiteNft, EvmLiteNftRaw, EvmNftMetadataParam, EvmNftsByOwnerQuery } from "../types";

const EVM_NFTS = "EvmNfts";
const EVM_BALANCE = "EvmBalance";

export const cavemanApi = createApi({
    reducerPath: "cavemanApi",
    tagTypes: [EVM_NFTS, EVM_BALANCE],
    baseQuery: cavemanBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        evmNftsByOwner: builder.query<EvmLiteNft[], EvmNftsByOwnerQuery>({
            providesTags: [EVM_NFTS],
            query(body) {
                return {
                    url: EVM_NFTS_BY_OWNER_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: (raw: EvmLiteNftRaw[]) => raw.filter(filterNullableRecord)
        }),
        evmMetadata: builder.query<EvmLiteNft[], EvmNftMetadataParam[]>({
            providesTags: [EVM_NFTS],
            query(body) {
                return {
                    url: EVM_METADATA_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: (raw: EvmLiteNftRaw[]) => raw.filter(filterNullableRecord)
        }),
        evmBalance: builder.query<{ wei: string; uiAmount: number }, EvmBalance>({
            providesTags: [EVM_BALANCE],
            query(body) {
                return {
                    url: EVM_BALANCE_ROUTE,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const { useEvmNftsByOwnerQuery, useEvmMetadataQuery, useEvmBalanceQuery } = cavemanApi;

export function useCavemanApi() {
    return { resetEvmNfts: () => cavemanApi.util.invalidateTags([EVM_NFTS, EVM_BALANCE]) };
}
