import { PublicKey } from "@solana/web3.js";

import { MayNumber } from "../types";
import { findMinMax } from "./math";

export function formatNum(num: MayNumber, options?: { longThreshold?: number; customDecimals?: number }) {
    if (num === undefined || num === null) return "--";

    if (options?.customDecimals) {
        if (num > 0 && num < 1) {
            return num.toLocaleString([], {
                minimumSignificantDigits: options.customDecimals,
                maximumSignificantDigits: options.customDecimals
            });
        }

        return num.toLocaleString([], {
            minimumFractionDigits: options.customDecimals,
            maximumFractionDigits: options?.customDecimals
        });
    }

    if (num >= (options?.longThreshold ?? 10_000)) {
        return Intl.NumberFormat([], {
            notation: "compact",
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 4
        }).format(num);
    }
    if (num > (options?.longThreshold ?? 1_000_000)) {
        return Intl.NumberFormat([], { notation: "compact" }).format(num);
    }

    if (num > 0 && num < 1) {
        return num.toLocaleString([], { minimumSignificantDigits: 2, maximumSignificantDigits: 2 });
    }

    return num.toLocaleString([], { minimumFractionDigits: 0, maximumFractionDigits: options?.customDecimals ?? 2 });
}

export function formatStatNum(num: MayNumber, options?: { symbol: string }) {
    const postFix = options?.symbol ? ` ${options?.symbol}` : "";
    return formatNum(num, { longThreshold: 100 }) + postFix;
}

export function formatUsd(num: MayNumber) {
    if (num === undefined) return "--";
    const decimals = 2;

    if (num > 10_000) {
        const significantDigits = 4;
        return new Intl.NumberFormat([], {
            style: "currency",
            currency: "USD",
            notation: "compact",
            minimumSignificantDigits: significantDigits,
            maximumSignificantDigits: significantDigits
        }).format(num);
    }

    if (num > 0 && num < 1) {
        const minVisibleValue = 1 / 10 ** decimals;
        if (num < minVisibleValue) return `≤$${minVisibleValue}`;

        return new Intl.NumberFormat([], {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals
        }).format(num);
    }

    return new Intl.NumberFormat([], {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    }).format(num);
}

const MAX_ALLOWED_DECIMALS = 2;
export function formatTokenAmount(
    num: number | null | undefined,
    options: { decimals?: number; symbol?: string; hideSymbol?: boolean; short?: boolean }
) {
    const symbol = options.symbol ?? "";
    const postFix = symbol && !options.hideSymbol ? ` ${symbol}` : "";

    const decimals = Math.min(options.decimals ?? 2, MAX_ALLOWED_DECIMALS);

    if (num === undefined || num === null) return "--" + postFix;
    const minVisibleValue = 1 / 10 ** decimals;
    if (num < minVisibleValue && num > 0 && !!options.short) return `≤${minVisibleValue}${postFix}`;
    if (num > 0 && num < 1) {
        return formatSmallDecimals(num) + postFix;
    }
    return (
        num.toLocaleString([], {
            minimumFractionDigits: 0,
            maximumFractionDigits: Math.min(decimals, MAX_ALLOWED_DECIMALS)
        }) + postFix
    );
}

export function formatSmallDecimals(num: number) {
    const rawFormat = num.toLocaleString([], {
        minimumSignificantDigits: 3,
        maximumSignificantDigits: 3
    });
    const [whole, decimals] = rawFormat.split(".");
    if (!decimals) return rawFormat;

    let leadingZerosCount = 0;
    while (decimals[leadingZerosCount] === "0") {
        leadingZerosCount++;
    }

    if (leadingZerosCount < 3) return rawFormat;

    if (leadingZerosCount > 9) {
        return "0";
    }

    const subscriptNumber = String.fromCharCode(8320 + leadingZerosCount);

    const remainingFraction = decimals.slice(leadingZerosCount);
    return `${whole}.0${subscriptNumber}${remainingFraction}`;
}

export function formatPercent(
    num: MayNumber,
    options?: {
        skipMultiplication?: boolean;
        hideSymbol?: boolean;
        customDecimals?: number;
        includePlus?: boolean;
        minimumSignificantDigits?: number;
        maxPrecisionUi?: number; // ex 0.1 -> 0.1%
        customDigits?: number;
    }
) {
    const minimumSignificantDigits = options?.minimumSignificantDigits ?? 2;
    const percent = num && !options?.skipMultiplication ? num * 100 : num || 0;

    const isCompact = percent > 100_000;
    const symbolWithSpace = isCompact ? " %" : "%";
    const symbol = options?.hideSymbol ? "" : symbolWithSpace;

    const notation = isCompact ? "compact" : "standard";
    const prefix = options?.includePlus && num && num > 0 ? "+" : "";

    if (options?.customDigits) {
        return prefix + formatFixedDigits(percent, options.customDigits) + symbol;
    }

    if (options?.customDecimals) {
        return (
            prefix +
            percent.toLocaleString([], {
                minimumFractionDigits: options.customDecimals,
                maximumFractionDigits: options?.customDecimals,
                notation
            }) +
            symbol
        );
    }

    if (percent > 0 && percent < 1) {
        if (options?.maxPrecisionUi && percent < options?.maxPrecisionUi) {
            return prefix + `< ${options?.maxPrecisionUi.toLocaleString([])}` + symbol;
        }
        return (
            prefix +
            percent.toLocaleString([], { minimumSignificantDigits, maximumSignificantDigits: 2, notation }) +
            symbol
        );
    }

    return (
        prefix + percent.toLocaleString([], { maximumFractionDigits: 2, minimumFractionDigits: 0, notation }) + symbol
    );
}

export function formatFixedDigits(num: number, totalDigits = 3) {
    if (!num) return "0";

    const absNum = Math.abs(num);
    const minValue = 1 / 10 ** (totalDigits - 1);

    // Handle large numbers
    if (absNum >= 10 ** totalDigits) {
        return Intl.NumberFormat([], {
            notation: "compact",
            maximumSignificantDigits: totalDigits
        }).format(num);
    }

    // Handle small numbers
    if (absNum > 0 && absNum < minValue) {
        return `<${minValue}`;
    }

    // For numbers less than 1, we want to count decimal places after the first non-zero digit
    if (absNum < 1) {
        return num.toLocaleString([], {
            maximumSignificantDigits: totalDigits,
            minimumSignificantDigits: 1
        });
    }

    // Handle normal range
    return num.toLocaleString([], {
        minimumSignificantDigits: totalDigits,
        maximumSignificantDigits: totalDigits
    });
}

export function formatAddress(address: string | PublicKey | undefined | null, size = 4) {
    if (!address) return "--";
    return address.toString().slice(0, size) + "…" + address.toString().slice(-size);
}

export function formatAccountAddress(address: string | PublicKey | undefined | null, size = 4) {
    if (!address) return "--";
    return "…" + address.toString().slice(-size);
}

export function abbreviateString(val: string | undefined, maxLength = 20) {
    if (!val) return "--";
    if (val.length <= maxLength) return val;
    return val.slice(0, Math.max(maxLength - 3, 0)) + "…";
}

export function abbreviateParagraph(val: string | undefined, maxLength = 150) {
    if (!val || val.length <= maxLength) return val;

    const words = val.split(" ");
    let abbreviated = "";

    for (let i = 0; i < words.length; i++) {
        if ((abbreviated + words[i]).length > maxLength) {
            abbreviated += "…";
            break;
        }
        abbreviated += (i === 0 ? "" : " ") + words[i];
    }

    return abbreviated;
}

export function extractDomain(url: string | undefined): string | undefined {
    if (!url) return undefined;
    const pattern = /^(?:https?:\/\/)?(?:www\.)?([^?#]+)/;

    const matches = url.match(pattern);

    if (matches && matches[1]) {
        return matches[1].replace("/", "");
    }

    return undefined;
}

type FormatMinMaxOptions = {
    symbol: string;
    fallback?: string;
    formatter?: (val: number | undefined) => string;
    includeSpace?: boolean;
};
export function formatMinMax({
    min,
    max,
    symbol,
    fallback,
    formatter: formatterOverride,
    includeSpace
}: {
    min: number | string | undefined;
    max: number | string | undefined;
} & FormatMinMaxOptions) {
    const isPercent = symbol === "%";
    const symbolWithSpace = isPercent ? symbol : " " + symbol;

    const format = (inputVal: number | undefined) => {
        if (formatterOverride) {
            return formatterOverride(inputVal);
        }
        if (isPercent) {
            return formatPercent(inputVal, { hideSymbol: true });
        }
        return formatNum(inputVal);
    };

    const [fMin, fMax] = [typeof min === "string" ? min : format(min), typeof max === "string" ? max : format(max)];

    if (!min && !max) {
        if (fallback) return fallback;
        return `--${symbolWithSpace}`;
    }

    const space = includeSpace ? " " : "";
    if (min && !max) return fMin + symbolWithSpace;
    if (!min && max) return fMax + symbolWithSpace;
    if (min === max) return fMin + symbolWithSpace;
    return `${fMin}${space}-${space}${fMax}${symbolWithSpace}`;
}

export function formatMinMaxArray({ values, ...options }: { values: number[] | undefined } & FormatMinMaxOptions) {
    const { min, max } = findMinMax(values, (num) => num);
    return formatMinMax({ min, max, ...options });
}

export function formatPercentArray(values: number[] | undefined) {
    return formatMinMaxArray({
        values,
        formatter: (v) => formatPercent(v, { hideSymbol: true }),
        symbol: "%",
        fallback: "0%"
    });
}

export function safeJSONParse(value: string, fallback: object) {
    try {
        return JSON.parse(value);
    } catch (error) {
        return fallback;
    }
}

export function camelCaseToTitleCase(input: string) {
    // Handle empty input
    if (!input) {
        return "";
    }

    // Split the input string by capital letters
    const words = input.replace("_", " ").split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join with spaces
    const titleCase = words.map((word, i) => (i === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join(" ");

    return titleCase;
}

export function snakeCaseToTitleCase(input: string) {
    // Handle empty input
    if (!input) {
        return "";
    }

    // Split the string into words using underscore as a delimiter
    const words = input.split("_");

    // Capitalize the first letter of each word
    const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space
    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString.replace("Url", "URL").replace("Id", "ID");
}

export function formatBytes(bytes: number | undefined): string {
    const sizes = ["B", "KB", "MB", "GB", "TB"];

    if (!bytes) return "0B";

    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
    return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + " " + sizes[i];
}

export function getDeterministicIndexFromSeed(seed: string, max: number): number {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
        hash = (hash << 5) - hash + seed.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash) % max;
}
