import { StatColumn, StatProps } from "@bridgesplit/ui";
import { BsMetaUtil, isValidLeverage } from "@bridgesplit/abf-react";
import { formatPercent, stringUiPercentToDecimals } from "@bridgesplit/utils";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";
import { useTransactionFeeStat } from "app/components/transactions";
import { TransactionTypes } from "app/types";

import { HealthText } from "../../common";
import { useLoopHealth } from "./util";
import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";

export default function WindStats() {
    const { quote, form, isQuoteFetching, externalQuote } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const variant = "body2" as const;

    const health = useLoopHealth();

    const transactionFeeStat = useTransactionFeeStat({
        variant: variant,
        transactionType: TransactionTypes.EarnBorrow
    });

    if (!form.collateralAmount || !isValidLeverage(form.multiplier) || !externalQuote) return null;

    const stats: StatProps[] = [
        {
            caption: "Position size",
            value: BsMetaUtil.formatAmount(loopExpanded?.collateralToken, quote?.leveragedCollateralAmount)
        },
        {
            caption: "Health",
            value: <HealthText variant={variant} health={health?.healthRatio} />
        },
        {
            caption: "Loan to value",
            value: formatPercent(health?.ltv)
        },
        {
            caption: "Borrowed",
            value: BsMetaUtil.formatAmount(loopExpanded?.principalToken, quote?.principalAmount)
        },
        {
            caption: "Borrow APY",
            value: formatPercent(quote?.bestQuote.apy)
        },
        transactionFeeStat
    ];

    if (externalQuote?.type === LoopRoutePlatform.Jupiter) {
        const uiPercent = stringUiPercentToDecimals(externalQuote.quote.priceImpactPct);
        stats.push({
            caption: "Price impact",
            value: formatPercent(uiPercent, { maxPrecisionUi: 0.01 })
        });
    }

    return <StatColumn variant={variant} loading={isQuoteFetching} stats={stats} />;
}
