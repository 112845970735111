import { Column, Icon, Row, Text, TextButton, TextExternalLink } from "@bridgesplit/ui";

import { OnboardingBullet } from "./constants";

export function GuideHeader({
    title,
    description,
    close
}: {
    title: string;
    description?: string;
    close?: () => void;
}) {
    return (
        <Column spacing={1} alignItems="center" textAlign="center">
            <Row>
                <Text color="secondary">
                    <Icon type="logo" />
                </Text>
                {close && (
                    <TextButton onClick={close}>
                        <Icon type="reject" />
                    </TextButton>
                )}
            </Row>
            <Column textAlign="center" alignItems="center" spacing={0.5}>
                <Text variant="h2">{title}</Text>
                {description && (
                    <Text variant="body2" color="caption">
                        {description}
                    </Text>
                )}
            </Column>
        </Column>
    );
}

export function GuideBullets({ bullets, iconSize = "30px" }: { bullets: OnboardingBullet[]; iconSize?: string }) {
    return (
        <Column spacing={1.5}>
            {bullets.map(({ Icon, header, text }, i) => (
                <Column spacing={0.5} key={i}>
                    <Row>
                        <Text sx={{ width: iconSize }} color="secondary">
                            <Icon />
                        </Text>
                        <Text>{header}</Text>
                    </Row>
                    <Text sx={{ ml: iconSize }} color="caption" variant="body2">
                        {text}
                    </Text>
                </Column>
            ))}
        </Column>
    );
}

export function GuideFooter({ docs }: { docs: string }) {
    return (
        <Text sx={{ display: "inline-flex", gap: 0.5 }} color="caption" variant="body2">
            Still have questions?
            <TextExternalLink href={docs}>Read our docs</TextExternalLink>
        </Text>
    );
}
