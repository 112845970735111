import { ReactNode } from "react";

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { colorToAlpha, convertAnyDate, getUnixTs } from "@bridgesplit/utils";
import { Stack, keyframes, useMediaQuery } from "@mui/material";

import { ShadowCard } from "../containers";
import { SPACING, useAppPalette } from "../../theme";
import { EmptyPlaceholder } from "../misc";
import { MEDIA } from "../../util";

type NumberKeys<T> = {
    [K in keyof T]: T[K] extends number | undefined ? K : never;
}[keyof T];

type Props<T extends { date: number } & Record<string, number | undefined>> = {
    data: T[] | undefined;
    keys: { key: NumberKeys<T> & string; color?: string }[];
    aspectRatio?: number;
    height?: number;
    tooltip: (point: T) => ReactNode;
    formatXAxis?: (date: number) => string;
    formatYAxis?: (price: number) => string;
    marginX?: number;
    marginY?: number;
    loading?: boolean;
    minTickGap?: number;
};

export default function PriceLineChartWithAxis<T extends { date: number }>({
    data: rawData,
    aspectRatio,
    tooltip,
    formatXAxis,
    formatYAxis,
    marginX = 1,
    marginY = 2,
    loading,
    minTickGap,
    height,
    keys
}: Props<T>) {
    const { primary, paperBackground } = useAppPalette();
    const isMobile = useMediaQuery(MEDIA.LG.below);

    const skeltonData = Array(10)
        .fill(null)
        .map((_, i): T => {
            const partial = { date: getUnixTs() + i * 10 } as Partial<T>;
            keys.forEach(({ key }) => {
                const increasingNumber = (9 - i) * 10 + (i % 2 === 0 ? 5 : 10);
                partial[key] = increasingNumber as T[typeof key];
            });

            return partial as T;
        });

    const isLoading = !rawData || loading;
    const data = !isLoading ? rawData?.sort((a, b) => a.date - b.date) : skeltonData;

    const flatData = rawData
        ?.map((d) =>
            Object.entries(d)
                .filter(([key]) => keys.some(({ key: k }) => k === key))
                .map(([_, value]) => value)
        )
        .flat();

    const mx = SPACING * marginX;
    const my = SPACING * marginY;

    // the only way to get consistent x axis behavior is by reversing data+x axis
    const chartData = data.reverse();

    const longestLabel = flatData?.reduce((prev, curr) => Math.max(formatYAxis?.(curr).length ?? 0, prev), 0) ?? 0;

    if (rawData?.length === 0) return <EmptyPlaceholder header="No data" />;

    return (
        <Stack
            sx={{
                // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
                width: "100%",
                height: height + "px",
                position: "relative",
                ".area-chart": { animation: isLoading ? `${pulse} 1s ease infinite` : undefined }
            }}
        >
            <Stack sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                <ResponsiveContainer height={height} width="100%" aspect={aspectRatio}>
                    <AreaChart margin={{ top: my, bottom: my, left: mx, right: mx }} data={chartData}>
                        <defs>
                            {keys.map(({ color, key }) => (
                                <linearGradient key={key} id={"color" + key} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={color ?? primary} stopOpacity={0.4} />
                                    <stop offset="95%" stopColor={color ?? primary} stopOpacity={0} />
                                </linearGradient>
                            ))}
                        </defs>
                        {!isLoading && (
                            <XAxis
                                reversed
                                tickMargin={my}
                                minTickGap={minTickGap ?? my}
                                fontSize={14}
                                tickFormatter={(v) =>
                                    formatXAxis
                                        ? formatXAxis(v)
                                        : convertAnyDate(v).toLocaleString([], { month: "short", year: "numeric" })
                                }
                                axisLine={false}
                                tickLine={false}
                                dataKey="date"
                            />
                        )}
                        {!isLoading && !isMobile && (
                            <YAxis
                                width={longestLabel * 14 + 2}
                                minTickGap={mx}
                                tickMargin={mx}
                                fontSize={14}
                                tickFormatter={formatYAxis}
                                orientation="right"
                                axisLine={false}
                                tickLine={false}
                            />
                        )}

                        <CartesianGrid opacity={0.3} vertical={false} />
                        {!isLoading && (
                            <Tooltip
                                content={({ active, payload }: TooltipProps<number, number>) => {
                                    const point = payload?.[0]?.payload as T;

                                    if (active) {
                                        return (
                                            <ShadowCard sx={{ p: 1, backgroundColor: paperBackground }}>
                                                {tooltip(point)}
                                            </ShadowCard>
                                        );
                                    }
                                    return null;
                                }}
                            />
                        )}

                        {keys.map(({ key, color }, i) => (
                            <Area
                                key={i}
                                className="area-chart"
                                dot={false}
                                isAnimationActive={false}
                                strokeWidth={2}
                                dataKey={key}
                                stroke={colorToAlpha(color ?? primary, 0.5)}
                                fill={`url(#color${key})`}
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </Stack>
        </Stack>
    );
}

const pulse = keyframes`
0% {
    opacity: 0.5;
}
50% {
    opacity: 0.2;
}
100%{
    opacity: 0.5;
}
`;
