import { OutlinedCard } from "@bridgesplit/ui";

import AccessWaitlistContent from "./AccessWaitlistContent";

export default function AccessWaitlistCard() {
    return (
        <OutlinedCard padding={3} spacing={4}>
            <AccessWaitlistContent showNotifyButton />
        </OutlinedCard>
    );
}
