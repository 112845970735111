import { ENV_DETAILS } from "@bridgesplit/utils";

// include client to prevent bad store of cookies
export const AUTH0_COOKIES_PREFIX = "ABF_AUTH_0" + ENV_DETAILS.authClient;
export const ABF_GROUP_COOKIE_PREFIX = "ABF_GROUP" + ENV_DETAILS.authClient;
export const USER_WALLET_COOKIE = "USER_WALLET";
export const USER_ID_COOKIE = "USER_ID";

export const ABF_GROUP_HEADER = "organization";
export const IDEMPOTENT_KEY_HEADER = "Idempotent-Key";
export const SKIP_AUTH0_COOKIE = "SkipAuth0";
