import { Icon, Row, StatColumn, StatProps, Text, TextSkeleton, TextVariant, TooltipText } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useMarketContext } from "../common";
import { LiquidationPrice, useHealthColor } from "../../common";
import { useHealthRatio, useLiquidationPrice } from "./util";

export default function BorrowHealthStats({ variant }: { variant: TextVariant }) {
    const { healthRatio, liquidationThreshold } = useHealthRatio();

    const liquidationDetails = useLiquidationPrice();

    const stats: StatProps[] = [
        {
            value: <HealthFactor variant={variant} healthRatio={healthRatio} />,
            caption: COPY.HEALTH_FACTOR_TERM,
            tooltip: COPY.HEALTH_FACTOR_TOOLTIP
        },
        {
            value: <BorrowLiquidationPrice liquidationDetails={liquidationDetails} variant={variant} />,
            caption: COPY.LIQUIDATION_PRICE_TERM,
            tooltip: COPY.LIQUIDATION_PRICE_TOOLTIP
        },
        {
            value: formatPercent(liquidationThreshold),
            caption: COPY.LIQUIDATION_THRESHOLD_TERM,
            tooltip: COPY.LIQUIDATION_THRESHOLD_TOOLTIP
        }
    ];

    return <StatColumn captionVariant={variant} variant={variant} spacing={0.5} stats={stats} />;
}

function HealthFactor({ variant, healthRatio }: { variant: TextVariant; healthRatio: number }) {
    const healthColor = useHealthColor()(healthRatio);
    const { quoteFetching } = useMarketBorrowContext();

    return (
        <Row spacing={1}>
            <Text loading={quoteFetching} loadingWidth="70px" variant={variant} sx={{ color: healthColor }}>
                {formatPercent(healthRatio, { customDecimals: 2 })}
            </Text>

            {!healthRatio && !quoteFetching && (
                <TooltipText variant={variant} icon={false} helpText="Insufficient collateral" color="error">
                    <Icon type="warning" />
                </TooltipText>
            )}
        </Row>
    );
}

function BorrowLiquidationPrice({
    variant,
    liquidationDetails
}: {
    variant: TextVariant;
    liquidationDetails: ReturnType<typeof useLiquidationPrice>;
}) {
    const { activeUserCollateral, quoteFetching } = useMarketBorrowContext();
    const { token } = useMarketContext();

    const { liquidationPrice, percentChange } = liquidationDetails;

    if (quoteFetching) return <TextSkeleton variant={variant} />;

    return (
        <LiquidationPrice
            variant={variant}
            principal={token}
            collateral={activeUserCollateral?.metadata}
            liquidationPrice={liquidationPrice}
            percentChange={percentChange}
        />
    );
}
