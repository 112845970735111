import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface CountryCode {
    name: string;
    dialCode: string;
    code: string;
    flag: string;
}

interface ISO3166SubRegion {
    code: string;
    name: string;
}
interface ISO3166Region {
    name: string;
    alpha2: string;
    alpha3: string;
    numeric: string;
    regions: ISO3166SubRegion[];
}

export const DEFAULT_ISO3166_VALUE = "USA";

export const DEFAULT_COUNTRY_CODE: CountryCode = {
    name: "United States",
    dialCode: "+1",
    code: "US",
    flag: "🇺🇸"
};

export const staticApi = createApi({
    reducerPath: "staticApi",
    baseQuery: fetchBaseQuery({ baseUrl: "https://bridgesplit-app.s3.amazonaws.com/dev" }),
    endpoints: (builder) => ({
        countryCodes: builder.query<CountryCode[], void>({
            query() {
                return {
                    url: "country-codes.json",
                    method: "GET"
                };
            }
        }),
        iso3166Regions: builder.query<ISO3166Region[], void>({
            query() {
                return {
                    url: "iso3166.json",
                    method: "GET"
                };
            }
        })
    })
});

export const { useCountryCodesQuery, useIso3166RegionsQuery } = staticApi;
