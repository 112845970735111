import { ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { STRATEGY_TEMPLATE_ALL_IDENTIFIER } from "@bridgesplit/abf-react";

export type MarketLendForm = {
    collateral: string[];
    amount?: number;
    yieldSource: ExternalYieldSource;
    templateId: string;
    templateApyInitializedForMint: string | null;
    collateralInitializedForMint: string | null;
    presetToApy: Map<string, number | undefined>; // preset id => val
};

export const initialLendForm: MarketLendForm = {
    presetToApy: new Map(),
    collateral: [],
    templateApyInitializedForMint: null,
    collateralInitializedForMint: null,
    yieldSource: ExternalYieldSource.MarginFi,
    templateId: STRATEGY_TEMPLATE_ALL_IDENTIFIER
};
