import { useCallback, useMemo } from "react";

import {
    IS_LOCAL_NX_DEV,
    NullableRecord,
    bpsToUiDecimals,
    filterNullableRecord,
    greaterThan,
    groupArrayElementsBy,
    lamportsToUiAmount,
    removeDuplicatesByProperty,
    roundDownToDecimals,
    uiAmountToLamports
} from "@bridgesplit/utils";
import { BsMetadata, DurationUnit, getDurationInSeconds } from "@bridgesplit/abf-sdk";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import { BsMetaUtil, useAbfTypesToUiConverter, useBsPrincipalTokens } from "../utils";
import {
    CollateralWithPreset,
    MarketExpanded,
    PresetStrategyTerms,
    MaxQuoteFromCollateralAmount,
    MarketDetailStats,
    BestQuote,
    Collateral,
    CollateralWithMaxQuote,
    AbfLoanExpanded,
    SellLoanQuote,
    MarketPrincipalStats,
    RefinanceInfoParams,
    EstimatedRefinanceInfo,
    BorrowCap
} from "../types";
import {
    useTokenListQuery,
    useAllMarketPrincipalStatsQuery,
    useBestQuotesQuery,
    useBorrowCapsQuery,
    useCustodianQuotesQuery,
    useMarketPrincipalStatsByMintQuery,
    useMarketQuotesQuery,
    useMarketStatsQuery,
    useMaxQuotesQuery,
    usePresetPrincipalByMintsQuery,
    useRefinanceInfoQuery,
    useSellQuoteQuery,
    useStrategyPresetsQuery
} from "../reducers";
import { useOraclePrices } from "./pricing";
import { useExternalYieldVaults } from "./external-yield";

export function useMarkets(options?: { skip?: boolean }) {
    const { tokens } = useBsPrincipalTokens();

    const mints = tokens?.map((t) => t.assetMint);
    const { data: marketLoanStatsQuery, isLoading: loanLoading } = useAllMarketPrincipalStatsQuery(undefined, {
        skip: options?.skip
    });
    const { data: strategyStatsQuery, isLoading: strategyLoading } = useMarketStatsQuery(
        { principal: mints ?? [] },
        { skip: !mints || options?.skip }
    );

    const { getBorrowCap, isLoading: borrowCapsLoading } = useMarketBorrowCaps(mints);

    const { getOracle } = useOraclePrices(mints);

    const { convertMarketPrincipalStats, convertStrategyStats } = useAbfTypesToUiConverter(mints);
    const principalStatsMap = useMemo(
        () => (marketLoanStatsQuery ? new Map(Object.entries(marketLoanStatsQuery)) : undefined),
        [marketLoanStatsQuery]
    );
    const strategyMap = useMemo(
        () => (strategyStatsQuery ? new Map(Object.entries(strategyStatsQuery)) : undefined),
        [strategyStatsQuery]
    );

    const { getYieldVault, isLoading: yieldVaultLoading } = useExternalYieldVaults();

    const isLoading = strategyLoading || loanLoading || !tokens || borrowCapsLoading || yieldVaultLoading;

    const data = tokens
        ?.map((metadata): NullableRecord<MarketExpanded> => {
            const usdPrice = getOracle(metadata.assetMint)?.usdPrice ?? null;
            const strategyStats = strategyMap?.get(metadata.assetMint) ?? { totalDeposits: 0, durationToMinApy: [] };

            // api will omit mints without loans but these shouldn't be filtered out
            const principalStats: MarketPrincipalStats = principalStatsMap?.get(metadata.assetMint) ?? {
                principalOriginated: 0,
                principalUtilized: 0,
                salesVolume: 0
            };
            const borrowCap = getBorrowCap(metadata.assetMint);

            return {
                strategyStats: strategyStats ? convertStrategyStats(metadata.assetMint, strategyStats) : undefined,
                principalStats: convertMarketPrincipalStats(metadata.assetMint, principalStats),
                borrowCap,
                metadata,
                usdPrice,
                yieldVault: getYieldVault(metadata.assetMint)
            };
        })
        .filter(filterNullableRecord)
        .sort(
            (a, b) =>
                (b.usdPrice ?? 0) * (b.strategyStats.totalDeposits + b.principalStats.principalOriginated) -
                (a.usdPrice ?? 0) * (a.strategyStats.totalDeposits + a.principalStats.principalOriginated)
        );

    return { data: isLoading ? undefined : data, isLoading };
}

export function useMarketStats(mint: string | undefined, options?: { skip?: boolean }) {
    const { data: principalStats, isLoading: principalStatsLoading } = useMarketPrincipalStatsByMintQuery(
        mint ?? skipToken,
        { skip: !mint || options?.skip }
    );
    const { data: strategyStatsResponse, isLoading: strategyLoading } = useMarketStatsQuery(
        {
            principal: mint ? [mint] : [],
            force: true
        },
        { skip: !mint || options?.skip }
    );
    const strategyStats = mint ? strategyStatsResponse?.[mint] : undefined;

    // pass empty array since we already have metadata
    const { convertMarketPrincipalStats, convertStrategyStats } = useAbfTypesToUiConverter([mint]);

    const isLoading = principalStatsLoading || strategyLoading;
    const data = useMemo((): MarketDetailStats | undefined => {
        if (!strategyStats || isLoading || !principalStats || !mint) return undefined;
        return {
            strategyStats: convertStrategyStats(mint, strategyStats),
            principalStats: convertMarketPrincipalStats(mint, principalStats)
        };
    }, [convertMarketPrincipalStats, convertStrategyStats, isLoading, mint, principalStats, strategyStats]);

    return { data, isLoading };
}

export function useMinPrincipalDeposit(principalMetadata: BsMetadata | undefined) {
    const { data: rawData } = usePresetPrincipalByMintsQuery(
        principalMetadata ? { principalMints: [principalMetadata.assetMint] } : skipToken,
        { skip: !principalMetadata?.assetMint }
    );
    const lamportAmount = principalMetadata ? rawData?.[principalMetadata?.assetMint] : undefined;
    const uiAmount = lamportAmount ? lamportsToUiAmount(lamportAmount, principalMetadata?.decimals) : undefined;
    return { uiAmount, lamportAmount };
}

export function useMarketBorrowCaps(principalMints: string[] | undefined) {
    const { data: rawData, isLoading } = useBorrowCapsQuery(principalMints ?? skipToken, {
        skip: !principalMints?.length
    });

    const { convertBorrowCap } = useAbfTypesToUiConverter(principalMints);

    const data = useMemo(() => {
        return rawData?.map((cap) => convertBorrowCap(cap));
    }, [rawData, convertBorrowCap]);

    const mintToCap = useMemoizedKeyMap(data, (m) => m.principalMint);

    function getBorrowCap(mint: string | undefined) {
        if (!mint || !mintToCap) return undefined;
        return mintToCap.get(mint);
    }

    return { data, getBorrowCap, isLoading };
}

export function useSupportedCollateral(principalMint: string | undefined, options?: { skip?: boolean }) {
    const { data: tokens } = useTokenListQuery(undefined, { skip: options?.skip });
    return tokens?.filter((t) => t.isCollateral && t.assetMint !== principalMint);
}

type MarketQuoteParams = {
    custodianIdentifier: string | undefined;
    collateralMints: string[] | undefined;
    principalMint: string | undefined;
    minPrincipalAmountLamports?: number;
    limit?: number;
    offset?: number;
    preset: PresetStrategyTerms | undefined;
};

function useQuotes(
    {
        collateralMints,
        principalMint,
        minPrincipalAmountLamports,
        limit = 1000,
        offset = 0,
        preset,
        custodianIdentifier
    }: MarketQuoteParams,
    options?: { skip?: boolean }
) {
    const commonParams =
        principalMint && preset
            ? {
                  principal: principalMint,
                  minPrincipalAmount: minPrincipalAmountLamports,
                  duration: preset.duration,
                  durationType: preset.durationType,
                  limit,
                  offset
              }
            : undefined;

    const skip = !principalMint || !preset || options?.skip;
    const marketQuery = useMarketQuotesQuery(
        commonParams && collateralMints
            ? {
                  ...commonParams,
                  collateral: collateralMints
              }
            : skipToken,
        { skip: skip || !collateralMints?.length }
    );

    const custodianQuery = useCustodianQuotesQuery(
        commonParams && custodianIdentifier
            ? {
                  ...commonParams,
                  custodian: custodianIdentifier
              }
            : skipToken,
        { skip: skip || !custodianIdentifier }
    );

    return custodianIdentifier ? custodianQuery : marketQuery;
}

export function useOrderbookQuotes(params: MarketQuoteParams) {
    const { principalMint } = params;

    const { data: quotes, isLoading, isFetching } = useQuotes(params);
    const { convertOrderbookQuote } = useAbfTypesToUiConverter([principalMint]);
    const data = useMemo(() => {
        if (!quotes || !principalMint) return undefined;
        return quotes?.map((q) => convertOrderbookQuote(q, principalMint)).flat();
    }, [convertOrderbookQuote, principalMint, quotes]);

    return { data, isLoading, isFetching };
}

export function usePresets(collateralMints?: string[]) {
    // filter in FE to reduce duplicate queries
    const { data } = useStrategyPresetsQuery({ collateralMints: undefined });

    const allowedMints = useMemo(() => new Set(collateralMints), [collateralMints]);

    const { convertPreset } = useAbfTypesToUiConverter([]);

    const allPresets = useMemo(() => {
        if (!data) return undefined;
        return Object.values(data)
            .flat()
            .map(convertPreset)
            .filter((term) => (collateralMints ? allowedMints.has(term.offerTerms.collateralMint) : true))
            .sort(
                (a, b) =>
                    getDurationInSeconds(a.strategyTerms.duration, a.strategyTerms.durationType) -
                    getDurationInSeconds(b.strategyTerms.duration, b.strategyTerms.durationType)
            );
    }, [allowedMints, collateralMints, convertPreset, data]);

    const presets = useMemo(() => {
        if (!allPresets) return undefined;

        return removeDuplicatesByProperty(
            allPresets.map((s) => s.strategyTerms),
            "presetStrategyIdentifier"
        );
    }, [allPresets]);

    return { presets, allPresets };
}

type MaxQuoteParams = {
    duration: number | undefined;
    durationType: DurationUnit | undefined;
    principalMint: string | undefined;
    collateralToLamportAmounts: Map<string, number> | undefined;
    skip?: boolean;
};
function useMaxQuotes({ duration, durationType, collateralToLamportAmounts, principalMint, skip }: MaxQuoteParams) {
    return useMaxQuotesQuery(
        duration !== undefined && durationType !== undefined && principalMint && collateralToLamportAmounts
            ? {
                  duration,
                  durationType,
                  principal: principalMint,
                  collateralToAmount: Object.fromEntries(collateralToLamportAmounts.entries())
              }
            : skipToken,
        {
            skip:
                (duration === undefined && durationType === undefined) ||
                !principalMint ||
                !collateralToLamportAmounts?.size ||
                skip
        }
    );
}

export function useMaxQuotesForCollateral({
    principalMint,
    duration,
    durationType,
    collateral,
    skip
}: {
    duration: number | undefined;
    durationType: DurationUnit | undefined;
    principalMint: string | undefined;
    collateral: Collateral[] | undefined;
    skip?: boolean;
}) {
    const collateralToLamportAmounts = useMemo(() => {
        return collateral?.reduce((map, curr) => {
            const amountLamports = uiAmountToLamports(curr.amount, curr.metadata.decimals);
            if (!amountLamports) return map;
            // find max balance for given mint (ie. staked sol)
            const prev = map.get(curr.mint) ?? 0;
            const maxBalance = Math.max(prev, amountLamports);

            // only query if balance
            if (maxBalance) {
                map.set(curr.mint, maxBalance);
            }
            return map;
        }, new Map<string, number>());
    }, [collateral]);

    const {
        currentData: maxQuotes,
        isLoading: quotesLoading,
        isFetching
    } = useMaxQuotes({ collateralToLamportAmounts, duration, durationType, principalMint, skip });

    const principalPriceUsd = useOraclePrices([principalMint]).getUsdPrice(principalMint);
    const isLoading = principalPriceUsd === undefined || quotesLoading;

    const { convertOfferQuote, getDecimals } = useAbfTypesToUiConverter([principalMint]);

    const collateralToMaxQuote = useMemoizedKeyMap(maxQuotes, (q) => q.collateralMint);

    const principalDecimals = getDecimals(principalMint);

    const data = useMemo(() => {
        if (!collateral || !principalMint || (!collateralToMaxQuote && collateralToLamportAmounts?.size))
            return undefined;
        return collateral
            .map((asset): CollateralWithMaxQuote => {
                const quoteRaw = collateralToMaxQuote?.get(asset.mint);
                const quote = quoteRaw ? convertOfferQuote(quoteRaw, principalMint) : quoteRaw;

                let collateralPriceUsd = asset.usdPrice;

                // if best quote has a min price, use it relative to principal USD price
                if (!collateralPriceUsd && quote?.minPrice && principalPriceUsd) {
                    collateralPriceUsd = principalPriceUsd * quote.minPrice;
                }

                if (!quote) {
                    return { ...asset, maxQuote: undefined };
                }

                const collateralUsd = collateralPriceUsd ? asset.amount * collateralPriceUsd : asset.usdValue;

                if (!principalPriceUsd || !collateralUsd) {
                    return { ...asset, maxQuote: { ...quote, maxBorrow: 0 } };
                }

                // max borrow needs to be rounded down manually since LTV might not be evenly divisible
                const maxBorrowFromQuote = roundDownToDecimals(
                    collateralUsd * (1 / principalPriceUsd) * quote.ltv,
                    principalDecimals
                );

                const maxBorrow = Math.min(quote.principalAvailable, maxBorrowFromQuote);

                const maxQuote: MaxQuoteFromCollateralAmount = { ...quote, maxBorrow };

                return { ...asset, maxQuote };
            })
            .sort((a, b) => {
                const maxBorrowSort = (b.maxQuote?.maxBorrow ?? 0) - (a.maxQuote?.maxBorrow ?? 0);
                if (maxBorrowSort !== 0) {
                    return maxBorrowSort;
                }
                const balanceSort = b.amount - a.amount;
                if (balanceSort !== 0) {
                    return balanceSort;
                }
                return BsMetaUtil.getSymbolUnique(a.metadata).localeCompare(BsMetaUtil.getSymbolUnique(b.metadata));
            });
    }, [
        collateral,
        principalMint,
        collateralToMaxQuote,
        collateralToLamportAmounts?.size,
        convertOfferQuote,
        principalPriceUsd,
        principalDecimals
    ]);

    return { data, isLoading, isFetching };
}

type BestQuoteParams = {
    collateralMints: string[] | undefined;
    principalMint: string | undefined;
    presets: PresetStrategyTerms[] | undefined;
    minPrincipalAmountLamports: number | undefined;
    showSelf?: boolean;
    skip?: boolean;
};
export function useBestQuotes({
    collateralMints,
    principalMint,
    presets,
    minPrincipalAmountLamports,
    showSelf,
    skip
}: BestQuoteParams) {
    // sort to prevent double queries for diff ordred mints
    const sortedCollateralMints = collateralMints ? [...collateralMints].sort((a, b) => b.localeCompare(a)) : undefined;

    const { data, isLoading, isFetching } = useBestQuotesQuery(
        presets && principalMint && sortedCollateralMints
            ? {
                  durations: removeDuplicatesByProperty(presets, "presetStrategyIdentifier").map(
                      ({ duration, durationType }) => ({ duration, durationType })
                  ),
                  collateralMints: sortedCollateralMints,
                  principalMint,
                  minPrincipalAmount: minPrincipalAmountLamports || 0,
                  showSelf
              }
            : skipToken,
        {
            skip: !presets?.length || !principalMint || !sortedCollateralMints?.length || skip,
            pollingInterval: IS_LOCAL_NX_DEV ? undefined : 15_000
        }
    );

    const { convertOfferQuote } = useAbfTypesToUiConverter([principalMint]);

    const quotes = useMemo(() => {
        if (!data || !principalMint) return undefined;
        return data?.map((q) => convertOfferQuote(q, principalMint));
    }, [convertOfferQuote, principalMint, data]);

    return { data: quotes, isLoading, isFetching };
}

export function useCollateralWithPresets(tokens: BsMetadata[] | undefined) {
    const { allPresets } = usePresets(tokens?.map((t) => t.assetMint));

    const presetByCollateral = useMemo(() => {
        if (!allPresets) return undefined;
        return groupArrayElementsBy(allPresets, (preset) => preset.offerTerms.collateralMint);
    }, [allPresets]);

    if (!presetByCollateral) return undefined;
    return tokens
        ?.map(
            (metadata): NullableRecord<CollateralWithPreset> => ({
                metadata,
                presets: presetByCollateral?.get(metadata.assetMint)
            })
        )
        .filter(filterNullableRecord);
}

export function useGlobalBestOffers({
    principalMint,
    collateralMints,
    presets,
    skip
}: {
    principalMint: string | undefined;
    collateralMints: string[] | undefined;
    presets: PresetStrategyTerms[] | undefined;
    skip?: boolean;
}) {
    const { data: minPrincipal } = usePresetPrincipalByMintsQuery(
        principalMint ? { principalMints: [principalMint] } : skipToken,
        { skip: !principalMint || skip }
    );

    const minPrincipalAmountLamports = principalMint ? minPrincipal?.[principalMint] : undefined;

    const { data: bestQuotes } = useBestQuotes({
        minPrincipalAmountLamports,
        collateralMints,
        principalMint,
        presets,
        showSelf: true,
        skip: !minPrincipalAmountLamports || skip
    });

    const bestQuotesUnique = new Map<string, BestQuote>();

    if (!bestQuotes) return undefined;
    bestQuotes?.forEach((quote) => {
        const key = `${quote.duration}${quote.durationType}`;
        const prev = bestQuotesUnique.get(key);
        if (!prev || quote.apy < prev.apy) {
            bestQuotesUnique.set(key, quote);
        }
    });

    return Array.from(bestQuotesUnique.values());
}

export function useSellQuote(loanExpanded: AbfLoanExpanded | undefined) {
    const { data: rawData, ...query } = useSellQuoteQuery(loanExpanded?.loan.address ?? skipToken, {
        skip: !loanExpanded
    });

    const data = useMemo((): SellLoanQuote | undefined => {
        if (!rawData) return undefined;
        const decimals = loanExpanded?.principalMetadata.decimals ?? 0;
        return {
            ...rawData,
            fairSalePriceAtQuoteRate: lamportsToUiAmount(rawData.fairSalePriceAtQuoteRate, decimals),
            valueLeft: lamportsToUiAmount(rawData.valueLeft, decimals),
            salePrice: lamportsToUiAmount(rawData.salePrice, decimals)
        };
    }, [loanExpanded?.principalMetadata.decimals, rawData]);

    return { data, ...query };
}

export function useRefinanceInfo(params: RefinanceInfoParams | undefined) {
    const { data: rawData, ...query } = useRefinanceInfoQuery(params ?? skipToken, {
        skip: !params || !Object.keys(params ?? {}).length
    });

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return new Map(
            Object.entries(rawData).map(([principalMint, refinanceInfos]) => [
                principalMint,
                new Map(
                    refinanceInfos.map((info): [string, EstimatedRefinanceInfo] => [
                        info.collateralMint,
                        {
                            collateralMint: info.collateralMint,
                            ltv: bpsToUiDecimals(info.ltv),
                            liquidationThreshold: bpsToUiDecimals(info.liquidationThreshold)
                        }
                    ])
                )
            ])
        );
    }, [rawData]);

    const getRefinanceInfo = useCallback(
        (principalMint: string | undefined, collateralMint: string | undefined) => {
            if (!principalMint || !collateralMint) return undefined;
            return data?.get(principalMint)?.get(collateralMint);
        },
        [data]
    );

    return { data, getRefinanceInfo, isLoading: query.isLoading || !data, isFetching: query.isFetching };
}

const WARNING_THRESHOLD = 0.98; // 98%
export function getBorrowCapDetails({
    borrowCap,
    token,
    principalUtilized
}: {
    borrowCap: BorrowCap | null;
    token: BsMetadata;
    principalUtilized: number;
}) {
    const globalBorrowCap = borrowCap?.global || 0;
    const percentOfCap = principalUtilized / (globalBorrowCap || 1);
    const warning = !!borrowCap && percentOfCap >= WARNING_THRESHOLD;

    const remainingGlobalCapacity = Math.max(globalBorrowCap - principalUtilized, 0);

    const warningMessage = (function warningMessage() {
        if (!warning) return null;
        if (percentOfCap >= 1) return "The market cap for borrows has been reached";
        return `This market only has ${BsMetaUtil.formatAmount(
            token,
            remainingGlobalCapacity
        )} remaining borrow capacity`;
    })();

    function exceedsPerLoanCap(amount: number | undefined) {
        if (!borrowCap) return false;
        return greaterThan(amount, borrowCap?.perLoan);
    }

    function exceedsGlobalCap(amount: number | undefined) {
        if (!remainingGlobalCapacity) return false;
        return greaterThan(amount, remainingGlobalCapacity);
    }

    return { borrowCap, percentOfCap, warningMessage, exceedsPerLoanCap, exceedsGlobalCap, remainingGlobalCapacity };
}
