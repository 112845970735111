import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { AbfLoanResponse, LoanSalesFilter, LoanFilterApi } from "../types";
import { GET_LOANS_WITH_INFO_ROUTE, LOAN_SALES_INFO_ROUTE, VERIFY_LOANS_SETUP_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const LOANS_TAG = "Loans";
const VERIFY_LOANS = "VerifyLoans";

export const abfLoanApi = createApi({
    reducerPath: "abfLoanApi",
    tagTypes: [LOANS_TAG, VERIFY_LOANS],
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        loanInfos: builder.query<AbfLoanResponse, LoanFilterApi>({
            providesTags: [LOANS_TAG],
            query(body) {
                return {
                    url: GET_LOANS_WITH_INFO_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        verifyOrdersAsLoans: builder.query<Record<string, boolean>, string[]>({
            providesTags: [VERIFY_LOANS],
            query(orderAddresses) {
                return {
                    url: VERIFY_LOANS_SETUP_ROUTE,
                    method: "POST",
                    body: orderAddresses
                };
            }
        }),
        loanSalesInfo: builder.query<AbfLoanResponse, LoanSalesFilter>({
            providesTags: [LOANS_TAG],
            query(body) {
                return {
                    url: LOAN_SALES_INFO_ROUTE,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const { useLoanInfosQuery, useVerifyOrdersAsLoansQuery, useLoanSalesInfoQuery } = abfLoanApi;

export const useAbfLoanApi = () => {
    const api = abfLoanApi.endpoints;
    const dispatch = useDispatch();

    // refetch data
    const [fetchLoanInfos] = api.loanInfos.useLazyQuery();
    const [verifyOrdersAsLoans] = api.verifyOrdersAsLoans.useLazyQuery();

    return {
        resetLoanApi: () => dispatch(abfLoanApi.util.invalidateTags([LOANS_TAG])),
        resetVerifyOrdersAsLoans: () => dispatch(abfLoanApi.util.invalidateTags([VERIFY_LOANS])),
        fetchLoanInfos,
        verifyOrdersAsLoans
    };
};
