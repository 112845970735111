import { ReactNode } from "react";

import { Column, OutlinedCard, Text } from "@bridgesplit/ui";

export function PageSubtitle({ children }: { children: ReactNode }) {
    return <Text variant="h3"> {children} </Text>;
}

interface PageTitleProps {
    title: string;
    description?: string;
}

export function PageTitle({ title, description }: PageTitleProps) {
    return (
        <Column spacing={1}>
            <Text variant="h1">{title} </Text>
            {description && <Text color="caption">{description}</Text>}
        </Column>
    );
}

export function TableCardWrapper({ children }: { children: ReactNode }) {
    return (
        <OutlinedCard spacing={2} padding={3}>
            {children}
        </OutlinedCard>
    );
}
