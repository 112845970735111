import { useCallback, useEffect, useMemo } from "react";

import { TokenPositionExpanded } from "@bridgesplit/abf-sdk";
import { lamportsToUiAmount, Result, roundDownToDecimals, uiAmountToLamports } from "@bridgesplit/utils";
import BN from "bn.js";
import { BsMetaUtil, parseOrcaAmounts, useUserAvailableAssets } from "@bridgesplit/abf-react";

import { useWhirlpoolContext } from "../WhirlpoolContext";
import { calculateDepositRatios, getSlippageTolerance, getTokenUsdPrice, getYield, useWhirlpoolLtv } from "../util";
import { orca } from "../orca";

export function useOpenPositionHooks() {
    useMaintainOpenPositionQuote();
    useInitializeOpenPositionPriceRange();
    useInitializeOpenPositionAmounts();
}

function useMaintainOpenPositionQuote() {
    const {
        whirlpoolPosition,
        poolData,
        whirlpoolOffchain,
        openPositionForm,
        setOpenPositionForm,
        setOpenPositionQuote,
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();

    const fetchYieldData = useCallback(async () => {
        try {
            if (!poolData || !whirlpoolOffchain) return;

            if (openPositionForm.tickLower === undefined || openPositionForm.tickUpper === undefined) return;

            const { tokenA, tokenB } = whirlpoolPosition;

            const side = (() => {
                if (openPositionForm.side) return openPositionForm.side;

                // pick the side that has more balance if side is not set
                const percentOfAMax = (openPositionForm.tokenAAmount ?? 0) / tokenA.amount;
                const percentOfBMax = (openPositionForm.tokenBAmount ?? 0) / tokenB.amount;
                return percentOfAMax > percentOfBMax ? "A" : "B";
            })();
            const token = side === "A" ? tokenA : tokenB;
            const amount = side === "A" ? openPositionForm.tokenAAmount : openPositionForm.tokenBAmount;
            if (amount === undefined) return;

            if (openPositionForm.tickUpper < openPositionForm.tickLower) {
                return setOpenPositionForm((prev) => ({ ...prev, status: "error" }));
            }

            setOpenPositionForm((prev) => ({
                ...prev,
                status: prev.status === "silent-refetch" ? prev.status : "refetching"
            }));
            const quote = await orca.pool.getOpenPositionQuote({
                poolAddress: whirlpoolPosition.whirlpoolAddress,
                tokenMint: token.mint,
                tokenAmount: new BN(uiAmountToLamports(amount, token.decimals)),
                tickLowerIndex: openPositionForm.tickLower,
                tickUpperIndex: openPositionForm.tickUpper,
                refresh: false,
                slippageTolerance: getSlippageTolerance(slippagePercentDecimals)
            });

            const tokenAAmount = lamportsToUiAmount(quote.estTokenA.toNumber(), tokenA.decimals);
            const tokenBAmount = lamportsToUiAmount(quote.estTokenB.toNumber(), tokenB.decimals);
            setOpenPositionForm((prev) => ({
                ...prev,
                tokenAAmount: side === "A" ? prev.tokenAAmount : tokenAAmount,
                tokenBAmount: side === "B" ? prev.tokenBAmount : tokenBAmount,
                status: "updated"
            }));
            setOpenPositionQuote(quote);
        } catch (error) {
            Result.err(error);
            setOpenPositionForm((prev) => ({ ...prev, status: "error" }));
        }
    }, [
        poolData,
        whirlpoolOffchain,
        openPositionForm.tickLower,
        openPositionForm.tickUpper,
        openPositionForm.tokenAAmount,
        openPositionForm.tokenBAmount,
        openPositionForm.side,
        whirlpoolPosition,
        setOpenPositionForm,
        slippagePercentDecimals,
        setOpenPositionQuote
    ]);

    useEffect(() => {
        if (openPositionForm.status && ["refetch-needed", "silent-refetch"].includes(openPositionForm.status)) {
            fetchYieldData();
        }
    }, [fetchYieldData, openPositionForm.status]);

    return { fetchYieldData };
}

function useInitializeOpenPositionPriceRange() {
    const { setOpenPositionForm, positionData, openPositionForm } = useWhirlpoolContext();
    useEffect(() => {
        if (!positionData || openPositionForm.pricesInitialized) return;
        setOpenPositionForm((prev) => ({
            ...prev,
            tickLower: positionData?.tickLowerIndex,
            tickUpper: positionData?.tickUpperIndex,
            pricesInitialized: true
        }));
    }, [positionData, openPositionForm.pricesInitialized, setOpenPositionForm]);
}

function useInitializeOpenPositionAmounts() {
    const { whirlpoolPosition, openPositionForm, setOpenPositionForm } = useWhirlpoolContext();
    const { balances } = useWhirlpoolOpenPositionBalances();
    useEffect(() => {
        if (!whirlpoolPosition || openPositionForm.amountsInitialized) return;
        setOpenPositionForm((prev) => ({
            ...prev,
            // might be less than starting balance if user's wallet can't cover slippage
            tokenAAmount: whirlpoolPosition.tokenA.amount,
            tokenBAmount: whirlpoolPosition.tokenB.amount,
            amountsInitialized: true
        }));
    }, [whirlpoolPosition, openPositionForm.amountsInitialized, setOpenPositionForm, balances]);
}

export function useOpenPositionYieldData() {
    const { whirlpoolPosition, poolData, whirlpoolOffchain, openPositionQuote, positionData } = useWhirlpoolContext();
    const usdValue = useWhirlpoolUsdValue();
    return useMemo(() => {
        if (!poolData || !whirlpoolOffchain || !usdValue || !positionData) return undefined;

        const liquidity = openPositionQuote?.liquidity ?? whirlpoolPosition.position.totalLiquidity;

        const { tokenAUsd, tokenBUsd, positionUsdValue } = usdValue;

        const positionLiquidity = !tokenAUsd || !tokenBUsd ? new BN(0) : new BN(liquidity);

        const yieldData = getYield({
            // set liquidity to 0 if either token is 0
            positionLiquidity,
            // add position liquidity and subtract previous liquidity
            poolLiquidity: poolData.liquidity.add(positionLiquidity).sub(positionData.liquidity),
            whirlpoolOffchain,
            positionUsdValue
        });
        return yieldData;
    }, [
        poolData,
        whirlpoolOffchain,
        usdValue,
        positionData,
        openPositionQuote?.liquidity,
        whirlpoolPosition.position.totalLiquidity
    ]);
}

function useWhirlpoolUsdValue() {
    const { whirlpoolPosition, poolData, whirlpoolOffchain, openPositionQuote } = useWhirlpoolContext();
    return useMemo(() => {
        if (!poolData || !whirlpoolOffchain) return undefined;

        const tokenAAmount =
            openPositionQuote?.estTokenA ?? whirlpoolPosition.position.liquidityAmounts[whirlpoolPosition.tokenA.mint];
        const tokenBAmount =
            openPositionQuote?.estTokenB ?? whirlpoolPosition.position.liquidityAmounts[whirlpoolPosition.tokenB.mint];

        const tokenAUsd = getTokenUsdPrice(whirlpoolPosition.tokenA, new BN(tokenAAmount));
        const tokenBUsd = getTokenUsdPrice(whirlpoolPosition.tokenB, new BN(tokenBAmount));

        const positionUsdValue = tokenAUsd + tokenBUsd;
        return { positionUsdValue, tokenAUsd, tokenBUsd };
    }, [poolData, whirlpoolOffchain, openPositionQuote, whirlpoolPosition]);
}

export function useWhirlpoolOpenPositionBalances() {
    const {
        openPositionForm,
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();
    const { whirlpoolPosition } = useWhirlpoolContext();
    const { data } = useUserAvailableAssets({});

    const feeAmounts = parseOrcaAmounts(whirlpoolPosition, whirlpoolPosition.position.feeAmounts);

    const getBalance = useCallback(
        (token: TokenPositionExpanded) => {
            if (!data) return undefined;

            const userBalance = data.find((d) => d.mint === token.mint)?.amount ?? 0;
            const fee = feeAmounts.find((a) => a.mint === token.mint)?.uiAmount ?? 0;
            const position = token.amount;
            const total = roundDownToDecimals(userBalance + token.amount + fee, token.decimals);
            const totalUsd = total * token.usdPrice;

            return { total, userBalance, fee, position, totalUsd };
        },
        [data, feeAmounts]
    );

    const balances = useMemo(() => {
        const A = getBalance(whirlpoolPosition.tokenA);
        const B = getBalance(whirlpoolPosition.tokenB);

        return { A, B };
    }, [getBalance, whirlpoolPosition.tokenA, whirlpoolPosition.tokenB]);

    // Since Orca passes the amount user inputs + slippage, we need to subtract the slippage to get the actual balance as max

    const insufficientBalanceMessage = useMemo(() => {
        if ((balances.A?.total ?? 0) < (openPositionForm.tokenAAmount ?? 0))
            return `Insufficient ${BsMetaUtil.getSymbol(tokenA.metadata)} balance`;
        if ((balances.B?.total ?? 0) < (openPositionForm.tokenBAmount ?? 0))
            return `Insufficient ${BsMetaUtil.getSymbol(tokenB.metadata)} balance`;
        return undefined;
    }, [openPositionForm.tokenAAmount, openPositionForm.tokenBAmount, tokenA.metadata, tokenB.metadata, balances]);

    return { insufficientBalanceMessage, balances };
}

export function useOpenPositionLtv() {
    const wp = useWhirlpoolUsdValue();

    return useWhirlpoolLtv(wp?.positionUsdValue);
}

export function useOpenPositionDepositRatio() {
    const { whirlpoolPosition, poolData, openPositionForm } = useWhirlpoolContext();
    return useMemo(() => {
        if (!poolData || openPositionForm.tickLower === undefined || openPositionForm.tickUpper === undefined)
            return undefined;
        return calculateDepositRatios(
            poolData?.sqrtPrice,
            whirlpoolPosition,
            openPositionForm.tickLower,
            openPositionForm.tickUpper
        );
    }, [poolData, openPositionForm.tickLower, openPositionForm.tickUpper, whirlpoolPosition]);
}
