import { createApi } from "@reduxjs/toolkit/query/react";

import { ExternalPrice, OhlcPricingData, FetchPricingDataFilter, PricingData, QuoteAsset } from "../types";
import { ROUTE_FETCH_LATEST_PRICING, ROUTE_FETCH_PRICING, ROUTE_FETCH_QUOTE_ASSETS } from "../constants";
import { abfSerializeQueryArgs, pricingBaseQuery } from "./util";

const EXTERNAL_PRICES_TAG = "ExternalPrices";
export const externalPricesApi = createApi({
    reducerPath: "externalPricesApi",
    baseQuery: pricingBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [EXTERNAL_PRICES_TAG],
    endpoints: (builder) => ({
        externalPrices: builder.query<PricingData, FetchPricingDataFilter>({
            providesTags: [EXTERNAL_PRICES_TAG],
            query(params) {
                return {
                    url: ROUTE_FETCH_PRICING,
                    method: "POST",
                    params: { raw: true },
                    body: params
                };
            },
            transformResponse: (val: { RawPricingData: PricingData }) => val.RawPricingData
        }),
        externalPricesOhlc: builder.query<OhlcPricingData, FetchPricingDataFilter>({
            providesTags: [EXTERNAL_PRICES_TAG],
            query(params) {
                return {
                    url: ROUTE_FETCH_PRICING,
                    method: "POST",
                    body: params
                };
            }
        }),
        quoteAssets: builder.query<Record<string, QuoteAsset>, string[]>({
            query(params) {
                return {
                    url: ROUTE_FETCH_QUOTE_ASSETS,
                    method: "POST",
                    body: params
                };
            }
        }),
        latestExternalPrices: builder.query<ExternalPrice[], FetchPricingDataFilter>({
            query(params) {
                return {
                    url: ROUTE_FETCH_LATEST_PRICING,
                    method: "POST",
                    body: params
                };
            },
            transformErrorResponse: () => []
        })
    })
});

export const { useExternalPricesQuery, useQuoteAssetsQuery, useExternalPricesOhlcQuery, useLatestExternalPricesQuery } =
    externalPricesApi;
