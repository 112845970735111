import { ReactNode, createContext, useCallback, useContext as useContextReact, useMemo, useState } from "react";

import { Button, Image, Tooltip } from "@bridgesplit/ui";
import { ExpandMore } from "@mui/icons-material";
import { useActiveWallet, useUserMultichainWallets, useUserIsPrime } from "@bridgesplit/abf-react";
import { DispatchType } from "@bridgesplit/react";
import { ChainId } from "@bridgesplit/abf-sdk";

import { getChainMeta } from "../common";
import ChainSelect from "./select-chain/ChainSelect";
import RegisterNewChain from "./select-chain/RegisterNewChain";
import CreatePasskey from "./select-chain/CreatePasskey";

enum Step {
    Default,
    SelectChain,
    CreatePasskey,
    RegisterChain
}

type SelectChainContextType = {
    step: Step;
    setStep: DispatchType<Step>;
    chain: ChainId;
    setChain: DispatchType<ChainId>;
};

const SelectChainContext = createContext<SelectChainContextType | undefined>(undefined);

function useContext() {
    const context = useContextReact(SelectChainContext);
    if (!context) {
        throw new Error("useContext must be used within a SelectChain.Wrapper");
    }
    return context;
}

function SelectChainWrapper({ children }: { children: ReactNode }) {
    const { step, setStep, chain, setChain } = useContext();

    const select = useSelectChain();

    const { data: multichainWallets } = useUserMultichainWallets();

    const registeredChains = useMemo(() => {
        const wallets = multichainWallets?.map((w) => getChainMeta(w.chainId).chainId) ?? [];
        return [ChainId.Solana, ...wallets];
    }, [multichainWallets]);

    const back = useCallback(() => {
        if (!registeredChains.includes(chain)) {
            setChain(ChainId.Solana);
        }
        return setStep(Step.Default);
    }, [chain, registeredChains, setChain, setStep]);

    switch (step) {
        case Step.SelectChain:
            return <ChainSelect chain={chain} setChain={select} />;
        case Step.RegisterChain:
            return <RegisterNewChain onSuccess={() => setStep(Step.Default)} back={back} chain={chain} />;
        case Step.CreatePasskey:
            return <CreatePasskey back={back} chain={chain} next={() => setStep(Step.RegisterChain)} />;
        default:
            return <>{children}</>;
    }
}

function useSelectChain() {
    const { setStep, setChain } = useContext();
    const { isMpcWalletCreated } = useActiveWallet();
    const { fetchMultichainWallets } = useUserMultichainWallets();

    return async function select(chain: ChainId) {
        setChain(chain);
        if (chain === ChainId.Solana) {
            return setStep(Step.Default);
        }

        if (!isMpcWalletCreated) {
            return setStep(Step.CreatePasskey);
        }

        const wallets = await fetchMultichainWallets();

        if (wallets.find((w) => w.chainId === getChainMeta(chain).chainId)) {
            return setStep(Step.Default);
        }

        return setStep(Step.RegisterChain);
    };
}

function PromptToSwitch() {
    const { chain, setStep } = useContext();
    const meta = getChainMeta(chain);

    const { isPrime } = useUserIsPrime();

    if (!isPrime) return null;

    return (
        <Tooltip title={`Switch from ${meta.name} to a different blockchain network`}>
            <Button sx={{ px: 1 }} height={30} onClick={() => setStep(Step.SelectChain)} variant="default">
                <Image variant="circle" src={meta?.logo} size="14px" /> {meta.symbol} <ExpandMore />
            </Button>
        </Tooltip>
    );
}

function Wrapper({ children }: { children: ReactNode }) {
    const [chain, setChain] = useState<ChainId>(ChainId.Solana);
    const [step, setStep] = useState<Step>(Step.Default);

    return (
        <SelectChainContext.Provider value={{ chain, setChain, step, setStep }}>
            <SelectChainWrapper>{children}</SelectChainWrapper>
        </SelectChainContext.Provider>
    );
}

/**
 * Controls a component that allows the user to switch chains
 * User will click on `SwitchNetwork`, triggering the selector to go into SelectChain mode
 * Once user has finalized their option, they will return to the children from `Wrapper`
 */
export default { Wrapper, PromptToSwitch, useState: useContext, useSelectChain };
