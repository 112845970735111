import { useCallback, useEffect } from "react";

import { AppCookie, useAlert, useUserPublicKey, useWeb3User } from "@bridgesplit/react";
import { AuthorizationParams, IdToken, useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_PUBLIC_KEY, Result, getReadableErrorMessage, getUnixTs } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";
import { WALLET_TXN_HEADER } from "@bridgesplit/abf-sdk";
import { UAParser } from "ua-parser-js";

import { useAbfFetches, useAuth, useAuthStorage, useUserMeQuery } from "../reducers";
import { AbfUser, TransactionWalletAuth, PrimeRole } from "../types";
import { getAvatarFromName } from "../utils";
import { useGroup } from "./group";
import { useActiveWallet } from "./wallet";
import { LINK_EMAIL_ROUTE, REDIRECT_LOCATION_KEY, SIGN_UP_PREFERENCE_KEY } from "../constants";
import { axiosCreateAbf } from "../reducers/util";

export function useUserMe(options?: { skip?: boolean }) {
    const { params, skip } = useUserMeParams();
    const query = useUserMeQuery(params, {
        skip: options?.skip || skip
    });

    return query;
}

export function useSkipUnauthenticated(require?: "both" | "bearer") {
    const { state } = useAuthStorage();

    const requireVal = require ?? "both";
    const { data: me } = useUserMe();

    if (!me?.user.identifier) return true;
    if (requireVal === "both" && !state.groupIdentifier) return true;

    return false;
}

export function useUserMeParams() {
    const { state } = useAuthStorage();
    const { isAuthenticated, isWalletBased, isLoading } = useAuth();
    const userPubkey = useUserPublicKey();
    const params = isWalletBased ? { walletPubkey: userPubkey } : { idToken: state.idToken?.token };
    const skip = !isAuthenticated || isLoading || !!state.authError;
    return { params, skip };
}

export function useUserProfile() {
    const { data, isLoading } = useUserMe();

    // use our db with auth0 as fallback
    const user: AbfUser = {
        ...data?.user,
        avatar: data?.user.avatar || getAvatarFromName(data?.user.name),
        name: data?.user.name,
        email: data?.user.email ?? "",
        identifier: data?.user.identifier ?? ""
    };

    return { user, isLoading };
}

export function useUserIsPrime() {
    const { currentData } = useUserMe();

    const isPrime = currentData?.prime_roles?.includes(PrimeRole.Prime) ?? false;
    const isAdmin = currentData?.prime_roles?.includes(PrimeRole.Admin) ?? false;

    return { isPrime, isAdmin };
}

export function useTransactionWalletAuth() {
    const { activeWallet } = useActiveWallet();
    const { getWeb3UserResult } = useWeb3User();

    return function (): Result<TransactionWalletAuth> {
        try {
            if (!activeWallet) return Result.errFromMessage("You haven't registered your wallet");
            if (activeWallet.mpcIdentifier) return Result.ok({ mpcIdentifier: activeWallet.mpcIdentifier });
            const web3User = getWeb3UserResult();
            if (!web3User.isOk()) return Result.err(web3User);

            return Result.ok({ wallet: web3User.unwrap().wallet });
        } catch (error) {
            // catches browser wallet errors
            return Result.errWithDebug(getReadableErrorMessage("access your connected wallet"), error);
        }
    };
}

export function useAuth0Logout() {
    const { logout: auth0Logout } = useAuth0();

    return () => {
        onBeforeAuthentication();
        auth0Logout({ logoutParams: { returnTo: window.location.origin } });
    };
}

export function useAuth0Login() {
    const { loginWithPopup, loginWithRedirect } = useAuth0();

    return async (authorizationParams?: AuthorizationParams) => {
        const browser = new UAParser(navigator.userAgent).getBrowser();
        const isMobile = new UAParser(navigator.userAgent).getDevice().type === "mobile";

        // virtual browsers like Phantom error with popup
        if (browser.name === "WebKit" || isMobile) {
            return await loginWithRedirect({ authorizationParams });
        }

        return await loginWithPopup({ authorizationParams });
    };
}

export function useAbfLogout() {
    const auth0Logout = useAuth0Logout();
    const { isAuthenticated } = useAuth0();
    const { disconnect, select } = useWallet();
    const { resetCookies, setAuthError } = useAuthStorage();

    function logOut() {
        resetCookies();
        disconnect();
        select(null);
        setAuthError(null);

        if (isAuthenticated) {
            auth0Logout();
        }
    }

    return { logOut };
}

export function onBeforeAuthentication() {
    const route = window.location.pathname;

    if (route !== "/") {
        AppCookie.set(REDIRECT_LOCATION_KEY, window.location.pathname + window.location.search);
    }
}

export function useLogoutOnError() {
    const { isError } = useUserMe();
    const { isAuthenticated, isLoading } = useAuth();
    const { logOut } = useAbfLogout();

    useEffect(() => {
        if (!isError || isLoading || !isAuthenticated) return;
        logOut();
    }, [isAuthenticated, isError, isLoading, logOut]);
}

// Store auth0 credentials in a cookie so that it can be used in RTK queries
export function useSetAuth0Bearer() {
    const { isAuthenticated, isLoading, error, user } = useAuth0();
    const logout = useAuth0Logout();
    const { alert } = useAlert();

    // Store in both cookie and state
    const { state, setIdTokenWithExpiry } = useAuthStorage();
    const fetchIdToken = useFetchIdTokensResult();
    const refreshAccessToken = useRefreshAccessToken();
    const { resetAll } = useAbfFetches();

    const handleError = useCallback(() => {
        alert(getReadableErrorMessage("log in with email"), "error");
        return logout();
    }, [alert, logout]);

    useEffect(() => {
        if (state.idToken || state.skipAuth0 || !isAuthenticated || isLoading || error || !user?.email_verified) return;
        (async function fetchAndSetToken() {
            const idTokenRes = await fetchIdToken();
            let idToken = idTokenRes.unwrapOrUndefined();

            // if id doesn't exist, try refreshing
            if (!idToken?.exp || idToken.exp < getUnixTs()) {
                const res = await refreshAccessToken();
                if (!res.isOk()) {
                    return handleError();
                }
                const idTokenRes = await fetchIdToken();
                idToken = idTokenRes.unwrapOrUndefined();
            }
            if (!idToken) {
                return handleError();
            }

            AppCookie.remove(SIGN_UP_PREFERENCE_KEY);
            setIdTokenWithExpiry(idToken.__raw, idToken.exp);
            resetAll();
        })();
    }, [
        error,
        fetchIdToken,
        handleError,
        isAuthenticated,
        isLoading,
        refreshAccessToken,
        resetAll,
        setIdTokenWithExpiry,
        state.idToken,
        state.skipAuth0,
        user?.email_verified
    ]);
}

function useFetchIdTokensResult() {
    const { getIdTokenClaims } = useAuth0();

    return async function fetchIdToken(): Promise<Result<IdToken>> {
        try {
            const idToken = await getIdTokenClaims();
            return Result.ok(idToken);
        } catch (error) {
            return Result.err(error);
        }
    };
}

function useRefreshAccessToken() {
    const { getAccessTokenSilently } = useAuth0();

    return async function refreshToken(): Promise<Result<string>> {
        try {
            const token = await getAccessTokenSilently({ cacheMode: "off" });
            return Result.ok(token);
        } catch (error) {
            return Result.err(error);
        }
    };
}

export function useUserPermissionedOrgs(options?: { skip?: boolean }) {
    const { custodianPermissions, isLoading } = useGroup({ skip: options?.skip });
    return { identifiers: Object.keys(custodianPermissions), isLoading };
}

export function useSwitchAccount() {
    const { setGroupIdentifier, setEscrowNonce } = useAuthStorage();

    // const { resetAll } = useAbfFetches();

    return (groupIdentifier: string) => {
        // reset escrow nonce
        setEscrowNonce(DEFAULT_PUBLIC_KEY);

        // set identifier in storage to show new group
        setGroupIdentifier(groupIdentifier);
    };
}

export function useRefreshAuth0Token() {
    const { setIdTokenWithExpiry } = useAuthStorage();
    const { getAccessTokenSilently, getIdTokenClaims, user, isAuthenticated } = useAuth0();
    return async function refreshAuth0(): Promise<Result<boolean>> {
        try {
            if (!isAuthenticated) return Result.errFromMessage("You aren't logged in");
            if (user?.email_verified) return Result.ok(true);
            await getAccessTokenSilently({ cacheMode: "off" });
            const idToken = await getIdTokenClaims();

            if (!idToken?.email_verified)
                return Result.errFromMessage("You haven't verified your email", { skipSentry: true });
            setIdTokenWithExpiry(idToken.__raw, idToken.exp);
            return Result.ok(true);
        } catch (e) {
            return Result.errWithDebug(getReadableErrorMessage("check email verification"), e, { skipSentry: true });
        }
    };
}

export function useLinkWalletToEmail() {
    const { getIdTokenClaims } = useAuth0();

    return async function registerWallet(signedTransaction: string | undefined) {
        try {
            const idToken = await getIdTokenClaims();

            if (!idToken || !signedTransaction) return Result.errFromMessage("Failed to fetch authentication");
            await axiosCreateAbf().get(LINK_EMAIL_ROUTE, {
                headers: { Authorization: `Bearer ${idToken.__raw}`, [WALLET_TXN_HEADER]: signedTransaction }
            });
            return Result.ok();
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("register email"), error);
        }
    };
}
