import { AxiosError } from "axios";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import * as Sentry from "@sentry/react";

import { DEBUG, ErrorType, IS_LOCAL_NX_DEV, UNKNOWN_ERROR } from "../constants";

export class Result<T> {
    private readonly value?: T;
    private readonly errorMessage?: string;
    private readonly status: boolean = false;

    private constructor(status: boolean, value?: T, errorMessage?: string) {
        this.status = status;
        this.value = value;
        this.errorMessage = errorMessage;
    }

    /**
     * Create a successful result
     * If property is undefined, will return true to prevent unwrap
     */
    static ok<T>(value?: T): Result<T> {
        return new Result(true, value);
    }

    static errFromMessage<T>(
        error: string,
        options?: { errorType?: ErrorType; rawError?: unknown; skipSentry?: boolean }
    ): Result<T> {
        const uiError = transformRawErrorToUi(error);
        if (!options?.skipSentry) {
            Sentry.captureException(error, { extra: { rawError: options?.rawError } });
        }

        return new Result<T>(false, undefined, uiError);
    }

    static errWithDebug<T>(errorMessage: string, error: unknown, options?: { skipSentry?: boolean }): Result<T> {
        if (DEBUG) {
            return this.err(error);
        }
        // eslint-disable-next-line no-console
        console.warn(error);
        return this.errFromMessage(errorMessage, { rawError: error, skipSentry: options?.skipSentry });
    }

    // Create an error result
    static err<T>(error: unknown, options?: { skipSentry?: boolean }): Result<T> {
        // eslint-disable-next-line no-console
        console.warn(error);

        if (typeof error === "string") {
            return this.errFromMessage(error, { skipSentry: options?.skipSentry });
        }
        if (error instanceof Result) {
            if (error.isOk()) {
                // in case dev tries to cast Ok result as an error
                return this.errFromMessage(UNKNOWN_ERROR, { skipSentry: options?.skipSentry });
            }
            return error;
        }

        if (error instanceof AxiosError) {
            return this.errFromMessage(parseAxiosError(error), {
                errorType: ErrorType.ApiFetchError,
                rawError: error,
                skipSentry: options?.skipSentry
            });
        }

        if (typeof error === "object" && !!error) {
            // find nested "error" properties
            if ("error" in error && typeof error.error === "object" && !!error.error) {
                return this.err(error.error, options);
            }

            // find nested "data" properties
            if ("data" in error && typeof error.data === "object" && !!error.data) {
                return this.err(error.data, options);
            }

            const foundNestedValue = findNestedErrorValues(error, ["data", "message", "error"]);
            if (foundNestedValue) {
                return this.errFromMessage(foundNestedValue, { rawError: error, skipSentry: options?.skipSentry });
            }
        }

        return this.errFromMessage(parseErrorMessage(error), { rawError: error, skipSentry: options?.skipSentry });
    }

    static combine<T>(results: Result<T>[]): Result<T[]> {
        try {
            const data: T[] = [];
            for (const result of results) {
                if (result.isOk()) {
                    data.push(result.unwrap());
                } else if (result.isErr()) {
                    return Result.err(result);
                }
            }

            return Result.ok(data);
        } catch (error) {
            return Result.err(error);
        }
    }

    // Check if the result is a successful result
    isOk(): boolean {
        return this.status === true;
    }

    // Check if the result is a successful result
    isErr(): boolean {
        return this.status === false;
    }

    // Get the value from a successful result, or throw an error
    unwrap(): T {
        if (!this.status && IS_LOCAL_NX_DEV) {
            throw new Error("Cannot unwrap an error result");
        }
        return this.value as T;
    }

    unwrapOrUndefined(): T | undefined {
        if (this.value) {
            return this.value;
        }
        return undefined;
    }

    // Get the value from a successful result, or throw an error
    unwrapOr(fallback: T): T {
        if (this.value) {
            return this.value;
        }
        return fallback;
    }

    unwrapErrMessage(fallback = "Something went wrong"): string {
        return this.errorMessage ?? fallback;
    }
}

/**
 * Generate a consistent error message for users
 * @param taskDescription a 2-4 word description of action
 * @returns Unable to {taskDescription}
 */
export function getReadableErrorMessage(taskDescription: string) {
    return `Unable to ${taskDescription}`;
}

export function parseAxiosError(e: AxiosError) {
    const data = e.response?.data;

    if (data && typeof data === "string") {
        return transformRawErrorToUi(data);
    }

    const statusCode = e.response?.status ?? e.status;

    if (statusCode === 401 || statusCode === 400) return "Insufficient permissions";
    if (statusCode === 500) return "Server error";
    if (statusCode === 503) return "Server is unavailable";

    if (statusCode === 415) return "Your request was malformed. Please try again later";
    if (statusCode === 404) return "Invalid request";
    return "Unable to reach server";
}

function findNestedErrorValue(obj: Record<string, unknown>, key: string): string | undefined {
    if (!obj || typeof obj !== "object") return undefined;
    if (key in obj && typeof obj[key] === "string") {
        return obj[key] as string;
    }
    return undefined;
}

function findNestedErrorValues(obj: unknown, keys: string[]) {
    for (const key of keys) {
        const foundValue = findNestedErrorValue(obj as Record<string, unknown>, key);
        if (foundValue) return foundValue;
    }
    return undefined;
}

function transformRawErrorToUi(errorMessage: string) {
    if (errorMessage.includes("Duplicate entry")) {
        return "The data you're trying to add already exists";
    }

    if (errorMessage.includes("Failed to fetch")) {
        return "Unable to reach server";
    }

    if (errorMessage.includes("Could not find key")) {
        return "Unable to retrieve Passkey credentials. Please refresh and try again";
    }

    if (errorMessage.includes("Failed to deserialize query string")) {
        return "Server received an unexpected response";
    }

    if (errorMessage.includes("Failed to deserialize the JSON body")) {
        return "The data you sent is getting rejected by our servers";
    }

    if (errorMessage.includes("User rejected the request")) {
        return "You rejected the transaction";
    }
    if (errorMessage.includes("Error: already handling a request from")) {
        return "Wallet extension error. Turn your wallet extension off then on again in your browser settings";
    }

    if (errorMessage.includes("The operation either timed out or was not allowed")) {
        return "Passkey creation was rejected by your browser. Please try again";
    }

    if (errorMessage.includes("Something went wrong")) {
        return errorMessage.replace("Something went wrong: ", "");
    }

    return errorMessage;
}

export function parseErrorMessage(e: any) {
    const errorCode: number | undefined = e?.error?.code;
    const errorMessage: string | undefined = e?.error?.message;

    // eslint-disable-next-line no-console
    console.warn(e);

    if (e instanceof Error) {
        return e.message;
    }
    if (e?.status === "FETCH_ERROR") {
        return "Unable to get a response from server";
    } else if (errorCode) {
        if (errorCode === 4001) {
            return "You rejected the transaction";
        } else if (errorCode === 4100) {
            return "Unknown signer. Try refreshing the page or reconnecting your wallet";
        } else if (errorCode === -32002) {
            return "Wallet error. Ensure you don't have any pending transaction confirmations and refresh the page";
        }
    }
    if (errorMessage) {
        transformRawErrorToUi(errorMessage);
        return errorMessage;
    }
    if (typeof e === "string") {
        return transformRawErrorToUi(e);
    }
    return UNKNOWN_ERROR;
}

export function parseErrorFromOptions(error: string, parsedErrors: Record<string, string>) {
    for (const [errorMessage, parsed] of Object.entries(parsedErrors)) {
        if (error.toLowerCase().includes(errorMessage.toLowerCase())) {
            return parsed;
        }
    }
    return undefined;
}
