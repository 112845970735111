import React from "react";

import { LoopscaleWeb3Provider, useAppTheme } from "@bridgesplit/react";
import { DialogProvider, LoopscaleTrackingProvider, WalletProvider, createThemeOptions } from "@bridgesplit/ui";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from "@mui/system/ThemeProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import { RPC_URL } from "@bridgesplit/utils";
import { isAuth0SameDomain, isCrossOriginAllowed } from "@bridgesplit/abf-react";
import { AUTH_CLIENT_ID, AUTH_DOMAIN } from "app/constants";

import AppAlerts from "./AppAlerts";
import { useTrack } from "../../hooks";

// When auth0 runs in a separate domains from browser, session auth doesn't work
// https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari
const IS_SAME_DOMAIN = isAuth0SameDomain(AUTH_DOMAIN);
const USE_REFRESH_TOKENS = !IS_SAME_DOMAIN && !isCrossOriginAllowed();

export default function Providers({ children }: { children: React.ReactNode }) {
    const { mode } = useAppTheme();
    const theme = createTheme(createThemeOptions(mode));
    const track = useTrack();

    return (
        <ThemeProvider theme={theme}>
            <Auth0Provider
                useRefreshTokens={USE_REFRESH_TOKENS}
                cacheLocation={USE_REFRESH_TOKENS ? "localstorage" : "memory"}
                domain={AUTH_DOMAIN}
                clientId={AUTH_CLIENT_ID}
                authorizationParams={{ redirect_uri: window.location.origin }}
            >
                <WalletProvider connectionUrl={RPC_URL}>
                    <LoopscaleWeb3Provider connectionUrl={RPC_URL}>
                        <DialogProvider>
                            <AppAlerts>
                                <LoopscaleTrackingProvider trackEvent={track.rawTrack}>
                                    {children}
                                </LoopscaleTrackingProvider>
                            </AppAlerts>
                        </DialogProvider>
                    </LoopscaleWeb3Provider>
                </WalletProvider>
            </Auth0Provider>
        </ThemeProvider>
    );
}
