import { AbfRole, AbfRoleNumber } from "../types";

export const ROLE_STR_TO_NUM: Record<AbfRole, AbfRoleNumber> = {
    [AbfRole.SuperAdmin]: AbfRoleNumber.SuperAdmin,
    [AbfRole.Admin]: AbfRoleNumber.Admin,
    [AbfRole.Lender]: AbfRoleNumber.Lender,
    [AbfRole.Borrower]: AbfRoleNumber.Borrower,
    [AbfRole.EscrowManager]: AbfRoleNumber.EscrowManager,
    [AbfRole.Member]: AbfRoleNumber.Member
};

export const ROLE_NUM_TO_STR = Object.fromEntries(
    Object.entries(ROLE_STR_TO_NUM).map(([key, val]) => [val, key])
) as Record<AbfRoleNumber, AbfRole>;

export function convertRolesToRoleNumbers(roles: AbfRole[]): AbfRoleNumber[] {
    return roles.map((r) => ROLE_STR_TO_NUM[r]);
}

export function convertRolesNumbersToRole(roles: AbfRoleNumber[]): AbfRole[] {
    return roles.map((r) => ROLE_NUM_TO_STR[r]);
}
