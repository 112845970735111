import { CARD_WIDTH, Column, NotFoundCard } from "@bridgesplit/ui";

import Page from "./Page";

export default function MissingDataPage() {
    return (
        <Page allowNullGroup hideAlerts permission>
            <Column sx={{ my: 5, width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Column sx={{ maxWidth: CARD_WIDTH }}>
                    <NotFoundCard header="Not found" description="We couldn't find the data you're looking for" />
                </Column>
            </Column>
        </Page>
    );
}
