import { combineReducers, Middleware } from "@reduxjs/toolkit";

import { abfEscrowedAssetApi, useAbfEscrowedAssetApi } from "./escrowedAssetApi";
import { abfLoanApi, useAbfLoanApi } from "./loanApi";
import { abfOrderApi, useAbfOrderApi } from "./orderApi";
import { abfCustodianApi, useCustodianApi } from "./custodianApi";
import { abfUserApi, useAbfUserApi } from "./userApi";
import { abfLedgerAccountsApi, useAbfLedgerAccountsApi } from "./ledgerAccountsApi";
import { groupAdminApi, useGroupApi } from "./groupApi";
import authSlice from "./authSlice";
import { loanRequestApi, useLoanRequestApi } from "./loanRequestApi";
import { lockboxApi, useLockboxApi } from "./lockboxApi";
import { abfLendingStrategyApi, useLendingStrategyApi } from "./lendingStrategyApi";
import { externalPricesApi } from "./externalPricesApi";
import { messagingApi } from "./messagingApi";
import { identityApi } from "./identityApi";
import { bsMetadataApi, useBsMetadataApi } from "./bsMetadataApi";
import { syndicatedApi, useSyndicatedApi } from "./syndicatedApi";
import { abfUserInvitesApi, useAbfUserInvitesApi } from "./invitesApi";
import { feesApi, useFeesApi } from "./feeApi";
import { sphereApi, useSphereApi } from "./sphereApi";
import { mpcApi, useMpcApi } from "./mpcApi";
import { transactionsApi } from "./transactionsApi";
import { cavemanApi, useCavemanApi } from "./cavemanApi";
import { tygrisApi, useTygrisApi } from "./tygrisApi";
import { bridgeTransactionApi, useBridgeTransactionApi } from "./bridgeTransactionApi";
import { stakedSolApi, useAbfStakeApi } from "./stakedSolApi";
import { napoleonApi, useNapoleonApi } from "./napoleonApi";
import { oraclePricesApi, useOraclePricesApi } from "./oraclePricesApi";
import { transactionHealthApi } from "./transactionHealthApi";
import { pointsApi, usePointsApi } from "./pointsApi";
import { twitterPointsApi, useTwitterPointsApi } from "./twitterPointsApi";
import { discordPointsApi, useDiscordPointsApi } from "./discordPointsApi";
import { tokenListApi } from "./tokenListApi";
import { onboardingApi } from "./onboardingApi";
import { externalYieldApi } from "./externalYieldApi";
import { loopApi, useLoopApi } from "./loopApi";
import { jupiterApi, useJupiterApi } from "./jupiterApi";
import { meteoraApi, useMeteoraApi } from "./meteoraApi";
import { orcaExternalApi, useOrcaExternalApi } from "./orcaExternalApi";
import { loopPublicApi, useLoopPublicApi } from "./loopPublicApi";
import { napoleonPublicApi, useNapoleonPublicApi } from "./napoleonPublicApi";
import { abfEscrowedAssetPublicApi, useAbfEscrowedAssetPublicApi } from "./escrowedAssetPublicApi";
import { abfCustodianPublicApi } from "./custodianPublicApi";

const AbfSdkReducers = {
    authSlice,
    [abfOrderApi.reducerPath]: abfOrderApi.reducer,
    [bsMetadataApi.reducerPath]: bsMetadataApi.reducer,
    [tokenListApi.reducerPath]: tokenListApi.reducer,
    [abfEscrowedAssetApi.reducerPath]: abfEscrowedAssetApi.reducer,
    [abfEscrowedAssetPublicApi.reducerPath]: abfEscrowedAssetPublicApi.reducer,
    [abfCustodianApi.reducerPath]: abfCustodianApi.reducer,
    [abfCustodianPublicApi.reducerPath]: abfCustodianPublicApi.reducer,
    [abfUserApi.reducerPath]: abfUserApi.reducer,
    [abfLoanApi.reducerPath]: abfLoanApi.reducer,
    [abfLedgerAccountsApi.reducerPath]: abfLedgerAccountsApi.reducer,
    [groupAdminApi.reducerPath]: groupAdminApi.reducer,
    [loanRequestApi.reducerPath]: loanRequestApi.reducer,
    [lockboxApi.reducerPath]: lockboxApi.reducer,
    [abfLendingStrategyApi.reducerPath]: abfLendingStrategyApi.reducer,
    [syndicatedApi.reducerPath]: syndicatedApi.reducer,
    [abfUserInvitesApi.reducerPath]: abfUserInvitesApi.reducer,
    [externalPricesApi.reducerPath]: externalPricesApi.reducer,
    [messagingApi.reducerPath]: messagingApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    [feesApi.reducerPath]: feesApi.reducer,
    [sphereApi.reducerPath]: sphereApi.reducer,
    [mpcApi.reducerPath]: mpcApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [cavemanApi.reducerPath]: cavemanApi.reducer,
    [bridgeTransactionApi.reducerPath]: bridgeTransactionApi.reducer,
    [tygrisApi.reducerPath]: tygrisApi.reducer,
    [stakedSolApi.reducerPath]: stakedSolApi.reducer,
    [napoleonApi.reducerPath]: napoleonApi.reducer,
    [napoleonPublicApi.reducerPath]: napoleonPublicApi.reducer,
    [oraclePricesApi.reducerPath]: oraclePricesApi.reducer,
    [transactionHealthApi.reducerPath]: transactionHealthApi.reducer,
    [pointsApi.reducerPath]: pointsApi.reducer,
    [twitterPointsApi.reducerPath]: twitterPointsApi.reducer,
    [discordPointsApi.reducerPath]: discordPointsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [externalYieldApi.reducerPath]: externalYieldApi.reducer,
    [loopApi.reducerPath]: loopApi.reducer,
    [loopPublicApi.reducerPath]: loopPublicApi.reducer,
    [jupiterApi.reducerPath]: jupiterApi.reducer,
    [meteoraApi.reducerPath]: meteoraApi.reducer,
    [orcaExternalApi.reducerPath]: orcaExternalApi.reducer
};

export type SdkRootState = ReturnType<ReturnType<typeof combineReducers<typeof AbfSdkReducers>>>;

const AbfSdkMiddleware: Middleware[] = [
    abfEscrowedAssetApi.middleware,
    abfEscrowedAssetPublicApi.middleware,
    abfOrderApi.middleware,
    abfCustodianApi.middleware,
    abfCustodianPublicApi.middleware,
    abfUserApi.middleware,
    abfLoanApi.middleware,
    abfLedgerAccountsApi.middleware,
    groupAdminApi.middleware,
    loanRequestApi.middleware,
    lockboxApi.middleware,
    abfLendingStrategyApi.middleware,
    syndicatedApi.middleware,
    abfUserInvitesApi.middleware,
    externalPricesApi.middleware,
    messagingApi.middleware,
    identityApi.middleware,
    bsMetadataApi.middleware,
    tokenListApi.middleware,
    feesApi.middleware,
    sphereApi.middleware,
    mpcApi.middleware,
    transactionsApi.middleware,
    cavemanApi.middleware,
    bridgeTransactionApi.middleware,
    tygrisApi.middleware,
    stakedSolApi.middleware,
    napoleonApi.middleware,
    napoleonPublicApi.middleware,
    externalYieldApi.middleware,
    oraclePricesApi.middleware,
    transactionHealthApi.middleware,
    pointsApi.middleware,
    twitterPointsApi.middleware,
    discordPointsApi.middleware,
    onboardingApi.middleware,
    loopApi.middleware,
    loopPublicApi.middleware,
    jupiterApi.middleware,
    meteoraApi.middleware,
    orcaExternalApi.middleware
];

export function useAbfFetches() {
    const escrow = useAbfEscrowedAssetApi();
    const escrowPublic = useAbfEscrowedAssetPublicApi();
    const loan = useAbfLoanApi();
    const order = useAbfOrderApi();
    const custodian = useCustodianApi();
    const user = useAbfUserApi();
    const ledgerAccounts = useAbfLedgerAccountsApi();
    const groupAdmin = useGroupApi();
    const loanRequest = useLoanRequestApi();
    const lockbox = useLockboxApi();
    const lendingStrategy = useLendingStrategyApi();
    const syndicated = useSyndicatedApi();
    const invites = useAbfUserInvitesApi();
    const fees = useFeesApi();
    const evm = useCavemanApi();
    const bridgeTransaction = useBridgeTransactionApi();
    const bsMetadata = useBsMetadataApi();
    const tygris = useTygrisApi();
    const napoleon = useNapoleonApi();
    const napoleonPublic = useNapoleonPublicApi();
    const stake = useAbfStakeApi();
    const oracle = useOraclePricesApi();
    const twitterPoints = useTwitterPointsApi();
    const discordPoints = useDiscordPointsApi();
    const points = usePointsApi();
    const loop = useLoopApi();
    const loopPublic = useLoopPublicApi();
    const sphere = useSphereApi();
    const mpc = useMpcApi();
    const jupiter = useJupiterApi();
    const meteora = useMeteoraApi();
    const orcaExternal = useOrcaExternalApi();

    const all = {
        ...escrow,
        ...escrowPublic,
        ...loan,
        ...order,
        ...custodian,
        ...user,
        ...ledgerAccounts,
        ...groupAdmin,
        ...loanRequest,
        ...lockbox,
        ...lendingStrategy,
        ...syndicated,
        ...invites,
        ...fees,
        ...sphere,
        ...mpc,
        ...evm,
        ...bridgeTransaction,
        ...bsMetadata,
        ...tygris,
        ...napoleon,
        ...napoleonPublic,
        ...stake,
        ...oracle,
        ...twitterPoints,
        ...discordPoints,
        ...points,
        ...loop,
        ...loopPublic,
        ...jupiter,
        ...meteora,
        ...orcaExternal
    };

    function resetAll() {
        all.resetCustodianApi();
        all.resetEscrowedApi();
        all.resetEscrowedPublicApi();
        all.resetLedgerAccounts();
        all.resetOrderApi();
        all.resetLoanApi();
        all.resetVerifyOrdersAsLoans();
        all.resetLoanRequests();
        all.resetCustodianSignupCodes();
        all.resetGroupAdminApi();
        all.resetGroupDocumentsApi();
        all.resetGroupMetadataApi();
        all.resetLockboxApi();
        all.resetLendingStrategyApi();
        all.resetFeesApi();
        all.resetSphere();
        all.resetMeApi();
        all.resetMpcApi();
        all.resetEvmNfts();
        all.resetBridgeTransactions();
        all.resetBsMetadataApi();
        all.resetEvmGas();
        all.resetNapoleonApi();
        all.resetNapoleonPublicApi();
        all.resetStakeApi();
        all.resetOraclePricesApi();
        all.resetTwitterInfo();
        all.resetDiscordInfo();
        all.resetPointsAndReferrals();
        all.resetLoopApi();
        all.resetLoopApiPublic();
        all.resetJupiterSwapApi();
        all.resetMeteoraApi();
        all.resetOrcaExternalApi();
    }

    return { resetAll, ...all };
}

export * from "./escrowedAssetApi";
export * from "./escrowedAssetPublicApi";
export * from "./loanApi";
export * from "./orderApi";
export * from "./custodianApi";
export * from "./custodianPublicApi";
export * from "./userApi";
export * from "./ledgerAccountsApi";
export * from "./loanRequestApi";
export * from "./groupApi";
export * from "./authSlice";
export * from "./lockboxApi";
export * from "./lendingStrategyApi";
export * from "./syndicatedApi";
export * from "./invitesApi";
export * from "./externalPricesApi";
export * from "./messagingApi";
export * from "./identityApi";
export * from "./bsMetadataApi";
export * from "./tokenListApi";
export * from "./feeApi";
export * from "./sphereApi";
export * from "./transactionsApi";
export * from "./mpcApi";
export * from "./cavemanApi";
export * from "./bridgeTransactionApi";
export * from "./tygrisApi";
export * from "./stakedSolApi";
export * from "./napoleonApi";
export * from "./napoleonPublicApi";
export * from "./oraclePricesApi";
export * from "./transactionHealthApi";
export * from "./pointsApi";
export * from "./twitterPointsApi";
export * from "./discordPointsApi";
export * from "./onboardingApi";
export * from "./externalYieldApi";
export * from "./loopApi";
export * from "./loopPublicApi";
export * from "./jupiterApi";
export * from "./meteoraApi";
export * from "./orcaExternalApi";
export { AbfSdkMiddleware, AbfSdkReducers };
