import { useState } from "react";

import { Result, SSN_REGEX } from "@bridgesplit/utils";

export function truncateNumber(n: number | undefined, desiredDigits: number) {
    if (n === undefined) return undefined;
    if (desiredDigits <= 0) {
        return 0;
    }

    const numString = n.toString();

    if (numString.length <= desiredDigits) {
        return n;
    }

    const truncatedString = numString.slice(0, desiredDigits);
    return parseFloat(truncatedString);
}

export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const DOB_ERROR = "Invalid date";
// Parse a input from DOB_PATTERN
export function parseDOBFromPatternString(
    patternString: string | undefined
): Result<{ day: number; month: number; year: number }> {
    try {
        const parsed = patternString?.split("/").map((p) => parseInt(p));
        if (parsed?.length !== 3) {
            return Result.errFromMessage(DOB_ERROR);
        }
        const [month, day, year] = parsed;
        return Result.ok({ day, month, year });
    } catch (error) {
        return Result.errFromMessage(DOB_ERROR);
    }
}

const PHONE_ERROR = "Invalid date of birth";
export function parsePhoneNumberIntoE164({
    phoneNumber,
    countryCode
}: {
    phoneNumber: string | undefined;
    countryCode: string | undefined;
}): Result<string> {
    if (phoneNumber === undefined || countryCode === undefined) return Result.errFromMessage(PHONE_ERROR);
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    if (!digitsOnly) return Result.errFromMessage(PHONE_ERROR);
    return Result.ok(countryCode + digitsOnly);
}

export function parsePatternIntoNumber(raw: string | undefined, errorFallback = "Invalid number"): Result<number> {
    if (!raw) return Result.errFromMessage(errorFallback);
    const digitsOnly = raw.replace(/\D/g, "");
    const int = parseInt(digitsOnly);
    if (isNaN(int)) return Result.errFromMessage(errorFallback);
    return Result.ok(int);
}

export function parseSSN(ssn: string | undefined): Result<string> {
    if (!ssn || !SSN_REGEX.test(ssn)) return Result.errFromMessage("Invalid SSN");
    return Result.ok(ssn);
}

export type PaginationDetails = ReturnType<typeof usePagination>;
export function usePagination({ pageSize }: { pageSize: number }) {
    const firstPage = 0;
    const [page, setPage] = useState<number>(firstPage);

    const resetPage = () => setPage(firstPage);
    const offset = page * pageSize;

    return {
        offset,
        pageSize,
        page,
        setPage,
        resetPage
    };
}
