import { useCallback, useMemo, useState } from "react";

import { DialogWrapper } from "@bridgesplit/ui";

import WalletSetupVerifyWallet from "./WalletSetupVerifyWallet";
import WalletSetupCreateMpc from "./WalletSetupCreateMpc";
import WalletSetupSetWalletType from "./WalletSetupSetWalletType";
import { WalletSetupStep } from "./util";
import WalletSetupMpcPrimeGated from "./WalletSetupMpcPrimeGated";

function WalletSetupDialogInternal() {
    const [step, setStep] = useState<WalletSetupStep>(WalletSetupStep.Select);

    const back = useCallback(() => setStep(WalletSetupStep.Select), []);

    return useMemo(() => {
        switch (step) {
            case WalletSetupStep.Mpc:
                return <WalletSetupCreateMpc back={back} />;
            case WalletSetupStep.Wallet:
                return <WalletSetupVerifyWallet back={back} />;
            case WalletSetupStep.MpcPrimeGated:
                return <WalletSetupMpcPrimeGated back={back} />;
            default:
                return <WalletSetupSetWalletType setStep={setStep} />;
        }
    }, [back, step]);
}

export default function WalletSetupDialog() {
    return (
        <DialogWrapper>
            <WalletSetupDialogInternal />
        </DialogWrapper>
    );
}
