import { OnboardingStep } from "@bridgesplit/abf-react";
import { Column, Button } from "@bridgesplit/ui";
import { useAppDialog } from "app/utils";
import { AppTracking, TrackEvent } from "app/types";

import { GuideBullets, GuideFooter } from "./common";
import { OnboardingBullet } from "./constants";
import { DialogHeaderWithBack, HeaderWithClose } from "../../common";

export default function RoleGuide({
    step,
    forcedTitle,
    unforcedTitle,
    bullets,
    docs,
    next,
    isForced,
    isNested,
    trackingEvent
}: {
    step: OnboardingStep;
    forcedTitle: string;
    unforcedTitle: string;
    bullets: OnboardingBullet[];
    docs: string;
    next: (step: OnboardingStep) => void;
    isForced: boolean;
    isNested?: boolean;
    trackingEvent: TrackEvent;
}) {
    const { close } = useAppDialog();

    const handleClose = async () => {
        next(step);
    };
    const title = isForced ? forcedTitle : unforcedTitle;

    return (
        <>
            {isNested ? (
                <DialogHeaderWithBack back={handleClose} header={title} />
            ) : (
                <HeaderWithClose close={isForced && isNested ? close : handleClose} header={title} />
            )}

            <GuideBullets bullets={bullets} />
            <Column spacing={1}>
                {isForced && (
                    <Button
                        fullWidth
                        onClick={handleClose}
                        variant="contained"
                        tracking={AppTracking.from(trackingEvent)}
                    >
                        Continue
                    </Button>
                )}
                <GuideFooter docs={docs} />
            </Column>
        </>
    );
}
