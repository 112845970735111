import { Column, OutlinedCard, Text } from "@bridgesplit/ui";

import OpenPositionStats from "./OpenPositionStats";
import OpenPositionPriceRange from "./OpenPositionPriceRange";
import OpenPositionCta from "./OpenPositionCta";
import OpenPositionAmounts from "./OpenPositionAmounts";

function OpenPositionTop() {
    return (
        <Column spacing={2}>
            <Text color="caption">
                Re-initialize your position using your current position and wallet balances. Yield is auto-compounded
            </Text>

            <OpenPositionSection header="Liquidity range">
                <OpenPositionPriceRange />
            </OpenPositionSection>
            <OpenPositionSection header="Position balances">
                <OpenPositionAmounts />
            </OpenPositionSection>
        </Column>
    );
}

function OpenPositionBottom() {
    return (
        <Column spacing={2}>
            <OpenPositionStats />
            <OpenPositionCta />
        </Column>
    );
}

function OpenPositionSection({ children, header }: { children: React.ReactNode; header: string }) {
    return (
        <OutlinedCard spacing={1} padding={2}>
            <Text color="caption">{header}</Text>
            <Column spacing={2}>{children}</Column>
        </OutlinedCard>
    );
}

export const OpenPosition = {
    Top: OpenPositionTop,
    Bottom: OpenPositionBottom
};
