import { memo, useMemo } from "react";

import { Column, formatChartPercent, PriceLineChartWithAxis, Text, TooltipText } from "@bridgesplit/ui";
import { PERCENT_DECIMALS, filterNullableRecord, formatPercent, formatSeconds } from "@bridgesplit/utils";
import { formatDurationWithType, getDurationInSeconds } from "@bridgesplit/abf-sdk";
import { useGlobalBestOffers } from "@bridgesplit/abf-react";

import { useMarketContext } from "./MarketContext";
import { useQuotesContext } from "./QuotesContext";
import { EmptyChart } from "./empty";

export default memo(function YieldCurve({
    colorOverride,
    height = 270,
    includeHeader = true
}: {
    colorOverride?: string;
    height?: number;
    includeHeader?: boolean;
}) {
    const { durationToMinApy: durationToMinApyCached, principalMint, isCustodian } = useMarketContext();

    const { presets, collateralMintsForQueries } = useQuotesContext();
    const durationToMinApyLive = useGlobalBestOffers({
        principalMint,
        collateralMints: collateralMintsForQueries,
        presets,
        skip: isCustodian // best quotes won't work for custodian
    });

    const durationToMinApy = durationToMinApyLive ?? durationToMinApyCached;

    const ratesInSeconds = durationToMinApy?.map(({ duration, durationType, apy }) => {
        return {
            rate: apy,
            seconds: getDurationInSeconds(duration, durationType)
        };
    });

    const formattedDurationBySeconds = useMemo(() => {
        if (!durationToMinApy) return undefined;
        return new Map(
            durationToMinApy.map(({ duration, durationType }) => [
                getDurationInSeconds(duration, durationType),
                formatDurationWithType(duration, durationType)
            ])
        );
    }, [durationToMinApy]);

    const content = useMemo(() => {
        if (ratesInSeconds?.length === 0) return <EmptyChart />;

        return (
            <PriceLineChartWithAxis
                keys={[{ key: "rate", color: colorOverride }]}
                tooltip={({ rate: apy, date }) => {
                    return (
                        <Column spacing={0.5}>
                            <Text>{formatPercent(apy, { customDecimals: PERCENT_DECIMALS })} APY</Text>
                            <Text color="caption">{formatSeconds(date)}</Text>
                        </Column>
                    );
                }}
                formatXAxis={(seconds) => formattedDurationBySeconds?.get(seconds) ?? formatSeconds(seconds)}
                formatYAxis={formatChartPercent}
                height={height}
                data={ratesInSeconds?.filter(filterNullableRecord)?.map((d) => ({ rate: d.rate, date: d.seconds }))}
            />
        );
    }, [colorOverride, formattedDurationBySeconds, height, ratesInSeconds]);

    if (includeHeader) {
        return (
            <Column width="100%" spacing={2}>
                <TooltipText helpText="Lowest available APY by duration" variant="h4">
                    Yield curve
                </TooltipText>

                {content}
            </Column>
        );
    }

    return <> {content} </>;
});
