import { useMemo } from "react";

import {
    AbfLoanExpanded,
    BsMetaUtil,
    isSimpleLoan,
    LoanInterestDueInfo,
    summarizeChangesFromNewInterest
} from "@bridgesplit/abf-react";
import { StatProps, StatColumn } from "@bridgesplit/ui";
import { formatDate } from "@bridgesplit/utils";

export default function RepayStats({
    paymentInfo,
    loanExpanded
}: {
    paymentInfo: LoanInterestDueInfo;
    loanExpanded: AbfLoanExpanded | undefined;
}) {
    const summary = useMemo(
        () => (loanExpanded ? summarizeChangesFromNewInterest(loanExpanded, paymentInfo) : undefined),
        [loanExpanded, paymentInfo]
    );

    const isSimple = isSimpleLoan(loanExpanded);

    const totalDebt = useMemo(() => loanExpanded?.debt, [loanExpanded]);
    const newTotalDebt = useMemo(() => {
        if (!totalDebt) return 0;
        // simple loans pay fees in single txn so prepayment/late fees are included
        if (isSimple) {
            return Math.max(totalDebt?.total - paymentInfo.repaymentAmount, 0);
        }
        if (!summary) return 0;

        return summary.outstanding[1];
    }, [isSimple, paymentInfo.repaymentAmount, summary, totalDebt]);

    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    const stats: StatProps[] = [
        { value: formatDate(summary?.dueDate, "date", { showTime: "always" }), caption: "Due by", hide: isSimple },
        {
            value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, summary?.interestSaved),
            caption: "Interest saved",
            textColor: "success",
            hide: isSimple || !summary?.interestSaved
        },
        {
            value: summary?.earlyFees[0]
                ? summary.earlyFees
                : BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, summary?.earlyFees?.[1]),
            caption: "Prepayment penalty",
            textColor: "error",
            hide: isSimple || !summary?.earlyFees.some((f) => !!f)
        },
        {
            value: [totalDebt?.total, newTotalDebt],
            caption: isSimple ? "Total debt" : "Total outstanding",
            symbol
        },
        {
            value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, summary?.lateFee),
            caption: "Late fee",
            textColor: "error",
            hide: !summary?.lateFee,
            symbol
        }
    ];
    return <StatColumn loading={!summary} stats={stats} />;
}
