import { useCallback, useEffect, useMemo } from "react";

import { Column, FONT_SIZES, Image, Span, StatColumn, TextButton, TextSkeleton } from "@bridgesplit/ui";
import { findMinElement, formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { AppDialog } from "app/utils";
import { useExternalYieldVaults } from "@bridgesplit/abf-react";
import { TransactionTypes } from "app/types";

import { useMarketLendContext } from "./MarketLendContext";
import { useMarketDialog } from "../util";
import { useSelectStrategyTemplate } from "./util";
import { useMarketContext } from "../common";
import { useTransactionFeeStat } from "../../transactions";

export default function LendLiteStats() {
    const transactionFeeStat = useTransactionFeeStat({ transactionType: TransactionTypes.MarketLend });

    return (
        <Column spacing={0.5}>
            <StatColumn
                stats={[
                    {
                        caption: COPY.STRATEGY_TEMPLATE_TERM,
                        value: <SelectTemplate />
                    },
                    {
                        caption: COPY.EXTERNAL_YIELD_APY_TERM,
                        tooltip: COPY.EXTERNAL_YIELD_APY_TOOLTIP,
                        value: <ExternalYieldApy />
                    },
                    {
                        caption: COPY.STRATEGY_APY_TERM,
                        tooltip: COPY.STRATEGY_APY_TOOLTIP_LITE,
                        value: <Apy />
                    },
                    transactionFeeStat
                ]}
            />
        </Column>
    );
}

function ExternalYieldApy() {
    const { principalMint } = useMarketContext();
    const { getYieldVault, isLoading } = useExternalYieldVaults();

    if (isLoading) return <TextSkeleton variant="body1" width="60px" />;

    return <>{formatPercent(getYieldVault(principalMint)?.apy ?? 0)}</>;
}

function Apy() {
    const { form, setForm } = useMarketLendContext();

    const { principalMint } = useMarketContext();

    const lowestApy = useMemo(
        () => findMinElement(Array.from(form.presetToApy?.values()), (v) => v),
        [form.presetToApy]
    );

    const setApy = useCallback(
        (apy: number | undefined) =>
            setForm((prev) => {
                const activeTermIds = Array.from(prev.presetToApy?.keys() ?? []);
                const presetToApy = new Map(activeTermIds.map((m) => [m, apy]));

                return { ...prev, presetToApy };
            }),
        [setForm]
    );

    // in case any apys are leftover from advanced edit
    useEffect(() => {
        if (lowestApy && Array.from(form.presetToApy.values()).some((v) => v !== lowestApy)) {
            setApy(lowestApy);
        }
    }, [form.presetToApy, lowestApy, setApy]);

    if (form.templateApyInitializedForMint !== principalMint) return <TextSkeleton variant="body1" width="60px" />;

    return <>{formatPercent(lowestApy, { skipMultiplication: true })}</>;
}

function SelectTemplate() {
    const {
        form: { templateId },
        templates
    } = useMarketLendContext();
    const select = useSelectStrategyTemplate();

    const template = templates?.find(({ template }) => template.identifier === templateId);
    const { open } = useMarketDialog();

    return (
        <TextButton
            disabled={!templates?.length}
            onClick={() => open(AppDialog.TemplateSelect, { select, templates: templates ?? [] })}
        >
            <Image variant="circle" size={FONT_SIZES.body1 + "px"} src={template?.template.logo} />
            <Span>{template ? template.template.name : <TextSkeleton variant="body1" width="70px" />}</Span>

            <ExpandMoreOutlined sx={{ color: (theme) => theme.palette.text.disabled }} />
        </TextButton>
    );
}
