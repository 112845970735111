import { skipToken } from "@reduxjs/toolkit/dist/query";
import { NullableRecord, combineCollections, filterAllKeysDefined, removeDuplicates } from "@bridgesplit/utils";
import { AbfOrderFundingType } from "@bridgesplit/abf-sdk";

import { useOrdersQuery } from "../reducers";
import { isCollateralSplMint, isPendingOffer, isValidOffer, useAbfTypesToUiConverter } from "../utils";
import { OrderFilter, AbfOrderExpanded, AbfOrderExpandedBase } from "../types";
import { useGetSyndicatesByOrders } from "./syndicated";
import { useEscrowAccounts } from "./escrow";
import { useActiveGroup, useGroupsByEscrows } from "./group";
import { useSkipUnauthenticated } from "./auth";

export function useOrders(params: OrderFilter, options?: { skip?: boolean }) {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const { data: rawData, ...rest } = useOrdersQuery(params, { skip: options?.skip || skipIfUnauthenticated });

    const principalMints = Object.values(rawData?.orders ?? {}).map((o) => o.order.principalMint);
    const collateralMints = Object.values(rawData?.loanRequests ?? {})
        .map((req) => req.assetInfos.filter((m) => isCollateralSplMint(m)).map((m) => m.assetKey))
        .flat();

    const {
        getMetadata,
        convertOrder,
        convertLoanRequest,
        convertLoanRequestCollateral,
        tokensLoading,
        convertOrderFeeSchedule,
        convertOrderSchedule
    } = useAbfTypesToUiConverter(combineCollections([principalMints, collateralMints]));

    const orders = rawData ? Object.values(rawData.orders) : undefined;
    const loanRequests = rawData ? Object.values(rawData.loanRequests) : undefined;

    const escrowsForGroupLookup =
        orders && loanRequests
            ? orders
                  .filter(({ order: { fundingType } }) => fundingType === AbfOrderFundingType.Standard)
                  .map((o) => o.order.maker)
                  .concat(loanRequests?.map((l) => l.loanRequest.maker))
            : undefined;

    const syndicatesOrders = orders
        ?.filter(({ order: { fundingType } }) => fundingType === AbfOrderFundingType.Syndicated)
        .map(({ order: { address } }) => address);
    const { getSyndicateByOrder } = useGetSyndicatesByOrders(syndicatesOrders);

    const { cache: escrowGroupCache } = useGroupsByEscrows(escrowsForGroupLookup);
    const data = orders
        ?.map(({ order, orderSchedule }): NullableRecord<AbfOrderExpanded> => {
            const request = rawData?.loanRequests[order.collateralMint];
            const loanRequest = request ? convertLoanRequest(request?.loanRequest) : undefined;
            const principalMetadata = getMetadata(order.principalMint);
            const escrowedAsset = rawData?.escrowedAssets[order.maker]?.[order.principalMint];
            const custodianIdentifiers = request ? removeDuplicates(Object.values(request?.custodianMapping)) : [];
            const feeScheduleRaw = rawData?.feeInfos?.[order.address];
            const base: NullableRecord<Omit<AbfOrderExpandedBase, "orderMaker" | "type">> = {
                principalMetadata,
                order: convertOrder(order),
                orderSchedule: convertOrderSchedule(orderSchedule),
                custodianIdentifiers,
                isPrincipalFunded: !!escrowedAsset && escrowedAsset.amount >= order.principalAmount,
                loanRequest,
                collateral: request ? convertLoanRequestCollateral(request?.assetInfos) : undefined,
                requestMaker: loanRequest?.maker ? escrowGroupCache.get(loanRequest?.maker)?.group : undefined,
                feeSchedule: feeScheduleRaw ? convertOrderFeeSchedule(feeScheduleRaw) : { earlyFees: [], lateFees: [] }
            };

            if (order.fundingType === AbfOrderFundingType.Syndicated) {
                const syndicateExpanded = getSyndicateByOrder(order.address);
                return { ...base, type: "syndicated", syndicateExpanded, orderMaker: syndicateExpanded?.maker };
            }

            return { ...base, type: "standard", orderMaker: escrowGroupCache.get(order.maker)?.group };
        })
        ?.filter(filterAllKeysDefined);

    return { data: tokensLoading ? undefined : data?.filter((o) => isValidOffer(o)), ...rest };
}

export function useCollateralOrders(mint: string | undefined, options?: { skip?: boolean }) {
    const { data: orders } = useOrders({ collateral: mint }, { skip: options?.skip });
    return orders?.filter((o) => o.isPrincipalFunded && isPendingOffer(o));
}

export function useLenderOrderForEscrow(lenderEscrow: string | undefined, options?: { skip: boolean }) {
    const { data: orders } = useOrders(
        {
            makers: [lenderEscrow ?? ""]
        } ?? skipToken,
        { skip: !lenderEscrow || options?.skip }
    );

    return orders?.filter((o) => isPendingOffer(o));
}

export function useLenderOrders(options?: { skip: boolean }) {
    const { escrowPubkeys } = useEscrowAccounts();
    const { data: orders } = useOrders(
        {
            makers: escrowPubkeys
        } ?? skipToken,
        { skip: !escrowPubkeys || options?.skip }
    );

    return orders?.filter((o) => isPendingOffer(o));
}

export function useBorrowerOrders() {
    const { groupIdentifier } = useActiveGroup();

    const { data: orders } = useOrders(
        {
            designated_takers: [groupIdentifier ?? ""]
        } ?? skipToken,
        { skip: !groupIdentifier }
    );
    return orders?.filter((o) => o.isPrincipalFunded && isPendingOffer(o));
}

export function useCustodianOrders(custodianIdentifier: string | undefined) {
    const { data: orders } = useOrders(
        {
            custodian: custodianIdentifier
        } ?? skipToken,
        { skip: !custodianIdentifier }
    );

    return orders?.filter((o) => o.isPrincipalFunded && isPendingOffer(o));
}
