import { ReactNode } from "react";

import { SphereUserBankAccount } from "@bridgesplit/abf-sdk";
import { FormInputType } from "@bridgesplit/react";
import { Column, Row, Select, StatProps, Text, TextLink, TooltipText } from "@bridgesplit/ui";
import { AccountBalanceOutlined } from "@mui/icons-material";
import { SPHERE_CONVERSION_FEE_BPS, SPHERE_FEE_ENABLED } from "@bridgesplit/abf-react";
import { formatPercent, bpsToUiDecimals, USDC_SYMBOL, formatUsd } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { formatBank } from "./util";
import { TransferStats } from "../transfers/types";

export function BankSelect({
    label,
    bankIndex,
    setBankIndex,
    banks
}: {
    label: string;
    bankIndex: number;
    setBankIndex: FormInputType<number>;
    banks: SphereUserBankAccount[] | undefined;
}) {
    const { open } = useAppDialog();
    const bank = banks?.[bankIndex];

    return (
        <Column spacing={1}>
            <Row spaceBetween>
                <Text color="caption" variant="body2">
                    {label}
                </Text>
                {!!banks?.length && (
                    <TextLink onClick={() => open(AppDialog.AddBank, undefined)} underlineLink="always" variant="body2">
                        {AppDialog.AddBank}
                    </TextLink>
                )}
            </Row>
            <Select
                loading={!banks}
                renderValue={() => (
                    <Text>
                        <AccountBalanceOutlined /> {formatBank(bank)}
                    </Text>
                )}
                value={bankIndex}
                setValue={setBankIndex}
                options={banks?.map((b, i) => ({ value: i, label: formatBank(bank) })) ?? []}
            />
        </Column>
    );
}

export function SphereCtaInfoWrapper({ children }: { children: ReactNode }) {
    return (
        <Column spacing={1}>
            <Text variant="body2" color="caption">
                ACH transfers usually take 1-3 business days
            </Text>
            {children}
        </Column>
    );
}

export function useSphereStats({ side, fee, withdrawAmount, receiveAmount, inputAmount }: TransferStats): StatProps[] {
    const isDeposit = side === "deposit";
    const feePercent = formatPercent(bpsToUiDecimals(SPHERE_CONVERSION_FEE_BPS));
    return [
        {
            caption: `Withdrawn from ${isDeposit ? "Bank" : "Loopscale"}`,
            value: SPHERE_FEE_ENABLED ? withdrawAmount : inputAmount,
            isUsd: isDeposit ? true : false,
            symbol: isDeposit ? undefined : USDC_SYMBOL
        },
        {
            caption: "Processing fee",
            tooltip: SPHERE_FEE_ENABLED
                ? `All ACH bank transfers incur a ${feePercent} conversion fee paid to our payment processors`
                : undefined,
            value: <FeeDisplayStat fee={fee} />
        },

        {
            caption: `Received on ${isDeposit ? "Loopscale" : "Bank"}`,
            value: SPHERE_FEE_ENABLED ? receiveAmount : inputAmount,
            isUsd: isDeposit ? false : true,
            symbol: isDeposit ? USDC_SYMBOL : undefined
        }
    ];
}
function FeeDisplayStat({ fee }: { fee: number }) {
    const formatted = formatUsd(fee);
    const feePercent = formatPercent(bpsToUiDecimals(SPHERE_CONVERSION_FEE_BPS));

    if (SPHERE_FEE_ENABLED) {
        return <Text color={fee > 0 ? "error" : "body"}>{formatted}</Text>;
    }

    return (
        <Row spacing={0.5}>
            <TooltipText
                helpText={`${feePercent} ACH transfer fees waived for a limited time`}
                color="disabled"
                sx={{ textDecoration: "line-through", flexDirection: "row-reverse" }}
            >
                {formatted}
            </TooltipText>
            <Text color="success">{formatUsd(0)}</Text>
        </Row>
    );
}
