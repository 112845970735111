import { Result, getReadableErrorMessage } from "@bridgesplit/utils";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type MutationResult<T> =
    | {
          data: T;
      }
    | {
          error: FetchBaseQueryError | SerializedError;
      };
export async function mutationIntoResult<Body, Response>(
    promise: (body: Body) => Promise<MutationResult<Response>>,
    body: Body,
    taskDescription: string,
    options?: { skipSentry?: boolean; useCustomErrorMessage?: boolean }
): Promise<Result<Response>> {
    const res = await promise(body);
    if ("error" in res)
        return Result.errWithDebug(
            options?.useCustomErrorMessage ? taskDescription : getReadableErrorMessage(taskDescription),
            res,
            { skipSentry: options?.skipSentry }
        );
    return Result.ok(res.data);
}

export async function mutationIntoRawResult<Body, Response>(
    promise: (body: Body) => Promise<MutationResult<Response>>,
    body: Body,
    options?: { skipSentry?: boolean }
): Promise<Result<Response>> {
    const res = await promise(body);

    if ("error" in res)
        return Result.err(res, {
            // skip sentry if not specified since raw handling has custom error handling
            skipSentry: options?.skipSentry ?? true
        });
    return Result.ok(res.data);
}
