import { ReactNode, useCallback, useMemo, useState } from "react";

import { ChainId } from "@bridgesplit/abf-sdk";
import {
    Column,
    Row,
    Text,
    Icon,
    TooltipText,
    PollingWithProgress,
    Checkbox,
    SkeletonRounded,
    VerticalScrollContainer,
    repeatElement,
    Image,
    ExpandContent,
    TagTextAlert,
    useCopyAddress,
    useAppPalette,
    SearchInput,
    StatProps
} from "@bridgesplit/ui";
import {
    BsMetaUtil,
    EvmLiteNft,
    TokenBalanceExpanded,
    abfEscrowedAssetPublicApi,
    isValidPublicKey,
    useAbfFetches,
    useActiveWallet,
    useUserCustodianPermissions
} from "@bridgesplit/abf-react";
import { IS_LOCAL_NX_DEV, formatAddress, textContains } from "@bridgesplit/utils";
import { FormInputType } from "@bridgesplit/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { COPY } from "app/constants";

import { EvmBalance, SelectChain } from "../wormhole";
import { ChainAddressInput, getChainMeta } from "../common";

export function useSelectAddressController(params?: { alwaysCustom?: boolean }) {
    const [addressState, setAddress] = useState<string>();
    const { isMpcActive, activeWallet } = useActiveWallet();

    const useCustomAddress = params?.alwaysCustom || isMpcActive;
    const address = useCustomAddress ? addressState : activeWallet?.wallet;

    const AddressComponent = useCallback(() => {
        if (!useCustomAddress) return null;
        return <ChainAddressInput chainId={ChainId.Solana} label="To" value={address} setValue={setAddress} />;
    }, [address, useCustomAddress]);

    const stats: StatProps[] = [
        { value: COPY.ESCROW_TERM_FULL, caption: "From" },
        { value: formatAddress(address), caption: "To", hide: useCustomAddress }
    ];

    const errorMessage = useMemo(() => {
        if (!address) return "Enter Address";
        if (!isValidPublicKey(address)) return "Invalid address";
        return undefined;
    }, [address]);

    return {
        address,
        AddressComponent,
        stats,
        errorMessage
    };
}

// Separate component to allow nesting within SelectChain context
export function EvmBalanceWrapperWithSwitch({ children }: { children: ReactNode }) {
    const { chain } = SelectChain.useState();
    return <EvmBalance.Wrapper chain={chain}>{children}</EvmBalance.Wrapper>;
}

export function RefreshEscrowsButton() {
    const { activeWallet } = useActiveWallet();
    const { resetEscrowedApi, resetBridgeTransactions } = useAbfFetches();

    const { isFetching } = abfEscrowedAssetPublicApi.endpoints.tokensByWallet.useQueryState(
        activeWallet?.wallet ?? skipToken
    );
    return (
        <PollingWithProgress
            skip={IS_LOCAL_NX_DEV}
            helpText="Refresh your received assets"
            isFetching={isFetching}
            callback={() => {
                resetEscrowedApi();
                resetBridgeTransactions();
            }}
        />
    );
}

type LiteAsset = {
    name: string;
    image: string;
    description?: string;
    isFungible: boolean;
    mint: string;
    warningWithTooltip?: string;
    disabled?: boolean;
};
type SelectLiteAssetsProps = {
    selected: Set<string>;
    setSelected: FormInputType<Set<string> | undefined>;
    assets: LiteAsset[] | undefined;
    emptyText: string;
    loading?: boolean;
    label?: string;
    allowSearch?: boolean;
    overflowAThreshold?: number;
};

export function SelectLiteAssets({
    selected,
    setSelected,
    assets,
    emptyText,
    loading,
    allowSearch,
    label,
    overflowAThreshold = 5
}: SelectLiteAssetsProps) {
    const { error, hoverBackground } = useAppPalette();
    const [search, setSearch] = useState<string>("");

    const filteredAssets = assets?.filter((a) => textContains(a.name, search));

    const toggleAll = useCallback(
        function () {
            if (!assets) return;
            const allSelected = [...assets.map((a) => a.mint)];
            setSelected(selected.size ? new Set() : new Set(allSelected));
        },
        [assets, selected.size, setSelected]
    );

    if (!assets || loading) {
        return <Column spacing={1}> {repeatElement(<SkeletonRounded height="20px" />, 3)}</Column>;
    }

    if (assets.length === 0) {
        return <Text color="disabled">{emptyText}</Text>;
    }

    return (
        <Column spacing={2}>
            {!!assets.length && (
                <Row onClick={toggleAll} sx={{ cursor: "pointer" }} spaceBetween>
                    <Text color="caption">{selected.size} selected </Text>
                    <Checkbox
                        indeterminate={!!selected.size && selected.size !== assets.length}
                        checked={selected.size === assets.length}
                        onChange={toggleAll}
                    />
                </Row>
            )}

            {allowSearch && (
                <SearchInput placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
            )}

            <VerticalScrollContainer
                maxHeight={overflowAThreshold * 50}
                isOverflowing={!!assets && assets.length > overflowAThreshold}
                sx={{ mx: -2 }}
            >
                {label && (
                    <Text color="caption" variant="body2">
                        {label}
                    </Text>
                )}
                {filteredAssets?.map(({ isFungible, image, name, mint, warningWithTooltip, disabled, description }) => {
                    const isSelected = selected.has(mint);
                    return (
                        <Row
                            key={mint}
                            spaceBetween
                            onClick={() => {
                                if (disabled) return;
                                const copy = new Set(selected);
                                isSelected ? copy.delete(mint) : copy.add(mint);
                                setSelected(copy);
                            }}
                            sx={{
                                px: 2,
                                py: 0.5,
                                ":hover": { background: hoverBackground },
                                cursor: disabled ? "not-allowed" : "pointer",
                                opacity: disabled ? 0.5 : 1
                            }}
                        >
                            <Row spacing={1}>
                                <Image variant={isFungible ? "circle" : "rounded"} size="25px" src={image} />
                                <Column>
                                    <TooltipText icon={false} helpText={warningWithTooltip}>
                                        {name} {warningWithTooltip && <Icon type="warning" sx={{ color: error }} />}
                                    </TooltipText>
                                    {description && (
                                        <Text color="caption" variant="body2">
                                            {description}
                                        </Text>
                                    )}
                                </Column>
                            </Row>
                            <Checkbox disabled={disabled} checked={!!isSelected} />
                        </Row>
                    );
                })}
            </VerticalScrollContainer>
        </Column>
    );
}

type WithdrawDepositsProps = Omit<SelectLiteAssetsProps, "assets"> & {
    assets: TokenBalanceExpanded[] | undefined;
    chain: ChainId;
};
export function WithdrawChainDeposits({ assets, chain, ...props }: WithdrawDepositsProps) {
    const meta = getChainMeta(chain);
    return (
        <SelectLiteAssets
            {...props}
            assets={assets?.map(({ metadata, mint, amount }) => ({
                image: BsMetaUtil.getImage(metadata),
                name: BsMetaUtil.formatAmount(metadata, amount),
                isFungible: BsMetaUtil.isFungible(metadata),
                mint,
                warningWithTooltip: (() => {
                    const sourceChain = BsMetaUtil.getChainId(metadata);
                    return sourceChain !== chain
                        ? `${BsMetaUtil.getName(metadata)} originated from ${
                              getChainMeta(sourceChain).name
                          } and you are withdrawing to ${meta.name}`
                        : undefined;
                })()
            }))}
        />
    );
}

export function describeAssetTransfer(assets: EvmLiteNft[] | undefined) {
    return assets?.length === 1 ? assets[0].name : `${assets?.length} assets`;
}

export function AcceptedCustodians({ chain }: { chain: ChainId }) {
    const custodians = useUserCustodianPermissions();
    const copy = useCopyAddress();
    return (
        <>
            <ExpandContent textColor="caption" header="Supported Assets">
                {custodians
                    ?.filter((c) => (c.custodian.sourceChain ?? ChainId.Solana) === chain)
                    ?.map(({ custodian: { groupIdentifier, logo, name, sourceContract } }) => (
                        <Row spaceBetween key={groupIdentifier}>
                            <Row spacing={1}>
                                <Image variant="circle" src={logo} size="20px" />
                                <Text> {name} </Text>
                            </Row>
                            {sourceContract && (
                                <TooltipText
                                    onClick={() => copy(sourceContract)}
                                    icon={false}
                                    isLink
                                    helpText="Copy contract address"
                                >
                                    {formatAddress(sourceContract)}
                                </TooltipText>
                            )}
                        </Row>
                    ))}
            </ExpandContent>
            <TagTextAlert color="body">
                <Icon type="warning" /> Any unsupported assets sent to this address will be permanently lost
            </TagTextAlert>
        </>
    );
}
