import {
    useCallback,
    useEffect,
    useRef,
    useState,
    ComponentType,
    lazy as originalLazy,
    useMemo,
    RefObject
} from "react";

import { IS_LOCAL_NX_DEV, formatAddress, groupArrayElementsBy, sleep } from "@bridgesplit/utils";
import { AppCookie, useAlert } from "@bridgesplit/react";
import { useLocation } from "react-router-dom";

export function useLoadedImageSrc(src: string | undefined) {
    const [loadedSrc, setLoadedSrc] = useState<string | null>(null);

    useEffect(() => {
        setLoadedSrc(null);
        if (!src) return;
        const handleLoad = () => {
            if (src === "") return;
            setLoadedSrc(src);
        };
        const image = new Image();
        image.addEventListener("load", handleLoad);
        image.src = src;

        return () => {
            image.removeEventListener("load", handleLoad);
        };
    }, [src]);

    return loadedSrc;
}

export function useOutsideAlerter(onOutsideClick: () => void) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                // since popover overrides screen, need to target it using menu item
                const isSelect = (event.target as Element).className?.includes?.("MuiMenuItem-root");
                if (!isSelect) {
                    onOutsideClick();
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutsideClick, ref]);

    return ref;
}

export function useScrollDetector(refFromProps?: RefObject<HTMLDivElement> | null) {
    const [hasScrolled, setHasScrolled] = useState(false);
    const refElem = useRef<HTMLDivElement>(null);

    const ref = refFromProps ?? refElem;

    useEffect(() => {
        function handleScroll() {
            if (ref.current) {
                const { scrollTop, clientHeight } = ref.current;
                setHasScrolled(scrollTop > 20 && clientHeight > 20);
            }
        }

        const element = ref.current;
        if (element) {
            element.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScroll);
            }
        };
    }, [ref]);

    return { ref, hasScrolled };
}

export function useScrollPast() {
    const [hasScrolledPast, setHasScrolledPast] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (ref.current) {
            const targetPosition = ref.current.getBoundingClientRect();
            const midPoint = (targetPosition.top + targetPosition.bottom) / 2;
            if (midPoint < 0) {
                setHasScrolledPast(true);
            } else {
                setHasScrolledPast(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return { ref, hasScrolledPast };
}

export type SupportedKey = "ArrowUp" | "ArrowDown" | "Enter";
export function useMultipleKeyDetecter(
    keys: SupportedKey[],
    callbackMap: { [key in SupportedKey]?: () => void },
    skip?: boolean
) {
    const handle = useCallback(
        (e: KeyboardEvent) => {
            if (keys.includes(e.key as SupportedKey)) {
                callbackMap[e.key as SupportedKey]?.();
            }
        },
        [callbackMap, keys]
    );

    useEffect(() => {
        if (skip) return;
        document.addEventListener("keydown", handle);
        return () => {
            document.removeEventListener("keydown", handle);
        };
    }, [handle, skip]);
}

export function useKeyDetecter(key: SupportedKey, callback: () => void, skip?: boolean) {
    return useMultipleKeyDetecter([key], { [key]: callback }, skip);
}

export function useTabbableList(submit: (() => void) | (() => Promise<void>), dataCount: number | undefined) {
    const [activeIndex, setActiveIndex] = useState(0);
    const itemRefs = useRef<HTMLDivElement[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useMultipleKeyDetecter(["Enter", "ArrowDown", "ArrowUp"], {
        Enter: submit,
        ArrowUp: () => setActiveIndex((prev) => Math.max(prev - 1, 0)),
        ArrowDown: () => setActiveIndex((prev) => (dataCount && prev + 1 >= dataCount ? prev : prev + 1))
    });

    useEffect(() => {
        const containerElement = containerRef.current;

        const itemElement = itemRefs.current[activeIndex];
        if (!containerElement || !itemElement) return;

        const itemTop = itemElement.offsetTop;
        const itemBottom = itemTop + itemElement.offsetHeight;
        const containerTop = containerElement.scrollTop;
        const containerBottom = containerTop + containerElement.clientHeight;

        if (itemBottom > containerBottom) {
            containerElement.scrollTop = itemTop - containerElement.clientHeight - itemElement.offsetHeight;
        } else if (itemTop < containerTop) {
            containerElement.scrollTop = 0;
        }
    }, [activeIndex]);

    return { activeIndex, setActiveIndex, itemRefs, containerRef };
}

export function useOnInitialRender<T>(callback: () => T, skip?: boolean) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (skip || loaded) return;
        callback();
        setLoaded(true);
    }, [callback, loaded, skip]);

    return { loaded };
}

export function useOnInitialRenderRef<T>(callback: () => T, skip?: boolean) {
    const ref = useRef<boolean>();

    useEffect(() => {
        if (skip || !!ref.current) return;
        callback();
        ref.current = true;
    }, [callback, skip]);

    return { loaded: !!ref.current };
}

export function usePolling({ callback, interval, skip }: { callback: () => void; interval: number; skip?: boolean }) {
    const intervalId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (skip) return;
        // Clear the previous interval if it exists
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }

        // Set up a new interval
        intervalId.current = setInterval(() => {
            callback();
        }, interval);

        // Clean up the interval when the component unmounts
        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, [callback, interval, skip]);
}

/**
 * Refresh the page if user encounters a chunk error (cached page)
 * @references https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
type ImportComponent = () => Promise<{ default: ComponentType }>;
export const lazy = (importComponent: ImportComponent) =>
    originalLazy((async () => {
        const isPageHasBeenForceRefreshed = JSON.parse(AppCookie.get("page-has-been-force-refreshed") || "false");

        try {
            const component = await importComponent();
            AppCookie.set("page-has-been-force-refreshed", "false");
            return component;
        } catch (error) {
            if (!isPageHasBeenForceRefreshed) {
                AppCookie.set("page-has-been-force-refreshed", "true");
                window.location.reload();
                // Return a promise that never resolves to prevent further execution
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                return new Promise(() => {});
            } else {
                // Return a fallback component or null
                return { default: () => null };
            }
        }
    }) as ImportComponent);

export enum TriggerStatus {
    NotStarted,
    Ready,
    Fetching,
    Complete
}
export function useTriggerHook(callback: () => Promise<void>, options?: { skip?: boolean }) {
    const [status, setStatus] = useState<TriggerStatus>(TriggerStatus.NotStarted);

    useEffect(() => {
        if (status !== TriggerStatus.Ready || !!options?.skip) return;
        (async () => {
            setStatus(TriggerStatus.Fetching);
            await callback();
            setStatus(TriggerStatus.Complete);
        })();
    }, [callback, options?.skip, status]);

    return {
        trigger: () => setStatus(TriggerStatus.Ready),
        isLoading: [TriggerStatus.Fetching, TriggerStatus.Ready].includes(status)
    };
}

export function useWarnOnPageExit() {
    useEffect(() => {
        if (IS_LOCAL_NX_DEV) return; // interferes with react refreshes
        window.onbeforeunload = () => true;
    }, []);
}

export function useTimer(options?: { skip?: boolean; startSeconds?: number }) {
    const [seconds, setSeconds] = useState(options?.startSeconds ?? 0);

    useEffect(() => {
        if (options?.skip) return;
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [options?.skip]);

    return seconds;
}

export function useCopyToClipboard() {
    const { alert } = useAlert();
    const [recentlyCopied, setRecentlyCopied] = useState(false);

    return async function copyToClipboard(text: string, options?: { successMessage?: string }) {
        try {
            navigator.clipboard.writeText(text);
            if (options?.successMessage && !recentlyCopied) {
                alert(options.successMessage, "success");
                setRecentlyCopied(true);
            }
            const timer = setTimeout(() => {
                setRecentlyCopied(false);
            }, 3_000);
            return () => clearTimeout(timer);
        } catch {
            alert("Your browser does not support clipboard copy", "error");
        }
    };
}

export function useCopyAddress() {
    const copyToClipboard = useCopyToClipboard();

    return async function copyAddress(address: string | undefined, options?: { customText?: string }) {
        if (!address) return;
        const addressUi = options?.customText ?? formatAddress(address);
        const successMessage = `${addressUi} copied to clipboard`;
        await copyToClipboard(address, { successMessage });
    };
}

export function useMemoizedKeyMap<T, K extends string | number>(data: T[] | undefined, getKey: (t: T) => K) {
    return useMemoizedMap(data, getKey, (d) => d);
}

export function useMemoizedGroupedMap<T, K extends string | number>(data: T[] | undefined, getKey: (t: T) => K) {
    return useMemo(() => {
        if (!data) return undefined;
        return groupArrayElementsBy(data, getKey);
    }, [data, getKey]);
}

export function useMemoizedMap<T, K extends string | number, V>(
    data: T[] | undefined,
    getKey: (t: T) => K,
    getValue: (t: T) => V
) {
    return useMemo(() => {
        if (!data) return undefined;
        return new Map(data.map((d) => [getKey(d), getValue(d)]));
    }, [data, getKey, getValue]);
}

export function useObjectToMap<T extends string | number | symbol, K>(
    data: Record<T, K> | undefined
): Map<T, K> | undefined {
    return useMemo(() => {
        if (!data) return undefined;
        return new Map(Object.entries(data)) as Map<T, K>;
    }, [data]);
}

interface Dimensions {
    width: number;
    height: number;
}

export function useElementDimensions<T extends HTMLElement>(): [RefObject<T>, Dimensions] {
    const elementRef = useRef<T>(null);
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (elementRef.current) {
                const { width, height } = elementRef.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return [elementRef, dimensions];
}

export function useScrollToElement(hashName: string) {
    const ref = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const hash = location.hash.replace("#", "");

    useEffect(() => {
        if (!hash || !ref || hashName !== hash) return;

        ref.current?.scrollIntoView();
    }, [hash, hashName]);

    return { ref };
}

export function useResetWithCoolDown() {
    const [onCoolDown, setOnCoolDown] = useState(false);

    return async (reset: () => void) => {
        if (onCoolDown) return;
        setOnCoolDown(true);
        reset();
        // prevent spam refreshes
        await sleep(1_000);
        setOnCoolDown(false);
    };
}
