import {
    BsMetaUtil,
    LabelVisibility,
    LoopscalePointSource,
    PointsInfo,
    useCollateralMultipliers,
    useCollateralPointsSourcesUtil
} from "@bridgesplit/abf-react";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { Column, RewardIcon, Row, StatColumn, Text, Tooltip } from "@bridgesplit/ui";
import { TokenImage } from "app/components/common";
import { Divider } from "@mui/material";

import { TooltipContent, useCreateCollateralStats } from "./common";

interface CollateralLabelTooltipProps {
    header: JSX.Element;
    multipliers: PointsInfo[];
    hasMultiplier: boolean;
}

interface LabelAndRewardsProps {
    metadata: BsMetadata;
    loopscalePointSources: LoopscalePointSource[];
    visibility?: LabelVisibility;
    hideBasePointsWithZeroBonus?: boolean;
    showSymbolLabel?: boolean;
}
export function CollateralLabel({
    metadata,
    loopscalePointSources,
    visibility = LabelVisibility.ShowFull,
    hideBasePointsWithZeroBonus = true,
    showSymbolLabel = true
}: LabelAndRewardsProps): JSX.Element | null {
    const { hasPointSource, hasLoopPointMultiplierAboveBase, hasAnyPointSourceAboveBase } =
        useCollateralPointsSourcesUtil();

    const assetMint = metadata.assetMint;

    const multipliers = useCollateralMultipliers([metadata], loopscalePointSources);
    if (visibility === LabelVisibility.Hidden) return null;

    const header = <CollateralHeader metadata={metadata} />;
    const symbol = BsMetaUtil.getSymbol(metadata);

    const hasAnyPointSource = loopscalePointSources.some((source) => hasPointSource(assetMint, source));
    const hasMultiplier =
        hasLoopPointMultiplierAboveBase([metadata], loopscalePointSources ?? []) ||
        hasAnyPointSourceAboveBase([metadata]);

    if (!hasAnyPointSource || visibility === LabelVisibility.ShowBasic) {
        return <Text>{symbol}</Text>;
    }

    return (
        <Tooltip
            reverseColors
            title={<CollateralLabelTooltip header={header} multipliers={multipliers} hasMultiplier={hasMultiplier} />}
        >
            <Row sx={{ justifyContent: "right" }} spacing={1}>
                {showSymbolLabel && <Text underline>{symbol}</Text>}
                <RewardIcon hasMultiplier={hasMultiplier} />
            </Row>
        </Tooltip>
    );
}
interface CollateralHeaderProps {
    metadata: BsMetadata;
}

function CollateralHeader({ metadata }: CollateralHeaderProps): JSX.Element {
    const symbol = BsMetaUtil.getSymbolUnique(metadata);
    return (
        <Column spacing={1}>
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="sm" />
                <Text>{symbol}</Text>
            </Row>
            <Text variant="body2" color="caption">
                {`${symbol} is receiving boosted Loopscale rewards`}
            </Text>
        </Column>
    );
}

function CollateralLabelTooltip({ header, multipliers, hasMultiplier }: CollateralLabelTooltipProps): JSX.Element {
    const stats = useCreateCollateralStats(multipliers, hasMultiplier);

    return (
        <TooltipContent>
            {header}
            {stats && stats.length > 0 && (
                <>
                    <Divider />
                    <StatColumn stats={stats} />
                </>
            )}
        </TooltipContent>
    );
}
