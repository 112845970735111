export const DEPOSIT_ENDPOINT = "/txn/deposit";
export const WITHDRAW_ENDPOINT = "/txn/withdraw";
export const DEPOSIT_STAKED_SOL_ENDPOINT = "/txn/deposit_staked_sol";
export const WITHDRAW_STAKED_SOL_ENDPOINT = "/txn/withdraw_staked_sol";
export const TRANSFER_SA_TXN_ENDPOINT = "/txn/transfer_sa";

export const INSTANT_UNSTAKE_TXN_ROUTE = "/txn/instant_unstake";
export const ESCROW_TRANSFER_ENDPOINT = "/txn/escrow_transfer";
export const ESCROW_EDIT_ENDPOINT = "/txn/escrow_edit";
export const SPL_TRANSFER_ENDPOINT = "/txn/transfer";

export const CREATE_ORDER_ENDPOINT = "/txn/orders/create";
export const FILL_ORDER_ENDPOINT = "/txn/orders/fill";
export const CANCEL_ORDER_ENDPOINT = "/txn/orders/cancel";
export const SYNC_LEDGERS_ENDPOINT = "/ledger_accounts/sync";
export const REPAY_LOAN_ENDPOINT = "/txn/loans/repay";
export const REPAY_ZC_LOAN_ENDPOINT = "/txn/loans/repay/zc";

export const REPAY_MULTIPLE_LEDGERS_ENDPOINT = "/txn/loans/repay/multiple";
export const SETUP_LOAN_ENDPOINT = "/txn/loans/setup_loan";
export const ROLE_UPDATE_ENDPOINT = "/txn/roles/update";
export const GENERATE_SIGN_IN_TXN_ENDPOINT = "auth/sign_in";
export const CREATE_USER_MFI_ACCOUNT_ROUTE = "/txn/user_mfi/create";

export const LOAN_WITHDRAW_ENDPOINT = "/txn/loans/loan_withdraw";
export const ATOMIC_LOAN_END_ENDPOINT = "/txn/loans/end";
export const CHECK_DEFAULT_ENDPOINT = "/txn/loans/check_default";

// lockbox
export const INIT_LOCKBOX_ENDPOINT = "/lockbox/txn/init";
export const DEPOSIT_LOCKBOX_ENDPOINT = "/lockbox/txn/deposit";
export const WITHDRAW_LOCKBOX_ENDPOINT = "/lockbox/txn/withdraw";
export const TOPUP_LOCKBOX_ENDPOINT = "/txn/manage/spl";
export const TOPUP_STAKED_LOCKBOX_ENDPOINT = "/txn/manage/staked_sol";
export const UNLOCK_LOCKBOX_ENDPOINT = "/lockbox/txn/unlock";
export const DEPOSIT_LOCKBOX_STAKED_SOL_ENDPOINT = "/lockbox/txn/deposit_staked_sol";
export const WITHDRAW_LOCKBOX_STAKED_SOL_ENDPOINT = "/lockbox/txn/withdraw_staked_sol";
export const CLOSE_LOCKBOX_ENDPOINT = "/lockbox/txn/close";

// borrow more
export const LOAN_INCREASE_PRINCIPAL = "/txn/increase_credit";

// syndicated
export const INIT_SYNDICATED_ORDER_ENDPOINT = "/txns/init";
export const FUND_SYNDICATED_ORDER_ENDPOINT = "/txns/fund";
export const WITHDRAW_SYNDICATED_ORDER_ENDPOINT = "/txns/withdraw";
export const UPDATE_SYNDICATED_ORDER_ENDPOINT = "/txns/update";
export const REDEEM_SYNDICATED_ORDER_ENDPOINT = "/txns/redeem";
export const CANCEL_SYNDICATED_ORDER_ENDPOINT = "/txns/cancel";
export const PLACE_SYNDICATED_ORDER_ENDPOINT = "/txns/place";

// fee
export const PAY_FEE_ENDPOINT = "/txn/fees/pay";

// bs metadata
export const BS_METADATA_EVM_UPLOAD_ENDPOINT = "/txn/create/crosschain";

export const GET_JITO_TIP_TXN_ROUTE = "/txn/jito/tip";

export const CREATE_STRATEGY_TXN_ROUTE = "/txn/create_strategy";
export const CLOSE_STRATEGY_TXN_ROUTE = "/txn/strategy/close";

export const FILL_STRATEGY_ORDER_TXN_ROUTE = "/txn/create_and_fill_strategy_order";
export const UPDATE_LIQUIDATION_MANAGER_ROUTE = "/txn/update_liquidation_manager";

export const USER_WALLET_HEADER = "User-Wallet";
export const WALLET_TXN_HEADER = "Wallet-Transaction";
export const USER_ID_HEADER = "X-User-Id";
export const API_VERSION_HEADER = "X-Msrvc-Version";

export const UPDATE_ORDER_REFINANCE_TERMS_TXN_ROUTE = "/txn/update_order_refinance_terms";
export const GET_LOAN_SALE_TRANSACTION_ROUTE = "/txn/sale_transaction";
export const GET_DEBT_TRANSFER_TRANSACTION_ROUTE = "/txn/debt_transfer_transaction";

// loop
export const ROUTE_LOOP_WIND_TXN = "/txn/loop/wind";
export const ROUTE_LOOP_UNWIND_TXN = "/txn/loop/unwind";
export const ROUTE_LOOP_VAULT_SETUP_TXN = "/txn/loop/vault/setup";
// Meteora
export const METEORA_DEPOSIT_TXN = "/meteora/txn/deposit";
export const METEORA_WITHDRAW_TXN = "/meteora/txn/withdraw";

// Orca
export const ROUTE_ORCA_CLAIM_FEE_TXN = "/txn/orca/claim_fee";
export const ROUTE_ORCA_UPDATE_LIQUIDITY_TXN = "/txn/orca/update_liquidity";
export const ROUTE_ORCA_UPDATE_POSITION_TXN = "/txn/orca/update_position";
export const ROUTE_ORCA_DECREASE_LIQUIDITY = "/txn/orca/decrease_liquidity";
export const ROUTE_ORCA_INCREASE_LIQUIDITY = "/txn/orca/increase_liquidity";
