import { createApi } from "@reduxjs/toolkit/query/react";
import { ExternalYieldVault } from "@bridgesplit/abf-sdk";

import { ROUTE_GET_EXTERNAL_YIELD_INFO } from "../constants";
import { abfSerializeQueryArgs, napoleonPublicBaseQuery } from "./util";

const EXTERNAL_YIELD_API = "Napoleon";

// NOTE: `useExternalYieldInterestQuery` lives in napoleonApi.ts
export const externalYieldApi = createApi({
    reducerPath: "externalYieldApi",
    baseQuery: napoleonPublicBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [EXTERNAL_YIELD_API],
    endpoints: (builder) => ({
        externalYieldInfo: builder.query<ExternalYieldVault[], void>({
            query() {
                return {
                    url: ROUTE_GET_EXTERNAL_YIELD_INFO,
                    method: "GET"
                };
            }
        })
    })
});

export const { useExternalYieldInfoQuery } = externalYieldApi;
