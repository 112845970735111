import { LoopscalePointSource, PointsInfoWithoutMultiplier, PointSource } from "../types";

export const LOOPSCALE_POINTS_SOURCE_INFO: Record<LoopscalePointSource, PointsInfoWithoutMultiplier> = {
    [LoopscalePointSource.Borrow]: {
        program: PointSource.Loopscale,
        caption: "Borrow boost"
    },
    [LoopscalePointSource.Lend]: {
        program: PointSource.Loopscale,
        caption: "Lend boost"
    },
    [LoopscalePointSource.IdleCap]: {
        program: PointSource.Loopscale,
        caption: "Idle cap boost"
    }
};

export const POINTS_SOURCE_INFO: Record<PointSource, PointsInfoWithoutMultiplier> = {
    [PointSource.MarginFi]: {
        program: PointSource.MarginFi,
        caption: "mrgn rewards"
    },
    [PointSource.LoopscaleHidden]: {
        program: PointSource.LoopscaleHidden,
        caption: "Total multiplier"
    },
    [PointSource.Loopscale]: {
        program: PointSource.Loopscale,
        caption: "Total multiplier"
    },
    [PointSource.Jupiter]: {
        program: PointSource.Jupiter,
        caption: "Jupiter rewards"
    },
    [PointSource.Meteora]: {
        program: PointSource.Meteora,
        caption: "Meteora rewards"
    },
    [PointSource.HubSol]: {
        program: PointSource.HubSol,
        caption: "Loyalty league rewards"
    }
};

// The USD per unit amount. Currently points per day is the rate per usd per $1000
export const POINTS_BASIS = 1000;

interface LoopscalePointCaptionInfo {
    full: string;
    short: string;
}

export const LOOPSCALE_POINT_CAPTIONS: Record<LoopscalePointSource, LoopscalePointCaptionInfo> = {
    [LoopscalePointSource.Borrow]: {
        full: "Borrow multiplier",
        short: "Borrow"
    },
    [LoopscalePointSource.Lend]: {
        full: "Lend multiplier",
        short: "Lend"
    },
    [LoopscalePointSource.IdleCap]: {
        full: "Idle capital multiplier",
        short: "Idle capital"
    }
};
