import { CheckListProps, CheckList, Icon } from "@bridgesplit/ui";
import { AbfGroupType, useGroup, useUserIdentity, useUserSphere } from "@bridgesplit/abf-react";
import {
    AssignmentOutlined,
    BadgeOutlined,
    DocumentScannerOutlined,
    FolderSharedOutlined,
    LocalAtmOutlined
} from "@mui/icons-material";
import { KycStatus, UboStatus } from "@bridgesplit/abf-sdk";
import { Hidable } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { useOpenSphereTermsDialog } from "./util";

export default function BankChecklist() {
    const identity = useUserIdentity();
    const { open } = useAppDialog();
    const { activeGroup } = useGroup();
    const isBusiness = activeGroup?.groupType !== AbfGroupType.Individual;

    const openSphereTerms = useOpenSphereTermsDialog();
    const sphere = useUserSphere();

    const items: Hidable<CheckListProps>[] = [
        {
            icon: <Icon type="kyc" />,
            title: "Verify identity",
            description:
                "Verify your identity before adding a bank account. Loopscale partners with Stripe for secure identity verification",
            completed: identity.isVerified,
            cta: () => open(AppDialog.KYC, {}),
            ctaText: identity.status === KycStatus.NotSubmitted ? "Verify with Stripe" : "Check Status"
        },
        {
            icon: <LocalAtmOutlined />,
            title: "Register for cash-to-USDC transfers",
            description:
                "Transfer between cash in your bank account to USDC (digital dollars). Loopscale partners with Sphere for secure payments",
            completed: sphere.accountCreated,
            cta: () =>
                open(isBusiness ? AppDialog.RegisterBusinessSphere : AppDialog.RegisterIndividualSphere, undefined),
            ctaText: "Register with Sphere"
        },

        {
            icon: <DocumentScannerOutlined />,
            title: "Upload company formation documentation",
            description: "Upload corporate documentation verifying the formation of your company",
            completed: !!sphere.kyb?.incorporationDocumentUploaded,
            cta: () => open(AppDialog.UploadSphereDocument, { type: "incorporation" }),
            ctaText: AppDialog.UploadSphereDocument,
            hide: !isBusiness
        },
        {
            icon: <FolderSharedOutlined />,
            title: "Upload UBO documentation",
            description: "Upload documentation verifying your company's Ultimate Beneficial Owners (UBOs)",
            completed: !!sphere.kyb?.uboDocumentUploaded,
            cta: () => open(AppDialog.UploadSphereDocument, { type: "ubo" }),
            ctaText: AppDialog.UploadSphereDocument,
            hide: !isBusiness
        },
        {
            icon: <AssignmentOutlined />,
            title: AppDialog.SphereAcceptTerms,
            description: "Accept the terms of service for Sphere, which Loopscale uses for secure payments",
            completed: sphere.termsOfServiceAccepted,
            cta: openSphereTerms.open,
            buttonProps: { loading: openSphereTerms.isLoading },
            ctaText: AppDialog.SphereAcceptTerms
        },
        {
            icon: <BadgeOutlined />,
            title: "Await identity verification for UBOs",
            description: "All of your UBOs must complete identity verification through Stripe",
            completed:
                !!sphere.kyb?.uboStatuses.length && sphere.kyb?.uboStatuses.every((s) => s.status === UboStatus.Added),
            cta: () => open(AppDialog.SphereUBOStatuses, undefined),
            ctaText: AppDialog.SphereUBOStatuses,
            hide: !isBusiness
        },
        {
            icon: <Icon type="bank" />,
            title: AppDialog.AddBank,
            description:
                "To deposit and withdraw cash, link a bank account. Loopscale partners with Plaid to link your bank account.",
            completed: !!sphere.bankAccounts?.length,
            cta: () => open(AppDialog.AddBank, undefined),
            ctaText: AppDialog.AddBank
        }
    ];

    return (
        <CheckList
            order="fixed"
            loading={sphere.isLoading || identity.isLoading}
            items={items.filter((i) => !i.hide)}
            expanded
        />
    );
}
