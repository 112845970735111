import { useCallback, useState } from "react";

import { LoanInfoSorting, LoanPaginationInfo } from "@bridgesplit/abf-react";
import { useLocalStorage } from "@bridgesplit/react";
import { SortDirection } from "@bridgesplit/utils";

const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_SORT_SIDE = SortDirection.Desc;
const DEFAULT_SORT_TYPE = LoanInfoSorting.Principal;

export function useLoansPagination({
    pageSize = DEFAULT_PAGE_SIZE,
    initialState
}: {
    pageSize?: number;
    initialState?: { principalMint?: string };
}) {
    const [sortSide, setSortSide] = useLocalStorage<SortDirection>("LoanCardsOrder", DEFAULT_SORT_SIDE);
    const [sortType, setSortType] = useLocalStorage<LoanInfoSorting>("LoanCardsSort", DEFAULT_SORT_TYPE);
    const [page, setPage] = useState<number>(0);
    const [activeOnly, setActiveOnly] = useState<boolean>(true);
    const [principalMintState, setPrincipalMint] = useState<string | undefined>();
    const principalMint = principalMintState ?? initialState?.principalMint;

    const params: LoanPaginationInfo = {
        page,
        pageSize,
        sortSide,
        sortType,
        activeOnly,
        principalMint
    };

    const reset = useCallback(() => {
        setSortSide(DEFAULT_SORT_SIDE);
        setSortType(DEFAULT_SORT_TYPE);
        setPage(0);
        setActiveOnly(true);
        setPrincipalMint(undefined);
    }, [setSortSide, setSortType, setPage, setActiveOnly, setPrincipalMint]);

    return {
        page,
        params,
        setSortSide,
        setSortType,
        setPage,
        setActiveOnly,
        setPrincipalMint,
        reset
    };
}
