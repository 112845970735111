import {
    Button,
    Icon,
    MediaQuery,
    MenuItemProps,
    MenuItems,
    PopoverWrapper,
    SPACING,
    usePopover
} from "@bridgesplit/ui";
import { Delete, ExpandMore, OpenInNewOutlined, SettingsOutlined, TuneOutlined } from "@mui/icons-material";
import { StrategyExpanded, useSupportedCollateral } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";
import { useNavigate } from "react-router-dom";

import { usePutStrategyCollateral } from "../terms/util";
import { getStrategyLoansPath } from "../../common";
import { useSignedTransactionSender } from "../../transactions";

export default function StrategyCardActions({
    strategyExpanded,
    query
}: {
    strategyExpanded: StrategyExpanded | undefined;
    query: MediaQuery;
}) {
    const { popoverProps, close, open } = usePopover();
    const items = useMenuItems(strategyExpanded);
    return (
        <>
            <Button sx={{ [query.below]: { width: "100%" } }} onClick={open} variant="outlined">
                More <ExpandMore />
            </Button>

            <PopoverWrapper
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ horizontal: "left", vertical: -SPACING }}
                {...popoverProps}
            >
                <MenuItems close={close} items={items} />
            </PopoverWrapper>
        </>
    );
}

function useMenuItems(strategyExpanded: StrategyExpanded | undefined): MenuItemProps[] {
    const { open } = useAppDialog();
    const putCollateral = usePutStrategyCollateral();
    const tokens = useSupportedCollateral(strategyExpanded?.principalMetadata.assetMint);
    const signedSender = useSignedTransactionSender();
    const navigate = useNavigate();
    if (!strategyExpanded) return [];

    const items: MenuItemProps[] = [
        {
            icon: <Icon type="edit" />,
            text: AppDialog.StrategyTerms,
            onClick: () => open(AppDialog.StrategyTerms, { strategy: strategyExpanded })
        },
        {
            icon: <TuneOutlined />,
            text: "Modify collateral",
            onClick: () =>
                open(AppDialog.MultiSelectCollateral, {
                    header: "Modify collateral",
                    collateral: strategyExpanded.collateral.map((c) => c.metadata.assetMint),
                    tokens,
                    setCollateralAsync: (collateral) =>
                        signedSender(
                            (signedTransaction) => putCollateral(signedTransaction, strategyExpanded, collateral),
                            { description: "Updating collateral" }
                        ),
                    principalMint: strategyExpanded?.principalMetadata.assetMint,
                    isAdvanced: true
                })
        },
        {
            icon: <SettingsOutlined />,
            text: AppDialog.StrategySettings,
            onClick: () => open(AppDialog.StrategySettings, { strategy: strategyExpanded })
        },
        {
            icon: <OpenInNewOutlined />,
            text: "View loans",
            onClick: () => navigate(getStrategyLoansPath(strategyExpanded?.strategy))
        },
        {
            icon: <Delete />,
            text: AppDialog.StrategyDelete,
            onClick: () => open(AppDialog.StrategyDelete, { strategy: strategyExpanded })
        }
    ];

    return items;
}
