import { ReactNode } from "react";

import { useActiveWallet } from "@bridgesplit/abf-react";

import { DialogHeader, AppButton } from "../common";
import { useVerifyWalletFromForm, useWalletTypeForm } from "../group";

export default function WalletRequiredWrapper({ children }: { children: ReactNode }) {
    const { userWalletsForGroup } = useActiveWallet();

    if (userWalletsForGroup?.length === 0) {
        return <RegisterNewWallet />;
    }

    return <> {children} </>;
}

function RegisterNewWallet() {
    const form = useWalletTypeForm();

    const verifyWallet = useVerifyWalletFromForm(form);
    const { component: walletTypeForm, buttonDisabled } = form;

    return (
        <>
            <DialogHeader
                header="Wallet submission required"
                description="Your wallet is currently unverified. You will need to verify your wallet before you can take actions on Loopscale"
            />

            {walletTypeForm}
            <AppButton
                isTransaction={false}
                disabled={buttonDisabled}
                asyncCta={{ onClickWithResult: verifyWallet, closeDialog: "onSuccess" }}
            >
                Verify wallet
            </AppButton>
        </>
    );
}
