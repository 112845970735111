import { useCallback, useMemo, useState } from "react";

import {
    BackCta,
    Button,
    Column,
    ConditionalLink,
    DialogWrapper,
    Icon,
    IconWithBackground,
    StepProgress,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { OnboardingStep, useMarkOnboardingStepComplete } from "@bridgesplit/abf-react";
import { VerifiedOutlined } from "@mui/icons-material";
import { colorToAlpha } from "@bridgesplit/utils";
import { PRIME_SLUG } from "app/constants";
import { useAppDialog } from "app/utils";

enum Step {
    Welcome,
    Borrow,
    Lend,
    Escrow
}

const STEPS_LENGTH = Object.keys(Step).length / 2;
export default function PrimeWelcomeDialog() {
    const [step, setStep] = useState(Step.Welcome);

    const { markAsComplete } = useMarkOnboardingStepComplete();
    const { close } = useAppDialog();

    const onFinish = useCallback(() => {
        markAsComplete([OnboardingStep.Prime]);
        close();
    }, [markAsComplete, close]);

    const next = useCallback(
        function () {
            if (step === STEPS_LENGTH - 1) {
                onFinish();
            } else {
                setStep((prev) => prev + 1);
            }
        },
        [onFinish, step]
    );

    const back = useCallback(function () {
        setStep((prev) => Math.max(prev - 1, 0));
    }, []);

    const content = useMemo(() => {
        switch (step) {
            case Step.Welcome:
                return <Welcome />;
            case Step.Borrow:
                return <Borrow />;
            case Step.Lend:
                return <Lend />;
            case Step.Escrow:
                return <Escrow />;
            default:
                return null;
        }
    }, [step]);

    return (
        <DialogWrapper sx={{ justifyContent: "space-between", minHeight: "360px" }}>
            <Column spacing={2}>
                <StepProgress step={step} totalSteps={STEPS_LENGTH} />
                {content}
            </Column>

            <Column spacing={1}>
                <ConditionalLink to={step === STEPS_LENGTH - 1 ? PRIME_SLUG : undefined}>
                    <Button fullWidth variant="contained" onClick={next}>
                        {step === STEPS_LENGTH - 1 ? "Explore Prime" : "Continue"}
                    </Button>
                </ConditionalLink>
                <Button fullWidth variant="outlined" onClick={onFinish}>
                    Close
                </Button>
                {step > 0 && <BackCta back={back} />}
            </Column>
        </DialogWrapper>
    );
}

function Welcome() {
    return (
        <>
            <Header header="You’re approved for Prime" icon={<VerifiedOutlined />} />

            <Text color="caption" variant="body2">
                You now have access to new asset classes, customizable loan terms, off-ramps, and much more
            </Text>
        </>
    );
}

const LOAN_BORROWER_COPY = [
    "Start borrowing by specifying your desired and collateral with a funding qequest",
    "Borrow against multiple tokenized assets as collateral",
    "Receive tailored loan offers from lenders"
];
const LOAN_LENDER_COPY = [
    "Access loan requests with tokenized assets as collateral",
    "Create advanced offers with configurable repayment schedules and risk settings"
];

function Lend() {
    return (
        <>
            <Header header="Send customized offers" icon={<Icon type="lend" />} />

            {LOAN_LENDER_COPY.map((line, i) => (
                <Text key={i} color="caption" variant="body2">
                    {line}
                </Text>
            ))}
        </>
    );
}

function Borrow() {
    return (
        <>
            <Header header="Unlock flexible borrowing" icon={<Icon type="borrow" />} />

            {LOAN_BORROWER_COPY.map((line, i) => (
                <Text key={i} color="caption" variant="body2">
                    {line}
                </Text>
            ))}
        </>
    );
}
const ESCROW_COPY = [
    "Send assets to your escrow account to reuse them across multiple different funding requests",
    "Withdraw assets from your escrow at any time by signing with your wallet"
];
function Escrow() {
    return (
        <>
            <Header header="Using your Loopscale escrow" icon={<Icon type="escrow" />} />

            {ESCROW_COPY.map((line, i) => (
                <Text key={i} color="caption" variant="body2">
                    {line}
                </Text>
            ))}
        </>
    );
}

function Header({ header, icon }: { header: string; icon: JSX.Element }) {
    const { prime } = useAppPalette();
    return (
        <>
            <IconWithBackground
                sx={{ color: prime, backgroundColor: colorToAlpha(prime, 0.1) }}
                fontWeight="normal"
                tagColor="secondary"
                size={50}
                variant="h3"
            >
                {icon}
            </IconWithBackground>
            <Text variant="h3"> {header} </Text>
        </>
    );
}
