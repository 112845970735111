import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { SYNDICATED_ORDERS_INVITE_ROUTE, GET_SYNDICATED_ORDERS_ROUTE } from "../constants";
import { abfSerializeQueryArgs, syndicatedBaseQuery } from "./util";
import { SyndicatedOrderFilter, SyndicatesResponse } from "../types";

const SYNDICATED_TAG = "Syndicated";

export interface InviteArgs {
    emails: string[];
    identifier: string;
}

export const syndicatedApi = createApi({
    reducerPath: "syndicatedApi",
    baseQuery: syndicatedBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [SYNDICATED_TAG],
    endpoints: (builder) => ({
        syndicatedOrders: builder.query<SyndicatesResponse, SyndicatedOrderFilter>({
            providesTags: [SYNDICATED_TAG],
            query(body) {
                return {
                    url: GET_SYNDICATED_ORDERS_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        createSyndicatedOrderInvites: builder.mutation<void, InviteArgs>({
            invalidatesTags: [SYNDICATED_TAG],
            query(body) {
                return {
                    url: `${SYNDICATED_ORDERS_INVITE_ROUTE}/${body.identifier}`,
                    method: "POST",
                    body: body.emails
                };
            }
        }),
        deleteSyndicatedOrderInvites: builder.mutation<void, InviteArgs>({
            invalidatesTags: [SYNDICATED_TAG],
            query(body) {
                return {
                    url: `${SYNDICATED_ORDERS_INVITE_ROUTE}/${body.identifier}`,
                    method: "DELETE",
                    body: body.emails
                };
            }
        })
    })
});

export const {
    useSyndicatedOrdersQuery,
    useCreateSyndicatedOrderInvitesMutation,
    useDeleteSyndicatedOrderInvitesMutation
} = syndicatedApi;

export const useSyndicatedApi = () => {
    const dispatch = useDispatch();
    const [fetchSyndicatedOrders] = syndicatedApi.endpoints.syndicatedOrders.useLazyQuery();
    return {
        fetchSyndicatedOrders,
        resetSyndicatedApi: () => dispatch(syndicatedApi.util.invalidateTags([SYNDICATED_TAG]))
    };
};
