import { ChainId } from "@bridgesplit/abf-sdk";
import { BackCta, Column, Icon, IconWithBackground, Text } from "@bridgesplit/ui";

import { AppButton, CHAIN_METADATA } from "../../common";
import { useCreateSolanaWallet } from "../util";

export default function CreatePasskey({ chain, back, next }: { chain: ChainId; back: () => void; next: () => void }) {
    const meta = CHAIN_METADATA[chain];
    const register = useCreateSolanaWallet();

    return (
        <>
            <Column textAlign="center" alignItems="center" spacing={2}>
                <IconWithBackground variant="h1" size={60} tagColor="info">
                    <Icon type="passkey" />
                </IconWithBackground>
                <Column spacing={0.5} alignItems="center">
                    <Text fontWeight="normal" variant="h2">
                        Create a Passkey
                    </Text>
                    <Text color="caption">
                        Passkeys allow you to confirm {meta.name} deposits by authenticating with your local device
                    </Text>
                </Column>
            </Column>
            <Column spacing={1}>
                <AppButton
                    isTransaction={false}
                    asyncCta={{
                        onClickWithResult: register,
                        options: {
                            alertOnError: true,
                            onSuccessMessage: "Passkey created",
                            onSuccess: next
                        }
                    }}
                >
                    Create passkey
                </AppButton>
                <BackCta back={back} />
            </Column>
        </>
    );
}
