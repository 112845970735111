import { useGroup, useUserCustodianPermissions } from "@bridgesplit/abf-react";
import {
    Column,
    EmptyPlaceholder,
    HeaderCard,
    HeaderCardWithAction,
    Image,
    OutlinedCard,
    Row,
    TagText,
    Text,
    TextButton
} from "@bridgesplit/ui";
import { COPY, ROLE_METADATA } from "app/constants";
import { AppDialog, EmptyPromptType, useAppDialog, useEmptyPrompts } from "app/utils";

import { RolesDisplay } from "../group/common";

export default function AccountPermissions() {
    const { activeRoles } = useGroup();
    const { open } = useAppDialog();

    return (
        <>
            <HeaderCard spacing={2} header="Roles">
                <Text color="caption">You have the following permissions</Text>
                <RolesDisplay roles={activeRoles} />
            </HeaderCard>
            <HeaderCardWithAction
                actionElement={
                    <TextButton onClick={() => open(AppDialog.AirtableInterestForm, undefined)}>
                        Request additional access
                    </TextButton>
                }
                spacing={2}
                header={`${COPY.CUSTODIAN_TERM} Permissions`}
            >
                <Text color="caption">
                    You have access to the following {COPY.CUSTODIAN_TERM_PLURAL.toLowerCase()}. These{" "}
                    {COPY.CUSTODIAN_TERM_PLURAL.toLowerCase()} permissions determine the collateral you are authorized
                    to lend or borrow against
                </Text>
                <CustodianDisplay />
            </HeaderCardWithAction>
        </>
    );
}

function CustodianDisplay() {
    const custodians = useUserCustodianPermissions();

    const emptyPrompt = useEmptyPrompts()(
        EmptyPromptType.AssetClassAccess,
        `You don't have any ${COPY.CUSTODIAN_TERM} permissions`
    );

    if (custodians?.length === 0) {
        return <EmptyPlaceholder {...emptyPrompt} />;
    }

    return (
        <Row sx={{ width: "100%", overflowX: "auto" }} alignItems="stretch" spacing={1}>
            {custodians?.map(({ custodian, roles }) => (
                <OutlinedCard
                    key={custodian.groupIdentifier}
                    sx={{ width: "100%", minWidth: "250px", justifyContent: "space-between" }}
                    padding={1.5}
                    spacing={1}
                >
                    <Column spacing={0.5}>
                        <Image src={custodian.logo} size="40px" variant="circle" />
                        <Text variant="h3"> {custodian.name} </Text>

                        <Text variant="body2" color="caption">
                            {custodian.description}
                        </Text>
                    </Column>

                    <Row spacing={1}>
                        {roles.map((r) => (
                            <TagText key={r}> {ROLE_METADATA[r].name} </TagText>
                        ))}
                    </Row>
                </OutlinedCard>
            ))}
        </Row>
    );
}
