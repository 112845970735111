import { colorToAlpha } from "@bridgesplit/utils";
import { PaletteOptions } from "@mui/material";

import { THEME_COLORS } from "./theme";

export function getDarkPalette(): PaletteOptions {
    return {
        primary: {
            main: colorToAlpha(THEME_COLORS.DARK, 0.95)
        },
        secondary: {
            main: "#394EFF"
        },
        background: {
            default: "#121421",
            paper: "#2A2B36"
        },
        success: {
            main: "#06DB0C"
        },
        error: {
            main: "#F54545"
        },
        warning: {
            main: "#FFD15C"
        },
        info: {
            main: "#4A88FF"
        },
        divider: colorToAlpha(THEME_COLORS.DARK, 0.05),
        text: {
            primary: colorToAlpha(THEME_COLORS.DARK, 0.95),
            secondary: colorToAlpha(THEME_COLORS.DARK, 0.6),
            disabled: colorToAlpha(THEME_COLORS.DARK, 0.4)
        }
    };
}
