import { colorToAlpha } from "@bridgesplit/utils";
import { PaletteOptions } from "@mui/material";

import { THEME_COLORS } from "./theme";

export function getLightPalette(): PaletteOptions {
    return {
        primary: {
            main: colorToAlpha(THEME_COLORS.LIGHT, 0.95)
        },
        secondary: {
            main: "#394EFF"
        },
        background: {
            default: "#F8F8FA",
            paper: "#F8F8FA"
        },
        success: {
            main: "#009D05"
        },
        error: {
            main: "#E12D2D"
        },
        warning: {
            main: "#FF7631"
        },
        info: {
            main: "#113EDD"
        },
        divider: colorToAlpha(THEME_COLORS.LIGHT, 0.05),
        text: {
            primary: colorToAlpha(THEME_COLORS.LIGHT, 0.95),
            secondary: colorToAlpha(THEME_COLORS.LIGHT, 0.6),
            disabled: colorToAlpha(THEME_COLORS.LIGHT, 0.4)
        }
    };
}
