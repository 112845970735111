import { ReactNode, useEffect, useMemo, useState } from "react";

import {
    AlertProgress,
    AlertVariant,
    DEFAULT_SOLANA_EXPLORER,
    IS_LOCAL_NET,
    TransactionStatus
} from "@bridgesplit/utils";
import { Notifications } from "@mui/icons-material";
import { CircularProgress, linearProgressClasses } from "@mui/material";

import { BORDER_RADIUS, useAppPalette } from "../theme";
import { Column, ExpandContent, Row } from "./containers";
import { Text, TextColor } from "./typography";
import { Icon, IconWithBackground } from "./icons";
import { ProgressBar } from "./info";
import { TextButton } from "./buttons";

function Snackbar({ children }: { children: ReactNode }) {
    const { paperBackground, isDarkMode, border } = useAppPalette();

    return (
        <Column
            sx={{
                borderRadius: BORDER_RADIUS,
                border,
                width: "400px",
                maxWidth: "100vw",
                background: paperBackground
            }}
        >
            <Column
                spacing={1}
                sx={{
                    px: 1.5,
                    py: 1,
                    boxShadow: isDarkMode ? undefined : "0px 0px 10px rgba(0, 0, 0, 0.1)"
                }}
            >
                {children}
            </Column>
        </Column>
    );
}

type CustomAlertProps = {
    message: string | undefined;
    description?: string | undefined;
    variant: AlertVariant;
    progress?: AlertProgress;
    close: () => void;
};
export function AlertsSnackbar({ variant, message, description, progress, close }: CustomAlertProps) {
    return (
        <Snackbar>
            <Column spacing={1}>
                <SnackbarHeader variant={variant} message={message} close={close} />
                {!!description && <Text color="caption">{description?.slice(0, 300)}</Text>}
                {progress && <ProgressDisplay progress={progress} />}
            </Column>
        </Snackbar>
    );
}

function ProgressDisplay({ progress: { totalSteps, estimatedSeconds, stepIndex } }: { progress: AlertProgress }) {
    return (
        <Row>
            {Array(totalSteps)
                .fill(null)
                .map((_, i) => (
                    <ProgressCounter
                        key={i}
                        estimateSeconds={estimatedSeconds}
                        active={stepIndex === i}
                        complete={stepIndex > i}
                    />
                ))}
        </Row>
    );
}

export function TransactionsResultsSnackbar({
    message,
    results,
    variant,
    close
}: CustomAlertProps & { results: TransactionToDisplay[] }) {
    return (
        <Snackbar>
            <Column spacing={1}>
                <SnackbarHeader variant={variant} message={message} close={close} />
                <ExpandContent textColor="caption" defaultExpanded={false} header="Transactions">
                    {results.map((t) => (
                        <TransactionStatusRow key={t.signature} {...t} />
                    ))}
                </ExpandContent>
            </Column>
        </Snackbar>
    );
}

type TransactionToDisplay = { signature: string; status: TransactionStatus; title: string };
function TransactionStatusRow({ title, signature, status }: TransactionToDisplay) {
    const { icon, color } = useMemo((): { icon: ReactNode; color: TextColor } => {
        switch (status) {
            case TransactionStatus.Confirmed:
                return { icon: <Icon type="accept" />, color: "success" };
            case TransactionStatus.Signed:
                return { icon: <Icon type="pending" />, color: "caption" };

            default:
                return { icon: <Icon type="reject" />, color: "error" };
        }
    }, [status]);

    return (
        <Row spaceBetween>
            <Text
                onClick={() => {
                    if (!signature) return;
                    if (IS_LOCAL_NET) {
                        return window.open(
                            `https://explorer.solana.com/tx/${signature}?cluster=custom&customUrl=https%3A%2F%2Flocalnet.loopscale.com%2F`
                        );
                    }
                    window.open(`${DEFAULT_SOLANA_EXPLORER}tx/${signature}`);
                }}
                isLink={!!signature}
                underlineLink="hover"
            >
                {title}
            </Text>

            <Text color={color}>{icon}</Text>
        </Row>
    );
}

function SnackbarHeader({
    message,
    variant,
    close
}: {
    message: string | undefined;
    variant: AlertVariant;
    close: () => void;
}) {
    return (
        <Row spaceBetween sx={{ alignItems: "flex-start" }} spacing={1}>
            <Row spacing={1}>
                <SnackbarIcon variant={variant} />
                <Text>{message?.slice(0, 100)}</Text>
            </Row>

            {variant !== "spinner" && (
                <TextButton variant="body2" color="caption" onClick={close}>
                    <Icon type="reject" />
                </TextButton>
            )}
        </Row>
    );
}

const ICON_SIZE = 24;

function SnackbarIcon({ variant }: { variant: AlertVariant }) {
    switch (variant) {
        case "error":
            return (
                <IconWithBackground tagColor="error" size={ICON_SIZE}>
                    <Icon type="reject" />
                </IconWithBackground>
            );
        case "info":
            return (
                <IconWithBackground tagColor="info" size={ICON_SIZE}>
                    <Notifications />
                </IconWithBackground>
            );
        case "success":
            return (
                <IconWithBackground tagColor="success" size={ICON_SIZE}>
                    <Icon type="accept" />
                </IconWithBackground>
            );
        case "spinner":
            return (
                <IconWithBackground tagColor="body" size={ICON_SIZE}>
                    <CircularProgress color="inherit" size={10} />
                </IconWithBackground>
            );
        default:
            return null;
    }
}

const TICK_ODDS = 0.1; // 10% chance of jumping two ticks
const TICKS_PER_SECOND = 2;
function useStaggeredTimer(options?: { skip?: boolean }) {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (options?.skip) return;
        const interval = setInterval(() => {
            // randomly increase ticks
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1_000 / TICKS_PER_SECOND);

        return () => clearInterval(interval);
    }, [options?.skip]);

    return seconds;
}

function ProgressCounter({
    active,
    complete,
    estimateSeconds
}: {
    active: boolean;
    estimateSeconds: number;
    complete: boolean;
}) {
    const tick = useStaggeredTimer({ skip: !active || complete });
    const secondsElapsed = tick / (TICKS_PER_SECOND * (1 + TICK_ODDS));

    const progress = complete ? 1 : 1 - Math.exp(-secondsElapsed / estimateSeconds);

    return (
        <ProgressBar
            sx={{
                width: "100%",
                // otherwise the bar of a given LinearProgress overlaps with the others
                [`& .${linearProgressClasses.bar}`]: {
                    transform: active || complete ? undefined : "translateX(-101%) !important"
                }
            }}
            value={100 * progress}
        />
    );
}
