import { useMemo } from "react";

import { useAuth } from "../reducers";
import { useUserBetaAccess } from "./points";
import { useActiveWallet } from "./wallet";
import { AccessLevel } from "../reducers/util";
import { useUserMe } from "./auth";

interface AccessLevelState {
    accessLevel: AccessLevel;
    isNotConnected: boolean;
    isUnregistered: boolean;
    isWaitlist: boolean;
    isBeta: boolean;
    isLoading: boolean;
}

export function useAccessLevel(): AccessLevelState {
    const { isAuthenticated, isLoading: authLoading } = useAuth();
    const { hasPermission } = useUserBetaAccess();
    const { userWalletsForGroup } = useActiveWallet();

    const { isLoading: userMeLoading } = useUserMe();

    const isLoading = authLoading || userMeLoading;

    return useMemo((): AccessLevelState => {
        let accessLevel: AccessLevel;

        if (!isAuthenticated) {
            accessLevel = AccessLevel.NotConnected;
        } else if (userWalletsForGroup?.length === 0) {
            accessLevel = AccessLevel.Unregistered;
        } else if (hasPermission) {
            accessLevel = AccessLevel.BetaAccess;
        } else {
            accessLevel = AccessLevel.Waitlist;
        }

        return {
            accessLevel,
            isNotConnected: accessLevel === AccessLevel.NotConnected,
            isUnregistered: accessLevel === AccessLevel.Unregistered,
            isWaitlist: accessLevel === AccessLevel.Waitlist,
            isBeta: accessLevel === AccessLevel.BetaAccess,
            isLoading
        };
    }, [isAuthenticated, userWalletsForGroup?.length, hasPermission, isLoading]);
}
