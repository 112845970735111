import { DurationUnit, BsMetadata, OracleQuote, ExternalYieldVault } from "@bridgesplit/abf-sdk";

import { LiquidationType } from "./strategy";
import { PresetTerms, StrategyDuration } from "./preset";
import { TokenBalance } from "./escrow";

export type MarketExpanded = {
    usdPrice: number | null;
    principalStats: MarketPrincipalStats;
    strategyStats: MarketStrategyStats;
    metadata: BsMetadata;
    borrowCap: BorrowCap | null;
    yieldVault: ExternalYieldVault | null;
};

export type MarketDetailStats = {
    principalStats: MarketPrincipalStats;
    strategyStats: MarketStrategyStats;
};

export interface MarketsFilter {
    principal: string[];
    force?: boolean;
}

export interface DurationToMinApyItem {
    duration: number;
    durationType: number;
    apy: number;
}

export interface MarketStrategyStats {
    totalDeposits: number;
    durationToMinApy: DurationToMinApyItem[];
}

export interface MarketStratResponse {
    [marketId: string]: MarketStrategyStats;
}
interface DurationToMinApy {
    [key: string]: number;
}

interface TotalDepositsData {
    totalDeposits: number;
    durationToMinApy: DurationToMinApy;
}

export interface RawMarketStratResponse {
    [mint: string]: TotalDepositsData;
}

export interface Collateral extends TokenBalance {
    oracle: OracleQuote | null;
    usdPrice: number;
    usdValue: number;
}

export type MaxQuoteFromCollateralAmount = OfferQuote & {
    maxBorrow: number;
};

export interface CollateralWithMaxQuote extends Collateral {
    maxQuote: MaxQuoteFromCollateralAmount | undefined;
}

export interface RawQuoteFilter {
    collateral: string[];
    principal: string;
    limit: number; // max 1000
    duration: number;
    durationType: number;
    offset: number;
    minPrincipalAmount?: number;
}

interface QuoteFilterBase {
    principal: string;
    minPrincipalAmount?: number;
    duration: number;
    durationType: DurationUnit;
    limit: number;
    offset: number;
}

export interface CollateralQuoteFilter extends QuoteFilterBase {
    collateral: string[];
}

export interface CollateralQuoteFilterForApy extends CollateralQuoteFilter {
    collateral: string[];
    apyRange: [number, number];
}

export interface CustodianQuoteFilter extends QuoteFilterBase {
    custodian: string;
}

export interface MaxQuoteRequest {
    collateralToAmount: Record<string, number>;
    principal: string;
    duration: number;
    durationType: DurationUnit;
}

export interface BestQuoteFilter {
    collateralMints: string[];
    principalMint: string;
    durations: StrategyDuration[];
    minPrincipalAmount: number;
    showSelf?: boolean;
}

export interface MinPrincipalFilter {
    principalMints: string[];
}

export interface PresetsFilter {
    collateralMints?: string[];
}

export type RawQuote = [number, string, string, number, number, number, number | null];

export type OrderbookQuote = {
    apy: number;
    maxPrincipalAvailable: number;
    sumPrincipalAvailable: number;
};

export type OrderbookQuoteForApy = {
    collateral: string;
    maxPrincipalAvailable: number;
    sumPrincipalAvailable: number;
};

export type OfferQuote = {
    apy: number;
    lendingStrategyIdentifier: string;
    assetTermsIdentifier: string;
    principalAvailable: number;
    ltv: number;
    collateralMint: string;
    liquidationThreshold: number;
    minPrice: number | null;
};

export type BestQuote = OfferQuote & {
    duration: number;
    durationType: DurationUnit;
};

export type BestSellQuote = Omit<BestQuote, "lendingStrategyIdentifier"> & {
    escrow: string;
};

export interface CreateStrategyFromPresetParams {
    strategyIdentifier: string; // ID set from FE
    offerTermsToApy: Record<string, number>; // apy in bps, string of offer term identifiers
    principalMint: string;
    liquidationTypes: LiquidationType[];
    strategyName?: string;
    strategyDescription?: string;
}

export interface CreateOfferFromPresetParams {
    offerTermsToApy: Record<string, number>; // apy in bps, string of offer term identifiers
    strategyIdentifier: string;
}

export interface PresetWithQuotes {
    preset: PresetTerms;
    quotes: OfferQuote[];
}

export interface BorrowCap {
    principalMint: string;
    perLoan: number;
    global: number;
}

export type MarketPrincipalStats = {
    principalUtilized: number;
    principalOriginated: number;
    salesVolume: number;
};

export type RatesHistoryParams = {
    principalMints: string[];
    timeLookback: number;
    onlyLoopscale?: boolean;
};

export enum RateMarketType {
    Loopscale,
    KaminoMain,
    MarginFi,
    Solend
}

export type HistoricalMarketRate = {
    id: number;
    marketType: RateMarketType;
    principalMint: "string";
    timestamp: number;
    borrowApy: number;
    lendApy: number;
    duration: number;
    durationType: DurationUnit;
};

export type HistoricalLoopVaultBorrowRate = {
    timestamp: number;
    borrowRate: number;
    loopVaultIdentifier: string;
};

export interface CollateralYieldHistory {
    id: number;
    collateralMint: string;
    apy: number;
    timestamp: number;
}

export interface SellLoanQuote {
    quote: BestSellQuote;
    salePrice: number;
    fairSalePriceAtQuoteRate: number;
    valueLeft: number;
    saleTime: number;
    // loanInfo: LoanInfo; not used
    amountInLoanEscrow: number;
}

export type EstimatedRefinanceInfo = {
    collateralMint: string;
    ltv: number;
    liquidationThreshold: number;
};

export type RefinanceInfoResponse = Record<string, EstimatedRefinanceInfo[]>;

// principal to collateral mints
export type RefinanceInfoParams = Record<string, string[]>;

export interface TransferCreditQuote {
    apy: number;
    amountLeft: number;
    strategyIdentifier: string;
    ltv: number;
    liquidationThreshold: number;
}
