import { useMemo, useState } from "react";

import { AbfPermission, useUserEscrowedPrincipalByMint } from "@bridgesplit/abf-react";
import { StatColumn, TokenInput } from "@bridgesplit/ui";
import { USDC_MINT, USDC_SYMBOL } from "@bridgesplit/utils";
import { SphereUserBankAccount } from "@bridgesplit/abf-sdk";

import { AppButton, useBalanceChecker } from "../common";
import { calcSphereOfframpFees, useOfframpPayout } from "./util";
import { SphereCtaInfoWrapper, useSphereStats } from "./common";

export default function SphereOfframp({ bank }: { bank: SphereUserBankAccount | undefined }) {
    const [amount, setAmount] = useState<number>();
    const balance = useUserEscrowedPrincipalByMint(USDC_MINT);
    const { insufficientBalance, BalanceDisplay } = useBalanceChecker({
        mint: USDC_MINT,
        amount,
        escrowNeeded: true,
        setMax: setAmount
    });

    const submitPayout = useOfframpPayout();
    const fees = calcSphereOfframpFees(amount);
    const stats = useSphereStats(fees);

    const errorMessage = useMemo(() => {
        if (amount && balance && amount > balance) return "Insufficient Balance";
        return undefined;
    }, [amount, balance]);

    return (
        <>
            <TokenInput
                label="Amount"
                maxAmount={balance}
                adornment={USDC_SYMBOL}
                value={amount}
                setValue={(val) => setAmount(val)}
            />
            {!!amount && <StatColumn stats={stats} />}
            <SphereCtaInfoWrapper>
                <AppButton
                    isTransaction
                    fullWidth
                    errorMessage={errorMessage}
                    authentication={{ permission: AbfPermission.WithdrawFunds }}
                    disabled={!amount || insufficientBalance}
                    asyncCta={{
                        onClickWithResult: () => submitPayout({ amount: fees.sphereInputAmount, bank }),
                        options: {
                            onSuccessMessage: "Withdrawal initiated",
                            onSuccessColor: "info",
                            onSuccess: () => setAmount(undefined)
                        }
                    }}
                    variant="contained"
                >
                    Withdraw
                </AppButton>
                <BalanceDisplay />
            </SphereCtaInfoWrapper>
        </>
    );
}
