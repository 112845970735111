import { useCallback, useMemo } from "react";

import {
    abfUserApi,
    calculateHealthRatioFromLtv,
    calculateLtvFromLeverage,
    loopApi,
    LoopWindParams,
    useActiveWallet,
    useEscrowPreference,
    useGetTransactionGenerationType,
    useLoopWindTransaction,
    useSetupLoopTransaction
} from "@bridgesplit/abf-react";
import { decimalsToBps, LOADING_ERROR, MISSING_PARAM_ERROR, MISSING_WALLET_ERROR, Result } from "@bridgesplit/utils";
import { AssetTypeIdentifier, StrategyCollateralInfoParams, WindRoute } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { TrackTransactionEvent } from "app/types";
import { trackSubmitEarnVault } from "app/hooks";

import { useBalanceChecker } from "../../common";
import { useLoopContext } from "../LoopContext";
import { allTransactionsSucceeded, useTransactionSender } from "../../transactions";
import { useWindContext } from "./WindContext";
import { DEFAULT_WIND_FORM } from "./constants";

export function useLoopBalanceChecker() {
    const {
        form: { collateralAmount }
    } = useWindContext();
    const { loopExpanded } = useLoopContext();

    const handleChange = useLoopSetCollateralAmount();
    return useBalanceChecker({
        amount: collateralAmount,
        mint: loopExpanded?.collateralToken.assetMint,
        setMax: handleChange
    });
}

export function useLoopSetCollateralAmount() {
    const { setForm } = useWindContext();
    return useCallback(
        (collateralAmount: number | undefined) => setForm((prev) => ({ ...prev, collateralAmount })),
        [setForm]
    );
}

const WIND_ERRORS = {
    "Calculated flash loan amount": "Exceeds desired slippage. You can adjust your slippage in settings",
    "Borrow cap": "Your request exceeds the available borrow capacity for this market"
};
export function useLoopWind() {
    const { loopExpanded } = useLoopContext();

    const { form, setForm, externalQuote, quote } = useWindContext();
    const wind = useLoopWindTransaction();
    const send = useTransactionSender();
    const { escrowNeeded } = useEscrowPreference();
    const getTransactionGenerationType = useGetTransactionGenerationType();
    const setupWind = useSetupWind();
    const { activeWallet } = useActiveWallet();

    return useCallback(async () => {
        if (!loopExpanded) return Result.errFromMessage(LOADING_ERROR);

        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);

        if (!form.collateralAmount || !quote || !externalQuote || !form.multiplier)
            return Result.errFromMessage(MISSING_PARAM_ERROR);

        const setupWindRes = await setupWind(loopExpanded.vaultIdentifier);
        if (!setupWindRes.isOk()) return Result.err(setupWindRes);

        const { bestQuote, principalAmount } = quote;

        const collateralInfo: StrategyCollateralInfoParams = {
            amountFromWallet: escrowNeeded ? 0 : form.collateralAmount,
            amountFromEscrow: escrowNeeded ? form.collateralAmount : 0,
            assetTermsIdentifier: bestQuote.assetTermsIdentifier,
            assetTypeDiscriminator: AssetTypeIdentifier.SplToken,
            assetKey: loopExpanded.collateralToken.assetMint
        };
        const transactionGenerationType = await getTransactionGenerationType();

        const ltv = calculateLtvFromLeverage(form.multiplier);

        const params: LoopWindParams = {
            loopExpanded,
            windRoute: { [externalQuote.type]: externalQuote.quote } as WindRoute,
            vaultIdentifier: loopExpanded.vaultIdentifier,
            collateralInfo,
            leverageMultiplier: form.multiplier,
            strategyIdentifier: bestQuote.lendingStrategyIdentifier,
            principalRequested: principalAmount,
            apy: decimalsToBps(bestQuote.apy),
            ltv: decimalsToBps(ltv),
            slippage: decimalsToBps(form.percentSlippageDecimals),
            liquidationThreshold: decimalsToBps(bestQuote.liquidationThreshold),
            transactionGenerationType,
            useFillerEscrow: escrowNeeded,
            principalMint: loopExpanded.principalToken.assetMint,
            userPublicKey: activeWallet.wallet
        };

        const res = await send(wind, params, {
            alerter: { generationEstimateSeconds: 8, showProgress: true },
            messageOverrides: {
                errorMessageMap: WIND_ERRORS
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitEarnOrder,
                params: trackSubmitEarnVault({ params })
            }
        });
        if (allTransactionsSucceeded(res)) {
            setForm(DEFAULT_WIND_FORM);
        }
        return res;
    }, [
        loopExpanded,
        activeWallet,
        form.collateralAmount,
        form.multiplier,
        form.percentSlippageDecimals,
        quote,
        externalQuote,
        setupWind,
        escrowNeeded,
        getTransactionGenerationType,
        send,
        wind,
        setForm
    ]);
}

// parallelizes the account setup with the MarginFi setup if needed
function useSetupWind() {
    const setupLoop = useSetupLoopTransaction();

    const [fetchUserHasMarginFiAccount] = abfUserApi.useLazyUserHasMarginFiAccountQuery();
    const [fetchLoopVaultSetupCompleted] = loopApi.useLazyLoopVaultSetupCompletedQuery();

    const send = useTransactionSender();

    return async (loopVaultAddress: string) => {
        const [marginFiAccountQuery, loopVaultSetupCompletedQuery] = await Promise.all([
            fetchUserHasMarginFiAccount(undefined, true),
            fetchLoopVaultSetupCompleted(loopVaultAddress, true)
        ]);
        const setupMarginFiAccount = marginFiAccountQuery.data === false;
        const setupLoopVault = loopVaultSetupCompletedQuery.data === false;

        // no setup needed
        if (!setupMarginFiAccount && !setupLoopVault) return Result.ok();

        return await send(
            setupLoop,
            { loopVaultAddress, setupMarginFiAccount, setupLoopVault },
            { description: `Setting up your ${COPY.LOOP_TERM.toLowerCase()} account` }
        );
    };
}

export function useLoopHealth() {
    const { quote } = useWindContext();
    const { loopExpanded } = useLoopContext();

    return useMemo(() => {
        if (!quote || !loopExpanded) return undefined;
        const { bestQuote, leverageMultiplier } = quote;

        const ltv = calculateLtvFromLeverage(leverageMultiplier);
        const healthRatio = calculateHealthRatioFromLtv(ltv, bestQuote.liquidationThreshold);

        return { ltv, healthRatio };
    }, [loopExpanded, quote]);
}
