import { useMemo, useState } from "react";

import { StatColumn } from "@bridgesplit/ui";
import {
    useWalletAvailableAssets,
    useEscrowDepositTransaction,
    useActiveEscrow,
    DepositParams,
    useActiveWallet,
    formatWallet,
    useActiveGroup
} from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";
import { COPY } from "app/constants";
import { TrackTransactionEvent } from "app/types";

import { AppButton, EmptyWalletAssets, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { useTransactionSender } from "../../transactions";

export default function DepositEscrows({
    specifiedKeys,
    specifiedCustodian
}: {
    specifiedKeys?: string[];
    specifiedCustodian?: string;
}) {
    const { close } = useAppDialog();

    const { activeWallet } = useActiveWallet();

    const rawAssets = useWalletAvailableAssets(activeWallet?.wallet, {
        includeStakedSol: true,
        includeLpPositions: true
    });

    const specifiedKeysSet = specifiedKeys ? new Set(specifiedKeys) : undefined;
    const assets = rawAssets?.filter((a) => {
        if (!!specifiedCustodian && a.metadata.assetOriginator !== specifiedCustodian) return false;
        if (specifiedKeysSet && !specifiedKeysSet.has(a.key)) return false;
        return true;
    });
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce } = useActiveEscrow();
    const [selectedAmounts, setSelectedAmounts] = useState<Map<string, number | undefined>>(new Map());

    const nonZeroAmounts = useMemo(
        (): DepositParams | undefined =>
            assets
                ?.map(({ key, mint, stakeAccount }) => ({
                    mint,
                    escrowNonce,
                    organizationIdentifier: groupIdentifier ?? "",
                    amount: selectedAmounts.get(key) ?? 0,
                    stakeAccount: stakeAccount?.stakeAccount
                }))
                .filter(({ amount }) => !!amount),
        [assets, escrowNonce, groupIdentifier, selectedAmounts]
    );

    const send = useTransactionSender();
    const deposit = useEscrowDepositTransaction();

    async function submit() {
        close();
        setSelectedAmounts(new Map());
        return await send(deposit, nonZeroAmounts, {
            mixpanelEvent: { key: TrackTransactionEvent.EscrowDeposit, params: nonZeroAmounts }
        });
    }

    const assetSelectionError = getSelectAssetsErrorMessage({ selected: selectedAmounts, assets });

    if (rawAssets?.length === 0) {
        return <EmptyWalletAssets />;
    }

    return (
        <>
            <SelectAssets
                allowTokenInput={specifiedKeys?.length === 1}
                assets={assets}
                selected={selectedAmounts}
                setSelected={setSelectedAmounts}
            />

            <StatColumn
                stats={[
                    {
                        value: formatWallet(activeWallet),
                        caption: "From"
                    },
                    { value: COPY.ESCROW_TERM_FULL, caption: "To" }
                ]}
            />

            <AppButton
                isTransaction
                disabled={!!assetSelectionError}
                asyncCta={{ onClickWithResult: submit }}
                variant="contained"
            >
                {assetSelectionError ?? AppDialog.Deposit}
            </AppButton>
        </>
    );
}
