import { Middleware } from "@reduxjs/toolkit";

import alertsSlice from "./alertsSlice";
import localStorageSlice from "./localStorageSlice";
import { staticApi } from "./staticApi";
import { rpcApi } from "./rpcApi";
import { typescriptApi } from "./typescriptApi";

const InternalSdkReducers = {
    alertsSlice,
    localStorageSlice,
    [staticApi.reducerPath]: staticApi.reducer,
    [rpcApi.reducerPath]: rpcApi.reducer,
    [typescriptApi.reducerPath]: typescriptApi.reducer
};

const InternalSdkMiddleware: Middleware[] = [staticApi.middleware, rpcApi.middleware, typescriptApi.middleware];

export * from "./alertsSlice";
export * from "./localStorageSlice";
export * from "./types";
export * from "./staticApi";
export * from "./rpcApi";
export * from "./typescriptApi";
export { InternalSdkMiddleware, InternalSdkReducers };
