import { AxiosInstance, AxiosResponse } from "axios";
import { PublicKey, VersionedMessage, VersionedTransaction } from "@solana/web3.js";

import { VersionedTransactionResponse } from "../../types";
import { base64 } from "../bytes";

export function deserializeVersionedTransaction(response: VersionedTransactionResponse): VersionedTransaction {
    const message = VersionedMessage.deserialize(new Uint8Array(base64.decode(response.message)));
    const txn = new VersionedTransaction(message);
    response.signatures.forEach((sig) => {
        const deserializedSignature = new Uint8Array(base64.decode(sig.signature));
        txn.addSignature(new PublicKey(sig.publicKey), deserializedSignature);
    });
    return txn;
}

export async function fetchVersionedTransaction<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<VersionedTransactionResponse> {
    const response = await api.post<BodyType, AxiosResponse<VersionedTransactionResponse>>(url, data);
    return response.data;
}

export async function fetchVersionedTransactions<BodyType, QueryType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType,
    queryParams?: QueryType
): Promise<VersionedTransactionResponse[]> {
    const response = await api.post<BodyType, AxiosResponse<VersionedTransactionResponse[]>>(url, data, {
        params: queryParams
    });
    return response.data;
}

export async function fetchAndDeserializeVersionedTransaction<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<VersionedTransaction> {
    const serializedTxn = await fetchVersionedTransaction(api, url, data);
    return deserializeVersionedTransaction(serializedTxn);
}

export async function fetchAndDeserializeVersionedTransactions<BodyType, QueryType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType,
    query?: QueryType
): Promise<VersionedTransaction[]> {
    const serializedTxns = await fetchVersionedTransactions(api, url, data, query);
    return serializedTxns.map((txn) => deserializeVersionedTransaction(txn));
}
