import { Column, OutlinedCard, Row, Text } from "@bridgesplit/ui";
import { useMediaQuery } from "@mui/material";
import { COPY } from "app/constants";

import WindAmount from "./WindAmount";
import WindMultiplier from "./WindMultiplier";
import WindStats from "./WindStats";
import WindCta from "./WindCta";
import WindNestedStats from "./WindNestedStats";
import { LOOP_DETAIL_QUERY } from "../detail";
import WindTopButtons from "./WindTopButtons";

export default function WindCard() {
    const isMobile = useMediaQuery(LOOP_DETAIL_QUERY.below);

    return (
        <Column spacing={3}>
            <Column spacing={1}>
                <OutlinedCard hideCard={isMobile} spacing={3}>
                    <Row spaceBetween>
                        <Text variant="h4"> {COPY.LOOP_TERM_ACTION} </Text>
                        <WindTopButtons />
                    </Row>
                    <WindAmount />
                    <WindMultiplier />
                    <WindNestedStats />
                    <WindCta />
                </OutlinedCard>
            </Column>

            <WindStats />
        </Column>
    );
}
