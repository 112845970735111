import { useMemo } from "react";

import {
    CREDENTIAL_ERROR,
    SignatureType,
    Result,
    TIME,
    base64url,
    convertAnyDate,
    findMaxElement,
    groupArrayElementsBy,
    sleep,
    getReadableErrorMessage
} from "@bridgesplit/utils";
import {
    CompleteCredentialRegistration,
    CredentialWithHistory,
    DfnsActionType,
    DfnsChallenge,
    DfnsCredential,
    DfnsRegistrationStatus,
    DfnsUserCredentials,
    LoginResponseServer,
    PublicKeyCredentialCreationOptionsServer,
    SignData,
    UserActionData
} from "@bridgesplit/abf-sdk";
import UAParser from "ua-parser-js";

import {
    useInitiateMpcUserRegistrationMutation,
    useCompleteUserRegistrationMutation,
    useCreateMpcChallengeMutation,
    useCreateMpcPatMutation,
    useInitiateMpcUserLoginMutation,
    useCompleteMpcUserLoginMutation,
    mpcApi,
    useMpcUserCredentialsQuery,
    usePasskeyHistoryQuery,
    useInitiateNewCredentialRegistrationMutation,
    useCompleteNewCredentialRegistrationMutation,
    useApproveNewCredentialRegistrationMutation
} from "../reducers/mpcApi";
import { useSkipUnauthenticated, useUserProfile } from "./auth";
import { useActiveWallet, useUserWallets } from "./wallet";

export const MPC_SIG_EXPIRE = TIME.HOUR * 6;
export const MPC_PAT_EXPIRE = TIME.DAY * 30;

export const MPC_SIG_COOKIE_EXPIRE = Date.now() + MPC_SIG_EXPIRE * 1000;
export const MPC_PAT_COOKIE_EXPIRE = Date.now() + MPC_PAT_EXPIRE * 1000;
export const REGISTER_SIGNATURES = [SignatureType.Register, SignatureType.Login, SignatureType.CreatePat];

const ERRORS = {
    sign: "An error occurred when authenticating with your Passkey. Please try again",
    register: "An error occurred when creating your Passkey. Please try again",
    parse: "Unable to parse credentials from your device"
};

type SignatureCallback = (type: SignatureType) => void;
type SetSignaturesNeeded = (needed: SignatureType[]) => void;

export function useMpcStatus(options?: { skip?: boolean }) {
    const { userWalletsForGroup, isMpcActive } = useActiveWallet();
    const { user } = useUserProfile();
    const fetchMpc = isMpcActive || (!userWalletsForGroup?.length && !!user.mpcIdentifier);

    const { data, isLoading } = useMpcUserCredentialsQuery(undefined, {
        skip: !fetchMpc || options?.skip
    });

    return {
        data,
        ...parseMpcStatusFromCredentials(data),
        isLoading
    };
}

// split into separate fn to allow async calls
export function parseMpcStatusFromCredentials(data: DfnsUserCredentials | undefined) {
    const activeCredential = data ? getActiveCredential(data) : undefined;
    const passkeyRegistered = !!activeCredential;
    const pendingPasskeyRegistration = data?.passkeys?.find((d) => !d.registered);

    const verified = data?.login && !!data.currentPasskey;
    const validPasskeys = data?.passkeys?.filter((d) => !!d.registered);
    return {
        passkeyRegistered,
        verified,
        activeCredential,
        pendingPasskeyRegistration,
        validPasskeys
    };
}

export function useCheckRedoMpcRegisterNeeded() {
    const { wallets } = useUserWallets();
    const { user } = useUserProfile();

    const { data } = useMpcStatus();

    const redoRegisterNeeded =
        !!user.mpcIdentifier &&
        !data?.passkeys.length &&
        data?.registration === DfnsRegistrationStatus.NotStarted &&
        !!wallets?.find((w) => w.mpcIdentifier);
    return redoRegisterNeeded;
}

function getActiveCredential(credentialData: DfnsUserCredentials) {
    const currentDevice = new UAParser(navigator.userAgent);

    const foundFromMetadata = credentialData?.passkeys?.find((d) => {
        const userAgent = d.metadata?.split(MPC_META_SEPARATOR)[0];
        if (!d.registered || !userAgent) return false;
        const passkeyDevice = new UAParser(userAgent);

        return (
            passkeyDevice.getBrowser().name === currentDevice.getBrowser().name &&
            passkeyDevice.getOS().name === currentDevice.getOS().name
        );
    });

    if (foundFromMetadata) return foundFromMetadata;

    // return the first passkey available
    return credentialData?.passkeys[0];
}

export function getSignaturesNeededFromCredentials(
    credentialData: DfnsUserCredentials | undefined,
    forceSign?: boolean
) {
    const signaturesNeeded: SignatureType[] = [];
    if (!credentialData?.passkeys.length) {
        signaturesNeeded.push(SignatureType.Register);
    }

    if (!credentialData?.login || forceSign) {
        signaturesNeeded.push(SignatureType.Login);
    }

    if (!credentialData?.currentPasskey || forceSign) {
        signaturesNeeded.push(SignatureType.CreatePat);
    }
    return signaturesNeeded;
}

export function useMpcRegisterOrSign() {
    const sign = useMpcSign();
    const register = useMpcRegister();
    const [fetchCredentials] = mpcApi.useLazyMpcUserCredentialsQuery();

    return async function (options?: {
        onSign?: SignatureCallback;
        beforeSign?: SignatureCallback;
        setSignaturesNeeded?: SetSignaturesNeeded;
        forceSign?: boolean;
        cacheCredentials?: boolean;
    }) {
        try {
            const credentials = await fetchCredentials(undefined, true).unwrap();

            options?.setSignaturesNeeded?.(getSignaturesNeededFromCredentials(credentials, options.forceSign));

            const registerNeeded =
                !credentials.passkeys.length || credentials.registration === DfnsRegistrationStatus.NotStarted;

            if (registerNeeded) {
                options?.beforeSign?.(SignatureType.Register);
                const registerRes = await register();

                if (!registerRes.isOk()) return registerRes;
                options?.onSign?.(SignatureType.Register);

                // prevents stale credentials
                await sleep(1_000);
            }

            return await sign({
                ...options,
                forceFetchCredentials: !options?.cacheCredentials,
                forceSign: registerNeeded || options?.forceSign
            });
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

export function useMpcCustomSign() {
    const mpcLogin = useMpcLogin();
    const createPat = useMpcCreatePat();
    const register = useMpcRegister();
    const [fetchCredentials] = mpcApi.useLazyMpcUserCredentialsQuery();

    return async function (
        steps: SignatureType[],
        options?: {
            onSign?: SignatureCallback;
            beforeSign?: SignatureCallback;
            credentialId?: number;
        }
    ) {
        try {
            if (steps.includes(SignatureType.Register)) {
                options?.beforeSign?.(SignatureType.Register);
                const registerRes = await register();

                if (!registerRes.isOk()) return registerRes;
                options?.onSign?.(SignatureType.Register);

                // prevents stale credentials
                await sleep(1_000);
            }

            if (steps.includes(SignatureType.Login)) {
                options?.beforeSign?.(SignatureType.Login);

                const credentials = await fetchCredentials(undefined, steps.includes(SignatureType.Register)).unwrap();

                const activeCredential = getActiveCredential(credentials);
                const credentialIdToLogin = options?.credentialId ?? activeCredential?.id;
                const loginRes = await mpcLogin(credentialIdToLogin);

                if (!loginRes.isOk()) return loginRes;
                options?.onSign?.(SignatureType.Login);
            }

            if (steps.includes(SignatureType.CreatePat)) {
                options?.beforeSign?.(SignatureType.CreatePat);
                const patRes = await createPat();

                if (!patRes.isOk()) return patRes;
                options?.onSign?.(SignatureType.CreatePat);
            }

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

export function useMpcSign() {
    const mpcLogin = useMpcLogin();
    const createPat = useMpcCreatePat();
    const [fetchCredentials] = mpcApi.useLazyMpcUserCredentialsQuery();

    return async function (options?: {
        forceSign?: boolean;
        onSign?: SignatureCallback;
        beforeSign?: SignatureCallback;
        forceFetchCredentials?: boolean;
        credentialId?: number;
    }) {
        try {
            const credentials = await fetchCredentials(
                undefined,
                options?.forceFetchCredentials ? false : true
            ).unwrap();

            const activeCredential = getActiveCredential(credentials);
            const credentialIdToLogin = options?.credentialId ?? activeCredential?.id;

            if (!activeCredential) {
                return Result.errFromMessage(getReadableErrorMessage("find a Passkey on this device"));
            }

            if (!credentials.login || options?.forceSign) {
                options?.beforeSign?.(SignatureType.Login);

                const mpcLoginRes = await mpcLogin(credentialIdToLogin);
                if (!mpcLoginRes.isOk()) {
                    return mpcLoginRes;
                }
                options?.onSign?.(SignatureType.Login);
            }

            const patCachedInBackend = credentials.currentPasskey;
            if (options?.forceSign || !patCachedInBackend) {
                options?.beforeSign?.(SignatureType.CreatePat);
                const patResult = await createPat();
                if (!patResult.isOk()) return patResult;
                options?.onSign?.(SignatureType.CreatePat);
            }

            await fetchCredentials();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.register, e);
        }
    };
}

function useMpcRegister() {
    const [initiateUserRegistration] = useInitiateMpcUserRegistrationMutation();
    const [completeUserRegistration] = useCompleteUserRegistrationMutation();

    return async function () {
        try {
            const passkeySupported = validatePasskeySupported();
            if (!passkeySupported.isOk()) return passkeySupported;

            const res = await initiateUserRegistration().unwrap();
            const signedCredentials = await signRegistrationData(res);

            if (!signedCredentials.isOk()) return signedCredentials;

            await completeUserRegistration(signedCredentials.unwrap()).unwrap();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

export function useMpcRegisterNewCredential() {
    const [initiateUserRegistration] = useInitiateNewCredentialRegistrationMutation();
    const [completeUserRegistration] = useCompleteNewCredentialRegistrationMutation();

    return async function () {
        try {
            const passkeySupported = validatePasskeySupported();
            if (!passkeySupported.isOk()) return passkeySupported;

            const res = await initiateUserRegistration().unwrap();
            const signedCredentials = await signRegistrationData(res);

            if (!signedCredentials.isOk()) return signedCredentials;

            await completeUserRegistration(signedCredentials.unwrap()).unwrap();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.register, e);
        }
    };
}

function useMpcLogin() {
    const [initiateMpcUserLogin] = useInitiateMpcUserLoginMutation();
    const [completeMpcUserLogin] = useCompleteMpcUserLoginMutation();

    return async function (passkeyId: number) {
        try {
            const res = await initiateMpcUserLogin().unwrap();
            const data = transformDfnsChallenge(res);

            const credentialsRes = await getAndParseCredentials({
                allowCredentials: [...data.allowCredentials.key, ...data.allowCredentials.webauthn],
                challenge: data.challenge
            });
            if (!credentialsRes.isOk()) return credentialsRes;
            const credentials = credentialsRes.unwrap();

            await completeMpcUserLogin({
                location: navigator.userAgent,
                passkeyId,
                req: {
                    firstFactor: {
                        kind: "Fido2",
                        credentialAssertion: {
                            credId: base64url.encode(credentials.rawId),
                            clientData: base64url.encode(credentials.response.clientDataJSON),
                            signature: base64url.encode(credentials.response.signature),
                            userHandle: base64url.encode(credentials.response.userHandle),
                            authenticatorData: base64url.encode(credentials.response.authenticatorData)
                        }
                    },
                    challengeIdentifier: data.challengeIdentifier
                }
            }).unwrap();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

function useMpcCreatePat() {
    const [createMpcChallenge] = useCreateMpcChallengeMutation();
    const [createMpcPat] = useCreateMpcPatMutation();

    return async function () {
        try {
            const data = await createMpcChallenge(DfnsActionType.Pat).unwrap();

            const challengeData = await signChallengeData(data);
            if (!challengeData.isOk()) return challengeData;

            await createMpcPat(challengeData.unwrap()).unwrap();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

export function useApproveNewPasskey() {
    const [createMpcChallenge] = useCreateMpcChallengeMutation();
    const [approve] = useApproveNewCredentialRegistrationMutation();

    return async function (passkeyId: number) {
        try {
            const data = await createMpcChallenge(DfnsActionType.RegisterNewDevice).unwrap();

            const challengeData = await signChallengeData(data);
            if (!challengeData.isOk()) return challengeData;

            await approve({
                passkeyId,
                challengeData: challengeData.unwrap()
            }).unwrap();

            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(ERRORS.sign, e);
        }
    };
}

async function signRegistrationData(
    res: PublicKeyCredentialCreationOptionsServer
): Promise<Result<CompleteCredentialRegistration>> {
    const credentialOptions = transformPublicKeyCredentialCreation(res);

    if (!credentialOptions.isOk()) {
        return Result.err(credentialOptions);
    }

    const credentialRaw = await navigator.credentials.create({
        publicKey: credentialOptions.unwrap()
    });

    const registerData = getRegistrationDataFromCredential(credentialRaw);

    return registerData;
}

async function signChallengeData(data: LoginResponseServer): Promise<Result<UserActionData>> {
    const credentialsRes = await getAndParseCredentials({
        allowCredentials: [
            ...(data.allowCredentials.webauthn.map((cred) => ({
                id: base64url.decode(cred.id) as Uint8Array,
                type: "public-key"
            })) as PublicKeyCredentialDescriptor[]),
            ...(data.allowCredentials.key.map((cred) => ({
                id: base64url.decode(cred.id) as Uint8Array,
                type: "public-key"
            })) as PublicKeyCredentialDescriptor[])
        ],
        challenge: Uint8Array.from(data.challenge, (c) => c.charCodeAt(0))
    });
    if (!credentialsRes.isOk()) return Result.err(credentialsRes);
    const credentials = credentialsRes.unwrap();

    const userAction: UserActionData = {
        challenge: data,
        signData: {
            firstFactorCredential: {
                credentialKind: "Fido2",
                credentialInfo: {
                    credId: base64url.encode(credentials.rawId),
                    clientData: base64url.encode(credentials.response.clientDataJSON),
                    attestationData: base64url.encode(credentials.response.authenticatorData),
                    signature: base64url.encode(credentials.response.signature),
                    userHandle: base64url.encode(credentials.response.userHandle)
                }
            }
        }
    };
    return Result.ok(userAction);
}

function getRegistrationDataFromCredential(credentialRaw: Credential | null): Result<CompleteCredentialRegistration> {
    const credentialRes = parseCredentials(credentialRaw);

    if (!credentialRes.isOk()) {
        return Result.err(credentialRes);
    }

    const credential = credentialRes.unwrap();

    const signData: SignData = {
        firstFactorCredential: {
            credentialKind: "Fido2",
            credentialInfo: {
                credId: base64url.encode(credential.rawId),
                clientData: base64url.encode(credential.response.clientDataJSON),
                attestationData: base64url.encode(credential.response.attestationObject),
                signature: "", // unused here
                userHandle: "" // unused here
            }
        }
    };

    return Result.ok({ name: "", metadata: getPasskeyMetadata(), signData });
}

// Check window public key directly instead of isConditionalMediationAvailable since it supports older browsers
function validatePasskeySupported() {
    if (!window.PublicKeyCredential) {
        return Result.errFromMessage(
            "Your device doesn't support Passkeys. Please upgrade your browser to the latest version"
        );
    }
    return Result.ok();
}

async function getAndParseCredentials(
    publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptions
): Promise<Result<ParsedCredentials>> {
    try {
        const credentialsRaw = await navigator.credentials.get({
            publicKey: {
                rpId: window.location.hostname,
                userVerification: "required",
                ...publicKeyCredentialRequestOptions
            }
        });

        const parsed = parseCredentials(credentialsRaw);

        return parsed;
    } catch (e) {
        return Result.errWithDebug(ERRORS.parse, e);
    }
}

type ParsedCredentials = Omit<PublicKeyCredential, "response"> & {
    response: Omit<AuthenticatorAssertionResponse, "userHandle"> &
        AuthenticatorAttestationResponse &
        Required<{ userHandle: ArrayBuffer }>;
};
function parseCredentials(credential: Credential | null): Result<ParsedCredentials> {
    try {
        if (!credential) {
            return Result.errFromMessage(CREDENTIAL_ERROR);
        }

        if (!isValidPublicKeyCredentials(credential)) {
            return Result.errFromMessage("Invalid credential type");
        }

        const publicKeyCredential = credential as ParsedCredentials;
        return Result.ok(publicKeyCredential);
    } catch (e) {
        return Result.errWithDebug(ERRORS.parse, e);
    }
}

function isValidPublicKeyCredentials(credential: Credential | null) {
    return credential?.type === "public-key";
}

// In util folder into of api to prevent not serializable data in reducer
function transformDfnsChallenge(raw: LoginResponseServer): DfnsChallenge {
    return {
        challenge: Uint8Array.from(raw.challenge, (c) => c.charCodeAt(0)),
        challengeIdentifier: raw.challengeIdentifier,
        allowCredentials: {
            key: raw.allowCredentials.key.map((cred) => ({
                id: base64url.decode(cred.id) as Uint8Array,
                type: "public-key"
            })),
            webauthn: raw.allowCredentials.webauthn.map((cred) => ({
                id: base64url.decode(cred.id) as Uint8Array,
                type: "public-key"
            }))
        },
        externalAuthenticationUrl: raw.externalAuthenticationUrl
    };
}

// In util folder into of api to prevent not serializable data in reducer
function transformPublicKeyCredentialCreation(
    raw: PublicKeyCredentialCreationOptionsServer
): Result<PublicKeyCredentialCreationOptions> {
    const data: PublicKeyCredentialCreationOptions = {
        ...raw,
        challenge: Uint8Array.from(raw.challenge, (c) => c.charCodeAt(0)),
        user: {
            ...raw.user,
            id: Uint8Array.from(raw.user.id, (c) => c.charCodeAt(0))
        },
        excludeCredentials: raw.excludeCredentials.map((cred) => ({
            type: cred.type,
            transports: [],
            id: base64url.decode(cred.id)
        })),
        attestation: "direct",
        authenticatorSelection: raw.authenticatorSelection
    };

    if (data.rp.id !== window.location.hostname) {
        return Result.errFromMessage(
            "Unable to generate credentials for this domain. The wrong server is likely being called"
        );
    }
    return Result.ok(data);
}

const POTENTIAL_ERRORS = ["Server is unavailable", "Could not find key"];
export function isExpiredPasskeyError<T>(errorResult: Result<T>) {
    if (!errorResult.isErr()) return false;
    const message = errorResult.unwrapErrMessage();

    // explicitly catch sign error in case MPC cookies are out of date
    return !!POTENTIAL_ERRORS.find((e) => message.includes(e));
}

const MPC_META_SEPARATOR = "BridgesplitHostname";
function getPasskeyMetadata() {
    return navigator.userAgent + MPC_META_SEPARATOR + window.location.host;
}

export function isPasskeyCurrentDevice(credential: DfnsCredential) {
    const currentMetadata = getPasskeyMetadata();
    return currentMetadata === credential.metadata;
}

export function formatPasskeyMetadata(metadata: string | null) {
    if (!metadata) return "Unknown";
    const parsed = getParsedPasskeyUserAgent(metadata);
    const { browser, os, device } = parsed;
    const simplifiedOS = device.model ? device.model.replace("Macintosh", "Mac") : os.name ?? "";
    return `${browser.name} on ${simplifiedOS}`;
}

export function getParsedPasskeyUserAgent(metadata: string) {
    const userAgent = metadata.includes(MPC_META_SEPARATOR) ? metadata.split(MPC_META_SEPARATOR)[0] : metadata;
    return new UAParser(userAgent).getResult();
}

export function isPasskeySignActive(lastSignedAt: number | undefined) {
    if (!lastSignedAt) return false;

    return Date.now() - convertAnyDate(lastSignedAt).getTime() < MPC_SIG_EXPIRE * 1000;
}

export function useMpcCredentialsWithHistory() {
    const skipIfUnauthenticated = useSkipUnauthenticated();
    const { activeMpcWallet } = useActiveWallet();

    const { data: credentials } = useMpcUserCredentialsQuery(undefined, {
        skip: skipIfUnauthenticated || !activeMpcWallet
    });
    const { data: passkeys } = usePasskeyHistoryQuery(undefined, { skip: skipIfUnauthenticated || !activeMpcWallet });

    const passkeyMap = useMemo(() => {
        if (!passkeys) return undefined;
        return groupArrayElementsBy(passkeys, (p) => p.passkeyId);
    }, [passkeys]);

    if (!activeMpcWallet) return [];

    if (!passkeys || !credentials) return undefined;

    return credentials?.passkeys.map((c): CredentialWithHistory => {
        const history = passkeyMap?.get(c.id) ?? [];
        return { ...c, history, lastSignIn: findMaxElement(history, (h) => h.timestamp)?.timestamp };
    });
}
