import { useMemo } from "react";

import { ChainId } from "@bridgesplit/abf-sdk";

import WithdrawEscrows from "./WithdrawEscrows";
import { SelectChain } from "../../wormhole";
import BridgeToEvm from "./BridgeToEvm";

export default function WithdrawWithSource({ mints }: { mints?: string[] }) {
    const { chain } = SelectChain.useState();

    return useMemo(() => {
        if (chain !== ChainId.Solana) {
            return <BridgeToEvm mints={mints ?? []} chain={chain} />;
        }

        return <WithdrawEscrows mints={mints} />;
    }, [chain, mints]);
}
