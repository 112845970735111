import { Bridge, ProtoUtils } from "@bridgesplit/bs-protos";

export type ChainId = ProtoUtils.ChainId;
export const ChainId = ProtoUtils.ChainId;

export enum AssetClass {
    Various,
    FarmLand,
    Whiskey,
    REC,
    LpPosition,
    MetaplexNft,
    NativeToken,
    OnRampedToken,
    BsLockBoxNft,
    BsSyndicateNft,
    OnchainCollectible,
    Watch,
    TradingCard
}

export enum TokenListTag {
    All = "All",
    Featured = "Featured",
    Native = "Native",
    Stables = "Stables",
    Bridged = "Bridged",
    LST = "LST",
    Memes = "Memes",
    Governance = "Governance",
    Staked = "Staked",
    Utility = "Utility",
    Misc = "Misc",
    DeFi = "DeFi",
    Treasuries = "Treasuries",
    LP = "LP"
}

export const DEFAULT_ASSET_CLASS = AssetClass.MetaplexNft;

export interface ParsedBsMetadata {
    assetType: number;
    assetClass: AssetClass;
    assetOriginator: string;
    creator: string;
    uri: string;
    address: string;
    assetMint: string;
    extended: RawBsMetadataJson;
    name: string;
    decimals: number;
    tags?: TokenListTag[];
}

export interface RawBsMetadataJson {
    id: number;
    assetMint: string;
    value: string;
    verified: boolean;
}

export type BaseOffchainMetadata = {
    display_media?: string;
    image?: string;
    name?: string;
    description?: string;
    symbol?: string;
    extra_media?: ExtraMediaItem[];
    source_chain?: SourceChain;
    extra_links?: ExtraLink[];
};

export interface ExtraMediaItem {
    type?: string;
    uri?: string;
}

export interface AssetClassInfo {
    id: number;
    assetClass: AssetClass;
    name: string;
    description: string;
    schema: JSONSchema;
}
export interface AssetClassInfo {
    id: number;
    assetClass: AssetClass;
    name: string;
    description: string;
    schema: JSONSchema;
}

export type JSONSchema = ObjectProperty & { $schema: string };
type StringProperty = { type: "string" };
type NumberProperty = {
    type: "number" | "integer";
    minimum?: number;
    maximum?: number;
    exclusiveMinimum?: boolean;
    exclusiveMaximum?: boolean;
    multipleOf?: number;
};

type BooleanProperty = { type: "boolean" };
type ArrayProperty = { type: "array"; items: JSONSchemaProperty[] };
type ObjectProperty = { type: "object"; required: string[]; properties: Record<string, JSONSchemaProperty> };

export type JSONSchemaProperty = StringProperty | NumberProperty | BooleanProperty | ArrayProperty | ObjectProperty;

export interface SourceChain {
    chain_id: ChainId;
    contract?: string;
    mint?: string;
    token_id?: string;
}

export interface ExtraLink {
    content_type?: string;
    name: string;
    uri: string;
}

export interface OptimisticEvmUploadArgs {
    evmAssetInfo: Bridge.EvmAssetInfo;
    assetOriginator: string;
    sourceChain: ChainId;
}
