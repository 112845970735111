import { useMemo, useState } from "react";

import {
    FONT_SIZES,
    Image,
    InvisibleSelect,
    Row,
    SelectOption,
    StatColumn,
    StatProps,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { filterTruthy, formatPercent, formatUsd, TIME } from "@bridgesplit/utils";
import { formatLeverage, getContinuousCompoundedInterest, isValidLeverage } from "@bridgesplit/abf-react";
import { useLoopPoints } from "app/components/points/stats";

import { useLoopContext } from "../LoopContext";
import { useWindContext } from "./WindContext";
import { useLoopPointsDetails } from "../../common";

const TIME_OPTIONS: SelectOption<number>[] = [
    { label: "per week", value: TIME.WEEK },
    { label: "per month", value: TIME.MONTH },
    { label: "per year", value: TIME.YEAR }
];
export default function WindNestedStats() {
    const { quote, form, isQuoteFetching } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const pointsDetails = useLoopPointsDetails({ loopExpanded });
    const { isDarkMode } = useAppPalette();

    const [estimateSeconds, setEstimateSeconds] = useState(TIME_OPTIONS[TIME_OPTIONS.length - 1].value);
    const interest = useMemo(() => {
        if (!quote || !loopExpanded) return undefined;
        const principalUsd = loopExpanded.collateralOracle.getUsdAmount(quote.collateralAmount);

        return getContinuousCompoundedInterest({
            principal: principalUsd,
            apy: quote.netApyPct,
            loanDuration: estimateSeconds,
            subtractPrincipal: true
        });
    }, [estimateSeconds, loopExpanded, quote]);

    const apy = quote?.netApyPct;

    const totalBorrow = (quote?.principalAmount ?? 1) * (loopExpanded?.principalOracle.usdPrice ?? 1);
    const additionalLoopStats = useLoopPoints({
        borrowed: totalBorrow,
        variant: "body1",
        metadata: [loopExpanded?.collateralToken, loopExpanded?.principalToken].filter(filterTruthy)
    });

    const stats: StatProps[] = [
        {
            caption: "APY",
            value: formatPercent(apy),
            textColor: (() => {
                if (!apy) return "disabled";
                if (apy < 0) return "error";
                if (isDarkMode) return "success";
                return "secondary";
            })()
        },
        {
            caption: (
                <Row>
                    <Text sx={{ mr: -0.5 }} color="caption">
                        Estimated yield
                    </Text>
                    <InvisibleSelect
                        color="caption"
                        value={estimateSeconds}
                        setValue={setEstimateSeconds}
                        options={TIME_OPTIONS}
                    />
                </Row>
            ),
            value: formatUsd(interest)
        },
        ...additionalLoopStats
    ];

    if (pointsDetails && quote) {
        const formattedLeverage = formatLeverage(quote?.leverageMultiplier);
        stats.push({
            caption: `${pointsDetails.name} rewards`,
            value: (
                <Row spacing={0.5}>
                    <Image hideSkeleton size={FONT_SIZES.body1 + "px"} variant="square" src={pointsDetails.image} />
                    <Text> {formattedLeverage} </Text>
                </Row>
            )
        });
    }

    if (!form.collateralAmount || !isValidLeverage(form.multiplier) || quote === null) return null;
    return <StatColumn loading={isQuoteFetching} stats={stats} />;
}
