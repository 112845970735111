import React, { ReactNode, forwardRef, useEffect, useState, useCallback } from "react";

import { Box, ButtonBase, Collapse, Divider, Stack, StackProps, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ExpandLess, ExpandLessOutlined, ExpandMore, ExpandMoreOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { DispatchType, useLocalStorage } from "@bridgesplit/react";
import { colorToAlpha } from "@bridgesplit/utils";

import {
    BORDER_RADIUS,
    DIALOG_WINDOW_CENTER_MIN_HEIGHT,
    LIGHT_SMALL_SHADOW,
    SPACING,
    useAppPalette,
    useColorFromTag
} from "../theme";
import { SxType } from "../types";
import { MediaQuery } from "../types/media";
import { MEDIA, useScrollDetector } from "../util";
import { RawTagColor, TagColor, Text, TextColor, TextSkeleton, TextVariant } from "./typography";
import { Button, ButtonProps, ToggleSpacedButtons } from "./buttons";
import { SelectOption } from "./input";
import { hideScrollbarSx } from "../constants";

interface ColumnProps extends StackProps {
    handleHovered?: DispatchType<boolean>;
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>(function Column(
    { handleHovered, spacing, sx, ...props },
    ref
) {
    const negativeSpacing = spacing && typeof spacing === "number" && spacing < 0;
    return (
        <Stack
            ref={ref}
            spacing={negativeSpacing ? spacing : undefined}
            id="column"
            sx={{ gap: negativeSpacing ? undefined : spacing, ...sx }}
            onMouseEnter={() => handleHovered?.(true)}
            onMouseLeave={() => handleHovered?.(false)}
            {...props}
        />
    );
});

interface RowProps extends ColumnProps {
    spaceBetween?: boolean;
    wrap?: boolean;
}
export const Row = forwardRef<HTMLDivElement, RowProps>(
    ({ spaceBetween, handleHovered, wrap, sx, spacing, ...props }, ref) => {
        return (
            <Stack
                ref={ref}
                onMouseEnter={() => handleHovered?.(true)}
                onMouseLeave={() => handleHovered?.(false)}
                id="row"
                sx={{
                    gap: spacing,
                    justifyContent: spaceBetween ? "space-between" : undefined,
                    alignItems: props.alignItems ?? "center",
                    flexWrap: wrap ? "wrap" : undefined,
                    ...sx
                }}
                direction="row"
                {...props}
            />
        );
    }
);

export function MediaWrapper({ query, children }: { query: string; children: ReactNode }) {
    return <Box sx={{ display: "none", [query]: { display: "initial" } }}> {children} </Box>;
}

interface MediaStackProps extends StackProps {
    query?: MediaQuery;
    spacing?: number;
    mobileSpacing?: number;
    spaceBetweenRow?: boolean;
    sxAbove?: SxType;
    sxBelow?: SxType;
}
export function MediaStack({
    query = MEDIA.LG,
    children,
    spacing = 1,
    mobileSpacing,
    spaceBetweenRow,
    sxAbove,
    sxBelow,
    sx,
    ...otherProps
}: MediaStackProps) {
    return (
        <Stack
            sx={{
                [query.below]: {
                    flexDirection: "column",
                    gap: `${SPACING * (mobileSpacing ?? (spacing || 0))}px`,
                    ...sxBelow
                },
                [query.above]: {
                    justifyContent: spaceBetweenRow ? "space-between" : undefined,
                    alignItems: "center",
                    flexDirection: "row",
                    gap: `${SPACING * (spacing || 0)}px`,
                    ...sxAbove
                },

                ...sx
            }}
            {...otherProps}
        >
            {children}
        </Stack>
    );
}

export function OutlinedCard({
    children,
    padding = 2,
    sx,
    spacing,
    hideCard,
    hoverable
}: {
    children: ReactNode;
    padding?: number;
    sx?: SxType;
    spacing?: number;
    hideCard?: boolean;
    hoverable?: boolean;
}) {
    const { border, cardBackground, hoverBackground } = useAppPalette();
    return (
        <Column
            spacing={spacing}
            sx={
                hideCard
                    ? sx
                    : {
                          ":hover": { background: hoverable ? hoverBackground : undefined },
                          border,
                          background: cardBackground,
                          borderRadius: BORDER_RADIUS,
                          p: padding,
                          ...sx
                      }
            }
        >
            {children}
        </Column>
    );
}

export function ShadowCard({
    children,
    spacing,
    sx,
    onClick,
    padding,
    handleHovered
}: {
    children: React.ReactNode;
    sx?: SxType;
    spacing?: number;
    onClick?: () => void;
    padding?: boolean;
    handleHovered?: DispatchType<boolean>;
}) {
    const { cardBackground, border } = useAppPalette();
    return (
        <Column
            onClick={onClick}
            handleHovered={handleHovered}
            spacing={spacing}
            sx={{
                p: padding ? "20px" : undefined,
                overflow: "hidden",
                borderRadius: BORDER_RADIUS,
                backgroundColor: cardBackground,
                border,
                ...sx
            }}
        >
            {children}
        </Column>
    );
}

export function DynamicGrid({
    children,
    minImageWidth = "200px",
    gridGap = 2,
    sx
}: {
    children: ReactNode;
    minImageWidth?: string;
    sx?: SxType;
    gridGap?: number;
}) {
    return (
        <Stack
            sx={{
                display: "grid",
                gridGap: `${gridGap * SPACING}px`,
                gridTemplateColumns: `repeat(auto-fill, minmax(${minImageWidth}, 1fr))`,
                ...sx
            }}
        >
            {children}
        </Stack>
    );
}

export function StaticGrid({
    children,
    columns,
    mobileColumns = 1,
    query = MEDIA.MD,
    sx,
    gridGap = 2
}: {
    children: ReactNode;
    mobileColumns?: number;
    columns: number;
    query?: MediaQuery;
    sx?: SxType;
    gridGap?: number;
}) {
    return (
        <Stack
            sx={{
                display: "grid",
                gridGap: `${gridGap * SPACING}px`,
                [query.above]: {
                    gridTemplateColumns: Array(columns).fill("1fr").join(" ")
                },
                [query.below]: {
                    gridTemplateColumns: Array(mobileColumns).fill("1fr").join(" ")
                },

                ...sx
            }}
        >
            {children}
        </Stack>
    );
}

export interface HeaderCardProps {
    header: ReactNode;
    headerVariant?: TextVariant;
    children: React.ReactNode;
    wrapperSx?: SxType;
    childrenSx?: SxType;
    spacing?: number;
    loading?: boolean;
    headerSpacing?: number;
}

export function HeaderCard({
    header,
    headerVariant = "h3",
    headerSpacing = 2,
    wrapperSx,
    children,
    childrenSx,
    spacing,
    loading
}: HeaderCardProps) {
    return (
        <Column spacing={headerSpacing} id="header-card" sx={{ width: "100%", ...wrapperSx }}>
            {typeof header === "string" ? (
                <Text loading={loading} variant={headerVariant}>
                    {header}
                </Text>
            ) : (
                header
            )}
            <Divider />
            <Column spacing={spacing} id="header-body" sx={{ ...childrenSx }}>
                {children}
            </Column>
        </Column>
    );
}

interface HeaderCardWithActionProps extends HeaderCardProps {
    header: ReactNode;
    actionElement: ReactNode;
    headerSx?: SxType;
    actionQuery?: MediaQuery; // push action to bottom
}
export function HeaderCardWithAction({
    actionElement,
    headerVariant = "h3",
    header,
    headerSx,
    children,
    actionQuery,
    ...props
}: HeaderCardWithActionProps) {
    const isAbove = useMediaQuery(actionQuery ? actionQuery.above : MEDIA.MD.above);
    const queryEnabled = !!actionQuery;
    return (
        <HeaderCard
            spacing={2}
            headerVariant={headerVariant}
            header={
                isAbove || !queryEnabled ? (
                    <Row sx={headerSx} spaceBetween>
                        {typeof header === "string" ? <Text variant={headerVariant}>{header}</Text> : header}
                        {actionElement}
                    </Row>
                ) : (
                    header
                )
            }
            {...props}
        >
            {children}
            {!isAbove && queryEnabled && !props.loading ? actionElement : null}
        </HeaderCard>
    );
}

export interface TabInterface {
    label: React.ReactNode;
    component: React.ReactNode;
    icon?: React.ReactNode;
    hide?: boolean;
    id?: string; // use for params
}
type Props = {
    tabs: TabInterface[];
    bodySx?: SxType;
    labelSx?: SxType;
    tabSx?: SxType;
    wrapperSx?: SxType;
    spacing?: number;
    childrenSpacing?: number;
    variant?: "standard" | "spaced-out" | "toggle-buttons";
    tabId?: string;
    paramsOptions?: { allowed: boolean; overrideExistingParams?: boolean };
    rowEndElement?: ReactNode;
    textVariant?: TextVariant;
    onTabClick?: () => void;
    loading?: boolean;
    localStorageKey?: string;
};

export function TabsCard({
    tabs: propTabs,
    bodySx,
    labelSx,
    tabSx,
    wrapperSx,
    spacing = 2,
    childrenSpacing,
    paramsOptions,
    rowEndElement,
    textVariant = "h3",
    onTabClick,
    variant = "standard",
    loading,
    localStorageKey
}: Props) {
    const tabs = propTabs.filter((t) => !t.hide);
    const [params, updateParams] = useSearchParams();
    const open = params.get("open");
    const openIndex = tabs.map((t, i) => t.id ?? i.toString()).findIndex((t) => t === open);
    const tabIndex = !paramsOptions?.allowed || openIndex === -1 ? undefined : openIndex;

    const localStorageState = useLocalStorage(localStorageKey ?? "", tabIndex || 0);
    const localState = React.useState<number>(tabIndex || 0);
    const [openTab, setOpenTab] = localStorageKey ? localStorageState : localState;

    const selectedTab = openTab < tabs.length ? tabs[openTab] : tabs[0];
    useEffect(() => {
        if (tabIndex !== undefined && tabIndex !== openTab) {
            setOpenTab(tabIndex);
        }
    }, [openTab, setOpenTab, tabIndex]);

    const handleTabClick = useCallback(
        (tab: TabInterface, index: number) => {
            setOpenTab(index);
            onTabClick?.();
            if (paramsOptions?.allowed) {
                const tabId = tabs[index].id ?? index.toString();
                if (paramsOptions.overrideExistingParams) {
                    updateParams({ open: tabId });
                } else {
                    params.set("open", tabId);
                    updateParams(params);
                }
            }
        },
        [
            setOpenTab,
            onTabClick,
            paramsOptions?.allowed,
            paramsOptions?.overrideExistingParams,
            tabs,
            updateParams,
            params
        ]
    );

    // edge case
    if (tabs.length === 0) return null;

    if (loading) {
        return (
            <HeaderCard header={<TextSkeleton variant={textVariant} />} spacing={spacing}>
                <Column spacing={childrenSpacing} sx={bodySx}>
                    {selectedTab.component}
                </Column>
            </HeaderCard>
        );
    }

    return (
        <Column sx={wrapperSx} spacing={spacing}>
            {variant === "toggle-buttons" && (
                <ToggleSpacedButtons
                    containerSx={{ overflowX: "auto", ...hideScrollbarSx }}
                    options={tabs.map((t, i) => ({ value: i, label: t.label }))}
                    value={openTab}
                    setValue={(index) => handleTabClick(tabs[index], index)}
                />
            )}
            {variant !== "toggle-buttons" && (
                <TabWrapper sx={tabSx}>
                    {tabs.map((tab, i) => (
                        <CustomTab
                            hideStyles={tabs.length === 1}
                            textVariant={textVariant}
                            index={i}
                            wrapperSx={variant === "spaced-out" ? { width: "100%", alignItems: "center", pt: 1.5 } : {}}
                            labelSx={{ paddingLeft: i === 0 ? 0 : undefined, ...labelSx }}
                            onClick={() => handleTabClick(tab, i)}
                            icon={tab.icon}
                            label={tab.label}
                            isSelected={openTab === i}
                            key={i}
                        />
                    ))}
                    {/* last "tab" becomes rest of border */}
                    {variant === "standard" && <TabDivider />}
                    {rowEndElement ? (
                        <CustomTab textVariant={textVariant} index={-1} label={rowEndElement} isSelected={false} />
                    ) : null}
                </TabWrapper>
            )}
            <Column spacing={childrenSpacing} sx={bodySx}>
                {selectedTab.component}
            </Column>
        </Column>
    );
}

export function TabsSelect<T extends string | number>({
    value,
    setValue,
    options,
    wrapperSx,
    textVariant = "h4"
}: {
    options: SelectOption<T>[];
    value: T;
    setValue: DispatchType<T>;
    wrapperSx?: SxType;
    textVariant?: TextVariant;
}) {
    return (
        <TabWrapper sx={wrapperSx}>
            {options.map((tab, i) => (
                <CustomTab
                    hideStyles={options.length === 1}
                    textVariant={textVariant}
                    index={i}
                    wrapperSx={{ width: "100%", alignItems: "center", pt: 1.5 }}
                    onClick={() => {
                        setValue(tab.value as T);
                    }}
                    label={tab.label ?? tab.value}
                    isSelected={tab.value === value}
                    key={i}
                />
            ))}
        </TabWrapper>
    );
}

function TabWrapper({ children, sx }: { children: ReactNode; sx?: SxType }) {
    return (
        <Stack
            role="tablist"
            aria-orientation="horizontal"
            sx={{
                flexDirection: "row",
                alignItems: "flex-end",
                width: "100%",
                maxWidth: "100vw",
                overflow: "auto",
                "::-webkit-scrollbar ": { display: "none" },
                scrollbarWidth: "none",
                ...sx
            }}
        >
            {children}
        </Stack>
    );
}

export function TabDivider() {
    return (
        <CustomTab
            index={-1}
            wrapperSx={{ width: "100%" }}
            label={<Stack sx={{ width: "100%" }} />}
            isSelected={false}
        />
    );
}

export function CustomTab({
    label,
    isSelected,
    onClick,
    labelSx,
    index,
    icon,
    wrapperSx,
    textVariant = "h3",
    hideStyles
}: {
    label: React.ReactNode;
    icon?: React.ReactNode;
    isSelected: boolean;
    onClick?: () => void;
    labelSx?: SxType;
    wrapperSx?: SxType;
    index: number;
    textVariant?: TextVariant;
    hideStyles?: boolean;
}) {
    const { textPrimary, hoverBackground } = useAppPalette();

    return (
        <ButtonBase
            disableRipple
            tabIndex={index === -1 ? -1 : 0}
            role="tab"
            sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: BORDER_RADIUS,
                gap: ["h4", "body1", "body2"].includes(textVariant) ? 1 : 2,
                pt: 0.5,
                ":focus-visible": { outline: "none", background: index === -1 ? "none" : hoverBackground },
                cursor: onClick && !hideStyles ? "pointer" : "default",
                minWidth: "fit-content",
                ...wrapperSx
            }}
            onClick={onClick}
        >
            {typeof label === "string" ? (
                <Text sx={{ px: 2, ...labelSx }} color={isSelected ? "body" : "disabled"} variant={textVariant}>
                    {icon}
                    {label}
                </Text>
            ) : (
                label
            )}
            <Divider sx={{ borderColor: isSelected && !hideStyles ? textPrimary : undefined }} flexItem />
        </ButtonBase>
    );
}

export function AbsoluteCenterWrapper({ children }: { children: React.ReactNode }) {
    return (
        <Stack
            sx={{
                position: "fixed",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {children}
        </Stack>
    );
}

export function TabButtons({ tabs }: { tabs: TabInterface[] }) {
    const [index, setIndex] = useState(0);
    return (
        <>
            <ToggleSpacedButtons
                containerSx={{ overflowX: "auto", ...hideScrollbarSx }}
                value={index}
                setValue={setIndex}
                options={tabs.map((t, i) => ({ value: i, label: t.label }))}
            />
            {tabs[index]?.component}
        </>
    );
}

interface VerticalScrollProps extends StackProps {
    maxHeight: number;
    maxHeightMobile?: string;
}
export const VerticalScroll = forwardRef<HTMLDivElement, VerticalScrollProps>(
    ({ sx, maxHeight, children, spacing = 0.5, maxHeightMobile = "30vh", ...props }, ref) => {
        return (
            <Stack
                id="vertical-scroll"
                ref={ref}
                sx={{
                    maxHeight: `${maxHeight}px`,
                    [`@media (max-height: ${DIALOG_WINDOW_CENTER_MIN_HEIGHT}px)`]: {
                        maxHeight: maxHeightMobile
                    },
                    minHeight: "100%",
                    overflowY: "auto",
                    "::-webkit-scrollbar ": { display: "none" },
                    scrollbarWidth: "none",
                    gap: spacing,
                    ...sx
                }}
                {...props}
            >
                {children}
            </Stack>
        );
    }
);

interface VerticalScrollContainerProps extends StackProps {
    maxHeight?: number;
    loading?: boolean;
    isOverflowing?: boolean;
    scrollFadeHeight?: number;
}

export function VerticalScrollContainer({
    sx,
    maxHeight = 200,
    scrollFadeHeight = 50,
    children,
    loading,
    isOverflowing = true,
    ...props
}: VerticalScrollContainerProps) {
    const { paperBackground, border, primary } = useAppPalette();
    const { ref, hasScrolled } = useScrollDetector();

    const height = ref.current?.clientHeight || 0;
    const scrollHeight = 50;

    const fadeNeeded = isOverflowing || (!loading && height - scrollHeight >= maxHeight);

    return (
        <Stack
            ref={ref}
            sx={{
                gap: 0.5,
                position: "relative",
                maxHeight: `${maxHeight}px`,
                overflowY: fadeNeeded ? "auto" : undefined,
                minHeight: "fit-content",
                "::-webkit-scrollbar ": { display: "none" },
                scrollbarWidth: "none",
                ...sx
            }}
            {...props}
        >
            {children}
            {fadeNeeded && (
                <Stack
                    sx={{
                        opacity: hasScrolled ? 0 : 1,
                        justifyContent: "center",
                        alignItems: "center",
                        background: `linear-gradient(transparent, ${paperBackground})`,
                        height: hasScrolled ? 0 : `${scrollFadeHeight}px`,
                        transition: "0.3s",
                        width: "100%",
                        position: "sticky",
                        bottom: 0
                    }}
                >
                    <KeyboardArrowDown
                        sx={{
                            color: primary,
                            mb: 0.5,
                            fontSize: "14px",
                            p: `${SPACING / 2}px`,
                            borderRadius: "100%",
                            backgroundColor: paperBackground,
                            boxShadow: LIGHT_SMALL_SHADOW,
                            border
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
}

export function ExpandContent({
    children,
    sx,
    headerSx,
    header,
    textVariant,
    textColor,
    defaultExpanded = false,
    hideExpand = false,
    spacing = 1
}: {
    header: string | JSX.Element;
    children: ReactNode;
    sx?: SxType;
    headerSx?: SxType;
    textVariant?: TextVariant;
    textColor?: TextColor;
    defaultExpanded?: boolean;
    hideExpand?: boolean;
    spacing?: number;
}) {
    const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

    if (hideExpand) {
        return (
            <Stack spacing={spacing} sx={sx}>
                <Text variant={textVariant} color={textColor}>
                    {header}
                </Text>
                {children}
            </Stack>
        );
    }

    return (
        <Stack spacing={spacing} sx={sx}>
            <Row
                sx={{ cursor: "pointer", ...headerSx }}
                spaceBetween
                onClick={() => setExpanded(!expanded)}
                spacing={1}
            >
                {typeof header === "string" ? (
                    <Text variant={textVariant} color={textColor}>
                        {header}
                    </Text>
                ) : (
                    header
                )}
                <Text variant={textVariant} color={textColor}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </Text>
            </Row>

            {expanded ? children : null}
        </Stack>
    );
}

export function TabbableButton({ children, onClick, sx }: { children: ReactNode; onClick: () => void; sx?: SxType }) {
    const { hoverBackground } = useAppPalette();
    return (
        <ButtonBase
            disableRipple
            onClick={onClick}
            tabIndex={0}
            sx={{
                pt: 0.5,
                borderRadius: BORDER_RADIUS,
                ":focus-visible": {
                    backgroundColor: hoverBackground
                },
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ...sx
            }}
        >
            {children}
        </ButtonBase>
    );
}

export interface AlertWrapperProps extends Omit<RowProps, "color"> {
    color?: TagColor;
}
export function AlertWrapper({ color = "body", sx, ...otherProps }: AlertWrapperProps) {
    const baseColor = useColorFromTag(color);
    const { isDarkMode } = useAppPalette();
    return (
        <Row
            sx={{
                border: `1px solid ${colorToAlpha(baseColor, 0.5)}`,
                borderRadius: BORDER_RADIUS,
                p: 1.5,
                backgroundColor: colorToAlpha(baseColor, isDarkMode ? 0.1 : 0.05),
                color: baseColor,
                ...sx
            }}
            {...otherProps}
        />
    );
}

export function PageAlert({
    header,
    description,
    rowEnd,
    color = "body",
    icon
}: {
    header: string;
    description: string | null;
    rowEnd?: ReactNode;
    color?: Exclude<RawTagColor, "warning">;
    icon?: ReactNode;
}) {
    return (
        <AlertWrapper spacing={1} wrap color={color} spaceBetween>
            <Row alignItems="flex-start" spacing={1}>
                <Text variant="h3" color={color}>
                    {icon}
                </Text>
                <Column>
                    <Text>{header}</Text>
                    {description && (
                        <Text color="caption" variant="body2">
                            {description}
                        </Text>
                    )}
                </Column>
            </Row>
            {rowEnd}
        </AlertWrapper>
    );
}

export interface FlowStepProps {
    expandedElement: ReactNode;
    collapsedElement?: ReactNode;
    header: string;
    description?: string;
    optional?: boolean;
    buttonProps?: ButtonProps;
}

export function FlowStep({
    expandedElement,
    collapsedElement,
    status,
    headerVariant = "h3",
    header,
    description,
    optional,
    buttonProps,
    onModify,
    next
}: FlowStepProps & {
    status: "complete" | "active" | "incomplete";
    headerVariant?: TextVariant;
    onModify: () => void;
    next?: () => void;
}) {
    return (
        <Column spacing={2}>
            <Row spaceBetween>
                <Text color={status === "incomplete" ? "disabled" : "body"} variant={headerVariant}>
                    {header}
                </Text>
                {status === "active" && optional && <Text color="caption">Optional </Text>}
                {status === "complete" && (
                    <Text isLink color="caption" underlineLink="always" onClick={onModify}>
                        Modify
                    </Text>
                )}
            </Row>

            {status === "active" && <Divider />}
            {status === "active" && !!description && (
                <Text variant="body2" color="caption">
                    {description}
                </Text>
            )}

            {(() => {
                if (status !== "complete") return null;
                if (typeof collapsedElement === "string") return <Text color="caption">{collapsedElement}</Text>;
                return collapsedElement;
            })()}
            {status === "active" ? expandedElement : null}

            {status === "active" && next && (
                <Button onClick={next} fullWidth variant="contained" {...buttonProps}>
                    {buttonProps?.children ?? "Continue"}
                </Button>
            )}
        </Column>
    );
}

export function FlowStepper({
    activeIndex,
    setActiveIndex,
    steps,
    headerVariant
}: {
    activeIndex: number;
    setActiveIndex: DispatchType<number>;
    steps: FlowStepProps[];
    headerVariant?: TextVariant;
}) {
    return (
        <Column spacing={4}>
            {steps.map((step, i) => (
                <FlowStep
                    key={i}
                    next={i < steps.length - 1 ? () => setActiveIndex(i + 1) : undefined}
                    onModify={() => setActiveIndex(i)}
                    headerVariant={headerVariant}
                    status={(() => {
                        if (i === activeIndex) return "active";
                        if (i < activeIndex) return "complete";
                        return "incomplete";
                    })()}
                    {...step}
                />
            ))}
        </Column>
    );
}

type AccordionType = { label: React.ReactNode; component: React.ReactNode; hide?: boolean };
export function Accordion({
    accordions: accordionsRaw,
    labelVariant = "h4",
    defaultOpen
}: {
    accordions: AccordionType[];
    labelVariant?: TextVariant;
    defaultOpen?: number;
}) {
    const [openIndexState, setOpenIndex] = useState<number | undefined | null>(undefined);
    const openIndex = openIndexState === undefined ? defaultOpen : openIndexState;
    const accordions = accordionsRaw.filter((a) => !a.hide);
    return (
        <Column spacing={1.5}>
            {accordions.map(({ label, component }, i) => (
                <Column spacing={1} key={i}>
                    <ButtonBase disableRipple>
                        <Row
                            sx={{ width: "100%" }}
                            onClick={() => (openIndex === i ? setOpenIndex(null) : setOpenIndex(i))}
                            spaceBetween
                        >
                            <Text variant={labelVariant}>{label} </Text>
                            <Text sx={{ fontSize: "20px" }} color="disabled" isLink>
                                {openIndex === i ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                            </Text>
                        </Row>
                    </ButtonBase>
                    <Collapse in={openIndex === i}>{component}</Collapse>
                </Column>
            ))}
        </Column>
    );
}
