import { AbfUserInvite } from "./invites";
import { AbfLoanVault } from "./loan";
import { AbfOrder } from "./order";

export interface SyndicatedOrder {
    id: number;
    address: string;
    maker: string;
    groupIdentifier: string;
    nonce: string;
    order: string;
    principalMint: string;
    principalAmount: number;
    collateralMint: string;
    collateralAmount: number;
    designatedTaker: string;
    maxOutstandingPayments: number;
    orderNonce: string;
    principalDeposited: number;
    principalRedeemed: number;
    creationTime: number;
    status: SyndicatedOrderStatus;
    allowEarlyRepayments: boolean | undefined;
}

export enum SyndicatedOrderStatus {
    Initialized = 0,
    Funded = 1,
    Active = 2,
    Closed = 3,
    Default = 4,
    ActiveLoan = 5,
    ToBeClosed = 6
}

export function getSyndicatedOrderStatus(status?: number, placedOrder?: AbfOrder, loanVault?: AbfLoanVault) {
    if (
        ((loanVault && placedOrder?.address !== loanVault?.order) || (!placedOrder && loanVault)) &&
        status !== SyndicatedOrderStatus.Closed
    )
        return SyndicatedOrderStatus.ToBeClosed;
    if (placedOrder && loanVault && placedOrder?.address === loanVault?.order) return SyndicatedOrderStatus.ActiveLoan;
    return status;
}

export function getSyndicatedOrderStatusTag(status: number, placedOrder?: AbfOrder, loanVault?: AbfLoanVault) {
    const orderStatus = getSyndicatedOrderStatus(status, placedOrder, loanVault);
    switch (orderStatus) {
        case SyndicatedOrderStatus.Initialized:
            return "Pending";
        case SyndicatedOrderStatus.Funded:
            return "Pending";
        case SyndicatedOrderStatus.Active:
            return "Placed";
        case SyndicatedOrderStatus.Closed:
            return "Closed";
        case SyndicatedOrderStatus.ActiveLoan:
            return "Active";
        case SyndicatedOrderStatus.ToBeClosed:
            return "Competing offer accepted";
    }
}

export function isSyndicatedOrderActive(status?: SyndicatedOrderStatus) {
    return status === SyndicatedOrderStatus.Active || status === SyndicatedOrderStatus.ActiveLoan;
}

export function isSyndicatedOrderCancellable(status?: SyndicatedOrderStatus) {
    return !(status === SyndicatedOrderStatus.ActiveLoan || status === SyndicatedOrderStatus.Closed);
}

export interface SyndicatedOrderContributor {
    id: number;
    address: string;
    nonce: string;
    groupIdentifier: string;
    syndicatedOrder: string;
    nftMint: string;
    principalDeposited: number;
    lastClaimedPrincipalAmount: number;
    lastClaimedLedgerId: number;
    principalRedeemed: number;
    creationTime: number;
    lastUpdateTime: number;
    status: number;
}

export interface SyndicatedOrdersWithContributors {
    data: SyndicatedOrder;
    contributors: Record<string, SyndicatedOrderContributor>;
    invites: Record<string, AbfUserInvite>;
}
