import { AbfEscrowAccount, AssetClass, BsMetadata, DurationUnit, ExternalYieldInfo } from "@bridgesplit/abf-sdk";

import { PresetTerms } from "./preset";

export interface StrategyExpanded extends StrategyInfo {
    totalBalance: number; // includes escrowed balance + external yield balance
    principalMetadata: BsMetadata;
    collateral: StrategyCollateral[];
    escrowAccount: AbfEscrowAccount | null;
    principalUsdPrice: number;
    loanStats: StrategyLoanStats;
}

export interface StrategyLoanStats {
    // active loans
    activeLoanCount: number;
    wAvgApy: number;
    principalDeployed: number;
    interestAccrued: number;
    // total loans
    totalPrincipalOriginated: number;
    totalInterestEarned: number;
    totalLoanCount: number;
}

export interface StrategyCollateral {
    metadata: BsMetadata;
    terms: AbfLendingStrategyAssetTerms[];
}

export interface StrategyInfo {
    strategy: AbfLendingStrategy;
    strategyEscrowBalance: number;
    offerWithTermsAndFilter: OfferWithTermsAndFilter[];
    externalYieldInfo: ExternalYieldInfo | null;
    liquidationTypes: LiquidationType[];
    loanStats: StrategyLoanStats;
}

export interface OfferWithTermsAndFilter {
    offer: AbfLendingStrategyOffer;
    offerTerms: OfferTermsInfo[];
    offerFilters: AbfLendingStrategyOfferCollateralFilter[];
}

export interface OfferTermsInfo {
    offerAssetTerms: { terms: AbfLendingStrategyAssetTerms };
}

export interface LendingStrategyFilter {
    groups?: string[];
    principalMints?: string[];
    identifiers?: string[];
    escrowAccounts?: string[];
}

export interface EditLendingStrategyInput {
    identifier: string;
    name: string;
    description: string;
    liquidation_types: LiquidationType[];
}

export interface AssetTermsInfo {
    offerIdentifier: string;
    ltv: number; // 0.01 bps
    apy: number; // 0.01 bps
    duration: number;
    durationType: number;
    repaymentRrule: string;
    maxOutstandingPayments: number;
    allowEarlyRepayments: boolean;
    liquidationThreshold: number | null; // defaults to u64.max if null
}

export interface EditAssetTermsInput {
    strategyIdentifier: string;
    assetTermsToInfo: Record<string, AssetTermsInfo>;
}

export interface AbfLendingStrategy {
    id: number;
    name: string;
    description: string;
    group: string;
    identifier: string;
    escrow: string;
    principalMint: string;
    archived: boolean | null;
    onchain: boolean | null;
    ltvSlippage: number | null;
    createdAt: number;
}

export interface AbfLendingStrategyAssetTerms {
    id: number;
    identifier: string;
    offerIdentifier: string;
    lendingStrategyIdentifier: string;
    ltv: number;
    apy: number;
    duration: number;
    durationType: DurationUnit;
    repaymentRrule: string;
    maxOutstandingPayments: number;
    allowEarlyRepayments: boolean;
    liquidationThreshold: number;
}

export interface AbfLendingStrategyOffer {
    id: number;
    strategyIdentifier: string;
    identifier: string;
    name: string;
    description?: string;
    minPrice: number;
}

export interface AbfLendingStrategyTerms {
    id: number;
    identifier: string;
    lendingStrategyIdentifier: string;
    duration: number;
    durationType: DurationUnit;
    repaymentRrule: string;
    maxOutstandingPayments: number;
    allowEarlyRepayments: boolean | undefined;
}

export interface AbfLendingStrategyOfferCollateralFilter {
    id: number;
    offerIdentifier: string;
    identifier: string;
    traitName: string;
    traitValue: string;
    comparator: FilterComparator;
    assetClass?: AssetClass;
}
export type FilterComparator = "=" | ">" | "<" | ">=" | "<=" | "INC";

// in contract, LiquidationType enum is called DefaultType
export enum LiquidationType {
    OutstandingPayment,
    DirectSeizure
}

export interface StrategyTemplate {
    name: string;
    description: string | null;
    identifier: string;
    logo: string;
    principalMint: string | null;
}
export interface StrategyTemplateInfo {
    template: StrategyTemplate;
    presetsIdentifiers: string[];
}

export const STRATEGY_TEMPLATE_ALL_IDENTIFIER = "all";
export const STRATEGY_TEMPLATE_CUSTOMIZE_IDENTIFIER = "custom";

export interface StrategyTemplateExpanded extends StrategyTemplateInfo {
    presets: PresetTerms[];
    collateral: BsMetadata[];
}
