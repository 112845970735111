export type TransactionsWalletConnectProps = {
    error: WalletConnectError | undefined;
    isLoading: boolean;
    header?: { description: string };
};

export enum WalletConnectError {
    None,
    PasskeyError,
    NotRegistered,
    NotConnected,
    NotApprovedByAdmin
}
