import { LoopPositionExpanded, LoopscalePointSource } from "@bridgesplit/abf-react";
import { filterTruthy } from "@bridgesplit/utils";
import { getLoopEligibleRewardSources } from "app/components/common";
import { RateAndRewardsBreakdown } from "app/components/points";

export function LoopPositionApy({ loopPosition }: { loopPosition: LoopPositionExpanded | undefined }) {
    if (!loopPosition?.userLoopInfo.netApy) return null;

    const metadata = [loopPosition?.loopExpanded.principalToken, loopPosition?.loopExpanded.collateralToken].filter(
        filterTruthy
    );
    const pointSources = getLoopEligibleRewardSources(metadata);

    const totalAmount =
        (loopPosition?.loanExpanded.principalAmount ?? 0) * (loopPosition?.loanExpanded.principalUsdPrice ?? 0);

    return (
        <RateAndRewardsBreakdown
            pointSources={pointSources}
            loopscalePointSources={[LoopscalePointSource.Borrow]}
            totalAmount={totalAmount}
            metadata={metadata}
            loopLeverage={loopPosition.userLoopInfo.leverageMultiplier}
            apy={loopPosition.userLoopInfo.netApy}
            isSpecific
            rateDisplayProps={{ showApyLabel: true }}
        />
    );
}
