import { AbfEscrowedAsset } from "./escrow";
import { FeeScheduleInfo } from "./fee";
import { LoanRequest, LoanRequestCollateral } from "./request";
import { DurationUnit } from "./time";

export interface AbfOrderResponse {
    orders: Record<string, OrderInfo>;
    escrowedAssets: Record<string, Record<string, AbfEscrowedAsset>>;
    loanRequests: MatchedRequestResponse;
    feeInfos: Record<string, FeeScheduleInfo>;
}

export interface OrderInfo {
    order: AbfOrder;
    orderSchedule: AbfOrderSchedule;
}

export type MatchedRequestResponse = Record<
    string,
    { loanRequest: LoanRequest; assetInfos: LoanRequestCollateral[]; custodianMapping: Record<string, string> }
>;

export interface AbfOrder {
    id: number;
    address: string;
    version: AbfOrderVersion;
    nonce: string;
    maker: string;
    designatedTaker: string;
    principalMint: string;
    principalAmount: number;
    collateralMint: string;
    collateralAmount: number;
    makerRole: AbfOrderSide;
    lastUpdatedSlot: number;
    maxOutstandingPayments: number;
    lastPaymentId: number;
    finalPaymentId: number;
    finalPaymentTimeOffset: number;
    status: AbfOrderStatus;
    defaultType: AbfOrderDefaultType;
    fundingType: AbfOrderFundingType;
    txn: string;
    createdAt: number;
    allowEarlyRepayments: boolean | undefined;
    lastInteractedTime: number;
    orderLedgers: string;
    originationFee: number;
    liquidationThreshold: number;
    liquidationManager: string;
    maxRefinanceApy: number | null;
    refinanceDuration: number;
    refinanceDurationType: DurationUnit;

    refinanceEnabled: boolean;
}

export enum CompoundingFrequency {
    Simple = 0,
    Continuous = 1
}
export enum AbfOrderStatus {
    NotReady = 0, // open order without ledgers created
    Active = 1, // open order with ledgers created
    Fulfilled = 2, // filled order
    InDefault = 3, // filled order with the loan defaulted
    Closed = 4, // filled order w loan repaid
    Liquidated = 5, // filled order w loan liquidated
    Cancelled = 6, // open order that was cancelled
    PendingDefault = 7,
    Refinanced = 8
}

enum AbfOrderDefaultType {
    MissedPayments
}

export enum AbfOrderFundingType {
    Standard,
    Syndicated,
    FlashLoan
}

enum AbfOrderVersion {
    V1,
    V2
}

export enum AbfOrderSide {
    Borrow,
    Lend
}

export interface AbfOrderSchedule {
    id: number;
    orderAddress: string;
    frequencyRrule: string;
    maxOutstandingPayments?: number;
    apy: number;
    duration: number;
    durationType: DurationUnit;
    compoundingFrequency: CompoundingFrequency;
}
