import { DialectSolanaSdk } from "@dialectlabs/react-sdk-blockchain-solana";
import { Notifications } from "@dialectlabs/react-ui";
import { DialogWrapper } from "@bridgesplit/ui";

import { DIALECT_DAPP_ADDRESS } from "./common";

import "./dialect.css";

export default function DialectNotificationDialog() {
    return (
        <DialogWrapper>
            <DialectSolanaSdk dappAddress={DIALECT_DAPP_ADDRESS}>
                <Notifications />
            </DialectSolanaSdk>
        </DialogWrapper>
    );
}
