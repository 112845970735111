import { useState } from "react";

import {
    AbfPermissionForCustodian,
    BsMetaUtil,
    summarizeLedgerAccounts,
    useIsLoanEscrowBased,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { MayNumber } from "@bridgesplit/utils";
import { Column, TokenInput } from "@bridgesplit/ui";

import { MakeRepaymentParams, MakeRepaymentProps } from "./types";
import { AppButton, useBalanceChecker } from "../../../common";
import { usePrincipalRepay, calculateNewLoanInterestDue } from "./util";
import RepayStats from "./RepayStats";

export default function RepayPrincipal({ loanExpanded }: MakeRepaymentProps) {
    const [inputAmount, setAmount] = useState<MayNumber>();
    const { principalOutstanding } = summarizeLedgerAccounts(loanExpanded);

    const principalBeingPaid = Math.min(principalOutstanding, inputAmount ?? 0);

    const payment = calculateNewLoanInterestDue({
        loanExpanded,
        repaymentAmount: principalBeingPaid,
        ledgerAccounts: loanExpanded?.ledgerAccounts,
        isPrincipalRepay: true
    });
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const params: MakeRepaymentParams = {
        loanExpanded,
        payment,
        isFullRepay: false
    };

    const { principalRepay } = usePrincipalRepay(params);
    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);

    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        mint: loanExpanded?.principalMetadata.assetMint,
        amount: principalBeingPaid,
        escrowNeeded,
        setMax: (val) => setAmount(val ? Math.min(val, principalOutstanding) : val)
    });

    return (
        <>
            <TokenInput
                symbol={symbol}
                label="Amount"
                value={inputAmount}
                decimals={loanExpanded?.principalMetadata.decimals}
                setValue={setAmount}
                maxText="Total due: "
                maxAmount={principalOutstanding}
                maxButtonCta="Full payment"
            />
            <RepayStats loanExpanded={loanExpanded} paymentInfo={payment} />

            <Column spacing={0.5}>
                <AppButton
                    isTransaction
                    authentication={{
                        permission: AbfPermissionForCustodian.RepayLoan,
                        custodians: getLoanCustodianIdentifiers(loanExpanded)
                    }}
                    disabled={!inputAmount || insufficientBalance}
                    asyncCta={{ onClickWithResult: principalRepay, closeDialog: "onSuccess" }}
                    fullWidth
                >
                    Repay
                </AppButton>
                <BalanceDisplay />
            </Column>
        </>
    );
}
