import { useMemo, useState } from "react";

import {
    BsMetaUtil,
    LoopExpandedMeteora,
    METEORA_POLL_INTERVAL,
    useMeteoraWithdrawQuoteQuery
} from "@bridgesplit/abf-react";
import { Meteora } from "@bridgesplit/bs-protos";
import { uiAmountToLamports, bigNumberStringToUiAmount } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StatColumn, StatProps } from "@bridgesplit/ui";

import { useWithdrawMeteoraLp } from "./util";
import { AppButton, TokenInputWithPrice, useBalanceChecker } from "../../common";
import { MeteoraCtaWrapper } from "./common";

export default function MeteoraWithdraw({
    loopExpanded,
    slippagePercentUi
}: {
    loopExpanded: LoopExpandedMeteora;
    slippagePercentUi: number;
}) {
    const [lpAmount, setLpAmount] = useState<number | undefined>(undefined);

    const withdrawParams = useMemo((): Meteora.DynamicPoolWithdrawQuoteParams | undefined => {
        if (!lpAmount) return undefined;
        const withdrawAmount = uiAmountToLamports(lpAmount, loopExpanded.collateralToken.decimals);

        const { poolAddress } = loopExpanded.meteoraPool;

        return {
            pool: poolAddress,
            tokenMint: loopExpanded.principalToken.assetMint,
            withdrawTokenAmount: withdrawAmount.toString(),
            slippage: slippagePercentUi
        };
    }, [
        loopExpanded.collateralToken.decimals,
        loopExpanded.meteoraPool,
        loopExpanded.principalToken.assetMint,
        lpAmount,
        slippagePercentUi
    ]);

    const { data: externalQuote } = useMeteoraWithdrawQuoteQuery(withdrawParams ?? skipToken, {
        skip: !withdrawParams,
        pollingInterval: METEORA_POLL_INTERVAL
    });

    const withdraw = useWithdrawMeteoraLp();
    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        amount: lpAmount,
        mint: loopExpanded.collateralToken.assetMint,
        setMax: setLpAmount
    });
    return (
        <>
            <TokenInputWithPrice
                label="You withdraw"
                value={lpAmount}
                setValue={setLpAmount}
                metadata={loopExpanded.collateralToken}
                selectToken={null}
                belowInput={<BalanceDisplay />}
            />
            {!!lpAmount && <Stats quote={externalQuote} loopExpanded={loopExpanded} />}
            <MeteoraCtaWrapper loopExpanded={loopExpanded} side="withdrawals">
                <AppButton
                    isTransaction
                    disabled={!externalQuote || insufficientBalance}
                    asyncCta={{
                        onClickWithResult: () => withdraw(loopExpanded, externalQuote),
                        options: { alertOnError: true }
                    }}
                    color="secondary"
                >
                    Withdraw
                </AppButton>
            </MeteoraCtaWrapper>
        </>
    );
}

function Stats({
    quote,
    loopExpanded
}: {
    quote: Meteora.DynamicPoolWithdrawQuote | undefined;
    loopExpanded: LoopExpandedMeteora;
}) {
    const tokenAIsPrincipal = loopExpanded.meteoraPool.tokenAMint === loopExpanded.principalToken.assetMint;

    const stats: StatProps[] = [
        {
            value: BsMetaUtil.formatAmount(
                loopExpanded.principalToken,
                bigNumberStringToUiAmount(
                    tokenAIsPrincipal ? quote?.tokenAOutAmount : quote?.tokenBOutAmount,
                    loopExpanded.principalToken.decimals
                )
            ),
            caption: "You receive"
        },
        {
            value: BsMetaUtil.formatAmount(
                loopExpanded.principalToken,
                bigNumberStringToUiAmount(
                    tokenAIsPrincipal ? quote?.minTokenAOutAmount : quote?.minTokenBOutAmount,
                    loopExpanded.principalToken.decimals
                )
            ),
            caption: "Minimum received"
        }
    ];

    return <StatColumn stats={stats} loading={!quote} />;
}
