export const APP_MAX_WIDTH = "1200px";
export const SMALL_MAX_WIDTH = "1000px";
export const XS_MAX_WIDTH = "700px";

export const CARD_WIDTH = "370px";
export const PAGE_CARD_WIDTH = "450px";

export const INPUT_MAX_WIDTH = "600px";
export const WIDE_MAX_WIDTH = "1600px";

export const SCROLL_WIDTH = 12;
export const MAX_DIALOG_HEIGHT = 350;
