import { useMemo } from "react";

import {
    BackCta,
    Column,
    FONT_SIZES,
    Image,
    Row,
    TagTextAlert,
    Text,
    useAppPalette,
    useCopyAddress
} from "@bridgesplit/ui";
import { useUserPublicKey, useWeb3User } from "@bridgesplit/react";
import { useActiveWallet, useCreateGroup, useSignInTransactionAuth } from "@bridgesplit/abf-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Result, formatAddress } from "@bridgesplit/utils";
import { ContentCopyOutlined } from "@mui/icons-material";

import { useWalletSetupGroupToCreate } from "./util";
import { AppButton, DialogHeader } from "../../common";

/**
 * If user can update on-chain perms, verify using an update_roles txn
 * Otherwise, verify use an empty transaction
 */
export default function VerifyWallet({ back }: { back: () => void }) {
    const userPublicKey = useUserPublicKey();
    const { createGroupParams } = useWalletSetupGroupToCreate();
    const { activePubkeysSet } = useActiveWallet();
    const { select, disconnect } = useWallet();

    const walletAlreadyAdded = userPublicKey ? activePubkeysSet.has(userPublicKey) : false;

    const signIn = useSignInTransactionAuth();
    const createGroup = useCreateGroup();

    const { getWeb3UserResult } = useWeb3User();

    async function submit() {
        const web3UserRes = getWeb3UserResult();

        if (!web3UserRes.isOk()) return Result.errFromMessage("No wallet connected");
        const auth = web3UserRes.unwrap();
        const signedTransactionRes = await signIn(auth);
        if (!signedTransactionRes.isOk()) return Result.err(signedTransactionRes);

        const wallet = auth.wallet.publicKey.toString();
        return await createGroup({
            ...createGroupParams,
            group_name: wallet,
            admin_wallet: wallet,
            signedTransaction: signedTransactionRes.unwrap()
        });
    }

    const description = useMemo(() => {
        if (walletAlreadyAdded) return "Your wallet is already verified";

        return "The wallet you link cannot be changed later";
    }, [walletAlreadyAdded]);

    return (
        <>
            <Header />
            <ConnectedWallet />
            <TagTextAlert icon="tooltip" color="info">
                {description}
            </TagTextAlert>

            <Column spacing={1}>
                <AppButton
                    overrideAccess
                    isTransaction={false}
                    color="secondary"
                    disabled={!userPublicKey}
                    asyncCta={{
                        closeDialog: "onSuccess",
                        onClickWithResult: submit,
                        options: { onSuccessMessage: "Wallet linked" }
                    }}
                >
                    Link wallet
                </AppButton>

                <BackCta
                    back={async () => {
                        await disconnect();
                        select(null);
                        back();
                    }}
                />
            </Column>
        </>
    );
}

function Header() {
    return <DialogHeader header="Link wallet" description=" Sign a message verifying ownership of your wallet" />;
}

function ConnectedWallet() {
    const { wallet, publicKey } = useWallet();

    const pubkey = publicKey?.toString();

    const copy = useCopyAddress();
    const { textDisabled } = useAppPalette();

    return (
        <Column>
            <Text color="caption" variant="body2">
                Wallet to link
            </Text>
            <Row spacing={1}>
                <Image variant="square" size={FONT_SIZES.body1 + "px"} src={wallet?.adapter.icon} />
                <Text isLink onClick={() => copy(pubkey)}>
                    {formatAddress(pubkey)} <ContentCopyOutlined sx={{ color: textDisabled }} />
                </Text>
            </Row>
        </Column>
    );
}
