import { StatRawValue, Column, Text } from "@bridgesplit/ui";

export interface AccessStat {
    value: StatRawValue | [StatRawValue, StatRawValue];
    caption: string;
}
export function AccessStat({ caption, value }: AccessStat) {
    return (
        <Column alignItems={"center"}>
            <Text variant="body2" color="caption">
                {caption}
            </Text>
            {value}
        </Column>
    );
}
