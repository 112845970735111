export enum ColorExtension {
    Teal,
    Purple,
    Prime
}
export type ColorExtensionDetails = { light: string; dark: string };

export const COLOR_EXTENSIONS: Record<ColorExtension, ColorExtensionDetails> = {
    [ColorExtension.Purple]: { light: "#9747FF", dark: "#9747FF" },
    [ColorExtension.Teal]: { light: "#0096B6", dark: "#00D1FF" },
    [ColorExtension.Prime]: { light: "#F5BE53", dark: "#F5BE53" }
};
