import { memo, useMemo } from "react";

import { Column, TagTextAlert, useMemoizedGroupedMap } from "@bridgesplit/ui";
import { percentUiToDecimals } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { useOraclePrices } from "@bridgesplit/abf-react";

import { AppButton, useBalanceChecker } from "../../common";
import { useMarketLend } from "../util";
import { useMarketLendContext } from "./MarketLendContext";
import { useMarketContext } from "../common";

export default memo(function LendCta() {
    const { form, offerPresets } = useMarketLendContext();
    const { principalMint } = useMarketContext();
    const lend = useMarketLend();
    const { insufficientBalance, escrowNeeded } = useBalanceChecker({
        mint: principalMint,
        amount: form.amount
    });

    const offerPresetsById = useMemoizedGroupedMap(offerPresets, (p) => p.strategyTerms.presetStrategyIdentifier);

    // for each strategyIdentifier, add every single matching offer
    const presetToApy = useMemo(() => {
        const presetToApy = new Map<string, number>();

        for (const [strategyIdentifier, apy] of form.presetToApy.entries()) {
            const presets = offerPresetsById?.get(strategyIdentifier);
            if (!presets || !apy) continue;
            for (const preset of presets) {
                const offerId = preset.offerTerms.presetOfferIdentifier;
                presetToApy.set(offerId, percentUiToDecimals(apy));
            }
        }
        return presetToApy;
    }, [form.presetToApy, offerPresetsById]);

    const alertError = useMemo(() => {
        if (insufficientBalance) return "Insufficient balance";

        return undefined;
    }, [insufficientBalance]);

    const ctaError = useMemo(() => {
        if (!form.amount) return "Enter an amount";
        if (!form.collateral.length) return "Select collateral";
        if (!presetToApy.size) return "Enter offer terms";
        return undefined;
    }, [form.amount, form.collateral.length, presetToApy.size]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            {!alertError && <LowAmountWarning />}
            <AppButton
                isTransaction
                asyncCta={{
                    onClickWithResult: () => lend({ form: { ...form, presetToApy }, escrowNeeded }),
                    options: { alertOnError: true }
                }}
                color="secondary"
                disabled={!!ctaError || !!alertError}
            >
                {ctaError ?? `Create ${COPY.STRATEGY_TERM.toLowerCase()}`}
            </AppButton>
        </Column>
    );
});

const MIN_USD_AMOUNT = 250;
function LowAmountWarning() {
    const { principalMint } = useMarketContext();
    const {
        form: { amount }
    } = useMarketLendContext();
    const { getOracle } = useOraclePrices([principalMint]);

    const usdPrice = getOracle(principalMint)?.getUsdAmount(amount);

    if (!usdPrice || usdPrice > MIN_USD_AMOUNT) {
        return null;
    }

    return (
        <TagTextAlert color="warning" icon="tooltip">
            Small deposits are unlikely to be borrowed and may remain idle for extended periods
        </TagTextAlert>
    );
}
