import { OutlinedCard, Row, StatColumn, Text, Link } from "@bridgesplit/ui";
import { BsMetaUtil, LoopscalePointSource, PointSource, RoleView, StrategyDuration } from "@bridgesplit/abf-react";
import { bsMath, formatUsd } from "@bridgesplit/utils";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { RateAndRewardsBreakdown } from "app/components/points";

import { MarketStats } from "./types";
import { BorrowCapWarning, getMarketPath, TokenImage } from "../../common";
import MarketDetailCta from "./MarketDetailCta";

export default function PrincipalMarketCard({
    marketStats,
    selectedDuration,
    view
}: {
    marketStats: MarketStats | undefined;
    selectedDuration: StrategyDuration | undefined;
    view: RoleView;
}) {
    const metadata = marketStats?.metadata;

    return (
        <Link to={getMarketPath({ token: metadata, view: undefined })}>
            <OutlinedCard padding={2} spacing={3}>
                <Row spacing={1}>
                    <TokenImage metadata={metadata} size="lg" />

                    <Text loading={!marketStats} variant="h3" fontWeight="normal">
                        {BsMetaUtil.getSymbolUnique(metadata)}
                    </Text>
                </Row>
                <StatColumn
                    loading={!marketStats || !selectedDuration}
                    stats={[
                        {
                            caption: `${formatDurationWithTypeShorthand(
                                selectedDuration?.duration,
                                selectedDuration?.durationType
                            )} APY`,
                            value: (
                                <RateAndRewardsBreakdown
                                    apy={marketStats?.apy}
                                    pointSources={[PointSource.Loopscale]}
                                    loopscalePointSources={[LoopscalePointSource.Borrow]}
                                    metadata={marketStats?.metadata ? [marketStats.metadata] : undefined}
                                />
                            )
                        },
                        {
                            caption: "Supplied",
                            value: bsMath.add(marketStats?.availableUsd, marketStats?.borrowedUsd),
                            isUsd: true
                        },
                        {
                            caption: "Borrowed",
                            value: marketStats?.borrowCapWarning ? (
                                <BorrowCapWarning borrowCapWarning={marketStats.borrowCapWarning}>
                                    {formatUsd(marketStats?.borrowedUsd)}
                                </BorrowCapWarning>
                            ) : (
                                marketStats?.borrowedUsd
                            ),
                            isUsd: true
                        }
                    ]}
                />
                <MarketDetailCta isMobile view={view} token={metadata} />
            </OutlinedCard>
        </Link>
    );
}
