import { AbfGroupWithRoles } from "./group";
import { AbfRole, AbfRoleNumber } from "./role";

export interface AbfUserWithPermissionsAndWallets {
    user: AbfUser;
    permissions: Record<string, AbfRole[]>;
    wallets: AbfUserWallet[];
    groups: AbfGroupWithRoles[];
    prime_roles: PrimeRole[];
    beta_lend_access: boolean;
    beta_borrow_access: boolean;
}

export enum PrimeRole {
    Default = 0,
    Prime = 1,
    Accredited = 2,
    USBased = 3,
    Admin = 4
}

export enum AccreditedStatus {
    Yes = "Yes",
    No = "No"
}

export interface PrimeRoleStatusParams {
    jurisdiction: string;
    roleIds: PrimeRole[];
}

export interface AbfOnchainRole {
    id: number;
    address: string;
    user: string;
    organizationIdentifier: string;
    role: AbfRoleNumber;
}

export interface AbfUserWalletVerification {
    is_verified: boolean;
    onchain_roles: AbfOnchainRole[];
    offchain_roles: [string, AbfRoleNumber][];
}

export interface AbfUser {
    isAppliedToPrime?: boolean;
    name?: string;
    email: string;
    identifier: string;
    mpcIdentifier?: string;
    avatar?: string;
    bsFunding?: boolean;
}

export interface AbfUserWallet {
    groupIdentifier: string;
    userIdentifier: string;
    wallet: string;
    mpcIdentifier?: string;
}

export enum RoleView {
    Borrow = "Borrow",
    Lend = "Lend"
}

export interface UserGroupViewReturn {
    groupIdentifier: string;
    userIdentifier: string;
    view: RoleView;
}

export interface PrimeApplicationData {
    id: number;
    userIdentifier: string;
    jurisdiction: string;
    appliedDate: number;
    approvedDate: number;
}
