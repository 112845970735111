import {
    getDurationDetails,
    getInitSyndicatedOrderTransaction,
    getCreateOrderTransaction,
    AbfOrder,
    getFillOrderEndpoint,
    getCancelOrderTransaction
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { uiPercentToBps, uiAmountToLamports, DEFAULT_PUBLIC_KEY, Result, generateNonce } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { OrderParams, AbfTransactionDetails, AbfGeneratorResult } from "../types";
import { useDecimalsByMint } from "../utils";
import { useAbfGenerateTransaction } from "./common";

export function useCreateOrderTransaction(): AbfTransactionDetails<OrderParams> {
    const generate = useAbfGenerateTransaction();

    const { resetOrderApi, resetEscrowedApi, resetLoanRequests, resetSyndicatedApi } = useAbfFetches();
    const { fetchDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams(params: OrderParams): AbfGeneratorResult {
        try {
            const durationDetails = getDurationDetails(params.durationType);

            const decimals = await fetchDecimals(params.principalMint);
            const generateFunction = params.syndicated ? getInitSyndicatedOrderTransaction : getCreateOrderTransaction;
            const nonce = generateNonce();

            const createOrder = generate({
                generateFunction,
                identifier: "Make Offer",
                params: {
                    ...params,
                    makerRole: params.lend,
                    apy: uiPercentToBps(params.apy),
                    duration: params.duration,
                    durationType: durationDetails.paramUnit,
                    principalAmount: uiAmountToLamports(params.principalAmount, decimals),
                    designatedTakerEscrowNonce: params.designatedTakerEscrowNonce ?? DEFAULT_PUBLIC_KEY,
                    makerEscrow: params.makerEscrow,
                    nonce,
                    feeSchedules: params.feeSchedules,
                    allowEarlyRepayments: params.allowEarlyRepayments ?? false,
                    compoundingFrequency: params.compoundingFrequency
                }
            });

            const depositAndPlace = await combineTransactionPromises([createOrder], { order: "sequential" });

            return depositAndPlace;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanRequests();
            resetOrderApi();
            resetSyndicatedApi();
            resetEscrowedApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Sending your Offer" };
}

type FillOrderParams = AbfOrder & { loanRequestNft: string; fillerEscrow: string };
export function useFillOrderTransaction(): AbfTransactionDetails<FillOrderParams> {
    const generate = useAbfGenerateTransaction();

    const { resetLoanApi, resetOrderApi, resetLedgerAccounts, resetLoanRequests } = useAbfFetches();

    async function getTransactionsWithParams(order: FillOrderParams): AbfGeneratorResult {
        try {
            const fill = generate({
                generateFunction: getFillOrderEndpoint,
                identifier: "Start Loan",
                params: {
                    loan_request_nft: order.loanRequestNft,
                    order: order?.address,
                    order_maker: order?.maker,
                    collateral_mint: order?.collateralMint,
                    principal_mint: order?.principalMint,
                    maker_role: order?.makerRole,
                    filler_escrow: order.fillerEscrow,
                    use_filler_escrow: true,
                    self_generate_lut: true
                }
            });

            const transactions = await combineTransactionPromises([fill], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLedgerAccounts();
            resetLoanApi();
            resetOrderApi();
            resetLoanRequests();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Starting Loan" };
}

type CancelOrderParams = AbfOrder;
export function useCancelOrderTransaction(): AbfTransactionDetails<CancelOrderParams> {
    const generate = useAbfGenerateTransaction();

    const { resetOrderApi, resetLoanRequests } = useAbfFetches();

    async function getTransactionsWithParams(order: CancelOrderParams): AbfGeneratorResult {
        try {
            const mappedParams = {
                order: order?.address,
                maker_role: order?.makerRole ?? 2 // to cause error if not 0 or 1
            };
            const cancel = generate({
                generateFunction: getCancelOrderTransaction,
                identifier: "Cancel Offer",
                params: mappedParams
            });
            const transactions = await combineTransactionPromises([cancel], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetOrderApi();
            resetLoanRequests();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Cancelling Offer" };
}
