import { useMemo } from "react";

import {
    getFrequencyFromRruleSet,
    getPaymentScheduleFromDates,
    getTimeOffsetsFromRRuleSet,
    getOfferDuration,
    AbfOrderExpanded,
    BsMetaUtil,
    LoanRequestExpanded,
    useActiveGroup
} from "@bridgesplit/abf-react";
import {
    AbfOrderFundingType,
    BsMetadata,
    FeeRuleType,
    FeeType,
    LoanRequest,
    PrepaymentStatus,
    getDurationDetails
} from "@bridgesplit/abf-sdk";
import {
    Button,
    Column,
    OutlinedCard,
    Row,
    SPACING,
    TableAssetDisplay,
    TableColumn,
    TableTimeDisplay,
    TagText,
    Text,
    Tooltip,
    TooltipText,
    VerticalScrollContainer
} from "@bridgesplit/ui";
import { formatDate, formatNum, formatPercent, getUnixTs } from "@bridgesplit/utils";
import { RRuleSet } from "rrule";
import { MAKE_OFFER_SLUG } from "app/constants";
import { useAppDialog, AppDialog } from "app/utils";

import { getLoanRequestPath } from "./requests";

export const commonOfferColumns: TableColumn<AbfOrderExpanded>[] = [
    {
        dataIndex: "principal",
        title: "Principal",
        width: "20%",
        align: "right",
        render: ({ data }) => {
            const insufficientFunds = !data.isPrincipalFunded;
            return (
                <TooltipText
                    helpText={
                        insufficientFunds
                            ? "This offer is invalid due to insufficient funds and is not visible to the borrower"
                            : undefined
                    }
                    color={insufficientFunds ? "error" : "body"}
                >
                    {BsMetaUtil.formatAmount(data.principalMetadata, data.order.principalAmount)}
                </TooltipText>
            );
        },
        sortBy: ({ data }) => data.order.principalAmount
    }
];

export function getOfferLenderName({ order, orderMaker }: AbfOrderExpanded) {
    return order.fundingType === AbfOrderFundingType.Syndicated ? "Multiple Lenders" : orderMaker.groupName;
}

export const offerColumns: TableColumn<AbfOrderExpanded>[] = [
    {
        dataIndex: "createdAt",
        title: "Offer",
        width: "15%",
        render: ({ data }) => (
            <TableAssetDisplay
                showImage={false}
                name={getOfferLenderName(data)}
                description={formatDate(data.order.createdAt, "relative")}
            />
        ),
        sortBy: ({ data }) => data.order.createdAt
    },
    {
        dataIndex: "loanTerm",
        title: "Loan Term",
        width: "10%",
        render: ({ data }) => <Text> {getOfferDuration(data.orderSchedule)} </Text>,
        sortBy: ({ data }) => getOfferDuration(data.orderSchedule)
    },
    ...commonOfferColumns,
    {
        dataIndex: "apy",
        title: "APY",
        width: "10%",
        align: "right",
        render: ({ data }) => <Text>{formatPercent(data.orderSchedule.apy)}</Text>,
        sortBy: ({ data }) => data.orderSchedule.apy
    }
];

export const offerColumnsWithAssets: TableColumn<AbfOrderExpanded>[] = [
    {
        dataIndex: "mint",
        title: "Loan",
        width: "20%",
        render: ({ data }) => (
            <TableAssetDisplay
                unreadWithTooltip={
                    data.loanRequest.archived ? "This offer was made to a cancelled funding request" : undefined
                }
                name={data.loanRequest.name}
                showImage={false}
            />
        ),
        sortBy: ({ data }) => data.loanRequest.name
    },
    {
        dataIndex: "createdAt",
        title: "Placed",
        width: "20%",
        render: ({ data }) => <TableTimeDisplay time={data.order.createdAt} includeDate={false} />,
        sortBy: ({ data }) => data.order.createdAt
    },

    ...commonOfferColumns
];

const ROW_HEIGHT = 40;
const SCROLL_HEIGHT = 200;
export function OfferSchedule({
    rruleSet,
    apy = 0,
    principal = 0,
    principalMetadata
}: {
    apy: number | undefined;
    principal: number | undefined;
    rruleSet: RRuleSet;
    principalMetadata: BsMetadata | null | undefined;
}) {
    const freqUnit = useMemo(() => getFrequencyFromRruleSet(rruleSet), [rruleSet]);
    const freq = getDurationDetails(freqUnit);
    const { schedule } = useMemo(() => {
        const timeOffsets = getTimeOffsetsFromRRuleSet(rruleSet);

        return getPaymentScheduleFromDates({
            timeOffsets,
            apy: apy,
            principal: principal
        });
    }, [apy, principal, rruleSet]);

    return (
        <VerticalScrollContainer
            maxHeight={SCROLL_HEIGHT}
            isOverflowing={schedule.length * (ROW_HEIGHT + SPACING) > SCROLL_HEIGHT}
            spacing={1}
        >
            {schedule.map((d, i) => (
                <Row sx={{ alignItems: "flex-start" }} spaceBetween key={i}>
                    <Column>
                        <Text>
                            {(() => {
                                if (schedule.length === 1 || i === schedule.length - 1) return "Maturity";

                                return `${freq.unit} ${i + 1}`;
                            })()}
                        </Text>
                        <Text variant="body2" color="caption">
                            {formatDate(d.timeOffset + getUnixTs(), "date")}
                        </Text>
                    </Column>

                    <Column alignItems="flex-end">
                        <Text>{BsMetaUtil.formatAmount(principalMetadata, d.amount)}</Text>
                        <Text variant="body2" color="caption">
                            {formatNum(d.totalAmount)} total
                        </Text>
                    </Column>
                </Row>
            ))}
        </VerticalScrollContainer>
    );
}

export function OfferScheduleRow({
    rruleSet,
    apy = 0,
    principal = 0,
    principalMetadata
}: {
    apy: number | undefined;
    principal: number | undefined;
    rruleSet: RRuleSet;
    principalMetadata: BsMetadata | null | undefined;
}) {
    const { schedule } = useMemo(() => {
        const timeOffsets = getTimeOffsetsFromRRuleSet(rruleSet);

        return getPaymentScheduleFromDates({ timeOffsets, apy, principal });
    }, [apy, principal, rruleSet]);

    return (
        <Row sx={{ overflowX: "auto", mx: -2, px: 2 }} alignItems="stretch" spacing={1}>
            {schedule.map((d, i) => (
                <OutlinedCard sx={{ width: "100%", minWidth: "max-content" }} key={i}>
                    <Text variant="body2" color="caption">
                        {formatDate(d.timeOffset + getUnixTs(), "date")} {i === schedule.length - 1 ? "(Maturity)" : ""}
                    </Text>
                    <Row spacing={0.5}>
                        <Text variant="h4">
                            {BsMetaUtil.formatAmount(principalMetadata, d.amount, { hideSymbol: true })}
                        </Text>
                        <Text color="caption">{BsMetaUtil.getSymbol(principalMetadata)}</Text>
                    </Row>
                </OutlinedCard>
            ))}
        </Row>
    );
}

export function OfferTableExpandCta({
    data,
    request
}: {
    data: AbfOrderExpanded;
    request: LoanRequestExpanded | undefined;
}) {
    const { open } = useAppDialog();
    const { groupIdentifier } = useActiveGroup();

    const onClick = () => request && open(AppDialog.OfferDetails, { order: data, request });
    if (data.requestMaker.groupIdentifier === groupIdentifier) {
        return (
            <Button onClick={onClick} variant="contained">
                Accept
            </Button>
        );
    }

    if (data.orderMaker.groupIdentifier === groupIdentifier) {
        return (
            <Button onClick={onClick} color="error" variant="outlined">
                Cancel
            </Button>
        );
    }

    return (
        <Button onClick={onClick} variant="outlined">
            Details
        </Button>
    );
}

export enum OrderStatus {
    Closed,
    Escrowed,
    ActiveLoan,
    RepaidLoan,
    DefaultedLoan,
    LedgerCreated
}

export function OrderStatusTag({ status }: { status: OrderStatus | undefined }) {
    if (status === undefined) {
        return <TagText> ... </TagText>;
    }

    switch (status) {
        case OrderStatus.Closed:
            return <TagText color="body"> Not deposited </TagText>;
        case OrderStatus.Escrowed:
            return <TagText color="info"> Open to offers </TagText>;
        case OrderStatus.ActiveLoan:
            return <TagText color="success"> Active loan </TagText>;
        case OrderStatus.RepaidLoan:
            return <TagText color="success"> Loan complete </TagText>;
        case OrderStatus.DefaultedLoan:
            return <TagText color="error"> Defaulted loan </TagText>;
        case OrderStatus.LedgerCreated:
            return (
                <Tooltip title="Loan finalization in progress">
                    <TagText color="error"> Pending loan </TagText>
                </Tooltip>
            );
        default:
            return null;
    }
}

export const getPathFromOffer = (order: AbfOrderExpanded | undefined) => {
    return getLoanRequestPath(order?.loanRequest);
};

export const getMakeOfferPath = (request: LoanRequest | undefined) =>
    request?.lockboxNftMint ? `${MAKE_OFFER_SLUG}/${request?.nonce}` : MAKE_OFFER_SLUG;

export function formatMaxOutstandingPaymentsTooltip(maxOutstandingPayments: number | undefined) {
    if (!maxOutstandingPayments) {
        return "Missing any payments will result in a default, allowing the lender to seize all collateral";
    }
    return `More than ${maxOutstandingPayments} simultaneous outstanding payments will result in a default, allowing the lender to seize all collateral`;
}

export function formatMaxOutstandingPayments(maxOutstandingPayments: number | undefined) {
    if (!maxOutstandingPayments) return "Any missed payment";
    return `${maxOutstandingPayments}+ outstanding payments`;
}

export function formatPrepaymentPenalty(feeAmount: number | undefined) {
    if (!feeAmount) return "No Fee";
    return formatPercent(feeAmount);
}

export function getPrepaymentStatusFromExpandedOrder(order: AbfOrderExpanded | undefined) {
    if (!order) {
        return undefined;
    }
    if (order.order.allowEarlyRepayments) {
        if (order.feeSchedule.earlyFees.length > 0) {
            return PrepaymentStatus.WithPenalty;
        } else {
            return PrepaymentStatus.NoPenalty;
        }
    } else {
        return PrepaymentStatus.NotAllowed;
    }
}
export function getPrepaymentsStatusText(prepaymentStatus: PrepaymentStatus) {
    if (prepaymentStatus === PrepaymentStatus.NoPenalty) {
        return "No Penalty";
    } else if (prepaymentStatus === PrepaymentStatus.WithPenalty) {
        return "With Penalty";
    } else {
        return "Prohibited";
    }
}

export function getPrepaymentsStatusTooltip(prepaymentStatus: PrepaymentStatus) {
    if (prepaymentStatus === PrepaymentStatus.NoPenalty) {
        return "There is no penalty for making early repayments";
    } else if (prepaymentStatus === PrepaymentStatus.WithPenalty) {
        return undefined;
    } else {
        return "Early payments are not allowed";
    }
}

export function formatFeeRuleType(ruleType: FeeRuleType) {
    switch (ruleType) {
        case FeeRuleType.InterestOnly:
            return "Interest";
        case FeeRuleType.PrincipalOnly:
            return "Principal";
        case FeeRuleType.InterestAndPrincipal:
            return "Maturity";
        default:
            return "";
    }
}

export function formatFeeType(type: FeeType) {
    switch (type) {
        case FeeType.LateRepayment:
            return "Late payment";
        case FeeType.EarlyRepayment:
            return "Prepayment";
        default:
            return "";
    }
}
