import { StatColumn } from "@bridgesplit/ui";

import { useOpenPositionLtv } from "./util";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useHealthChangeStats } from "../common";

export default function OpenPositionStats() {
    const { openPositionForm } = useWhirlpoolContext();
    const openPositionLtv = useOpenPositionLtv();
    const stats = useHealthChangeStats(openPositionLtv);

    if (
        !openPositionForm.tickLower ||
        !openPositionForm.tickUpper ||
        !openPositionForm.tokenAAmount ||
        !openPositionForm.tokenBAmount ||
        openPositionForm.status === undefined
    )
        return null;

    return <StatColumn loading={openPositionLtv.isLoading} stats={stats} />;
}
