import {
    DEFAULT_PUBLIC_KEY,
    bsMath,
    calculatePercentChange,
    findMaxElement,
    getUnixTs,
    isBeforeNow,
    isPastNow,
    roundToDecimals
} from "@bridgesplit/utils";
import {
    RepaymentType,
    CompoundingFrequency,
    EarlyFeeSchedule,
    getDurationInSeconds,
    AbfLedgerAccount,
    AbfLoanVault,
    AbfOrderFundingType
} from "@bridgesplit/abf-sdk";

import { AbfLoanExpanded, LoanDebt, LoanStatus } from "../types";
import { getEscrowAccountForGroup } from "./pda";
import {
    calculateInterestSavedIfPaidEarly,
    isRepaymentInWindow,
    summarizeFeeAccounts,
    summarizeLedgerAccounts
} from "./order";
import { useEscrowPreference } from "../api";

export function calculateHealthRatio(
    totalOwedValue: number | undefined,
    loanCollateralValue: number | undefined,
    liquidationThreshold: number | undefined
): number {
    if (!totalOwedValue || !loanCollateralValue || !liquidationThreshold) return 0;
    const loanRatio = totalOwedValue / loanCollateralValue;

    return calculateHealthRatioFromLtv(loanRatio, liquidationThreshold);
}

export function calculateHealthRatioFromLtv(ltv: number | undefined, liquidationThreshold: number | undefined): number {
    if (!ltv || !liquidationThreshold) return 0;

    const health = ltv * (1 / liquidationThreshold);
    const healthRatio = Math.max(1 - health, 0);
    return healthRatio;
}

export function useIsLoanEscrowBased() {
    const { escrowNeeded } = useEscrowPreference();

    return function (loanExpanded: AbfLoanExpanded | undefined) {
        if (escrowNeeded) return true;
        return getIsLoanEscrowBased(loanExpanded);
    };
}

export function getIsLoanEscrowBased(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;
    const defaultLenderEscrow = getEscrowAccountForGroup(loanExpanded.lender.groupIdentifier, DEFAULT_PUBLIC_KEY);
    return defaultLenderEscrow === loanExpanded.loan.lender;
}

export function calculateLiquidationPrice(
    totalPrincipalAmount: number | undefined,
    loanCollateralAmount: number | undefined,
    liquidationThreshold: number | undefined
): number {
    const collateralizationRatio = bsMath.div(loanCollateralAmount, totalPrincipalAmount);
    const liquidationPrice = bsMath.mul(liquidationThreshold, collateralizationRatio) ?? 0;
    return 1 / liquidationPrice;
}

export function isLoanZeroCoupon(loanExpanded: AbfLoanExpanded | undefined): boolean {
    return loanExpanded?.ledgerAccounts.length === 1;
}

export function isLoanRefinanced(loanExpanded: AbfLoanExpanded | undefined): boolean {
    return !!loanExpanded?.loan.refinancedFrom || !!loanExpanded?.loan.refinancedTo;
}

export function isSimpleLoan(loanExpanded: AbfLoanExpanded | undefined): boolean {
    if (!loanExpanded) return false;
    return !getIsLoanEscrowBased(loanExpanded) && isLoanZeroCoupon(loanExpanded);
}

const SELL_LOAN_START_TIME = 1725051600; // 08/30/2024 @ 5pm
export function canLenderSellLoan(loanExpanded: AbfLoanExpanded | undefined): boolean {
    if (!loanExpanded) return false;
    // cannot sell loan if in refinance grace period
    if ([LoanStatus.RefinanceGrace, LoanStatus.DefaultRisk].includes(loanExpanded.status)) return false;
    return isSimpleLoan(loanExpanded) && loanExpanded.loan.loanStartTime > SELL_LOAN_START_TIME;
}

export function isLoopLoan(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.order.fundingType === AbfOrderFundingType.FlashLoan;
}

export function isLoanSimpleFeeSchedule(loanExpanded: AbfLoanExpanded | undefined): boolean {
    return (
        !loanExpanded?.order.maxOutstandingPayments &&
        !loanExpanded?.feeSchedule.earlyFees.length &&
        !loanExpanded?.feeSchedule.lateFees.length
    );
}

export function isLoanOracleBased(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return false;

    return (
        loanExpanded.principalUsdPrice &&
        loanExpanded.order.liquidationThreshold < 10_000 &&
        loanExpanded.collateral.every((c) => c.usdPrice)
    );
}

export function getNextPaymentDate(loan: AbfLoanExpanded | undefined): number | undefined {
    if (!loan) {
        return undefined;
    }
    const firstNotFilledElement = loan.ledgerAccounts.find((el) => !el.isFilled);
    if (!firstNotFilledElement) return undefined;
    return firstNotFilledElement.dueTimeOffset + loan.loan.loanStartTime;
}

export function calculateLoanLiquidationPrice(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded || loanExpanded.collateral.length !== 1 || !isLoanOracleBased(loanExpanded)) return undefined;

    const collateral = loanExpanded.collateral[0];
    const totalOutstanding = summarizeLedgerAccounts(loanExpanded).totalOutstanding;

    const liquidationPrice = calculateLiquidationPrice(
        totalOutstanding,
        collateral.amount,
        loanExpanded.order.liquidationThreshold
    );

    const currentPrice = bsMath.div(collateral.usdPrice ?? undefined, loanExpanded.principalUsdPrice);
    const percentChange = calculatePercentChange(currentPrice, liquidationPrice);

    return { price: liquidationPrice, collateral, percentChange };
}

export type LoanInterestDueInfo = {
    newTotalInterestOutstanding: number;
    principalToPay: number;
    interestToPay: number;
    feesToPay: number;
    newFeeAmount: number;
    newTotalInterestDue: number;
    isInterestRecalculated: boolean;
    repaymentAmount: number;
};

export type LoanInterestDueParams = {
    loanExpanded: AbfLoanExpanded;
    repaymentAmount: number;
    ledgerAccounts: AbfLedgerAccount[];
    isPrincipalRepay?: boolean;
};

export function getLoanInterestDue(params: LoanInterestDueParams): LoanInterestDueInfo {
    const { loanExpanded, repaymentAmount, ledgerAccounts } = params;
    const currentLedgers = summarizeLedgerAccounts(loanExpanded, ledgerAccounts);
    const allLedgers = summarizeLedgerAccounts(loanExpanded);

    const isRepayingPrincipal = !!currentLedgers.principalOutstanding;

    const principalToPay = isRepayingPrincipal ? Math.min(currentLedgers.principalOutstanding, repaymentAmount) : 0;
    let interestToPay = Math.min(currentLedgers.interestOutstanding, repaymentAmount - principalToPay);
    let feesToPay = Math.max(repaymentAmount - principalToPay - interestToPay, 0);

    if (!isRecalculateInterestNeeded(params)) {
        return {
            feesToPay,
            newFeeAmount: 0,
            principalToPay,
            interestToPay,
            isInterestRecalculated: false,
            newTotalInterestOutstanding: allLedgers.interestOutstanding,
            newTotalInterestDue: allLedgers.interestDue,
            repaymentAmount
        };
    }

    let newTotalInterestDue = allLedgers.interestDue;
    const interestPaid = allLedgers.interestPaid;
    let newTotalInterestOutstanding = allLedgers.interestOutstanding;
    if (principalToPay) {
        const interestSavedFromPrincipal = calculateInterestSavedIfPaidEarly({
            loanExpanded,
            repaymentAmount: principalToPay,
            repaymentType: RepaymentType.Principal
        });
        newTotalInterestDue = newTotalInterestDue - interestSavedFromPrincipal;

        newTotalInterestDue = roundToDecimals(newTotalInterestDue, loanExpanded.principalMetadata.decimals);
        newTotalInterestOutstanding = newTotalInterestDue - interestPaid;
    }

    if (interestToPay) {
        const interestSavedFromInterest = calculateInterestSavedIfPaidEarly({
            loanExpanded,
            repaymentAmount: interestToPay,
            repaymentType: RepaymentType.Interest,
            interestOutstanding: newTotalInterestOutstanding
        });

        newTotalInterestDue = newTotalInterestDue - interestSavedFromInterest;

        newTotalInterestDue = roundToDecimals(newTotalInterestDue, loanExpanded.principalMetadata.decimals);
    }

    const ledgersInterestOutstandingBeforeInterestRepay = Math.max(newTotalInterestDue - interestPaid, 0);

    interestToPay = Math.min(interestToPay, ledgersInterestOutstandingBeforeInterestRepay);

    newTotalInterestOutstanding = newTotalInterestOutstanding - interestToPay;

    const earlyFeeSchedule = findClosestEarlyFee(loanExpanded);
    let newFeeAmount = earlyFeeSchedule
        ? earlyFeeSchedule.feeSchedule.feeAmount * (allLedgers.interestDue - newTotalInterestDue)
        : 0;
    newFeeAmount = roundToDecimals(newFeeAmount, loanExpanded.principalMetadata.decimals);

    feesToPay = Math.max(repaymentAmount - principalToPay - interestToPay, 0);

    return {
        repaymentAmount,
        feesToPay,
        newTotalInterestOutstanding,
        principalToPay,
        interestToPay,
        isInterestRecalculated: true,
        newTotalInterestDue,
        newFeeAmount
    };
}

const getDueTime = (loan: AbfLoanVault, ledgerAccount: AbfLedgerAccount) =>
    loan.loanStartTime + ledgerAccount.dueTimeOffset;

const getDueTimeWithGracePeriod = (loan: AbfLoanVault, ledgerAccount: AbfLedgerAccount) =>
    getDueTime(loan, ledgerAccount) + (ledgerAccount.gracePeriod ?? 0);

function isRecalculateInterestNeeded(params: LoanInterestDueParams) {
    const {
        loanExpanded: { order, loan, orderSchedule, ledgerAccounts },
        repaymentAmount
    } = params;

    if (!order.allowEarlyRepayments) return false;

    const finalLedgerAccount = ledgerAccounts[ledgerAccounts.length - 1];
    const nextLedgerAccount = ledgerAccounts.filter((l) => !l.isFilled)[0];

    if (!nextLedgerAccount) return false;

    // if principal is being paid in window
    if (isRepaymentInWindow(finalLedgerAccount, order.finalPaymentTimeOffset + loan.loanStartTime)) return false;

    // if loan is being paid late
    if (isPastNow(getDueTime(loan, finalLedgerAccount))) return false;

    const isMultipleRepay = params.ledgerAccounts.length > 1;
    // either early in time or overpaying principal
    const principalOutstanding = nextLedgerAccount.principalDue - nextLedgerAccount.principalPaid;
    const isEarly =
        isBeforeNow(getDueTime(loan, nextLedgerAccount)) || (isMultipleRepay && repaymentAmount > principalOutstanding);

    if (!isEarly) return false;

    return (
        params.isPrincipalRepay ||
        orderSchedule.compoundingFrequency !== CompoundingFrequency.Simple ||
        !!nextLedgerAccount.principalDue
    );
}

export function findUnfilledEmptyLedgers(loanExpanded: AbfLoanExpanded | undefined) {
    return loanExpanded?.ledgerAccounts.filter((l) => {
        if (l.isFilled) return false;
        const principalOutstanding = Math.max(l.principalDue - l.principalPaid, 0);
        const interestOutstanding = Math.max(l.interestDue - l.interestPaid, 0);
        return !principalOutstanding && !interestOutstanding;
    });
}

export function findClosestEarlyFee(loanExpanded: AbfLoanExpanded): EarlyFeeSchedule | undefined {
    const now = getUnixTs();
    const earlyPaymentSchedules = loanExpanded.feeSchedule?.earlyFees;
    const loanStartTime = loanExpanded.loan.loanStartTime;

    earlyPaymentSchedules?.sort((a, b) => {
        return (
            getDurationInSeconds(a.feeMetadata.duration, a.feeMetadata.durationType) -
            getDurationInSeconds(b.feeMetadata.duration, b.feeMetadata.durationType)
        );
    });

    const validFeeScheds = earlyPaymentSchedules?.filter(
        (sched) =>
            getDurationInSeconds(sched.feeMetadata.duration, sched.feeMetadata.durationType) + loanStartTime < now
    );

    if (!validFeeScheds?.length) {
        return earlyPaymentSchedules?.[0]; // return first amount if none are valid (repayment is before first specification)
    }

    return validFeeScheds?.[0];
}

export function getLoanTotalDebt(loanExpanded: AbfLoanExpanded, customRepaymentAmount?: number): LoanDebt {
    if (!isSimpleLoan(loanExpanded)) {
        const originalLedgers = summarizeLedgerAccounts(loanExpanded);

        return {
            total: originalLedgers.totalOutstanding,
            earlyFee: originalLedgers.feesOutstanding,
            interestAccrued: originalLedgers.interestPaid
        };
    }

    const originalLedgers = summarizeLedgerAccounts(loanExpanded);
    const repaymentAmount = customRepaymentAmount ?? originalLedgers.totalOutstanding;
    const info = getLoanInterestDue({
        ledgerAccounts: loanExpanded.ledgerAccounts,
        loanExpanded,
        repaymentAmount
    });

    const summary = summarizeChangesFromNewInterest(loanExpanded, info);

    const interestAccrued = (() => {
        const areAllPaymentMade = loanExpanded.ledgerAccounts.every((l) => l.isFilled);

        //  calculate the total borrower will make by end of loan
        const interestDue = areAllPaymentMade
            ? originalLedgers.interestDue // if ledgers are all paid, just use sum ledgers payments
            : info.newTotalInterestDue; // otherwise, use accrued interest based on the borrower paying rn

        // for loans that are never sold, the lender funded the principal and receives all repayments
        if (!loanExpanded.saleEvents.length) return interestDue;

        const salesDesc = loanExpanded.saleEvents.sort((a, b) => b.timestamp - a.timestamp);
        const lastEvent = salesDesc[0];

        // if user bought loan, don't include interest accrued before they received it
        if (lastEvent.isUserBuyer) return Math.max(interestDue - lastEvent.interestPaid, 0);

        if (lastEvent.isUserSeller) {
            const buyEvent = salesDesc.find((s) => s.isUserBuyer);
            const sellEvent = lastEvent;

            // if user never bought this loan, then they received its entire interest
            if (!buyEvent) return sellEvent.interestPaid;

            // if user bought this loan, it could have been partially paid
            const interestPaidDiff = sellEvent.interestPaid - buyEvent.interestPaid;

            // if user made proceeds from selling this loan due to favorable APY
            const sellProceeds = Math.max(sellEvent.loanSaleAmount - buyEvent.loanSaleAmount, 0);
            return interestPaidDiff + sellProceeds;
        }

        return 0;
    })();

    return {
        total: summary.totalToPay,
        earlyFee: summary.earlyFees[1],
        interestAccrued
    };
}

export function getNextLedgerAccount(loanExpanded: AbfLoanExpanded | undefined) {
    const nextLedgerAccount = loanExpanded?.ledgerAccounts.find((l) => !l.isFilled);
    return { nextLedgerAccount };
}

type BeforeAfter = [number, number];
type SummarizedChanges = {
    outstanding: BeforeAfter;
    totalToPay: number;
    lateFee: number;
    earlyFees: BeforeAfter;
    interestSaved: number;
    dueDate: number;
};

export function summarizeChangesFromNewInterest(
    loanExpanded: AbfLoanExpanded,
    paymentInfo: LoanInterestDueInfo
): SummarizedChanges {
    const originalLedger = summarizeLedgerAccounts(loanExpanded);

    const originalFees = summarizeFeeAccounts(loanExpanded.feeAccounts);
    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);

    const { newTotalInterestOutstanding, principalToPay, interestToPay, feesToPay, newTotalInterestDue, newFeeAmount } =
        paymentInfo;

    const interestSaved = Math.max(originalLedger.interestDue - newTotalInterestDue, 0);

    const newTotalFeeAmount = newFeeAmount + originalFees.feesOutstanding;

    let totalToPay = principalToPay + interestToPay;
    let oldOutstanding = originalLedger.interestOutstanding + originalLedger.principalOutstanding;
    const newOutstanding =
        Math.max(originalLedger.principalOutstanding - principalToPay) +
        Math.max(newTotalInterestOutstanding - interestToPay, 0) +
        Math.max(newTotalFeeAmount - feesToPay, 0);

    let lateFee = 0;
    let oldEarlyFee = 0;
    let newEarlyFee = 0;

    // simple loans pay fees up front
    if (isSimpleLoan(loanExpanded)) {
        oldOutstanding += originalFees.feesOutstanding;
        totalToPay += newTotalFeeAmount;
    } else {
        // non simple loans should display fees separately
        oldEarlyFee = originalFees.feesOutstanding;
        newEarlyFee = newTotalFeeAmount;
        if (nextLedgerAccount) {
            lateFee = calculateAmountLateFee(loanExpanded.loan, nextLedgerAccount);
        }
    }

    return {
        totalToPay: roundToDecimals(totalToPay, loanExpanded.principalMetadata.decimals),
        outstanding: [oldOutstanding, newOutstanding],
        earlyFees: [oldEarlyFee, newEarlyFee],
        interestSaved,
        lateFee,
        dueDate: (nextLedgerAccount?.dueTimeOffset ?? 0) + loanExpanded.loan.loanStartTime
    };
}

export function calculateAmountLateFee(loan: AbfLoanVault, ledgerAccount: AbfLedgerAccount) {
    if (!isPastNow(getDueTimeWithGracePeriod(loan, ledgerAccount))) {
        return 0;
    }
    const fee =
        (ledgerAccount.latePaymentFee || 0) *
        (ledgerAccount.principalDue -
            ledgerAccount.principalPaid +
            (ledgerAccount.interestDue - ledgerAccount.interestPaid));
    return Math.max(fee, 0);
}

export function findMostRecentSellerEvent(loanExpanded: AbfLoanExpanded | undefined) {
    return findMaxElement(
        loanExpanded?.saleEvents.filter((e) => e.isUserSeller),
        ({ timestamp }) => timestamp
    );
}
export function findMostRecentBuyerEvent(loanExpanded: AbfLoanExpanded | undefined) {
    return findMaxElement(
        loanExpanded?.saleEvents.filter((e) => e.isUserBuyer),
        ({ timestamp }) => timestamp
    );
}

export function getTotalRepaidForCurrentLender(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return 0;
    const originalLedgers = summarizeLedgerAccounts(loanExpanded);
    const buyEvent = loanExpanded.saleEvents.sort((a, b) => b.timestamp - a.timestamp).find((c) => c.isUserBuyer);

    const totalRepaidInPast = bsMath.add(buyEvent?.principalPaid, buyEvent?.interestPaid, buyEvent?.feePaid) ?? 0;
    const totalRepaidTotal = originalLedgers.totalPaid;
    const totalRepaidForCurrentLender = totalRepaidTotal - totalRepaidInPast;
    return totalRepaidForCurrentLender;
}

export function getTotalLenderSaleProceeds(loanExpanded: AbfLoanExpanded | undefined) {
    if (!loanExpanded) return { proceeds: 0, mostRecentSell: undefined };
    const mostRecentSell = findMostRecentSellerEvent(loanExpanded);
    const totalRepaidForCurrentLender = getTotalRepaidForCurrentLender(loanExpanded);

    const proceeds = bsMath.add(mostRecentSell?.loanSaleAmount, totalRepaidForCurrentLender) ?? 0;
    return { proceeds, mostRecentSell };
}
