import React from "react";

import { TrackEventFunction } from "./types";

// Define the type for the tracking function
type ContextState = { trackEvent: TrackEventFunction };

// Create the context with a default value
const LoopscaleTrackingContext = React.createContext<ContextState | null>(null);

// Create a provider component
function LoopscaleTrackingProvider({
    children,
    trackEvent
}: {
    children: React.ReactNode;
    trackEvent: TrackEventFunction;
}) {
    return <LoopscaleTrackingContext.Provider value={{ trackEvent }}>{children}</LoopscaleTrackingContext.Provider>;
}

// Create a custom hook to use the context
function useLoopscaleTrackingContext() {
    const context = React.useContext(LoopscaleTrackingContext);

    return context;
}

export { LoopscaleTrackingProvider, useLoopscaleTrackingContext };
