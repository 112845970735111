import { Button, Icon, Link, Row, Text, useAppPalette } from "@bridgesplit/ui";
import { formatNum } from "@bridgesplit/utils";
import { UserPointsFunction, useAuth, useCurrentTime, useUserPoints } from "@bridgesplit/abf-react";
import { REWARDS_SLUG } from "app/constants";

import { NAV_BUTTON_HEIGHT } from "./constants";

export function NavPoints() {
    const { getPointsAtTime, isLoading } = useUserPoints();
    const { textPrimary, secondary } = useAppPalette();

    const { isAuthenticated } = useAuth();

    if (!isAuthenticated || isLoading) return null;

    return (
        <Link to={REWARDS_SLUG}>
            <Button
                height={NAV_BUTTON_HEIGHT}
                textProps={{ sx: { color: textPrimary, svg: { color: secondary } } }}
                color="secondary"
                variant="outlined"
            >
                <Icon type="points" />
                <Points getPoints={getPointsAtTime} />
            </Button>
        </Link>
    );
}

export function PointsNested() {
    const { getPointsAtTime, isLoading } = useUserPoints();
    const { hoverBackground } = useAppPalette();

    const { isAuthenticated } = useAuth();

    if (!isAuthenticated || isLoading) return null;

    return (
        <Link
            sx={{
                width: "100%",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: hoverBackground
                }
            }}
            to={REWARDS_SLUG}
        >
            <Row tabIndex={0} spacing={1} sx={{ flexGrow: 1, px: 2, py: 1 }}>
                <Text color="caption">Rewards</Text>
                <Text color="secondary">
                    <Icon type="points" /> <Points getPoints={getPointsAtTime} />
                </Text>
            </Row>
        </Link>
    );
}

export function Points({ getPoints }: { getPoints: UserPointsFunction }) {
    const { now } = useCurrentTime();
    const data = getPoints(now);

    return <> {formatNum(data?.totalPoints ?? 0)} </>;
}
