import {
    MayString,
    STAKED_SOL_MINT,
    filterTruthy,
    formatDate,
    formatTokenAmount,
    removeDuplicates
} from "@bridgesplit/utils";
import {
    AssetTypeIdentifier,
    BsMetadata,
    ChainId,
    ExtraLink,
    LoanRequestCollateral,
    LockboxAsset,
    TokenListTag
} from "@bridgesplit/abf-sdk";

import { useBsMetadataByMints, useCustodiansByIdentifiers } from "../reducers";
import { LoanCollateral, TokenBalance } from "../types";

export const SOL_CUSTODIAN = "so1eR6vHFtUSmj9tkjVhuaf8A8ksLJaFFurf8L3xS1y";

const HIDDEN_METADATA_KEYS: Set<string> = new Set([
    "description",
    "display_media",
    "extra_media",
    "name",
    "symbol",
    "primary_sale_happened",
    "token_standard",
    "update_authority",
    "key",
    "mint",
    "edition_nonce",
    "is_mutable",
    "condition_description",
    "schema_version",
    "location",
    "seller_fee_basis_points",
    "image"
]);
const DATE_TRAIT_NAMES = ["date", "time", "since", "before"];
const PLACEHOLDER_IMAGE = "https://bridgesplit-app.s3.amazonaws.com/tokens/placeholder.png";

export function useMetadataDetailsByMint(mint: string | undefined) {
    const { getMetadata, isLoading: metadataLoading, isFetching } = useBsMetadataByMints(mint ? [mint] : []);

    const metadata = getMetadata(mint);
    const custodianIdentifier = metadata?.assetOriginator;

    const { cache, isLoading: custodianLoading } = useCustodiansByIdentifiers([custodianIdentifier]);
    const custodian = custodianIdentifier ? cache?.get(custodianIdentifier) : undefined;

    return {
        metadata,
        custodian,
        isLoading: metadataLoading || custodianLoading,
        notFound: !metadata && !metadataLoading && !isFetching
    };
}

type BsMetaUtilInput = BsMetadata | undefined | null;

export class BsMetaUtil {
    static getName(metadata: BsMetaUtilInput): string {
        return this.getNameNoFallback(metadata) || "Unknown Asset";
    }

    static getNameNoFallback(metadata: BsMetaUtilInput): string | undefined {
        let name = metadata?.offchainMetadata?.name?.replace(/\0/g, "");

        if (name) {
            return name;
        }

        name = metadata?.name?.replace(/\0/g, "");
        if (name) {
            return name;
        }

        return undefined;
    }

    static getImage(metadata: BsMetaUtilInput, options?: { hideCdn?: boolean; size?: number }): string {
        if (!metadata) return "";
        let image = metadata?.offchainMetadata.display_media;
        if (!image) {
            image = metadata?.offchainMetadata?.extra_links?.find((a) => a.content_type?.includes("image"))?.uri;
        }
        if (!image) {
            image = metadata?.offchainMetadata?.image;
        }

        if (!image) {
            return PLACEHOLDER_IMAGE;
        }
        image = parseImageUri(image);
        if (options?.hideCdn) return image;
        const defaultSize = this.isFungible(metadata) ? 60 : 400;
        return getCdnUrl(image, options?.size ?? defaultSize);
    }

    static getAllImages(metadata: BsMetaUtilInput, options?: { hideCdn?: boolean; size?: number }): string[] {
        if (!metadata) return [""];

        let images = metadata.offchainMetadata.extra_links
            ?.filter((l) => l.content_type?.startsWith("image"))
            .map((i) => i.uri);

        if (!images?.length) {
            images = metadata.offchainMetadata.extra_media
                ?.filter((l) => l.type?.startsWith("image"))
                .map((i) => i.uri)
                .filter(filterTruthy);
        }
        images = images ? removeDuplicates(images).map((i) => parseImageUri(i)) : images;

        if (!images?.length) return [this.getImage(metadata, options)];
        if (options?.hideCdn) return images;
        return images.map((src) => getCdnUrl(src, options?.size ?? 400));
    }

    static getExternalFiles(metadata: BsMetaUtilInput): ExtraLink[] | undefined {
        if (!metadata) return undefined;
        return metadata.offchainMetadata.extra_links?.filter((l) => !l.content_type?.startsWith("image"));
    }

    static getDescription(metadata: BsMetaUtilInput) {
        let description = metadata?.offchainMetadata?.description;
        if (description === "null") description = undefined; // legacy assets
        return description ?? "No description provided";
    }

    static getSymbol(metadata: BsMetaUtilInput) {
        if (!metadata) return "";
        let symbol = metadata?.offchainMetadata?.symbol;
        if (symbol) return symbol;
        symbol = metadata?.offchainMetadata.symbol;
        if (symbol) return symbol;
        return "UNKNOWN";
    }

    static getNameOrSymbol(metadata: BsMetaUtilInput) {
        if (this.isNonFungible(metadata)) {
            return this.getName(metadata);
        }
        return this.getSymbol(metadata);
    }

    static isFungible(metadata: BsMetaUtilInput) {
        if (!metadata) return false;
        return metadata?.decimals !== 0;
    }

    static isNonFungible(metadata: BsMetaUtilInput) {
        return metadata?.decimals === 0;
    }

    static formatAmount(
        metadata: BsMetaUtilInput,
        amount: number | undefined,
        options?: { hideSymbol?: boolean; decimals?: number }
    ) {
        const isLp = metadata?.tags?.includes(TokenListTag.LP);
        if (this.isNonFungible(metadata)) {
            if (isLp) {
                return this.getSymbol(metadata);
            }
            return this.getSymbolUnique(metadata);
        }

        return formatTokenAmount(amount, {
            symbol: isLp ? this.getSymbol(metadata) : BsMetaUtil.getSymbolUnique(metadata),
            decimals: options?.decimals ?? metadata?.decimals,
            hideSymbol: options?.hideSymbol
        });
    }

    static getChain(metadata: BsMetaUtilInput) {
        return metadata?.offchainMetadata?.source_chain;
    }

    static getChainId(metadata: BsMetaUtilInput) {
        return this.getChain(metadata)?.chain_id ?? ChainId.Solana;
    }

    static getAttributes(metadata: BsMetaUtilInput) {
        if (metadata?.offchainMetadata) {
            const validTypes = ["number", "string", "boolean"];
            const properties = Object.entries(metadata.offchainMetadata).filter(
                ([key]) => !HIDDEN_METADATA_KEYS.has(key)
            );

            // de-nest any arrays
            properties.forEach(([key, value]) => {
                if (!Array.isArray(value)) return;
                value.forEach((subvalue) => {
                    properties.push([key, subvalue]);
                });
            });

            const attributes = properties.filter(([, value]) => {
                const type = typeof value;
                return validTypes.includes(type) && (!!value || type === "boolean");
            });

            return attributes.map(([trait_type, value]) => {
                if (typeof value === "boolean") {
                    return {
                        trait_type,
                        value: value ? "True" : "False"
                    };
                }
                if (typeof value === "number") {
                    if (isPropertyLikelyDate(trait_type)) {
                        return {
                            trait_type,
                            value: formatDate(value, "date", { alwaysShowYear: true })
                        };
                    }
                    return {
                        trait_type,
                        value: value.toString()
                    };
                }
                return {
                    trait_type,
                    value: value as string
                };
            });
        }
        return [];
    }

    static isValid(input: object | undefined | null): input is BsMetadata {
        if (!input) return false;
        if ("assetMint" in input && "assetClass" in input) return true;
        return false;
    }

    // Returns unique name for NFTs, LPs, and other assets with the same token symbol
    static getSymbolUnique(metadata: BsMetaUtilInput): string {
        if (this.isNonFungible(metadata) || metadata?.tags?.includes(TokenListTag.LP)) {
            return this.getName(metadata);
        }
        return this.getSymbol(metadata);
    }
}

export function getCdnUrl(image: MayString, size: number) {
    return `https://cdn.loopscale.com/?fit=crop&height=${size}&width=${size}&image=${image}`;
}

export function parseImageUri(image: string) {
    return image.replace("ipfs://", "https://ipfs.io/ipfs/");
}

export function getSearchFromMetadata(metadata: BsMetaUtilInput) {
    return [BsMetaUtil.getName(metadata), BsMetaUtil.getSymbol(metadata)];
}

function isPropertyLikelyDate(propertyName: string) {
    const propertyNameLower = propertyName.toLowerCase();
    return !!DATE_TRAIT_NAMES.find((trait) => propertyNameLower.includes(trait));
}

export function isStakedSol(metadataOrAssetType: BsMetaUtilInput | AssetTypeIdentifier | string) {
    if (typeof metadataOrAssetType === "string")
        return metadataOrAssetType === AssetTypeIdentifier.StakedSol || metadataOrAssetType === STAKED_SOL_MINT;
    return metadataOrAssetType?.assetMint === STAKED_SOL_MINT;
}

export function isCollateralStakedSol(asset: LoanRequestCollateral | LockboxAsset | LoanCollateral) {
    return asset.assetTypeDiscriminator === AssetTypeIdentifier.StakedSol;
}

export function isCollateralSplMint(asset: LoanRequestCollateral | LockboxAsset | LoanCollateral) {
    return asset.assetTypeDiscriminator === AssetTypeIdentifier.SplToken;
}

export function formatTokens(
    tokens: Omit<TokenBalance, "mint">[] | undefined,
    options?: { hideSymbol?: boolean; condensed?: boolean; showAll?: boolean }
) {
    if (tokens?.length === 1) {
        const token = tokens[0];
        if (BsMetaUtil.isNonFungible(token.metadata) && options?.condensed) {
            return BsMetaUtil.getSymbol(token.metadata);
        }
        return BsMetaUtil.formatAmount(token.metadata, token.amount, { hideSymbol: options?.hideSymbol });
    }

    if (options?.showAll) {
        return tokens
            ?.map((t) => BsMetaUtil.formatAmount(t.metadata, t.amount, { hideSymbol: options?.hideSymbol }))
            .join(" + ");
    }

    return `${tokens?.length || 0} assets`;
}

export function formatTokenMetadata(tokens: BsMetadata[] | undefined, term = "assets") {
    return tokens?.length === 1 ? BsMetaUtil.getSymbol(tokens[0]) : `${tokens?.length || 0} ${term}`;
}
