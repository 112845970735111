import {
    AssetTypeIdentifier,
    formatDurationWithTypeShorthand,
    StrategyCollateralInfoParams
} from "@bridgesplit/abf-sdk";
import { bpsToPercent } from "@bridgesplit/utils";
import {
    CreateStrategyFromPresetParams,
    FillStrategyOrderArgs,
    formatLeverage,
    LoopWindParams,
    StrategyDuration
} from "@bridgesplit/abf-react";
import { OrderSource, SubmitEarnData, TrackMarketBorrowData, TrackMarketLendData } from "app/types";
import { TransactionAuthenticated } from "@bridgesplit/react";

export function trackSubmitMarketBorrowOrder({
    params,
    duration,
    source
}: {
    params: FillStrategyOrderArgs;
    duration: StrategyDuration;
    source: OrderSource;
}): TrackMarketBorrowData {
    return {
        strategyIdentifier: params.strategyIdentifier,
        refinanceOrder: params.refinancedOrder,
        lockboxAddress: params.lockboxAddress,
        collateral: formatCollateral(params.collateral),
        principalMint: params.principalMint,
        principalAmount: params.principalRequested,
        apy: bpsToPercent(params.apy),
        ltv: bpsToPercent(params.ltv),
        duration: formatDurationWithTypeShorthand(duration.duration, duration.durationType),
        txnGenerationType: params.transactionGenerationType ? "Jito" : "Priority",
        source
    };
}

export function trackSubmitMarketLend(
    dbParams: TransactionAuthenticated<CreateStrategyFromPresetParams>,
    amountFromWallet: number,
    amountFromEscrow: number,
    offerTerms: FormattedOfferTerms
): TrackMarketLendData {
    return {
        strategyIdentifier: dbParams.strategyIdentifier,
        offerTerms,
        principalMint: dbParams.principalMint,
        amountFromWallet,
        amountFromEscrow
    };
}

export function trackSubmitEarnVault({ params }: { params: LoopWindParams }): SubmitEarnData {
    return {
        loopExpanded: params.loopExpanded,
        windRoute: params.windRoute,
        vaultIdentifier: params.loopExpanded.vaultIdentifier,
        collateralInfo: formatCollateral([params.collateralInfo]),
        leverageMultiplier: formatLeverage(params.leverageMultiplier),
        strategyIdentifier: params.strategyIdentifier,
        principalRequested: params.principalRequested,
        apy: bpsToPercent(params.apy),
        ltv: bpsToPercent(params.ltv),
        slippage: bpsToPercent(params.slippage),
        liquidationThreshold: bpsToPercent(params.liquidationThreshold),
        transactionGenerationType: params.transactionGenerationType,
        useFillerEscrow: params.useFillerEscrow,
        principalMint: params.principalMint,
        userPublicKey: params.userPublicKey
    };
}

interface CollateralInfo {
    totalAmount: number;
    assetType: string;
    assetMint: string;
}

function getAssetTypeKey(value: string): keyof typeof AssetTypeIdentifier | undefined {
    const entries = Object.entries(AssetTypeIdentifier);
    const found = entries.find(([_, val]) => val === value);
    return found ? (found[0] as keyof typeof AssetTypeIdentifier) : undefined;
}

function formatCollateral(collateral: StrategyCollateralInfoParams[]): CollateralInfo[] {
    const formattedCollateral = collateral.map((c): CollateralInfo => {
        const assetTypeKey = getAssetTypeKey(c.assetTypeDiscriminator);
        return {
            totalAmount: c.amountFromEscrow + c.amountFromWallet,
            assetType: assetTypeKey || c.assetTypeDiscriminator,
            assetMint: c.assetKey
        };
    });
    return formattedCollateral;
}

export interface FormattedOfferTerms {
    collateral: string[];
    terms: {
        [key: string]: number;
    };
}
