import { createContext, useContext, useEffect, useState } from "react";

import {
    useLoopBestQuote,
    DEFAULT_PRESET,
    LoopQuoteExpanded,
    useWindExternalQuote,
    ExternalWindQuote,
    StrategyDuration,
    getLoopSlippage
} from "@bridgesplit/abf-react";
import { DispatchType } from "@bridgesplit/react";
import { percentUiToDecimals } from "@bridgesplit/utils";

import { WindForm } from "./types";
import { useLoopContext } from "../LoopContext";
import { DEFAULT_WIND_FORM } from "./constants";

interface WindContext {
    quote: LoopQuoteExpanded | null | undefined;
    externalQuote: ExternalWindQuote | undefined;
    isQuoteFetching: boolean;
    form: WindForm;
    setForm: DispatchType<WindForm>;
    isActionOpen: boolean;
    setIsActionOpen: DispatchType<boolean>;
    durationForQueries: StrategyDuration;
}

const LoopContext = createContext<WindContext | null>(null);

export function useWindContext(): WindContext {
    const context = useContext(LoopContext);
    if (context === null) {
        throw new Error("useWindContext must be used within a LoopProvider");
    }
    return context;
}

export function WindProvider({ children }: { children: React.ReactNode }) {
    const { loopExpanded } = useLoopContext();
    const [isActionOpen, setIsActionOpen] = useState(false);
    const [form, setForm] = useState<WindForm>(DEFAULT_WIND_FORM);

    const defaultSlippageDecimals = percentUiToDecimals(getLoopSlippage(loopExpanded).defaultSlippageUi);

    useEffect(() => {
        if (loopExpanded && form.percentSlippageDecimals === undefined) {
            setForm({
                ...form,
                percentSlippageDecimals: defaultSlippageDecimals
            });
        }
    }, [defaultSlippageDecimals, form, loopExpanded]);

    const durationForQueries = form.preset ?? DEFAULT_PRESET;
    const loopBestQuote = useLoopBestQuote({
        loopExpanded: loopExpanded,
        leverageMultiplier: form.multiplier,
        collateralAmountUi: form.collateralAmount,
        duration: durationForQueries
    });

    const externalQuote = useWindExternalQuote({
        loopExpanded: loopExpanded,
        principalAmount: loopBestQuote.data?.principalAmount,
        slippagePercentDecimals: form.percentSlippageDecimals ?? defaultSlippageDecimals
    });
    const isQuoteFetching = loopBestQuote.isFetching || externalQuote.isFetching;
    return (
        <LoopContext.Provider
            value={{
                quote: loopBestQuote.data,
                form,
                setForm,
                externalQuote: externalQuote.data,
                isQuoteFetching,
                isActionOpen,
                setIsActionOpen,
                durationForQueries
            }}
        >
            {children}
        </LoopContext.Provider>
    );
}
