import { ReactNode, useMemo, useState } from "react";

import {
    BsMetaUtil,
    getLoanEndTime,
    getTotalRepaidForCurrentLender,
    SellLoanTransactionParams,
    summarizeLedgerAccounts,
    useActiveWallet,
    useSellLoanTransaction,
    useSellQuote
} from "@bridgesplit/abf-react";
import {
    BORDER_RADIUS,
    CheckboxRow,
    Column,
    FONT_SIZES,
    InvisibleSelect,
    Row,
    SPACING,
    StatColumn,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import {
    bsMath,
    calculatePercentChange,
    formatAddress,
    formatDate,
    formatPercent,
    lessThan,
    LOADING_ERROR,
    Result
} from "@bridgesplit/utils";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { COPY } from "app/constants";
import { TrackTransactionEvent } from "app/types";

import { AppButton, TokenImage } from "../../common";
import { ActionProps } from "./types";
import { useTransactionSender } from "../../transactions";

enum EscrowSetting {
    Wallet,
    KeepInEscrow
}
const SLIPPAGE_OPTIONS = [0.5, 1, 2, 5];
type Form = { escrowSetting: EscrowSetting; slippage: number };
export default function SellLoan(props: ActionProps) {
    const { loanExpanded } = props;

    const { data: quote } = useSellQuote(loanExpanded);
    const send = useTransactionSender();
    const sellLoan = useSellLoanTransaction();
    const [form, setForm] = useState<Form>({ escrowSetting: EscrowSetting.Wallet, slippage: SLIPPAGE_OPTIONS[0] });
    const { activeWallet } = useActiveWallet();
    const [accepted, setAccepted] = useState(false);

    const originalLedgers = useMemo(() => summarizeLedgerAccounts(loanExpanded), [loanExpanded]);

    const totalLedgersOutstanding = originalLedgers.totalOutstanding;
    const sellPriceDiff = quote ? calculatePercentChange(totalLedgersOutstanding, quote?.salePrice) : undefined;

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        if (!quote) return Result.errFromMessage("No quote found");
        const params: SellLoanTransactionParams = {
            decimals: loanExpanded.principalMetadata.decimals,
            loanAddress: loanExpanded.loan.address,
            slippage: form.slippage,
            expectedSalePrice: quote.salePrice,
            keepInEscrow: form.escrowSetting === EscrowSetting.KeepInEscrow
        };
        return await send(sellLoan, params, {
            mixpanelEvent: { key: TrackTransactionEvent.SubmitSellLoan, params }
        });
    }

    const netInterest = useMemo(() => {
        if (!quote || !loanExpanded) return undefined;
        const totalRepaidForCurrentLender = getTotalRepaidForCurrentLender(loanExpanded);

        return quote?.salePrice + totalRepaidForCurrentLender - loanExpanded?.principalAmount;
    }, [loanExpanded, quote]);
    const isTakingPrincipalLoss = lessThan(netInterest, 0);

    // this will occur if there isn't sufficient liquidity to give fair fair
    const priceImpact = bsMath.sub(quote?.salePrice, quote?.fairSalePriceAtQuoteRate);
    const priceImpactWarningNeeded = lessThan(priceImpact, 0);

    const ctaError = useMemo(() => {
        if (priceImpactWarningNeeded && !accepted) return "Accept warning";
        if (!quote?.salePrice) return "No offers";
        return undefined;
    }, [accepted, quote?.salePrice, priceImpactWarningNeeded]);

    return (
        <>
            <Text color="caption">Get instant liquidity for your loan by selling it back to the market</Text>

            <Column>
                <NestedCard isTop>
                    <Row spacing={0.5}>
                        <Text> Your loan </Text>
                        <Text color="caption">
                            repaid {formatDate(getLoanEndTime(loanExpanded, false), "relative").toLowerCase()}
                        </Text>
                    </Row>
                    <Row spacing={1}>
                        <TokenImage metadata={loanExpanded?.principalMetadata} size="sm" />
                        <Text>
                            {BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, totalLedgersOutstanding, {
                                hideSymbol: true
                            })}
                        </Text>
                    </Row>
                </NestedCard>
                <DownDivider />
                <NestedCard>
                    <Text> Receive now </Text>
                    <Row spacing={1}>
                        <TokenImage metadata={loanExpanded?.principalMetadata} size="sm" />
                        <Text loading={!quote}>
                            {BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, quote?.salePrice, {
                                hideSymbol: true
                            })}
                        </Text>
                        {!!sellPriceDiff && (
                            <Text color={sellPriceDiff < -0.03 ? "error" : "caption"} variant="body2" loading={!quote}>
                                {formatPercent(sellPriceDiff, { includePlus: true })}
                            </Text>
                        )}
                    </Row>
                </NestedCard>
            </Column>

            <StatColumn
                stats={[
                    {
                        caption: "Receive to",
                        value: (
                            <InvisibleSelect
                                options={[
                                    { value: EscrowSetting.Wallet, label: formatAddress(activeWallet?.wallet) },
                                    { value: EscrowSetting.KeepInEscrow, label: COPY.STRATEGY_TERM }
                                ]}
                                value={form.escrowSetting}
                                setValue={(escrowSetting) => setForm((prev) => ({ ...prev, escrowSetting }))}
                            />
                        )
                    },
                    {
                        caption: "Slippage",
                        value: (
                            <InvisibleSelect
                                options={SLIPPAGE_OPTIONS.map((value) => ({
                                    value,
                                    label: formatPercent(value, { skipMultiplication: true })
                                }))}
                                value={form.slippage}
                                setValue={(slippage) => setForm((prev) => ({ ...prev, slippage }))}
                            />
                        )
                    },
                    {
                        caption: "Price impact",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, priceImpact),
                        hide: !priceImpactWarningNeeded,
                        tooltip: `The difference between your loan's ${BsMetaUtil.formatAmount(
                            loanExpanded?.principalMetadata,
                            quote?.fairSalePriceAtQuoteRate,
                            { hideSymbol: true }
                        )} fair market value and the price you're receiving`,
                        textColor: "error"
                    },
                    {
                        caption: "You funded",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, loanExpanded?.principalAmount),
                        hide: !isTakingPrincipalLoss
                    },
                    {
                        caption: "Net interest",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, netInterest),
                        tooltip: "Due to limited liquidity, early withdrawing would result in a loss",
                        textColor: "error",
                        hide: !isTakingPrincipalLoss
                    }
                ]}
            />
            {priceImpactWarningNeeded && (
                <CheckboxRow
                    value={accepted}
                    setValue={() => setAccepted(!accepted)}
                    label="I understand that I'm receiving less than this loan's fair value due to insufficient liquidity"
                />
            )}

            <AppButton disabled={!!ctaError} isTransaction asyncCta={{ onClickWithResult: submit }}>
                {ctaError ?? "Early withdraw"}
            </AppButton>
        </>
    );
}

function NestedCard({ children, isTop }: { children: ReactNode; isTop?: boolean }) {
    const { hoverBackground } = useAppPalette();
    let borderRadiusParts = [BORDER_RADIUS, BORDER_RADIUS, "0px", "0px"];
    if (!isTop) {
        borderRadiusParts = borderRadiusParts.reverse();
    }
    const borderRadius = borderRadiusParts.join(" ");
    return (
        <Row
            spaceBetween
            sx={{
                borderRadius,
                backgroundColor: hoverBackground,
                p: 2
            }}
        >
            {children}
        </Row>
    );
}

function DownDivider() {
    const { hoverBackground, paperBackground, textDisabled } = useAppPalette();
    const size = "20px";
    return (
        <Row sx={{ height: SPACING / 2 + "px", flexGrow: 1, justifyContent: "center" }}>
            <Box sx={{ background: paperBackground, p: SPACING / 4 + "px", zIndex: 1, borderRadius: "100%" }}>
                <Box
                    sx={{
                        background: hoverBackground,
                        svg: { fontSize: FONT_SIZES.body1, color: textDisabled },
                        height: size,
                        width: size,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%"
                    }}
                >
                    <ArrowDownwardOutlined />
                </Box>
            </Box>
        </Row>
    );
}
