import { useMemo, useState } from "react";

import {
    Column,
    EmptyPlaceholder,
    MEDIA,
    MediaWrapper,
    SearchInput,
    ToggleSpacedButtons,
    hideScrollbarSx,
    repeatElement
} from "@bridgesplit/ui";
import { filterTruthy, textContains } from "@bridgesplit/utils";
import { getSearchFromMetadata } from "@bridgesplit/abf-react";
import { TokenListTag } from "@bridgesplit/abf-sdk";

import CollateralMarketsTable from "./CollateralMarketsTable";
import CollateralMarketCard from "./CollateralMarketCard";
import { useCollateralMarkets } from "./util";
import { CollateralMarket } from "./types";
import { getTokenTagMetadata, PageSubtitle, TableCardWrapper } from "../../common";

type TokenFilter = TokenListTag;
const DEFAULT_FILTER = TokenListTag.Featured;
const options = [
    TokenListTag.Featured,
    TokenListTag.All,
    TokenListTag.DeFi,
    TokenListTag.LP,
    TokenListTag.Memes,
    TokenListTag.LST,
    TokenListTag.Governance
]
    .map(getTokenTagMetadata)
    .filter(filterTruthy);
const QUERY = MEDIA.LG;
export default function CollateralMarkets() {
    const rawData = useCollateralMarkets();
    const [filterState, setFilter] = useState<TokenFilter>();
    const filter = filterState ?? DEFAULT_FILTER;

    const [search, setSearch] = useState<string>("");

    const data = useMemo(() => {
        if (!rawData) return rawData;
        return rawData.filter(({ metadata }) => {
            const tagFilter = filter === TokenListTag.All || metadata.tags?.includes(filter);
            if (!tagFilter) return false;
            if (!search) return true;
            if (metadata.assetMint === search) return true;
            return textContains(getSearchFromMetadata(metadata), search);
        });
    }, [filter, rawData, search]);

    return (
        <Column spacing={3}>
            <PageSubtitle>Collateral</PageSubtitle>
            <SearchInput
                onChange={(e) => {
                    setSearch(e.target.value);
                    if (filterState === undefined) {
                        setFilter(TokenListTag.All);
                    }
                }}
                value={search}
                placeholder="Search"
                fullWidth
            />

            <ToggleSpacedButtons
                variant="contained"
                containerSx={{ overflowX: "auto", ...hideScrollbarSx }}
                size="small"
                value={filter}
                setValue={(value) => {
                    setFilter(value);
                    setSearch("");
                }}
                options={options.map((o) => ({
                    value: o.tag,
                    label: (
                        <>
                            {o.icon} {o.label}
                        </>
                    )
                }))}
            />

            <Mobile markets={data} />
            <MediaWrapper query={QUERY.above}>
                <TableCardWrapper>
                    <CollateralMarketsTable key={filter} markets={data} />
                </TableCardWrapper>
            </MediaWrapper>
        </Column>
    );
}

function Mobile({ markets }: { markets: CollateralMarket[] | undefined }) {
    return (
        <Column sx={{ [QUERY.above]: { display: "none" }, width: "100%" }} spacing={2}>
            {markets?.length === 0 && (
                <EmptyPlaceholder
                    header="No eligible collateral"
                    description="You don't own any Loopscale-supported collateral"
                />
            )}
            {markets?.map((market) => (
                <CollateralMarketCard key={market.metadata.assetMint} market={market} />
            ))}
            {!markets && repeatElement(<CollateralMarketCard market={undefined} />)}
        </Column>
    );
}
