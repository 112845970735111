import { BORDER_RADIUS, FONT_SIZES, FONT_WEIGHTS, MEDIA, SxType } from "@bridgesplit/ui";

export const NAV_Z_INDEX = 100;
export const CARD_Z_INDEX = NAV_Z_INDEX - 1;

export const NAV_BREAKPOINT = MEDIA.LG;
export const DESKTOP_DRAWER_PADDING = 3;
export const MOBILE_PADDING = 2;
export const DESKTOP_PADDING = 4;

export const NAV_BUTTON_HEIGHT = 35;
export const NAV_ICON_BUTTON_SX: SxType = {
    width: NAV_BUTTON_HEIGHT + "px",
    height: NAV_BUTTON_HEIGHT + "px",
    borderRadius: BORDER_RADIUS,
    borderColor: (theme) => theme.palette.divider,
    svg: {
        fontSize: `${FONT_SIZES.body1}px !important`,
        color: "inherit",
        fontWeight: FONT_WEIGHTS.normal
    }
};
