import { useMemo } from "react";

import { removeDuplicatesByProperty } from "@bridgesplit/utils";
import { ChainId } from "@bridgesplit/bs-protos/dist/generated/typescript/util";

import { abfUserApi, useAuthStorage, useUserMultichainWalletsQuery } from "../reducers";
import { useSkipUnauthenticated, useUserIsPrime, useUserMe } from "./auth";

export function useUserWallets() {
    const { data } = useUserMe();

    return { wallets: data?.wallets };
}

export function useActiveWallet() {
    const { state } = useAuthStorage();

    const { data, isLoading } = useUserMe();

    const userWalletsForGroup = useMemo(() => {
        if (!data?.wallets) return undefined;
        return removeDuplicatesByProperty(
            data.wallets.filter((w) => w.groupIdentifier === state.groupIdentifier),
            "wallet"
        );
    }, [data?.wallets, state.groupIdentifier]);
    const activePubkeysSet = new Set(userWalletsForGroup?.map((w) => w.wallet));

    const activeWallet = userWalletsForGroup?.[0];
    const isMpcActive = !!activeWallet?.mpcIdentifier;
    const activeMpcWallet = userWalletsForGroup?.find((w) => !!w.mpcIdentifier);
    const isMpcAvailable = !!activeMpcWallet;
    const isSelfCustodialAvailable = !!data?.wallets.find((w) => !w.mpcIdentifier);

    const solanaMpcWallet = data?.wallets.find((w) => !!w.mpcIdentifier);
    const isMpcWalletCreated = !!solanaMpcWallet;

    return {
        activeWallet,
        userWalletsForGroup,
        activePubkeysSet,
        isLoading,
        activeMpcWallet,
        isMpcActive,
        isMpcAvailable,
        isSelfCustodialAvailable,
        isMpcWalletCreated,
        solanaMpcWallet
    };
}

export function useUserMultichainWallets() {
    const { isPrime } = useUserIsPrime();
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const query = useUserMultichainWalletsQuery(undefined, { skip: skipIfUnauthenticated || !isPrime });
    const [fetchUserMultichainWallets] = abfUserApi.endpoints.userMultichainWallets.useLazyQuery();

    async function fetchMultichainWallets(force?: boolean) {
        const res = await fetchUserMultichainWallets(undefined, !force);
        if ("error" in res) return [];
        return res.data ?? [];
    }

    return { fetchMultichainWallets, ...query };
}

export function useUserMultichainWallet() {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const query = useUserMultichainWalletsQuery(undefined, { skip: skipIfUnauthenticated });
    const [fetchUserMultichainWallets] = abfUserApi.endpoints.userMultichainWallets.useLazyQuery();

    async function fetchMultichainWallet(chainId: ChainId, force?: boolean) {
        const res = await fetchUserMultichainWallets(undefined, !force);
        if ("error" in res) return undefined;
        return res.data?.find((wallet) => wallet.chainId === chainId);
    }

    return { fetchMultichainWallet, ...query };
}
