import { useState } from "react";

import { BackCta, Column, Icon, StatColumn, TabsSelect, TagTextAlert, TokenInput } from "@bridgesplit/ui";
import { EvmTransactionType, useEstimateGas, useUserChainWallet, useUserEvmBalance } from "@bridgesplit/abf-react";
import { EVM_NATIVE_DECIMALS, EVM_NATIVE_MINT, formatNum, roundToEther } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import {
    AppButton,
    ChainAddressInput,
    DialogHeader,
    QrCodeCopyAddress,
    getChainMeta,
    isValidAddress
} from "../../common";
import EvmBalance, { EvmBalanceContextMode } from "../EvmBalance";
import { useTransferEvmAsset } from "../util";

export default function ManageBalance() {
    const { chain, mode, setMode } = EvmBalance.useState();

    const back = () => setMode(EvmBalanceContextMode.Default);

    const meta = getChainMeta(chain);

    return (
        <>
            <DialogHeader
                header={`Manage ${meta.name} balance`}
                description={`Your Loopscale ${meta.symbol} balance is used to pay for network fees when transferring assets using ${meta.name}`}
            />
            <Column sx={{ mx: -2, mt: -1 }}>
                <TabsSelect
                    value={mode}
                    setValue={setMode}
                    options={[
                        { value: EvmBalanceContextMode.Deposit, label: "Deposit" },
                        { value: EvmBalanceContextMode.Withdraw, label: "Withdraw" }
                    ]}
                />
            </Column>
            {mode === EvmBalanceContextMode.Deposit && <Deposit />}
            {mode === EvmBalanceContextMode.Withdraw && <Withdraw />}

            <BackCta back={back} />
        </>
    );
}

function Deposit() {
    const { chain, requestedBalance } = EvmBalance.useState();

    const { wallet } = useUserChainWallet(chain);
    const meta = getChainMeta(chain);
    const { data } = useUserEvmBalance(chain);

    return (
        <>
            <QrCodeCopyAddress chainId={chain} address={wallet} />
            <StatColumn
                stats={[
                    { value: data?.uiAmount, symbol: meta.symbol, caption: COPY.ESCROW_TERM },
                    {
                        value: requestedBalance,
                        symbol: meta.symbol,
                        caption: "Recommended Deposit",
                        hide: !requestedBalance
                    }
                ]}
            />
            <TagTextAlert>
                <Icon type="tooltip" /> Only send {meta.symbol} over the {meta.name} network
            </TagTextAlert>
        </>
    );
}

const GAS_BUFFER = 1.5;
function Withdraw() {
    const { chain } = EvmBalance.useState();
    const meta = getChainMeta(chain);
    const { data } = useUserEvmBalance(chain);

    const [address, setAddress] = useState<string>();
    const [amount, setAmount] = useState<number>();
    const transfer = useTransferEvmAsset();

    const gas = useEstimateGas(chain)([EvmTransactionType.TransferNative]);

    const availableBalance = data && gas?.total ? Math.max(data.uiAmount - gas.total * GAS_BUFFER, 0) : undefined;

    const errorMessage = (() => {
        if (!address) return "Enter Address";
        if (!amount) return "Enter Amount";
        if (!availableBalance || amount > availableBalance) return "Insufficient Balance";
        if (!isValidAddress(chain, address)) return "Invalid Address";
        return undefined;
    })();

    return (
        <>
            <ChainAddressInput label="Your Polygon address" chainId={chain} value={address} setValue={setAddress} />

            <TokenInput
                decimals={EVM_NATIVE_DECIMALS}
                label="Withdraw amount"
                maxAmount={availableBalance}
                value={amount}
                setValue={setAmount}
                symbol={meta.symbol}
            />

            <AppButton
                isTransaction
                asyncCta={{
                    closeDialog: "onSuccess",
                    options: { onSuccessMessage: `Withdrew ${formatNum(amount)} ${meta.symbol}` },
                    onClickWithResult: () =>
                        transfer({
                            uiAmount: roundToEther(amount ?? 0),
                            recipientAddress: address ?? "",
                            contract: EVM_NATIVE_MINT,
                            chainId: chain
                        })
                }}
                disabled={!!errorMessage}
            >
                {errorMessage ?? `Withdraw ${meta.symbol}`}
            </AppButton>
        </>
    );
}
