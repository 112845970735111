import { lazy } from "@bridgesplit/ui";

import BankOverview from "./BankOverview";
import BankAccountsTable from "./BankAccountsTable";
import BankChecklist from "./BankChecklist";

const UboRegister = lazy(() => import("./UboRegister"));
const BankTransfersOverview = lazy(() => import("./BankTransfersOverview"));

export { BankOverview, BankAccountsTable, BankChecklist, UboRegister, BankTransfersOverview };
export * from "./dialogs";
export * from "./util";
