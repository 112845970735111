import { useMemo } from "react";

import {
    Collateral,
    MarketExpanded,
    useBsMetadataByMint,
    useOraclePrices,
    useRefinanceInfo,
    useSummarizedUserAssets,
    useTokenListQuery
} from "@bridgesplit/abf-react";
import { DEFAULT_PRINCIPAL_MINT, NullableRecord, filterNullableRecord } from "@bridgesplit/utils";

import { CollateralMarket } from "./types";

const principalMint = DEFAULT_PRINCIPAL_MINT;
export function useCollateralMarkets() {
    // directly use token list to prevent principal mint from being filtered out
    const tokens = useTokenListQuery().data?.filter((t) => t.isCollateral);

    const { getRefinanceInfo } = useRefinanceInfo(
        tokens?.length ? { [principalMint]: tokens?.map((t) => t.assetMint) } : undefined
    );
    const principalMetadata = useBsMetadataByMint(principalMint);
    const { getOracle } = useOraclePrices(tokens?.map((t) => t.assetMint));

    const { balanceMap: userBalanceMap, isLoading } = useSummarizedUserAssets({
        includeStakedSol: true,
        includeLpPositions: true
    });

    const collateral = useMemo(() => {
        if (!tokens || !userBalanceMap) return undefined;
        return tokens
            .map((t): NullableRecord<Collateral> => {
                const oracle = getOracle(t.assetMint) ?? null;
                const userBalance = userBalanceMap.get(t.assetMint);
                return {
                    mint: t.assetMint,
                    metadata: t,
                    oracle,
                    usdPrice: oracle?.usdPrice ?? 0,
                    amount: userBalance?.available ?? 0,
                    usdValue: userBalance?.availableUsd ?? 0
                };
            })
            .filter(filterNullableRecord);
    }, [getOracle, tokens, userBalanceMap]);

    return useMemo(() => {
        if (!collateral || !userBalanceMap || !principalMetadata || isLoading) return undefined;
        return collateral
            .map(({ metadata, usdValue, amount }): NullableRecord<CollateralMarket> => {
                return {
                    metadata,
                    oracle: getOracle(metadata.assetMint) ?? null,
                    // since max int was passed in for no balance
                    principalMetadata,
                    refinanceInfo: getRefinanceInfo(principalMint, metadata.assetMint),
                    usdValue,
                    userBalance: amount
                };
            })
            .filter(filterNullableRecord);
    }, [collateral, getOracle, getRefinanceInfo, isLoading, principalMetadata, userBalanceMap]);
}

export function useMarketSummaryStats(markets: MarketExpanded[] | undefined) {
    return useMemo(() => {
        if (!markets) return undefined;
        let principalUtilized = 0;
        let principalOriginated = 0;
        let availableDeposits = 0;
        let salesVolume = 0;

        for (const { principalStats, strategyStats, usdPrice } of markets) {
            const price = usdPrice ?? 0;
            principalOriginated += principalStats.principalOriginated * price;
            principalUtilized += principalStats.principalUtilized * price;
            availableDeposits += strategyStats.totalDeposits * price;
            salesVolume += principalStats.salesVolume * price;
        }

        const totalDeposits = principalUtilized + availableDeposits;
        return { principalUtilized, principalOriginated, totalDeposits, availableDeposits, salesVolume };
    }, [markets]);
}
