import { useState } from "react";

import { DialogWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { StrategySettingsForm, StrategySettingsProps } from "../settings/types";
import StrategySettingsToggles from "../settings/StrategySettingsToggles";
import StrategySettingsCta from "../settings/StrategySettingsCta";
import { DialogHeader } from "../../common";

export default function StrategySettingsDialog() {
    const { getData } = useAppDialog();
    const strategy = getData<AppDialog.StrategySettings>()?.strategy;

    const [formState, setForm] = useState<StrategySettingsForm>();

    const initialForm: StrategySettingsForm | undefined = strategy
        ? {
              allowNewOffers: (strategy.escrowAccount?.originationCap ?? 0) !== 0,
              yieldSource: strategy.escrowAccount?.externalYieldSource ?? ExternalYieldSource.None
          }
        : undefined;

    const form = formState ?? initialForm;

    const props: StrategySettingsProps = {
        strategy,
        form,
        initialForm,
        setForm,
        resetForm: () => setForm(undefined)
    };

    return (
        <DialogWrapper>
            <DialogHeader header={AppDialog.StrategySettings} description={null} />
            <StrategySettingsToggles {...props} />
            <StrategySettingsCta {...props} />
        </DialogWrapper>
    );
}
