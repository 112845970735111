import { SPACING } from "../../theme";
import { Column } from "../containers";
import { SkeletonRounded } from "../misc";
import { Text } from "../typography";

export const TABLE_ROW_HEIGHT = 70;
export function ConnectWalletPrompt({ rowsPerPage }: { rowsPerPage: number }) {
    return (
        <Column
            height="100%"
            spacing={1}
            sx={{
                p: 2,
                alignItems: "center",
                justifyContent: "center",
                minHeight: `${TABLE_ROW_HEIGHT * rowsPerPage + SPACING * 2}px`
            }}
        >
            <Text> Please connect your wallet </Text>
        </Column>
    );
}

export function RowsSkeleton({ rowsPerPage }: { rowsPerPage: number }) {
    return (
        <Column sx={{ px: 1, py: 2 }}>
            {Array(rowsPerPage)
                .fill(null)
                .map((_, i) => (
                    <SkeletonRounded key={i} width="100%" height={TABLE_ROW_HEIGHT} sx={{ marginBottom: "2px" }} />
                ))}
        </Column>
    );
}
