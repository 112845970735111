import { useState } from "react";

import { AbfPermission, BsMetaUtil, StrategyExpanded, getStrategyName, useActiveWallet } from "@bridgesplit/abf-react";
import { Column, StatColumn, TagTextAlert, TokenInput } from "@bridgesplit/ui";
import { bsMath, formatAddress } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { AppButton, useBalanceChecker } from "../../common";
import { useStrategyDeposit } from "./util";

export default function StrategyDeposit({ strategy }: { strategy: StrategyExpanded | undefined }) {
    const { activeWallet } = useActiveWallet();

    const [amount, setAmount] = useState<number>();

    const mint = strategy?.strategy.principalMint;
    const { balance, escrowNeeded, BalanceDisplay, insufficientBalance } = useBalanceChecker({
        mint,
        amount,
        setMax: setAmount
    });
    const metadata = strategy?.principalMetadata;
    const symbol = BsMetaUtil.getSymbol(metadata);

    const deposit = useStrategyDeposit();

    return (
        <>
            <TokenInput label="Amount" symbol={symbol} maxAmount={balance} value={amount} setValue={setAmount} />
            {!strategy?.escrowAccount?.originationCap && amount && !!strategy?.strategy.onchain && (
                <TagTextAlert icon="tooltip" color="info">
                    This deposit won't be used until you re-enable new offers in settings
                </TagTextAlert>
            )}
            <StatColumn
                stats={[
                    {
                        caption: "Deposit from",
                        value: escrowNeeded ? COPY.ESCROW_TERM_FULL : formatAddress(activeWallet?.wallet)
                    },
                    {
                        caption: getStrategyName(strategy),
                        symbol,
                        value: [
                            strategy?.totalBalance,
                            Math.max(bsMath.add(strategy?.totalBalance, amount || 0) || 0, 0)
                        ],
                        hide: !amount
                    }
                ]}
            />

            <Column spacing={0.5}>
                <AppButton
                    isTransaction
                    fullWidth
                    authentication={{ permission: AbfPermission.DepositFunds }}
                    disabled={!amount || insufficientBalance}
                    asyncCta={{
                        options: { onSuccess: () => setAmount(undefined) },
                        onClickWithResult: () => deposit({ amount, strategy, escrowNeeded })
                    }}
                    variant="contained"
                >
                    Deposit
                </AppButton>
                <BalanceDisplay />
            </Column>
        </>
    );
}
