import { useActiveWallet } from "@bridgesplit/abf-react";
import { useUserPublicKey } from "@bridgesplit/react";
import { StatColumn, Text } from "@bridgesplit/ui";
import { formatAddress } from "@bridgesplit/utils";

import { DisconnectWalletButton } from "./common";

export default function WalletNotRegistered() {
    const { userWalletsForGroup } = useActiveWallet();
    const pubkey = useUserPublicKey();
    const wallets = userWalletsForGroup?.filter((w) => !w.mpcIdentifier).map((w) => w.wallet);

    // error
    if (!wallets || !pubkey) return null;

    return (
        <>
            <Text color="error"> Your connected wallet isn't linked to your account</Text>
            <StatColumn
                stats={[pubkey, ...wallets].map((wallet) => ({
                    value: formatAddress(wallet),
                    caption: wallet === pubkey ? "Connected" : "Registered"
                }))}
            />
            <DisconnectWalletButton />
        </>
    );
}
