import { ReactNode } from "react";

import { Column, IconButton, Row, SxType, Text, TextVariant } from "@bridgesplit/ui";
import { useAppDialog } from "app/utils";

export function DialogHeader({
    header,
    description,
    headerVariant = "h3",
    centerAlign,
    spacing = 0.5
}: {
    header: string | ReactNode;
    description: string | React.ReactElement | null;
    headerVariant?: TextVariant;
    centerAlign?: boolean;
    spacing?: number;
}) {
    return (
        <Column spacing={spacing} sx={{ alignItems: centerAlign ? "center" : undefined }}>
            {typeof header === "string" ? (
                <Text sx={{ textAlign: centerAlign ? "center" : undefined }} variant={headerVariant}>
                    {header}
                </Text>
            ) : (
                header
            )}

            {!!description && (
                <Text
                    sx={{ display: "block", textAlign: centerAlign ? "center" : undefined }}
                    color="caption"
                    variant="body2"
                >
                    {description}
                </Text>
            )}
        </Column>
    );
}

export function HeaderWithClose({
    variant,
    close,
    header,
    sx
}: {
    variant?: TextVariant;
    close: () => void;
    header?: string;
    sx?: SxType;
}) {
    return (
        <Row sx={sx} spaceBetween>
            <Text variant={variant}>{header}</Text>
            <IconButton onClick={close} textColor="caption" border={false} icon="reject" />
        </Row>
    );
}

export function DialogHeaderWithBack({
    back,
    header,
    sx,
    rowEnd
}: {
    back?: () => void;
    header?: string;
    sx?: SxType;
    rowEnd?: ReactNode;
}) {
    const { close } = useAppDialog();
    return (
        <Row sx={sx} spaceBetween>
            <Row sx={{ width: "100%" }}>
                <IconButton
                    onClick={back ?? close}
                    textColor="caption"
                    border={false}
                    icon={back ? "back" : "reject"}
                />
            </Row>

            <Text
                variant="h4"
                sx={{
                    width: "100%",
                    minWidth: "max-content",
                    textAlign: "center",
                    justifyContent: "center"
                }}
            >
                {header}
            </Text>
            <Row sx={{ width: "100%", justifyContent: "flex-end" }}>{rowEnd} </Row>
        </Row>
    );
}
