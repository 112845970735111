import { StatColumn, StatProps } from "@bridgesplit/ui";
import { BsMetaUtil, formatLeverage, getLoopBorrowCapDetails } from "@bridgesplit/abf-react";
import { formatPercent, formatUsd } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useLoopContext } from "../LoopContext";
import { BorrowCapWarning } from "../../common";

export default function LoopDetailStats() {
    const { loopExpanded } = useLoopContext();
    const borrowCapWarning = getLoopBorrowCapDetails(loopExpanded);

    const stats: StatProps[] = [
        {
            value: formatPercent(loopExpanded?.loopVault.collateralApyPct),
            caption: `Base ${BsMetaUtil.getSymbol(loopExpanded?.collateralToken)} APY`
        },
        {
            value: formatPercent(loopExpanded?.loopVault.maxLeveragedApyPct),
            caption: loopExpanded
                ? `${formatLeverage(loopExpanded?.loopVault.maxLeverage)} ${COPY.LEVERAGE_TERM.toLowerCase()} APY`
                : "Max APY"
        },
        {
            value: formatUsd(loopExpanded?.collateralOracle.getUsdAmount(loopExpanded.loopVault.collateralDeposited)),
            caption: COPY.LOOP_COLLATERAL_DEPOSIT_TERM
        },
        {
            value: (
                <BorrowCapWarning borrowCapWarning={borrowCapWarning?.warningMessage}>
                    {formatUsd(
                        loopExpanded?.principalOracle.getUsdAmount(
                            Math.min(
                                loopExpanded?.loopVault?.principalAmountAvailable,
                                borrowCapWarning?.remainingGlobalCapacity ?? 0
                            )
                        )
                    )}
                </BorrowCapWarning>
            ),
            caption: COPY.LOOP_PRINCIPAL_AVAILABLE_TERM
        }
    ];

    return <StatColumn variant="body1" stats={stats} loading={!loopExpanded} />;
}
