import {
    useActiveEscrow,
    useRepayLoanTransaction,
    AbfLoanExpanded,
    useLedgerAccountsSyncQuery,
    summarizeLedgerAccounts,
    useAbfFetches,
    useAbfTypesToUiConverter,
    useSyncLedgersTransaction,
    TransactionSenderOptions,
    getLoanEndTime,
    useRepayZcLoanTransaction,
    LoanRepaymentParam,
    isSimpleLoan,
    TRANSACTION_DEFAULT_BATCH_COMMITMENT,
    useIsLoanEscrowBased,
    getLoanInterestDue,
    LoanInterestDueInfo,
    LoanInterestDueParams,
    getNextLedgerAccount
} from "@bridgesplit/abf-react";
import { AbfLedgerAccount, AbfLoanInfo, RepaymentType } from "@bridgesplit/abf-sdk";
import { LOADING_ERROR, NullableRecord, Result, doNothing, filterNullableRecord, getUnixTs } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TransactionResult, useTransactionsState } from "@bridgesplit/react";
import { TrackTransactionEvent } from "app/types";

import { allTransactionsSucceeded, useTransactionSender } from "../../../transactions";
import { MakeRepaymentParams, SubmitRepayParams, SubmitZcRepayParams } from "./types";
import { LedgersPaymentInfo } from "../../type";

export function useZcRepay(props: MakeRepaymentParams) {
    const { loanExpanded, payment, isFullRepay } = props;
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const repay = useRepayZcLoanTransaction();

    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);

    const executeWithRefresh = useExecuteWithRefresh();

    async function zcRepay() {
        if (!loanExpanded || !activeEscrow) return Result.errFromMessage(LOADING_ERROR);

        if (!isSimpleLoan(props.loanExpanded))
            return Result.errWithDebug(LOADING_ERROR, "Cannot repay non-zero coupon loan");

        const { principalToPay, interestToPay, feesToPay } = payment;

        const payments: LoanRepaymentParam[] = [];

        if (principalToPay && nextLedgerAccount) {
            payments.push({
                ledger: nextLedgerAccount,
                amount: principalToPay,
                overpay: false,
                repaymentType: RepaymentType.Principal
            });
        }

        if (interestToPay && nextLedgerAccount) {
            payments.push({
                ledger: nextLedgerAccount,
                amount: interestToPay,
                overpay: false,
                repaymentType: RepaymentType.Interest
            });
        }

        if (!payments.length && !feesToPay) {
            return Result.errWithDebug(LOADING_ERROR, "No amount to pay");
        }

        const params: SubmitZcRepayParams = {
            loan: loanExpanded.loan,
            order: loanExpanded.order,
            repayerEscrowAccount: activeEscrow,
            fullRepay: !!isFullRepay,
            feeAmount: feesToPay,
            escrowNeeded,
            payments
        };

        return await send(repay, params, {
            description: "Repaying loan",
            sendOptions: { refetch: doNothing },
            mixpanelEvent: { key: TrackTransactionEvent.SubmitRepay, params }
        });
    }

    return {
        repay: () => executeWithRefresh(zcRepay)
    };
}

export function useSingleRepay(props: MakeRepaymentParams) {
    const {
        loanExpanded,
        payment: { principalToPay, interestToPay }
    } = props;
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();

    const repay = useRepayLoanTransaction();

    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);

    const syncLedgersIfNeeded = useSyncLedgersIfNeeded(loanExpanded);

    const repayLedgerInterest = useRepayLedgerInterest(props);
    const executeWithRefresh = useExecuteWithRefresh();

    async function singleRepay() {
        if (!nextLedgerAccount) return Result.errFromMessage("Payments have all been made");
        if (!loanExpanded || !activeEscrow) return Result.errFromMessage(LOADING_ERROR);

        const ledgerPrincipalOutstanding = Math.max(
            nextLedgerAccount.principalDue - nextLedgerAccount.principalPaid,
            0
        );

        if (ledgerPrincipalOutstanding > 0) {
            const payments: LoanRepaymentParam[] = [
                {
                    ledger: nextLedgerAccount,
                    amount: principalToPay,
                    overpay: false,
                    repaymentType: RepaymentType.Principal
                }
            ];

            const params: SubmitRepayParams = {
                payments,
                loan: loanExpanded.loan,
                order: loanExpanded.order,
                repayerEscrowAccount: activeEscrow,
                escrowNeeded,
                fullRepay: props.isFullRepay
            };

            const repayRes = await send(repay, params, {
                description: "Repaying Principal",
                isMultiStep: true,
                sendOptions: {
                    refetch: doNothing,
                    allowBatchFailure: false,
                    commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT
                },
                mixpanelEvent: { key: TrackTransactionEvent.RepayPrincipal, params }
            });
            if (!allTransactionsSucceeded(repayRes)) return repayRes;
            const syncRes = await syncLedgersIfNeeded(undefined);
            if (!allTransactionsSucceeded(syncRes)) return syncRes;
        }

        const repayRes = await repayLedgerInterest("Repaying Interest", interestToPay);
        if (!allTransactionsSucceeded(repayRes)) return repayRes;
        const syncRes = await syncLedgersIfNeeded(undefined);
        if (!allTransactionsSucceeded(syncRes)) return syncRes;
        return repayRes;
    }

    return {
        repay: () => executeWithRefresh(singleRepay),
        disabled: !nextLedgerAccount || !loanExpanded || !activeEscrow
    };
}

export function usePrincipalRepay(props: MakeRepaymentParams) {
    const {
        loanExpanded,
        payment: { principalToPay }
    } = props;
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();

    const repay = useRepayLoanTransaction();

    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);
    const executeWithRefresh = useExecuteWithRefresh();

    const syncLedgersIfNeeded = useSyncLedgersIfNeeded(loanExpanded);
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    async function principalRepay() {
        if (!principalToPay || !nextLedgerAccount || !loanExpanded || !activeEscrow)
            return Result.errFromMessage(LOADING_ERROR);

        const repayRes = await send(
            repay,
            {
                loan: loanExpanded.loan,
                order: loanExpanded.order,
                repayerEscrowAccount: activeEscrow,
                escrowNeeded,
                payments: [
                    {
                        ledger: nextLedgerAccount,
                        amount: principalToPay,
                        overpay: true,
                        repaymentType: RepaymentType.Principal
                    }
                ],
                fullRepay: props.isFullRepay
            },
            {
                isMultiStep: true,
                sendOptions: { refetch: doNothing, commitmentLevel: "confirmed", allowBatchFailure: false }
            }
        );
        if (!allTransactionsSucceeded(repayRes)) return repayRes;

        return await syncLedgersIfNeeded();
    }

    return {
        principalRepay: () => executeWithRefresh(principalRepay)
    };
}

export function useRepayAll(props: MakeRepaymentParams) {
    const {
        loanExpanded,
        payment: { principalToPay }
    } = props;
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();

    const repay = useRepayLoanTransaction();
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);

    const executeWithRefresh = useExecuteWithRefresh();

    const syncLedgersIfNeeded = useSyncLedgersIfNeeded(loanExpanded);
    const repayLedgers = useRepayAdditionalLedgers(props);

    const loanEndTime = getLoanEndTime(loanExpanded, false);

    async function repayAll() {
        if (!loanExpanded || !activeEscrow || !nextLedgerAccount || !loanEndTime)
            return Result.errFromMessage(LOADING_ERROR);

        const principalRepaymentParams = getRepayAllPrincipalParams({
            totalPrincipalOwed: principalToPay,
            nextLedgerAccount
        });

        const params: SubmitRepayParams = {
            payments: principalRepaymentParams,
            order: loanExpanded.order,
            escrowNeeded,
            loan: loanExpanded.loan,
            repayerEscrowAccount: activeEscrow,
            fullRepay: props.isFullRepay
        };

        if (principalToPay) {
            const repayRes = await send(repay, params, {
                description: "Repaying Loan",
                isMultiStep: true,
                sendOptions: {
                    refetch: doNothing,
                    allowBatchFailure: false,
                    commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT
                },
                mixpanelEvent: { key: TrackTransactionEvent.SubmitRepay, params }
            });
            if (!allTransactionsSucceeded(repayRes)) return repayRes;
        }

        const syncRes = await syncLedgersIfNeeded();
        if (!allTransactionsSucceeded(syncRes)) return syncRes;
        return await repayLedgers("Finalize Payments");
    }

    return { repayAll: () => executeWithRefresh(repayAll) };
}

// Payments must be manually refetched to prevent race conditions
function useExecuteWithRefresh() {
    const {
        resetLoanApi,
        resetEscrowedApi,
        resetLockboxApi,
        resetFeesApi,
        resetLedgerAccounts,
        resetLedgerAccountsSync
    } = useAbfFetches();
    const { setTransactionsLoading } = useTransactionsState();

    return async <T>(execute: () => Promise<T>) => {
        const res = await execute();

        setTransactionsLoading(false);
        resetLedgerAccounts();
        resetLoanApi();
        resetEscrowedApi();
        resetLockboxApi();
        resetLedgerAccountsSync();
        resetFeesApi();
        return res;
    };
}

function useSyncLedgersIfNeeded(loanExpanded: AbfLoanExpanded | undefined) {
    const send = useTransactionSender();

    const syncLedgers = useSyncLedgersTransaction();

    const { checkLedgerAccountsSync } = useAbfFetches();
    const orderAddress = loanExpanded?.order.address;

    return async (sendOptions?: TransactionSenderOptions): Promise<Result<TransactionResult[]>> => {
        if (!orderAddress) return Result.errFromMessage(LOADING_ERROR);
        const ledgersSynced = await checkLedgerAccountsSync(orderAddress, false).unwrap();
        if (ledgersSynced === true) {
            return Result.ok([]);
        }
        return await send(
            syncLedgers,
            { orderAddress },
            { sendOptions: { refetch: doNothing, ...sendOptions }, isMultiStep: true }
        );
    };
}

function useRefreshedLedgers(loanExpanded: AbfLoanExpanded | undefined) {
    // fetch fresh loan info to determine the correct ledger accounts
    const { fetchLoanInfos, checkLedgerAccountsSync } = useAbfFetches();

    const { convertLoan, convertLedgerAccount, convertOrder, convertOrderSchedule } = useAbfTypesToUiConverter([
        loanExpanded?.order.principalMint
    ]);

    return async function getRefreshedLedgers(
        loanExpanded: AbfLoanExpanded
    ): Promise<Result<{ newLedgersInfo: LedgersPaymentInfo; loanInfo: AbfLoanInfo }>> {
        const [loanRes, ledgerAccountsSync] = await Promise.all([
            fetchLoanInfos({ loan_addresses: [loanExpanded.loan.address] }).unwrap(),
            checkLedgerAccountsSync(loanExpanded.order.address).unwrap()
        ]);
        const newLoan = loanRes.loans[loanExpanded.loan.address];
        const loanInfo: AbfLoanInfo = {
            order: convertOrder(newLoan.order),
            loan: convertLoan(newLoan.loan),
            ledgerAccounts: Object.values(newLoan.ledgerAccounts).map((l) =>
                convertLedgerAccount(l, newLoan.order.principalMint)
            ),
            orderSchedule: convertOrderSchedule(newLoan.orderSchedule)
        };
        const newLedgersInfo = getLedgersPaymentInfo(loanInfo as AbfLoanExpanded);

        if (!ledgerAccountsSync) {
            return Result.err("Unable to complete repayment. Try again");
        }

        return Result.ok({ newLedgersInfo, loanInfo });
    };
}

function useRepayLedgerInterest({ loanExpanded, isFullRepay }: MakeRepaymentParams) {
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();

    const repay = useRepayLoanTransaction();

    const getRefreshedLedgers = useRefreshedLedgers(loanExpanded);
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const { nextLedgerAccount: ledgerAccount } = getNextLedgerAccount(loanExpanded);
    return async (description: string, amount: number): Promise<Result<TransactionResult[]>> => {
        if (!loanExpanded || !activeEscrow || !ledgerAccount) return Result.errFromMessage(LOADING_ERROR);

        const newLedgersInfoResult = await getRefreshedLedgers(loanExpanded);

        if (newLedgersInfoResult.isErr()) {
            return Result.err(newLedgersInfoResult);
        }
        const { newLedgersInfo } = newLedgersInfoResult.unwrap();

        if (newLedgersInfo?.nextLedgerAccount?.address !== ledgerAccount.address) {
            // this means ledger account was repaid/unsynced before, you need to repay on the next ledger.
            return Result.err("Unable to complete repayment. Try again");
        }

        const payments = getRepayInterestParamsForLedger({ ledgerAccount, amount });
        if (payments.length > 0) {
            return await send(
                repay,
                {
                    payments,
                    order: loanExpanded.order,
                    escrowNeeded,
                    loan: loanExpanded.loan,
                    repayerEscrowAccount: activeEscrow,
                    fullRepay: isFullRepay
                },
                {
                    description,
                    isMultiStep: true,
                    sendOptions: { refetch: doNothing, allowBatchFailure: false, commitmentLevel: "confirmed" }
                }
            );
        } else {
            return Result.ok([]);
        }
    };
}

function useRepayAdditionalLedgers({ loanExpanded, isFullRepay }: MakeRepaymentParams) {
    const send = useTransactionSender();
    const { activeEscrow } = useActiveEscrow();
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const repay = useRepayLoanTransaction();

    const getRefreshedLedgers = useRefreshedLedgers(loanExpanded);

    return async (description: string) => {
        if (!loanExpanded || !activeEscrow) return Result.errFromMessage(LOADING_ERROR);
        const newLedgersInfoResult = await getRefreshedLedgers(loanExpanded);

        if (newLedgersInfoResult.isErr()) {
            return Result.err(newLedgersInfoResult);
        }
        const { newLedgersInfo } = newLedgersInfoResult.unwrap();
        if (!newLedgersInfo.nextDueLedgerAccount) {
            return Result.err("Unable to complete repayment. Try again");
        }

        const payments = getRepayAllInterestParams({
            unfilledLedgerAccounts: newLedgersInfo.unfilledLedgerAccounts
        });
        return await send(
            repay,
            {
                payments,
                order: loanExpanded.order,
                escrowNeeded,
                loan: loanExpanded.loan,
                repayerEscrowAccount: activeEscrow,
                fullRepay: isFullRepay
            },
            {
                description,
                isMultiStep: true,
                sendOptions: { refetch: doNothing, allowBatchFailure: false, commitmentLevel: "confirmed" }
            }
        );
    };
}

export function getRepayInterestParamsForLedger(params: {
    ledgerAccount: AbfLedgerAccount;
    amount?: number; // if no amount repay full
}): LoanRepaymentParam[] {
    const { ledgerAccount, amount } = params;

    const repaymentParams: LoanRepaymentParam[] = [];
    const interestDue = Math.max(ledgerAccount.interestDue - ledgerAccount.interestPaid, 0);

    const amountInterestToPay = amount !== undefined ? Math.min(amount, interestDue) : interestDue;

    if (amountInterestToPay > 0) {
        repaymentParams.push({
            ledger: ledgerAccount,
            amount: amountInterestToPay,
            overpay: false,
            repaymentType: RepaymentType.Interest
        });
    }

    return repaymentParams;
}

export function getRepayPrincipalAndInterestParamsForLedger(params: {
    ledgerAccount: AbfLedgerAccount;
    overPayInterest?: boolean;
    overPayPrincipal?: boolean;
    amount?: number; // if no amount repay full
}): LoanRepaymentParam[] {
    const repaymentParams: LoanRepaymentParam[] = [];

    const { ledgerAccount, overPayPrincipal, overPayInterest, amount } = params;

    if (ledgerAccount.isFilled) {
        return repaymentParams;
    }

    const principalDue = Math.max(ledgerAccount.principalDue - ledgerAccount.principalPaid, 0);
    const interestDue = Math.max(ledgerAccount.interestDue - ledgerAccount.interestPaid, 0);

    const amountPrincipalToPay = amount ? Math.min(amount, principalDue) : principalDue;

    const amountInterestToPay = amount
        ? Math.min(Math.max(amount - amountPrincipalToPay, 0), interestDue)
        : interestDue;

    repaymentParams.push({
        ledger: ledgerAccount,
        amount: amountPrincipalToPay,
        overpay: overPayPrincipal ?? false,
        repaymentType: RepaymentType.Principal
    });

    // if ledger will be filled after principal repay, don't repay interest

    const willLedgerBeFilledByPrincipal = amountPrincipalToPay >= principalDue && interestDue === 0;

    if (!willLedgerBeFilledByPrincipal) {
        repaymentParams.push({
            ledger: ledgerAccount,
            amount: amountInterestToPay,
            overpay: overPayInterest ?? false,
            repaymentType: RepaymentType.Interest
        });
    }

    return repaymentParams;
}

function getRepayAllPrincipalParams(params: {
    nextLedgerAccount: AbfLedgerAccount;
    totalPrincipalOwed: number;
}): LoanRepaymentParam[] {
    const repaymentParams: LoanRepaymentParam[] = [];

    const { nextLedgerAccount, totalPrincipalOwed } = params;

    repaymentParams.push({
        ledger: nextLedgerAccount,
        amount: Math.max(totalPrincipalOwed, 0),
        overpay: true,
        repaymentType: RepaymentType.Principal
    });

    return repaymentParams;
}

function getRepayAllInterestParams(params: {
    unfilledLedgerAccounts: AbfLedgerAccount[] | undefined;
}): LoanRepaymentParam[] {
    const repaymentParams: LoanRepaymentParam[] = [];

    const { unfilledLedgerAccounts } = params;

    unfilledLedgerAccounts?.forEach((l) => {
        repaymentParams.push({
            ledger: l,
            amount: Math.max(l.interestDue - l.interestPaid, 0),
            overpay: false,
            repaymentType: RepaymentType.Interest
        });
    });

    return repaymentParams;
}

export function useLedgerSyncNeeded(loanExpanded: AbfLoanExpanded | undefined) {
    const { data, isFetching } = useLedgerAccountsSyncQuery(loanExpanded?.order.address ?? skipToken, {
        skip: !loanExpanded
    });
    const { transactionsInProgress } = useTransactionsState();

    return !isFetching && !transactionsInProgress && data === false;
}

export function getLedgersPaymentInfo(loanExpanded: AbfLoanExpanded | undefined): LedgersPaymentInfo {
    const unfilledLedgerAccounts = loanExpanded?.ledgerAccounts
        .filter((l) => !l.isFilled)
        .sort((a, b) => a.dueTimeOffset - b.dueTimeOffset);

    const nextLedgerAccount = unfilledLedgerAccounts?.[0];

    const { principalDue, principalPaid, principalOutstanding } = summarizeLedgerAccounts(loanExpanded);

    unfilledLedgerAccounts?.sort((a, b) => a.ledgerId - b.ledgerId);

    const lateLedgerAccounts = unfilledLedgerAccounts?.filter(
        (l) => l.dueTimeOffset + (loanExpanded?.loan.loanStartTime ?? 0) < getUnixTs()
    );

    const lateLedgerAccountIds = new Set(lateLedgerAccounts?.map((l) => l.ledgerId));

    const futureLedgerAccounts = unfilledLedgerAccounts?.filter((l) => !lateLedgerAccountIds?.has(l.ledgerId));
    const nextDueLedgerAccount = futureLedgerAccounts?.[0];

    const finalLedgerAccount = unfilledLedgerAccounts?.[unfilledLedgerAccounts.length - 1];

    return {
        futureLedgerAccounts,
        nextDueLedgerAccount,
        unfilledLedgerAccounts,
        lateLedgerAccounts,
        principalPaid,
        nextLedgerAccount,
        principalDue,
        principalOutstanding,
        finalLedgerAccount
    };
}

export function calculateNewLoanInterestDue(params: NullableRecord<LoanInterestDueParams>): LoanInterestDueInfo {
    if (!filterNullableRecord(params)) {
        const originalLedgers = summarizeLedgerAccounts(params.loanExpanded, params.ledgerAccounts);

        return {
            repaymentAmount: params.repaymentAmount ?? 0,
            newFeeAmount: 0,
            principalToPay: 0,
            interestToPay: 0,
            feesToPay: 0,
            newTotalInterestOutstanding: originalLedgers.interestOutstanding,
            isInterestRecalculated: false,
            newTotalInterestDue: originalLedgers.interestDue
        };
    }

    const newLoanInterest = getLoanInterestDue(params);

    return newLoanInterest;
}
