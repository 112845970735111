import { FONT_WEIGHTS, Icon, MEDIA, Row, TextLink } from "@bridgesplit/ui";

import { DESKTOP_PADDING, NAV_BREAKPOINT } from "./constants";
import NavOptions from "./NavOptions";
import ProfileButton from "./ProfileButton";
import NavbarWallet from "./NavbarWallet";
import { useNavOptions } from "./util";
import DialectNotificationButton from "./DialectNotificationButton";
import { NavPoints } from "./points";

const WIDE_BREAKPOINT = MEDIA.XL; // Less than XL, center based on space between instead of perfectly centered
export default function DesktopNavbar() {
    const navOptions = useNavOptions();

    return (
        <Row
            sx={{
                p: DESKTOP_PADDING,
                [NAV_BREAKPOINT.below]: { display: "none" },
                [WIDE_BREAKPOINT.below]: { justifyContent: "space-between" }
            }}
        >
            <Row sx={{ [WIDE_BREAKPOINT.above]: { width: "100%" } }} spacing={1}>
                <TextLink variant="h4" textSx={{ width: "max-content", fontWeight: FONT_WEIGHTS.logo }} to="/">
                    <Icon type="logo" />
                </TextLink>
            </Row>
            <Row spacing={0.5}>
                <NavOptions options={navOptions} />
            </Row>

            <Row sx={{ [WIDE_BREAKPOINT.above]: { width: "100%", justifyContent: "flex-end" } }} spacing={1}>
                <NavbarWallet />
                <DialectNotificationButton />
                <NavPoints />
                <ProfileButton />
            </Row>
        </Row>
    );
}
