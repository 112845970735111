import { AbfOrder, AbfOrderSchedule } from "./order";

export interface AbfLedgerAccountResponse {
    ledgerAccounts: AbfLedgerAccount[];
    orders: Record<string, AbfOrder>;
    loans: Record<string, AbfLoanVault>;
}

export interface AbfLoanInfo {
    loan: AbfLoanVault;
    ledgerAccounts: AbfLedgerAccount[];
    order: AbfOrder;
    orderSchedule: AbfOrderSchedule;
}

export interface AbfLedgerAccountInfo {
    loan: AbfLoanVault;
    ledgerAccount: AbfLedgerAccount;
}

export interface AbfLoanVault {
    id: number;
    order: string;
    lender: string;
    borrower: string;
    collateral: string;
    debtNote: string;
    creditNote: string;
    creditClaimed: boolean;
    debtClaimed: boolean;
    loanStartTime: number;
    txn: string;
    address: string;
    numFeesOutstanding: number | null;
    feesReceived: number | null;
    lastInteractedTime: number;
    refinancedFrom: string | null;
    refinancedTo: string | null;
}

export interface AbfLedgerAccount {
    id: number;
    address: string;
    ledgerId: number;
    orderAddress: string;
    principalDue: number;
    principalPaid: number;
    interestDue: number;
    interestPaid: number;
    dueTimeOffset: number;
    paidTime: number;
    paymentType: AbfLedgerPaymentType;
    isFilled: boolean;
    gracePeriod?: number; // grace period in seconds for the ledger. Ledger is not defaulted until grace period has passed.
    latePaymentFee?: number;
    earlyPaymentFee?: number;
    paymentWindow?: number;
    orderLedgersAddress: string;
    lastInteractedTime: number | null;
}

export enum AbfLedgerPaymentType {
    Base,
    Fee
}

export enum LedgerPaymentType {
    Payment = "Payment",
    Prepayment = "Prepayment"
}

export type LedgerPaymentEvent = {
    ledgerId: number;
    ledgerAddress: string;
    timestamp: number;
    txn: string;
    principalPaid: number;
    interestPaid: number;
    paymentType: LedgerPaymentType;
    loan: string;
};

export type FeePaymentEvent = {
    feeAccount: string;
    timestamp: number;
    txn: string;
    amount: number;
    loan: string;
};

export type PaymentEvent = { Ledger: LedgerPaymentEvent } | { Fee: FeePaymentEvent };
