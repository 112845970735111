import { useState, useEffect, useMemo, useCallback } from "react";

import {
    BsMetaUtil,
    Collateral,
    DEFAULT_PRESET,
    RoleView,
    calculateHealthRatio,
    calculateLiquidationPrice,
    getOffsetDetailsFromPreset,
    getPaymentScheduleFromDates,
    isStakedSol,
    useActiveEscrow,
    useActiveWallet,
    useEscrowPreference,
    useOraclePrices,
    useStakedSolByWallet,
    useSummarizedUserAssets
} from "@bridgesplit/abf-react";
import {
    NullableRecord,
    SOL_DECIMALS,
    bsMath,
    calculatePercentChange,
    decimalsToBps,
    filterNullableRecord,
    findMinElement,
    percentDecimalsToUi,
    removeDuplicatesByProperty,
    roundDownToDecimals
} from "@bridgesplit/utils";
import { useSearchParams } from "react-router-dom";
import { CompoundingFrequency, formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { AppParams } from "app/utils";

import { useMarketContext, useQuotesContext } from "../common";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { BorrowMode } from "./type";
import { useBalanceChecker } from "../../common";
import { getDefaultCollateral, useMarketCapDetails, useMarketCollateralFromProps, useOpenGuideOnLoad } from "../util";
import { MarketProps } from "../types";
import { useTrack } from "../../../hooks";
import { TrackEventWithProps } from "../../../types";

export function useMarketBorrowHooks(options?: { setDefaultDuration?: boolean }) {
    useMaintainBorrowLtv();
    useInitializeMarketBorrowCollateral();
    useInitializeMarketPreset(options);
    useOpenGuideOnLoad({ view: RoleView.Borrow });
}

export function useInitializeMarketPreset(options?: { setDefaultDuration?: boolean }) {
    const { setForm, presets } = useMarketBorrowContext();
    const [initialized, setInitialized] = useState(false);
    const [search] = useSearchParams();
    const termShortHand = search.get(AppParams.Term);

    const presetFromParams = useMemo(() => {
        return presets?.find((p) => {
            if (formatDurationWithTypeShorthand(p.duration, p.durationType) === termShortHand) return true;

            if (
                options?.setDefaultDuration &&
                DEFAULT_PRESET.durationType === p.durationType &&
                DEFAULT_PRESET.duration === p.duration
            )
                return true;

            return false;
        });
    }, [options?.setDefaultDuration, presets, termShortHand]);

    useEffect(() => {
        if (!presetFromParams || !!initialized) return;
        setInitialized(true);

        setForm((prev) => ({ ...prev, preset: presetFromParams }));
    }, [initialized, presetFromParams, presets, setForm, termShortHand]);
}

export function useMarketBorrowCollateral() {
    const { form, userCollateral } = useMarketBorrowContext();
    const activeCollateral = userCollateral?.find((c) => c.mint === form.collateralMint);

    return { userCollateral, activeCollateral };
}

export function useMaxBorrowAmount() {
    const { maxQuote } = useMarketBorrowContext();

    const maxGlobalBorrow = useMaxGlobalAvailableBorrow();

    if (!maxQuote) {
        return { maxBorrow: 0, maxGlobalBorrow };
    }
    let maxBorrow = maxQuote.maxBorrow;

    if (maxGlobalBorrow !== undefined) {
        maxBorrow = Math.min(maxBorrow, maxGlobalBorrow);
    }
    return { maxBorrow, maxGlobalBorrow };
}

export function useMaxGlobalAvailableBorrow() {
    const { borrowCap } = useMarketContext();

    const borrowCapDetails = useMarketCapDetails();
    const remainingGlobalCapacity = borrowCapDetails?.remainingGlobalCapacity;

    let maxGlobalBorrow: number | undefined;
    if (borrowCap) {
        maxGlobalBorrow = borrowCap.perLoan;
    }
    if (remainingGlobalCapacity !== undefined) {
        if (maxGlobalBorrow === undefined) {
            maxGlobalBorrow = remainingGlobalCapacity;
        } else {
            maxGlobalBorrow = Math.min(maxGlobalBorrow, remainingGlobalCapacity);
        }
    }

    return maxGlobalBorrow;
}

export function useBorrowStats() {
    const { bestQuote, prices } = useMarketBorrowContext();

    const ltv = bsMath.div(prices.principalAmountUsd, prices.collateralAmountUsd);
    const liquidationThreshold = bestQuote?.liquidationThreshold;

    return {
        liquidationThreshold,
        ltv,
        insufficientCollateral: decimalsToBps(ltv) - decimalsToBps(bestQuote?.ltv) > 5 //  small bps difference might occur due to rounding
    };
}

export function useStakeCollateral() {
    const { activeWallet } = useActiveWallet();

    const { escrowNeeded } = useEscrowPreference();
    const { activeEscrow } = useActiveEscrow();

    const { data: stakedSol } = useStakedSolByWallet(escrowNeeded ? activeEscrow : activeWallet?.wallet);

    function findMinStakeAmount(minCollateralAmount: number | undefined) {
        const validStakes = stakedSol?.filter((s) => s.amount + s.rentExemptReserve >= (minCollateralAmount || 0));

        const minStakeAccount = findMinElement(validStakes, (s) => s.amount + s.rentExemptReserve);

        const minStakeAmount = bsMath.tokenAdd(
            minStakeAccount?.amount,
            minStakeAccount?.rentExemptReserve,
            SOL_DECIMALS
        );

        return { validStakes, minStakeAmount, minStakeAccount };
    }

    return { findMinStakeAmount, stakedSol };
}

export function useBorrowBalanceChecker() {
    const { form, activeUserCollateral, setForm } = useMarketBorrowContext();

    return useBalanceChecker({
        mint: form.collateralMint,
        setMax: (maxAmount) =>
            setForm((prev) => ({
                ...prev,
                collateralAmount: maxAmount,
                refetchOutAmount: true,
                mode: BorrowMode.InputCollateral
            })),
        amount: form.collateralAmount,
        customBalance: isStakedSol(form.collateralMint)
            ? bsMath.add(form.stakeAccount?.amount, form.stakeAccount?.rentExemptReserve)
            : activeUserCollateral?.amount
    });
}

export function useBorrowLtv() {
    const { form, bestQuote, maxQuote, prices } = useMarketBorrowContext();

    const { maxBorrow } = useMaxBorrowAmount();

    return useMemo(() => {
        // used to set LTV multiplier if user inputs number first without ltv selection
        const ltvMultiplierFallback = form.amount && maxBorrow ? form.amount / maxBorrow : 0;
        const ltvMultiplier = form.ltvMultiplier || ltvMultiplierFallback;

        const ltv =
            prices.principalAmountUsd && prices.collateralAmountUsd
                ? prices.principalAmountUsd / prices.collateralAmountUsd
                : 0;

        const ltvFromQuote = bestQuote?.ltv ?? maxQuote?.ltv;

        return { ltv, ltvMultiplier, ltvFromQuote };
    }, [
        bestQuote?.ltv,
        form.amount,
        form.ltvMultiplier,
        maxBorrow,
        maxQuote?.ltv,
        prices.collateralAmountUsd,
        prices.principalAmountUsd
    ]);
}

// initialize based on custom mint or just the first token
export function useInitializeMarketBorrowCollateral() {
    const { form, setForm, allCollateral } = useMarketBorrowContext();
    const { principalMint } = useMarketContext();
    const [search] = useSearchParams();
    const specifiedMint = search.get(AppParams.CollateralMint) ?? getDefaultCollateral(principalMint);

    const allowedMints = useMemo(
        () => new Set(allCollateral?.map((c) => c.assetMint).filter((mint) => mint !== principalMint)),
        [allCollateral, principalMint]
    );

    const mintToSet = useMemo(() => {
        if (specifiedMint && allowedMints.has(specifiedMint)) return specifiedMint;
        return allowedMints.values().next().value;
    }, [allowedMints, specifiedMint]);

    useEffect(() => {
        if (!mintToSet) return;
        if (form.collateralMint && form.collateralMint !== principalMint && allowedMints.has(form.collateralMint))
            return;

        setForm((prev) => ({ ...prev, collateralMint: mintToSet }));
    }, [allowedMints, form.collateralMint, mintToSet, principalMint, setForm]);
}

export function useMaintainBorrowLtv() {
    const { form, setForm, prices, bestQuote, quoteFetching, activeUserCollateral } = useMarketBorrowContext();

    const { findMinStakeAmount } = useStakeCollateral();
    const { token } = useMarketContext();
    const collateralDecimals = useMemo(
        () => activeUserCollateral?.metadata.decimals,
        [activeUserCollateral?.metadata.decimals]
    );

    const { ltvFromQuote: ltv, ltvMultiplier } = useBorrowLtv();

    const isNft = BsMetaUtil.isNonFungible(activeUserCollateral?.metadata);

    useEffect(() => {
        if (!form.refetchOutAmount || !!quoteFetching || !ltv) return;
        // allow free edit of principal or collateral
        if (form.mode === BorrowMode.InputCollateral || form.mode === BorrowMode.InputPrincipal) {
            if (!prices.collateralAmountUsd || !prices.principalAmountUsd || !bestQuote) return;

            const maxLtv = bestQuote.ltv;
            const ltv = prices.principalAmountUsd / prices.collateralAmountUsd;
            const ltvMultiplier = ltv / maxLtv;

            return setForm((prev) => ({
                ...prev,
                ltvMultiplier,
                refetchOutAmount: false
            }));
        }

        if (form.mode === BorrowMode.InputLtv) {
            if (!prices.collateralAmountUsd || !prices.principalPriceUsd) return;

            const principalAmountUsd = prices.collateralAmountUsd * ltv * ltvMultiplier;
            const principalAmount = principalAmountUsd / prices.principalPriceUsd;

            return setForm((prev) => ({
                ...prev,
                amount: roundDownToDecimals(principalAmount, token?.decimals),
                refetchOutAmount: false
            }));
        }
    }, [
        activeUserCollateral?.amount,
        bestQuote,
        collateralDecimals,
        findMinStakeAmount,
        form.amount,
        form.collateralMint,
        form.mode,
        form.refetchOutAmount,
        isNft,
        ltv,
        ltvMultiplier,
        prices.collateralAmountUsd,
        prices.collateralPriceUsd,
        prices.principalAmountUsd,
        prices.principalPriceUsd,
        quoteFetching,
        setForm,
        token?.decimals
    ]);
}

export function useBorrowTimeOffsets() {
    const { form } = useMarketBorrowContext();

    return useMemo(() => (form.preset ? getOffsetDetailsFromPreset(form.preset) : undefined), [form.preset]);
}

export function useUserCollateralForMarket(marketProps: MarketProps, options?: { includeLpPositions?: boolean }) {
    const { balanceMap, balances } = useSummarizedUserAssets({
        includeStakedSol: true,
        includeLpPositions: options?.includeLpPositions
    });
    const { custodianIdentifier, isCustodian } = marketProps;

    const tokens = useMarketCollateralFromProps(marketProps);
    const { getOracle } = useOraclePrices(tokens?.map((t) => t.assetMint));

    // only include non token list balances for custodian explore
    const custodianBalances = useMemo(
        () =>
            isCustodian
                ? balances
                      ?.filter(({ metadata }) => metadata.assetOriginator === custodianIdentifier)
                      .map((c): NullableRecord<Collateral> => {
                          const oracle = getOracle(c.mint);
                          return {
                              ...c,
                              oracle,
                              usdPrice: oracle?.usdPrice ?? 0,
                              usdValue: oracle?.getUsdAmount(c.amount)
                          };
                      })
                      .filter(filterNullableRecord)
                : [],
        [balances, custodianIdentifier, getOracle, isCustodian]
    );

    const tokensWithAnyMetadata =
        custodianBalances && tokens ? [...tokens, ...custodianBalances.map((c) => c.metadata)] : tokens;

    const data = useMemo(() => {
        if (!tokens || !balanceMap || !custodianBalances) return undefined;

        const tokenList = tokens
            .map((t): NullableRecord<Collateral> => {
                const oracle = getOracle(t.assetMint) ?? null;
                const userBalance = balanceMap.get(t.assetMint);
                return {
                    mint: t.assetMint,
                    metadata: t,
                    oracle,
                    amount: userBalance?.available ?? 0,
                    usdValue: userBalance?.availableUsd ?? 0,
                    usdPrice: oracle?.usdPrice ?? 0
                };
            })
            .filter(filterNullableRecord);

        const allTokens = removeDuplicatesByProperty([...tokenList, ...custodianBalances], "mint");

        return allTokens.sort((a, b) => b.amount - a.amount);
    }, [balanceMap, custodianBalances, getOracle, tokens]);

    const owned = useMemo(() => (balances ? data?.filter((d) => !!d.amount) : undefined), [balances, data]);

    return { data, owned, isLoading: !balances || !tokens, tokens: tokensWithAnyMetadata };
}

export function useMarketBorrowSetCollateral() {
    const { setForm } = useMarketBorrowContext();

    const { setCollateralMints: setQuoteCollateralMint } = useQuotesContext();

    const [, setSearch] = useSearchParams();
    const { trackWithProps } = useTrack();

    return useCallback(
        (collateral: Collateral) => {
            const {
                metadata: { assetMint: collateralMint }
            } = collateral;

            trackWithProps(TrackEventWithProps.SelectBorrowCollateral, {
                tokenSymbol: BsMetaUtil.getSymbol(collateral.metadata)
            });
            setForm((prev) => ({ ...prev, collateralMint, refetchOutAmount: true }));
            setQuoteCollateralMint([collateralMint]);
            setSearch((prev) => {
                prev.set(AppParams.CollateralMint, collateralMint);
                return prev;
            });
        },
        [setForm, setQuoteCollateralMint, setSearch, trackWithProps]
    );
}

export function useScheduleDetails() {
    const { form, bestQuote } = useMarketBorrowContext();
    const offsets = useBorrowTimeOffsets();

    return useMemo(() => {
        if (!offsets || !form.amount || !bestQuote) return undefined;

        const { timeOffsets, lastOffset, loanEndDate } = offsets;

        const { totalInterest } = getPaymentScheduleFromDates({
            loanDurationSeconds: lastOffset,
            timeOffsets,
            principal: form.amount,
            apy: percentDecimalsToUi(bestQuote.apy),
            compoundingFrequency: CompoundingFrequency.Continuous
        });

        return { totalInterest, loanEndDate };
    }, [bestQuote, form.amount, offsets]);
}

export function useSetBorrowerPreset() {
    const { setForm, presets } = useMarketBorrowContext();
    const { setPreset: setQuotePreset } = useQuotesContext();
    const [, setSearch] = useSearchParams();
    const { trackWithProps } = useTrack();

    const setPreset = useCallback(
        (presetStrategyIdentifier: string) => {
            const preset = presets?.find((p) => p.presetStrategyIdentifier === presetStrategyIdentifier);
            setForm((prev) => ({
                ...prev,
                preset,
                refetchOutAmount: true
            }));
            setQuotePreset(preset);
            setSearch((prev) => {
                prev.set(AppParams.Term, formatDurationWithTypeShorthand(preset?.duration, preset?.durationType));
                return prev;
            });
            trackWithProps(TrackEventWithProps.SelectBorrowDuration, {
                duration: formatDurationWithTypeShorthand(preset?.duration, preset?.durationType)
            });
        },
        [presets, setForm, setQuotePreset, setSearch, trackWithProps]
    );

    return { setPreset };
}

export function useHealthRatio() {
    const {
        bestQuote,
        prices,
        form: { amount }
    } = useMarketBorrowContext();
    const totalInterest = useScheduleDetails()?.totalInterest;

    const totalPrincipalAmount = bsMath.add(amount, totalInterest);
    const { ltv, liquidationThreshold } = useBorrowStats();
    const healthRatio = calculateHealthRatio(
        bsMath.mul(totalPrincipalAmount, prices.principalPriceUsd),
        prices.collateralAmountUsd,
        bestQuote?.liquidationThreshold
    );

    return { healthRatio, ltv, liquidationThreshold };
}

export function useLiquidationPrice() {
    const { token } = useMarketContext();

    const { form, bestQuote, prices } = useMarketBorrowContext();
    const totalInterest = useScheduleDetails()?.totalInterest;

    const liquidationPrice = calculateLiquidationPrice(
        bsMath.tokenAdd(form.amount, totalInterest, token?.decimals),
        form.collateralAmount,
        bestQuote?.liquidationThreshold
    );

    const currentPrice = bsMath.div(prices.collateralPriceUsd, prices.principalPriceUsd);
    const percentChange = calculatePercentChange(currentPrice, liquidationPrice);

    return { liquidationPrice, ltv: bestQuote?.ltv, currentPrice, percentChange };
}
