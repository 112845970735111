import { createApi } from "@reduxjs/toolkit/query/react";

import { ABF_GROUP_HEADER, CREATE_USER_VERIFICATION_SESSION, USER_IDENTITY_ROUTE } from "../constants";
import { abfSerializeQueryArgs, identityBaseQuery } from "./util";
import { UserIdentity } from "../types";

export const identityApi = createApi({
    reducerPath: "identityApi",
    baseQuery: identityBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        userIdentity: builder.query<UserIdentity, string>({
            query(groupIdentifier) {
                return {
                    url: USER_IDENTITY_ROUTE,
                    method: "GET",
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        createUserVerificationSession: builder.mutation<string, string>({
            query(idempotentKey) {
                return {
                    responseHandler: "text",
                    url: CREATE_USER_VERIFICATION_SESSION,
                    method: "POST",
                    headers: {
                        "Idempotent-Key": idempotentKey
                    }
                };
            }
        })
    })
});

export const { useUserIdentityQuery, useCreateUserVerificationSessionMutation } = identityApi;
