import { useState } from "react";

import { useApplyReferralMutation, useUserReferral } from "@bridgesplit/abf-react";
import { mutationIntoRawResult } from "@bridgesplit/react";
import { DialogWrapper, FormInput } from "@bridgesplit/ui";
import { parseErrorFromOptions, Result } from "@bridgesplit/utils";
import { AppDialog } from "app/utils";
import { BS_SHORT_URL_INVITE } from "app/constants";

import { REFERRAL_CODE_PLACEHOLDER } from "../constants";
import { AppButton, DialogHeader } from "../../common";

const ERRORS = {
    PrefineryError: "This code is valid but can't be applied to your account",
    "You cannot refer yourself": "You can't refer yourself",
    "Referral cycle detected": "You can't refer someone who has already referred you"
};

export default function ReferralEnterCodeDialog() {
    const [code, setCode] = useState<string>();
    const [applyReferral] = useApplyReferralMutation(undefined);
    const { data: referral } = useUserReferral();

    async function submit() {
        if (referral?.userReferralCode === code) {
            return Result.errFromMessage(ERRORS["You cannot refer yourself"], { skipSentry: true });
        }
        const res = await mutationIntoRawResult(applyReferral, code);

        if (res.isOk()) {
            return res;
        }
        const parsedError = parseErrorFromOptions(res.unwrapErrMessage(), ERRORS);

        if (parsedError) return Result.errFromMessage(parsedError, { skipSentry: true });
        return Result.errFromMessage("Invalid referral code");
    }

    return (
        <DialogWrapper>
            <DialogHeader
                spacing={0.5}
                header={AppDialog.ReferralEnterCode}
                description="Enter a friend's referral code below"
            />

            <FormInput
                label="Referral code"
                placeholder={REFERRAL_CODE_PLACEHOLDER}
                value={code}
                setValue={(input) => {
                    if (!input) return setCode(input);

                    // in case user accidentally pastes full ref link
                    if (input?.includes(BS_SHORT_URL_INVITE)) {
                        input = input.split(BS_SHORT_URL_INVITE + "/")[1];
                    }

                    const regex = new RegExp(`^[a-zA-Z0-9]*$`);

                    if (!regex.test(input)) return;
                    setCode(input);
                }}
                variant="string"
            />

            <AppButton
                overrideAccess
                isTransaction={false}
                asyncCta={{
                    closeDialog: "onSuccess",
                    onClickWithResult: submit,
                    options: { onSuccessMessage: "Referral was successfully applied" }
                }}
                disabled={!code}
                variant="contained"
            >
                {AppDialog.ReferralEnterCode}
            </AppButton>
        </DialogWrapper>
    );
}
