import { useState } from "react";

import { Column, Row, ToggleSpacedButtons, useAppPalette } from "@bridgesplit/ui";
import { MarketGuideMode, RoleView } from "@bridgesplit/abf-react";
import { useTrack } from "app/hooks";
import { TrackEvent } from "app/types";

import { YieldCurve, RatesComparison, CollateralSwitch, DurationSelect, useMarketContext } from "../common";
import MarketOrderbook from "./MarketOrderbook";
import { DESKTOP_PADDING } from "../../navbar";
import { OpenHowItWorks, RatesComparisonPeriodSelect, useRatesComparisonPeriodSelect } from "../../common";

enum Open {
    RateHistory = "Rate history",
    YieldCurve = "Yield curve"
}
export default function MarketLayoutAdvanced() {
    const { border } = useAppPalette();

    const [open, setOpen] = useState(Open.RateHistory);
    const periodSelect = useRatesComparisonPeriodSelect("MarketLayoutAdvancedPeriod");
    const { view } = useMarketContext();
    const { track } = useTrack();

    const setOpenWithTracking = (val: Open) => {
        track(val === Open.YieldCurve ? TrackEvent.ViewYieldCurve : TrackEvent.ViewRateHistory);
        setOpen(val);
    };

    return (
        <>
            <Row spaceBetween sx={{ borderBottom: border, px: DESKTOP_PADDING, py: 0.5 }}>
                <Row spacing={2}>
                    <CollateralSwitch />
                    <DurationSelect />
                </Row>
                <OpenHowItWorks
                    variant="text"
                    mode={view === RoleView.Borrow ? MarketGuideMode.Borrow : MarketGuideMode.Lend}
                />
            </Row>

            <Row sx={{ borderBottom: border, alignItems: "stretch" }} spacing={2}>
                <Column spacing={2} sx={{ pl: DESKTOP_PADDING, pr: 1, py: 3, width: "100%" }}>
                    <Row spaceBetween>
                        <ToggleSpacedButtons
                            value={open}
                            setValue={setOpenWithTracking}
                            options={Object.values(Open).map((value) => ({ value }))}
                        />
                        {open === Open.RateHistory && <RatesComparisonPeriodSelect {...periodSelect} />}
                    </Row>

                    {open === Open.YieldCurve ? (
                        <YieldCurve includeHeader={false} />
                    ) : (
                        <RatesComparison customPeriod={periodSelect.period} includeHeader={false} />
                    )}
                </Column>
                <Column sx={{ p: 3, borderLeft: border, width: "100%", maxWidth: "270px" }}>
                    <MarketOrderbook />
                </Column>
            </Row>
        </>
    );
}
