import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { EvmUtil, Bridge } from "@bridgesplit/bs-protos";

import {
    BridgeTransactionInfo,
    CompleteTransactionActionArgs,
    EvmTransferTransactionInfo,
    TrackedTransactionsFilter
} from "../types";
import {
    INITIATE_TRANSFER_ROUTE,
    COMPLETE_TRANSFER_ROUTE,
    GET_BRIDGE_TRANSFERS_ROUTE,
    TRANSFER_EVM_ROUTE,
    GET_EVM_TRANSFERS_ROUTE
} from "../constants";
import { abfSerializeQueryArgs, rubiconBaseQuery } from "./util";

const BRIDGE_TXN_TAG = "BridgeTransactions";

export const bridgeTransactionApi = createApi({
    reducerPath: "bridgeTransaction",
    baseQuery: rubiconBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [BRIDGE_TXN_TAG],
    endpoints: (builder) => ({
        bridgeTransactions: builder.query<BridgeTransactionInfo[], TrackedTransactionsFilter>({
            query: (wallets) => ({
                url: GET_BRIDGE_TRANSFERS_ROUTE,
                method: "POST",
                body: wallets
            }),
            providesTags: [BRIDGE_TXN_TAG]
        }),
        evmTransfers: builder.query<EvmTransferTransactionInfo[], TrackedTransactionsFilter>({
            query: (body) => ({
                url: GET_EVM_TRANSFERS_ROUTE,
                method: "POST",
                body
            }),
            providesTags: [BRIDGE_TXN_TAG]
        }),
        initiateMultichainTransfer: builder.mutation<void, Bridge.StartBridgeAssetMessages>({
            query: (args) => ({
                url: INITIATE_TRANSFER_ROUTE,
                method: "POST",
                body: serializeStartBridgeAssetMessage(args)
            }),
            invalidatesTags: [BRIDGE_TXN_TAG]
        }),
        completeTransactionActions: builder.mutation<void, CompleteTransactionActionArgs>({
            query: (args) => ({
                url: COMPLETE_TRANSFER_ROUTE,
                method: "POST",
                body: args
            }),
            invalidatesTags: [BRIDGE_TXN_TAG]
        }),
        transferEvmAsset: builder.mutation<void, EvmUtil.TransferAssetMessage>({
            query: (args) => ({
                url: TRANSFER_EVM_ROUTE,
                method: "POST",
                body: args
            })
        })
    })
});

export const {
    useBridgeTransactionsQuery,
    useInitiateMultichainTransferMutation,
    useCompleteTransactionActionsMutation,
    useTransferEvmAssetMutation,
    useEvmTransfersQuery
} = bridgeTransactionApi;

export const useBridgeTransactionApi = () => {
    const api = bridgeTransactionApi.endpoints;
    const dispatch = useDispatch();
    const [fetchBridgeTransactions] = api.bridgeTransactions.useLazyQuery();

    return {
        resetBridgeTransactions: () => dispatch(bridgeTransactionApi.util.invalidateTags([BRIDGE_TXN_TAG])),
        fetchBridgeTransactions
    };
};

function serializeStartBridgeAssetMessage(data: Bridge.StartBridgeAssetMessages) {
    const messages = data.messages.map((startBridgeData) => ({
        ...startBridgeData,
        assetInfo: startBridgeData.assetInfo
            ? {
                  assetInfo: startBridgeData.assetInfo.evmAssetInfo
                      ? {
                            evmAssetInfo: startBridgeData.assetInfo.evmAssetInfo
                        }
                      : {
                            solAssetInfo: startBridgeData.assetInfo.solAssetInfo
                        }
              }
            : undefined
    }));

    return messages;
}
