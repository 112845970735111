import { KycStatus } from "@bridgesplit/abf-sdk";
import { useAsyncResultHandler } from "@bridgesplit/react";
import { IS_LOCAL_NX_DEV, Result, generateNonce, getReadableErrorMessage } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useUserIdentityQuery, useCreateUserVerificationSessionMutation } from "../reducers";
import { useGroup } from "./group";
import { useAccessLevel } from "./access";

const DISABLE_KYC = IS_LOCAL_NX_DEV;
// const DISABLE_KYC = false;
export function useUserIdentity() {
    const { activeGroup } = useGroup();
    const { isBeta } = useAccessLevel();
    const { data: identity, isLoading } = useUserIdentityQuery(activeGroup?.groupIdentifier ?? skipToken, {
        skip: !activeGroup || DISABLE_KYC || !isBeta
    });

    const status = DISABLE_KYC ? KycStatus.Verified : identity?.identityStatus ?? KycStatus.NotSubmitted;
    const isVerified = status === KycStatus.Verified;

    return {
        status,
        isVerified,
        isLoading: isLoading || (identity === undefined && !DISABLE_KYC)
    };
}

export function useVerifyUserIdentity() {
    const { resultHandler, isLoading } = useAsyncResultHandler();
    const [startSession] = useCreateUserVerificationSessionMutation();

    async function verify(): Promise<Result<void>> {
        try {
            const url = await startSession(generateNonce()).unwrap();
            window.open(url);
            return Result.ok();
        } catch (e) {
            return Result.errWithDebug(getReadableErrorMessage("verify identity"), e);
        }
    }

    return {
        startKyc: () => resultHandler(verify, { alertOnError: true }),
        isLoading
    };
}
