import { useEffect, useMemo, useState } from "react";

import { useLocalStorage } from "../reducers";

type Mode = "light" | "dark";
export type Theme = "auto" | Mode;

const CSS_THEME_PROPERTY = "data-theme";

export function useAppTheme() {
    const [themeSetting, setThemeSetting] = useLocalStorage<Theme>("APP_THEME", "auto");
    const devicePrefersDark = useDarkModePreference();

    const mode: Mode = useMemo(
        () => (themeSetting === "auto" ? (devicePrefersDark ? "dark" : "light") : themeSetting),
        [themeSetting, devicePrefersDark]
    );

    useEffect(() => {
        document.documentElement.setAttribute(CSS_THEME_PROPERTY, mode);
    }, [mode]);

    function updateTheme(theme: Theme) {
        setThemeSetting(theme);
    }

    return { mode, theme: themeSetting, updateTheme };
}

export function useMaintainCssTheme() {
    const { mode } = useAppTheme();

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === CSS_THEME_PROPERTY) {
                    const currentTheme = document.documentElement.getAttribute(CSS_THEME_PROPERTY);
                    if (currentTheme !== mode) {
                        document.documentElement.setAttribute(CSS_THEME_PROPERTY, mode);
                    }
                }
            });
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: [CSS_THEME_PROPERTY] });

        return () => observer.disconnect();
    }, [mode]);
}
export function useMaintainDialectTheme() {
    const { mode } = useAppTheme();

    useEffect(() => {
        const updateDialectThemes = () => {
            document.querySelectorAll(".dialect").forEach((element) => {
                element.setAttribute(CSS_THEME_PROPERTY, mode);
            });
        };

        updateDialectThemes();

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    updateDialectThemes();
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [mode]);
}

export function useDarkModePreference(): boolean {
    const [darkMode, setDarkMode] = useState<boolean>(false);

    useEffect(() => {
        const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
        setDarkMode(prefersDarkMode.matches);

        const handleChange = (event: MediaQueryListEvent) => {
            document.documentElement.setAttribute(CSS_THEME_PROPERTY, event.matches ? "dark" : "light");
            setDarkMode(event.matches);
        };

        prefersDarkMode.addEventListener("change", handleChange);

        return () => {
            prefersDarkMode.removeEventListener("change", handleChange);
        };
    }, []);

    return darkMode;
}

// max length of 2
export function getAvatarFromString(initials: string) {
    return `https://cdn.auth0.com/avatars/${initials.slice(0, 2).toLocaleLowerCase()}.png`;
}

export function encodeQueryParams(params: object) {
    const validParams = Object.entries(params).filter(([, value]) => value !== undefined);
    const queryString = validParams
        .map(([key, value]) => {
            // Encode both key and value
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(value);
            return `${encodedKey}=${encodedValue}`;
        })
        .join("&");
    if (!validParams.length) return "";
    return `?${queryString}`;
}
