import { formatPasskeyMetadata, getParsedPasskeyUserAgent, useMpcCredentialsWithHistory } from "@bridgesplit/abf-react";
import { Button, Column, Text, useAppPalette } from "@bridgesplit/ui";
import { AddToQueueOutlined, ComputerOutlined, KeyOutlined, PhoneOutlined, TabletOutlined } from "@mui/icons-material";
import { ALLOWED_FEATURES } from "@bridgesplit/utils";
import { useAppDialog, AppDialog } from "app/utils";

export default function PasskeyError() {
    return (
        <>
            <Text color="error">
                No passkey found on this device. Authenticate using an existing passkey on a different device or create
                a new Passkey
            </Text>
            <TransactionsMpcPicker />
        </>
    );
}

function TransactionsMpcPicker() {
    const credentialWithHistory = useMpcCredentialsWithHistory();
    const { textDisabled } = useAppPalette();

    const { open } = useAppDialog();

    return (
        <Column spacing={1}>
            {credentialWithHistory?.map(({ metadata, id }) => {
                return (
                    <Button
                        height={50}
                        sx={{ justifyContent: "flex-start", svg: { color: textDisabled } }}
                        variant="outlined"
                        key={id}
                    >
                        <DeviceIcon metadata={metadata} /> Authenticate with {formatPasskeyMetadata(metadata)}
                    </Button>
                );
            })}
            {ALLOWED_FEATURES.newPasskeys && (
                <Button
                    onClick={() => open(AppDialog.CreateNewPasskey, undefined)}
                    height={50}
                    sx={{ justifyContent: "flex-start", svg: { color: textDisabled } }}
                    variant="outlined"
                >
                    <AddToQueueOutlined /> Register this device
                </Button>
            )}
        </Column>
    );
}

type DeviceType = "mobile" | "tablet" | "desktop";
function DeviceIcon({ metadata }: { metadata: string | null }) {
    if (!metadata) return <KeyOutlined />;
    const userAgent = getParsedPasskeyUserAgent(metadata);
    const type: DeviceType = (userAgent.device.type as DeviceType) ?? "desktop";

    switch (type) {
        case "mobile":
            return <PhoneOutlined />;
        case "desktop":
            return <ComputerOutlined />;
        case "tablet":
            return <TabletOutlined />;
        default:
            return <KeyOutlined />;
    }
}
