import { Column, TooltipText } from "@bridgesplit/ui";

import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useSetBorrowerPreset } from "./util";
import { SelectPresetTerm } from "../../common";

export default function BorrowSetTerm() {
    return (
        <Column spacing={0.5}>
            <TooltipText helpText="Lock in your APY for a fixed duration" variant="body2">
                Fixed rate for
            </TooltipText>
            <SelectTerm />
        </Column>
    );
}

function SelectTerm() {
    const { form, presets } = useMarketBorrowContext();
    const { setPreset } = useSetBorrowerPreset();

    return (
        <SelectPresetTerm
            presets={presets}
            presetStrategyIdentifier={form.preset?.presetStrategyIdentifier}
            setPreset={setPreset}
        />
    );
}
