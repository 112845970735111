import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { getUnixTs } from "@bridgesplit/utils";
import { AppCookie } from "@bridgesplit/react";

import {
    REFERRAL_KEY,
    ROUTE_APPLY_COMMUNITY_REWARDS,
    ROUTE_APPLY_INVITE,
    ROUTE_GET_COMMUNITY_MULTIPLIERS,
    ROUTE_GET_COMMUNITY_REWARDS,
    ROUTE_GET_POINT_MULTIPLIERS,
    ROUTE_GET_REWARDS,
    ROUTE_GET_REWARDS_MULTIPLIER_SUM,
    ROUTE_GET_USER_POINTS_CALC,
    ROUTE_GET_USER_REFERRAL_INFO,
    ROUTE_GET_USER_WL_INFO,
    ROUTE_REFER_USER
} from "../constants";
import { abfSerializeQueryArgs, rubyBaseQuery, unauthenticatedSerializeQueryArgs } from "./util";
import {
    CommunityMultiplier,
    CommunityPointsReward,
    CommunityRewardInfo,
    PointMultiplier,
    PointsReward,
    PointsRewardType,
    RewardsFilter,
    UserPointsCalculationInfo,
    UserReferralInfo,
    UserWaitlistInfo
} from "../types";

const POINTS_TAG = "Points";
const REFERRAL_TAG = "REFERRAL";

export const pointsApi = createApi({
    reducerPath: "pointsApi",
    tagTypes: [POINTS_TAG, REFERRAL_TAG],
    baseQuery: rubyBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        rewards: builder.query<PointsReward[], RewardsFilter>({
            serializeQueryArgs: unauthenticatedSerializeQueryArgs,
            query(body) {
                return {
                    url: ROUTE_GET_REWARDS,
                    method: "POST",
                    body
                };
            }
        }),
        userPoints: builder.query<UserPointsCalculationInfo, string>({
            providesTags: [POINTS_TAG],
            query() {
                return {
                    url: ROUTE_GET_USER_POINTS_CALC,
                    method: "GET",
                    params: {
                        // calc points 5s in future to prevent having to wait for one time events to end
                        timestamp: getUnixTs() + 5
                    }
                };
            }
        }),
        userWhitelistInfo: builder.query<UserWaitlistInfo, string>({
            providesTags: [POINTS_TAG],
            query() {
                return {
                    url: ROUTE_GET_USER_WL_INFO,
                    method: "GET"
                };
            }
        }),
        pointMultipliers: builder.query<PointMultiplier[], void>({
            providesTags: [POINTS_TAG],
            query() {
                return {
                    url: ROUTE_GET_POINT_MULTIPLIERS,
                    method: "GET"
                };
            }
        }),
        userReferralInfo: builder.query<UserReferralInfo, string>({
            providesTags: [REFERRAL_TAG],
            queryFn: async (params, api, extraOptions, baseQuery) => {
                const referralInfoRes = await baseQuery({
                    url: ROUTE_GET_USER_REFERRAL_INFO,
                    method: "GET"
                });

                const referralInfo = referralInfoRes.data as UserReferralInfo;

                const referralCode = AppCookie.get(REFERRAL_KEY);

                if (!referralInfo.referredByCode && referralCode && referralInfo.userReferralCode !== referralCode) {
                    const result = await baseQuery({
                        url: ROUTE_REFER_USER,
                        params: { referralCode },
                        method: "POST"
                    });

                    AppCookie.remove(REFERRAL_KEY);

                    // early return previous ref info
                    if (result.error) {
                        return { data: referralInfo };
                    }

                    // manually refetch
                    const newReferralInfoRes = await baseQuery({
                        url: ROUTE_GET_USER_REFERRAL_INFO,
                        method: "GET"
                    });

                    return { data: newReferralInfoRes.data as UserReferralInfo };
                }

                return { data: referralInfo };
            }
        }),
        // take in user ID to trigger refetch
        communityRewards: builder.query<CommunityRewardInfo[], string>({
            providesTags: [POINTS_TAG],
            query() {
                return {
                    url: ROUTE_GET_COMMUNITY_REWARDS,
                    method: "GET"
                };
            }
        }),
        // take in user ID to trigger refetch
        communityPointMultipliers: builder.query<CommunityMultiplier[], string>({
            providesTags: [POINTS_TAG],
            query() {
                return {
                    url: ROUTE_GET_COMMUNITY_MULTIPLIERS,
                    method: "GET"
                };
            }
        }),
        // take in user ID to trigger refetch
        pointsMultiplierSumForReward: builder.query<number, { pointsReward: PointsRewardType; userId: string }>({
            query({ pointsReward }) {
                return {
                    url: `${ROUTE_GET_REWARDS_MULTIPLIER_SUM}/${pointsReward}`,
                    method: "GET"
                };
            },
            transformResponse: ({ totalMultiplier }: { totalMultiplier: number }) => totalMultiplier
        }),
        applyCommunityReward: builder.mutation<CommunityPointsReward[], PointsRewardType>({
            invalidatesTags: [POINTS_TAG],
            query(reward) {
                return {
                    url: `${ROUTE_APPLY_COMMUNITY_REWARDS}/${reward}`,
                    method: "POST"
                };
            }
        }),
        applyReferral: builder.mutation<string, string | undefined>({
            invalidatesTags: [REFERRAL_TAG, POINTS_TAG],
            query(code) {
                return {
                    url: ROUTE_REFER_USER,
                    params: {
                        referralCode: code
                    },
                    method: "POST"
                };
            }
        }),
        applyBetaInvite: builder.mutation<void, string>({
            invalidatesTags: [REFERRAL_TAG, POINTS_TAG],
            query(code) {
                return {
                    url: `${ROUTE_APPLY_INVITE}/${code}`,
                    method: "POST"
                };
            }
        })
    })
});

export const {
    useRewardsQuery,
    useUserPointsQuery,
    useUserWhitelistInfoQuery,
    useUserReferralInfoQuery,
    useCommunityRewardsQuery,
    usePointsMultiplierSumForRewardQuery,
    usePointMultipliersQuery,
    useCommunityPointMultipliersQuery,
    useApplyBetaInviteMutation,
    useApplyReferralMutation,
    useApplyCommunityRewardMutation
} = pointsApi;

export function usePointsApi() {
    const dispatch = useDispatch();

    return {
        resetPoints: () => dispatch(pointsApi.util.invalidateTags([POINTS_TAG])),
        resetPointsAndReferrals: () => dispatch(pointsApi.util.invalidateTags([POINTS_TAG, REFERRAL_TAG])),
        resetReferrals: () => dispatch(pointsApi.util.invalidateTags([REFERRAL_TAG]))
    };
}
