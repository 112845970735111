import { useEffect, useState } from "react";

import {
    BackCta,
    Button,
    Column,
    DialogWrapper,
    OrDivider,
    SpanFlex,
    TagText,
    TagTextAlert,
    Text,
    WalletButtons
} from "@bridgesplit/ui";
import { EmailOutlined } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import {
    SIGN_UP_PREFERENCE_KEY,
    useAbfLogout,
    useAccessLevel,
    useAuth0Login,
    useAuthStorage,
    useUserMe
} from "@bridgesplit/abf-react";
import { ALLOWED_FEATURES, parseErrorFromOptions, Result } from "@bridgesplit/utils";
import { AppCookie } from "@bridgesplit/react";
import { useAppDialog } from "app/utils";
import { COPY } from "app/constants";

import { UnverifiedEmail } from "../unverified";

type ConnectingType = "wallet" | "email";
export default function ConnectDialog() {
    const { user } = useAuth0();

    if (user?.email_verified === false) {
        return <Unverified />;
    }

    return (
        <DialogWrapper>
            <Verified />
        </DialogWrapper>
    );
}

const LOGIN_ERRORS = {
    "Prime users must log in with email": "This wallet is associated with a Prime account. Please login with email"
};

function Verified() {
    const { close } = useAppDialog();
    const login = useAuth0Login();

    const { setSkipAuth0, resetCookies } = useAuthStorage();

    const [connected, setConnected] = useState<ConnectingType>();
    const { isNotConnected } = useAccessLevel();
    const userMe = useUserMe({ skip: isNotConnected });
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        if (userMe.error && !errorMessage) {
            const message =
                parseErrorFromOptions(Result.err(userMe.error).unwrapErrMessage(), LOGIN_ERRORS) ?? "Unable to login";
            setErrorMessage(message);
        }
    }, [errorMessage, userMe.error]);

    useEffect(() => {
        if (userMe.data) {
            close();
        }
    }, [close, userMe.data]);

    return (
        <>
            <Column spacing={0.5} alignItems="center" textAlign="center">
                <Text variant="h3"> {COPY.CONNECT_PROMPT} </Text>
                <Text variant="body2" color="caption">
                    {COPY.CONNECT_DESCRIPTION}
                </Text>
            </Column>

            <WalletButtons
                onSelect={() => {
                    resetCookies();
                    setConnected("wallet");
                }}
                loading={connected === "wallet" && userMe.isFetching}
            />
            <OrDivider />
            <Button
                loading={connected === "email" && userMe.isFetching}
                variant="outlined"
                textProps={{ sx: { justifyContent: "space-between", width: "100%" } }}
                sx={{ justifyContent: "flex-start" }}
                onClick={async () => {
                    resetCookies();
                    setSkipAuth0(false);
                    const isSignupPreferred = ALLOWED_FEATURES.disableEmailSignup
                        ? false
                        : !!AppCookie.get(SIGN_UP_PREFERENCE_KEY);
                    await login(isSignupPreferred ? { screen_hint: "signup" } : undefined);
                    setConnected("email");
                }}
            >
                <SpanFlex sx={{ gap: 1.5 }}>
                    <EmailOutlined />
                    Email
                </SpanFlex>
                {ALLOWED_FEATURES.disableEmailSignup && (
                    <TagText
                        helpText="Currently only existing users can sign in with email. New users will be able to sign up soon"
                        color="body"
                        variant="basic"
                        useTooltip={false}
                    >
                        Existing users only
                    </TagText>
                )}
            </Button>
            {!!errorMessage && (
                <TagTextAlert color="error" icon="warning">
                    {errorMessage}
                </TagTextAlert>
            )}
        </>
    );
}

function Unverified() {
    const { logOut } = useAbfLogout();
    return (
        <DialogWrapper>
            <UnverifiedEmail />
            <BackCta back={logOut} backCta="Log out" />
        </DialogWrapper>
    );
}
