import { useState } from "react";

import { BsMetaUtil, RoleView } from "@bridgesplit/abf-react";
import { DialogWrapper } from "@bridgesplit/ui";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { MarketProvider, QuotesProvider } from "./common";
import { MarketDialogWrapper } from "./dialog";
import { BorrowStep, BorrowStepper, MarketBorrowProvider } from "./borrow";

export default function BorrowDialog() {
    const { getData } = useAppDialog();
    const data = getData<AppDialog.Borrow>();

    const [tokenOverride, setToken] = useState<BsMetadata>();
    const [step, setStep] = useState<BorrowStep>(BorrowStep.Collateral);
    const token = tokenOverride ?? data?.token;
    const principalMint = token?.assetMint;

    return (
        <MarketProvider
            marketProps={{
                isDialog: true,
                token,
                selectToken: setToken,
                symbol: BsMetaUtil.getSymbol(token),
                loading: false,
                principalMint,
                view: RoleView.Borrow
            }}
        >
            <QuotesProvider>
                <MarketBorrowProvider>
                    <DialogWrapper>
                        <MarketDialogWrapper>
                            <BorrowStepper step={step} setStep={setStep} />
                        </MarketDialogWrapper>
                    </DialogWrapper>
                </MarketBorrowProvider>
            </QuotesProvider>
        </MarketProvider>
    );
}
