import { useAbfLogout, useAuthStorage } from "@bridgesplit/abf-react";
import { Column, Text, Button, AbsoluteCenterWrapper, IconHeader, TagText } from "@bridgesplit/ui";
import { DnsOutlined } from "@mui/icons-material";

import { PageBase } from "./Page";

export function AppFallback() {
    return (
        <PageBase hideFooter>
            <AppFallbackContent />
        </PageBase>
    );
}

export function AppFallbackContent() {
    return null;
}

export function AuthError() {
    const { logOut } = useAbfLogout();
    const {
        state: { authError }
    } = useAuthStorage();

    return (
        <AbsoluteCenterWrapper>
            <Column
                spacing={3}
                sx={{
                    maxWidth: "400px",
                    height: "100%",

                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                }}
            >
                <IconHeader tagColor="error">
                    <DnsOutlined />
                </IconHeader>
                <Column spacing={1} alignItems="center">
                    <Text variant="h1">Authentication error</Text>
                    <Text color="caption">
                        Error fetching account. Please log out and try again. For further assistance, contact
                        support@loopscale.com We apologize for any inconvenience
                    </Text>
                </Column>
                {!!authError && (
                    <TagText textVariant="body1" variant="colored" sx={{ fontFamily: "monospace" }} color="error">
                        {authError}
                    </TagText>
                )}
                <Button variant="contained" onClick={logOut}>
                    Log out
                </Button>
            </Column>
        </AbsoluteCenterWrapper>
    );
}
