import { Dispatch, SetStateAction, memo } from "react";

export type DispatchType<T> = Dispatch<SetStateAction<T>>;

export type FormInputType<T> = (t: T) => void;

export const genericsMemo: <T>(component: T) => T = memo;

export type FormProps<T> = {
    form: T;
    setForm: DispatchType<T>;
};

export enum FileExtensionEnum {
    Txt = "Txt",
    Csv = "Csv",
    Json = "Json",
    Xml = "Xml",
    Pdf = "Pdf",
    Png = "Png"
}

export interface RawFile {
    /// raw file contents, b64
    fileContents: string;
    /// file extension enum
    fileExtension: FileExtensionEnum;
}
