import { Fragment, ReactNode } from "react";

import {
    Column,
    JLP_LOGO,
    MARGIN_FI_LOGO,
    RewardImage,
    Row,
    SOLANA_HUB_LOGO,
    StatProps,
    StatWithIcon,
    Text,
    METEORA_SVG,
    Icon,
    useAppPalette,
    RewardIcon,
    SpanFlex
} from "@bridgesplit/ui";
import {
    PointsInfo,
    LoopscalePointSource,
    PointSource,
    POINTS_SOURCE_INFO,
    POINTS_BASIS
} from "@bridgesplit/abf-react";
import { BoltOutlined } from "@mui/icons-material";
import { formatFixedDigits, TIME } from "@bridgesplit/utils";

export interface CollateralInfo {
    pointSource: PointSource[];
    collateralPointBonus: Map<LoopscalePointSource, number>;
    name: string;
}

interface TooltipContentProps {
    children: React.ReactNode;
    width?: string;
}
export function TooltipContent({ children, width = "250px" }: TooltipContentProps): JSX.Element {
    return (
        <Column spacing={2} padding={1} sx={{ width }}>
            {children}
        </Column>
    );
}

interface CreateTotalMultiplierStatProps {
    sumStat: PointsInfo[];
    baseRate: number;
    showMultiplier?: boolean;
    totalAmountUsd: number;
    isSpecific?: boolean;
}

export function useCreateTotalMultiplierStat({
    sumStat,
    baseRate,
    showMultiplier = true,
    totalAmountUsd: totalAmount,
    isSpecific = false
}: CreateTotalMultiplierStatProps): StatProps[] {
    return sumStat.map((multiplier) => {
        const pointsPerDay =
            (multiplier?.multiplier ?? 1) * baseRate * (totalAmount / POINTS_BASIS) * (TIME.DAY / TIME.HOUR);

        const value = showMultiplier ? `${multiplier.multiplier}x` : formatFixedDigits(pointsPerDay);

        return {
            value: (
                <StatWithIcon
                    key={`${multiplier.multiplier}-${multiplier.program}`}
                    value={value}
                    captionColor="body"
                    icon={<RewardIcon />}
                />
            ),
            caption: isSpecific
                ? "Daily points"
                : showMultiplier
                ? `Total point multiplier`
                : `Daily points per $${formatFixedDigits(POINTS_BASIS)}`
        };
    });
}

export function useCreateMultiplierLoops(multipliers: PointsInfo[]): StatProps[] {
    return multipliers.map((multiplier) => {
        return {
            value: <StatWithIcon value={`${multiplier.multiplier}x`} />,
            caption: multiplier.caption
        };
    });
}

export function useCreateCollateralStats(multipliers: PointsInfo[] | undefined, isBoosted: boolean) {
    const { prime, secondary } = useAppPalette();
    if (!multipliers) return undefined;
    return multipliers.map((multiplier) => {
        return {
            value: (
                <StatWithIcon
                    value={`${multiplier.multiplier}x`}
                    icon={
                        isBoosted ? (
                            <Text sx={{ color: isBoosted ? prime : secondary }}>
                                <BoltOutlined />
                            </Text>
                        ) : undefined
                    }
                />
            ),
            caption: multiplier.caption
        };
    });
}

interface AdditionalStatProps {
    multiplier: PointsInfo;
}

export function RenderAdditionalStat({ multiplier }: AdditionalStatProps) {
    const value = (multiplier.multiplier ?? 0) > 1 ? `${multiplier.multiplier}x` : undefined;

    return (
        <Column spacing={1} sx={{ width: "100%" }}>
            <Row spacing={1}>
                {getIconForSource(multiplier.program)}
                <Text>{multiplier.caption}</Text>
            </Row>
            <Row spacing={1} sx={{ width: "100%", justifyContent: "flex-start" }}>
                <Text color="caption">
                    {value && ![PointSource.MarginFi].includes(multiplier.program)
                        ? `Deposits earn up to ${value} ${multiplier.caption}`
                        : `Deposits earn ${multiplier.caption}`}
                </Text>
            </Row>
        </Column>
    );
}

export function getIconForSource(source: PointSource): ReactNode {
    switch (source) {
        case PointSource.MarginFi:
            return <RewardImage src={MARGIN_FI_LOGO} />;
        case PointSource.Loopscale:
            return <Icon type="points" />;
        case PointSource.Jupiter:
            return <RewardImage src={JLP_LOGO} />;
        case PointSource.Meteora:
            return <RewardImage src={METEORA_SVG} />;
        case PointSource.HubSol:
            return <RewardImage src={SOLANA_HUB_LOGO} />;
    }
}

export function generateLeverageStat(leverage: number | undefined): PointsInfo | undefined {
    if (!leverage) return undefined;
    return {
        program: PointSource.LoopscaleHidden,
        caption: "Max leverage",
        multiplier: leverage
    };
}

export function generateBaseStat(
    pointSources: LoopscalePointSource[] | undefined,
    pointsSourceMapping: Record<LoopscalePointSource, number> | null
): PointsInfo[] | undefined {
    if (!pointsSourceMapping || !pointSources) return undefined;

    return pointSources.map((source) => ({
        program: PointSource.Loopscale,
        caption: "Loop multiplier",
        multiplier: pointsSourceMapping[source]
    }));
}

interface PointSourceMapProps {
    pointSources: PointSource[];
    background?: string;
    isLoading?: boolean;
}

export function PointSourceMap({ pointSources, background, isLoading }: PointSourceMapProps): JSX.Element {
    const { secondary } = useAppPalette();
    return (
        <Row spacing={1}>
            {pointSources.map((point) => (
                <Fragment key={point}>
                    <PointSourceIcon source={point} background={background ?? secondary} isLoading={isLoading} />
                </Fragment>
            ))}
        </Row>
    );
}
interface PointSourceIconProps {
    source: PointSource;
    background?: string;
    isLoading?: boolean;
}

function PointSourceIcon({ source }: PointSourceIconProps): JSX.Element {
    const { secondary } = useAppPalette();
    if (source === PointSource.Loopscale) {
        return (
            <Text sx={{ color: secondary }} variant="body1">
                {getIconForSource(POINTS_SOURCE_INFO[source].program)}
            </Text>
        );
    }

    return <SpanFlex>{getIconForSource(POINTS_SOURCE_INFO[source].program)}</SpanFlex>;
}
