import { AbfEscrowAccount } from "@bridgesplit/abf-sdk";

import { AbfUserWithPermissionsAndWallets } from "./user";
import { AbfRole, AbfRoleNumber } from "./role";

export enum AbfGroupType {
    Individual,
    Organization,
    Custodian
}

export interface AbfUserGroup {
    groupIdentifier: string;
    groupName: string;
    // groupDescription: string;
    groupImage: string | undefined;
    groupType: AbfGroupType;
}

export interface AbfCreateUserGroupParams {
    admin_wallet: string | undefined; // set as null for MPC wallets
    group_name: string;
    group_description: string;
    group_image: string;
    group_type: AbfGroupType;
}

export interface AddWalletParams {
    isMpc: boolean;
    wallet?: string;
    signedTxn?: string;
    message?: string;
    dfnsId?: string;
}

export interface AbfCreateUserGroupResponse {
    groupIdentifier: string;
    mpcIdentifier?: string;
}

export interface AbfUserGroupMetadata {
    id: number;
    groupIdentifier: string;
    legalName: string;
    yearFounded?: string;
    sector?: string;
    headquarters?: string;
    keyPeople?: string;
    website?: string;
    email?: string;
    linkedin?: string;
    twitter?: string;
    verified?: number;
}

export type PutAbfUserGroupMetadata = Omit<AbfUserGroupMetadata, "id" | "verified">;

export interface GroupDocumentInsert {
    documentType: number;
    url: string;
}

export interface AbfGroupDocuments {
    id: number;
    groupIdentifier: string;
    documentType: number;
    url: string;
}

export interface AbfGroupWithRoles {
    group: AbfUserGroup;
    roles: AbfRole[];
}

interface AbfUserGroupInvites {
    id: number;
    groupIdentifier: string;
    userEmail: string;
    role: AbfRoleNumber;
}

export interface AbfGroupPermissionsReturn {
    existing: AbfUserWithPermissionsAndWallets[];
    invited: AbfUserGroupInvites[];
    on_chain: Record<string, AbfRole[]>;
}

export interface AbfGroupWithPermissions {
    group: AbfUserGroup;
    permissions: Record<string, AbfRole[]>;
}

export interface AbfCheckGroupOnChainRolesReturn {
    roles_to_add: [string, AbfRoleNumber[]][];
    roles_to_remove: [string, AbfRoleNumber[]][];
}

export interface AbfRolesInvite {
    email: string;
    roles: AbfRole[];
}

export interface AbfGroupPermissionChange {
    groupIdentifier: string;
    roles: AbfRole[];
}

export interface AbfUserPermissionChange {
    userIdentifier: string;
    roles: AbfRole[];
}

export interface AbfUserGroupAndEscrow {
    escrow_account: AbfEscrowAccount;
    group: AbfUserGroup;
}
