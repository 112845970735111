import { ALLOWED_FEATURES } from "@bridgesplit/utils";
import { Commitment } from "@solana/web3.js";

export const MAX_TRANSACTION_BUNDLE_SIZE = 4; // max bundle size is 5 excluding tip txn
export const MAX_LOCKBOX_SIZE = 100;
export const MAX_TRANSACTION_BUNDLE_COUNT = 3;
export const DEFAULT_BUNDLE_BUNDLE_THRESHOLD = 3;

export const TRANSACTION_DEFAULT_BATCH_COMMITMENT: Commitment = ALLOWED_FEATURES.disableFinalized
    ? "confirmed"
    : "finalized";
