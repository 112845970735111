import { useActiveWallet, useUserUnrecognizedAssets } from "@bridgesplit/abf-react";
import { Button, Column, Icon, Text, useAppPalette } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";

export default function UnrecognizedAssetsAlert() {
    const { activeWallet } = useActiveWallet();
    const { data: assets } = useUserUnrecognizedAssets(!activeWallet?.mpcIdentifier);
    const { open } = useAppDialog();
    const { error } = useAppPalette();

    if (!activeWallet?.mpcIdentifier || !assets?.length) return null;

    return (
        <Column spacing={1}>
            <Text variant="body2">
                <Icon type="warning" sx={{ color: error }} /> {assets.length} unrecognized asset
                {assets.length === 1 ? "" : "s"}
            </Text>
            <Button
                onClick={() => open(AppDialog.WithdrawUnrecognized, undefined)}
                height={30}
                width="max-content"
                textProps={{ variant: "body2" }}
                fullWidth={false}
            >
                Withdraw
            </Button>
        </Column>
    );
}
