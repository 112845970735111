import {
    getSignaturesNeededFromCredentials,
    useMpcCustomSign,
    useMpcRegisterOrSign,
    useMpcStatus
} from "@bridgesplit/abf-react";
import { useAlert } from "@bridgesplit/react";
import { Result, SignatureType, generateNonce } from "@bridgesplit/utils";
import { COPY } from "app/constants";

type Options = { forceSign?: boolean };
export function useMpcRegisterOrSignWithAlerts() {
    const { data } = useMpcStatus();
    const sign = useSignWithAlerts();

    return async function (options?: Options) {
        const steps = getSignaturesNeededFromCredentials(data, options?.forceSign);
        return await sign(steps, options);
    };
}

export function useMpcSignIfPatExpired() {
    const { data } = useMpcStatus();
    const sign = useSignWithAlerts();

    return async function (options?: Options) {
        // only sign if pat expired
        const steps = data?.currentPasskey ? [] : getSignaturesNeededFromCredentials(data, options?.forceSign);

        return await sign(steps, options);
    };
}

function useSignWithAlerts() {
    const customSign = useMpcCustomSign();
    const registerOrSign = useMpcRegisterOrSign();
    const { alert, dismiss } = useAlert();

    return async (steps: SignatureType[], options?: Options) => {
        const showProgress = steps.length > 1;
        const customSnackbarId = generateNonce();

        const beforeSign = (sig: SignatureType) =>
            alert(COPY.PASSKEY_SIGN_PROMPT, "spinner", {
                description: showProgress
                    ? `Step ${steps.findIndex((v) => v === sig) + 1} of ${steps.length}: ${sig}`
                    : undefined,
                customSnackbarId
            });

        const promise = options?.forceSign
            ? registerOrSign({ beforeSign, forceSign: true })
            : customSign(steps, { beforeSign });

        const res = await promise;

        dismiss(customSnackbarId);
        if (!res.isOk()) {
            return Result.errWithDebug(COPY.PASSKEY_SIGN_FAIL, res);
        }

        return res;
    };
}
