import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
    useAuthStorage,
    useGroupCustodianPermissionsQuery,
    useGroupsByEscrowsQuery,
    useGroupsByIdentifiersQuery
} from "../reducers";
import { AbfGroupType, AbfUserGroup, AbfUserGroupAndEscrow } from "../types";
import { AbfUserPermissions, formatGroupName } from "../utils";
import { useSkipUnauthenticated, useUserMe } from "./auth";
import { useAccessLevel } from "./access";

export function useActiveGroup() {
    const { state } = useAuthStorage();

    return { groupIdentifier: state.groupIdentifier };
}

export function useGroup(options?: { skip?: boolean }) {
    const { state } = useAuthStorage();
    const { isBeta } = useAccessLevel();

    const { data, isLoading: queryLoading, isFetching } = useUserMe({ skip: options?.skip });
    const { data: custodianPermissionsRaw, isLoading: custodianPermsLoading } = useGroupCustodianPermissionsQuery(
        undefined,
        {
            skip:
                !data ||
                !state.groupIdentifier ||
                !(state.groupIdentifier in data.permissions) ||
                options?.skip ||
                isFetching ||
                !!state.authError ||
                !isBeta
        }
    );

    const custodianPermissions = useMemo(() => {
        if (!custodianPermissionsRaw?.permissions) return undefined;
        const custodianPermissions = { ...custodianPermissionsRaw?.permissions };
        if (custodianPermissionsRaw && custodianPermissionsRaw?.group.groupType !== AbfGroupType.Custodian) {
            delete custodianPermissions[custodianPermissionsRaw.group.groupIdentifier];
        }
        return custodianPermissions;
    }, [custodianPermissionsRaw]);

    const activeGroup = useMemo(
        () => data?.groups.find((g) => g.group?.groupIdentifier === state.groupIdentifier),
        [data?.groups, state.groupIdentifier]
    );

    const permissions =
        activeGroup && custodianPermissions ? new AbfUserPermissions(activeGroup, custodianPermissions) : undefined;

    const isLoading = useMemo(() => queryLoading || custodianPermsLoading, [custodianPermsLoading, queryLoading]);

    return {
        custodianPermissions: custodianPermissions ?? {},
        groups: data?.groups,
        isLoading,
        activeGroup: activeGroup?.group,
        activeRoles: activeGroup?.roles,
        permissions
    };
}

export function useGroupsByIdentifiers(identifiers: string[] | undefined) {
    const skipIfUnauthenticated = useSkipUnauthenticated();
    const { data, ...query } = useGroupsByIdentifiersQuery(identifiers ?? skipToken, {
        skip: !identifiers?.length || skipIfUnauthenticated
    });
    const dataWithNames = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(data ?? {}).map(([id, group]): [string, AbfUserGroup] => [
                    id,
                    { ...group, groupName: formatGroupName(group) }
                ])
            ),
        [data]
    );
    const cache = useMemo(() => new Map(Object.entries(dataWithNames)), [dataWithNames]);

    return { ...query, cache };
}

export function useGroupsByEscrows(escrowAccounts: string[] | undefined) {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const { data, ...query } = useGroupsByEscrowsQuery(escrowAccounts ?? skipToken, {
        skip: !escrowAccounts?.length || skipIfUnauthenticated
    });

    const dataWithNames = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(data ?? {}).map(([id, { escrow_account, group }]): [string, AbfUserGroupAndEscrow] => [
                    id,
                    { group: { ...group, groupName: formatGroupName(group) }, escrow_account }
                ])
            ),
        [data]
    );

    const cache = useMemo(() => new Map(Object.entries(dataWithNames ?? {})), [dataWithNames]);

    return { ...query, cache };
}
