import { StatColumn } from "@bridgesplit/ui";
import { bsMath, formatPercent, formatUsd } from "@bridgesplit/utils";
import { BsMetaUtil } from "@bridgesplit/abf-react";

import BorrowCollateral from "../BorrowCollateral";
import { useMarketBorrowContext } from "../MarketBorrowContext";

export default function Collateral() {
    const { maxQuote, prices, activeUserCollateral, form } = useMarketBorrowContext();

    return (
        <>
            <BorrowCollateral />
            {!!form.collateralAmount && (
                <StatColumn
                    loading={maxQuote === undefined}
                    stats={[
                        {
                            caption: "Max LTV",
                            tooltip: `Borrow up to ${formatPercent(
                                maxQuote?.ltv
                            )} of the value of your ${BsMetaUtil.getSymbol(activeUserCollateral?.metadata)}`,
                            value: formatPercent(maxQuote?.ltv)
                        },
                        {
                            caption: "Borrow limit",
                            value: formatUsd(bsMath.mul(prices.collateralAmountUsd, maxQuote?.ltv) ?? 0)
                        }
                    ]}
                />
            )}
        </>
    );
}
