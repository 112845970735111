import { MEDIA } from "@bridgesplit/ui";

export const POINTS_BREAKPOINT = MEDIA.XL;

export const INVITE_CODE_LENGTH = 6;
export const REFERRAL_CODE_LENGTH = 5;
export const REFERRAL_CODE_PLACEHOLDER = "XX123";
export const INVITE_CODE_PLACEHOLDER = "Abc123";
export const REFERRAL_CODE_POINTS = 50;
export const REFERRAL_PERCENTAGE = 0.05; // 5%
