import { useState } from "react";

import { StatColumn, TokenInput } from "@bridgesplit/ui";
import { USDC_SYMBOL } from "@bridgesplit/utils";
import { SphereUserBankAccount } from "@bridgesplit/abf-sdk";
import { AbfPermission } from "@bridgesplit/abf-react";

import { AppButton } from "../common";
import { SphereCtaInfoWrapper, useSphereStats } from "./common";
import { useOnrampPayout, calcSphereOnrampFees } from "./util";

export default function SphereOnramp({ bank }: { bank: SphereUserBankAccount | undefined }) {
    const [amount, setAmount] = useState<number>();

    const submitPayout = useOnrampPayout();
    const fees = calcSphereOnrampFees(amount);
    const stats = useSphereStats(fees);
    return (
        <>
            <TokenInput
                label="Amount"
                showBalance={false}
                maxAmount={undefined}
                adornment={USDC_SYMBOL}
                value={amount}
                setValue={(val) => setAmount(val)}
            />
            {!!amount && <StatColumn stats={stats} />}

            <SphereCtaInfoWrapper>
                <AppButton
                    isTransaction
                    fullWidth
                    authentication={{ permission: AbfPermission.DepositFunds }}
                    disabled={!amount}
                    asyncCta={{
                        onClickWithResult: () => submitPayout({ amount: fees.sphereInputAmount, bank }),
                        options: {
                            onSuccessMessage: "Deposit initiated",
                            onSuccessColor: "info",
                            onSuccess: () => setAmount(undefined)
                        }
                    }}
                    variant="contained"
                >
                    Deposit
                </AppButton>
            </SphereCtaInfoWrapper>
        </>
    );
}
