import {
    AbfLoanExpanded,
    BsMetaUtil,
    calculateLoanLiquidationPrice,
    formatLeverage,
    isLoanActive,
    LoopPositionExpanded
} from "@bridgesplit/abf-react";
import { MediaQuery, MediaStats, StatProps } from "@bridgesplit/ui";
import { formatUsd } from "@bridgesplit/utils";
import { useMediaQuery } from "@mui/material";
import { COPY } from "app/constants/copy";

import {
    useLoopProfitLoss,
    useLoopUnwindAlerts,
    LiquidationPrice,
    LoopProfitLoss,
    LoopInitialDeposit
} from "../../common";
import { LoopPositionApy } from "./common";

export default function LoopPositionStats({
    loopPosition,
    query,
    isExpanded
}: {
    loopPosition: LoopPositionExpanded | undefined;
    query: MediaQuery;
    isExpanded: boolean;
}) {
    const userLoopInfo = loopPosition?.userLoopInfo;
    const loanExpanded = loopPosition?.loanExpanded;

    const gainSummary = useLoopProfitLoss(loopPosition, { skip: !isExpanded });
    const isActive = isLoanActive(loanExpanded);
    const isMobile = useMediaQuery(query.below);
    const { unwindWarningMessage } = useLoopUnwindAlerts({
        loopPositionExpanded: loopPosition,
        profitLossUsd: gainSummary?.profitLossUsd,
        variant: "preview"
    });

    const stats: StatProps[] = [
        {
            value: userLoopInfo ? formatLeverage(userLoopInfo?.leverageMultiplier) : undefined,
            caption: COPY.LEVERAGE_TERM,
            hide: !isMobile
        },
        {
            value: <LoopPositionApy loopPosition={loopPosition} />,
            caption: "Net APY",
            hide: !isMobile || !userLoopInfo?.netApy
        },
        {
            value: <LoopInitialDeposit loopPosition={loopPosition} />,
            caption: "Original deposit"
        },
        {
            value: <LoopProfitLoss loopPosition={loopPosition} gainSummary={gainSummary} />,
            caption: "Est. P&L",
            tooltip: unwindWarningMessage
        },
        {
            value: formatUsd(userLoopInfo?.additionalCollateralDepositsUsd),
            caption: `${BsMetaUtil.getSymbol(loopPosition?.loopExpanded.collateralToken)} top-ups`,
            hide: !userLoopInfo?.additionalCollateralDepositsUsd
        },
        {
            value: formatUsd(userLoopInfo?.totalPaidUsd),
            caption: `${BsMetaUtil.getSymbol(loopPosition?.loopExpanded.principalToken)} repayments`,
            hide: !userLoopInfo?.totalPaidUsd || !isActive
        },
        {
            value: <LoopLiqPrice loanExpanded={loanExpanded} />,
            caption: COPY.LIQUIDATION_PRICE_TERM,
            tooltip: COPY.LIQUIDATION_PRICE_TOOLTIP,
            hide: !isActive || isMobile
        },
        {
            value: formatUsd(loopPosition?.userLoopInfo.netPositionValue),
            caption: "Withdrawn",
            hide: isActive
        }
    ];

    return <MediaStats query={query} variant="body1" loading={!loopPosition || !gainSummary} stats={stats} />;
}

function LoopLiqPrice({ loanExpanded }: { loanExpanded: AbfLoanExpanded | undefined }) {
    const liquidationPrice = calculateLoanLiquidationPrice(loanExpanded);
    return (
        <LiquidationPrice
            liquidationPrice={liquidationPrice?.price ?? 0}
            percentChange={liquidationPrice?.percentChange}
            collateral={liquidationPrice?.collateral.metadata}
            principal={loanExpanded?.principalMetadata}
        />
    );
}
