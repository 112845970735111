import { Fragment, memo } from "react";

import { useAppTheme, useAppNavigate } from "@bridgesplit/react";
import {
    BORDER_RADIUS_SM,
    Icon,
    Image,
    MenuItemProps,
    MenuItems,
    Select,
    TooltipText,
    useCopyAddress,
    useCopyToClipboard,
    VerticalScroll
} from "@bridgesplit/ui";
import {
    AccountBalanceOutlined,
    AccountBoxOutlined,
    AddOutlined,
    CheckOutlined,
    ContrastOutlined,
    GroupOutlined,
    Logout,
    PieChartOutline,
    ShareOutlined,
    StarOutline
} from "@mui/icons-material";
import {
    AbfGroupType,
    AbfUserGroup,
    formatGroup,
    useAbfLogout,
    useActiveGroup,
    useSwitchAccount,
    useGroup,
    useUserBetaAccess,
    useUserIsPrime,
    useAuth,
    useActiveWallet,
    useUserReferral
} from "@bridgesplit/abf-react";
import { Divider } from "@mui/material";
import { greaterThan } from "@bridgesplit/utils";
import {
    ORGANIZATION_CREATE_SLUG,
    ACCOUNT_SETTINGS_SLUG,
    ORGANIZATION_SETTINGS_SLUG,
    BANK_TRANSFERS_SLUG,
    PRIME_SLUG,
    PORTFOLIO_OVERVIEW_SLUG,
    BS_SHORT_URL_INVITE
} from "app/constants";

import { useUiPermission } from "./util";

export default memo(function ProfileDropdown({ close }: { close: () => void }) {
    const pageItems = usePageItems();
    const groupsItems = useGroups();
    const appItems = useAppControls();

    const sections = [pageItems, groupsItems, appItems].filter((item) => !!item.some((i) => !i.hide));

    return (
        <>
            {sections.map((items, i) => (
                <Fragment key={i}>
                    {i !== 0 && <Divider flexItem />}
                    <VerticalScroll sx={{ minHeight: "auto" }} maxHeight={230}>
                        <MenuItems items={items} px={2} py={1} spacing={1} iconWidth="30px" close={close} />
                    </VerticalScroll>
                </Fragment>
            ))}
        </>
    );
});

function ThemeSwitch() {
    const { theme, updateTheme } = useAppTheme();

    return (
        <Select
            sx={{ width: "100px", height: "30px" }}
            value={theme}
            setValue={updateTheme}
            options={[
                { value: "auto", label: "Device" },
                { value: "light", label: "Light" },
                { value: "dark", label: "Dark" }
            ]}
        />
    );
}

function useGroups(): MenuItemProps[] {
    const { groups } = useGroup();
    const { groupIdentifier } = useActiveGroup();
    const switchAccount = useSwitchAccount();
    const navigate = useAppNavigate();

    const personalGroups = groups?.filter((g) => g.group?.groupType === AbfGroupType.Individual);

    const otherGroups =
        groups
            ?.map((g) => g.group)
            .filter((g): g is AbfUserGroup => !!g)
            .sort((a, b) =>
                a.groupType === b.groupType ? a.groupName.localeCompare(b.groupName) : b.groupType - a.groupType
            ) || [];
    const items: MenuItemProps[] = otherGroups.map((group) => ({
        text: greaterThan(personalGroups?.length, 1) ? group.groupName : formatGroup(group),
        icon: <Image sx={{ borderWidth: BORDER_RADIUS_SM }} size="25px" variant="rounded" src={group.groupImage} />,
        onClick: () => switchAccount(group.groupIdentifier),
        rowEnd:
            groupIdentifier === group.groupIdentifier ? (
                <TooltipText helpText="Current organization" icon={false}>
                    <CheckOutlined />
                </TooltipText>
            ) : undefined
    }));

    if (items.length <= 1) return [];

    return [
        ...items,
        {
            text: "Create Organization",
            icon: <AddOutlined />,
            onClick: () => navigate(ORGANIZATION_CREATE_SLUG)
        }
    ];
}

function useAppControls(): MenuItemProps[] {
    const { logOut } = useAbfLogout();
    const { isWalletBased } = useAuth();
    const copy = useCopyAddress();
    const copyToClipboard = useCopyToClipboard();

    const { activeWallet } = useActiveWallet();
    const { data: referral } = useUserReferral();

    const referralLink = `${BS_SHORT_URL_INVITE}/${referral?.userReferralCode}`;

    return [
        {
            text: "Theme",
            icon: <ContrastOutlined />,
            disableClose: true,
            rowEnd: <ThemeSwitch />
        },
        {
            text: "Copy address",
            icon: <Icon type="copy" />,
            onClick: () => copy(activeWallet?.wallet ?? ""),
            hide: !activeWallet?.wallet || !isWalletBased
        },
        {
            text: "Share referral link",
            icon: <ShareOutlined />,
            onClick: () => copyToClipboard(referralLink, { successMessage: `${referralLink} copied to clipboard` }),
            hide: !referral?.userReferralCode
        },
        {
            text: isWalletBased ? "Disconnect" : "Log Out",
            icon: <Logout />,
            onClick: logOut
        }
    ];
}

function usePageItems() {
    const navigate = useAppNavigate();

    const { isAdmin, isCustodianAdmin } = useUiPermission();
    const { groups } = useGroup();
    const { isPrime } = useUserIsPrime();

    const { hasPermission } = useUserBetaAccess();

    const personalGroupExists = groups?.find((g) => g.group?.groupType === AbfGroupType.Individual);

    const items: MenuItemProps[] = [
        {
            text: "Portfolio",
            icon: <PieChartOutline />,
            onClick: () => navigate(PORTFOLIO_OVERVIEW_SLUG),
            hide: !hasPermission || isCustodianAdmin
        },
        {
            text: "Account",
            icon: <AccountBoxOutlined />,
            onClick: () => navigate(ACCOUNT_SETTINGS_SLUG),
            hide: !hasPermission
        },
        {
            text: "Organization",
            onClick: () => navigate(ORGANIZATION_SETTINGS_SLUG),
            icon: <GroupOutlined />,
            hide: !isAdmin
        },
        {
            text: "Loopscale Prime",
            onClick: () => navigate(PRIME_SLUG),
            icon: <StarOutline />,
            hide: !hasPermission
        },
        {
            text: "Bank transfers",
            onClick: () => navigate(BANK_TRANSFERS_SLUG),
            icon: <AccountBalanceOutlined />,
            hide: !isPrime || !hasPermission
        },

        {
            text: personalGroupExists ? "Create organization" : "New account",
            icon: <AddOutlined />,
            onClick: () => navigate(ORGANIZATION_CREATE_SLUG),
            // if >1 groups then it will show in group dropdown
            hide: (!!groups?.length && groups.length > 1) || !isPrime || !hasPermission
        }
    ];

    return items;
}
