import { MarketExpanded } from "@bridgesplit/abf-react";
import { StatProps, StatRow } from "@bridgesplit/ui";
import { formatUsd } from "@bridgesplit/utils";

import { useMarketSummaryStats } from "./util";

export default function BorrowStats({ markets }: { markets: MarketExpanded[] | undefined }) {
    const marketStats = useMarketSummaryStats(markets);

    const stats: StatProps[] = [
        { value: formatUsd(marketStats?.totalDeposits), caption: "Supplied" },
        { value: formatUsd(marketStats?.principalUtilized), caption: "Borrowed" }
    ];

    return <StatRow loading={!marketStats} stats={stats} />;
}
