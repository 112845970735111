import { useMemo } from "react";

import { AppButton, useLoopWindAlerts } from "app/components/common";
import { COPY } from "app/constants";
import { Column, TagTextAlert, Text } from "@bridgesplit/ui";
import { BsMetaUtil, getLoopBorrowCapDetails, isValidLeverage, useEscrowPreference } from "@bridgesplit/abf-react";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";

import { useLoopBalanceChecker, useLoopWind } from "./util";
import { useWindContext } from "./WindContext";
import { useLoopContext } from "../LoopContext";

const MIN_COLLATERAL_USD = 1;
export default function WindCta() {
    const wind = useLoopWind();
    const { form, isQuoteFetching, quote, externalQuote } = useWindContext();

    const { insufficientBalance } = useLoopBalanceChecker();
    const { loopExpanded } = useLoopContext();
    const { escrowNeeded } = useEscrowPreference();
    const { windDisabledWithMessage } = useLoopWindAlerts(loopExpanded);

    const borrowCapDetails = useMemo(() => {
        if (!loopExpanded) return undefined;
        return getLoopBorrowCapDetails(loopExpanded);
    }, [loopExpanded]);

    const ctaError = useMemo(() => {
        if (!form.collateralAmount) return "Enter an amount";
        if (!isValidLeverage(form.multiplier)) return `Enter ${COPY.LEVERAGE_TERM.toLowerCase()}`;
        if (isQuoteFetching) return "Fetching";
        return undefined;
    }, [form.collateralAmount, form.multiplier, isQuoteFetching]);

    const alertError = useMemo(() => {
        if (isQuoteFetching) return undefined;
        if (borrowCapDetails?.remainingGlobalCapacity === 0)
            return `${BsMetaUtil.getSymbol(loopExpanded?.principalToken)} borrow cap exceeded`;
        if (windDisabledWithMessage) return windDisabledWithMessage;
        if (!isQuoteFetching && form.collateralAmount && form.multiplier) {
            if (quote === null) return "No quote found";
            if (!externalQuote) return "No route found";
        }
        const collateralUsd = loopExpanded?.collateralOracle.getUsdAmount(form.collateralAmount);
        if (collateralUsd && collateralUsd < MIN_COLLATERAL_USD)
            return `Positions must be at least $${MIN_COLLATERAL_USD}`;
        if (borrowCapDetails && quote?.principalAmount) {
            const { exceedsGlobalCap, exceedsPerLoanCap } = borrowCapDetails;

            if (exceedsPerLoanCap(quote.principalAmount)) return "Exceeds borrow limit";
            if (exceedsGlobalCap(quote.principalAmount)) return "Exceeds borrow cap";
        }
        if (insufficientBalance) return "Insufficient balance";
        if (escrowNeeded) return `${COPY.LOOP_TERM.toLowerCase()} is only supported for individual accounts`;

        return undefined;
    }, [
        isQuoteFetching,
        borrowCapDetails,
        loopExpanded?.principalToken,
        windDisabledWithMessage,
        loopExpanded?.collateralOracle,
        form.collateralAmount,
        form.multiplier,
        quote,
        insufficientBalance,
        escrowNeeded,
        externalQuote
    ]);

    return (
        <Column spacing={1}>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                isTransaction
                asyncCta={{
                    onClickWithResult: wind,
                    options: { alertOnError: true }
                }}
                color="secondary"
                disabled={!!alertError || !!ctaError}
            >
                {ctaError || COPY.LOOP_TERM_ACTION}
            </AppButton>
            <BorrowRiskMessage loopExpanded={loopExpanded} />
        </Column>
    );
}

interface BorrowRiskMessageProps {
    loopExpanded?: { type: LoopRoutePlatform };
}

const messages = {
    [LoopRoutePlatform.Meteora]: "Setting up your position may include a small fee dependent on pool ratio.",
    [LoopRoutePlatform.Jupiter]: "Setting up your position includes a small Jupiter swap fee."
} as const;

function BorrowRiskMessage({ loopExpanded }: BorrowRiskMessageProps) {
    const message = loopExpanded?.type && messages[loopExpanded.type];

    return message ? (
        <Text variant="body2" color="caption" loading={!loopExpanded}>
            {message} Closing positions early may result in a loss
        </Text>
    ) : null;
}
