import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { AbfEscrowAccount, ROLE_UPDATE_ENDPOINT } from "@bridgesplit/abf-sdk";

import {
    AbfCustodianPermissionsReturn,
    AbfGroupPermissionsReturn,
    AbfUserGroup,
    AbfGroupWithPermissions,
    AbfCheckGroupOnChainRolesReturn,
    AbfRolesTransaction,
    AbfUserGroupMetadata,
    PutAbfUserGroupMetadata,
    AbfGroupDocuments,
    GroupDocumentInsert,
    AbfCreateUserGroupParams,
    AbfUserGroupAndEscrow
} from "../types";
import {
    ABF_GROUP_HEADER,
    GET_CUSTODIAN_MEMBERS_ROUTE,
    GET_GROUPS_BY_EMAILS_ROUTE,
    GET_GROUPS_METADATA_BY_ESCROWS_ROUTE,
    USER_CUSTODIAN_PERMISSONS,
    GET_GROUPS_ROUTE,
    USER_GROUP_PERMISSIONS_ROUTE,
    CHECK_GROUP_ON_CHAIN_ROLES_ROUTE,
    GROUP_ROLES_IS_SETUP,
    GROUP_ESCROWS_ROUTE,
    GROUP_METADATA_BY_IDENTIFIERS_ROUTE,
    FETCH_GROUP_DOCUMENTS_ROUTE,
    EDIT_GROUP_ROUTE
} from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const ADMIN_TAG = "Admin";
const GROUP_METADATA_TAG = "GroupMetadata";
const GROUP_DOCUMENTS_TAG = "GroupDocuments";
const GROUP_ESCROWS_TAG = "GroupEscrows";
export const groupAdminApi = createApi({
    reducerPath: "groupAdminApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ADMIN_TAG, GROUP_METADATA_TAG, GROUP_DOCUMENTS_TAG, GROUP_ESCROWS_TAG],
    endpoints: (builder) => ({
        groupsByIdentifiers: builder.query<Record<string, AbfUserGroup>, string[]>({
            providesTags: [GROUP_ESCROWS_TAG],
            query(identifiers) {
                return {
                    body: identifiers,
                    url: GET_GROUPS_ROUTE,
                    method: "POST"
                };
            }
        }),
        groupsByEscrows: builder.query<Record<string, AbfUserGroupAndEscrow>, string[]>({
            providesTags: [GROUP_ESCROWS_TAG],
            query(escrows) {
                return {
                    body: escrows,
                    url: GET_GROUPS_METADATA_BY_ESCROWS_ROUTE,
                    method: "POST"
                };
            }
        }),
        custodianMembers: builder.query<AbfCustodianPermissionsReturn, string>({
            providesTags: [ADMIN_TAG],
            query(groupIdentifier) {
                return {
                    url: GET_CUSTODIAN_MEMBERS_ROUTE,
                    method: "POST",
                    // force org to rerender on group change
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        groupCustodianPermissions: builder.query<AbfGroupWithPermissions, void>({
            providesTags: [ADMIN_TAG],
            query() {
                return {
                    url: USER_CUSTODIAN_PERMISSONS,
                    method: "GET"
                };
            }
        }),
        groupByAdminEmails: builder.query<Record<string, AbfUserGroup[]>, string[]>({
            query(emails) {
                return {
                    url: GET_GROUPS_BY_EMAILS_ROUTE,
                    method: "POST",
                    body: emails
                };
            }
        }),
        checkGroupOnChainRoles: builder.query<AbfCheckGroupOnChainRolesReturn, string>({
            providesTags: [ADMIN_TAG],
            query(groupIdentifier) {
                return {
                    url: CHECK_GROUP_ON_CHAIN_ROLES_ROUTE,
                    method: "POST",
                    // force org to rerender on group change,
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        groupMembers: builder.query<AbfGroupPermissionsReturn, string>({
            providesTags: [ADMIN_TAG],
            query(groupIdentifier) {
                return {
                    url: USER_GROUP_PERMISSIONS_ROUTE,
                    method: "GET",
                    // force org to be passed in to refresh renders
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),

        verifyGroupRoleSetup: builder.query<boolean, string>({
            providesTags: [ADMIN_TAG],
            query(groupIdentifier) {
                return {
                    url: GROUP_ROLES_IS_SETUP,
                    method: "GET",
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        groupUpdateTransactions: builder.query<AbfRolesTransaction[], string>({
            providesTags: [ADMIN_TAG],
            query(groupIdentifier) {
                return {
                    url: ROLE_UPDATE_ENDPOINT,
                    method: "POST",
                    // force org to be passed in to refresh renders
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        groupEscrows: builder.query<AbfEscrowAccount[], string>({
            providesTags: [GROUP_ESCROWS_TAG],
            query(groupIdentifier) {
                return {
                    url: GROUP_ESCROWS_ROUTE,
                    method: "GET",
                    // force org to be passed in to refresh renders
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        groupMetadataByIdentifiers: builder.query<Map<string, AbfUserGroupMetadata>, string[]>({
            providesTags: [GROUP_METADATA_TAG],
            query(groupIdentifiers) {
                return {
                    url: GROUP_METADATA_BY_IDENTIFIERS_ROUTE,
                    method: "POST",
                    body: groupIdentifiers
                };
            }
        }),
        updateGroup: builder.mutation<null, AbfCreateUserGroupParams>({
            invalidatesTags: [GROUP_METADATA_TAG, ADMIN_TAG],
            query(params) {
                return {
                    url: EDIT_GROUP_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        }),
        putGroupMetadata: builder.query<null, PutAbfUserGroupMetadata>({
            providesTags: [GROUP_METADATA_TAG],
            query(params) {
                return {
                    url: GROUP_METADATA_BY_IDENTIFIERS_ROUTE,
                    method: "PUT",
                    body: params
                };
            }
        }),
        groupDocumentsByIdentifiers: builder.query<Map<string, AbfGroupDocuments>, string[]>({
            providesTags: [GROUP_DOCUMENTS_TAG],
            query(groupIdentifiers) {
                return {
                    url: FETCH_GROUP_DOCUMENTS_ROUTE,
                    method: "POST",
                    body: groupIdentifiers
                };
            }
        }),
        putGroupDocuments: builder.query<null, { documents: GroupDocumentInsert[]; groupIdentifier: string }>({
            providesTags: [GROUP_DOCUMENTS_TAG],
            query(params) {
                return {
                    url: FETCH_GROUP_DOCUMENTS_ROUTE,
                    method: "PUT",
                    body: params.documents,
                    // force org to be passed in to refresh renders
                    headers: { [ABF_GROUP_HEADER]: params.groupIdentifier }
                };
            }
        })
    })
});
export const {
    useGroupsByIdentifiersQuery,
    useGroupsByEscrowsQuery,
    useVerifyGroupRoleSetupQuery,
    useGroupUpdateTransactionsQuery,
    useCheckGroupOnChainRolesQuery,
    useGroupCustodianPermissionsQuery,
    useCustodianMembersQuery,
    useGroupByAdminEmailsQuery,
    useGroupMembersQuery,
    useGroupEscrowsQuery,
    useGroupMetadataByIdentifiersQuery,
    useGroupDocumentsByIdentifiersQuery,
    usePutGroupDocumentsQuery,
    usePutGroupMetadataQuery,
    useUpdateGroupMutation
} = groupAdminApi;

export const useGroupApi = () => {
    const dispatch = useDispatch();

    return {
        resetGroupAdminApi: () => dispatch(groupAdminApi.util.invalidateTags([ADMIN_TAG])),
        resetGroupEscrowsApi: () => dispatch(groupAdminApi.util.invalidateTags([GROUP_ESCROWS_TAG])),
        resetGroupMetadataApi: () => dispatch(groupAdminApi.util.invalidateTags([GROUP_METADATA_TAG])),
        resetGroupDocumentsApi: () => dispatch(groupAdminApi.util.invalidateTags([GROUP_DOCUMENTS_TAG]))
    };
};
