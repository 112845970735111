import { ReactNode, memo, useMemo } from "react";

import {
    APP_MAX_WIDTH,
    CARD_WIDTH,
    Column,
    IconHeader,
    MediaQuery,
    Row,
    SPACING,
    SxType,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { AbfPermission, useGroup } from "@bridgesplit/abf-react";
import { LockOutlined } from "@mui/icons-material";

import {
    DESKTOP_PADDING,
    MOBILE_PADDING,
    NAV_BREAKPOINT,
    NAV_Z_INDEX,
    useNavbarHeight,
    Navbar,
    Footer
} from "../navbar";
import { AuthenticationAlerts } from "../auth";
import { GroupRequiredWrapper } from "../common";

type CustomPermission = boolean;

type BasePageProps = { children: ReactNode; sx?: SxType; mobileSx?: SxType; hideFooter?: boolean };

type MaxWidthWrapperProps = {
    spacing?: number;
    maxWidth?: string;
    hideAlerts?: boolean;
    children: ReactNode;
    maxWidthSx?: SxType;
};

type PageWithAuthProps = MaxWidthWrapperProps & {
    permission: AbfPermission | CustomPermission;
    allowNullGroup?: boolean;
};
type PageProps = BasePageProps & PageWithAuthProps;

// wrap PageBase like this to reduce renders
export default memo(function Page({ sx, mobileSx, children, hideFooter, allowNullGroup, ...props }: PageProps) {
    return (
        <PageBase hideFooter={hideFooter} sx={sx} mobileSx={mobileSx}>
            <GroupRequiredWrapper skip={allowNullGroup} action={null}>
                <PageWithAuth {...props}>{children}</PageWithAuth>
            </GroupRequiredWrapper>
        </PageBase>
    );
});

export function PageWithSidebar({
    sx,
    mobileSx,
    children,
    sidebar,
    breakpoint,
    width = CARD_WIDTH,
    ...props
}: BasePageProps & { breakpoint: MediaQuery; sidebar: ReactNode; width?: string; maxWidth?: string }) {
    const { height } = useNavbarHeight();

    const { border } = useAppPalette();

    return (
        <PageBase hideFooter sx={sx} mobileSx={mobileSx}>
            <Row spacing={4} sx={{ position: "relative", width: "100%", alignItems: "flex-start" }}>
                <Column sx={{ width: "100%", alignItems: "center" }}>
                    <MaxWidthWrapper {...props}>{children}</MaxWidthWrapper>
                </Column>
                <Column
                    sx={{
                        [breakpoint.below]: { display: "none" },
                        [breakpoint.above]: { width: "100%", maxWidth: width }
                    }}
                />
                <Column
                    sx={{
                        [breakpoint.below]: { display: "none" },
                        zIndex: NAV_Z_INDEX - 1,
                        width: width,
                        position: "fixed",
                        overflowY: "auto",
                        top: height,
                        right: 0,
                        borderLeft: border,
                        height: `calc(100% - ${height}px)`
                    }}
                >
                    {sidebar}
                </Column>
            </Row>
        </PageBase>
    );
}

function PageWithAuth({ children, permission, ...maxWidthProps }: PageWithAuthProps) {
    const { permissions } = useGroup();
    const authorized = useMemo(() => {
        if (typeof permission === "boolean") return permission;
        return permissions?.validate(permission);
    }, [permission, permissions]);

    if (authorized !== false) {
        return <MaxWidthWrapper {...maxWidthProps}>{children}</MaxWidthWrapper>;
    }

    return <Unauthorized />;
}

export function PublicPage({
    children,
    sx,
    mobileSx,
    hideFooter,
    ...maxWidthProps
}: MaxWidthWrapperProps & BasePageProps) {
    return (
        <PageBase sx={sx} mobileSx={mobileSx} hideFooter={hideFooter}>
            <MaxWidthWrapper {...maxWidthProps}>{children}</MaxWidthWrapper>
        </PageBase>
    );
}

export const PageBase = memo(function ({ children, sx, mobileSx, hideFooter }: BasePageProps) {
    const { height: navHeight, footer, navFooter } = useNavbarHeight();

    return (
        <>
            <Navbar />

            <Column
                id="page-max-center"
                sx={{
                    alignItems: "center",
                    [NAV_BREAKPOINT.above]: {
                        m: DESKTOP_PADDING,
                        mt: `${navHeight + SPACING * DESKTOP_PADDING}px`,
                        mb: `${footer + SPACING * MOBILE_PADDING}px`,

                        width: `calc(100% - ${SPACING * DESKTOP_PADDING * 2}px)`,
                        minHeight: `calc(100vh - ${navHeight + footer + SPACING * DESKTOP_PADDING * 2}px)`,
                        ...sx
                    },

                    [NAV_BREAKPOINT.below]: {
                        m: MOBILE_PADDING,
                        mt: `${navHeight + SPACING * MOBILE_PADDING}px`,
                        mb: `${footer + navFooter + SPACING * MOBILE_PADDING}px`,
                        width: `calc(100% - ${SPACING * MOBILE_PADDING * 2}px)`,
                        flexGrow: 1,
                        minHeight: `calc(100vh - ${navHeight + footer + navFooter + SPACING * MOBILE_PADDING * 2}px)`,
                        ...sx,
                        ...mobileSx
                    }
                }}
            >
                {children}
            </Column>
            {!hideFooter && <Footer />}
        </>
    );
});

function MaxWidthWrapper({
    children,
    spacing = 4,
    maxWidth = APP_MAX_WIDTH,
    hideAlerts,
    maxWidthSx
}: MaxWidthWrapperProps) {
    return (
        <Column id="page-max-width" spacing={spacing} sx={{ maxWidth, width: "100%", ...maxWidthSx }}>
            {!hideAlerts && <AuthenticationAlerts />}
            {children}
        </Column>
    );
}

function Unauthorized() {
    const { height: navHeight } = useNavbarHeight();

    return (
        <Column
            sx={{
                minHeight: `calc(100vh - ${navHeight}px - ${SPACING * DESKTOP_PADDING * 2}px)`,
                [NAV_BREAKPOINT.below]: {
                    height: `calc(100% - ${navHeight + SPACING * MOBILE_PADDING}px - ${SPACING * MOBILE_PADDING}px)`
                },
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            }}
            spacing={1}
        >
            <IconHeader>
                <LockOutlined />
            </IconHeader>
            <Column alignItems="center">
                <Text variant="h3"> Not authorized </Text>
                <Text color="caption"> You don't have permissions to view this page </Text>
            </Column>
        </Column>
    );
}
