import { useEffect, useMemo, useState } from "react";

import { Column, Row, Slider, TagTextAlert, Text } from "@bridgesplit/ui";
import debounce from "lodash.debounce";
import { formatPercent } from "@bridgesplit/utils";

import { TokenInputs } from "../common";
import { useOpenPositionDepositRatio } from "../open/util";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useUpdateWithdrawAmount, useWithdrawAmounts, useWithdrawWhirlpoolBalances } from "./util";

export default function WithdrawAmounts() {
    const { A, B } = useWithdrawWhirlpoolBalances();
    const { withdrawForm } = useWhirlpoolContext();
    const { tokenAAmount, tokenBAmount } = useWithdrawAmounts();

    const handleChange = useUpdateWithdrawAmount();
    const depositRatio = useOpenPositionDepositRatio();

    return (
        <Column spacing={2}>
            <WithdrawAlert />
            <WithdrawSlider />

            <TokenInputs
                handleChange={handleChange}
                form={{
                    status: withdrawForm.status,
                    tokenAAmount,
                    tokenBAmount
                }}
                ratio={depositRatio}
                isFetching={withdrawForm.status === "refetching"}
                getBelowInput={(side) =>
                    side === "A" ? (
                        <A.BalanceDisplay hideInsufficientBalance />
                    ) : (
                        <B.BalanceDisplay hideInsufficientBalance />
                    )
                }
            />
        </Column>
    );
}

function WithdrawSlider() {
    const [focused, setFocused] = useState(false);
    const [localValue, setLocalValue] = useState<number | undefined>(undefined);
    const {
        withdrawForm: { percentToWithdraw },
        setWithdrawForm
    } = useWhirlpoolContext();
    const { maxWithdrawPercent } = useWithdrawAmounts();

    const setValueDebounced = useMemo(
        () =>
            debounce((value: number) => {
                setWithdrawForm((prev) => ({
                    ...prev,
                    status: "refetch-needed",
                    percentToWithdraw: value
                }));
            }, 200),
        [setWithdrawForm]
    );

    useEffect(() => {
        return () => {
            setValueDebounced.cancel();
        };
    }, [setValueDebounced]);

    return (
        <Column>
            <Row spaceBetween>
                <Text variant="body2" color="caption">
                    Percent to withdraw
                </Text>
                <Text variant="body2">{formatPercent(percentToWithdraw)}</Text>
            </Row>
            <Slider
                onFocus={() => {
                    setFocused(true);
                    setLocalValue(percentToWithdraw);
                }}
                onBlur={() => setFocused(false)}
                step={0.001}
                min={0}
                disabled={maxWithdrawPercent === 0}
                max={maxWithdrawPercent}
                value={focused ? localValue : percentToWithdraw}
                setValue={(multiplier) => {
                    setLocalValue(multiplier);
                    setValueDebounced(multiplier);
                }}
            />
        </Column>
    );
}

function WithdrawAlert() {
    const { maxWithdrawPercent } = useWithdrawAmounts();
    if (maxWithdrawPercent) return null;
    return (
        <TagTextAlert icon="warning" color="warning">
            Your loan-to-value is too high to withdraw collateral
        </TagTextAlert>
    );
}
