import { ReactNode, useMemo } from "react";

import {
    AbfPermission,
    BsMetaUtil,
    TokenBalanceExpanded,
    abfEscrowedAssetApi,
    abfEscrowedAssetPublicApi,
    useAbfFetches,
    useActiveEscrow,
    useActiveWallet,
    useBsMetadataByMint,
    useEscrowPreference,
    useGroup,
    useUserEscrowedPrincipalByMint,
    useUserIsPrime,
    useWalletBalanceByMint
} from "@bridgesplit/abf-react";
import {
    Button,
    Column,
    FONT_SIZES,
    FONT_WEIGHTS,
    Icon,
    IconWithBackground,
    RefreshButtonWithLoading,
    Row,
    Span,
    Text,
    TextButton,
    TextVariant,
    Tooltip,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BoltOutlined } from "@mui/icons-material";
import { AppDialog, useAppDialog } from "app/utils";
import { COPY } from "app/constants";

type BalanceCheckOptions = {
    amount: number | undefined;
    mint: string | undefined;
    escrowNeeded?: boolean; // if omitted, will use escrow preference
    customBalance?: number;
    skip?: boolean;
    setMax?: (amount: number | undefined) => void;
    hideRowEnd?: boolean;
};

type BalanceProps = {
    variant?: TextVariant;
    maxText?: string;
    hideRefresh?: boolean;
    helpText?: string;
    hideInsufficientBalance?: boolean;
};

export function useBalanceChecker({
    amount,
    mint,
    escrowNeeded: escrowNeededOverride,
    skip: skipProps,
    customBalance,
    hideRowEnd,
    setMax
}: BalanceCheckOptions) {
    const { error, textPrimary, textSecondary } = useAppPalette();
    const { isMpcActive } = useActiveWallet();
    const { open } = useAppDialog();

    const { permissions } = useGroup();

    const { escrowNeeded: escrowPreference } = useEscrowPreference();
    const { activeWallet } = useActiveWallet();

    const skip = skipProps || !mint || !!customBalance;
    const escrowNeeded = escrowNeededOverride ?? escrowPreference;

    const walletBalance = useWalletBalanceByMint(activeWallet?.wallet, mint, {
        skip: !!escrowNeeded || !activeWallet?.wallet,
        fullSolBalance: false
    });
    const escrowBalance = useUserEscrowedPrincipalByMint(mint, {
        skip: !escrowNeeded || skip
    });
    const canDeposit = (escrowNeeded || isMpcActive) && permissions?.validate(AbfPermission.DepositFunds);

    const { isLoading, balance } = useMemo(() => {
        if (customBalance !== undefined) return { balance: customBalance, isLoading: false };

        if (escrowNeeded) return { balance: escrowBalance, isLoading: escrowBalance === undefined };
        return { balance: walletBalance.data?.amount ?? 0, isLoading: walletBalance.isLoading };
    }, [customBalance, escrowBalance, escrowNeeded, walletBalance]);

    const metadata = useBsMetadataByMint(mint);

    const insufficientBalance = !skipProps && amount && balance !== undefined ? balance < amount : false;

    const tokenBalance: TokenBalanceExpanded | undefined = metadata
        ? { key: metadata.assetMint, metadata, amount: balance ?? 0, mint: metadata.assetMint }
        : undefined;

    const RefreshBalance = ({ variant }: { variant: TextVariant }) => {
        if (escrowNeeded) {
            return <RefreshEscrowButton variant={variant} mint={mint} />;
        }
        return <RefreshWalletButton variant={variant} />;
    };

    const RowEnd = ({ variant = "body2" }: BalanceProps) => {
        if (hideRowEnd) return null;
        if (setMax && !!balance) {
            return (
                <TextButton color="secondary" variant={variant} onClick={() => setMax(balance)}>
                    Max
                </TextButton>
            );
        }
        if (canDeposit) {
            return (
                <Button
                    sx={{ fontWeight: insufficientBalance ? FONT_WEIGHTS.bold : undefined }}
                    variant="text"
                    textProps={{
                        variant,
                        sx: {
                            color: insufficientBalance ? textPrimary : textSecondary,
                            textDecoration: "underline"
                        }
                    }}
                    onClick={() => {
                        if (escrowNeeded) {
                            open(AppDialog.Deposit, {
                                specifiedAssetKeys: mint ? [mint] : undefined
                            });
                        } else {
                            open(AppDialog.DepositToWallet, { specifiedMint: mint });
                        }
                    }}
                >
                    Deposit
                </Button>
            );
        }
        return null;
    };

    const Balance = ({ variant = "body2", maxText, hideRefresh, helpText, hideInsufficientBalance }: BalanceProps) => {
        const defaultMaxText = escrowNeeded ? COPY.ESCROW_TERM : "Wallet";
        return (
            <Row spacing={0.5}>
                <Text color="caption" variant={variant}>
                    {maxText ?? defaultMaxText}:
                </Text>
                <Text color="caption" variant={variant}>
                    {BsMetaUtil.formatAmount(metadata, balance ?? 0)}
                </Text>
                {helpText && (
                    <Tooltip title={helpText}>
                        <Text color="disabled" variant={variant}>
                            <Icon type="tooltip" />
                        </Text>
                    </Tooltip>
                )}
                {!hideRefresh && <RefreshBalance variant={variant} />}

                {insufficientBalance && !hideInsufficientBalance && (
                    <TooltipText icon={false} helpText="Insufficient balance" variant={variant}>
                        <Icon sx={{ color: error }} type="warning" />
                    </TooltipText>
                )}
            </Row>
        );
    };

    const BalanceDisplay = (props: BalanceProps) => {
        if (isLoading) return <Row sx={{ height: FONT_SIZES.body1 + "px" }} />;
        if (hideRowEnd) return <Balance {...props} />;
        return (
            <Row spaceBetween>
                <Balance {...props} />

                <RowEnd {...props} />
            </Row>
        );
    };

    const BalanceWrapper = ({ variant = "body2", children }: { variant?: TextVariant; children: ReactNode }) => {
        return (
            <Column spacing={0.5}>
                {children}
                <BalanceDisplay variant={variant} />
            </Column>
        );
    };

    return {
        BalanceDisplay,
        BalanceWrapper,
        balance,
        tokenBalance,
        insufficientBalance,
        escrowNeeded,
        isLoading
    };
}

function RefreshWalletButton({ variant }: { variant: TextVariant }) {
    const { activeWallet } = useActiveWallet();
    const { resetEscrowedApi } = useAbfFetches();

    const { isFetching } = abfEscrowedAssetPublicApi.endpoints.tokensByWallet.useQueryState(
        activeWallet?.wallet ?? skipToken
    );

    return <RefreshButtonWithLoading variant={variant} isFetching={isFetching} callback={resetEscrowedApi} />;
}

function RefreshEscrowButton({ mint, variant }: { mint: string | undefined; variant: TextVariant }) {
    const { resetEscrowedApi } = useAbfFetches();

    const { activeEscrow } = useActiveEscrow();

    const { isFetching } = abfEscrowedAssetApi.endpoints.escrowedAssets.useQueryState({
        asset_mint: mint,
        escrow_accounts: activeEscrow ? [activeEscrow] : []
    });

    return <RefreshButtonWithLoading variant={variant} isFetching={isFetching} callback={resetEscrowedApi} />;
}

export function TransferActionsList({ close }: { close: () => void }) {
    const { hoverBackground } = useAppPalette();

    const { items } = useTransferItems();

    return (
        <>
            {items.map(({ icon, onClick, header }, i) => (
                <Row
                    sx={{ px: 2, py: 1, cursor: "pointer", ":hover": { background: hoverBackground } }}
                    key={i}
                    spacing={1}
                    onClick={() => {
                        onClick();
                        close();
                    }}
                >
                    <IconWithBackground size={20} variant="caption">
                        {icon}
                    </IconWithBackground>
                    <Text>{header}</Text>
                </Row>
            ))}
        </>
    );
}

export function TransferButtons({
    type,
    includeStakedSol
}: {
    type: keyof ReturnType<typeof useTransferItems>;
    includeStakedSol?: boolean;
}) {
    const { open: openDialog } = useAppDialog();
    const back = () => openDialog(AppDialog.Wallet, undefined);

    const items = useTransferItems({ includeStakedSol, back })[type];
    const { secondary } = useAppPalette();

    const isColumn = items.length > 2;

    return (
        <Row sx={{ gap: 1, flexGrow: 1 }}>
            {items.map(({ icon, onClick, cta }, i) => (
                <Button
                    fullWidth
                    textProps={{
                        variant: isColumn ? "body2" : undefined,
                        sx: {
                            flexDirection: isColumn ? "column" : undefined,
                            svg: { fontSize: isColumn ? FONT_SIZES.h3 + "px" : "inherit", color: secondary }
                        }
                    }}
                    variant="outlined"
                    key={i}
                    height={isColumn ? 60 : undefined}
                    onClick={onClick}
                >
                    {icon}
                    <Span>{cta}</Span>
                </Button>
            ))}
        </Row>
    );
}

type TransferItem = { header: string; cta: string; icon: JSX.Element; onClick: () => void };
function useTransferItems(params?: { includeStakedSol?: boolean; back?: () => void }) {
    const { open: openDialog } = useAppDialog();
    const { isPrime } = useUserIsPrime();

    const back = params?.back;

    const escrowItems: TransferItem[] = [
        {
            icon: <Icon type="deposit" />,
            cta: "Deposit",
            header: `Deposit to ${COPY.ESCROW_TERM.toLowerCase()}`,
            onClick: () => openDialog(AppDialog.Deposit, { back })
        },
        {
            icon: <Icon type="withdraw" />,
            cta: "Withdraw",
            header: `Withdraw from ${COPY.ESCROW_TERM.toLowerCase()}`,
            onClick: () => openDialog(AppDialog.Withdraw, { back })
        }
    ];

    const walletItems: TransferItem[] = [
        {
            icon: <Icon type="receive" />,
            header: "Receive",
            cta: "Receive",
            onClick: () => openDialog(AppDialog.DepositToWallet, { back })
        },
        {
            icon: <Icon type="send" />,
            header: "Send",
            cta: "Send",
            onClick: () => openDialog(AppDialog.WithdrawFromWallet, { back })
        }
    ];

    if (params?.includeStakedSol) {
        const getStake = (balances: "wallet" | "escrow") => ({
            icon: <BoltOutlined />,
            cta: "Unstake",
            header: "Unstake",
            onClick: () => openDialog(AppDialog.InstantUnstake, { balances, back })
        });
        walletItems.push(getStake("wallet"));
        escrowItems.push(getStake("escrow"));
    }

    const items = isPrime ? [...walletItems, ...escrowItems] : walletItems;
    return { items, walletItems, escrowItems };
}
