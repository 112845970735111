export enum OnboardingStep {
    BetaWelcome, // deprecated
    Prime,
    Borrow,
    Lend,
    LitePro, // deprecated
    Earn
}

export interface UserOnboarding {
    id: number;
    userIdentifier: string;
    onboardingStep: OnboardingStep;
}

export enum MarketGuideMode {
    Borrow,
    Lend,
    BorrowAndLend,
    Earn
}
