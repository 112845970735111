import { ChainId } from "@bridgesplit/abf-sdk";
import { NullableRecord, ParsedWei } from "@bridgesplit/utils";

import { TrackedTransaction } from "./wormhole";

export interface EvmNftsByOwnerQuery {
    sourceChain: ChainId;
    owner: string;
    includeSpam?: boolean;
}

export interface EvmBalance {
    sourceChain: ChainId;
    address: string;
}

export interface EvmNftMetadataParam {
    sourceChain: ChainId;
    tokenId: string;
    contract: string;
}

export interface EvmLiteNft {
    name: string;
    symbol: string;
    solanaMint: string;
    externalUrl: string;
    tokenId: string;
    contract: string;
    image: string;
}

export type EvmLiteNftRaw = NullableRecord<EvmLiteNft>;

export interface EvmTransferTransaction {
    id: number;
    transactionSignature: string;
    fromWalletPubkey: string;
    toWalletPubkey: string;
    amount: number;
    contract: string;
    tokenId?: string;
}
export interface EvmTransferTransactionInfo {
    transactionInfo: TrackedTransaction;
    evmTransferTransactionInfo: EvmTransferTransaction;
}

export enum EvmTransactionType {
    ApproveNft,
    TransferNft,
    TransferNative,
    Bridge,
    Claim
}
export interface EvmGasQuote {
    maxFeePerGas: ParsedWei;
    evmTransactionToGas: Record<EvmTransactionType, ParsedWei>;
}
