import { useState } from "react";

import { DialogWrapper, useMemoizedMap } from "@bridgesplit/ui";
import { percentDecimalsToUi } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { useStrategyTerms } from "../terms/util";
import StrategyTermsTable from "../terms/StrategyTermsTable";
import { StrategyTermsProps } from "../terms/type";
import StrategyTermsCta from "../terms/StrategyTermsCta";
import { DialogHeader } from "../../common";

export default function StrategyTermsDialog() {
    const { getData } = useAppDialog();
    const strategy = getData<AppDialog.StrategyTerms>()?.strategy;
    const [termToApyState, setTermToApy] = useState<Map<string, number | undefined>>();

    const terms = useStrategyTerms(
        strategy,
        strategy?.collateral.map((t) => t.metadata.assetMint)
    );

    const initialTerms = useMemoizedMap(
        terms,
        (t) => t.key,
        (t) => (t.minApy !== undefined ? percentDecimalsToUi(t.minApy) : undefined)
    );

    const termToApy = termToApyState ?? initialTerms;

    const props: StrategyTermsProps = {
        strategy,
        terms,
        termToApy,
        setTermToApy
    };
    return (
        <DialogWrapper>
            <DialogHeader header={AppDialog.StrategyTerms} description={null} />
            <StrategyTermsTable {...props} />
            <StrategyTermsCta {...props} />
        </DialogWrapper>
    );
}
