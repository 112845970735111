export enum ExternalYieldSource {
    None,
    MarginFi
}

export interface ExternalYieldInfo {
    externalYieldSource: ExternalYieldSource;
    balance: number;
    apy: number;
}

export interface ExternalYieldVault {
    yieldSourceVault: string;
    principalMint: string;
    yieldSource: ExternalYieldSource;
    totalAssetShares: number;
    valuePerShare: number;
    apy: number;
    lastUpdateTime: number;
}

export interface ExternalYieldAccrued {
    yieldSource: number;
    interestAccrued: number;
}
