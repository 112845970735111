import { createApi, skipToken } from "@reduxjs/toolkit/query/react";
import { AbfCustodian, BsMetadata } from "@bridgesplit/abf-sdk";
import { filterTruthy } from "@bridgesplit/utils";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import { RawCustodianResponse } from "../types";
import { abfBaseQueryPublic, abfSerializeQueryArgs } from "./util";
import { GET_CUSTODIANS_ROUTE, GET_CUSTODIANS_BY_SLUG_ROUTE } from "../constants";
import { useUserPermissionedOrgs } from "../api";
import { transformRawCustodian } from "./custodianApi";

const ORGANIZATION_PUBLIC_TAG = "Custodian";

export const abfCustodianPublicApi = createApi({
    reducerPath: "abfCustodianPublicApi",
    baseQuery: abfBaseQueryPublic,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ORGANIZATION_PUBLIC_TAG],
    endpoints: (builder) => ({
        custodiansByIdentifiers: builder.query<AbfCustodian[], string[]>({
            providesTags: [ORGANIZATION_PUBLIC_TAG],
            query(params) {
                return {
                    url: GET_CUSTODIANS_ROUTE,
                    method: "GET",
                    params: { page: 0, page_size: params.length }
                };
            },
            transformResponse: (raw: RawCustodianResponse[]) => {
                return raw.map((r) => transformRawCustodian(r));
            }
        }),
        custodianBySlug: builder.query<AbfCustodian, string>({
            providesTags: [ORGANIZATION_PUBLIC_TAG],
            query(slug) {
                return {
                    url: `${GET_CUSTODIANS_BY_SLUG_ROUTE}/${slug}`,
                    method: "GET"
                };
            },
            transformResponse: transformRawCustodian
        })
    })
});

export function useCustodiansByIdentifiers(identifiers: (string | null | undefined)[] | undefined) {
    const { identifiers: allowedIdentifiers } = useUserPermissionedOrgs();
    const identifiersToQuery = allowedIdentifiers.filter(filterTruthy);

    const { data, isLoading } = abfCustodianPublicApi.endpoints.custodiansByIdentifiers.useQuery(
        identifiersToQuery ?? skipToken,
        {
            skip: !allowedIdentifiers.length
        }
    );

    const cache = useMemoizedKeyMap(data, (d) => d.groupIdentifier);

    const custodians = data;

    return { custodians, cache, isLoading: isLoading || !data };
}
export function useCustodianByMetadata(metadata: BsMetadata | undefined) {
    const identifier = metadata?.assetOriginator;
    return useCustodianByIdentifier(identifier);
}

export function useCustodianByIdentifier(identifier: string | undefined) {
    const { cache } = useCustodiansByIdentifiers(identifier ? [identifier] : []);
    return identifier ? cache?.get(identifier) : undefined;
}

export const { useCustodianBySlugQuery } = abfCustodianPublicApi;
