import { AbfOrderResponse } from "@bridgesplit/abf-sdk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { OrderFilter } from "../types";
import { GET_ORDERS_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const ORDERS_TAG = "Orders";

export const abfOrderApi = createApi({
    reducerPath: "abfOrderApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ORDERS_TAG],
    endpoints: (builder) => ({
        orders: builder.query<AbfOrderResponse, OrderFilter>({
            providesTags: [ORDERS_TAG],
            query(params) {
                return {
                    url: GET_ORDERS_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        })
    })
});

export const { useOrdersQuery } = abfOrderApi;

export const useAbfOrderApi = () => {
    const api = abfOrderApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchAbfOrders] = api.orders.useLazyQuery();

    return {
        resetOrderApi: () => dispatch(abfOrderApi.util.invalidateTags([ORDERS_TAG])),
        fetchAbfOrders
    };
};
