import { ReactNode } from "react";

import { Button, Column } from "@bridgesplit/ui";
import { COPY } from "app/constants";

import { HeaderWithClose } from "../../common";
import { MobileBottomCta } from "../../wrappers";
import { useWindContext } from "../wind";

export default function LoopMobileCtaWrapper({ children, card }: { children: ReactNode; card: ReactNode }) {
    const { isActionOpen, setIsActionOpen } = useWindContext();

    return (
        <Column spacing={2}>
            {isActionOpen ? (
                <>
                    <HeaderWithClose variant="h4" close={() => setIsActionOpen(false)} header={COPY.LOOP_TERM_ACTION} />
                    {card}
                </>
            ) : (
                <>
                    {children}
                    <MobileBottomCta>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                setIsActionOpen(true);
                            }}
                        >
                            {COPY.LOOP_TERM_ACTION}
                        </Button>
                    </MobileBottomCta>
                </>
            )}
        </Column>
    );
}
