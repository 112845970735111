import { useCallback, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { Column, FONT_SIZES, Icon, Row, Text, TooltipText, useAppPalette } from "@bridgesplit/ui";
import { FormInputType } from "@bridgesplit/react";
import { greaterThan } from "@bridgesplit/utils";

import { useMarketContext } from "../common";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { TokenInputWithPrice } from "../../common";
import { BorrowMode, LTV_PRESETS } from "./type";
import { useMaxQuotesFetching, useSelectMarketPrincipal } from "../util";
import { useBorrowLtv, useMaxBorrowAmount } from "./util";
import BorrowLtv from "./BorrowLtv";

export default function BorrowAmount() {
    const { token } = useMarketContext();
    const selectPrincipal = useSelectMarketPrincipal();

    const { form, setForm, maxQuote, userCollateral, activeUserCollateral } = useMarketBorrowContext();

    const handleChange = useCallback(
        (amount: number | undefined, isMax?: boolean) => {
            setForm((prev) => {
                // prevent double form dispatch when input changes
                if (prev.amount === amount) return prev;

                const ltvMultiplier = (() => {
                    if (isMax) return LTV_PRESETS.Aggressive;
                    // set initial multiplier to moderate LTV if user inputs amount first
                    if (!prev.ltvMultiplier && amount) return LTV_PRESETS.Moderate;
                    return prev.ltvMultiplier;
                })();

                return {
                    ...prev,
                    ltvMultiplier,
                    refetchOutAmount: true,
                    amount,
                    mode: BorrowMode.InputPrincipal
                };
            });
        },
        [setForm]
    );

    const setValueDebounced = useMemo(() => debounce(handleChange, 500), [handleChange]);
    const [input, setInput] = useState<number>();
    const amount = form.mode === BorrowMode.InputPrincipal ? input ?? form.amount : form.amount;

    return (
        <Column spacing={0.5}>
            <Label
                setMax={(val) => {
                    setInput(val);
                    handleChange(val, true);
                }}
            />
            <TokenInputWithPrice
                loading={
                    form.refetchOutAmount &&
                    amount === undefined &&
                    maxQuote === undefined &&
                    activeUserCollateral?.amount !== 0 &&
                    userCollateral?.length !== 0
                }
                metadata={token}
                selectToken={selectPrincipal}
                value={amount}
                setValue={(val) => {
                    setInput(val);
                    setValueDebounced(val);
                }}
            />
            <BorrowLtv />
        </Column>
    );
}

function Label({ setMax }: { setMax: FormInputType<number | undefined> }) {
    const { token } = useMarketContext();
    const {
        form: { amount, collateralAmount },
        userCollateral,
        maxQuote
    } = useMarketBorrowContext();
    const { ltv } = useBorrowLtv();
    const { maxBorrow } = useMaxBorrowAmount();
    const { error } = useAppPalette();
    const isFetching = useMaxQuotesFetching();

    const isLoading = isFetching || !userCollateral;

    return (
        <Row sx={{ height: FONT_SIZES.body1 + "px" }} spaceBetween>
            <Text variant="body2">You borrow</Text>

            {!!collateralAmount && (
                <Row spacing={0.5}>
                    <Text color="caption" variant="body2">
                        Limit:
                    </Text>
                    <Text
                        loading={isLoading}
                        isLink
                        loadingWidth="50px"
                        onClick={() => setMax(maxBorrow)}
                        underlineLink="hover"
                        color="caption"
                        variant="body2"
                    >
                        {BsMetaUtil.formatAmount(token, maxBorrow ?? 0, { hideSymbol: true })}
                    </Text>
                    {!isLoading && greaterThan(amount, maxBorrow) && (
                        <TooltipText
                            tooltipProps={{ placement: "bottom" }}
                            icon={false}
                            helpText={
                                maxQuote && ltv > maxQuote.ltv
                                    ? "Exceeds your borrow limit"
                                    : "Exceeds available liquidity"
                            }
                            variant="body2"
                        >
                            <Icon sx={{ color: error }} type="warning" />
                        </TooltipText>
                    )}
                </Row>
            )}
        </Row>
    );
}
