import { MISSING_PARAM_ERROR, Result, doNothing } from "@bridgesplit/utils";
import {
    StrategyExpanded,
    abfLendingStrategyApi,
    abfLoanApi,
    groupAdminApi,
    useActiveGroup,
    useEditStrategySettings
} from "@bridgesplit/abf-react";

import { useTransactionSender } from "../../transactions";
import { UNCAPPED_STRATEGY_AMOUNT } from "../types";
import { StrategySettingsForm } from "./types";

const description = "Finalizing offer settings";

export function useRefreshStrategyAsync() {
    const [refetchStrategies] = abfLendingStrategyApi.endpoints.lendingStrategyInfos.useLazyQuery();
    const { groupIdentifier } = useActiveGroup();
    const [refetchEscrows] = groupAdminApi.endpoints.groupEscrows.useLazyQuery();
    const [refetchLoans] = abfLoanApi.endpoints.loanInfos.useLazyQuery();

    return async (strategy: StrategyExpanded) => {
        await Promise.all([
            refetchStrategies({ groups: groupIdentifier ? [groupIdentifier] : [] }),
            refetchEscrows(groupIdentifier ?? ""),
            refetchLoans({ lenders: [strategy.strategy.escrow] })
        ]);
    };
}

export function usePutStrategySettings() {
    const put = usePutStrategySettingsWithoutRefresh();
    const refresh = useRefreshStrategyAsync();

    return async (strategy: StrategyExpanded, form: StrategySettingsForm) => {
        const res = await put(strategy, form);
        if (res.isOk()) {
            await refresh(strategy);
        }
        return res;
    };
}

function usePutStrategySettingsWithoutRefresh() {
    const send = useTransactionSender();
    const editSettings = useEditStrategySettings();

    return async (strategy: StrategyExpanded, form: StrategySettingsForm) => {
        const { allowNewOffers } = form;
        if (!strategy) {
            return Result.errFromMessage(MISSING_PARAM_ERROR);
        }

        if (!strategy.escrowAccount) {
            return Result.errFromMessage("Escrow account not found");
        }

        const newOriginationCap = allowNewOffers ? UNCAPPED_STRATEGY_AMOUNT : 0;

        const editOriginationCap = newOriginationCap !== strategy.escrowAccount.originationCap;
        const editYieldSource = form.yieldSource !== strategy.escrowAccount?.externalYieldSource;
        const editEscrow = editOriginationCap || editYieldSource;

        if (editEscrow) {
            return await send(
                editSettings,
                {
                    strategyIdentifier: strategy.strategy.identifier,
                    originationCap: newOriginationCap,
                    editEscrow,
                    editLiquidationTypes: false,
                    externalYieldSource: form.yieldSource,
                    principalMint: strategy.strategy.principalMint
                },
                { description, sendOptions: { refetch: doNothing } }
            );
        }

        return Result.ok();
    };
}
