import { useActiveWallet } from "@bridgesplit/abf-react";
import { useUserPublicKey } from "@bridgesplit/react";
import { Button, WalletButtons } from "@bridgesplit/ui";
import { useWallet } from "@solana/wallet-adapter-react";

export function WalletCta() {
    const { isMpcActive } = useActiveWallet();

    const pubkey = useUserPublicKey();
    const { disconnect } = useWallet();

    if (isMpcActive) return null;

    if (pubkey) {
        return (
            <Button variant="contained" onClick={disconnect}>
                Disconnect
            </Button>
        );
    }

    return <WalletButtons />;
}

export function DisconnectWalletButton() {
    const { disconnect } = useWallet();

    return (
        <Button variant="contained" onClick={disconnect}>
            Disconnect
        </Button>
    );
}
