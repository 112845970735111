import { useMemo, useState } from "react";

import {
    AbfLoanExpanded,
    BestQuote,
    BsMetaUtil,
    FillStrategyOrderArgs,
    PresetStrategyTerms,
    abfLoanApi,
    calculateHealthRatio,
    calculateLoanHealth,
    getContinuousCompoundedInterest,
    getLoanCollateralUsdValue,
    getOffsetDetailsFromPreset,
    useBestQuotes,
    useEscrowPreference,
    useFillStrategyOfferTransaction,
    useGetTransactionGenerationType,
    usePresets
} from "@bridgesplit/abf-react";
import {
    EmptyPlaceholder,
    LabelWrapper,
    Row,
    Select,
    StatColumn,
    StatProps,
    Text,
    TextSkeleton
} from "@bridgesplit/ui";
import {
    LOADING_ERROR,
    Result,
    bsMath,
    decimalsToBps,
    findMaxElement,
    formatDate,
    formatPercent,
    uiAmountToLamports
} from "@bridgesplit/utils";
import { formatDurationWithType, getDurationInSeconds, StrategyCollateralInfoParams } from "@bridgesplit/abf-sdk";
import { useAppNavigate } from "@bridgesplit/react";
import { useLocation } from "react-router-dom";
import { COPY, LOAN_SLUG } from "app/constants";
import { TrackTransactionEvent, TransactionTypes } from "app/types";
import { trackSubmitMarketBorrowOrder } from "app/hooks";

import { useTransactionFeeStat, useTransactionSender } from "../../transactions";
import { isStrategyDurationSame } from "../../market-detail/util";
import { AppButton, getLoanAssetPath, HealthChange } from "../../common";

type PresetWithInfo = {
    loanEndDate: number;
    bestQuote: BestQuote | undefined;
    totalDebt: number;
    preset: PresetStrategyTerms;
    healthRatio: number;
    ltv: number;
};

export default function RefinanceLoan({ loanExpanded }: { loanExpanded: AbfLoanExpanded | undefined }) {
    const principalAmount = loanExpanded?.debt?.total ?? 0;

    const collateralMints = loanExpanded?.collateral.map((c) => c.metadata.assetMint);
    const { presets } = usePresets(collateralMints);
    const { data: quotes } = useBestQuotes({
        collateralMints,
        principalMint: loanExpanded?.order.principalMint,
        presets,
        minPrincipalAmountLamports: uiAmountToLamports(principalAmount, loanExpanded?.principalMetadata.decimals)
    });
    const [presetState, setPreset] = useState<PresetWithInfo>();

    const presetWithOffsets = useMemo(() => {
        if (!quotes || !presets) return undefined;
        return presets?.map((p): PresetWithInfo => {
            const { loanEndDate } = getOffsetDetailsFromPreset(p);
            const bestQuote = quotes?.find((q) => isStrategyDurationSame(q, p));

            const totalDebt = bestQuote
                ? getContinuousCompoundedInterest({
                      principal: principalAmount,
                      subtractPrincipal: false,
                      apy: bestQuote.apy,
                      loanDuration: getDurationInSeconds(bestQuote.duration, bestQuote.durationType)
                  })
                : 0;
            const principalUsd = bsMath.mul(totalDebt, loanExpanded?.principalUsdPrice) ?? 0;
            const collateralUsd = getLoanCollateralUsdValue(loanExpanded);

            const healthRatio = calculateHealthRatio(principalUsd, collateralUsd, bestQuote?.liquidationThreshold);
            const ltv = principalUsd / collateralUsd;
            return { loanEndDate, bestQuote, totalDebt, preset: p, healthRatio, ltv };
        });
    }, [loanExpanded, presets, principalAmount, quotes]);

    const presetsWithQuotes = presetWithOffsets?.filter((p) => !!p.bestQuote);
    const validPresets = presetsWithQuotes?.filter((p) => !!p.bestQuote && p.bestQuote.liquidationThreshold > p.ltv);
    const preset = presetState ?? validPresets?.[0];
    const borrow = useMarketBorrow(loanExpanded);

    if (validPresets?.length === 0) {
        return (
            <EmptyPlaceholder
                header="No refinance options"
                description={
                    presetsWithQuotes?.length
                        ? "Your loan health is too low to be refinanced"
                        : "There aren't any offers matching your loan terms"
                }
            />
        );
    }

    return (
        <>
            <LabelWrapper label="Extend until">
                <Select
                    renderValue={preset ? () => <RenderDuration presetInfo={preset} /> : undefined}
                    loading={!validPresets}
                    options={
                        presetWithOffsets?.map((presetInfo) => ({
                            disabled: !presetInfo.bestQuote,
                            value: presetInfo.preset.presetStrategyIdentifier,
                            label: (
                                <Row spaceBetween sx={{ width: "100%", height: "30px" }}>
                                    <RenderDuration presetInfo={presetInfo} />
                                    <Text> {formatPercent(presetInfo.bestQuote?.apy)} APY </Text>
                                </Row>
                            )
                        })) ?? Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> })
                    }
                    value={preset?.preset.presetStrategyIdentifier}
                    setValue={(presetStrategyIdentifier) => {
                        const preset = presetWithOffsets?.find(
                            (p) => p.preset.presetStrategyIdentifier === presetStrategyIdentifier
                        );
                        if (!preset) return;
                        setPreset(preset);
                    }}
                />
            </LabelWrapper>
            <Stats loanExpanded={loanExpanded} preset={preset} principalAmount={principalAmount} />
            <AppButton
                isTransaction
                disabled={!principalAmount || !preset}
                asyncCta={{ onClickWithResult: () => borrow(principalAmount, preset), closeDialog: "onSuccess" }}
            >
                Refinance
            </AppButton>
        </>
    );
}

function RenderDuration({ presetInfo: { preset, loanEndDate } }: { presetInfo: PresetWithInfo }) {
    return (
        <Row spacing={1}>
            <Text>{formatDurationWithType(preset.duration, preset.durationType)}</Text>
            <Text color="caption">{formatDate(loanEndDate, "date", { showTime: "never" })}</Text>
        </Row>
    );
}

function Stats({
    loanExpanded,
    principalAmount,
    preset
}: {
    loanExpanded: AbfLoanExpanded | undefined;
    principalAmount: number;
    preset: PresetWithInfo | undefined;
}) {
    const { health: currentHealth } = calculateLoanHealth(loanExpanded);
    const transactionFeeStat = useTransactionFeeStat({ transactionType: TransactionTypes.Refinance });

    const stats = useMemo(
        (): StatProps[] => [
            {
                value: [formatPercent(loanExpanded?.apy), formatPercent(preset?.bestQuote?.apy)],
                caption: "APY"
            },
            {
                value: formatDate(preset?.loanEndDate),
                caption: "New due date"
            },
            {
                value: [
                    BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, principalAmount, { hideSymbol: true }),
                    BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, preset?.totalDebt)
                ],
                caption: "Total debt"
            },
            {
                value: <HealthChange currentHealth={currentHealth} previousHealth={preset?.healthRatio} />,
                tooltip: COPY.HEALTH_FACTOR_TOOLTIP,
                caption: COPY.HEALTH_FACTOR_TERM
            },
            transactionFeeStat
        ],
        [currentHealth, loanExpanded?.apy, loanExpanded?.principalMetadata, preset, principalAmount, transactionFeeStat]
    );

    return <StatColumn loading={!preset} stats={stats} />;
}

function useMarketBorrow(loanExpanded: AbfLoanExpanded | undefined) {
    const send = useTransactionSender();
    const fillOrder = useFillStrategyOfferTransaction();

    const getTransactionGenerationType = useGetTransactionGenerationType();
    const { escrowNeeded } = useEscrowPreference();
    const [fetchLoans] = abfLoanApi.useLazyLoanInfosQuery();
    const location = useLocation();
    const navigate = useAppNavigate();

    return async (principalAmount: number, preset: PresetWithInfo | undefined) => {
        if (!loanExpanded || !preset?.bestQuote || !principalAmount) return Result.errFromMessage(LOADING_ERROR);

        const transactionGenerationType = await getTransactionGenerationType();

        const quote = preset.bestQuote;

        const collateral = loanExpanded.collateral.map(
            ({ key, assetTypeDiscriminator }): StrategyCollateralInfoParams => ({
                amountFromEscrow: 0,
                amountFromWallet: 0,
                assetTermsIdentifier: quote.assetTermsIdentifier,
                assetKey: key,
                assetTypeDiscriminator
            })
        );

        const params: FillStrategyOrderArgs = {
            strategyIdentifier: quote.lendingStrategyIdentifier,
            collateral,
            principalDecimals: loanExpanded.principalMetadata.decimals,
            principalRequested: principalAmount,
            apy: decimalsToBps(quote.apy),
            ltv: decimalsToBps(quote.ltv),
            liquidationThreshold: decimalsToBps(quote.liquidationThreshold),
            lockboxAddress: loanExpanded.lockboxAddress,
            transactionGenerationType,
            useFillerEscrow: escrowNeeded,
            principalMint: loanExpanded.order.principalMint,
            refinancedOrder: loanExpanded.order.address,
            loanTransactionIdentifier: "Refinance loan"
        };

        // navigate to new refinanced loan only if on loan detail page
        async function onSuccess() {
            if (!loanExpanded || !location.pathname.includes(LOAN_SLUG)) return;
            const loans = await fetchLoans({
                borrowers: [loanExpanded.borrowerEscrow],
                principal_mints: [loanExpanded.order.principalMint],
                active: true
            });
            if (!loans.data) return;
            const lastLoan = findMaxElement(Object.values(loans.data.loans), (v) => v.loan.id);

            if (!lastLoan) return;
            navigate(getLoanAssetPath(lastLoan.loan));
        }

        return await send(fillOrder, params, {
            description: "Refinancing loan",
            sendOptions: { onSuccess },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitRefinance,
                params: trackSubmitMarketBorrowOrder({
                    params,
                    duration: quote,
                    source: "detail"
                })
            }
        });
    };
}
