import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { Meteora } from "@bridgesplit/bs-protos";

import { pandoraBaseQuery } from "./util";
import { ROUTE_METEORA_DEPOSIT_QUOTE, ROUTE_METEORA_WITHDRAW_QUOTE } from "../constants";

const METEORA_QUOTE_TAG = "METEORA_QUOTE_TAG";
export const meteoraApi = createApi({
    reducerPath: "meteoraApi",
    tagTypes: [METEORA_QUOTE_TAG],
    baseQuery: pandoraBaseQuery,
    endpoints: (builder) => ({
        meteoraDepositQuote: builder.query<Meteora.DynamicPoolDepositQuote, Meteora.DynamicPoolDepositQuoteParams>({
            providesTags: [METEORA_QUOTE_TAG],
            query: (params) => ({
                url: ROUTE_METEORA_DEPOSIT_QUOTE,
                method: "POST",
                body: params
            })
        }),
        meteoraWithdrawQuote: builder.query<Meteora.DynamicPoolWithdrawQuote, Meteora.DynamicPoolWithdrawQuoteParams>({
            providesTags: [METEORA_QUOTE_TAG],
            query: (params) => ({
                url: ROUTE_METEORA_WITHDRAW_QUOTE,
                method: "POST",
                body: params
            })
        })
    })
});

export const { useMeteoraDepositQuoteQuery, useMeteoraWithdrawQuoteQuery } = meteoraApi;

export function useMeteoraApi() {
    const dispatch = useDispatch();
    return {
        resetMeteoraApi: () => dispatch(meteoraApi.util.invalidateTags([METEORA_QUOTE_TAG]))
    };
}
