import axios from "axios";

import { BRIDGESPLIT_TS_API } from "../constants";
import { NullableRecord, Result } from "../types";
import { camelCaseToTitleCase } from "./formatting";

export const tsApi = axios.create({
    baseURL: BRIDGESPLIT_TS_API
});

export function handlePartialParams<Params extends object>(
    paramsNullable: NullableRecord<Params>,
    optionalParamKeys?: (keyof Params)[]
): Result<Params> {
    const missingKeys: string[] = [];
    Object.entries(paramsNullable).forEach(([_key, val]) => {
        const key = _key as keyof Params;

        if (optionalParamKeys?.includes(key)) return;
        if (val === undefined) {
            const parsed = typeof key === "string" ? camelCaseToTitleCase(key) ?? key : key.toString();
            missingKeys.push(parsed);
        }
    });

    if (missingKeys.length) {
        return Result.errFromMessage(`Missing parameters: ${missingKeys.join(", ")}`);
    }

    return Result.ok(paramsNullable as Params);
}
