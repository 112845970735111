import { SPHERE_CONVERSION_FEE_BPS, useUserBankTransfers } from "@bridgesplit/abf-react";
import { PayoutStatus, PayoutType, SpherePayoutExpanded } from "@bridgesplit/abf-sdk";
import {
    Column,
    HeaderCard,
    Table,
    TableAssetDisplay,
    TableColumn,
    Text,
    TextColor,
    TooltipText,
    createRows
} from "@bridgesplit/ui";
import { bpsToUiDecimals, formatDate, formatUsd } from "@bridgesplit/utils";

import { formatBank } from "./util";

export default function BankActivity() {
    const transfers = useUserBankTransfers();

    const data = transfers?.filter((d) => !isPendingPayout(d));

    return (
        <HeaderCard spacing={1} header="Transfers">
            <Text color="caption">Transfers typically take a few minutes to update</Text>
            <Table
                paginationProps={{
                    type: "managed",
                    defaultSortKey: "transfer"
                }}
                emptyPlaceholder="You haven't made any USD transfers"
                loading={!transfers}
                rows={createRows(data, (b) => b.id)}
                columns={columns}
            />
        </HeaderCard>
    );
}

const columns: TableColumn<SpherePayoutExpanded>[] = [
    {
        dataIndex: "transfer",
        title: "Transfer",
        width: "20%",
        render: ({ data }) => {
            const name = (() => {
                switch (data.payoutType) {
                    case PayoutType.OnRamp:
                        return "Deposit from Bank";
                    case PayoutType.Offramp:
                        return "Withdrawal to Bank";
                }
            })();

            return <TableAssetDisplay name={name} description={formatDate(data.updatedTime, "relative")} />;
        },
        sortBy: ({ data }) => data.createdTime
    },
    {
        dataIndex: "account",
        title: "Bank Account",
        width: "20%",
        render: ({ data }) => (
            <TableAssetDisplay name={data.bankAccount.bankName} description={data.bankAccount.last4} />
        ),
        sortBy: ({ data }) => formatBank(data.bankAccount)
    },
    {
        dataIndex: "amount",
        title: "Amount",
        width: "20%",
        align: "right",
        render: ({ data }) => {
            const color = ((): TextColor => {
                if (data.payoutStatus !== PayoutStatus.Success) return "body";
                if (data.payoutType === PayoutType.Offramp) return "error";
                return "success";
            })();
            return (
                <Column alignItems="flex-end">
                    <Text color={color}>{formatPayoutAmount(data)}</Text>
                    <TransferStatusDisplay payoutStatus={data.payoutStatus} />
                </Column>
            );
        },
        sortBy: ({ data }) => data.amount
    }
];

function TransferStatusDisplay({ payoutStatus }: { payoutStatus: PayoutStatus }) {
    switch (payoutStatus) {
        case PayoutStatus.Success:
            return null;
        case PayoutStatus.Failed:
            return (
                <TooltipText helpText="Payment failed" variant="body2" color="error">
                    Failed
                </TooltipText>
            );

        default:
            return (
                <TooltipText
                    helpText="Pending transfers are usually completed with 1-3 business days"
                    variant="body2"
                    color="caption"
                >
                    Pending
                </TooltipText>
            );
    }
}

// filter offramps that haven't been funded
function isPendingPayout(payout: SpherePayoutExpanded) {
    return payout.payoutStatus === PayoutStatus.Pending && payout.payoutType === PayoutType.Offramp;
}

function formatPayoutAmount(payout: SpherePayoutExpanded) {
    if (payout.payoutType === PayoutType.Offramp) return `- ${formatUsd(payout.amount)}`;

    // on-ramps should show the post fee balanace since that's what will go to user's account
    const receiveAmount = payout.amount * (1 - bpsToUiDecimals(SPHERE_CONVERSION_FEE_BPS));
    return `+ ${formatUsd(receiveAmount)}`;
}
