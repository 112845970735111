import { useState } from "react";

import { useUserCustodianPermissions } from "@bridgesplit/abf-react";
import { groupArrayElementsBy } from "@bridgesplit/utils";
import { AbfCustodian, ChainId } from "@bridgesplit/abf-sdk";
import { BackCta, Column, Image, OutlinedOption, Row, Text, TooltipText } from "@bridgesplit/ui";
import { useAsyncResultHandler } from "@bridgesplit/react";
import { CircularProgress } from "@mui/material";
import { COPY } from "app/constants";

import { CHAIN_METADATA } from "../../common";
import { ChainMetadata } from "../../../types";

type ChainsWithCustodian = ChainMetadata & {
    custodians: AbfCustodian[];
};

export default function ChainSelect({
    chain,
    setChain
}: {
    chain: ChainId;
    setChain: (chain: ChainId) => Promise<void>;
}) {
    const custodians = useUserCustodianPermissions();
    const custodiansByChain = custodians
        ? groupArrayElementsBy(custodians, (c) => c.custodian.sourceChain ?? ChainId.Solana)
        : undefined;
    const [recentlySelected, setRecentlySelected] = useState<ChainId>();

    const { handler, isLoading } = useAsyncResultHandler();

    const chainsWithCustodians = custodians
        ? Object.values(CHAIN_METADATA).map(
              (chain): ChainsWithCustodian => ({
                  ...chain,
                  custodians: custodiansByChain?.get(chain.chainId)?.map((c) => c.custodian) ?? []
              })
          )
        : undefined;

    return (
        <>
            <Text variant="h3"> Select network </Text>
            <Column spacing={1}>
                {chainsWithCustodians?.map((c) => {
                    const isRecentlySelected = c.chainId === recentlySelected;
                    const isSelected = recentlySelected === undefined ? c.chainId === chain : isRecentlySelected;

                    return (
                        <OutlinedOption
                            onClick={() =>
                                handler(async () => {
                                    setRecentlySelected(c.chainId);
                                    return await setChain(c.chainId);
                                })
                            }
                            disabled={!c.custodians.length}
                            key={c.chainId}
                            padding={1}
                            isSelected={isSelected}
                        >
                            <Row spacing={1} spaceBetween>
                                <Row sx={{ opacity: isLoading && !isRecentlySelected ? 0.5 : 1 }} spacing={1}>
                                    <Image variant="circle" src={c.logo} size="30px" />
                                    <Column>
                                        <Text> {c.name} </Text>
                                        <TooltipText
                                            tooltipProps={{ placement: "right" }}
                                            helpText={
                                                c.custodians.length
                                                    ? ""
                                                    : `You don't have access to any supported ${COPY.CUSTODIAN_TERM_FULL_PLURAL.toLowerCase()} from ${
                                                          c.name
                                                      }`
                                            }
                                            color="caption"
                                            variant="body2"
                                        >
                                            {formatCustodianName(c.custodians)}
                                        </TooltipText>
                                    </Column>
                                </Row>
                                {isLoading && isRecentlySelected && <CircularProgress color="inherit" size="1rem" />}{" "}
                            </Row>
                        </OutlinedOption>
                    );
                })}
            </Column>
            <BackCta back={() => setChain(chain)} />
        </>
    );
}

function formatCustodianName(custodians: AbfCustodian[], countToShow = 3) {
    if (!custodians.length) return "Insufficient permissions";

    const more = custodians.length - countToShow;
    const custodianNames = custodians
        .map((c) => c.name)
        .slice(0, countToShow)
        .join(", ");
    if (more <= 0) return custodianNames;
    return `${custodianNames} and ${more} more`;
}
