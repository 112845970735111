import {
    getWithdrawStakedSolAssetTransactions,
    StakedSolAssetArgs,
    getInstantUnstakeTransaction,
    UnstakeRequestWithRoute
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { Result, SOL_DECIMALS, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction } from "./common";

type InstantUnstakeParams = { transfer: StakedSolAssetArgs; unstake: UnstakeRequestWithRoute[] };
export function useInstantUnstakeStakeTransaction(): AbfTransactionDetails<InstantUnstakeParams> {
    const generate = useAbfGenerateTransaction();

    const { resetEscrowedApi, resetStakeApi, resetLockboxApi } = useAbfFetches();

    async function getTransactionsWithParams({ transfer, unstake }: InstantUnstakeParams): AbfGeneratorResult {
        try {
            const stakeAccounts = transfer.stakeAccounts.map(({ amountToTransfer, address }) => ({
                address,
                amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
            }));

            const transferStakeTxns = generate({
                generateFunction: getWithdrawStakedSolAssetTransactions,
                identifier: "Transfer Stake",
                params: {
                    ...transfer,
                    stakeAccounts
                }
            });

            const unstakeTxns = generate({
                generateFunction: getInstantUnstakeTransaction,
                identifier: "Swap to SOL",
                params: unstake // expects raw response in lamports
            });

            const txns = await combineTransactionPromises(
                stakeAccounts.length ? [transferStakeTxns, unstakeTxns] : [unstakeTxns],
                {
                    order: "sequential"
                }
            );

            return txns;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetEscrowedApi();
            resetLockboxApi();
            resetStakeApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing Asset" };
}
