import { Column, Link, OutlinedCard, Row, Text } from "@bridgesplit/ui";
import { BsMetaUtil, isWhirlpoolMetadata, RoleView } from "@bridgesplit/abf-react";
import { formatNum, formatPercent } from "@bridgesplit/utils";

import { CollateralMarket } from "./types";
import { TokenImage, getMarketPath } from "../../common";

export default function CollateralMarketCard({ market }: { market: CollateralMarket | undefined }) {
    return (
        <Link
            sx={{ width: "100%" }}
            to={getMarketPath({
                view: RoleView.Borrow,
                token: market?.principalMetadata,
                collateralMint: market?.metadata.assetMint
            })}
        >
            <OutlinedCard sx={{ flexGrow: 1 }} padding={2} spacing={3}>
                <Row sx={{ width: "100%" }} spaceBetween>
                    <Row spacing={1}>
                        <TokenImage size="lg" metadata={market?.metadata} />
                        <Column>
                            <Text loading={!market}>{BsMetaUtil.getSymbolUnique(market?.metadata)}</Text>
                            <Text color="caption" variant="body2" loading={!market}>
                                Your balance: {formatBalance(market)}
                            </Text>
                        </Column>
                    </Row>
                    {!!market && <Text>{formatPercent(market.refinanceInfo.ltv)} LTV</Text>}
                </Row>
            </OutlinedCard>
        </Link>
    );
}

function formatBalance(market: CollateralMarket | undefined) {
    if (BsMetaUtil.isNonFungible(market?.metadata) && isWhirlpoolMetadata(market?.metadata)) {
        return formatNum(market?.userBalance);
    }
    return BsMetaUtil.formatAmount(market?.metadata, market?.userBalance, { hideSymbol: true });
}
