import { ReactElement, ReactNode } from "react";

import { SortOrder } from "@bridgesplit/utils";
import { DispatchType } from "@bridgesplit/react";

import { EmptyPlaceholderProps } from "../misc";

export interface BaseTableProps<T> {
    rows: TableRow<T>[] | undefined;
    columns: TableColumn<T>[];

    paginationProps: ApiPaginationProps | ManagedPaginationProps<T>;

    loading?: boolean;
    emptyPlaceholder: EmptyPlaceholderProps | string;
    searchPlaceholder?: string;
    preventEmptyRows?: boolean; // only show empty rows when there is pagination
    searchRowElement?: ReactElement;
    onRowClick?: (row: TableRow<T>) => void;
    onRowNavigatePath?: (row: TableRow<T>) => string;
    isRowDisabled?: (row: TableRow<T>) => boolean;
}

export type SortBy<T> = (params: T) => TableKey;

type TableAlignment = "left" | "center" | "right";

export type TableColumn<T> = {
    dataIndex: string;
    title: string | JSX.Element;
    align?: TableAlignment;
    width?: string;
    toolTip?: string;
    render: (props: TableRow<T>, index: number) => ReactNode;
    sortBy?: SortBy<TableRow<T>>;
    apiSortKey?: string;
};

export type ComparatorType<K extends TableKey> = (a: K, b: K) => number;

export type TableKey = string | number;

export type TableRow<T> = {
    data: T;
    key: TableKey;
};

export interface InternalTableProps<T> {
    tableProps: BaseTableProps<T>;
    paginationController: PaginationController;
}

export const TABLE_CTA_COLUMN_KEY = "cta";

export interface PaginationProps {
    type: "api" | "managed";
    defaultSortKey?: string;
    defaultOrder?: SortOrder;
    pageSize?: number;
    firstPage?: 1 | 0;
}
export interface ManagedPaginationProps<T> extends PaginationProps {
    type: "managed";
    getSearchFromRow?: (row: TableRow<T>) => string[];
}

export interface ApiPaginationProps extends PaginationProps {
    type: "api";
    totalDataCount: number | undefined;
    controller: PaginationController;
    searchEnabled?: boolean;
}

export interface PaginationController {
    search?: string;
    setSearch?: DispatchType<string>;
    page: number;
    setPage: DispatchType<number>;
    resetPage: () => void;
    orderBy: string;
    setOrderBy: DispatchType<string>;
    order: SortOrder;
    setOrder: DispatchType<SortOrder>;
    totalDataCount?: number;
}
