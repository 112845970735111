import { PublicKey } from "@solana/web3.js";

import { IS_LOCAL_NET } from "./api";

export const USDC_MINT = IS_LOCAL_NET
    ? "usdc1E6MzGCqodcKShvdqRjZK82aAnCskT31bLkniqB"
    : "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
export const WRAPPED_SOL_MINT = "So11111111111111111111111111111111111111112";
export const STAKED_SOL_MINT = "sTSoN7tYft63PJjHw2NcgJ43saq3NiE3XCdfvXXcTrD";
export const EVM_NATIVE_MINT = "0x0000000000000000000000000000000000001010";
export const JITO_SOL_MINT = "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn";
export const JLP_MINT = "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4";
export const DEFAULT_PUBLIC_KEY = PublicKey.default.toString();
export const SOL_NATIVE_MINT = DEFAULT_PUBLIC_KEY;

export const USDC_SYMBOL = "USDC";
export const SOL_SYMBOL = "SOL";
export const STAKED_SOL_SYMBOL = "stakedSOL";

export const USDC_DECIMALS = 6;
export const DEFAULT_PRINCIPAL_MINT = USDC_MINT;
export const DEFAULT_PRINCIPAL_DECIMALS = USDC_DECIMALS;
export const DEFAULT_TXN_FEE = 0.000015;
export const SOL_DECIMALS = 9;
export const EVM_NATIVE_DECIMALS = 18;
export const PERCENT_DECIMALS = 3;

export const LOCKBOX_RENT = 0.008712;
export const MIN_SOL_BALANCE = 0.035;
export const RECOMMENDED_SOL_BALANCE = 0.05;

export const BASE_FEE_LAMPORTS = 5000;

export const USD_PRICING_QUOTE_ASSET = "USDp3cZrdNy5ayTcWwhQDR8XFcf8kaH8P1Ar381NT7q";
