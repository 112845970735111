import { AbfLendingStrategy, BsMetaUtil, StrategyTemplateExpanded } from "@bridgesplit/abf-react";
import { IconButton, Row, Text, Image, TableColumn, FONT_SIZES, Column } from "@bridgesplit/ui";
import { BsMetadata, formatDurationWithType, getDurationInSeconds } from "@bridgesplit/abf-sdk";
import { findMaxElement } from "@bridgesplit/utils";
import { STRATEGY_LOANS_SLUG } from "app/constants";

import { OverlappingMetadataImages } from "./asset";

export function getStrategyLoansPath(strategy: AbfLendingStrategy | undefined) {
    if (strategy) {
        return `${STRATEGY_LOANS_SLUG}/${strategy.escrow}`;
    }
    return "#";
}

export function SelectedCollateralToken({ metadata, remove }: { metadata: BsMetadata; remove: () => void }) {
    return (
        <Row spacing={0.5} sx={{ width: "max-content" }}>
            <Row spacing={1}>
                <Image variant="circle" size="16px" src={BsMetaUtil.getImage(metadata)} />
                <Text> {BsMetaUtil.getSymbol(metadata)} </Text>
            </Row>

            <IconButton
                onClick={remove}
                textColor="disabled"
                color="error"
                background="hover"
                border={false}
                textVariant="body2"
                icon="reject"
            />
        </Row>
    );
}

export const strategyTemplateColumns: TableColumn<StrategyTemplateExpanded>[] = [
    {
        dataIndex: "asset",
        title: "Asset",
        width: "40%",
        render: ({ data: { template } }) => (
            <Row spacing={1}>
                <Image variant="circle" src={template.logo} size="30px" />
                <Column>
                    <Text>{template.name}</Text>
                    <Text color="caption" variant="body2">
                        {template.description}
                    </Text>
                </Column>
            </Row>
        )
    },
    {
        dataIndex: "collateral",
        title: "Collateral",
        align: "left",
        width: "30%",
        render: ({ data: { collateral } }) => {
            return <OverlappingMetadataImages size={FONT_SIZES.h3 + "px"} metadata={collateral} />;
        }
    },

    {
        dataIndex: "duration",
        title: "Max duration",
        align: "left",
        width: "30%",
        render: ({ data }) => {
            const maxDuration = getMaxDurationForTemplate(data);

            return <> {formatDurationWithType(maxDuration?.duration, maxDuration?.durationType)} </>;
        }
    }
];

export const getMaxDurationForTemplate = (template: StrategyTemplateExpanded) =>
    findMaxElement(template.presets, ({ strategyTerms: { duration, durationType } }) =>
        getDurationInSeconds(duration, durationType)
    )?.strategyTerms;
