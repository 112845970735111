import { createApi } from "@reduxjs/toolkit/query/react";

import { ROUTE_ONBOARDING } from "../constants";
import { abfSerializeQueryArgs, rubyBaseQuery, unauthenticatedSerializeQueryArgs } from "./util";
import { OnboardingStep, UserOnboarding } from "../types";

const ONBOARDING_TAG = "Onboarding";

export const onboardingApi = createApi({
    reducerPath: "onboardingApi",
    tagTypes: [ONBOARDING_TAG],
    baseQuery: rubyBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        onboarding: builder.query<OnboardingStep[], void>({
            serializeQueryArgs: unauthenticatedSerializeQueryArgs,
            providesTags: [ONBOARDING_TAG],
            query() {
                return {
                    url: ROUTE_ONBOARDING,
                    method: "GET"
                };
            },
            transformResponse: (raw: UserOnboarding[]) => {
                return raw.map((r) => r.onboardingStep);
            }
        }),
        completeOnboarding: builder.mutation<void, OnboardingStep>({
            invalidatesTags: [ONBOARDING_TAG],
            query(step) {
                return {
                    url: `${ROUTE_ONBOARDING}/${step}`,
                    method: "POST"
                };
            }
        })
    })
});

export const { useOnboardingQuery, useCompleteOnboardingMutation } = onboardingApi;
