import { useUserIsPrime } from "@bridgesplit/abf-react";

import BankOverview from "./BankOverview";
import { Page } from "../wrappers";
import { PageTitle } from "../common";

export default function BankTransfersOverview() {
    const { isPrime } = useUserIsPrime();

    return (
        <Page permission={!!isPrime} spacing={4}>
            <PageTitle
                title="Bank transfers"
                description="Loopscale partners with specialized third-party platforms, enabling users to access asset-backed loans with diverse collateral options"
            />
            <BankOverview />
        </Page>
    );
}
