import { Fragment, useMemo } from "react";

import {
    LoopTransferType,
    LoopExpanded,
    calculateHistoricalLoopProfitLoss,
    LoopPositionExpanded,
    useJlpPerps,
    LoopscalePointSource,
    PointSource,
    useLoopsExpanded,
    useUnwindExternalQuote,
    calculateUnwindStats,
    isLoanActive,
    isLstLoop,
    sumLoopPositionUsdContributions,
    BsMetaUtil
} from "@bridgesplit/abf-react";
import { LOOP_DETAIL_SLUG } from "app/constants";
import { Box } from "@mui/material";
import {
    METEORA_SVG,
    repeatElement,
    Row,
    SPACING,
    Text,
    TextButton,
    TextSkeleton,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import {
    filterTruthy,
    bpsToUiDecimals,
    formatPercent,
    percentUiToDecimals,
    TIME,
    formatSeconds,
    getUnixTs,
    calculatePercentChange,
    formatUsd
} from "@bridgesplit/utils";
import { BsMetadata, LoopRoutePlatform, LoopRouteType } from "@bridgesplit/abf-sdk";
import { useLocalStorage } from "@bridgesplit/react";
import { SwapHoriz } from "@mui/icons-material";

import { getTokenImageSize, TokenImage, TokenSize } from "./asset";
import { RateAndRewardsBreakdown } from "../points";
import { DEFAULT_SLIPPAGE } from "./slippage";
import { RateDisplayProps } from "../points/app/RateAndRewardsBreakdown";
import { ProfitLossText } from "./util";

export function getLoopPath(loopExpanded: LoopExpanded) {
    return `${LOOP_DETAIL_SLUG}/${loopExpanded.vaultIdentifier}`;
}

export function LoopImage({
    loopExpanded,
    size: propsSize,
    offset = -0.25,
    badgeSize = "sm"
}: {
    loopExpanded: LoopExpanded | undefined;
    size: TokenSize;
    badgeSize?: TokenSize;
    offset?: number;
}) {
    const size = getTokenImageSize(propsSize);

    const [primaryToken, secondaryToken] =
        loopExpanded?.depositType === LoopTransferType.CollateralOnly
            ? [loopExpanded?.principalToken, loopExpanded?.collateralToken]
            : [loopExpanded?.collateralToken, loopExpanded?.principalToken];

    return (
        <Box sx={{ position: "relative", width: size, height: size }}>
            <TokenImage
                size={badgeSize}
                sx={{ position: "absolute", bottom: SPACING * offset, right: SPACING * offset, zIndex: 1 }}
                metadata={primaryToken}
            />
            <TokenImage size={propsSize} metadata={secondaryToken} />
        </Box>
    );
}

// TODO: Currently the points is manually maintained. Need to store point sources in database and integrate.

const COLLATERAL_MINT_TO_POINTS: Record<string, PointSource[]> = {
    xLebAypjbaQ9tmxUKHV6DZU4mY8ATAAP2sfkNNQLXjf: [PointSource.Meteora, PointSource.Loopscale],
    "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4": [PointSource.Loopscale],
    HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX: [PointSource.HubSol, PointSource.Loopscale],
    "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm": [PointSource.Loopscale],
    jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v: [PointSource.Loopscale],
    BNso1VUJnh4zcfpZa6986Ea66P6TCp59hvtNJ8b1X85: [PointSource.Loopscale]
};
export function getLoopEligibleRewardSources(
    types: BsMetadata[] | BsMetadata | undefined,
    includeLoopscale = true
): PointSource[] {
    if (!types) {
        return [];
    }

    const typeArray = Array.isArray(types) ? types : [types];

    const allSources = new Set<PointSource>();

    typeArray.forEach((type) => {
        if (type?.assetMint) {
            const mintAddress = type.assetMint.toString();
            const sources = COLLATERAL_MINT_TO_POINTS[mintAddress] || [];
            sources.forEach((source) => allSources.add(source));
        }
    });

    const sourcesArray = Array.from(allSources);
    return includeLoopscale ? sourcesArray : sourcesArray.filter((source) => source !== PointSource.Loopscale);
}

export function LoopMarketApy({
    loopExpanded,
    isTable,
    rateDisplayProps
}: {
    loopExpanded: LoopExpanded | undefined;
    isTable?: boolean;

    rateDisplayProps?: RateDisplayProps;
}) {
    const { data: loopsExpanded } = useLoopsExpanded({}, { skip: !isTable });

    const allApys = loopsExpanded?.map((l) => l.loopVault.maxLeveragedApyPct);

    const loopSources = getLoopEligibleRewardSources(loopExpanded?.collateralToken);

    return (
        <RateAndRewardsBreakdown
            rateDisplayProps={{ allApys, ...rateDisplayProps }}
            apy={loopExpanded?.loopVault.maxLeveragedApyPct}
            pointSources={loopSources}
            loopscalePointSources={[LoopscalePointSource.Borrow]}
            metadata={[loopExpanded?.collateralToken, loopExpanded?.principalToken].filter(filterTruthy)}
            loopLeverage={loopExpanded?.loopVault.maxLeverage}
        />
    );
}

export function useLoopPointsDetails({ loopExpanded }: { loopExpanded: LoopExpanded | undefined }) {
    const { isDarkMode } = useAppPalette();

    return useMemo(() => {
        switch (loopExpanded?.type) {
            case LoopRoutePlatform.Jupiter:
                return null;
            case LoopRoutePlatform.Meteora:
                return {
                    image: METEORA_SVG,
                    backgroundColor: isDarkMode ? undefined : "#141C37",
                    name: "Meteora"
                };
            default:
                return null;
        }
    }, [isDarkMode, loopExpanded?.type]);
}

export function useLoopProfitLoss(loopPositionExpanded: LoopPositionExpanded | undefined, options: { skip: boolean }) {
    const isActive = useMemo(() => isLoanActive(loopPositionExpanded?.loanExpanded), [loopPositionExpanded]);
    const { data: externalQuote, isError } = useUnwindExternalQuote({
        loopPositionExpanded,
        slippagePercentDecimals: percentUiToDecimals(DEFAULT_SLIPPAGE),
        skip: options?.skip || !isActive || !loopPositionExpanded,
        disablePoll: true
    });

    return useMemo(() => {
        if (!loopPositionExpanded) return undefined;

        // fallback on BE calculation if external quote is not available
        if (!isActive || isError) {
            return calculateHistoricalLoopProfitLoss(loopPositionExpanded);
        }

        if (!externalQuote) return undefined;

        return calculateUnwindStats({ loopPosition: loopPositionExpanded, externalQuote });
    }, [loopPositionExpanded, isActive, isError, externalQuote]);
}

export function useLoopWindAlerts(loopExpanded: LoopExpanded | undefined) {
    const routeType = loopExpanded?.loopVault.routeType;
    let windDisabledWithMessage: string | undefined;

    const { data: jlpPerps } = useJlpPerps({ skip: !loopExpanded || routeType !== LoopRouteType.JlpPerps });

    if (!loopExpanded) return { windDisabledWithMessage };

    if (jlpPerps && routeType === LoopRouteType.JlpPerps) {
        const maxAumUsd = jlpPerps.aum.maxAumUsd;
        const aumUsd = jlpPerps.aum.aumUsd;
        const isCapExceeded = maxAumUsd && aumUsd && maxAumUsd < aumUsd;
        if (isCapExceeded) {
            windDisabledWithMessage = "Deposits temporarily disabled due to Jupiter's AUM cap being exceeded";
        }
    }

    return { windDisabledWithMessage };
}

export function useLoopUnwindAlerts({
    loopPositionExpanded,
    profitLossUsd,
    variant
}: {
    loopPositionExpanded: LoopPositionExpanded | undefined;
    profitLossUsd: number | undefined;
    variant?: "preview" | "route";
}) {
    const routeType = loopPositionExpanded?.loopExpanded?.loopVault.routeType;
    const { data: jlpPerps } = useJlpPerps({ skip: !loopPositionExpanded || routeType !== LoopRouteType.JlpPerps });
    let unwindWarningMessage: string | undefined;

    if (!loopPositionExpanded || profitLossUsd === undefined) return { unwindWarningMessage };

    const {
        userLoopInfo: { netApy }
    } = loopPositionExpanded;

    const isTemporarilyNegativePnl = !!netApy && netApy > 0 && profitLossUsd < 0;

    if (isLstLoop(loopPositionExpanded?.loopExpanded)) {
        const stakeWarning = "Staking yield is only compounded every epoch and may not be reflected in your P&L yet";
        if ((variant === "route" && isTemporarilyNegativePnl) || variant === "preview") {
            unwindWarningMessage = stakeWarning;
        }
    }

    if (routeType === LoopRouteType.Meteora) {
        if (isTemporarilyNegativePnl) {
            const loopApy = formatPercent(netApy);
            if (variant === "route") {
                unwindWarningMessage = `You're earning ${loopApy} APY. Closing now locks in losses. Meteora may not have updated trading fees`;
            } else {
                unwindWarningMessage = `You're earning ${loopApy} APY but your P&L is temporarily negative since Meteora has a setup fee and periodically updates yields`;
            }
        }
    }

    if (routeType === LoopRouteType.JlpPerps && !!jlpPerps) {
        const swapFeesPercent = bpsToUiDecimals(jlpPerps.fees.swapBps + jlpPerps.fees.taxBps, "bps");
        if (swapFeesPercent > 0.01 && variant === "route") {
            unwindWarningMessage = `JLP redemption fees are unusually high. Closing now will incur a ${formatPercent(
                swapFeesPercent
            )} fee`;
        }
    }

    return { unwindWarningMessage };
}

export function LoopTags({
    loopExpanded,
    dotSize = 3,
    variant = "body2"
}: {
    loopExpanded: LoopExpanded | undefined;
    dotSize?: number;
    variant?: "body2" | "caption";
}) {
    return (
        <Row spacing={0.5}>
            {!loopExpanded && repeatElement(<TextSkeleton variant={variant} width="20px" />)}
            {loopExpanded?.loopVault.tags.map((tag, i) => (
                <Fragment key={tag}>
                    {i !== 0 && (
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.palette.text.disabled,
                                width: dotSize,
                                height: dotSize,
                                borderRadius: "100%"
                            }}
                        />
                    )}
                    <Text variant={variant} color="caption">
                        {tag}
                    </Text>
                </Fragment>
            ))}
        </Row>
    );
}

enum LoopDisplayType {
    UsdCostBasis,
    TokenCostBasis
}
function useLoopDisplayType() {
    const [displayType, setDisplayType] = useLocalStorage("LOOP_DISPLAY_TYPE", LoopDisplayType.TokenCostBasis);
    return { displayType, setDisplayType };
}

const MIN_TIME_FOR_RECENT_START = TIME.MINUTE * 30;

export function LoopProfitLoss({
    loopPosition,
    gainSummary
}: {
    loopPosition: LoopPositionExpanded | undefined;
    gainSummary: ReturnType<typeof useLoopProfitLoss>;
}) {
    const { displayType } = useLoopDisplayType();

    if (!loopPosition || !gainSummary) return null;

    const recentStartTime =
        !loopPosition.loanExpanded.loan.refinancedFrom &&
        Math.max(getUnixTs() - loopPosition.loanExpanded.loan.loanStartTime, 0) < MIN_TIME_FOR_RECENT_START;

    if (recentStartTime) {
        return (
            <TooltipText
                helpText={`P&L calculation in progress (takes ${formatSeconds(MIN_TIME_FOR_RECENT_START)})`}
                color="disabled"
            >
                Pending
            </TooltipText>
        );
    }

    if (
        // principal only always displays in usd
        loopPosition?.loopExpanded.withdrawType === LoopTransferType.PrincipalOnly ||
        displayType === LoopDisplayType.UsdCostBasis
    ) {
        const percentChange = calculatePercentChange(
            gainSummary.contributions.collateralContributionUsd,
            gainSummary.contributions.collateralContributionUsd + gainSummary.profitLossUsd
        );

        return <ProfitLossText percentChange={percentChange} profitLossUsd={gainSummary?.profitLossUsd} />;
    }

    const percentChange = calculatePercentChange(
        gainSummary.contributions.collateralContribution,
        gainSummary.contributions.collateralContribution + (gainSummary?.profitLossTokenAmount ?? 0)
    );

    return (
        <ProfitLossText
            profitLossTokenAmount={gainSummary?.profitLossTokenAmount}
            metadata={loopPosition?.loopExpanded.collateralToken}
            percentChange={percentChange}
        />
    );
}

export function LoopInitialDeposit({ loopPosition }: { loopPosition: LoopPositionExpanded | undefined }) {
    const { displayType, setDisplayType } = useLoopDisplayType();
    if (!loopPosition) return <TextSkeleton variant="body1" width="60px" />;

    const { loopExpanded, userLoopInfo } = loopPosition;
    const contributions = sumLoopPositionUsdContributions(loopPosition);

    return (
        <Row spacing={0.5}>
            {loopPosition?.loopExpanded.withdrawType !== LoopTransferType.PrincipalOnly && (
                <TextButton
                    color="disabled"
                    onClick={() =>
                        setDisplayType(
                            displayType === LoopDisplayType.UsdCostBasis
                                ? LoopDisplayType.TokenCostBasis
                                : LoopDisplayType.UsdCostBasis
                        )
                    }
                >
                    <SwapHoriz />
                </TextButton>
            )}
            {displayType === LoopDisplayType.UsdCostBasis && (
                <Text>{formatUsd(contributions.initialCollateralUsd)} </Text>
            )}
            {displayType === LoopDisplayType.TokenCostBasis && (
                <Text>
                    {BsMetaUtil.formatAmount(loopExpanded.collateralToken, userLoopInfo.initialCollateralAmount)}{" "}
                </Text>
            )}
        </Row>
    );
}
