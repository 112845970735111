import Cookies, { CookieAttributes } from "js-cookie";

// This method checks if secure cookies are supported by the browser.
// Some browsers, especially older versions or specific configurations, may not support secure cookies,
// which are necessary for ensuring that cookies are only sent over HTTPS connections.
const isSecureCookieSupported: boolean = (() => {
    const testCookie = "testSecureCookie";
    try {
        Cookies.set(testCookie, "test", { secure: true });
        const success = !!Cookies.get(testCookie);
        Cookies.remove(testCookie);
        return success;
    } catch (e) {
        return false;
    }
})();

export class AppCookie {
    static get(name: string): string | undefined {
        return Cookies.get(name);
    }

    static set(name: string, value: string, options: CookieAttributes = {}): void {
        const defaultOptions: CookieAttributes = {
            sameSite: "strict",
            secure: isSecureCookieSupported
        };

        const mergedOptions: CookieAttributes = { ...defaultOptions, ...options };
        Cookies.set(name, value, mergedOptions);
    }

    static remove(name: string, options: CookieAttributes = {}): void {
        Cookies.remove(name, options);
    }
}
