import { ReactNode, useEffect, useRef, useState } from "react";

import {
    BORDER_RADIUS_ROUNDED,
    Button,
    Column,
    FormInput,
    TagTextAlert,
    Text,
    ToggleButtons,
    ToggleOption
} from "@bridgesplit/ui";
import { formatPercent, greaterThan, percentUiToDecimals } from "@bridgesplit/utils";
import { TuneOutlined } from "@mui/icons-material";

import { DialogHeaderWithBack } from "./dialog";

export const DEFAULT_SLIPPAGE = 0.1;
const DEFAULT_PRESETS = [DEFAULT_SLIPPAGE, 0.5, 1];
const CUSTOM_SLIPPAGE = 0;
function SlippageInput({
    value,
    setValue,
    presets = DEFAULT_PRESETS
}: {
    // uses ui value ex (1% = 1)
    value: number | undefined;
    setValue: (value: number | undefined) => void;
    presets?: number[];
}) {
    const isCustom = !presets.some((percent) => percent === value);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current || !isCustom) return;
        inputRef.current.focus();
    }, [isCustom]);

    const options: ToggleOption<number>[] = presets.map((percent) => ({
        value: percent,
        label: formatPercent(percent, { minimumSignificantDigits: 1, skipMultiplication: true })
    }));

    options.push({
        value: CUSTOM_SLIPPAGE,
        toggleProps: { disableRipple: true },
        label: isCustom ? (
            <FormInput
                variant="number"
                adornment="%"
                decimals={2}
                InputProps={{ inputRef }}
                validateNumber={(val) => (val ? val < 100 : true)}
                onClick={(e) => e.stopPropagation()}
                fullWidth
                sx={{ "& fieldset": { border: "none" } }}
                focused
                setValue={setValue}
                placeholder="0"
                value={value}
            />
        ) : (
            "Custom"
        )
    });

    return (
        <Column spacing={1}>
            <ToggleButtons
                value={value}
                setValue={(percentSlippage) => {
                    // undefined if the user clicks the custom button
                    if (percentSlippage === undefined) return;
                    setValue(percentSlippage);
                }}
                options={options}
            />
            {greaterThan(value, 5) && (
                <TagTextAlert color="warning" icon="warning">
                    High slippage may result in an unfavorable trade
                </TagTextAlert>
            )}
        </Column>
    );
}

type SlippageSettingsButtonProps = {
    value: number | undefined;
    open: () => void;
};

function SlippageSettingsButton({ value, open }: SlippageSettingsButtonProps) {
    return (
        <Button
            textProps={{ variant: "body2", svgColor: "disabled" }}
            sx={{ p: 0.5, borderRadius: BORDER_RADIUS_ROUNDED }}
            height={20}
            variant="outlined"
            onClick={open}
        >
            <TuneOutlined /> {formatPercent(value, { skipMultiplication: true })}
        </Button>
    );
}

type SlippageWrapperProps = {
    isEditing: boolean;
    close: () => void;
    value: number | undefined;
    setValue: (value: number | undefined) => void;
    children: ReactNode;
    presets: number[];
};

function SlippageWrapper({
    isEditing,
    value: initialValue,
    setValue: setPropsValue,
    children,
    close,
    presets
}: SlippageWrapperProps) {
    const [value, setValue] = useState(initialValue);

    if (!isEditing) return <>{children}</>;
    return (
        <>
            <Column spacing={2}>
                <DialogHeaderWithBack header="Modify slippage" back={close} />
                <Text color="caption">
                    Higher slippage values may help with transaction success rate, but may result in unfavorable trades
                </Text>
                <SlippageInput value={value} setValue={setValue} presets={presets} />
            </Column>
            <Button
                variant="contained"
                onClick={() => {
                    setPropsValue(value);
                    close();
                }}
            >
                Confirm slippage
            </Button>
        </>
    );
}

function useSlippage(options?: {
    defaultSlippageUi?: number;
    presetsUi?: number[];
    onEdit?: (value: number | undefined) => void;
}) {
    const [slippageUi, setSlippageUi] = useState<number | undefined>(options?.defaultSlippageUi);
    const [isEditing, setIsEditing] = useState(false);

    const slippagePercentUi = !!slippageUi && slippageUi !== CUSTOM_SLIPPAGE ? slippageUi : DEFAULT_SLIPPAGE;

    const slippageWrapperProps: Omit<SlippageWrapperProps, "children"> = {
        isEditing,
        close: () => setIsEditing(false),
        value: slippagePercentUi,
        setValue: (value) => {
            setSlippageUi(value);
            options?.onEdit?.(value);
        },
        presets: options?.presetsUi ?? DEFAULT_PRESETS
    };
    const slippageButtonProps: SlippageSettingsButtonProps = {
        value: slippagePercentUi,
        open: () => setIsEditing(true)
    };
    const slippagePercentDecimals = percentUiToDecimals(slippagePercentUi);

    return { slippagePercentDecimals, slippagePercentUi, slippageWrapperProps, slippageButtonProps };
}

export const Slippage = {
    Input: SlippageInput,
    Button: SlippageSettingsButton,
    Wrapper: SlippageWrapper,
    useController: useSlippage
};
