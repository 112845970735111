import { Column, useAppPalette } from "@bridgesplit/ui";

import { NAV_Z_INDEX } from "./constants";
import MobileNavbar from "./MobileNavbar";
import DesktopNavbar from "./DesktopNavbar";
import { useNavbarHeight } from "./util";

export default function Navbar() {
    const { border, background } = useAppPalette();
    const { heightInPixels } = useNavbarHeight();

    return (
        <Column
            sx={{
                position: "fixed",
                width: "100vw",
                left: 0,
                top: 0,
                right: 0,
                background,
                height: `calc(${heightInPixels} - 1px)`, // 1px for border
                zIndex: NAV_Z_INDEX,
                borderBottom: border,
                justifyContent: "center"
            }}
        >
            <MobileNavbar />
            <DesktopNavbar />
        </Column>
    );
}
