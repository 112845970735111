export const WALLET_ERROR_MESSAGES: Record<string, string> = {
    WalletNotReadyError: "Wallet is not ready",
    WalletLoadError: "Failed to load your wallet",
    WalletConfigError: "Configuration error with your wallet",
    WalletConnectionError: "Error connecting to your wallet",
    WalletDisconnectedError: "Wallet has been disconnected",
    WalletDisconnectionError: "Error disconnecting from your wallet",
    WalletAccountError: "Error with your wallet account",
    WalletPublicKeyError: "Error with parsing your wallet address",
    WalletKeypairError: "Error with your key pair in your wallet",
    WalletNotConnectedError: "Your wallet isn't connected. Please refresh the page",
    WalletSendTransactionError: "Error sending transaction",
    WalletSignTransactionError: "You rejected this transaction",
    WalletSignMessageError: "You rejected the prompt to sign",
    WalletSignInError: "Unable to connect to your wallet",
    WalletTimeoutError: "Your wallet is misconfigured. Refresh and try again",
    WalletWindowBlockedError: "Your wallet extension is being blocked",
    WalletWindowClosedError: "Your wallet extension is being blocked"
};

export const WALLET_ERROR_NAME_MAPPING: Record<string, string> = {
    "Transaction too large": "Transaction is too large. Try reducing data size",
    "Unexpected error": "Unable to connect to your wallet. Refresh the page and try again"
};
