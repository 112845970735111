import { useEffect } from "react";

import { AppCookie, mutationIntoRawResult, TransactionAuthenticated, useLocalStorage } from "@bridgesplit/react";
import {
    ErrorType,
    LOADING_ERROR,
    NullableRecord,
    Result,
    getReadableErrorMessage,
    handlePartialParams,
    removeDuplicates
} from "@bridgesplit/utils";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/dist/query";
import { WALLET_TXN_HEADER } from "@bridgesplit/abf-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

import {
    CREATE_GROUP_ROUTE,
    RESEND_VERIFY_EMAIL_ROUTE,
    PUT_CUSTODIAN_INVITES_ROUTE,
    UPDATE_USER_ROUTE,
    convertRolesToRoleNumbers,
    PUT_CUSTODIAN_GROUP_ROLES_ROUTE,
    PUT_USER_INVITES_ROUTE,
    PUT_USER_GROUP_ROLES_ROUTE,
    UPLOAD_USER_AVATAR_ROUTE,
    ADD_WALLET_TO_USER_ROUTE,
    CUSTODIAN_INVITE_CODE_KEY
} from "../constants";
import {
    useVerifyUserWalletSetupQuery,
    abfUserApi,
    useApplyCustodianCodeMutation,
    useAbfFetches,
    useAuthStorage,
    useOnboardingQuery,
    useCompleteOnboardingMutation,
    SdkRootState
} from "../reducers";
import {
    AbfCreateUserGroupParams,
    AbfCreateUserGroupResponse,
    AbfGroupPermissionChange,
    AbfRoleNumber,
    AbfRolesInvite,
    AbfUserPermissionChange,
    AbfUserWalletVerification,
    AddWalletParams,
    OnboardingStep
} from "../types";
import { axiosCreateAbf } from "../reducers/util";
import { useActiveGroup, useGroup } from "./group";
import { useUserBetaAccess } from "./points";
import { useAccessLevel } from "./access";

export function useSetCustodianCode() {
    const [applyCustodianCode, { isLoading }] = useApplyCustodianCodeMutation();

    const { groupIdentifier } = useActiveGroup();

    const code = AppCookie.get(CUSTODIAN_INVITE_CODE_KEY);

    const { custodianPermissions } = useGroup();

    useEffect(() => {
        if (isLoading || !custodianPermissions || !groupIdentifier || !code || code in custodianPermissions) return;
        (async () => {
            await applyCustodianCode({
                code,
                groupIdentifier
            });
            AppCookie.remove(CUSTODIAN_INVITE_CODE_KEY);
        })();
    });
}

export function useCreateGroup() {
    const { resetMeApi } = useAbfFetches();
    return async function createGroup({
        signedTransaction,
        ...partialParams
    }: TransactionAuthenticated<NullableRecord<AbfCreateUserGroupParams>>): Promise<
        Result<AbfCreateUserGroupResponse>
    > {
        try {
            const params = handlePartialParams<AbfCreateUserGroupParams>(partialParams, ["admin_wallet"]);
            if (!params.isOk()) return Result.err(params);
            const res = await axiosCreateAbf().post<{ group_identifier: string }>(CREATE_GROUP_ROUTE, params.unwrap(), {
                headers: { [WALLET_TXN_HEADER]: signedTransaction }
            });

            resetMeApi();

            return Result.ok({ groupIdentifier: res.data.group_identifier });
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("create group"), error);
        }
    };
}

export function useVerifyAbfUserWallet() {
    /**
     * Used to verify a user's wallet by proving ownership through a signed transaction
     * If a signed transaction is passed in, then send the transaction
     * Else, create an empty transaction
     */
    return async function (params: AddWalletParams): Promise<Result<void>> {
        try {
            const response = await axiosCreateAbf().post(ADD_WALLET_TO_USER_ROUTE, params, {
                headers: { [WALLET_TXN_HEADER]: params.signedTxn }
            });

            if (response.status === 200) {
                return Result.ok();
            }

            return Result.errFromMessage(getReadableErrorMessage("submit signature"), {
                errorType: ErrorType.ApiLoginError
            });
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("submit signature"), error);
        }
    };
}

export function useResendAuth0Email() {
    const { getIdTokenClaims } = useAuth0();
    const { state } = useAuthStorage();

    return async () => {
        let idToken = state.idToken?.token;
        if (!idToken) {
            const idTokenClaims = await getIdTokenClaims();
            idToken = idTokenClaims?.__raw;
        }
        if (!idToken) return Result.errFromMessage("Unable to fetch authentication credentials", { skipSentry: true });
        return await resendAuth0Email(idToken);
    };
}

async function resendAuth0Email(idToken: string) {
    try {
        const response = await axiosCreateAbf().post(RESEND_VERIFY_EMAIL_ROUTE, undefined, {
            headers: { Authorization: `Bearer ${idToken}` }
        });
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("resend verification email"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("resend verification email"), error);
    }
}

export async function editUser({ signedTransaction, ...edits }: TransactionAuthenticated<{ name: string }>) {
    try {
        const response = await axiosCreateAbf().patch(UPDATE_USER_ROUTE, edits, {
            headers: { [WALLET_TXN_HEADER]: signedTransaction }
        });
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("update user"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("update user"), error);
    }
}

function parseAuthResults(auth: AbfUserWalletVerification) {
    const onChainRoles = auth.onchain_roles.map((r) => r.role);
    const onChainRolesSet = new Set(onChainRoles);
    const offChainRoles = auth.offchain_roles.map(([, role]) => role);
    const missingRoles = removeDuplicates(offChainRoles.filter((r) => !onChainRolesSet.has(r)));
    const isVerified = auth.is_verified;

    return { isVerified, onChainRoles, offChainRoles, missingRoles };
}

export async function updateAvatarFromFile({
    selectedFile,
    isAdmin,
    signedTransaction
}: TransactionAuthenticated<{ selectedFile: File | undefined; isAdmin: boolean | undefined }>) {
    if (!selectedFile) {
        return Result.errFromMessage("No file selected");
    }
    if (isAdmin === undefined) {
        return Result.errFromMessage("No permission");
    }
    try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const queryParams = `?update_user=${isAdmin ? "false" : "true"}`;
        const url = `${UPLOAD_USER_AVATAR_ROUTE}${queryParams}`;
        await axiosCreateAbf().post(url, formData, {
            headers: { "Content-Type": "multipart/form-data", [WALLET_TXN_HEADER]: signedTransaction }
        });
        return Result.ok();
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("update profile picture"), error);
    }
}

export function useVerifyWalletSetup(walletToVerify: string | undefined) {
    const { hasPermission } = useUserBetaAccess();
    const { data, isLoading, isFetching } = useVerifyUserWalletSetupQuery(walletToVerify ?? skipToken, {
        skip: !walletToVerify || !hasPermission
    });

    const [fetch] = abfUserApi.endpoints.verifyUserWalletSetup.useLazyQuery();

    async function verifyAsync(): Promise<Result<ReturnType<typeof parseAuthResults>>> {
        if (!walletToVerify) return Result.errFromMessage(LOADING_ERROR);
        const res = await fetch(walletToVerify, true);
        if ("error" in res || !res.data) return Result.errWithDebug(getReadableErrorMessage("setup registration"), res);
        return Result.ok(parseAuthResults(res.data));
    }

    return { data: data ? parseAuthResults(data) : undefined, verifyAsync, isLoading, isFetching };
}

type InvitesParams = Record<string, AbfRoleNumber[]>;
export async function putCustodianGroupInvites(invites: AbfRolesInvite[]): Promise<Result<boolean>> {
    const body: InvitesParams = Object.fromEntries(invites.map((u) => [u.email, convertRolesToRoleNumbers(u.roles)]));
    try {
        const response = await axiosCreateAbf().put(PUT_CUSTODIAN_INVITES_ROUTE, body);
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("send invites"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("send invites"), error);
    }
}

type AbfUserGroupCustodianPermissionParams = {
    custodian_identifier: string;
    group_ids_and_roles: Record<string, AbfRoleNumber[]>;
};
export async function putCustodianGroupRoles(
    custodian: string,
    newPermissions: AbfGroupPermissionChange[]
): Promise<Result<boolean>> {
    try {
        const body: AbfUserGroupCustodianPermissionParams = {
            custodian_identifier: custodian,
            group_ids_and_roles: Object.fromEntries(
                newPermissions.map((entry) => [entry.groupIdentifier, convertRolesToRoleNumbers(entry.roles)])
            )
        };
        const response = await axiosCreateAbf().put(PUT_CUSTODIAN_GROUP_ROLES_ROUTE, body);
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("updates roles"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("updates roles"), error);
    }
}

export async function putUserGroupInvites(invites: AbfRolesInvite[]): Promise<Result<boolean>> {
    const body: InvitesParams = Object.fromEntries(invites.map((u) => [u.email, convertRolesToRoleNumbers(u.roles)]));
    try {
        const response = await axiosCreateAbf().put(PUT_USER_INVITES_ROUTE, body);
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("send invites"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("send invites"), error);
    }
}

type AbfAddUserPermissionsParams = {
    group: string;
    params: { user_identifier: string; permissions: AbfRoleNumber[] }[];
};
export async function putGroupUserRoles(
    group: string,
    newPermissions: AbfUserPermissionChange[]
): Promise<Result<boolean>> {
    try {
        const body: AbfAddUserPermissionsParams = {
            group,
            params: newPermissions.map((p) => ({
                user_identifier: p.userIdentifier,
                permissions: removeDuplicates(convertRolesToRoleNumbers(p.roles))
            }))
        };
        const response = await axiosCreateAbf().put(PUT_USER_GROUP_ROLES_ROUTE, body);
        if (response.status === 200) return Result.ok();
        return Result.errFromMessage(getReadableErrorMessage("update roles"));
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("update roles"), error);
    }
}

const USER_ONBOARDING_CACHE_KEY = "UserOnboardingCache";
function useCachedOnboardingSteps() {
    const [cachedSteps, setCachedSteps] = useLocalStorage<OnboardingStep[]>(USER_ONBOARDING_CACHE_KEY, []);
    return { cachedSteps, setCachedSteps };
}

// only call local storage if key is'nt cached
export function useUserOnboardingStep(stepsToCheck: OnboardingStep[]) {
    const { cachedSteps, setCachedSteps } = useCachedOnboardingSteps();
    const { isBeta } = useAccessLevel();

    const isMutating = useSelector((state: SdkRootState) =>
        Object.values(state.onboardingApi.mutations).some((m) => m?.status === QueryStatus.pending)
    );

    const isCachedComplete = stepsToCheck.every((step) => cachedSteps.includes(step));
    const {
        data: apiSteps,
        isFetching,
        isLoading: queryLoading
    } = useOnboardingQuery(undefined, {
        skip: isCachedComplete || !isBeta
    });

    const isLoading = isFetching || isMutating || queryLoading || !apiSteps || queryLoading;
    const steps = apiSteps ?? cachedSteps;
    const isComplete = isLoading || stepsToCheck.every((step) => steps.includes(step));

    useEffect(() => {
        if (!apiSteps || apiSteps.length >= cachedSteps.length) return;
        setCachedSteps(removeDuplicates([...cachedSteps, ...apiSteps]));
    }, [apiSteps, cachedSteps, setCachedSteps]);

    return { steps, isComplete, isLoading };
}

export function useMarkOnboardingStepComplete() {
    const { cachedSteps, setCachedSteps } = useCachedOnboardingSteps();

    const [completeMutation] = useCompleteOnboardingMutation();

    async function markAsComplete(stepsToCheck: OnboardingStep[]) {
        setCachedSteps(removeDuplicates([...cachedSteps, ...stepsToCheck]));

        const results = await Promise.all(stepsToCheck.map((step) => mutationIntoRawResult(completeMutation, step)));

        return Result.combine(results);
    }
    return { markAsComplete };
}
