export const BAXUS_EXPLORER = `https://baxus.co/asset/`;
export const SIGNUP_URL = "https://www.loopscale.com/request/general";
export const WALLET_HELP_URL = "https://phantom.app/learn/guides/how-to-create-a-new-wallet";
export const BAXUS_IDENTIFIER = "3Ma9bxLRjD6qSx6KonpUADuoAwpbu2cTXvtVmwb8ixqs";

export const SUPPORT_EMAIL = "support@loopscale.com";
export const SPHERE_WEBSITE_URL = "https://spherepay.co/";
export const STRIPE_IDENTITY_WEBSITE_URL = "https://stripe.com/identity";
export const TELLER_WEBSITE_URL = "https://teller.io/";
export const PLAID_WEBSITE_URL = "https://plaid.com/";
export const SPHERE_TOS_URL = "https://spherepay.co/";
export const WORMHOLE_URL = "https://wormhole.com/";
export const BS_SHORT_URL = "loop.sl";
export const BS_SHORT_URL_INVITE = BS_SHORT_URL + "/i";
export const TWITTER_URL = "https://x.com/LoopscaleLabs";
export const DISCORD_URL = "https://discord.gg/loopscale";
export const SUPPORT_URL = "https://discord.com/channels/895116209958297631/932703647236771850";
