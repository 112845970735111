import { DialogWrapper } from "@bridgesplit/ui";
import { BsMetaUtil, useBsMetadataByMint } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";

import { DialogHeaderWithBack } from "../common";
import DepositToWallet from "./deposit/DepositToWallet";

export default function DepositToWalletDialog() {
    const { getData } = useAppDialog();

    const data = getData<AppDialog.DepositToWallet>();
    const metadata = useBsMetadataByMint(data?.specifiedMint);
    return (
        <DialogWrapper>
            <DialogHeaderWithBack
                header={metadata ? `Receive ${BsMetaUtil.getName(metadata)}` : "Receive"}
                back={data?.back}
            />
            <DepositToWallet specifiedMint={data?.specifiedMint} />
        </DialogWrapper>
    );
}
