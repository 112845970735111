import { useCallback } from "react";

import { useAbfFetches, useVerifyDiscordJoinMutation } from "@bridgesplit/abf-react";
import { useSearchParams } from "react-router-dom";
import { Result, getReadableErrorMessage, parseErrorFromOptions } from "@bridgesplit/utils";
import { mutationIntoRawResult, useAsyncResultHandler, useAppNavigate } from "@bridgesplit/react";
import { useOnInitialRenderRef } from "@bridgesplit/ui";
import { WAITLIST_SLUG } from "app/constants";

const skipSentry = true;
export default function DiscordCallback() {
    const [params] = useSearchParams();
    const code = params.get("code");
    const { resetPoints } = useAbfFetches();

    const [verifyDiscordJoin] = useVerifyDiscordJoinMutation();
    const navigate = useAppNavigate();
    const { resultHandler, skipHook } = useAsyncResultHandler();

    const onFinish = useCallback(() => navigate(WAITLIST_SLUG), [navigate]);

    const verify = useCallback(async () => {
        if (!code) return Result.errFromMessage("No Discord credentials provided", { skipSentry });
        const res = await mutationIntoRawResult(verifyDiscordJoin, code, { skipSentry });
        navigate(WAITLIST_SLUG);

        if (res.isOk()) {
            resetPoints();
            return Result.ok();
        }
        const parsedError = parseErrorFromOptions(res.unwrapErrMessage(), DISCORD_ERRORS);

        return Result.errFromMessage(parsedError ?? getReadableErrorMessage("verify Discord"), { skipSentry: true });
    }, [code, navigate, resetPoints, verifyDiscordJoin]);

    useOnInitialRenderRef(
        () =>
            resultHandler(verify, {
                alertOnError: true,
                onFail: onFinish,
                onSuccess: onFinish,
                onSuccessMessage: "Discord verified"
            }),
        skipHook
    );

    return null;
}

const DISCORD_ERRORS = {
    "User already added to Discord": "You've already linked a Discord to this account",
    "User is not in the required server.": "You aren't in the Loopscale Discord",
    "Discord account is already connected.":
        "This Discord account is already connected to a different Loopscale account"
};
