import { BsMetadata, ChainId } from "@bridgesplit/abf-sdk";
import { Transaction as BsTransaction } from "@bridgesplit/bs-protos";

import { EvmLiteNft, EvmTransferTransactionInfo } from "./evm";

export interface BridgeTransactionExpanded extends BridgeTransactionInfo {
    bsMetadata: BsMetadata | null;
    evmMetadata: EvmLiteNft | null;
    claimTransactions: BridgeTransactionInfo[];
    transfers: EvmTransferTransactionInfo[];
    status: BridgeStatus;
    isLatest: boolean; // is this the latest try for bridging this asset
}

export enum BridgeStatus {
    Confirmed = "Confirmed",
    Pending = "Pending",
    BridgeError = "Bridge",
    ClaimError = "Claim",
    TransferError = "Transfer"
}

export interface MultiChainWallets {
    id: number;
    userIdentifier: string;
    dfnsWalletId: string;
    walletPubkey: string;
    chainId: ChainId;
}

export interface BridgeTransaction {
    id: number;
    transactionSignature: string;
    userIdentifier: string;
    fromWalletPubkey: string;
    toWalletPubkey: string;
    fromChainId: ChainId;
    toChainId: ChainId;
    assetMint?: string;
    assetAmount: number;
    transactionType: BridgeTransactionType;
    contract?: string;
    tokenId?: string;
}

export interface TrackedTransaction {
    id: number;
    signer: string;
    transactionSignature: string;
    transactionStatus: BsTransaction.TransactionStatus;
    sourceChain: ChainId;
    lastUpdateTime: number;
}

export enum BridgeTransactionType {
    Bridge = 0,
    Claim = 1
}

export interface BridgeTransactionInfo {
    transactionInfo: TrackedTransaction;
    bridgeTransactionInfo: BridgeTransaction;
}

export interface TrackedTransactionsFilter {
    transactions?: string[];
    wallets?: string[];
    tokenIds?: string[];
    contracts?: string[];
    solanaMints?: string[];
    groupIdentifiers?: string[];
}

export interface TrackedTransactionArgs {
    signature: string;
    status: number;
    timestamp: number;
    sourceChain: number;
    signer: string;
}

export interface CompleteTransactionActionArgs {
    signature: string;
    status: number;
    sourceChain: number;
}

export interface ApproveTransactionActionArgs {
    tokenContract: string;
    senderContract: string;
    chainId: number;
}
