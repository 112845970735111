import { useMemo } from "react";

import {
    bsMath,
    filterTruthy,
    filterUndefined,
    removeDuplicates,
    removeDuplicatesWithFunction
} from "@bridgesplit/utils";
import { AbfUserInviteType } from "@bridgesplit/abf-sdk";

import { useSyndicatedOrdersQuery, useUserInvitesQuery } from "../reducers";
import { SyndicatedOrderFilter, AbfSyndicateExpanded, SyndicatedContributorExpanded } from "../types";
import { isCollateralSplMint, useAbfTypesToUiConverter } from "../utils";
import { useUserProfile } from "./auth";
import { useEscrowAccounts } from "./escrow";
import { useActiveGroup, useGroupsByIdentifiers } from "./group";

type Options = { skip?: boolean };
export function useSyndicatedOrders(filter: SyndicatedOrderFilter, options?: Options) {
    const { data: rawData, isLoading, isFetching } = useSyndicatedOrdersQuery(filter, options);

    const collateralMints = Object.values(rawData?.loanRequests ?? {})
        .map((req) =>
            Object.values(req.assetInfos)
                .filter((c) => isCollateralSplMint(c))
                .map((collateral) => collateral.assetKey)
        )
        .flat();
    const orderMints = Object.values(rawData?.orders ?? {}).map((o) => o.principalMint);

    const {
        getMetadata,
        convertLoanRequest,
        convertLoanRequestCollateral,
        convertOrder,
        convertSyndicateContributors,
        convertSyndicateOrder
    } = useAbfTypesToUiConverter([...collateralMints, ...orderMints]);

    const syndicates = rawData ? Object.values(rawData.syndicates) : undefined;
    const inviteGroups = syndicates
        ?.map(({ invites }) =>
            Object.values(invites)
                .map((i) => i.organizationIdentifier)
                .filter(filterTruthy)
        )
        .flat();

    const { cache: groupCache } = useGroupsByIdentifiers(inviteGroups);

    const data = syndicates
        ?.map(({ data, contributors, invites }): AbfSyndicateExpanded | undefined => {
            const syndicate = convertSyndicateOrder(data);
            const placedOrder = rawData?.orders?.[syndicate.order];
            const loanVault = rawData?.loanVaults?.[syndicate.collateralMint];
            const maker = rawData?.groupData?.[syndicate.groupIdentifier];
            const request = rawData?.loanRequests?.[syndicate.collateralMint];
            const assetInfos = Object.values(request?.assetInfos ?? {});
            const custodians = assetInfos
                .map(({ assetKey }) => rawData?.custodianMapping?.[assetKey])
                .filter(filterUndefined);
            const principalMetadata = getMetadata(data.principalMint);

            const expandedContributors = Object.values(contributors)
                .map((contributor): SyndicatedContributorExpanded | undefined => {
                    const convertedContributor = convertSyndicateContributors(contributor, syndicate);
                    const group =
                        rawData?.groupData?.[contributor.groupIdentifier] ??
                        groupCache.get(contributor.groupIdentifier);

                    if (!group || !principalMetadata) {
                        return undefined;
                    }
                    return {
                        ...group,
                        principalMetadata,
                        contribution: convertedContributor,
                        principalDeposited: convertedContributor.principalDeposited,
                        principalDepositShare:
                            bsMath.div(convertedContributor.principalDeposited, syndicate.principalDeposited) || 0
                    };
                })
                .filter(filterUndefined);

            const groupsWithContributions = new Set(expandedContributors.map(({ groupIdentifier }) => groupIdentifier));
            const invitesAsContributors = Object.values(invites)
                .map(({ organizationIdentifier }): SyndicatedContributorExpanded | undefined => {
                    const group = organizationIdentifier ? groupCache.get(organizationIdentifier) : undefined;
                    if (
                        !group ||
                        !principalMetadata ||
                        !organizationIdentifier ||
                        groupsWithContributions.has(organizationIdentifier)
                    )
                        return undefined;

                    return {
                        ...group,
                        principalMetadata,
                        contribution: undefined,
                        principalDeposited: 0,
                        principalDepositShare: 0
                    };
                })
                .filter(filterUndefined);

            const allContributors = expandedContributors.concat(invitesAsContributors);

            const lastUpdatedAt = allContributors.reduce(
                (prev, curr) => Math.max(prev, curr.contribution?.lastUpdateTime || 0),
                syndicate.creationTime
            );
            if (!maker || !request || !custodians || !principalMetadata) return undefined;
            return {
                syndicate,
                principalMetadata,
                maker,
                contributors: allContributors,
                placedOrder: placedOrder ? convertOrder(placedOrder) : placedOrder,
                loanVault,
                loanRequest: convertLoanRequest(request.loanRequest),
                collateral: convertLoanRequestCollateral(assetInfos),
                custodianIdentifiers: removeDuplicates(custodians),
                lastUpdatedAt,
                invites: Object.values(invites)
            };
        })
        .filter(filterUndefined);

    return { data, isLoading, isFetching };
}

export function useUserSyndicatedOrders(options?: { skip?: boolean }) {
    const { groupIdentifier } = useActiveGroup();
    const { escrowPubkeys } = useEscrowAccounts();
    const { data: contributorSyndicates } = useSyndicatedOrders(
        { contributors: groupIdentifier ? [...(escrowPubkeys ?? []), groupIdentifier] : undefined },
        { skip: !groupIdentifier || !escrowPubkeys || options?.skip }
    );
    const { data: invites } = useUserInvitesQuery({ organizationIdentifier: groupIdentifier }, { skip: options?.skip });

    // include accepted invites to syndicates
    const acceptedSyndicateInvites = invites
        ?.filter((i) => i.accepted && i.inviteType === AbfUserInviteType.SyndicatedOffer)
        .map(({ identifier }) => identifier);
    const { data: inviteSyndicates } = useSyndicatedOrders(
        { addresses: acceptedSyndicateInvites },
        { skip: !acceptedSyndicateInvites?.length || options?.skip }
    );

    if ((!contributorSyndicates && !!groupIdentifier) || (!inviteSyndicates && !!acceptedSyndicateInvites?.length))
        return undefined;

    return removeDuplicatesWithFunction(
        (contributorSyndicates ?? []).concat(inviteSyndicates ?? []),
        (s) => s.syndicate.address
    );
}

export function useUserPendingSyndicatedInvite(address: string | undefined, options?: { skip?: boolean }) {
    const { user } = useUserProfile();

    const { data: invites } = useUserInvitesQuery(
        // filter by just email because identifier filter is protected by admin
        { email: user.email },
        { skip: !address || !user.email || options?.skip }
    );

    return invites?.find((i) => !i.accepted && i.email === user.email && i.identifier === address);
}

export function useSyndicatedOrdersByAddress(address: string | undefined) {
    const {
        data: rawData,
        isLoading,
        isFetching
    } = useSyndicatedOrders({ addresses: address ? [address] : [] }, { skip: !address });

    const data = rawData?.find((d) => d.syndicate.address === address);
    const notFound = !data && !!rawData && !isLoading && !isFetching;

    return { data, notFound };
}

export function useGetSyndicatesByOrders(
    orders: string[] | undefined,
    options?: { overrideSyndicates: AbfSyndicateExpanded[] | undefined } // allow syndicates to directly be passed in to prevent double queries
) {
    const {
        data: queryData,
        isFetching,
        isLoading
    } = useSyndicatedOrders({ orders }, { skip: !orders?.length || !!options?.overrideSyndicates });

    const data = options?.overrideSyndicates ?? queryData;

    const orderToSyndicated = useMemo(
        () => (data ? new Map(data.map((s) => [s.syndicate.order, s])) : undefined),
        [data]
    );

    function getSyndicateByOrder(order: string) {
        return orderToSyndicated?.get(order);
    }

    return { getSyndicateByOrder, isFetching, isLoading };
}
