import { createApi } from "@reduxjs/toolkit/query/react";
import { ParsedBsMetadata, ExtraTokenListMetadata, TokenListMetadata, TokenListTag } from "@bridgesplit/abf-sdk";
import { BS_METADATA_API } from "@bridgesplit/utils";

import { abfSerializeQueryArgs, AccessLevel, convertParsedBsMetadataToBsMetadata, getAbfBaseQuery } from "./util";
import { GET_TOKEN_LIST_ROUTE } from "../constants";

// split token list to use unauthenticated base query
export const tokenListApi = createApi({
    reducerPath: "tokenListApi",
    baseQuery: getAbfBaseQuery(BS_METADATA_API, { authLevel: AccessLevel.NotConnected }, false),
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        tokenList: builder.query<TokenListMetadata[], void>({
            query() {
                return {
                    url: GET_TOKEN_LIST_ROUTE,
                    method: "GET"
                };
            },
            transformResponse: (
                raw: ({ metadata: ParsedBsMetadata; tags: string } & Omit<ExtraTokenListMetadata, "tags">)[]
            ) => {
                return raw.map(({ metadata, tags: tagsRaw, ...rest }) => {
                    const tags = tagsRaw ? (JSON.parse(tagsRaw) as TokenListTag[]) : [];
                    const parsed = convertParsedBsMetadataToBsMetadata(metadata);
                    return { ...parsed, tags, ...rest };
                });
            }
        })
    })
});

export const { useTokenListQuery } = tokenListApi;
