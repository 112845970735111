import { useState } from "react";

import {
    AbfPermission,
    BsMetaUtil,
    StrategyExpanded,
    getStrategyName,
    useActiveWallet,
    useEscrowPreference
} from "@bridgesplit/abf-react";
import { StatColumn, TokenInput } from "@bridgesplit/ui";
import { bsMath, formatAddress } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { AppButton } from "../../common";
import { useStrategyWithdraw } from "./util";

export default function StrategyWithdraw({ strategy }: { strategy: StrategyExpanded | undefined }) {
    const [amount, setAmount] = useState<number>();

    const metadata = strategy?.principalMetadata;

    const { activeWallet } = useActiveWallet();

    const symbol = BsMetaUtil.getSymbol(metadata);
    const maxBalance = strategy?.totalBalance;

    const insufficientBalance = amount ? !maxBalance || amount > maxBalance : false;

    const { escrowNeeded } = useEscrowPreference();
    const withdraw = useStrategyWithdraw();
    const withdrawAll = amount === maxBalance;

    return (
        <>
            <TokenInput
                symbol={symbol}
                label="Amount"
                value={amount}
                setValue={setAmount}
                maxText="Available: "
                maxAmount={maxBalance}
                adornment={symbol}
            />
            <StatColumn
                stats={[
                    {
                        caption: "Withdraw to",
                        value: escrowNeeded ? COPY.ESCROW_TERM_FULL : formatAddress(activeWallet?.wallet)
                    },
                    {
                        caption: getStrategyName(strategy),
                        symbol,
                        value: [
                            strategy?.totalBalance,
                            Math.max(bsMath.sub(strategy?.totalBalance, amount || 0) || 0, 0)
                        ],
                        hide: !amount
                    }
                ]}
            />
            <AppButton
                isTransaction
                authentication={{ permission: AbfPermission.WithdrawTokens }}
                fullWidth
                asyncCta={{
                    options: { onSuccess: () => setAmount(undefined) },
                    onClickWithResult: () => withdraw({ strategy, amount, escrowNeeded, withdrawAll })
                }}
                disabled={!amount || insufficientBalance}
                variant="contained"
            >
                Withdraw
            </AppButton>
        </>
    );
}
