import { createApi } from "@reduxjs/toolkit/query/react";
import { SendTransactionsInterface, TransactionResult } from "@bridgesplit/react";

import { abfSerializeQueryArgs, mpcBaseQuery } from "./util";

const TRANSACTIONS_TAG = "Transactions";

export const transactionsApi = createApi({
    reducerPath: "transactionsApi",
    baseQuery: mpcBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [TRANSACTIONS_TAG],
    endpoints: (builder) => ({
        sendTransactions: builder.mutation<TransactionResult[], SendTransactionsInterface>({
            query(txns: SendTransactionsInterface) {
                return {
                    url: "/txns/send",
                    body: txns,
                    method: "POST"
                };
            }
        })
    })
});

export const { useSendTransactionsMutation } = transactionsApi;
