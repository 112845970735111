import { ReactNode, useState } from "react";

import { isBankAccountReadyForTransfers, useUserBankAccounts, useUserSphere } from "@bridgesplit/abf-react";
import {
    OutlinedCard,
    Column,
    Select,
    Row,
    Text,
    TextLink,
    TabsSelect,
    Icon,
    Button,
    ConditionalLink
} from "@bridgesplit/ui";
import { SphereUserBankAccount } from "@bridgesplit/abf-sdk";
import { DispatchType } from "@bridgesplit/react";
import { Add } from "@mui/icons-material";
import { AppDialog, useAppDialog } from "app/utils";
import { ACCOUNT_BANKS_SLUG } from "app/constants";

import BankOverviewHeader from "./BankOverviewHeader";
import BankActivity from "./BankActivity";
import { SideCardWrapper } from "../wrappers";
import { SphereOfframp, SphereOnramp } from "../transfers";
import { formatBank } from "./util";
import { GroupRequiredWrapper } from "../common";

export default function RampOverview() {
    return (
        <SideCardWrapper sideCard={<SpherePayouts />}>
            <BankOverviewHeader />

            <GroupRequiredWrapper action="connect a bank">
                <BankActivity />
            </GroupRequiredWrapper>
        </SideCardWrapper>
    );
}

enum Mode {
    Deposit = "Deposit",
    Withdraw = "Withdraw"
}
function SpherePayouts() {
    const { data: banks } = useUserBankAccounts({ activeOnly: true });
    const [index, setIndex] = useState(0);
    const [mode, setMode] = useState(Mode.Deposit);

    const bank = banks?.[index];

    return (
        <OutlinedCard spacing={2} padding={0}>
            <TabsSelect options={Object.values(Mode).map((value) => ({ value }))} value={mode} setValue={setMode} />

            <Column spacing={2} px={2} pb={2}>
                <VerifiedBankCtaWrapper>
                    <SelectBank banks={banks} index={index} setIndex={setIndex} />
                    {mode === Mode.Deposit && <SphereOnramp bank={bank} />}
                    {mode === Mode.Withdraw && <SphereOfframp bank={bank} />}
                </VerifiedBankCtaWrapper>
            </Column>
        </OutlinedCard>
    );
}

function SelectBank({
    banks,
    index,
    setIndex
}: {
    banks: SphereUserBankAccount[] | undefined;
    index: number;
    setIndex: DispatchType<number>;
}) {
    const { open } = useAppDialog();
    return (
        <Column spacing={0.5}>
            <Row spaceBetween>
                <Text color="caption" variant="body2">
                    Account
                </Text>
                <TextLink variant="body2" onClick={() => open(AppDialog.AddBank, undefined)}>
                    <Add />
                    New Bank
                </TextLink>
            </Row>
            <Select
                loading={!banks}
                value={index}
                setValue={setIndex}
                options={(banks ?? []).map((b, i) => ({ value: i, label: formatBank(b) }))}
            />
        </Column>
    );
}

function VerifiedBankCtaWrapper({ children }: { children: ReactNode }) {
    const { isVerified, isLoading: sphereLoading } = useUserSphere();
    const { data: banks, isLoading: banksLoading } = useUserBankAccounts();
    const validBanks = banks?.map((b) => isBankAccountReadyForTransfers(b));

    if (sphereLoading || banksLoading) {
        return <>{children}</>;
    }

    if (banks?.length === 0 || !isVerified) {
        return (
            <Column spacing={2}>
                <Column>
                    <Text variant="h4">No bank accounts connected</Text>
                    <Text color="caption">Add your first bank account to deposit dollars into Loopscale </Text>
                </Column>
                <ConditionalLink to={ACCOUNT_BANKS_SLUG}>
                    <Button fullWidth variant="contained">
                        Connect bank account
                    </Button>
                </ConditionalLink>
            </Column>
        );
    }

    if (validBanks?.length === 0) {
        return (
            <Column spacing={0.5}>
                <Text variant="h4">
                    <Icon type="pending" /> Verification in progress
                </Text>
                <Text color="caption">
                    Transfers are on hold until your bank is verified (typically 1-3 business days)
                </Text>
            </Column>
        );
    }

    return <>{children}</>;
}
