import { CARD_WIDTH, Column, OutlinedCard, Row, Text } from "@bridgesplit/ui";
import {
    BsMetaUtil,
    LockboxExpanded,
    formatTokens,
    useActiveEscrow,
    useBsMetadataByMint,
    useCloseLockboxesTransaction,
    useEscrowPreference,
    useEscrowedLockboxes
} from "@bridgesplit/abf-react";
import { IS_LOCAL_NX_DEV, LOCKBOX_RENT, WRAPPED_SOL_MINT } from "@bridgesplit/utils";

import { useWithdrawAllAssetsFromLockbox } from "../loan/util";
import { AppButton, OverlappingMetadataImages } from "../common";
import { useTransactionSender } from "../transactions";
import { Page } from "../wrappers";

export default function LockboxAssets() {
    const { lockboxesWithAssets, emptyLockboxes } = useEscrowedLockboxes();

    return (
        <Page maxWidth={CARD_WIDTH} permission>
            <OutlinedCard spacing={3} padding={2}>
                <Text variant="h3"> Closable accounts </Text>
                {lockboxesWithAssets?.length === 0 && emptyLockboxes?.length === 0 && <Text>No assets to claim </Text>}
                {!!emptyLockboxes?.length && <EmptyLockboxes lockboxes={emptyLockboxes} />}
                {IS_LOCAL_NX_DEV && !!lockboxesWithAssets?.length && <ReclaimAll lockboxes={lockboxesWithAssets} />}

                {!!lockboxesWithAssets?.length && (
                    <Column spacing={2}>
                        <Text variant="body2" color="caption">
                            You have pending assets
                        </Text>
                        {lockboxesWithAssets?.map((l) => (
                            <Lockbox key={l.data.address} lockbox={l} />
                        ))}
                    </Column>
                )}
            </OutlinedCard>
        </Page>
    );
}

function EmptyLockboxes({ lockboxes }: { lockboxes: LockboxExpanded[] }) {
    const sender = useTransactionSender();
    const closeLockboxes = useCloseLockboxesTransaction();
    const metadata = useBsMetadataByMint(WRAPPED_SOL_MINT);
    const { activeEscrow } = useActiveEscrow();
    if (!activeEscrow) return null;

    return (
        <Row spaceBetween>
            <Text variant="body2" color="caption">
                Reclaim ~{BsMetaUtil.formatAmount(metadata, LOCKBOX_RENT * lockboxes.length)} by closing accounts
            </Text>
            <AppButton
                color="error"
                fullWidth={false}
                isTransaction
                asyncCta={{
                    onClickWithResult: () =>
                        sender(closeLockboxes, {
                            lockboxes: lockboxes.map((l) => ({
                                lockboxAddress: l.data.address,
                                escrowAccount: l.escrow?.escrowAccountAddress ?? null
                            }))
                        })
                }}
            >
                Reclaim
            </AppButton>
        </Row>
    );
}

function Lockbox({ lockbox }: { lockbox: LockboxExpanded }) {
    const { escrowNeeded: escrowRequired } = useEscrowPreference();
    const withdraw = useWithdrawAllAssetsFromLockbox();

    return (
        <Row spaceBetween>
            <Row spacing={1}>
                <OverlappingMetadataImages size="20px" metadata={lockbox.assets.map((t) => t.metadata)} />

                <Text>{formatTokens(lockbox.assets)}</Text>
            </Row>
            <AppButton
                isTransaction
                color="error"
                textProps={{ variant: "body2" }}
                fullWidth={false}
                variant="text"
                asyncCta={{
                    onClickWithResult: () =>
                        withdraw([
                            {
                                escrowAccount: lockbox.escrow?.escrowAccountAddress ?? null,
                                lockbox,
                                escrowNeeded: escrowRequired && !!lockbox.escrow
                            }
                        ])
                }}
            >
                Withdraw
            </AppButton>
        </Row>
    );
}

function ReclaimAll({ lockboxes }: { lockboxes: LockboxExpanded[] }) {
    const withdraw = useWithdrawAllAssetsFromLockbox();
    const { escrowNeeded: escrowRequired } = useEscrowPreference();

    async function claimAll() {
        return await withdraw(
            lockboxes.map((lockbox) => ({
                escrowAccount: lockbox.escrow?.escrowAccountAddress ?? null,
                lockbox,
                escrowNeeded: escrowRequired && !!lockbox.escrow
            }))
        );
    }

    return (
        <AppButton
            isTransaction
            color="error"
            fullWidth={false}
            asyncCta={{
                onClickWithResult: claimAll
            }}
        >
            Withdraw All
        </AppButton>
    );
}
