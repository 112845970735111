import { Icon, SPACING } from "@bridgesplit/ui";
import {
    AdminPanelSettingsOutlined,
    AllInclusiveOutlined,
    ManageSearchOutlined,
    PieChartOutline,
    PostAddOutlined
} from "@mui/icons-material";
import { AbfGroupType, useCustodianByIdentifier, useGroup, validateIsCustodian } from "@bridgesplit/abf-react";
import { Hidable } from "@bridgesplit/utils";
import {
    CUSTODIAN_ADMIN_PERMISSIONS_SLUG,
    EXPLORE_REQUESTS_SLUG,
    CREATE_BORROW_REQUEST_SLUG,
    PORTFOLIO_OVERVIEW_SLUG,
    REWARDS_SLUG,
    LOOPS_SLUG,
    MARKETS_SLUG,
    MARKET_SLUG
} from "app/constants";

import { NavOption } from "./types";
import { getCustodianPath } from "../common";
import { DESKTOP_PADDING } from "./constants";

export function useNavOptions(params?: { isMobile?: boolean }) {
    const { isCustodianAdmin } = useUiPermission();

    const showRequestBased = false;

    const isMobile = !!params?.isMobile;

    const options: Hidable<NavOption>[] = [
        {
            title: "Borrow/Lend",
            path: MARKETS_SLUG,
            icon: <Icon type="lend" />,
            pathRegex: new RegExp(`${MARKETS_SLUG}|${MARKET_SLUG}`)
        },
        {
            title: "Loop",
            path: LOOPS_SLUG,
            icon: <AllInclusiveOutlined />,
            pathRegex: new RegExp(LOOPS_SLUG)
        },
        {
            title: "Open Loans",
            path: EXPLORE_REQUESTS_SLUG,
            icon: <ManageSearchOutlined />,
            hide: !showRequestBased
        },
        {
            title: isMobile ? "Request" : "Create Request",
            path: CREATE_BORROW_REQUEST_SLUG,
            icon: <PostAddOutlined />,
            hide: !showRequestBased
        },

        {
            title: "Permissions",
            path: CUSTODIAN_ADMIN_PERMISSIONS_SLUG,
            icon: <AdminPanelSettingsOutlined />,
            hide: !isCustodianAdmin
        },
        {
            title: "Portfolio",
            path: PORTFOLIO_OVERVIEW_SLUG,
            icon: <PieChartOutline />,
            hide: isCustodianAdmin
        },
        {
            title: "Rewards",
            path: REWARDS_SLUG,
            icon: <Icon type="points" />
        }
    ].filter((v) => !v.hide);

    return options;
}

export function useNavbarHeight() {
    const height = 70;
    const navFooter = 0;
    const footer = 40;

    return {
        footer,
        height,
        navFooter,
        heightInPixels: `${height}px`,
        fullHeight: `calc(100vh - ${height}px)`,
        stickyTop: height + DESKTOP_PADDING * SPACING
    };
}

export function useUiPermission() {
    const { permissions, activeGroup, isLoading } = useGroup();

    const isAdmin = permissions?.isAdmin() && activeGroup?.groupType !== AbfGroupType.Individual;
    const isCustodianAdmin = isAdmin && validateIsCustodian(activeGroup);
    const custodian = useCustodianByIdentifier(isCustodianAdmin ? activeGroup?.groupIdentifier : undefined);

    return {
        isLoading,
        isCustodianAdmin,
        adminCustodianPath: getCustodianPath(custodian),
        isAdmin,
        activeGroup: activeGroup
    };
}
