import { ReactNode } from "react";

import {
    AbfOrderExpanded,
    AirtableCustomerInterestType,
    AbfSyndicateExpanded,
    TransactionWalletAuth,
    LoanRequestExpanded,
    CollateralWithPreset,
    Collateral,
    AbfLoanExpanded,
    StrategyExpanded,
    StrategyTemplateExpanded,
    MarketGuideMode,
    StrategyDuration,
    LoopExpanded
} from "@bridgesplit/abf-react";
import { SphereCustomerToken, ChainId, BsMetadata } from "@bridgesplit/abf-sdk";
import { useDialog } from "@bridgesplit/ui";
import { Result } from "@bridgesplit/utils";
import { FormInputType, DispatchType } from "@bridgesplit/react";

import { MemberPermission, PermissionCreatedUser } from "../components/group/types";
import { MarketProps } from "../components/market-detail/types";
import { WindForm } from "../components/loop";

export enum AppDialog {
    Connect = "Connect",
    WalletSetup = "WalletSetup",
    TransactionSender = "Send transactions",
    WalletConnect = "Connect wallet",
    Wallet = "Wallet",
    PrimeWelcome = "PrimeWelcome",
    MarketGuide = "MarketGuide",

    // escrow
    Deposit = "Deposit",
    DepositToWallet = "DepositToWallet",
    Withdraw = "Withdraw",
    WithdrawFromWallet = "WithdrawFromWallet",
    EvmWithdrawToWallet = "EvmWithdrawToWallet",
    WithdrawUnrecognized = "WithdrawUnrecognizedDialog",
    InstantUnstake = "InstantUnstake",

    // offers
    OfferDetails = "Offer details",
    CancelLoanRequest = "Cancel request",
    ModifyLoanRequestPreferences = "Modify preferences",
    ModifyLoanRequestDetails = "Rename request",
    RequestLenderReject = "Reject request",

    ManageSyndicatedInvites = "Manage invites",
    LoanActions = "LoanActions",
    RefinanceSettings = "RefinanceSettings",

    // auth
    AddMember = "Add member",
    AddGroup = "Add organization",
    ModifyLoanRoles = "Modify roles",
    ModifyAdminRoles = "Modify permissions",
    DeleteMember = "Delete member",
    VerifyMember = "Verify member",
    CreateCustodianInvite = "Create invite",
    KYC = "KYC",
    CreateNewPasskey = "Register new passkey",

    AirtableInterestForm = "Airtable",
    SplashIntake = "SplashIntake",

    StrategyDelete = "Delete position",
    StrategyManageEscrow = "Deposit/Withdraw",
    StrategySeizedCollateral = "Claim collateral",
    StrategyTerms = "Update terms",
    StrategySettings = "Settings",

    AddBank = "Connect bank account",
    RegisterIndividualSphere = "Register with Sphere",
    ManualAddBank = "Manually verify bank",
    RegisterBusinessSphere = "Register business with Sphere",
    SphereAcceptTerms = "Accept terms",
    UploadSphereDocument = "Upload document",
    SphereUBOStatuses = "Check UBO verification statuses",
    MultiSelectCollateral = "MultiSelectCollateral",
    ApplyPrime = "Apply for Prime",
    PrimeLearnMore = "Learn more about Prime",
    SelectToken = "Select token",
    BorrowSelectCollateral = "Select collateral",
    ReferralEnterCode = "Accept a referral",
    Lend = "Lend",
    Borrow = "Borrow",
    TemplateSelect = "TemplateSelect",
    LendApy = "LendApy",
    WindSettings = "WindSettings",
    LoopExternalTransfers = "LoopExternalTransfers",

    AccessLevelUnregistered = "AccessLevelUnregistered",
    AccessLevelNotConnected = "AccessLevelNotConnected",
    AccessLevelWaitlist = "AccessLevelWaitlist",

    Notifications = "Notifications"
}

type Map = {
    [AppDialog.TransactionSender]: {
        description: string;
        resolve: (params: TransactionWalletAuth | PromiseLike<TransactionWalletAuth>) => void;
        reject: () => void;
    };
    [AppDialog.MultiSelectCollateral]: {
        header?: string;
        collateral: string[];
        tokens: BsMetadata[] | undefined;
        setCollateral?: FormInputType<CollateralWithPreset[]>;
        setCollateralAsync?: (collateral: CollateralWithPreset[]) => Promise<Result<unknown>>;
        principalMint?: string;
        isAdvanced: boolean;
    };
    [AppDialog.SelectToken]: {
        tokens: BsMetadata[] | undefined;
        setToken: (token: BsMetadata) => void;
        header?: string;
    };

    [AppDialog.BorrowSelectCollateral]: {
        marketProps: MarketProps;
        strategyDuration: StrategyDuration;
        setToken: (token: Collateral) => void;
    };
    [AppDialog.Deposit]: { specifiedAssetKeys?: string[]; specifiedCustodian?: string; back?: () => void };
    [AppDialog.DepositToWallet]: { specifiedMint?: string; back?: () => void };
    [AppDialog.InstantUnstake]: { balances: "wallet" | "escrow"; back?: () => void };

    [AppDialog.Withdraw]: { mints?: string[]; chain?: ChainId; back?: () => void };
    [AppDialog.WithdrawFromWallet]: { mints?: string[]; back?: () => void };

    [AppDialog.EvmWithdrawToWallet]: { mints: string[]; chain: ChainId };

    [AppDialog.OfferDetails]: { order: AbfOrderExpanded; request: LoanRequestExpanded };
    [AppDialog.CancelLoanRequest]: LoanRequestExpanded;
    [AppDialog.ModifyLoanRequestPreferences]: LoanRequestExpanded;
    [AppDialog.ModifyLoanRequestDetails]: LoanRequestExpanded;
    [AppDialog.RequestLenderReject]: LoanRequestExpanded;

    [AppDialog.ManageSyndicatedInvites]: AbfSyndicateExpanded;

    [AppDialog.SplashIntake]: { type: AirtableCustomerInterestType; custodianName: string };

    [AppDialog.KYC]: { ctaText?: string };

    [AppDialog.AddMember]: void;
    [AppDialog.AddGroup]: void;

    [AppDialog.ModifyLoanRoles]: MemberPermission;
    [AppDialog.ModifyAdminRoles]: MemberPermission;
    [AppDialog.DeleteMember]: MemberPermission;
    [AppDialog.VerifyMember]: PermissionCreatedUser;

    [AppDialog.StrategyDelete]: { strategy: StrategyExpanded };
    [AppDialog.StrategyManageEscrow]: { strategy: StrategyExpanded; side: "deposit" | "withdraw" };
    [AppDialog.StrategySeizedCollateral]: { strategy: StrategyExpanded; loans: AbfLoanExpanded[] };
    [AppDialog.StrategyTerms]: { strategy: StrategyExpanded };
    [AppDialog.StrategySettings]: { strategy: StrategyExpanded };

    [AppDialog.SphereAcceptTerms]: SphereCustomerToken;
    [AppDialog.UploadSphereDocument]: { type: "ubo" | "incorporation" };
    [AppDialog.LoanActions]: {
        loan: AbfLoanExpanded;
        action: { component: ReactNode; label: string };
        includeLoanHeader?: boolean;
        isDrawer?: boolean;
        hideHeader?: boolean;
    };
    [AppDialog.RefinanceSettings]: { loan: AbfLoanExpanded };
    [AppDialog.Lend]: { token: BsMetadata };
    [AppDialog.Borrow]: { token: BsMetadata };
    [AppDialog.TemplateSelect]: {
        select: (strategyTemplate: StrategyTemplateExpanded) => void;
        templates: StrategyTemplateExpanded[];
    };

    [AppDialog.MarketGuide]: {
        mode: MarketGuideMode;
        isForced?: boolean;
        isNestedDialog?: boolean;
        loopExpanded?: LoopExpanded; //Added for loops guide
    };
    [AppDialog.LendApy]: {
        setApy: (apy: number | undefined) => void;
        marketMinApy: number;
        defaultRate: number;
        currentApy: number;
    };
    [AppDialog.WindSettings]: { loopExpanded: LoopExpanded; form: WindForm; setForm: DispatchType<WindForm> };
    [AppDialog.LoopExternalTransfers]: { loopExpanded: LoopExpanded };

    [AppDialog.AccessLevelNotConnected]: void;
    [AppDialog.AccessLevelUnregistered]: void;
    [AppDialog.AccessLevelWaitlist]: void;

    [AppDialog.Notifications]: void;
};

export type AppDialogData<T extends AppDialog> = T extends keyof Map ? Map[T] : void;

export function useAppDialog() {
    const dialog = useDialog();

    function open<T extends AppDialog>(type: T, data: AppDialogData<T>) {
        dialog.open<AppDialogData<T>>(type, data);
    }

    function getData<T extends AppDialog>() {
        return dialog.getData<AppDialogData<T>>(dialog.openIdentifier as AppDialog);
    }

    const openIdentifier = dialog.openIdentifier as AppDialog;

    return {
        close: dialog.close,
        open,
        getData,
        openIdentifier,
        setPreventClose: dialog.setPreventClose,
        preventClose: dialog.preventClose
    };
}
