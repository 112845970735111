import { useMemo } from "react";

import {
    BsMetaUtil,
    calculateLoopNetApy,
    formatLeverage,
    useCollateralYieldHistoryQuery,
    useLoopVaultBorrowRateHistoryQuery,
    useRefinanceInfo
} from "@bridgesplit/abf-react";
import {
    Column,
    Dot,
    formatChartPercent,
    Icon,
    MEDIA,
    PriceLineChartWithAxis,
    Row,
    StatColumn,
    Text,
    useAppPalette
} from "@bridgesplit/ui";
import { formatDate, formatPercent, getUnixTs, percentUiToDecimals, TIME } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMediaQuery } from "@mui/material";

import { useLoopContext } from "../LoopContext";
import { RatesComparisonPeriodSelect, useRatesComparisonPeriodSelect } from "../../common";
import { useWindContext } from "../wind";

export default function LoopApyHistory() {
    const periodController = useRatesComparisonPeriodSelect("LoopApyHistoryPeriod");
    const rates = useLoopApyHistory(periodController.period);

    const { secondary, primary } = useAppPalette();
    const isMobile = useMediaQuery(MEDIA.MD.below);

    return (
        <Column spacing={2}>
            <Row spaceBetween>
                <Text color="caption">Historical APY</Text>
                <RatesComparisonPeriodSelect {...periodController} />
            </Row>

            <PriceLineChartWithAxis
                keys={[
                    { key: "netApy", color: secondary },
                    { key: "collateralApy", color: primary }
                ]}
                height={200}
                formatYAxis={formatChartPercent}
                formatXAxis={(seconds) => formatDate(seconds, "date", { showTime: "never" })}
                aspectRatio={isMobile ? 2 : 3}
                data={rates}
                tooltip={Tooltip}
            />
        </Column>
    );
}

type RatesInfo = {
    date: number;
    netApy: number;
    collateralApy: number;
    borrowApy: number;
};

function Tooltip(rates: RatesInfo | undefined) {
    const { primary, secondary } = useAppPalette();
    const { loopExpanded } = useLoopContext();
    const {
        form: { multiplier }
    } = useWindContext();
    if (!rates) return null;
    const { netApy, collateralApy, date, borrowApy } = rates;
    const leverageMultiplier = multiplier ?? loopExpanded?.loopVault.maxLeverage ?? 1;

    return (
        <Column sx={{ minWidth: "150px" }} spacing={1.5}>
            <Text color="caption">
                <Icon type="date" /> {formatDate(date)}
            </Text>

            <StatColumn
                stats={[
                    {
                        caption: `${formatLeverage(leverageMultiplier)} APY`,
                        value: (
                            <Text variant="body2">
                                <Dot color={secondary} /> {formatPercent(netApy)}
                            </Text>
                        )
                    },
                    {
                        caption: `${BsMetaUtil.getSymbol(loopExpanded?.collateralToken)} APY`,
                        value: (
                            <Text variant="body2">
                                <Dot color={primary} /> {formatPercent(collateralApy)}
                            </Text>
                        )
                    },
                    {
                        caption: "Borrow APY",
                        value: <Text variant="body2">{formatPercent(borrowApy)}</Text>
                    }
                ]}
            />
        </Column>
    );
}

const now = getUnixTs();
function useLoopApyHistory(period: number) {
    const { loopExpanded } = useLoopContext();
    const {
        form: { multiplier },
        durationForQueries
    } = useWindContext();

    const principalMint = loopExpanded?.principalToken.assetMint;
    const collateralMint = loopExpanded?.collateralToken.assetMint;
    const { data: collateralYield } = useCollateralYieldHistoryQuery(
        collateralMint
            ? {
                  startTime: now - period,
                  endTime: now,
                  collateralMint
              }
            : skipToken,
        { skip: !loopExpanded }
    );
    const { data: borrowRates } = useLoopVaultBorrowRateHistoryQuery(
        loopExpanded
            ? {
                  timeLookback: period,
                  loopVaultIdentifier: loopExpanded.vaultIdentifier
              }
            : skipToken,
        { skip: !loopExpanded }
    );

    const { getRefinanceInfo } = useRefinanceInfo(
        principalMint && collateralMint ? { [principalMint]: [collateralMint] } : undefined
    );
    const ltv = getRefinanceInfo(principalMint, collateralMint)?.ltv;

    const timeToBorrowRates = useMemo(() => {
        if (!borrowRates || !durationForQueries) return undefined;
        const rates = borrowRates;

        return new Map(
            rates.map(({ timestamp, borrowRate }) => [roundTimestamp(timestamp), percentUiToDecimals(borrowRate)])
        );
    }, [durationForQueries, borrowRates]);

    return useMemo(() => {
        if (!collateralYield || !borrowRates) return undefined;
        const seenTimestamps = new Set<number>();
        const data: RatesInfo[] = [];

        for (const { timestamp, apy: collateralApy } of collateralYield) {
            const roundedTimestamp = roundTimestamp(timestamp);
            if (seenTimestamps.has(roundedTimestamp)) continue;
            seenTimestamps.add(roundedTimestamp);

            const borrowApy = timeToBorrowRates?.get(roundedTimestamp);
            const leverageMultiplier = multiplier ?? loopExpanded?.loopVault.maxLeverage ?? 1;
            if (!borrowApy) continue;
            const netApy = calculateLoopNetApy({
                collateralYield: collateralApy,
                leverageMultiplier,
                principalBorrowRate: borrowApy,
                ltv: ltv ?? 1
            });
            data.push({ date: roundedTimestamp, netApy, collateralApy, borrowApy });
        }

        return data;
    }, [borrowRates, collateralYield, loopExpanded?.loopVault.maxLeverage, ltv, multiplier, timeToBorrowRates]);
}

function roundTimestamp(timestamp: number) {
    const roundTo = 30 * TIME.MINUTE;
    return Math.floor(timestamp / roundTo) * roundTo;
}
