import { useState } from "react";

import { ToggleSpacedButtons } from "@bridgesplit/ui";

import { GuideBullets, GuideFooter } from "./common";
import { OnboardingBullet } from "./constants";
import { DialogHeaderWithBack, HeaderWithClose } from "../../common";

export default function MultiRoleGuide({
    title,
    sections,
    close,
    isNested
}: {
    close: () => void;
    title: string;
    isNested?: boolean;
    sections: { label: string; bullets: OnboardingBullet[]; docs: string }[];
}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const activeSection = sections[selectedIndex];
    return (
        <>
            {isNested ? (
                <DialogHeaderWithBack back={close} header={title} />
            ) : (
                <HeaderWithClose close={close} header={title} />
            )}

            <ToggleSpacedButtons
                variant="default"
                size="small"
                options={sections.map(({ label }, i) => ({
                    label,
                    value: i
                }))}
                value={selectedIndex}
                setValue={(index) => {
                    setSelectedIndex(index);
                }}
            />

            <GuideBullets bullets={activeSection.bullets} />
            <GuideFooter docs={activeSection.docs} />
        </>
    );
}
