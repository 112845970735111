import { useCallback } from "react";

import {
    isValidPublicKey,
    useAbfFetches,
    useFetchTwitterOAuthTokenMutation,
    useVerifyTwitterFollowMutation
} from "@bridgesplit/abf-react";
import { useSearchParams } from "react-router-dom";
import { Result, formatAddress, getReadableErrorMessage, parseErrorFromOptions } from "@bridgesplit/utils";
import { mutationIntoRawResult, useAsyncResultHandler, useAppNavigate } from "@bridgesplit/react";
import { useOnInitialRenderRef } from "@bridgesplit/ui";
import { WAITLIST_SLUG } from "app/constants";

const DEFAULT_ERROR = getReadableErrorMessage("verify with X");
const skipSentry = true;
export default function TwitterCallback() {
    const [params] = useSearchParams();
    const oauthToken = params.get("oauth_token");
    const oauthVerifier = params.get("oauth_verifier");
    const { resetPoints } = useAbfFetches();

    const [fetchTwitterAccessToken] = useFetchTwitterOAuthTokenMutation();
    const [verifyTwitterFollow] = useVerifyTwitterFollowMutation();

    const navigate = useAppNavigate();
    const { resultHandler, skipHook } = useAsyncResultHandler();

    const onFinish = useCallback(() => navigate(WAITLIST_SLUG), [navigate]);

    const verify = useCallback(async () => {
        if (!oauthToken || !oauthVerifier) return Result.errFromMessage("No X credentials provided", { skipSentry });
        const accessTokenResult = await mutationIntoRawResult(
            fetchTwitterAccessToken,
            {
                oauthToken,
                oauthVerifier: oauthVerifier
            },
            { skipSentry }
        );
        if (!accessTokenResult.isOk()) {
            const parsedError = parseErrorFromOptions(accessTokenResult.unwrapErrMessage(), TWITTER_ERRORS);
            return Result.errFromMessage(parsedError ?? DEFAULT_ERROR, { skipSentry });
        }
        const verifyFollowRes = await mutationIntoRawResult(verifyTwitterFollow, {
            oauthToken: accessTokenResult.unwrap().oauthToken
        });

        if (verifyFollowRes.isOk()) {
            resetPoints();
            return verifyFollowRes;
        }

        const rawError = verifyFollowRes.unwrapErrMessage();
        if (rawError.includes(DUPLICATE_ERROR)) {
            const parsedWallet = parseDuplicateWallet(rawError);
            if (parsedWallet) {
                return Result.errFromMessage(
                    `This X account is already connected to a different wallet: ${formatAddress(parsedWallet)}`,
                    { skipSentry }
                );
            }
        }
        const parsedError = parseErrorFromOptions(verifyFollowRes.unwrapErrMessage(), TWITTER_ERRORS);
        return Result.errFromMessage(parsedError ?? DEFAULT_ERROR, { skipSentry });
    }, [fetchTwitterAccessToken, oauthToken, oauthVerifier, resetPoints, verifyTwitterFollow]);

    useOnInitialRenderRef(
        () =>
            resultHandler(verify, {
                alertOnError: true,
                onFail: onFinish,
                onSuccess: onFinish,
                onSuccessMessage: "X following verified"
            }),
        skipHook
    );

    return null;
}

function parseDuplicateWallet(input: string): string | null {
    const match = input.match(/"([^"]*)"/);
    if (!match || !match[1]) return null;

    if (isValidPublicKey(match[1])) return match[1];
    return null;
}

const DUPLICATE_ERROR = "User or handle already marked as followed by wallet";
const TWITTER_ERRORS = {
    "is not followed by the user": "You aren't following @LoopscaleLabs on X",
    "User not logged into twitter!": "Unable to verify your account with X servers",
    "Invalid log-in from Twitter": "Unable to verify your account with X servers",
    "OAuth token does not match": "Unable to verify your account with X servers",
    "User or handle already marked as followed": "This X account is already connected to a different Loopscale account"
};
