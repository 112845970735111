import { LoopExpandedMeteora } from "@bridgesplit/abf-react";
import { Column, Text, TextExternalLink } from "@bridgesplit/ui";

export function MeteoraCtaWrapper({
    children,
    loopExpanded,
    side
}: {
    children: React.ReactNode;
    loopExpanded: LoopExpandedMeteora;
    side: "deposits" | "withdrawals";
}) {
    return (
        <Column spacing={1}>
            {children}
            <Text color="caption" variant="body2" sx={{ display: "inline-flex", gap: 0.5 }}>
                Balanced {side} can be made on{" "}
                <TextExternalLink href={`https://app.meteora.ag/pools/${loopExpanded.meteoraPool.poolAddress}`}>
                    Meteora
                </TextExternalLink>
            </Text>
        </Column>
    );
}
