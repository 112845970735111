import {
    TIME,
    bsMath,
    convertDateToSeconds,
    findMaxElement,
    findMinElement,
    formatNum,
    getUnixTs
} from "@bridgesplit/utils";
import { Frequency } from "rrule";

export enum DurationUnit {
    Seconds,
    Days,
    Weeks,
    Months,
    Years,
    Minutes,
    Hours
}

export interface DurationWithUnit {
    duration: number;
    durationType: DurationUnit;
}

export const DEFAULT_DURATION = DurationUnit.Weeks;

export enum RRuleWeekday {
    Mon = "MO",
    Tue = "TU",
    Wed = "WE",
    Thur = "TH",
    Fri = "FR",
    Sat = "SA",
    Sun = "SU"
}

const weekdayLetters: { [key in RRuleWeekday]: string } = {
    [RRuleWeekday.Mon]: "M",
    [RRuleWeekday.Tue]: "T",
    [RRuleWeekday.Wed]: "W",
    [RRuleWeekday.Thur]: "R",
    [RRuleWeekday.Fri]: "F",
    [RRuleWeekday.Sat]: "S",
    [RRuleWeekday.Sun]: "U"
};

export function getWeekdaySingleLetter(day: RRuleWeekday): string {
    return weekdayLetters[day] || "";
}

export enum RRuleMonthlyOption {
    FirstDayOfMonth = "First day of the month",
    FirstWeekDayOfMonth = "First weekday of the month",
    LastDayOfMonth = "Last day of the month",
    LastWeekDayOfMonth = "Last weekday of the month"
}

export function getDurationInSeconds(durationTime: number | undefined, durationUnit: number) {
    return bsMath.mul(durationTime, getDurationDetails(durationUnit).time) || 0;
}

export function getDurationFromNowEstimate(durationTime: number | undefined, durationUnit: number) {
    if (!durationTime) return getUnixTs();

    if (durationUnit === DurationUnit.Months) {
        const date = new Date();
        date.setMonth(date.getMonth() + durationTime);
        return convertDateToSeconds(date);
    }
    return getUnixTs() + durationTime * getDurationDetails(durationUnit).time;
}

export function getDurationDetails(unit: DurationUnit) {
    const details = DURATION_UNIT_MAP[unit];
    if (!details) return DURATION_UNIT_MAP[DurationUnit.Weeks];
    return details;
}

export function formatDurationWithType(duration: number | undefined, durationType: DurationUnit | undefined): string {
    if (!duration || durationType === undefined) return "--";

    const freqDetails = getDurationDetails(durationType);
    return `${formatNum(duration)} ${freqDetails.unit.toLocaleLowerCase()}${duration === 1 ? "" : "s"}`;
}

export function formatMinMaxDurations(durations: { duration: number; durationType: DurationUnit }[] | undefined) {
    const minDuration = findMinElement(durations, (d) => getDurationInSeconds(d.duration, d.durationType));
    const maxDuration = findMaxElement(durations, (d) => getDurationInSeconds(d.duration, d.durationType));

    if (!minDuration || !maxDuration) return "--";
    if (minDuration.durationType === maxDuration.durationType) {
        if (minDuration.duration === maxDuration.duration) {
            return formatDurationWithType(minDuration.duration, minDuration.durationType);
        }
        const unit = getDurationDetails(minDuration.durationType).unit.toLowerCase();
        return `${formatNum(minDuration.duration)} - ${formatNum(maxDuration.duration)} ${unit}`;
    }

    return `${formatDurationWithType(minDuration.duration, minDuration.durationType)} - ${formatDurationWithType(
        maxDuration.duration,
        maxDuration.durationType
    )}`;
}

export function formatDurationWithTypeShorthand(
    duration: number | undefined,
    durationType: DurationUnit | undefined
): string {
    if (!duration || durationType === undefined) return "--";

    const freqDetails = getDurationDetails(durationType);
    return `${formatNum(duration)}${freqDetails.unit.toUpperCase()[0]}`;
}

export function secondsToDuration(seconds: number | undefined): { duration: number; durationUnit: DurationUnit } {
    if (!seconds || seconds < TIME.MINUTE) {
        return { duration: seconds || 0, durationUnit: DurationUnit.Seconds };
    }
    if (seconds < TIME.HOUR) {
        return { duration: seconds / TIME.MINUTE, durationUnit: DurationUnit.Minutes };
    }
    if (seconds < TIME.DAY) {
        return { duration: seconds / TIME.HOUR, durationUnit: DurationUnit.Hours };
    }
    if (seconds < TIME.WEEK) {
        return { duration: seconds / TIME.DAY, durationUnit: DurationUnit.Days };
    }
    if (seconds < TIME.MONTH) {
        return { duration: seconds / TIME.WEEK, durationUnit: DurationUnit.Weeks };
    }
    if (seconds < TIME.YEAR) {
        return { duration: seconds / TIME.MONTH, durationUnit: DurationUnit.Months };
    }
    return { duration: seconds / TIME.YEAR, durationUnit: DurationUnit.Years };
}

export function getRRuleFrequencyDurationDetails(freq: Frequency) {
    return (
        Object.values(DURATION_UNIT_MAP).find(({ frequency }) => frequency === freq) ??
        DURATION_UNIT_MAP[DEFAULT_DURATION]
    );
}

export type DurationDetails = {
    time: number;
    frequency: Frequency;
    unit: string;
    unitSingle: string;
    type: DurationUnit;
    paramUnit: string;
};

const DURATION_UNIT_MAP: Record<DurationUnit, DurationDetails> = {
    [DurationUnit.Seconds]: {
        time: TIME.SECOND,
        frequency: Frequency.SECONDLY,
        unit: "Second",
        unitSingle: "Secondly",
        type: DurationUnit.Seconds,
        paramUnit: "Seconds"
    },
    [DurationUnit.Minutes]: {
        time: TIME.MINUTE,
        frequency: Frequency.MINUTELY,
        unit: "Minute",
        unitSingle: "Minutely",
        type: DurationUnit.Minutes,
        paramUnit: "Minutes"
    },
    [DurationUnit.Hours]: {
        time: TIME.HOUR,
        frequency: Frequency.HOURLY,
        unit: "Hour",
        unitSingle: "Hourly",
        type: DurationUnit.Hours,
        paramUnit: "Hours"
    },
    [DurationUnit.Days]: {
        time: TIME.DAY,
        frequency: Frequency.DAILY,
        unit: "Day",
        unitSingle: "Daily",
        type: DurationUnit.Days,
        paramUnit: "Days"
    },
    [DurationUnit.Weeks]: {
        time: TIME.WEEK,
        frequency: Frequency.WEEKLY,
        unit: "Week",
        unitSingle: "Weekly",
        type: DurationUnit.Weeks,
        paramUnit: "Weeks"
    },
    [DurationUnit.Months]: {
        time: TIME.MONTH,
        frequency: Frequency.MONTHLY,
        unit: "Month",
        unitSingle: "Monthly",
        type: DurationUnit.Months,
        paramUnit: "Months"
    },
    [DurationUnit.Years]: {
        time: TIME.YEAR,
        frequency: Frequency.YEARLY,
        unit: "Year",
        unitSingle: "Yearly",
        type: DurationUnit.Years,
        paramUnit: "Years"
    }
};

export const DURATION_OPTIONS = [
    DurationUnit.Minutes,
    DurationUnit.Hours,
    DurationUnit.Days,
    DurationUnit.Weeks,
    DurationUnit.Months,
    DurationUnit.Years
].map((o) => {
    const details = getDurationDetails(o as DurationUnit);
    return { value: o, label: details.unit + "s" };
});
