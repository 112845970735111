export function colorToAlpha(color: string, alpha?: number) {
    const alphaVal = alpha ?? 1;

    if (color.includes("rgba")) {
        const rgb = color.replace("(", "").replace(")", "").split("rgba")[1].split(",");
        return `rgba(${rgb[0].trim()}, ${rgb[1].trim()}, ${rgb[2].trim()}, ${alphaVal})`;
    }

    if (color.includes("rgb")) {
        const rgb = color.replace("(", "").replace(")", "").split("rgb")[1].split(",");
        return `rgba(${rgb[0].trim()}, ${rgb[1].trim()}, ${rgb[2].trim()}, ${alphaVal})`;
    }

    if (color.startsWith("#")) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alphaVal})`;
    }

    return color;
}

export function textContains(val: string | string[], search: string) {
    const text = typeof val === "string" ? val : val.join("");

    // prevent "Invalid regular expression: /\/i: \ at end of pattern"
    const safeSearch = search.replace(/[^a-zA-Z0-9]/g, "").trim();

    return text.replace(`\\`, "").search(new RegExp(safeSearch, "i")) !== -1;
}

export function parseStringArray(rawString: string | undefined): string[] | undefined {
    if (!rawString) return undefined;
    return rawString
        ?.replace("[", "")

        .replace("]", "")
        .split(",")
        .map((t) => t.trim().replace(`"`, "").replace(`"`, ""))
        .filter((t) => !!t);
}

export function getErrorFromRegex({
    input,
    regex,
    message,
    emptyErrorMessage
}: {
    regex: RegExp;
    input: string | undefined;
    message?: string;
    emptyErrorMessage?: string;
}) {
    if (!input) return emptyErrorMessage ?? "Required";

    const valid = regex.test(input);
    if (!valid) return message;
    return undefined;
}

export function getAmountInputError({
    input,
    emptyErrorMessage
}: {
    input: number | undefined;
    message?: string;
    emptyErrorMessage?: string;
}) {
    if (input !== 0 && !input) return emptyErrorMessage ?? "Required";

    if (input < 0) return "Must be >0";

    return undefined;
}

export function getAmountStringError({
    input,
    maxCharacters,
    required
}: {
    input: string | undefined;
    maxCharacters?: number;
    required?: boolean;
}) {
    if (!input && required) return "Required";

    if (input && maxCharacters && input.length >= maxCharacters) return `Must be less than ${maxCharacters} characters`;

    return undefined;
}
