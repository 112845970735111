import { Column } from "@bridgesplit/ui";

import BorrowAmount from "./BorrowAmount";
import BorrowSetTerm from "./BorrowSetTerm";
import BorrowCta from "./BorrowCta";
import BorrowStats from "./BorrowStats";
import BorrowCollateral from "./BorrowCollateral";
import BorrowOracleUpdate from "./BorrowOracleUpdate";
import { useMarketBorrowHooks } from "./util";

export default function BorrowCard() {
    useMarketBorrowHooks();
    return (
        <Column spacing={3}>
            <BorrowCollateral />
            <BorrowSetTerm />
            <BorrowAmount />
            <Column spacing={2}>
                <BorrowStats />
                <BorrowOracleUpdate />
                <BorrowCta />
            </Column>
        </Column>
    );
}
