import { useState } from "react";

import {
    AbfPermissionForCustodian,
    BsMetaUtil,
    getNextLedgerAccount,
    isSimpleLoan,
    summarizeChangesFromNewInterest,
    summarizeLedgerAccounts,
    useActiveWallet,
    useIsLoanEscrowBased,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { formatAddress, MayNumber } from "@bridgesplit/utils";
import { Column, Text, TokenInput } from "@bridgesplit/ui";

import { MakeRepaymentParams, MakeRepaymentProps } from "./types";
import { AppButton, useBalanceChecker } from "../../../common";
import { useSingleRepay, useZcRepay, calculateNewLoanInterestDue } from "./util";
import RepayStats from "./RepayStats";

const FULL_REPAY_AMOUNT = -1;
export default function SinglePayment({ loanExpanded }: MakeRepaymentProps) {
    const [_inputAmountState, setAmount] = useState<MayNumber>(FULL_REPAY_AMOUNT);

    const { nextLedgerAccount } = getNextLedgerAccount(loanExpanded);
    const ledgerAccounts = nextLedgerAccount ? [nextLedgerAccount] : [];

    const { totalOutstanding, totalOutstandingWithoutFees } = summarizeLedgerAccounts(loanExpanded, ledgerAccounts);
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const isZcRepay = isSimpleLoan(loanExpanded);
    const { activeWallet } = useActiveWallet();

    // by default, recalculate loan interest based on the full principal due
    const fullRepay = calculateNewLoanInterestDue({
        loanExpanded,
        repaymentAmount: isZcRepay ? totalOutstanding : totalOutstandingWithoutFees,
        ledgerAccounts
    });

    const amountDue = loanExpanded ? summarizeChangesFromNewInterest(loanExpanded, fullRepay).totalToPay : 0;

    // Determine the input amount based on the state or the sum of due amounts
    const inputAmount = _inputAmountState === FULL_REPAY_AMOUNT ? amountDue : _inputAmountState;

    // recalculate loan interest based on the amount inputted by user (could be partial principal)
    const repayBasedOnInput = calculateNewLoanInterestDue({
        loanExpanded,
        repaymentAmount: inputAmount,
        ledgerAccounts
    });
    const isFullRepay = _inputAmountState === FULL_REPAY_AMOUNT || _inputAmountState === amountDue;

    const params: MakeRepaymentParams = {
        loanExpanded,
        payment: repayBasedOnInput,
        isFullRepay
    };
    const standard = useSingleRepay(params);
    const zc = useZcRepay(params);

    const { repay } = isZcRepay ? zc : standard;

    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        mint: loanExpanded?.principalMetadata.assetMint,
        amount: inputAmount,
        escrowNeeded,
        setMax: (val) => {
            if (val && val >= amountDue) {
                setAmount(FULL_REPAY_AMOUNT);
            } else {
                setAmount(val);
            }
        }
    });

    return (
        <>
            <TokenInput
                symbol={BsMetaUtil.getSymbol(loanExpanded?.principalMetadata)}
                label="Amount"
                value={inputAmount}
                decimals={loanExpanded?.principalMetadata.decimals}
                setValue={(val) => {
                    // manually prevent double on change due to manually modifying value
                    if (val === amountDue) {
                        setAmount(FULL_REPAY_AMOUNT);
                    } else {
                        setAmount(val);
                    }
                }}
                setMax={(amt) => {
                    if (amt) {
                        setAmount(FULL_REPAY_AMOUNT);
                    } else {
                        setAmount(amt);
                    }
                }}
                maxText="Total due: "
                maxAmount={amountDue}
                maxButtonCta="Full payment"
            />

            <RepayStats loanExpanded={loanExpanded} paymentInfo={repayBasedOnInput} />
            {escrowNeeded && isFullRepay && isZcRepay && (
                <Text color="caption" variant="body2">
                    Collateral will be returned to your wallet ({formatAddress(activeWallet?.wallet)})
                </Text>
            )}
            <Column spacing={0.5}>
                <AppButton
                    isTransaction
                    authentication={{
                        permission: AbfPermissionForCustodian.RepayLoan,
                        custodians: getLoanCustodianIdentifiers(loanExpanded)
                    }}
                    disabled={!inputAmount || inputAmount > amountDue || insufficientBalance}
                    asyncCta={{ onClickWithResult: repay, closeDialog: "onSuccess" }}
                    fullWidth
                >
                    Repay
                </AppButton>
                <BalanceDisplay />
            </Column>
        </>
    );
}
