import { BsMetadata, DurationUnit } from "@bridgesplit/abf-sdk";

//Napoleon Preset
export interface PresetOfferTerms {
    id: number;
    presetOfferIdentifier: string;
    presetStrategyTermsIdentifier: string;
    collateralMint: string;
    ltv: number;
    liquidationThreshold: number;
    name: string;
    description: string;
    assetClass: number;
}

export interface PresetTerms {
    offerTerms: PresetOfferTerms;
    strategyTerms: PresetStrategyTerms;
}

export interface PresetStrategyTerms {
    id: number;
    presetStrategyIdentifier: string;
    duration: number;
    durationType: number;
    repaymentRrule: string;
    maxOutstandingPayments: number;
    allowEarlyRepayments: boolean;
}

export interface StrategyDuration {
    duration: number;
    durationType: DurationUnit;
}

export interface CollateralWithPreset {
    metadata: BsMetadata;
    presets: PresetTerms[];
}

export const DEFAULT_PRESET: StrategyDuration = {
    duration: 1,
    durationType: DurationUnit.Days
};
