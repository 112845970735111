export interface OracleQuoteInfo {
    price: number;
    quoteMint: string;
    lastUpdateTime: number;
    oracleType: OracleType;
    maxStaleness: number;
    nowTimestamp: number;
}

export interface OracleQuote {
    usdPrice: number;
    getUsdAmount: <T extends number | undefined>(amount: T, decimals?: number) => T;
    baseQuotes: OracleQuoteInfo[];
}

export enum OracleType {
    PythLegacy,
    StakePoolLST,
    MeteoraLP,
    MeteoraVault,
    SwitchboardPull,
    OrcaWhirlpool,
    Pyth
}
