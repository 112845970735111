import {
    getLoopUnwindTransaction,
    getLoopVaultSetupTransaction,
    getLoopWindTransaction,
    getMarginFiAccountSetupTransaction,
    LoopUnwindTransactionInput,
    LoopWindTransactionInput
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises, TransactionAction, TransactionActionType } from "@bridgesplit/react";
import { filterTruthy, Result, TransactionStatus, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfTransactionDetails, AbfGeneratorResult, LoopExpanded, TransactionSenderOptions } from "../types";
import { useAbfGenerateTransaction, useAbfGenerateTransactionWithData } from "./common";

export type LoopWindParams = LoopWindTransactionInput & {
    loopExpanded: LoopExpanded;
    userPublicKey: string;
};

export function useLoopWindTransaction(): AbfTransactionDetails<LoopWindParams> {
    const generate = useAbfGenerateTransactionWithData();
    const { resetNapoleonApi, resetNapoleonPublicApi, resetLoopApi, resetEscrowedApi, resetLoanApi } = useAbfFetches();

    async function getTransactionsWithParams({
        loopExpanded,
        userPublicKey,
        ...params
    }: LoopWindParams): AbfGeneratorResult {
        try {
            const collateralDecimals = loopExpanded.collateralToken.decimals;
            const principalDecimals = loopExpanded.principalToken.decimals;
            const create = generate({
                generateFunction: getLoopWindTransaction,
                identifier: "Create position",
                getActions: (response): TransactionAction[] => [
                    {
                        action: {
                            [TransactionActionType.WriteLoopWind]: {
                                mint: params.collateralInfo.assetKey,
                                lockboxAddress: response.lockboxAddress,
                                owner: userPublicKey
                            }
                        },
                        transactionStatus: TransactionStatus.Confirmed
                    }
                ],
                params: {
                    ...params,
                    principalRequested: uiAmountToLamports(params.principalRequested, principalDecimals),
                    collateralInfo: {
                        ...params.collateralInfo,
                        amountFromWallet: uiAmountToLamports(
                            params.collateralInfo.amountFromWallet,
                            collateralDecimals
                        ),
                        amountFromEscrow: uiAmountToLamports(params.collateralInfo.amountFromEscrow, collateralDecimals)
                    }
                }
            });

            const transactions = await combineTransactionPromises([create], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetLoopApi();
            resetEscrowedApi();
            resetLoanApi();
        },
        minGeyserConfirmations: 11
    };

    return { getTransactionsWithParams, sendOptions, description: "Initializing position" };
}

export type LoopUnwindParams = LoopUnwindTransactionInput & {
    mintToReceive: string;
    lockboxMint: string;
    userPublicKey: string;
};

export function useLoopUnwindTransaction(): AbfTransactionDetails<LoopUnwindParams> {
    const generate = useAbfGenerateTransaction();
    const { resetNapoleonApi, resetLoopApi, resetEscrowedApi, resetLoanApi, resetNapoleonPublicApi } = useAbfFetches();

    async function getTransactionsWithParams({
        mintToReceive,
        lockboxMint,
        userPublicKey,
        ...params
    }: LoopUnwindParams): AbfGeneratorResult {
        try {
            const close = generate({
                generateFunction: getLoopUnwindTransaction,
                identifier: "Close",
                params,
                getActions: (): TransactionAction[] => [
                    {
                        action: {
                            [TransactionActionType.WriteLoopUnwind]: {
                                mint: mintToReceive,
                                lockboxAddress: lockboxMint,
                                owner: userPublicKey
                            }
                        },
                        transactionStatus: TransactionStatus.Confirmed
                    }
                ]
            });

            const transactions = await combineTransactionPromises([close], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetNapoleonApi();
            resetNapoleonPublicApi();
            resetLoopApi();
            resetEscrowedApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing position" };
}

type SetupLoopParams = {
    loopVaultAddress: string;
    setupMarginFiAccount: boolean;
    setupLoopVault: boolean;
};

export function useSetupLoopTransaction(): AbfTransactionDetails<SetupLoopParams> {
    const generate = useAbfGenerateTransaction();
    const { resetMarginFiApi } = useAbfFetches();

    async function getTransactionsWithParams({
        loopVaultAddress,
        setupLoopVault,
        setupMarginFiAccount
    }: SetupLoopParams): AbfGeneratorResult {
        try {
            const loopVaultSetup = setupLoopVault
                ? generate({
                      generateFunction: getLoopVaultSetupTransaction,
                      params: { loopVaultAddress },
                      identifier: "Setup accounts"
                  })
                : null;
            const marginFiSetup = setupMarginFiAccount
                ? generate({
                      generateFunction: getMarginFiAccountSetupTransaction,
                      params: {},
                      identifier: "Setup MarginFi account"
                  })
                : null;
            return await combineTransactionPromises([loopVaultSetup, marginFiSetup].filter(filterTruthy), {
                order: "parallel"
            });
        } catch (error) {
            return Result.err(error);
        }
    }
    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetMarginFiApi();
        }
    };

    return {
        getTransactionsWithParams,
        sendOptions,
        description: "Setup MarginFi account"
    };
}
