import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Connection, EpochInfo } from "@solana/web3.js";

export const rpcApi = createApi({
    reducerPath: "rpcApi",
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        currentEpoch: builder.query<EpochInfo, string>({
            queryFn: async (rpcUrl) => {
                const connection = new Connection(rpcUrl);
                const epoch = await connection.getEpochInfo();
                return { data: epoch };
            }
        })
    })
});

export const { useCurrentEpochQuery } = rpcApi;
