import { AbfCreateUserGroupParams, AbfGroupType, AbfUserGroup } from "@bridgesplit/abf-react";
import { DEFAULT_AVATAR, FormInput, Image, Row } from "@bridgesplit/ui";
import { COPY } from "app/constants";

export type GroupForm = {
    name: string;
    description: string;
    imageUrl: string;
    groupType: AbfGroupType;
};

export function CreateGroupForm<T extends GroupForm>({
    form,
    setForm
}: {
    form: Partial<T>;
    setForm: (param: Partial<T>) => void;
}) {
    return (
        <>
            <FormInput
                label="Name"
                placeholder={form.groupType === AbfGroupType.Individual ? "Your public name" : "Name your Organization"}
                variant="string"
                value={form.name}
                setValue={(val) => setForm({ ...form, name: val })}
            />
            {form.groupType !== AbfGroupType.Individual && (
                <FormInput
                    label="Description"
                    placeholder="Describe the purpose of your organization"
                    variant="string"
                    multiLineHeight={150}
                    value={form.description}
                    setValue={(val) => setForm({ ...form, description: val?.slice(0, 100) })}
                />
            )}
        </>
    );
}

export function ProfileCard({ group }: { group: Partial<AbfCreateUserGroupParams> | AbfUserGroup | undefined }) {
    const createGroup = group && "group_image" in group ? (group as Partial<AbfCreateUserGroupParams>) : undefined;
    const existingGroup = group && "groupIdentifier" in group ? (group as AbfUserGroup) : undefined;

    // Set image source based on the priority: existing group image > default avatar
    const imageSource = existingGroup?.groupImage ?? createGroup?.group_image ?? DEFAULT_AVATAR;

    return (
        <Row spacing={1}>
            <Image variant="circle" size="50px" src={imageSource} />
        </Row>
    );
}

export function getGroupType(group: AbfUserGroup | undefined) {
    if (group?.groupType === AbfGroupType.Custodian) return COPY.CUSTODIAN_TERM;
    if (group?.groupType === AbfGroupType.Individual) return "Personal Account";
    return "Organization";
}
