import { AbfPermission, useGroup, validateIsCustodian } from "@bridgesplit/abf-react";
import { HeaderCardWithAction, Text, TextButton } from "@bridgesplit/ui";
import { useAppDialog, AppDialog } from "app/utils";

import Page from "../../wrappers/Page";
import ManagePermissionsTable from "./ManagePermissionsTable";
import { useCustodianMembers } from "../util";
import CustodianGenerateCodes from "./CustodianGenerateCodes";

export default function CustodianPermissions() {
    const { activeGroup, permissions: userPermissions } = useGroup();

    const members = useCustodianMembers();
    const { open } = useAppDialog();
    const addOrg = () => open(AppDialog.AddGroup, undefined);

    return (
        <Page
            permission={!!validateIsCustodian(activeGroup) && !!userPermissions?.validate(AbfPermission.ModifyMembers)}
        >
            <HeaderCardWithAction
                actionElement={<TextButton onClick={addOrg}>Add Organization</TextButton>}
                header="Permissioned organizations"
                spacing={3}
            >
                <Text color="caption">
                    Control permissions for organizations interacting with {activeGroup?.groupName} assets
                </Text>

                <ManagePermissionsTable
                    emptyPlaceholder={{
                        header: "No Permissions",
                        description: "No organizations have permission to interact with your assets",
                        cta: { text: "Add Organization", onClick: addOrg }
                    }}
                    hideColumns={["wallet", "status"]}
                    searchPlaceholder="Search by organizations or invited emails"
                    members={members}
                />
            </HeaderCardWithAction>
            <CustodianGenerateCodes />
        </Page>
    );
}
