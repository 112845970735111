import { useState } from "react";

import { Button, DialogWrapper, ErrorWrapper, FormInput, ToggleButtons } from "@bridgesplit/ui";
import { CreateBankAccountInput, SphereBankAccountType } from "@bridgesplit/abf-sdk";
import { useCreateSphereBankAccountMutation } from "@bridgesplit/abf-react";
import { LOADING_ERROR, Result, getReadableErrorMessage, generateNonce } from "@bridgesplit/utils";
import { useAsyncResultHandler, IdempotentRequest } from "@bridgesplit/react";
import { AppDialog, useAppDialog } from "app/utils";

import { DialogHeader } from "../../common";
import { useSphereRefresh } from "../util";

type Form = {
    accountName: string;
    accountNumber: number;
    routingNumber: number;
    accountType: SphereBankAccountType;
    bankName: string;
};
export default function ManualAddBankDialog() {
    const [form, setForm] = useState<Partial<Form>>({});

    const { close } = useAppDialog();
    const [createBank] = useCreateSphereBankAccountMutation();
    const { resultHandler, isLoading, errorMessage } = useAsyncResultHandler();
    const refresh = useSphereRefresh();

    const allFieldsDefined =
        !!form.accountName &&
        !!form.accountNumber &&
        form.accountType !== undefined &&
        !!form.bankName &&
        !!form.routingNumber;

    async function submit() {
        if (!allFieldsDefined) return Result.errFromMessage(LOADING_ERROR);
        const { accountNumber, routingNumber, ...input } = form as Form;
        const body: IdempotentRequest<CreateBankAccountInput> = {
            idempotentKey: generateNonce(),
            nickname: input.bankName,
            bankAccountInfo: {
                ...input,
                accountNumber: accountNumber.toString(),
                routingNumber: routingNumber.toString()
            }
        };

        const res = await createBank(body);
        if ("error" in res) {
            return Result.errWithDebug(getReadableErrorMessage("add bank"), res);
        }

        await refresh({ banks: true });
        close();
        return Result.ok();
    }

    return (
        <DialogWrapper>
            <DialogHeader
                header={AppDialog.ManualAddBank}
                description="Connect a bank by verifying your routing information"
            />

            <FormInput
                placeholder="Bank of America, Chase, Wells Fargo, etc."
                label="Bank name"
                value={form.bankName}
                setValue={(val) => setForm((prev) => ({ ...prev, bankName: val }))}
                variant="string"
            />
            <FormInput
                placeholder="Investor Checking, Personal Savings etc."
                label="Account name"
                value={form.accountName}
                setValue={(val) => setForm((prev) => ({ ...prev, accountName: val }))}
                variant="string"
            />
            <FormInput
                placeholder={Array(9).fill("X").join("")}
                label="Routing number"
                tooltip="Locate this number on the bottom left corner of a check or within the account details provided by your bank"
                value={form.routingNumber}
                setValue={(routingNumber) => setForm((prev) => ({ ...prev, routingNumber }))}
                variant="number"
                thousandSeparator={false}
            />
            <FormInput
                placeholder={Array(12).fill("X").join("")}
                label="Account number"
                tooltip="Usually be found within your account details"
                value={form.accountNumber}
                setValue={(accountNumber) => setForm((prev) => ({ ...prev, accountNumber }))}
                variant="number"
                thousandSeparator={false}
            />
            <ToggleButtons
                value={form.accountType}
                setValue={(val) => setForm((prev) => ({ ...prev, accountType: val }))}
                options={Object.values(SphereBankAccountType).map((value) => ({ value }))}
            />
            <ErrorWrapper errorMessage={errorMessage}>
                <Button
                    loading={isLoading}
                    onClick={() => resultHandler(submit, { onSuccessMessage: "Bank details were submitted" })}
                    disabled={!allFieldsDefined}
                    variant="contained"
                >
                    Confirm Bank Details
                </Button>
            </ErrorWrapper>
        </DialogWrapper>
    );
}
