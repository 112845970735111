import {
    BsMetaUtil,
    AbfPermissionForCustodian,
    useSyncLedgersTransaction,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { Text } from "@bridgesplit/ui";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";

import { AppButton } from "../../../common";
import { MakeRepaymentProps } from "./types";
import { useTransactionSender } from "../../../transactions";

export default function SyncLedgers({ loanExpanded }: MakeRepaymentProps) {
    const symbol = BsMetaUtil.getSymbol(loanExpanded?.principalMetadata);
    const syncLedgers = useSyncLedgersTransaction();
    const send = useTransactionSender();

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        return await send(syncLedgers, { orderAddress: loanExpanded?.order.address });
    }

    return (
        <>
            <Text color="error">
                Your recent payment was received, but not confirmed. Please try again. You will not be charged any{" "}
                {symbol}
            </Text>
            <AppButton
                isTransaction
                authentication={{
                    permission: AbfPermissionForCustodian.RepayLoan,
                    custodians: getLoanCustodianIdentifiers(loanExpanded)
                }}
                asyncCta={{ onClickWithResult: submit }}
                variant="contained"
                fullWidth
            >
                Confirm payment
            </AppButton>
        </>
    );
}
