import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
    TIME,
    bsMath,
    filterNullableRecord,
    filterTruthy,
    getUnixTs,
    groupArrayElementsBy,
    removeDuplicatesWithFunction
} from "@bridgesplit/utils";
import { Transaction } from "@bridgesplit/bs-protos";
import { ChainId } from "@bridgesplit/abf-sdk";

import {
    useBridgeTransactionsQuery,
    useBsMetadataByMints,
    useEstimateEvmGasQuery,
    useEvmBalanceQuery,
    useEvmMetadataQuery,
    useEvmTransfersQuery
} from "../reducers";
import { useUserMultichainWallets, useActiveWallet } from "./wallet";
import {
    BridgeTransaction,
    BridgeTransactionType,
    EvmNftMetadataParam,
    EvmTransactionType,
    TrackedTransaction,
    TrackedTransactionsFilter,
    BridgeStatus,
    BridgeTransactionExpanded
} from "../types";
import { BsMetaUtil } from "../utils";
import { WORMHOLE_NFT_CONTRACTS } from "../constants";
import { useActiveGroup } from "./group";

export function useUserBridgesParams() {
    const { groupIdentifier } = useActiveGroup();

    const { data: multichainWallets, isLoading: walletsLoading } = useUserMultichainWallets();

    const params: TrackedTransactionsFilter | undefined = groupIdentifier
        ? { groupIdentifiers: [groupIdentifier] }
        : undefined;

    const skip = !multichainWallets?.length || !groupIdentifier;

    return { params, skip, isLoading: walletsLoading };
}

export function useUserBridgeTransactions() {
    const { params, skip, isLoading: walletsLoading } = useUserBridgesParams();

    const { data: allTransfers, isLoading: transfersLoading } = useEvmTransfersQuery(params ?? skipToken, { skip });

    const { data: transactions, isLoading: queryLoading } = useBridgeTransactionsQuery(params ?? skipToken, { skip });

    const evmAssets: EvmNftMetadataParam[] | undefined = transactions
        ?.map(({ bridgeTransactionInfo }) => ({
            sourceChain: getEvmChainFromBridge(bridgeTransactionInfo),
            // or with undefined to allow filter from nullable record
            contract: bridgeTransactionInfo.contract || undefined,
            tokenId: bridgeTransactionInfo.tokenId || undefined
        }))
        .filter(filterNullableRecord);
    const uniqueEvmAssets = removeDuplicatesWithFunction(
        evmAssets ?? [],
        (a) => a.contract + a.sourceChain + a.tokenId
    );
    const solanaMints = (transactions?.map((d) => d.bridgeTransactionInfo.assetMint) ?? []).filter(filterTruthy);

    const { data: allEvmMetadata, isLoading: evmMetadataLoading } = useEvmMetadataQuery(uniqueEvmAssets ?? skipToken, {
        skip: !uniqueEvmAssets.length
    });

    const { getMetadata, isLoading: metadataLoading } = useBsMetadataByMints(solanaMints);

    const isLoading =
        queryLoading ||
        (evmMetadataLoading && uniqueEvmAssets.length) ||
        (metadataLoading && solanaMints.length) ||
        transfersLoading ||
        walletsLoading;

    const data = useMemo(() => {
        if (skip) return [];
        if (isLoading || !transactions) return undefined;

        const groupedBridges = groupArrayElementsBy(
            transactions.filter((txn) => txn.bridgeTransactionInfo.transactionType === BridgeTransactionType.Bridge),
            (txn) => getUniqueAssetKeyForBridgeTransaction(txn.bridgeTransactionInfo)
        );

        const groupedClaims = groupArrayElementsBy(
            transactions.filter((txn) => txn.bridgeTransactionInfo.transactionType === BridgeTransactionType.Claim),
            (txn) => getUniqueAssetKeyForBridgeTransaction(txn.bridgeTransactionInfo)
        );

        const bridges: BridgeTransactionExpanded[] = [];

        for (const [uniqueAssetsKey, bridgeTransactions] of groupedBridges) {
            // sort transactions by old -> new
            const sortedTransactions = bridgeTransactions.sort(
                (a, b) => a.transactionInfo.lastUpdateTime - b.transactionInfo.lastUpdateTime
            );

            sortedTransactions.forEach((txn, i) => {
                const evmMetadata =
                    allEvmMetadata?.find(
                        (e) =>
                            evmAddressEqual(e.contract, txn.bridgeTransactionInfo.contract) &&
                            evmAddressEqual(e.tokenId, txn.bridgeTransactionInfo.tokenId)
                    ) ?? null;
                const bsMetadata = getMetadata(txn.bridgeTransactionInfo.assetMint) ?? null;

                // should only include the claim transactions that occur between this transaction and next bridge for the asset
                const currentBridgeTime = txn.transactionInfo.lastUpdateTime;
                const nextBridgeTime =
                    sortedTransactions[i + 1]?.transactionInfo.lastUpdateTime ?? Number.MAX_SAFE_INTEGER;

                const claimTransactions = (groupedClaims.get(uniqueAssetsKey) ?? []).filter(
                    ({ transactionInfo: { lastUpdateTime } }) =>
                        lastUpdateTime > currentBridgeTime && lastUpdateTime < nextBridgeTime
                );

                // only relevant for bridges from sol -> evm
                const transfers = (allTransfers ?? []).filter(
                    ({
                        transactionInfo: { lastUpdateTime },
                        evmTransferTransactionInfo: { amount, contract, tokenId }
                    }) => {
                        const sameAmount = amount === txn.bridgeTransactionInfo.assetAmount;
                        const transferIsAfterBridge = lastUpdateTime > txn.transactionInfo.lastUpdateTime;
                        const chainInfo = BsMetaUtil.getChain(bsMetadata);
                        return (
                            sameAmount &&
                            transferIsAfterBridge &&
                            evmAddressEqual(contract, txn.bridgeTransactionInfo.contract || chainInfo?.contract) &&
                            evmAddressEqual(tokenId, txn.bridgeTransactionInfo.tokenId || chainInfo?.token_id)
                        );
                    }
                );

                const isLatest = i === sortedTransactions.length - 1;

                const bridge = {
                    ...txn,
                    claimTransactions,
                    evmMetadata,
                    bsMetadata,
                    transfers,
                    isLatest,
                    status: BridgeStatus.Pending
                };

                const status = getBridgeStatus(bridge);
                // only show one failure at a time
                if (status === BridgeStatus.Confirmed || isLatest) {
                    bridges.push({ ...bridge, status });
                }
            });
        }

        return bridges;
    }, [allEvmMetadata, allTransfers, getMetadata, isLoading, skip, transactions]);

    return { data, isLoading };
}

export function getEvmChainFromBridge({ fromChainId, toChainId }: BridgeTransaction) {
    return fromChainId === ChainId.Solana ? toChainId : fromChainId;
}

export function evmAddressEqual(address1: string | undefined, address2: string | undefined) {
    if (!address1 || !address2) return undefined;
    return address1.toLowerCase() === address2.toLowerCase();
}

export function getUniqueAssetKeyForBridgeTransaction(transaction: BridgeTransaction) {
    return (
        transaction.assetAmount +
        (transaction.assetMint ?? "") +
        (transaction.tokenId ?? "") +
        (transaction.contract ?? "")
    ).toLowerCase();
}

export function useUserChainWallet(chainId: ChainId) {
    const { data: wallets } = useUserMultichainWallets();
    const { activeMpcWallet } = useActiveWallet();

    const wallet = wallets?.find((w) => w.chainId === chainId)?.walletPubkey;
    return { wallet, solanaMpc: activeMpcWallet?.wallet };
}

export function useUserEvmBalance(chainId: ChainId, options?: { disablePoll?: boolean }) {
    const { wallet } = useUserChainWallet(chainId);
    const { data } = useEvmBalanceQuery(wallet ? { address: wallet, sourceChain: chainId } : skipToken, {
        skip: !wallet,
        pollingInterval: options?.disablePoll ? 0 : 5_000
    });

    return { data };
}

const TRANSACTION_EXPIRY = 10 * TIME.MINUTE; // evm nonce expires after 10 mins
export function isTransactionPending(trackedTransaction: TrackedTransaction) {
    const isPendingStatus = trackedTransaction.transactionStatus === Transaction.TransactionStatus.PENDING;

    const secondsSinceLastUpdate = Math.max(getUnixTs() - trackedTransaction.lastUpdateTime, 0);

    return isPendingStatus && secondsSinceLastUpdate < TRANSACTION_EXPIRY;
}

export function useEstimateGas(chain: ChainId) {
    const { data: gasEstimation } = useEstimateEvmGasQuery(chain);
    return (transactions: EvmTransactionType[]) => {
        if (!gasEstimation) return undefined;

        const fee = gasEstimation.maxFeePerGas.uiAmount || 0;
        const gasLimits = transactions.map((txn) => gasEstimation.evmTransactionToGas[txn].uiAmount);
        const gasForTransactions = gasLimits.map((limit) => limit * fee);

        const total = bsMath.add(...gasForTransactions);

        return { total, gasLimits };
    };
}

// there is a 1-3 min delay from when bridge is confirmed to when claim is initialized
const WORMHOLE_TIMEOUT = TIME.MINUTE * 5;

function getBridgeStatus(bridge: BridgeTransactionExpanded): BridgeStatus {
    const { transactionInfo, transfers, claimTransactions } = bridge;

    if (transactionInfo.transactionStatus === Transaction.TransactionStatus.PENDING) return BridgeStatus.Pending;
    if (transactionInfo.transactionStatus !== Transaction.TransactionStatus.CONFIRMED) return BridgeStatus.BridgeError;

    const confirmedClaim = claimTransactions.find(
        (c) => c.transactionInfo.transactionStatus === Transaction.TransactionStatus.CONFIRMED
    );
    if (!confirmedClaim) {
        const pendingClaim = claimTransactions.find((c) => isTransactionPending(c.transactionInfo));
        if (pendingClaim) return BridgeStatus.Pending;

        const timeSinceBridgeConfirm = Math.max(getUnixTs() - transactionInfo.lastUpdateTime, 0);

        if (timeSinceBridgeConfirm < WORMHOLE_TIMEOUT) return BridgeStatus.Pending;

        return BridgeStatus.ClaimError;
    }

    // Transfer to MPC only needed for sol -> evm
    if (bridge.bridgeTransactionInfo.fromChainId === ChainId.Solana) {
        const confirmedTransfer = transfers.find(
            (t) => t.transactionInfo.transactionStatus === Transaction.TransactionStatus.CONFIRMED
        );
        if (confirmedTransfer) return BridgeStatus.Confirmed;

        const pendingTransfer = transfers.find((c) => isTransactionPending(c.transactionInfo));
        if (pendingTransfer) return BridgeStatus.Pending;

        const timeSinceClaimConfirm = Math.max(getUnixTs() - confirmedClaim.transactionInfo.lastUpdateTime, 0);

        if (timeSinceClaimConfirm < WORMHOLE_TIMEOUT) return BridgeStatus.Pending;

        if (!confirmedTransfer) {
            return BridgeStatus.TransferError;
        }
    }

    return BridgeStatus.Confirmed;
}

export function getWormholeContract(chain: ChainId) {
    if (!(chain in WORMHOLE_NFT_CONTRACTS)) return { nftContract: undefined };
    const nftContract = WORMHOLE_NFT_CONTRACTS[chain as 0 | 1 | 3];

    return { nftContract };
}
