import {
    LockboxInitArgs,
    getInitLockboxTransaction,
    LockboxAssetUpdateArgs,
    getWithdrawLockboxTransaction,
    StakedSolLockboxArgs,
    getWithdrawLockboxStakedSolTransaction,
    getUnlockLockboxTransaction,
    LockboxUnlockArgs,
    getDepositLockboxStakedSolTransaction,
    getDepositLockboxTransaction,
    getCloseLockboxesTransaction,
    LockboxCloseArgs,
    getTopUpLockboxStakedSolTransaction,
    getTopUpLockboxTransaction
} from "@bridgesplit/abf-sdk";
import { ParallelTransactionsBatch, combineTransactionPromises } from "@bridgesplit/react";
import { Result, SOL_DECIMALS, emptyPromise, filterTruthy, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails } from "../types";
import { useDecimalsByMint } from "../utils";
import { useAbfGenerateTransaction } from "./common";
import {
    DepositParams,
    EscrowManageParams,
    useGetEscrowDepositTransactions,
    useManageEscrowTransactions
} from "./escrow";
import { TRANSACTION_DEFAULT_BATCH_COMMITMENT } from "../constants";

export function useInitLockboxTransaction(): AbfTransactionDetails<LockboxInitArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanRequests } = useAbfFetches();

    async function getTransactionsWithParams(arg: LockboxInitArgs): AbfGeneratorResult {
        try {
            const fill = generate({
                generateFunction: getInitLockboxTransaction,
                identifier: "Create Funding Request",
                params: arg
            });

            const transactions = await combineTransactionPromises([fill], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanRequests();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Creating Funding Request" };
}

export type LockboxDepositParams = {
    lockbox: LockboxAssetUpdateArgs[];
    stakedSol: StakedSolLockboxArgs;
};

export function useLockboxDepositTransaction(): AbfTransactionDetails<LockboxDepositParams> {
    const generate = useAbfGenerateTransaction();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();
    const { resetLockboxApi, resetLoanApi, resetEscrowedApi, resetStakeApi, resetLoopApi } = useAbfFetches();

    async function getTransactionsWithParams({ lockbox, stakedSol }: LockboxDepositParams): AbfGeneratorResult {
        try {
            const getDecimals = await setupFetchMultipleDecimals(lockbox.map((l) => l.assetMint));

            const lockboxDeposit = lockbox.length
                ? generate({
                      generateFunction: getDepositLockboxTransaction,
                      identifier: "Deposit Asset",
                      params: lockbox.map((arg) => ({
                          ...arg,
                          amount: uiAmountToLamports(arg.amount, getDecimals(arg.assetMint))
                      }))
                  })
                : emptyPromise;

            // will get ignored if user doesn't withdraw staked sol
            const stakeDeposit = stakedSol?.stakeAccounts.length
                ? generate({
                      generateFunction: getDepositLockboxStakedSolTransaction,
                      identifier: "Deposit Asset",
                      params: {
                          ...stakedSol,
                          stakeAccounts: stakedSol.stakeAccounts.map(({ amountToTransfer, address }) => ({
                              address,
                              amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
                          }))
                      }
                  })
                : emptyPromise;

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([lockboxDeposit, stakeDeposit]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [depositTxn, stakeTxn] = generatedTxns;

            // then we can withdraw from locbkox or unstake
            if (depositTxn || stakeTxn) {
                transactions.push({
                    transactions: [...(depositTxn?.unwrap() ?? []), ...(stakeTxn?.unwrap() ?? [])]
                });
            }

            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLockboxApi();
            resetLoanApi();
            resetEscrowedApi();
            resetStakeApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Deposit Assets" };
}

export type LockboxStakeWithdrawParams = {
    unlock: LockboxUnlockArgs | null;
    lockbox: LockboxAssetUpdateArgs[];
    stakedSol: StakedSolLockboxArgs | null;
};
export function useLockboxWithdrawTransaction(): AbfTransactionDetails<LockboxStakeWithdrawParams> {
    const generate = useAbfGenerateTransaction();
    const { resetLockboxApi, resetLoanApi, resetEscrowedApi, resetStakeApi } = useAbfFetches();

    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams({
        unlock,
        lockbox,
        stakedSol
    }: LockboxStakeWithdrawParams): AbfGeneratorResult {
        try {
            const lockboxUnlock = unlock
                ? generate({
                      generateFunction: getUnlockLockboxTransaction,
                      identifier: "Unlock Assets",
                      params: unlock
                  })
                : emptyPromise;

            const getDecimals = await setupFetchMultipleDecimals(lockbox.map((l) => l.assetMint));

            const lockboxWithdraw = lockbox.length
                ? generate({
                      generateFunction: getWithdrawLockboxTransaction,
                      identifier: "Withdraw Asset",
                      params: lockbox.map((arg) => ({
                          ...arg,
                          escrowAccount: arg.escrowAccount || undefined,
                          amount: uiAmountToLamports(arg.amount, getDecimals(arg.assetMint))
                      }))
                  })
                : emptyPromise;

            // will get ignored if user doesn't withdraw staked sol
            const stakeWithdraw = stakedSol?.stakeAccounts.length
                ? generate({
                      generateFunction: getWithdrawLockboxStakedSolTransaction,
                      identifier: "Withdraw Asset",
                      params: {
                          ...stakedSol,
                          stakeAccounts: stakedSol.stakeAccounts.map(({ amountToTransfer, address }) => ({
                              address,
                              amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
                          }))
                      }
                  })
                : emptyPromise;

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([lockboxUnlock, lockboxWithdraw, stakeWithdraw]);
            const fail = Result.combine(generatedTxns.filter(filterTruthy));
            if (!fail.isOk()) return Result.err(fail);

            const [unlockTxn, withdrawTxn, unstakeTxn] = generatedTxns;

            // unlock txn must occur first
            if (unlockTxn) {
                transactions.push({
                    transactions: unlockTxn.unwrap(),
                    commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT
                });
            }

            // then we can withdraw from lockbox or unstake
            if (withdrawTxn || unstakeTxn) {
                transactions.push({
                    transactions: [...(withdrawTxn?.unwrap() ?? []), ...(unstakeTxn?.unwrap() ?? [])]
                });
            }

            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLockboxApi();
            resetLoanApi();
            resetEscrowedApi();
            resetStakeApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing Collateral" };
}

type CreateLoanRequestTransactionParams = { lockbox: LockboxInitArgs; deposits: DepositParams };
export function useCreateLoanRequestTransaction(): AbfTransactionDetails<CreateLoanRequestTransactionParams> {
    const generate = useAbfGenerateTransaction();

    const { resetEscrowedApi, resetStakeApi, resetOrderApi, resetLoanRequests, resetLendingStrategyApi } =
        useAbfFetches();

    const getEscrowTxns = useGetEscrowDepositTransactions();
    async function getTransactionsWithParams({
        lockbox,
        deposits
    }: CreateLoanRequestTransactionParams): AbfGeneratorResult {
        try {
            const init = generate({
                generateFunction: getInitLockboxTransaction,
                identifier: "Create Funding Request",
                params: lockbox
            });

            const { stakedDeposit, depositEscrow } = await getEscrowTxns(deposits);
            const transactionsToGenerate = [init, depositEscrow, stakedDeposit].filter(filterTruthy);
            const transactions = await combineTransactionPromises(transactionsToGenerate, { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanRequests();
            resetOrderApi();
            resetEscrowedApi();
            resetLendingStrategyApi();
            resetStakeApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Creating Funding Request" };
}

type CloseLockboxesParams = { lockboxes: LockboxCloseArgs[]; escrowWithdraw?: EscrowManageParams[] };
export function useCloseLockboxesTransaction(): AbfTransactionDetails<CloseLockboxesParams> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanRequests, resetLockboxApi } = useAbfFetches();
    const { escrowWithdraw } = useManageEscrowTransactions();

    async function getTransactionsWithParams({
        lockboxes,
        escrowWithdraw: assetsToWithdraw = []
    }: CloseLockboxesParams): AbfGeneratorResult {
        try {
            const close = generate({
                generateFunction: getCloseLockboxesTransaction,
                identifier: "Close lockbox",
                params: { lockboxes }
            });

            const escrowTxns = escrowWithdraw(assetsToWithdraw);

            const transactions = await combineTransactionPromises([close, escrowTxns], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanRequests();
            resetLockboxApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Closing accounts" };
}

export function useLockboxTopUpTransaction(): AbfTransactionDetails<LockboxDepositParams> {
    const generate = useAbfGenerateTransaction();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();
    const { resetLockboxApi, resetLoanApi, resetEscrowedApi, resetStakeApi, resetLoopApi } = useAbfFetches();

    async function getTransactionsWithParams({ lockbox, stakedSol }: LockboxDepositParams): AbfGeneratorResult {
        try {
            const getDecimals = await setupFetchMultipleDecimals(lockbox.map((l) => l.assetMint));

            const lockboxDeposit = lockbox.length
                ? generate({
                      generateFunction: getTopUpLockboxTransaction,
                      identifier: "Deposit Asset",
                      params: lockbox.map((arg) => ({
                          ...arg,
                          amount: uiAmountToLamports(arg.amount, getDecimals(arg.assetMint))
                      }))
                  })
                : emptyPromise;

            // will get ignored if user doesn't withdraw staked sol
            const stakeDeposit = stakedSol?.stakeAccounts.length
                ? generate({
                      generateFunction: getTopUpLockboxStakedSolTransaction,
                      identifier: "Deposit Asset",
                      params: {
                          ...stakedSol,
                          stakeAccounts: stakedSol.stakeAccounts.map(({ amountToTransfer, address }) => ({
                              address,
                              amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
                          }))
                      }
                  })
                : emptyPromise;

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([lockboxDeposit, stakeDeposit]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [depositTxn, stakeTxn] = generatedTxns;

            // then we can withdraw from locbkox or unstake
            if (depositTxn || stakeTxn) {
                transactions.push({
                    transactions: [...(depositTxn?.unwrap() ?? []), ...(stakeTxn?.unwrap() ?? [])]
                });
            }

            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLockboxApi();
            resetLoanApi();
            resetEscrowedApi();
            resetStakeApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Deposit Assets" };
}

export type LockboxCollateralWithdraw = {
    lockbox: LockboxAssetUpdateArgs[];
    stakedSol: StakedSolLockboxArgs;
};

export function useLockboxWithdrawCollateralTransaction(): AbfTransactionDetails<LockboxCollateralWithdraw> {
    const generate = useAbfGenerateTransaction();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();
    const { resetLockboxApi, resetLoanApi, resetEscrowedApi, resetStakeApi, resetLoopApi } = useAbfFetches();

    async function getTransactionsWithParams({ lockbox, stakedSol }: LockboxCollateralWithdraw): AbfGeneratorResult {
        try {
            const getDecimals = await setupFetchMultipleDecimals(lockbox.map((l) => l.assetMint));

            const lockboxDeposit = lockbox.length
                ? generate({
                      generateFunction: getTopUpLockboxTransaction,
                      identifier: "Withdraw Collateral",
                      params: lockbox.map((arg) => ({
                          ...arg,
                          amount: uiAmountToLamports(arg.amount, getDecimals(arg.assetMint))
                      }))
                  })
                : emptyPromise;

            // will get ignored if user doesn't withdraw staked sol
            const stakeDeposit = stakedSol?.stakeAccounts.length
                ? generate({
                      generateFunction: getTopUpLockboxStakedSolTransaction,
                      identifier: "Withdraw Collateral",
                      params: {
                          ...stakedSol,
                          stakeAccounts: stakedSol.stakeAccounts.map(({ amountToTransfer, address }) => ({
                              address,
                              amountToTransfer: uiAmountToLamports(amountToTransfer, SOL_DECIMALS)
                          }))
                      }
                  })
                : emptyPromise;

            const transactions: ParallelTransactionsBatch[] = [];

            const generatedTxns = await Promise.all([lockboxDeposit, stakeDeposit]);
            const fail = generatedTxns.find((t) => !t?.isOk());
            if (fail) {
                return Result.err(fail);
            }

            const [depositTxn, stakeTxn] = generatedTxns;

            // then we can withdraw from locbkox or unstake
            if (depositTxn || stakeTxn) {
                transactions.push({
                    transactions: [...(depositTxn?.unwrap() ?? []), ...(stakeTxn?.unwrap() ?? [])]
                });
            }

            return Result.ok(transactions);
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLockboxApi();
            resetLoanApi();
            resetEscrowedApi();
            resetStakeApi();
            resetLoopApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdraw Collateral" };
}
