import { DIALOG_PADDING } from "@bridgesplit/ui";

import { DialogHeaderWithBack, HeaderWithClose } from "../../common";
import { useMarketDialog } from "../util";
import { useMarketContextOptional } from "../common";

export default function MarketDialogHeader({ header, padding }: { header: string; padding?: boolean }) {
    const marketContext = useMarketContextOptional();
    const { close } = useMarketDialog();

    if (marketContext?.isDialog) {
        return <DialogHeaderWithBack sx={{ px: padding ? DIALOG_PADDING : undefined }} header={header} back={close} />;
    }

    return <HeaderWithClose header={header} sx={{ px: padding ? DIALOG_PADDING : undefined }} close={close} />;
}
