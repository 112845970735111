import { QuestionToggleProps } from "@bridgesplit/ui";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { COPY } from "app/constants";

import { useBaseFaq } from "./base";
import { useLoopContext } from "../../LoopContext";

export function useLstFaq(): QuestionToggleProps[] | undefined {
    const base = useBaseFaq();
    const { loopExpanded } = useLoopContext();

    if (!loopExpanded) return undefined;

    const symbol = BsMetaUtil.getSymbol(loopExpanded.collateralToken);
    const principalSymbol = BsMetaUtil.getSymbol(loopExpanded.principalToken);

    const collateral: QuestionToggleProps = {
        label: `What is ${symbol} and how does it earn yield?`,
        answer: [
            `${symbol} is a liquid staking token that automatically earns staking rewards. When you deposit ${principalSymbol}, you receive ${symbol} tokens that grow in value relative to ${principalSymbol} over time.`,
            `Through ${COPY.LOOP_TERM_PLURAL.toLowerCase()}, you can amplify these staking rewards by borrowing additional ${principalSymbol} to acquire more ${symbol}, increasing your potential yield.`
        ]
    };
    const risks: QuestionToggleProps = {
        label: "What are the risks?",
        answer: [
            `Since ${symbol} rewards only update once per epoch, liquidity pools may temporarily lag behind the actual stake pool value`,
            `When opening or closing your ${COPY.LOOP_TERM.toLowerCase()} position, price slippage may occur during the conversion between ${principalSymbol} and ${symbol} may reduce your overall yield`,
            "Smart contract risk: As with any DeFi product, there's always some level of smart contract risk."
        ]
    };

    if (!base) return undefined;
    const { intro, uniqueFeatures, management } = base;

    return [intro, collateral, risks, uniqueFeatures, management];
}
