import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { LoanRequest } from "@bridgesplit/abf-sdk";

import { CreateLoanRequestParams, LoanRequestInfo, LoanRequestQueryFilter } from "../types";
import { LOAN_REQUESTS_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const LOAN_REQUEST_TAG = "LoanRequests";
export const loanRequestApi = createApi({
    reducerPath: "loanRequestApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [LOAN_REQUEST_TAG],
    endpoints: (builder) => ({
        loanRequests: builder.query<LoanRequestInfo[], LoanRequestQueryFilter>({
            providesTags: [LOAN_REQUEST_TAG],
            query(body) {
                return {
                    url: LOAN_REQUESTS_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: (raw: Record<string, LoanRequestInfo>) => {
                return Object.values(raw);
            }
        }),
        createLoanRequest: builder.mutation<string, CreateLoanRequestParams>({
            invalidatesTags: [LOAN_REQUEST_TAG],
            query(body) {
                return {
                    url: LOAN_REQUESTS_ROUTE + "/create",
                    method: "POST",
                    body
                };
            }
        }),
        updateLoanRequest: builder.mutation<void, LoanRequest>({
            invalidatesTags: [LOAN_REQUEST_TAG],
            query(body) {
                return {
                    url: LOAN_REQUESTS_ROUTE,
                    method: "PUT",
                    body
                };
            }
        }),
        archiveLoanRequest: builder.mutation<void, { loanRequestNonce: string; adminEscrow: string }>({
            invalidatesTags: [LOAN_REQUEST_TAG],
            query({ adminEscrow, loanRequestNonce }) {
                return {
                    url: `${LOAN_REQUESTS_ROUTE}/archive/${loanRequestNonce}`,
                    method: "POST",
                    body: { escrow: adminEscrow }
                };
            }
        }),
        rejectLoanRequest: builder.mutation<void, { loanRequestNonce: string; adminEscrow: string }>({
            invalidatesTags: [LOAN_REQUEST_TAG],
            query({ loanRequestNonce, adminEscrow }) {
                return {
                    url: `${LOAN_REQUESTS_ROUTE}/reject/${loanRequestNonce}`,
                    method: "POST",
                    body: { escrow: adminEscrow }
                };
            }
        })
    })
});

export const {
    useLoanRequestsQuery,
    useCreateLoanRequestMutation,
    useUpdateLoanRequestMutation,
    useArchiveLoanRequestMutation,
    useRejectLoanRequestMutation
} = loanRequestApi;

export const useLoanRequestApi = () => {
    const api = loanRequestApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchLoanRequests] = api.loanRequests.useLazyQuery();

    return {
        resetLoanRequests: () => dispatch(loanRequestApi.util.invalidateTags([LOAN_REQUEST_TAG])),
        fetchLoanRequests
    };
};
