import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { AbfCustodian } from "@bridgesplit/abf-sdk";

import { AbfCustodianSignupCodes, AbfRoleNumber, RawCustodianResponse } from "../types";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";
import {
    ABF_GROUP_HEADER,
    CREATE_CUSTODIAN_SIGNUP_CODE_ROUTE,
    FETCH_CUSTODIAN_SIGNUP_CODE_ROUTE,
    APPLY_CUSTODIAN_SIGNUP_CODE_ROUTE,
    FETCH_ADMIN_CUSTODIAN_SIGNUP_CODE_ROUTE
} from "../constants";

const ORGANIZATION_TAG = "Custodian";
const SIGNUP_CODE_TAG = "CustodianSignupCodes";

export const abfCustodianApi = createApi({
    reducerPath: "abfCustodianApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ORGANIZATION_TAG, SIGNUP_CODE_TAG],
    endpoints: (builder) => ({
        findCustodianCodeDetails: builder.query<
            { codes: AbfCustodianSignupCodes[]; custodians: AbfCustodian[] },
            string
        >({
            providesTags: [SIGNUP_CODE_TAG],
            query(signupCode) {
                return {
                    url: FETCH_CUSTODIAN_SIGNUP_CODE_ROUTE + "/" + signupCode,
                    method: "GET"
                };
            },
            transformResponse: (raw: { codes: AbfCustodianSignupCodes[]; custodians: RawCustodianResponse[] }) => {
                return { codes: raw.codes, custodians: raw.custodians.map((c) => transformRawCustodian(c)) };
            }
        }),
        generateCustodianSignupCodes: builder.mutation<string, AbfRoleNumber[]>({
            invalidatesTags: [SIGNUP_CODE_TAG],
            query(permissions) {
                return {
                    url: CREATE_CUSTODIAN_SIGNUP_CODE_ROUTE,
                    method: "POST",
                    body: permissions,
                    responseHandler: "text"
                };
            }
        }),
        applyCustodianCode: builder.mutation<void, { code: string; groupIdentifier: string }>({
            invalidatesTags: [SIGNUP_CODE_TAG],
            query({ code, groupIdentifier }) {
                return {
                    // force org to be passed in to refresh renders
                    url: APPLY_CUSTODIAN_SIGNUP_CODE_ROUTE + "/" + code,
                    method: "POST",
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        }),
        custodianCodesForAdmin: builder.query<AbfCustodianSignupCodes[], string>({
            providesTags: [SIGNUP_CODE_TAG],
            query(groupIdentifier) {
                return {
                    // force org to be passed in to refresh renders
                    url: FETCH_ADMIN_CUSTODIAN_SIGNUP_CODE_ROUTE,
                    method: "GET",
                    headers: { [ABF_GROUP_HEADER]: groupIdentifier }
                };
            }
        })
    })
});

export const {
    useFindCustodianCodeDetailsQuery,
    useGenerateCustodianSignupCodesMutation,
    useCustodianCodesForAdminQuery,
    useApplyCustodianCodeMutation
} = abfCustodianApi;

export const useCustodianApi = () => {
    const api = abfCustodianApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchCustodianSignupCodes] = api.findCustodianCodeDetails.useLazyQuery();

    return {
        resetCustodianApi: () => dispatch(abfCustodianApi.util.invalidateTags([ORGANIZATION_TAG])),
        resetCustodianSignupCodes: () => dispatch(abfCustodianApi.util.invalidateTags([SIGNUP_CODE_TAG])),
        fetchCustodianSignupCodes
    };
};

export function transformRawCustodian(raw: RawCustodianResponse | AbfCustodian): AbfCustodian {
    if ("custodian" in raw) {
        let palette: string[] = [];
        const parsedPalette = raw.custodian.pallete ? JSON.parse(raw.custodian.pallete) : [];
        if (Array.isArray(parsedPalette)) {
            palette = parsedPalette
                .filter((color): color is string => typeof color === "string")
                .map((hex) => (hex.includes("#") ? hex : "#" + hex));
        }

        return {
            ...raw.custodian,
            palette,
            sourceChain: raw.sourceChain,
            sourceContract: raw.sourceContract
        };
    }
    // legacy custodians without chain info
    return raw;
}
