import { TypographyOptions } from "@mui/material/styles/createTypography";

import { FONT_SIZES, FONT_WEIGHTS } from "../theme";
import { MEDIA } from "../util";

export function getThemeTypography(fontFamily: string): TypographyOptions {
    return {
        fontSize: FONT_SIZES.body1,
        htmlFontSize: FONT_SIZES.body1,
        fontFamily: `${fontFamily}, Helvetica, -apple-system, system-ui, Roboto, Ubuntu`,
        h1: {
            fontSize: FONT_SIZES.h1,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.bold,
            [MEDIA.SM.below]: {
                fontSize: FONT_SIZES.h2
            }
        },
        h2: {
            fontSize: FONT_SIZES.h2,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.bold,
            [MEDIA.SM.below]: {
                fontSize: FONT_SIZES.h3
            }
        },
        h3: {
            fontSize: FONT_SIZES.h3,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.bold
        },
        h4: {
            fontSize: FONT_SIZES.h4,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.bold
        },
        body1: {
            fontSize: FONT_SIZES.body1,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.normal
        },
        body2: {
            fontSize: FONT_SIZES.body2,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.normal
        },
        caption: {
            fontSize: FONT_SIZES.caption,
            lineHeight: "normal",
            fontWeight: FONT_WEIGHTS.normal
        }
    };
}
