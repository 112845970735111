import { TIME } from "@bridgesplit/utils";

/**
 * Calc loan APY
 * @returns APY in decimal ex 0.2 -> 20%
 */
export function calculateApy({
    principalAmount,
    repaymentAmount,
    durationInSeconds,
    yearInSeconds = TIME.YEAR
}: {
    principalAmount: number;
    repaymentAmount: number;
    durationInSeconds: number;
    yearInSeconds?: number;
}) {
    const interest = repaymentAmount - principalAmount;
    const durationInYears = durationInSeconds / yearInSeconds;
    const APY = interest / principalAmount / durationInYears;

    if (!durationInYears) return 0;
    return Math.max(APY, 0);
}
