const CUSTODIAN_TERM = "Issuer";
const CUSTODIAN_TERM_PLURAL = "Issuers";
const CUSTODIAN_TERM_FULL = "Asset issuer";
const CUSTODIAN_TERM_FULL_PLURAL = "Asset issuers";

const custodian = { CUSTODIAN_TERM, CUSTODIAN_TERM_PLURAL, CUSTODIAN_TERM_FULL, CUSTODIAN_TERM_FULL_PLURAL };

const STRATEGY_TERM = "Position";
const STRATEGY_TERM_PLURAL = "Lending positions";
const MAX_DURATION_TOOLTIP = "The longest duration that borrowers can take out a loan for";
const ALLOWED_COLLATERAL_TOOLTIP = "The tokens you will accept as collateral. Loan-to-values are set by Loopscale";
const MAX_DURATION_TERM = "Max duration";
const ALLOWED_COLLATERAL_TERM = "Eligible collateral";
const YIELD_SOURCE_LABEL = "Lend idle capital";
const YIELD_SOURCE_TOOLTIP =
    "Deposit idle capital into variable-rate lending while you wait for a loan to match to your position";
const EXTERNAL_YIELD_APY_TERM = "Current APY";
const EXTERNAL_YIELD_APY_TOOLTIP = "The APY you'll earn while waiting for loans";

const STRATEGY_APY_TOOLTIP = `Your ${STRATEGY_TERM.toLowerCase()} will only be used for loans when it's the lowest APY in the market`;
const STRATEGY_APY_TOOLTIP_LITE = "The APY you'll earn on new loans";

const STRATEGY_APY_TERM = "Target APY";

const ORIGINATED_ALL_TIME_TERM = "Volume (all time)";
const strategy = {
    STRATEGY_TERM,
    STRATEGY_TERM_PLURAL,
    MAX_DURATION_TOOLTIP,
    ALLOWED_COLLATERAL_TOOLTIP,
    MAX_DURATION_TERM,
    ALLOWED_COLLATERAL_TERM,
    YIELD_SOURCE_LABEL,
    YIELD_SOURCE_TOOLTIP,
    STRATEGY_APY_TOOLTIP,
    STRATEGY_APY_TERM,
    STRATEGY_APY_TOOLTIP_LITE,
    EXTERNAL_YIELD_APY_TERM,
    EXTERNAL_YIELD_APY_TOOLTIP,
    ORIGINATED_ALL_TIME_TERM
};

const STRATEGY_TEMPLATE_TERM = "Lending strategy";
const STRATEGY_TEMPLATE_TERM_PLURAL = "Lending strategies";
const STRATEGY_TEMPLATE_TERM_LOWER = STRATEGY_TEMPLATE_TERM.toLowerCase();
const STRATEGY_TEMPLATE_TERM_PLURAL_LOWER = STRATEGY_TEMPLATE_TERM_PLURAL.toLowerCase();

const template = {
    STRATEGY_TEMPLATE_TERM,
    STRATEGY_TEMPLATE_TERM_PLURAL,
    STRATEGY_TEMPLATE_TERM_LOWER,
    STRATEGY_TEMPLATE_TERM_PLURAL_LOWER
};

const POINTS_REASON_NO_STRATEGY = `No ${STRATEGY_TERM} data available`;
const POINTS_REASON_NO_ACTIVE_LOANS = "No active loans in this strategy";
const POINTS_REASON_PRINCIPAL_LENDING = "Principal asset has lending points multiplier";
const POINTS_REASON_PRINCIPAL_IDLE = "Principal asset has idle points multiplier";
const POINTS_REASON_COLLATERAL = "One or more active loan's collateral asset has a lending points multiplier";
const POINTS_REASON_NO_POINTS = "Strategy does not earn any points";
const POINTS_REASON_EARNS_POINTS = `View active loans to see breakdown`;

const reasons = {
    POINTS_REASON_NO_STRATEGY,
    POINTS_REASON_NO_ACTIVE_LOANS,
    POINTS_REASON_PRINCIPAL_LENDING,
    POINTS_REASON_PRINCIPAL_IDLE,
    POINTS_REASON_COLLATERAL,
    POINTS_REASON_NO_POINTS,
    POINTS_REASON_EARNS_POINTS
};
const ESCROW_TERM = "Escrow";
const ESCROW_TERM_ASSETS = "Escrowed assets";
const ESCROW_TERM_FULL = "Escrow account";

const escrow = { ESCROW_TERM, ESCROW_TERM_ASSETS, ESCROW_TERM_FULL };

const HEALTH_FACTOR_TERM = "Health";
const HEALTH_FACTOR_TOOLTIP =
    "This ratio represents how well-collateralized your loan is. Your collateral can be liquidated if your health is 0 or below";
const LIQUIDATION_THRESHOLD_TOOLTIP = "The loan-to-value where your collateral is exposed to being liquidated";
const LIQUIDATION_THRESHOLD_TERM = "Liquidation LTV";

const LIQUIDATION_PRICE_TERM = "Liquidation price";
const LIQUIDATION_PRICE_TOOLTIP = "The price at which your collateral is exposed to being liquidated";

const health = {
    HEALTH_FACTOR_TERM,
    HEALTH_FACTOR_TOOLTIP,
    LIQUIDATION_THRESHOLD_TERM,
    LIQUIDATION_PRICE_TERM,
    LIQUIDATION_THRESHOLD_TOOLTIP,
    LIQUIDATION_PRICE_TOOLTIP
};

// info
const ASSET_INFO_TITLE = "Additional information";
const ASSET_ORIGINATOR_TITLE = "About loan custodian";
const ASSET_REDEMPTION_TITLE = "Recourse process";
const ASSET_ORDERS_TITLE = "Offers";
const ASSET_NOT_DEPOSITED_ALERT = "This asset must be deposited before offers can be sent";
const ASSET_PRICING_TOOLTIP = `Estimated value for an asset based on pricing data provided by the ${CUSTODIAN_TERM_FULL_PLURAL.toLocaleLowerCase()} (if available)`;
const ASSET_BRIDGE_TRANSFER_LENGTH = "Transfers typically take a few minutes";

const assetInfo = {
    ASSET_INFO_TITLE,
    ASSET_ORIGINATOR_TITLE,
    ASSET_REDEMPTION_TITLE,
    ASSET_ORDERS_TITLE,
    ASSET_NOT_DEPOSITED_ALERT,
    ASSET_PRICING_TOOLTIP,
    ASSET_BRIDGE_TRANSFER_LENGTH
};

// actions
const ASSET_ACTION_ACTIVE_LOAN = "Loan details";
const ASSET_ACTION_MAKE_REPAYMENT = "Make repayment";
const ASSET_ACTION_RECEIVING_OFFERS = "Receiving offers";
const ASSET_ACTION_DEFAULTED_LOAN = "Defaulted loan";
const ASSET_ACTION_PAID_OFF_LOAN = "Completed loan";
const ASSET_ACTION_LEDGER_OFFER = "Pending loan";

const assetActions = {
    ASSET_ACTION_ACTIVE_LOAN,
    ASSET_ACTION_MAKE_REPAYMENT,
    ASSET_ACTION_RECEIVING_OFFERS,
    ASSET_ACTION_DEFAULTED_LOAN,
    ASSET_ACTION_LEDGER_OFFER,
    ASSET_ACTION_PAID_OFF_LOAN
};

// tables
const WITHDRAW_ESCROW_CTA = "Withdraw";
const ASSET_PAGE_CTA = "View Asset";
const DEPOSIT_ESCROW_CTA = "Deposit assets";
const SEARCH_METADATA_PLACEHOLDER = "Search by asset";
const DEALS_EMPTY = "You don't have permission to view any deals";
const DEALS_EMPTY_FILTER = "No matching deals found";

const tableCtas = {
    DEALS_EMPTY,
    DEALS_EMPTY_FILTER,
    WITHDRAW_ESCROW_CTA,
    ASSET_PAGE_CTA,
    DEPOSIT_ESCROW_CTA,
    SEARCH_METADATA_PLACEHOLDER
};

const AUTH_INDIVIDUAL_GROUP_DESCRIPTION = "Personal account";
const WALLET_VERIFY = "You will need to pay a one time account fee of 0.00443 SOL to create your Loopscale Account";
const ACCOUNT_CREATE_PREVIEW = "By default, these account details will be shown on any loans you participate in";
const PASSKEY_SIGN_PROMPT = "Please authenticate with your device";
const WALLET_SIGN_PROMPT = "Please sign with your wallet";
const PASSKEY_SIGN_FAIL = "Unable to authenticate with your device";
const CONNECT_PROMPT = "Connect";
const CONNECT_DESCRIPTION = "Connect a Solana wallet or continue with email";

const auth = {
    AUTH_INDIVIDUAL_GROUP_DESCRIPTION,
    WALLET_VERIFY,
    ACCOUNT_CREATE_PREVIEW,
    PASSKEY_SIGN_PROMPT,
    WALLET_SIGN_PROMPT,
    PASSKEY_SIGN_FAIL,
    CONNECT_PROMPT,
    CONNECT_DESCRIPTION
};

const REQUEST_DEFAULT = "Any";
const REQUEST_PREFERENCE_TOOLTIP = "The borrower has established this range as a preference for incoming offers";
const GRACE_PERIOD =
    "The amount of time after the payment that the borrower can repay without paying fees or defaulting";
const MATURITY_GRACE_PERIOD = "A window of time after the due date that the borrower can repay without defaulting";
const PREPAYMENT_GRACE_PERIOD =
    "Specify the min time remaining in the loan at which a borrower can repay a loan with prorated interest";

const LATE_FEE =
    "Following a missed payment beyond the grace period, an additional percentage will be added to the original amount due";

const PREPAYMENT =
    "Borrowers can benefit from reduced interest by repaying their loan in the early repayment window, paying the prorated interest plus a discounted rate on the unused portion of the loan term";
const PREPAYMENT_FEE_DESCRIPTION =
    "The percentage fee incurred for repaying principal before the maturity date. This fee is calculated based on the difference between the original interest due and the new interest due after early repayment. For instance, with a 50% fee, the borrower is required to pay 50% of the interest amount initially saved by repaying the loan ahead of schedule";

const request = {
    REQUEST_DEFAULT,
    REQUEST_PREFERENCE_TOOLTIP,
    PREPAYMENT_GRACE_PERIOD,
    GRACE_PERIOD,
    MATURITY_GRACE_PERIOD,
    LATE_FEE,
    PREPAYMENT,
    PREPAYMENT_FEE_DESCRIPTION
};

const LOOP_TERM = "Yield loop";
const LOOP_TERM_ACTION = "Deposit";
const LOOP_TERM_PLURAL = "Yield loops";
const LEVERAGE_TERM = "Leverage";
const MAX_LEVERAGE_TERM = "Max leverage";
const LOOP_COLLATERAL_DEPOSIT_TERM = "Deposits";
const LOOP_PRINCIPAL_AVAILABLE_TERM = "Available";
const LOOP_ADDITIONAL_DEPOSITS_TERM = "Contributions";
const LOOP_ADDITIONAL_DEPOSITS_TOOLTIP = "Additional repayments and top ups after initial deposit";
const UNWIND_TERM = `Close ${LOOP_TERM.toLowerCase()}`;
const loop = {
    LOOP_TERM,
    LOOP_TERM_PLURAL,
    LEVERAGE_TERM,
    MAX_LEVERAGE_TERM,
    LOOP_COLLATERAL_DEPOSIT_TERM,
    LOOP_PRINCIPAL_AVAILABLE_TERM,
    LOOP_TERM_ACTION,
    LOOP_ADDITIONAL_DEPOSITS_TERM,
    LOOP_ADDITIONAL_DEPOSITS_TOOLTIP,
    UNWIND_TERM
};
const EARN_OPTION = "Vault";
const EARN_OPTION_PLURAL = "Vaults";
const earn = { EARN_OPTION, EARN_OPTION_PLURAL };
const TRANSACTION_FEE = "Fees";
const TRANSACTION_FEE_TOTAL = "Total Fees";
const TRANSACTION_FEE_NET = "Net Fees";
const SETUP_FEE = "Setup (one time)";
const RENT_REFUNDABLE = "Rent (refundable)";
const RENT_NON_REFUNDABLE = "Rent (non-refundable)";
const RENT_NETWORK = "Network";
const RENT_ESTIMATE_TOOLTIP = "The USD estimate is the total non-refundable fee to fill the transaction";
const transactions = {
    TRANSACTION_FEE,
    TRANSACTION_FEE_TOTAL,
    TRANSACTION_FEE_NET,
    SETUP_FEE,
    RENT_REFUNDABLE,
    RENT_NON_REFUNDABLE,
    RENT_NETWORK,
    RENT_ESTIMATE_TOOLTIP
};

const REWARDS_SUFFIX = " rewards";
const EARN_REWARDS = EARN_OPTION + REWARDS_SUFFIX;
const RATES_AND_REWARDS = "Rates and" + REWARDS_SUFFIX;
const BORROW_REWARDS = "Borrow" + REWARDS_SUFFIX;
const LEND_REWARDS = "Lend" + REWARDS_SUFFIX;

const rewards = {
    EARN_REWARDS,
    RATES_AND_REWARDS,
    BORROW_REWARDS,
    LEND_REWARDS
};
export const COPY = {
    ...custodian,
    ...assetInfo,
    ...assetActions,
    ...tableCtas,
    ...auth,
    ...request,
    ...escrow,
    ...health,
    ...strategy,
    ...template,
    ...loop,
    ...earn,
    ...transactions,
    ...rewards,
    ...reasons
};

export enum AppTab {
    Assets = "assets",
    Offers = "offers",
    Loans = "loans",
    Payments = "payments",
    Activity = "activity",
    GeneralSettings = "general"
}
