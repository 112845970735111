import { ReactNode, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { generateEmailLink, useAbfLogout, useRefreshAuth0Token, useResendAuth0Email } from "@bridgesplit/abf-react";
import {
    AbsoluteCenterWrapper,
    BackCta,
    Button,
    Column,
    EMAIL_ICONS,
    FONT_SIZES,
    IconHeader,
    Image,
    OutlinedCard,
    Span,
    Text,
    useOnInitialRender,
    usePolling
} from "@bridgesplit/ui";
import { ENV_DETAILS } from "@bridgesplit/utils";
import { useDomainLookupQuery } from "@bridgesplit/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Mail, OpenInNewOutlined } from "@mui/icons-material";

import { AppButton } from "../common";

export function UnverifiedEmailPage() {
    const { logOut } = useAbfLogout();

    return (
        <AbsoluteCenterWrapper>
            <OutlinedCard padding={2} spacing={2}>
                <UnverifiedEmail />

                <BackCta back={logOut} backCta="Log Out" />
            </OutlinedCard>
        </AbsoluteCenterWrapper>
    );
}

export function UnverifiedEmailWrapper({ children }: { children: ReactNode }) {
    const { user } = useAuth0();

    const refresh = useRefreshAuth0Token();
    const { loaded } = useOnInitialRender(refresh);

    if (user?.email_verified === false && loaded) {
        return <UnverifiedEmail />;
    }

    return <>{children}</>;
}

export function UnverifiedEmail() {
    const { user } = useAuth0();

    return (
        <>
            <IconHeader>
                <Mail />
            </IconHeader>
            <Column sx={{ textAlign: "center", alignItems: "center" }}>
                <Text variant="h3">Please verify your email </Text>
                <Text variant="body2" color="caption">
                    You must verify your email before you can send transactions. We sent a verification email to{" "}
                    {user?.email}
                </Text>
            </Column>
            <UnverifiedEmailCta />
        </>
    );
}

function UnverifiedEmailCta() {
    const refreshAuth0 = useRefreshAuth0Token();

    usePolling({ callback: refreshAuth0, interval: 2_500 });

    const resendAuth0Email = useResendAuth0Email();

    return (
        <Column spacing={1}>
            <AppButton
                isTransaction={false}
                variant="outlined"
                asyncCta={{
                    onClickWithResult: resendAuth0Email,
                    options: { alertOnError: true, onSuccessMessage: "Verification email sent" }
                }}
            >
                Resend verification
            </AppButton>
            <Cta />
        </Column>
    );
}

function Cta() {
    const { user } = useAuth0();

    const lookupDomain = user?.email?.split("@")?.[1];
    const { data: mxRecordDomain } = useDomainLookupQuery(lookupDomain ?? skipToken, { skip: !lookupDomain });

    const domain = mxRecordDomain ?? lookupDomain;
    const emailLink = generateEmailLink(
        domain,
        ENV_DETAILS.authDomain.includes("testing") ? "no-reply@auth0user.net" : "noreply@loopscale.com"
    );

    const emailProvider = useMemo(() => {
        if (emailLink?.includes("google")) return { name: "Gmail", logo: EMAIL_ICONS.gmail };
        if (emailLink?.includes("outlook")) return { name: "Outlook", logo: EMAIL_ICONS.outlook };
        return { name: "Inbox", logo: null };
    }, [emailLink]);

    if (!emailLink) return null;

    return (
        <Button variant="contained" onClick={() => window.open(emailLink)}>
            {emailProvider.logo ? (
                <Span>
                    <Image variant="fixed-height" height={FONT_SIZES.body1 + "px"} src={emailProvider.logo} />
                </Span>
            ) : (
                <Column sx={{ width: "21px" }} />
            )}
            Open {emailProvider.name} <OpenInNewOutlined />
        </Button>
    );
}
