import { useMemo } from "react";

import {
    DEFAULT_PUBLIC_KEY,
    LOADING_ERROR,
    MIN_SOL_BALANCE,
    NullableRecord,
    Result,
    SOL_DECIMALS,
    SOL_SYMBOL,
    STAKED_SOL_MINT,
    WRAPPED_SOL_MINT,
    bsMath,
    combineCollections,
    filterNullableRecord,
    formatTokenAmount,
    getReadableErrorMessage,
    isNativeWallet,
    lamportsToUiAmount,
    removeDuplicatesByProperty
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { BsMetadata, LoanRequestCollateral, SplitStakeAccount, StakeAccountArgs } from "@bridgesplit/abf-sdk";

import {
    abfEscrowedAssetPublicApi,
    useBsMetadataByMints,
    useCustodianByIdentifier,
    useEscrowedAssetHistoryQuery,
    useEscrowedAssetsQuery,
    useStakedSolByWalletQuery,
    useTokensByWalletQuery,
    useValidatorMetadataQuery
} from "../reducers";
import { isStakedSol, useAbfTypesToUiConverter } from "../utils";
import { EscrowedAssetFilter, EscrowedAssetHistoryFilter, StakeAccountExpanded, TokenBalanceExpanded } from "../types";
import { useActiveWallet } from "./wallet";
import { useActiveEscrow, useEscrowPreference } from "./escrow";
import { useExpandedBsMetadata } from "./utils";
import { MIN_STAKE_ACCOUNT_BALANCE } from "../constants";
import { useActiveGroup } from "./group";
import { useWhirlpoolPositions, whirlpoolPositionToToken } from "./whirlpool";
import { useSkipUnauthenticated } from "./auth";
import { useAccessLevel } from "./access";
import { useOraclePrices } from "./pricing";

type AssetParams = {
    skip?: boolean;
    includeStakedSol?: boolean;
    fullSolBalance?: boolean;
    includeLpPositions?: boolean;
};

// Get the available assets for user to deposit
export function useUserAvailableAssets(
    params: AssetParams & {
        escrowNeeded?: boolean; // if omitted, will use escrow preference
    }
) {
    const { escrowNeeded: escrowPreference } = useEscrowPreference();
    const { activeWallet } = useActiveWallet();
    const { groupIdentifier } = useActiveGroup();
    const { isBeta } = useAccessLevel();

    const escrowNeeded = params?.escrowNeeded ?? escrowPreference;
    const walletAssets = useWalletAvailableAssets(activeWallet?.wallet, {
        ...params,
        skip: !activeWallet || escrowNeeded || params?.skip
    });
    const escrows = useUserEscrowAssets({
        ...params,
        skip: !escrowNeeded || params?.skip
    });

    const isNotAuthenticated = useMemo(() => !activeWallet && !groupIdentifier, [activeWallet, groupIdentifier]);

    const data = useMemo(() => {
        if (isNotAuthenticated) return [];
        if (escrowNeeded) {
            return escrows;
        }
        return walletAssets;
    }, [escrowNeeded, escrows, isNotAuthenticated, walletAssets]);

    const isLoading = useMemo(() => {
        if (isNotAuthenticated || !isBeta) return false;
        if (escrowNeeded) return escrows === undefined;
        return walletAssets === undefined;
    }, [escrowNeeded, escrows, isBeta, isNotAuthenticated, walletAssets]);

    return { data, isLoading, escrowNeeded };
}

export function useEscrowedAssets(filter: EscrowedAssetFilter | void, options?: { skip: boolean }) {
    const skip = useSkipUnauthenticated();
    const { isBeta } = useAccessLevel();

    const { data: rawData, ...rest } = useEscrowedAssetsQuery(filter ?? skipToken, {
        skip: options?.skip || skip || !isBeta
    });
    const assetMints = rawData?.map((d) => d.mint);
    const { convertEscrow, tokensLoading } = useAbfTypesToUiConverter(assetMints);
    const data = rawData?.map((escrow) => convertEscrow(escrow));
    return { data: tokensLoading ? undefined : data, ...rest };
}

export function useUserEscrowAssets(options: AssetParams): TokenBalanceExpanded[] | undefined {
    const { activeEscrow } = useActiveEscrow();
    const { data } = useEscrowedAssets(
        { escrow_accounts: activeEscrow ? [activeEscrow] : [] },
        { skip: !activeEscrow || !!options?.skip }
    );
    const escrows = data ? removeDuplicatesByProperty(data, "mint").filter((e) => !isStakedSol(e.mint)) : undefined;
    const { getMetadata } = useBsMetadataByMints(escrows?.map((e) => e.mint));
    const expandedEscrows = escrows?.map((e) => ({ ...e, metadata: getMetadata(e.mint) })).filter(filterNullableRecord);

    // manually fetch stakes for escrow to display them separately
    const { data: escrowStakedSolRaw } = useStakedSolFromWalletAsTokens(activeEscrow, {
        skip: options?.skip || !options?.includeStakedSol
    });

    const possibleWpNfts = data?.filter((d) => d.amount === 1 && !getMetadata(d.mint)).map((d) => d.mint);
    const { data: wp, isLoading: wpLoading } = useWhirlpoolPositions(possibleWpNfts, {
        skip: !options?.includeLpPositions
    });

    const whirlpoolsAsTokens = wp?.map((w) => ({ ...whirlpoolPositionToToken(w), escrowed: true })) ?? [];

    const escrowsAsTokens = expandedEscrows?.map((t): TokenBalanceExpanded => ({ ...t, key: t.mint, escrowed: true }));
    const escrowStakedSol = escrowStakedSolRaw?.map((s) => ({ ...s, escrowed: true })) ?? [];

    const allEscrows =
        escrowsAsTokens && escrowStakedSol && (!wpLoading || !options?.includeLpPositions)
            ? combineCollections([escrowsAsTokens, escrowStakedSol, whirlpoolsAsTokens]).filter(
                  (e) => !isStakedSol(e.key)
              )
            : undefined;

    return allEscrows;
}

export function useEscrowHistory(filter: EscrowedAssetHistoryFilter, options?: { skip?: boolean }) {
    const { data } = useEscrowedAssetHistoryQuery(filter, options);

    const assetMints = data?.map((d) => d.mint);
    const { convertEscrowHistory, tokensLoading } = useAbfTypesToUiConverter(assetMints);

    const rows = useExpandedBsMetadata(
        data?.map((d) => convertEscrowHistory(d)),
        "mint"
    );
    if (tokensLoading) return undefined;
    return rows;
}

export function useUserEscrowHistory() {
    const { activeEscrow } = useActiveEscrow();
    return useEscrowHistory({ escrowAccountAddress: activeEscrow ?? "" }, { skip: !activeEscrow });
}

export function useWalletAvailableAssets(wallet: string | undefined, params: AssetParams) {
    const { data: stakeAccounts, isLoading: stakedSolLoading } = useStakedSolFromWalletAsTokens(wallet, {
        skip: params?.skip || !params?.includeStakedSol
    });

    const { data: rawData, isLoading: tokensLoading } = useTokensByWalletQuery(wallet ?? skipToken, {
        skip: !wallet || params?.skip
    });

    const possibleWpNfts = rawData?.tokens
        .filter((t) => t.tokenAmount.uiAmount === 1 && !(t.mint in rawData.metadataMap))
        .map((m) => m.mint);
    const { data: wp, isLoading: wpLoading } = useWhirlpoolPositions(possibleWpNfts, {
        skip: !params?.includeLpPositions
    });

    const whirlpoolsAsTokens = wp?.map(whirlpoolPositionToToken);

    if (tokensLoading || stakedSolLoading || (wpLoading && params?.includeLpPositions)) return undefined;
    return rawData?.tokens
        .map(
            ({
                mint,
                tokenAmount: { uiAmount: uiAmountRaw, amount, decimals }
            }): NullableRecord<TokenBalanceExpanded> => {
                const metadata = rawData ? rawData.metadataMap?.[mint] : undefined;

                const uiAmount = uiAmountRaw !== null ? uiAmountRaw : lamportsToUiAmount(parseInt(amount), decimals);
                const uiAmountAdjusted =
                    !params?.fullSolBalance && mint === WRAPPED_SOL_MINT
                        ? Math.max(0, bsMath.tokenSub(uiAmount, MIN_SOL_BALANCE, decimals))
                        : uiAmount;

                return {
                    key: mint,
                    metadata,
                    amount: uiAmountAdjusted,
                    mint
                };
            }
        )
        .concat(...(stakeAccounts ?? []), ...(whirlpoolsAsTokens ?? []))
        .filter(filterNullableRecord)
        .filter(({ amount }) => !!amount);
}

export function useWalletBalanceByMint(
    wallet: string | undefined,
    mint: string | undefined,
    params: { skip?: boolean; includeStakedSol?: boolean; fullSolBalance?: boolean }
) {
    const assets = useWalletAvailableAssets(wallet, params);
    const data = assets?.find((a) => a.mint === mint);

    return { data, isLoading: !assets };
}

export function useStakedSolFromWalletAsTokens(wallet: string | undefined, options?: { skip?: boolean }) {
    const { data: stakeAccounts, custodianIdentifier, ...rest } = useStakedSolByWallet(wallet, options);
    const convertStake = useConvertStakedSolTomWalletAsTokens(custodianIdentifier);

    return { ...rest, data: convertStake(stakeAccounts) };
}

function useConvertStakedSolTomWalletAsTokens(custodianIdentifier: string | undefined) {
    const custodian = useCustodianByIdentifier(custodianIdentifier);

    return (stakeAccounts: StakeAccountExpanded[] | undefined): TokenBalanceExpanded[] | undefined => {
        if (!stakeAccounts || !custodian) return undefined;
        return stakeAccounts?.map(({ metadata, validator, ...s }) => {
            return {
                metadata,
                custodian,
                amount: bsMath.tokenAdd(s.amount, s.rentExemptReserve, SOL_DECIMALS),
                decimals: SOL_DECIMALS,
                mint: STAKED_SOL_MINT,
                stakeAccount: s,
                key: s.stakeAccount
            };
        });
    };
}

export function useStakedSolByWallet(wallet: string | undefined, options?: { skip?: boolean }) {
    const { data: rawStakedSolData, ...stake } = useStakedSolByWalletQuery(
        wallet ? { wallet, force: isNativeWallet(wallet) } : skipToken,
        {
            skip: !wallet || options?.skip
        }
    );
    const allValidStakes = rawStakedSolData?.stakedSol.filter(
        (s) =>
            s.lockupPubkey === DEFAULT_PUBLIC_KEY && s.authorizedStaker === wallet && s.authorizedWithdrawer === wallet
    );

    const { data: validators, ...validator } = useValidatorMetadataQuery(
        allValidStakes?.map((s) => s.delegationVoterPubkey) ?? skipToken,
        { skip: !allValidStakes?.length }
    );

    const isFetching = stake.isFetching || validator.isFetching;
    const isLoading = stake.isLoading || validator.isLoading;

    const validatorsMap = useMemo(() => {
        if (!validators) return undefined;
        return new Map(validators?.map((v) => [v.voteIdentity, v]));
    }, [validators]);

    const stakedSol = isLoading
        ? undefined
        : allValidStakes?.map((s) => ({ ...s, validator: validatorsMap?.get(s.delegationVoterPubkey) }));

    const data =
        rawStakedSolData?.metadata &&
        stakedSol
            ?.map(({ validator, ...s }): StakeAccountExpanded => {
                // extend the existing staked sol meta
                const name = validator?.name ?? "Unknown Validator";
                const offchainMetadata: BsMetadata["offchainMetadata"] = {
                    ...rawStakedSolData?.metadata?.offchainMetadata
                };
                offchainMetadata.name = name;
                if (validator?.image) {
                    offchainMetadata.image = validator?.image;
                    offchainMetadata.display_media = validator?.image;
                }
                if (validator?.description) {
                    offchainMetadata.description = validator.description;
                }

                const metadata = {
                    ...rawStakedSolData.metadata,
                    assetMint: STAKED_SOL_MINT,
                    name,
                    offchainMetadata
                } as BsMetadata;

                return {
                    ...s,
                    validator,
                    metadata,
                    amount: s.amount / LAMPORTS_PER_SOL,
                    rentExemptReserve: s.rentExemptReserve / LAMPORTS_PER_SOL
                };
            })
            .sort((a, b) => b.amount - a.amount);

    return { data, custodianIdentifier: rawStakedSolData?.metadata.assetOriginator, isFetching, isLoading };
}

export function useUserEscrowedPrincipalByMint(mint: string | undefined, options?: { skip?: boolean }) {
    const { activeEscrow } = useActiveEscrow();

    const { data: escrowedAssets } = useEscrowedAssets(
        { asset_mint: mint, escrow_accounts: activeEscrow ? [activeEscrow] : [] },
        { skip: !activeEscrow || !!options?.skip || !mint || isStakedSol(mint) }
    );

    const { totalStakedSol } = useEscrowedStakedSol({ skip: !isStakedSol(mint) });

    if (isStakedSol(mint)) return totalStakedSol ?? 0;
    if (!escrowedAssets) return undefined;

    return escrowedAssets?.find((a) => a.mint === mint)?.amount ?? 0;
}

export function useUserEscrowsAndWalletSummarized(options?: {
    skipWallet?: boolean;
    customWallet?: string;
    includeStakedSol?: boolean;
}): TokenBalanceExpanded[] | undefined {
    const { activeWallet } = useActiveWallet();
    const wallet = options?.customWallet ?? activeWallet?.wallet;
    const walletAssets = useWalletAvailableAssets(wallet, {
        skip: options?.skipWallet || !wallet,
        includeStakedSol: options?.includeStakedSol
    });

    const escrows = useUserEscrowAssets({
        skip: options?.skipWallet || !wallet,
        includeStakedSol: options?.includeStakedSol
    });

    if (options?.skipWallet && !options.customWallet)
        return escrows?.map((e) => ({ ...e, key: e.mint, decimals: e.metadata.decimals }));

    if (!escrows || !walletAssets) return undefined;

    const amountMap = new Map<string, TokenBalanceExpanded>();

    for (const asset of walletAssets) {
        const prevAmount = amountMap.get(asset.mint)?.amount ?? 0;
        amountMap.set(asset.key, { ...asset, amount: prevAmount + asset.amount });
    }

    for (const escrow of escrows) {
        const prevAmount = amountMap.get(escrow.mint)?.amount ?? 0;
        amountMap.set(escrow.mint, {
            ...escrow,
            key: escrow.mint,
            amount: prevAmount + escrow.amount,
            escrowed: true
        });
    }

    return Array.from(amountMap.values());
}

export function useFetchStakeAccounts(
    prefetchWallet: string | undefined,
    options?: { skip?: boolean; skipPrefetch?: boolean }
) {
    const [fetchStakeByWallet] = abfEscrowedAssetPublicApi.endpoints.stakedSolByWallet.useLazyQuery();
    // prefetch
    const query = useStakedSolByWallet(prefetchWallet, {
        skip: options?.skip || options?.skipPrefetch || !prefetchWallet
    });

    async function fetchStakes(walletParams?: string): Promise<Result<SplitStakeAccount[]>> {
        if (options?.skip) return Result.ok([]);

        const wallet = walletParams ?? prefetchWallet;
        if (!wallet) return Result.errFromMessage(LOADING_ERROR);
        const stake = await fetchStakeByWallet({ wallet, force: isNativeWallet(wallet) }, true);
        if ("error" in stake)
            return Result.errWithDebug(getReadableErrorMessage("fetch staked Solana accounts"), stake.error);
        const stakes = stake.data?.stakedSol ?? [];
        const converted = stakes.map(
            (s): SplitStakeAccount => ({
                ...s,
                amount: s.amount / LAMPORTS_PER_SOL,
                rentExemptReserve: s.rentExemptReserve / LAMPORTS_PER_SOL
            })
        );
        return Result.ok(converted);
    }

    return { fetchStakes, query };
}

export function checkStakeInputErrors(
    stakeAccount: SplitStakeAccount,
    amount: number | undefined,
    newAccountStartingBalance?: number
): string | null {
    // don't display error while still entering
    if (!amount) return null;

    const fullAmount = bsMath.tokenAdd(stakeAccount.amount, stakeAccount.rentExemptReserve, SOL_DECIMALS);
    const oldAccountBalance = bsMath.tokenSub(fullAmount, amount, SOL_DECIMALS);
    const newAccountBalance = newAccountStartingBalance
        ? bsMath.tokenAdd(amount, newAccountStartingBalance, SOL_DECIMALS)
        : amount;

    const minBalance = bsMath.tokenAdd(stakeAccount.rentExemptReserve, MIN_STAKE_ACCOUNT_BALANCE, SOL_DECIMALS);

    const format = (num: number) =>
        formatTokenAmount(num, {
            symbol: SOL_SYMBOL,
            decimals: 4
        });

    if (newAccountBalance < minBalance) {
        return `Invalid amount: you must select at least ${format(minBalance)}`;
    }

    if (oldAccountBalance < minBalance) {
        if (oldAccountBalance === 0) return null; // close out the entire account
        return `Invalid amount: you must either select the entire amount of ${format(
            stakeAccount.amount + stakeAccount.rentExemptReserve
        )} or leave at least ${format(minBalance)} in your old stake account`;
    }

    return null;
}

export function splitStakeAccountsForRequest(
    stakeAccounts: SplitStakeAccount[],
    loanRequestCollateral: LoanRequestCollateral[],
    amount: number
): StakeAccountArgs[] {
    let remainingAmount = amount;

    // Convert loanRequestCollateral to a Map for easier lookup
    const requestCollateralMap = new Map<string, number>();
    loanRequestCollateral.forEach((collateral) => {
        requestCollateralMap.set(collateral.assetKey, collateral.amount);
    });

    // asc sort use smaller stake accounts fully instead of partially using big accounts
    const accountsToUse: StakeAccountArgs[] = [];
    const availableStakeAccounts: SplitStakeAccount[] = [];
    for (const account of stakeAccounts) {
        if (requestCollateralMap.has(account.stakeAccount)) {
            const requestedAmount = requestCollateralMap.get(account.stakeAccount)!; // safe non-null because of has check
            const fullStake = bsMath.tokenAdd(account.amount, account.rentExemptReserve, SOL_DECIMALS);

            const amountToTransfer = Math.min(remainingAmount, requestedAmount, fullStake);

            const hasError = checkStakeInputErrors(account, amountToTransfer);

            if (hasError) continue;

            if (amountToTransfer) {
                accountsToUse.push({ address: account.stakeAccount, amountToTransfer });
                remainingAmount = bsMath.tokenSub(remainingAmount, amountToTransfer, SOL_DECIMALS);
                requestCollateralMap.delete(account.stakeAccount); // rm from collateral map
            }

            if (remainingAmount === 0) break;
        } else {
            // check if there is a matching amount in the collateral, if there is add it to accountstoUse, else add it to available

            const requestAmounts = Array.from(requestCollateralMap.values());

            const matchingAmountIndex = requestAmounts.findIndex(
                (amount) => amount === bsMath.tokenAdd(account.amount, account.rentExemptReserve, SOL_DECIMALS) // only full splits used
            );

            if (matchingAmountIndex >= 0) {
                const amountToTransfer = requestAmounts[matchingAmountIndex];
                accountsToUse.push({ address: account.stakeAccount, amountToTransfer });
                remainingAmount = bsMath.tokenSub(remainingAmount, amountToTransfer, SOL_DECIMALS);
                requestCollateralMap.delete(Array.from(requestCollateralMap.keys())[matchingAmountIndex]);
            } else {
                availableStakeAccounts.push(account);
            }
        }
    }

    if (remainingAmount > 0) {
        // make sure stake accounts are not being reused
        //greedily split remaning stake accounts
        const greedyResult = greedySplit(
            availableStakeAccounts,
            remainingAmount,
            bsMath.tokenSub(amount, remainingAmount, SOL_DECIMALS)
        );
        remainingAmount = greedyResult.remainingAmount;
        accountsToUse.push(...greedyResult.accountsToUse);
    }

    return accountsToUse;
}

function greedySplit(accounts: SplitStakeAccount[], amount: number, amountAlreadySplit: number) {
    let remainingAmount = amount;
    // asc sort use smaller stake accounts fully instead of partially using big accounts
    const sortedAccounts = accounts.sort((a, b) => {
        const amountDifference = a.amount - b.amount;
        if (amountDifference !== 0) {
            return amountDifference;
        }
        const stakeAccountDifference = a.stakeAccount.localeCompare(b.stakeAccount);
        return stakeAccountDifference;
    });
    const accountsToUse: StakeAccountArgs[] = [];
    for (const account of sortedAccounts) {
        const fullStake = bsMath.tokenAdd(account.amount, account.rentExemptReserve, SOL_DECIMALS);

        const amountToTransfer = Math.min(remainingAmount, fullStake);

        const hasError = checkStakeInputErrors(account, amountToTransfer, amountAlreadySplit);

        if (hasError) continue;

        remainingAmount = bsMath.tokenSub(remainingAmount, amountToTransfer, SOL_DECIMALS);

        if (amountToTransfer) {
            accountsToUse.push({ address: account.stakeAccount, amountToTransfer });
        }
    }
    return { accountsToUse, remainingAmount };
}

function useEscrowedStakedSol(options?: { skip?: boolean }) {
    const { activeEscrow } = useActiveEscrow();

    const { data: rawStakedSolData } = useStakedSolByWalletQuery(
        activeEscrow ? { wallet: activeEscrow, force: false } : skipToken,
        { skip: !activeEscrow || options?.skip }
    );

    const totalStakedSolRaw = rawStakedSolData?.stakedSol?.reduce(
        (prev, curr) => prev + curr.amount + curr.rentExemptReserve,
        0
    );
    const totalStakedSol = lamportsToUiAmount(totalStakedSolRaw, SOL_DECIMALS);

    return { rawStakedSolData, totalStakedSol };
}

export function useUserUnrecognizedAssets(skip?: boolean) {
    const { activeWallet } = useActiveWallet();

    const { data: rawData, isLoading } = useTokensByWalletQuery(activeWallet?.wallet ?? skipToken, {
        skip: !activeWallet || skip
    });

    const data = rawData?.tokens.filter((t) => !(t.mint in rawData.metadataMap));

    return { data, isLoading };
}

export function useSummarizedUserAssets(params: AssetParams) {
    const { data: balances, isLoading } = useUserAvailableAssets(params);
    const { getUsdPrice } = useOraclePrices(balances?.map((b) => b.key));

    const balanceMap = useMemo(() => {
        if (!balances) return undefined;

        const balanceMap = balances.reduce((map, curr) => {
            // use the metadata key for things like wp
            const key = (() => {
                if (curr.stakeAccount) return STAKED_SOL_MINT;
                if (curr.whirlpoolPosition) return curr.whirlpoolPosition.whirlpoolAddress;
                return curr.mint;
            })();
            const prev = map.get(key) ?? { available: 0, availableUsd: 0 };
            const currentUsdValue = (getUsdPrice(key) ?? 0) * curr.amount;

            // Stake accounts can only be used once at a time
            if (curr.stakeAccount) {
                map.set(key, {
                    available: Math.max(prev.available, curr.amount),
                    availableUsd: Math.max(prev.availableUsd, currentUsdValue)
                });
            } // Orca is non fungible and should be added as a single unit
            else if (curr.whirlpoolPosition) {
                map.set(key, {
                    available: prev.available + 1,
                    availableUsd: Math.max(prev.availableUsd, curr.whirlpoolPosition.totalPrice ?? 0)
                });
            } else {
                map.set(key, {
                    available: prev.available + curr.amount,
                    availableUsd: prev.availableUsd + currentUsdValue
                });
            }
            return map;
        }, new Map<string, { available: number; availableUsd: number }>());

        return balanceMap;
    }, [balances, getUsdPrice]);

    return { balanceMap, balances, isLoading };
}
