import { useCallback } from "react";

import { StatColumn, StatProps } from "@bridgesplit/ui";
import { BsMetaUtil } from "@bridgesplit/abf-react";
import { formatUsd } from "@bridgesplit/utils";
import { Divider } from "@mui/material";

import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useOpenPositionDepositRatio, useWhirlpoolOpenPositionBalances } from "./util";
import { BalanceAndMax, TokenInputs } from "../common";

export default function OpenPositionAmounts() {
    return (
        <>
            <Inputs />
            <Stats />
        </>
    );
}

function Inputs() {
    const {
        openPositionForm,
        setOpenPositionForm,
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();
    const ratio = useOpenPositionDepositRatio();
    const { balances } = useWhirlpoolOpenPositionBalances();
    const depositRatio = useOpenPositionDepositRatio();

    const handleChange = useCallback(
        (amount: number | undefined, side: "A" | "B") => {
            setOpenPositionForm((prev) => {
                const tokenAmountKey = side === "A" ? "tokenAAmount" : "tokenBAmount";
                if (prev[tokenAmountKey] === amount) return prev;
                return { ...prev, [tokenAmountKey]: amount, side, status: "refetch-needed" };
            });
        },
        [setOpenPositionForm]
    );

    return (
        <TokenInputs
            handleChange={handleChange}
            ratio={ratio}
            form={openPositionForm}
            isFetching={openPositionForm.status === "refetching"}
            getBelowInput={(side) => {
                const balance = side === "A" ? balances.A : balances.B;
                if (!balance) return null;
                const { userBalance, fee, position } = balance;
                const token = side === "A" ? tokenA.metadata : tokenB.metadata;
                return (
                    <BalanceAndMax
                        side={side}
                        depositRatio={depositRatio}
                        inputAmount={side === "A" ? openPositionForm.tokenAAmount : openPositionForm.tokenBAmount}
                        setAmount={handleChange}
                        balanceA={balances.A?.total ?? 0}
                        balanceB={balances.B?.total ?? 0}
                        tooltipContent={
                            <>
                                <StatColumn
                                    captionVariant={variant}
                                    variant={variant}
                                    stats={[
                                        {
                                            caption: "Position + yield",
                                            value: BsMetaUtil.formatAmount(token, position + fee)
                                        },
                                        {
                                            caption: "Your wallet",
                                            value: BsMetaUtil.formatAmount(token, userBalance)
                                        }
                                    ]}
                                />
                                <Divider />
                            </>
                        }
                    />
                );
            }}
        />
    );
}

const variant = "body2";
function Stats() {
    const {
        openPositionForm,
        whirlpoolPosition: { tokenA, tokenB, totalPrice }
    } = useWhirlpoolContext();

    const tokenAAmount = openPositionForm.tokenAAmount ?? 0;
    const tokenBAmount = openPositionForm.tokenBAmount ?? 0;

    const tokenStats = [
        [tokenA, tokenAAmount],
        [tokenB, tokenBAmount]
    ] as const;

    const depositUsdValue = tokenAAmount * tokenA.usdPrice + tokenBAmount * tokenB.usdPrice;

    if (!openPositionForm.tickLower || !openPositionForm.tickUpper) return null;

    return (
        <StatColumn
            variant={variant}
            loading={openPositionForm.status && openPositionForm.status === "refetching"}
            stats={[
                { caption: "Deposit value", value: [formatUsd(totalPrice), formatUsd(depositUsdValue)] },
                ...tokenStats.map(([token, amount]): StatProps => {
                    const change = amount - token.amount;
                    return {
                        caption: `${BsMetaUtil.getSymbol(token.metadata)} balance`,
                        value:
                            change === 0
                                ? "No change"
                                : `${change > 0 ? "+" : ""}${BsMetaUtil.formatAmount(token.metadata, change)}`,
                        textColor: (() => {
                            if (change === 0) return "disabled";
                            if (change > 0) return "success";
                            return "error";
                        })()
                    };
                })
            ]}
        />
    );
}
