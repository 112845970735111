import { createApi } from "@reduxjs/toolkit/query/react";
import {
    SphereUserInfo,
    SphereUserWallets,
    SphereUserBankAccount,
    CreateBankAccountInput,
    CreateCustomerInput,
    CreateWalletInput,
    CreatePayoutInput,
    SpherePayoutFilter,
    CreateBusinessCustomerInput,
    SphereCustomerToken,
    BusinessKybStatus,
    NewUboInput,
    UboStatus,
    SpherePayoutInfo
} from "@bridgesplit/abf-sdk";
import { useDispatch } from "react-redux";
import { IdempotentRequest, RawFile } from "@bridgesplit/react";
import { TransactionInstructionResponse } from "@bridgesplit/utils";

import {
    CREATE_SPHERE_WALLET,
    CREATE_SPHERE_BANK_ACCOUNT,
    CREATE_SPHERE_ONRAMP,
    CREATE_SPHERE_OFFRAMP,
    FETCH_SPHERE_BANK_ACCOUNT,
    FETCH_SPHERE_PAYOUT,
    FETCH_SPHERE_USER_INFO,
    FETCH_SPHERE_WALLET,
    IDEMPOTENT_KEY_HEADER,
    CREATE_SPHERE_INDIVIDUAL_CUSTOMER,
    CREATE_SPHERE_BUSINESS_CUSTOMER,
    PROMPT_SPHERE_TOS,
    UPLOAD_UBO_DOCUMENTATION,
    UPLOAD_INCORPORATION_DOCUMENTATION,
    BUSINESS_KYB_STATUS,
    ADD_NEW_UBO,
    GET_UBO_USER_STATUS,
    ABF_GROUP_HEADER
} from "../constants";
import { abfSerializeQueryArgs, zephyrMetadataBaseQuery } from "./util";

const SPHERE_TAG = "Sphere";
const KYB_TAG = "SphereKYB";
const UBO_TAG = "Ubo";

export const sphereApi = createApi({
    tagTypes: [SPHERE_TAG, KYB_TAG, UBO_TAG],
    baseQuery: zephyrMetadataBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        /** Queries */
        sphereUserInfo: builder.query<SphereUserInfo | null, void>({
            providesTags: [SPHERE_TAG, KYB_TAG],
            query: () => ({
                url: FETCH_SPHERE_USER_INFO,
                method: "GET"
            })
        }),
        sphereUserWallets: builder.query<SphereUserWallets[], void>({
            providesTags: [SPHERE_TAG],
            query: () => ({
                url: FETCH_SPHERE_WALLET,
                method: "GET"
            })
        }),
        sphereUserBankAccount: builder.query<SphereUserBankAccount[], void>({
            providesTags: [SPHERE_TAG],
            query: () => ({
                url: FETCH_SPHERE_BANK_ACCOUNT,
                method: "GET"
            })
        }),
        sphereUserPayouts: builder.query<SpherePayoutInfo[], SpherePayoutFilter>({
            providesTags: [SPHERE_TAG],
            query: (filter) => ({
                url: FETCH_SPHERE_PAYOUT,
                method: "POST",
                body: filter
            })
        }),
        sphereCustomerToken: builder.query<{ data: SphereCustomerToken }, void>({
            providesTags: [SPHERE_TAG],
            query: () => ({
                url: PROMPT_SPHERE_TOS,
                method: "POST"
            })
        }),
        businessKybStatus: builder.query<BusinessKybStatus, void>({
            providesTags: [SPHERE_TAG],
            query: () => ({
                url: BUSINESS_KYB_STATUS,
                method: "GET"
            }),
            transformResponse: (
                raw: Omit<BusinessKybStatus, "uboStatuses"> & { uboStatuses: Record<string, UboStatus> }
            ) => {
                const uboStatuses = Object.entries(raw.uboStatuses).map(([email, status]) => ({
                    email,
                    status: status as UboStatus
                }));
                return { ...raw, uboStatuses };
            }
        }),
        uboUserStatus: builder.query<UboStatus, void>({
            providesTags: [UBO_TAG],
            query: () => ({
                url: GET_UBO_USER_STATUS,
                method: "GET"
            })
        }),

        /** Mutations */
        createSphereIndividualCustomer: builder.mutation<SphereUserInfo, IdempotentRequest<CreateCustomerInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_INDIVIDUAL_CUSTOMER,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        createSphereBusinessCustomer: builder.mutation<void, IdempotentRequest<CreateBusinessCustomerInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_BUSINESS_CUSTOMER,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        createSphereWallet: builder.mutation<SphereUserWallets, IdempotentRequest<CreateWalletInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_WALLET,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        createSphereBankAccount: builder.mutation<SphereUserBankAccount[], IdempotentRequest<CreateBankAccountInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_BANK_ACCOUNT,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        createSphereOnramp: builder.mutation<void, IdempotentRequest<CreatePayoutInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_ONRAMP,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        createSphereOfframp: builder.mutation<TransactionInstructionResponse[], IdempotentRequest<CreatePayoutInput>>({
            query: ({ idempotentKey, ...body }) => ({
                url: CREATE_SPHERE_OFFRAMP,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        uploadSphereUboDocumentation: builder.mutation<void, IdempotentRequest<RawFile>>({
            invalidatesTags: [SPHERE_TAG, KYB_TAG],
            query: ({ idempotentKey, ...body }) => ({
                url: UPLOAD_UBO_DOCUMENTATION,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        uploadSphereIncorporationDocumentation: builder.mutation<void, IdempotentRequest<RawFile>>({
            invalidatesTags: [SPHERE_TAG, KYB_TAG],
            query: ({ idempotentKey, ...body }) => ({
                url: UPLOAD_INCORPORATION_DOCUMENTATION,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey }
            })
        }),
        addNewUbo: builder.mutation<void, IdempotentRequest<NewUboInput> & { groupIdentifier: string }>({
            invalidatesTags: [UBO_TAG],
            query: ({ idempotentKey, groupIdentifier, ...body }) => ({
                url: ADD_NEW_UBO,
                method: "POST",
                body,
                headers: { [IDEMPOTENT_KEY_HEADER]: idempotentKey, [ABF_GROUP_HEADER]: groupIdentifier }
            })
        })
    })
});

export const {
    useSphereUserInfoQuery,
    useSphereUserPayoutsQuery,
    useSphereUserWalletsQuery,
    useSphereUserBankAccountQuery,
    useBusinessKybStatusQuery,
    useUboUserStatusQuery,
    useSphereCustomerTokenQuery,
    useCreateSphereIndividualCustomerMutation,
    useCreateSphereBusinessCustomerMutation,
    useCreateSphereWalletMutation,
    useCreateSphereBankAccountMutation,
    useCreateSphereOnrampMutation,
    useCreateSphereOfframpMutation,
    useUploadSphereIncorporationDocumentationMutation,
    useUploadSphereUboDocumentationMutation,
    useAddNewUboMutation
} = sphereApi;

export const useSphereApi = () => {
    const dispatch = useDispatch();
    // refetch data
    return {
        resetSphere: () => dispatch(sphereApi.util.invalidateTags([SPHERE_TAG, KYB_TAG, UBO_TAG]))
    };
};
