import { StrictMode } from "react";

import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./app/app";
import { store } from "./app/reducers";
import { ErrorBoundary } from "./app/components/wrappers";
import { initSentry } from "./app/utils";

initSentry();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    </StrictMode>
);
