import { useMemo, useState } from "react";

import {
    BsMetaUtil,
    LoopExpandedMeteora,
    METEORA_POLL_INTERVAL,
    useMeteoraDepositQuoteQuery
} from "@bridgesplit/abf-react";
import { Meteora } from "@bridgesplit/bs-protos";
import { uiAmountToLamports, bigNumberStringToUiAmount } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StatColumn, StatProps } from "@bridgesplit/ui";

import { AppButton, TokenInputWithPrice, useBalanceChecker } from "../../common";
import { useMeteoraLpDeposit } from "./util";
import { MeteoraCtaWrapper } from "./common";

export default function MeteoraDeposit({
    loopExpanded,
    slippagePercentUi
}: {
    loopExpanded: LoopExpandedMeteora;
    slippagePercentUi: number;
}) {
    const [principalAmount, setPrincipalAmount] = useState<number | undefined>(undefined);

    const depositParams = useMemo((): Meteora.DynamicPoolDepositQuoteParams | undefined => {
        if (!principalAmount || !slippagePercentUi) return undefined;
        const depositAmount = uiAmountToLamports(principalAmount, loopExpanded.principalToken.decimals).toString();
        const { poolAddress, tokenAMint, tokenBMint } = loopExpanded.meteoraPool;

        return {
            pool: poolAddress,
            tokenAInAmount: tokenAMint === loopExpanded.principalToken.assetMint ? depositAmount : "0",
            tokenBInAmount: tokenBMint === loopExpanded.principalToken.assetMint ? depositAmount : "0",
            balance: false,
            slippage: slippagePercentUi
        };
    }, [
        loopExpanded.meteoraPool,
        loopExpanded.principalToken.assetMint,
        loopExpanded.principalToken.decimals,
        principalAmount,
        slippagePercentUi
    ]);

    const { data: externalQuote } = useMeteoraDepositQuoteQuery(depositParams ?? skipToken, {
        pollingInterval: METEORA_POLL_INTERVAL,
        skip: !depositParams
    });
    const { BalanceDisplay, insufficientBalance } = useBalanceChecker({
        amount: principalAmount,
        mint: loopExpanded.principalToken.assetMint,
        setMax: setPrincipalAmount
    });

    const deposit = useMeteoraLpDeposit();
    return (
        <>
            <TokenInputWithPrice
                label="You deposit"
                value={principalAmount}
                setValue={setPrincipalAmount}
                metadata={loopExpanded.principalToken}
                selectToken={null}
                belowInput={<BalanceDisplay />}
            />
            {!!principalAmount && <Stats quote={externalQuote} loopExpanded={loopExpanded} />}
            <MeteoraCtaWrapper loopExpanded={loopExpanded} side="deposits">
                <AppButton
                    isTransaction
                    disabled={!externalQuote || insufficientBalance}
                    asyncCta={{
                        onClickWithResult: () => deposit(loopExpanded, externalQuote),
                        options: { alertOnError: true }
                    }}
                    color="secondary"
                >
                    Deposit
                </AppButton>
            </MeteoraCtaWrapper>
        </>
    );
}

function Stats({
    quote,
    loopExpanded
}: {
    quote: Meteora.DynamicPoolDepositQuote | undefined;
    loopExpanded: LoopExpandedMeteora;
}) {
    const stats: StatProps[] = [
        {
            value: BsMetaUtil.formatAmount(
                loopExpanded.collateralToken,
                bigNumberStringToUiAmount(quote?.poolTokenAmountOut, loopExpanded.collateralToken.decimals)
            ),
            caption: "You receive"
        },
        {
            value: BsMetaUtil.formatAmount(
                loopExpanded.collateralToken,
                bigNumberStringToUiAmount(quote?.minPoolTokenAmountOut, loopExpanded.collateralToken.decimals)
            ),
            caption: "Minimum received"
        }
    ];

    return <StatColumn stats={stats} loading={!quote} />;
}
