import { PresetStrategyTerms } from "@bridgesplit/abf-react";
import { SplitStakeAccount, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";

export type MarketBorrowForm = {
    mode: BorrowMode;
    amount?: number;
    collateralAmount?: number;
    collateralMint?: string;
    preset?: PresetStrategyTerms;
    stakeAccount?: SplitStakeAccount;
    stakeAccountAdjusted?: boolean; // notify user when a full SA has to be used due to program constraints
    ltvMultiplier: number; // decimals
    refetchOutAmount?: boolean; // update collateral amount from best quotes
    orcaPosition?: WhirlpoolPositionExpanded;
};

export enum BorrowMode {
    InputPrincipal,
    InputCollateral,
    InputLtv
}

export enum LtvPreset {
    Safe = "Safe",
    Moderate = "Moderate",
    Max = "Aggressive"
}

export const LTV_PRESETS: Record<LtvPreset, number> = {
    [LtvPreset.Safe]: 0.25,
    [LtvPreset.Moderate]: 0.5,
    [LtvPreset.Max]: 1
};

export const initialBorrowForm: MarketBorrowForm = {
    mode: BorrowMode.InputCollateral,
    ltvMultiplier: 0
};
