import { useCallback } from "react";

import {
    Table,
    TableRow,
    TableColumn,
    PercentInput,
    Text,
    TABLE_CTA_COLUMN_KEY,
    Column,
    TooltipText
} from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";

import { StrategyTermsProps, TermsForStrategy } from "./type";

export default function StrategyTermsTable({ terms, termToApy, setTermToApy }: StrategyTermsProps) {
    const editApy = useCallback(
        ({ key }: TermsForStrategy, apy: number | undefined) => {
            const copy = new Map(termToApy);
            copy.set(key, apy);
            setTermToApy(copy);
        },
        [setTermToApy, termToApy]
    );

    const rows: TableRow<TableData>[] | undefined = terms?.map((t) => ({
        data: {
            ...t,
            editApy: termToApy?.get(t.key),
            selfEditApy: (apy) => editApy(t, apy)
        },
        key: t.key
    }));

    return (
        <Table
            loading={!terms}
            paginationProps={{ type: "managed" }}
            emptyPlaceholder="No eligible collateral found."
            rows={rows}
            columns={columns}
        />
    );
}

type TableData = TermsForStrategy & {
    editApy: number | undefined;
    selfEditApy: (val: number | undefined) => void;
};
const columns: TableColumn<TableData>[] = [
    {
        dataIndex: "Duration",
        title: "Duration",
        render: ({
            data: {
                bestQuote,
                allowedForCollateral,
                presetTerms: { duration, durationType }
            }
        }) => (
            <Column>
                <Text>{formatDurationWithType(duration, durationType)}</Text>
                {bestQuote && (
                    <TooltipText helpText="Lowest market rate" variant="body2" color="caption">
                        {formatPercent(bestQuote?.apy)} APY
                    </TooltipText>
                )}
            </Column>
        )
    },

    {
        dataIndex: TABLE_CTA_COLUMN_KEY,
        title: "APY (%)",
        align: "right",
        width: "150px",
        render: Cta
    }
];

function Cta({ data: { editApy, selfEditApy, allowedForCollateral } }: TableRow<TableData>) {
    return (
        <PercentInput
            disabled={!allowedForCollateral}
            adornment={null}
            inputProps={{ width: "100px", sx: { textAlign: "right" } }}
            value={allowedForCollateral ? editApy : 0}
            setValue={selfEditApy}
        />
    );
}
