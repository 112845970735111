import { ReactNode } from "react";

import {
    DiscordSvg,
    ExternalLink,
    IconButton,
    MenuItemProps,
    MenuItems,
    PopoverWrapper,
    Row,
    SxType,
    Text,
    TextButton,
    Tooltip,
    TwitterSvg,
    useAppPalette,
    usePopover
} from "@bridgesplit/ui";
import {
    AutoMode,
    AutoStoriesOutlined,
    CheckOutlined,
    ContrastOutlined,
    DarkModeOutlined,
    LightModeOutlined
} from "@mui/icons-material";
import { Theme, useAppTheme } from "@bridgesplit/react";
import { DISCORD_URL, TWITTER_URL, DOCS } from "app/constants";

import { DESKTOP_PADDING, NAV_Z_INDEX } from "./constants";
import { useNavbarHeight } from "./util";

const LINKS: { icon: JSX.Element; label: string; link: string; showText?: boolean }[] = [
    { icon: <TwitterSvg />, label: "X", link: TWITTER_URL },
    { icon: <DiscordSvg />, label: "Discord", link: DISCORD_URL },
    { icon: <AutoStoriesOutlined />, label: "Docs", link: DOCS.root, showText: true }
];
export default function Footer({ sx }: { sx?: SxType }) {
    const { border, background } = useAppPalette();
    const { footer } = useNavbarHeight();

    return (
        <Row
            spacing={2}
            sx={{
                borderTop: border,
                px: DESKTOP_PADDING,
                height: footer + "px",
                background,
                position: "fixed",
                bottom: 0,
                width: "100vw",
                zIndex: NAV_Z_INDEX - 1,
                ...sx
            }}
        >
            <ThemeEdit />
            {LINKS.map(({ icon, label, link, showText }) => {
                if (showText) {
                    return (
                        <ExternalLink key={label} target="_blank" rel="noopener" href={link}>
                            <TextButton color="caption">{label}</TextButton>
                        </ExternalLink>
                    );
                }
                return (
                    <Tooltip key={label} title={label}>
                        <ExternalLink target="_blank" rel="noopener" href={link}>
                            <IconButton textColor="caption" textVariant="body1" border={false} jsxIcon={icon} />
                        </ExternalLink>
                    </Tooltip>
                );
            })}
        </Row>
    );
}

function ThemeEdit() {
    const { open, popoverProps, close } = usePopover();

    return (
        <>
            <PopoverWrapper
                anchorOrigin={{ vertical: -20, horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                sx={{ width: "200px" }}
                {...popoverProps}
            >
                <ThemeSwitch close={close} />
            </PopoverWrapper>
            <Tooltip title="Theme">
                <IconButton
                    onClick={open}
                    textColor="caption"
                    textVariant="body1"
                    border={false}
                    jsxIcon={<ContrastOutlined />}
                />
            </Tooltip>
        </>
    );
}

function ThemeSwitch({ close }: { close: () => void }) {
    const { theme, updateTheme } = useAppTheme();

    const options: { value: Theme; label: string; icon: ReactNode }[] = [
        { value: "auto", label: "Device (auto)", icon: <AutoMode /> },
        { value: "light", label: "Light", icon: <LightModeOutlined /> },
        { value: "dark", label: "Dark", icon: <DarkModeOutlined /> }
    ];
    const items = options.map(
        (option): MenuItemProps => ({
            text: option.label,
            onClick: () => updateTheme(option.value),
            icon: option.icon,
            rowEnd:
                theme === option.value ? (
                    <Text>
                        <CheckOutlined />
                    </Text>
                ) : undefined
        })
    );

    return <MenuItems close={close} items={items} />;
}
