import {
    getFundSyndicatedOrderTransaction,
    getRedeemSyndicatedOrderTransactions,
    SyndicatedOrderRedeemArgs,
    SyndicatedOrderContributor,
    getUpdateSyndicatedOrderTransaction,
    SyndicatedOrderUpdateArgsSide,
    getCancelSyndicatedOrderTransactions,
    SyndicatedOrderCancelArgs,
    getPlaceSyndicatedOrderTransactions,
    SyndicatedOrderPlaceArgs
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { Result, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails } from "../types";
import { useDecimalsByMint } from "../utils";
import { useAbfGenerateTransaction } from "./common";
import { TRANSACTION_DEFAULT_BATCH_COMMITMENT } from "../constants";

export function useSyndicatedRedeemTransaction(): AbfTransactionDetails<SyndicatedOrderRedeemArgs[]> {
    const generate = useAbfGenerateTransaction();
    const { resetSyndicatedApi, resetLoanApi } = useAbfFetches();

    async function getTransactionsWithParams(redeemArgs: SyndicatedOrderRedeemArgs[]): AbfGeneratorResult {
        try {
            const redeem = redeemArgs.map((arg) =>
                generate({
                    generateFunction: getRedeemSyndicatedOrderTransactions,
                    identifier: "Syndicate Redeem",
                    params: arg
                })
            );

            const transactions = await combineTransactionPromises(redeem, { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetSyndicatedApi();
            resetLoanApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Syndicate Redeem" };
}

export function useSyndicatedCancelTransaction(): AbfTransactionDetails<SyndicatedOrderCancelArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetSyndicatedApi } = useAbfFetches();

    async function getTransactionsWithParams(cancelArgs: SyndicatedOrderCancelArgs): AbfGeneratorResult {
        try {
            const cancel = generate({
                generateFunction: getCancelSyndicatedOrderTransactions,
                identifier: "Cancel Syndicate",
                params: cancelArgs
            });

            const transactions = await combineTransactionPromises([cancel], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetSyndicatedApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Cancel Syndicate" };
}

export function useSyndicatedPlaceOrderTransaction(): AbfTransactionDetails<SyndicatedOrderPlaceArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetSyndicatedApi, resetLoanRequests, resetOrderApi } = useAbfFetches();

    async function getTransactionsWithParams(placeArgs: SyndicatedOrderPlaceArgs): AbfGeneratorResult {
        try {
            const place = generate({
                generateFunction: getPlaceSyndicatedOrderTransactions,
                identifier: "Place Syndicate Order",
                params: placeArgs
            });

            const transactions = await combineTransactionPromises([place], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanRequests();
            resetOrderApi();
            resetSyndicatedApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Placing Syndicate Order" };
}

export type UpdateSyndicatedContributionArgs = {
    changeAmount: number;
    side: SyndicatedOrderUpdateArgsSide;
    syndicatedOrder: string;
    principalMint: string;
    contributor: SyndicatedOrderContributor | null;
};
export function useSyndicatedContributionTransaction(): AbfTransactionDetails<UpdateSyndicatedContributionArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetSyndicatedApi } = useAbfFetches();

    const { fetchDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams({
        changeAmount,
        side,
        principalMint,
        syndicatedOrder,
        contributor
    }: UpdateSyndicatedContributionArgs): AbfGeneratorResult {
        try {
            const userHasPreviousContribution = !!contributor;
            const decimals = await fetchDecimals(principalMint);
            const identifier = side === SyndicatedOrderUpdateArgsSide.Deposit ? "Deposit" : "Withdraw";
            const amount = uiAmountToLamports(changeAmount, decimals);

            const txn = (() => {
                if (!userHasPreviousContribution) {
                    return generate({
                        generateFunction: getFundSyndicatedOrderTransaction,
                        identifier,
                        params: { amount, syndicatedOrder }
                    });
                }

                return generate({
                    generateFunction: getUpdateSyndicatedOrderTransaction,
                    identifier,
                    params: { amount, syndicatedOrder, fundNftMint: contributor.nftMint, side }
                });
            })();

            const transactions = await combineTransactionPromises([txn], {
                order: "sequential",
                commitmentLevel: TRANSACTION_DEFAULT_BATCH_COMMITMENT
            });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetSyndicatedApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Updating your syndicate deposit" };
}
