import { BsMetadata } from "@bridgesplit/abf-sdk";

export interface RewardsFilter {
    feVisible: boolean;
    activeOnly: boolean;
}

export interface UserPointsCalculationInfo {
    points: UserPastPoints;
    pointsPerSecond: UserPointsPerSecond;
    timestamp: number;
}

export interface UserPastPoints {
    basePoints: number;
    referralBonusPoints: number;
}

export interface UserPointsPerSecond {
    basePointsPerSecond: number;
    referralPointsPerSecond: number;
}
export interface UserWaitlistInfo {
    userRank: number | null; // null if user not on WL
    totalUsers: number;
}

export interface PointMultiplier {
    reward: number;
    multiplier: number;
    mint: string;
    name: string;
}

export enum PointsRewardType {
    SignUp = 0,
    BetaSignup = 1,
    FollowTwitter = 2,
    JoinDiscord = 3,
    CommunityEngagement = 4,
    AcceptReferral = 7
}

export interface PointsReward {
    reward: PointsRewardType;
    rewardName: string;
    pointsPerSecond: number;
    startTime: number;
    endTime: number | null;
    feRewardLabel: string;
    feVisible: boolean;
    feDisabled: boolean;
    feUrl: string;
    feContinuous: boolean;
}

export interface UserPointsReward {
    id: number;
    userIdentifier: string;
    reward: PointsRewardType;
    startTime: number;
    endTime: number | null;
    multiplier: number;
}

export interface CommunityRewardInfo {
    reward: PointsReward;
    communityPointsReward: CommunityPointsReward;
    userPointsReward: UserPointsReward | null;
}

export enum CommunityMultiplierType {
    Ovols = 0
}

export interface CommunityMultiplier {
    communityMultiplier: CommunityMultiplierType;
    multiplier: number;
}

export interface CommunityPointsReward {
    reward: PointsRewardType;
    mint: string;
    communityRewardType: CommunityRewardsType;
    threshold: number;
    rewardImage: string;
    bonusThreshold: number;
    bonusAmount: number;
}

export interface CommunityRewardClaim {
    reward: number;
    userIdentifier: string;
    attemptedClaim: boolean;
}

export enum CommunityRewardsType {
    Token = 0,
    Nft = 1,
    OrcaLp = 2
}

export enum PointSource {
    MarginFi = 0,
    Loopscale = 1,
    Jupiter = 2,
    Meteora = 3,
    HubSol = 4,
    LoopscaleHidden = 5
}

export enum LoopscalePointSource {
    Borrow = 35,
    Lend = 36,
    IdleCap = 37
}

export interface CollateralPointsData {
    eligibleMultipliers: LoopscalePointSource[];
    showRewards: boolean;
    getAdditionalPoints: (pointSources: LoopscalePointSource[]) => number;
}

export interface CollateralInfo {
    pointSource: PointSource[];
    collateralPointBonus: Map<LoopscalePointSource, number>; // Using
    name: string;
}

export interface UseRewardsCalculationProps {
    pointSources: PointSource[];
    loopscalePointSources?: LoopscalePointSource[];
    metadata?: BsMetadata[];
    loopLeverage?: number;
}

export interface RewardsCalculationResult {
    finalRewards: PointsInfo[];
}

export interface TokenSourceData {
    eligibleMultipliers: LoopscalePointSource[];
    showRewards: boolean;
}

export interface PointsInfo {
    program: PointSource;
    multiplier: number | undefined;
    caption: string;
}
export type PointsInfoWithoutMultiplier = Omit<PointsInfo, "multiplier">;

export enum LabelVisibility {
    Hidden = "hidden", // Hides entire component
    ShowBasic = "basic", // Shows just text
    ShowFull = "full" // Shows text + rewards icon
}
