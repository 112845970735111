import { Result, SOL_NATIVE_MINT, base64, getReadableErrorMessage } from "@bridgesplit/utils";
import { Connection, PublicKey, Transaction, VersionedTransaction } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, AccountLayout } from "@solana/spl-token";

import {
    AccountChangeSummary,
    AccountWithOwner,
    AccountWithOwnerWithBalance,
    TransactionBalanceChange
} from "../types";

async function getContextSlot(connection: Connection) {
    const currentSlot = await connection.getSlot();
    const slots = await connection.getBlocks(currentSlot - 200);
    return slots[0];
}

export async function simulateTransaction(
    connection: Connection,
    transaction: Transaction | VersionedTransaction,
    accounts: string[],
    options?: { slot?: number }
) {
    if (isVersionedTransaction(transaction)) {
        let minContextSlot = options?.slot;
        if (minContextSlot === undefined) {
            // pass in minContextSlot to prevent mismatched slot
            minContextSlot = await getContextSlot(connection);
        }

        return await connection.simulateTransaction(transaction, {
            replaceRecentBlockhash: true,
            sigVerify: false,
            minContextSlot,
            commitment: "processed",
            accounts: { encoding: "base64", addresses: accounts }
        });
    }

    return await connection.simulateTransaction(
        transaction,
        undefined,
        accounts.map((a) => new PublicKey(a))
    );
}

export async function estimateBalanceChangesForTransactions(
    connection: Connection,
    transactions: (Transaction | VersionedTransaction)[],
    accountWithOwners: AccountWithOwner[]
): Promise<
    Result<{
        accountChangeSummary: AccountChangeSummary[];
        transactionBalanceChange: TransactionBalanceChange[];
    }>
> {
    try {
        const slot = await getContextSlot(connection);
        const currentBalancesPromise = fetchAccountsWithOwners(connection, accountWithOwners);
        const newBalancesForTransactionsPromise = Promise.all(
            transactions.map((t) => estimatePostTransactionBalances(connection, t, accountWithOwners, { slot }))
        );
        const [currentBalances, newBalancesForTransactions] = await Promise.all([
            currentBalancesPromise,
            newBalancesForTransactionsPromise
        ]);

        const previousAccountMap = new Map(currentBalances.map((b) => [b.account, b]));

        const accountChangeSummary = new Map<string, AccountChangeSummary>();
        const transactionBalanceChange: TransactionBalanceChange[] = [];

        for (const newBalances of newBalancesForTransactions) {
            if (newBalances.isErr() && newBalancesForTransactions.length === 1) {
                // only return fail if all txns fail
                return Result.err(newBalances);
            }
            for (const newAccount of newBalances.unwrapOr([])) {
                const prevAccount = previousAccountMap.get(newAccount.account);
                const previousBalance = prevAccount?.balance || 0;

                const change = newAccount.balance - previousBalance;

                transactionBalanceChange.push({
                    ...newAccount,
                    previousBalance,
                    newBalance: newAccount.balance,
                    change
                });

                const prevChange = accountChangeSummary.get(newAccount.account) ?? {
                    ...newAccount,
                    change: 0
                };
                accountChangeSummary.set(newAccount.account, { ...prevChange, change: prevChange.change + change });
            }
        }

        const data = { accountChangeSummary: Array.from(accountChangeSummary.values()), transactionBalanceChange };
        return Result.ok(data);
    } catch (error) {
        return Result.err(error);
    }
}

export async function estimatePostTransactionBalances(
    connection: Connection,
    transaction: Transaction | VersionedTransaction,
    accountWithOwners: AccountWithOwner[],
    options?: { slot?: number }
): Promise<Result<AccountWithOwnerWithBalance[]>> {
    const result = await simulateTransaction(
        connection,
        transaction,
        accountWithOwners.map((a) => a.account),
        options
    );

    if (result.value.err || !result.value.accounts) {
        // eslint-disable-next-line no-console
        console.warn(result.value);

        return Result.errFromMessage(getReadableErrorMessage("simulate transaction"));
    }

    const changes: AccountWithOwnerWithBalance[] = [];
    result.value.accounts.forEach((account, i) => {
        // accounts are returned in the same order
        const originalAccount = accountWithOwners[i];

        if (!account) return 0;

        const isToken = account.owner === TOKEN_PROGRAM_ID.toString();
        const isNativeSol = account.owner === SOL_NATIVE_MINT;

        if (isToken) {
            const tokenAccount = decodeAccount(account.data);
            if (tokenAccount) {
                const balance: number = parseInt(tokenAccount.amount.toString());
                changes.push({ ...originalAccount, balance });
            }
        }
        if (isNativeSol && account.lamports) {
            const balance = account.lamports;
            changes.push({ ...originalAccount, balance });
        }
    });
    return Result.ok(changes);
}

function decodeAccount(accountData: string[]) {
    try {
        const tokenAccount = AccountLayout.decode(base64.decode(accountData[0]));
        return tokenAccount;
    } catch {
        return undefined;
    }
}

export async function fetchAccountsWithOwners(connection: Connection, accountWithOwners: AccountWithOwner[]) {
    return await Promise.all(
        accountWithOwners.map(async (accountWithOwner) => {
            const balance = await getBalance(connection, accountWithOwner);
            return { ...accountWithOwner, balance } as AccountWithOwnerWithBalance;
        })
    );
}

async function getBalance(connection: Connection, accountWithOwner: AccountWithOwner) {
    try {
        if (accountWithOwner.owner === SOL_NATIVE_MINT) {
            return await connection.getBalance(new PublicKey(accountWithOwner.account));
        } else {
            const raw = await connection.getTokenAccountBalance(new PublicKey(accountWithOwner.account));

            return parseInt(raw.value.amount) || 0;
        }
    } catch (error) {
        // catch for account doesn't exist
        return 0;
    }
}

/** Copy of Connection.simulateTransaction that takes a commitment parameter. */
export async function getFeeForTransaction(
    connection: Connection,
    transaction: Transaction | VersionedTransaction
): Promise<number | null> {
    if (isVersionedTransaction(transaction)) {
        const fee = await connection.getFeeForMessage(transaction.message);
        return fee.value;
    }

    const res = await transaction.getEstimatedFee(connection);

    return res;
}

export const isVersionedTransaction = (tx: Transaction | VersionedTransaction): tx is VersionedTransaction => {
    return "version" in tx;
};

export function serializeAndEncodeTransaction(transaction: Transaction | VersionedTransaction) {
    const txnBytes = Buffer.from(transaction.serialize({ requireAllSignatures: false }));
    return base64.encode(txnBytes);
}
