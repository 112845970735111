import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import {
    CUSTODIAN_QUOTE_ROUTE,
    GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE,
    GET_BEST_LOOP_QUOTE_ROUTE,
    GET_BEST_QUOTE_ROUTE,
    GET_LOOP_VAULT_BORROW_RATE_HISTORY_ROUTE,
    GET_MARKET_BORROW_CAPS_ROUTE,
    GET_MARKET_PRESETS_ROUTE,
    GET_MARKET_PRINCIPAL_STATS_ROUTE,
    GET_MARKET_STRATEGY_STATS_ROUTE,
    GET_MAX_QUOTE_ROUTE,
    GET_PRESETS_ROUTE,
    GET_PRESET_MIN_PRINCIPAL_ROUTE,
    GET_RATE_HISTORY_ROUTE,
    MARKET_QUOTE_FOR_APY_ROUTE,
    MARKET_QUOTE_ROUTE,
    ROUTE_GET_COLLATERAL_YIELDS_IN_RANGE,
    ROUTE_GET_REFINANCE_INFO
} from "../constants";
import { abfSerializeQueryArgs, napoleonPublicBaseQuery } from "./util";
import {
    BestLoopQuote,
    BestLoopQuoteQuery,
    BestQuote,
    BestQuoteFilter,
    BorrowCap,
    CollateralQuoteFilter,
    CollateralQuoteFilterForApy,
    CollateralYieldHistory,
    CustodianQuoteFilter,
    DurationToMinApyItem,
    HistoricalLoopVaultBorrowRate,
    HistoricalMarketRate,
    MarketPrincipalStats,
    MarketStratResponse,
    MarketsFilter,
    MaxQuoteRequest,
    MinPrincipalFilter,
    OfferQuote,
    OrderbookQuote,
    OrderbookQuoteForApy,
    PresetStrategyTerms,
    PresetTerms,
    PresetsFilter,
    RateMarketType,
    RatesHistoryParams,
    RawMarketStratResponse,
    RawQuote,
    RefinanceInfoParams,
    RefinanceInfoResponse
} from "../types";

const NAPOLEON_PUBLIC_TAG = "Napoleon";

export const napoleonPublicApi = createApi({
    reducerPath: "napoleonPublicApi",
    baseQuery: napoleonPublicBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [NAPOLEON_PUBLIC_TAG],
    endpoints: (builder) => ({
        marketPresets: builder.query<PresetStrategyTerms[], void>({
            query() {
                return {
                    url: GET_MARKET_PRESETS_ROUTE,
                    method: "GET"
                };
            }
        }),
        strategyPresets: builder.query<Record<string, PresetTerms[]>, PresetsFilter>({
            query(body) {
                return {
                    url: GET_PRESETS_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        marketQuotes: builder.query<OrderbookQuote[], CollateralQuoteFilter>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: MARKET_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        marketQuotesForApy: builder.query<OrderbookQuoteForApy[], CollateralQuoteFilterForApy>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: MARKET_QUOTE_FOR_APY_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        custodianQuotes: builder.query<OrderbookQuote[], CustodianQuoteFilter>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: CUSTODIAN_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        refinanceInfo: builder.query<RefinanceInfoResponse, RefinanceInfoParams>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: ROUTE_GET_REFINANCE_INFO,
                    method: "POST",
                    body
                };
            }
        }),
        allMarketPrincipalStats: builder.query<MarketPrincipalStats[], void>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query() {
                return {
                    url: GET_ALL_MARKET_PRINCIPAL_STATS_ROUTE,
                    params: { force: true },
                    method: "GET"
                };
            }
        }),
        marketPrincipalStatsByMint: builder.query<MarketPrincipalStats, string>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(mint) {
                return {
                    url: `${GET_MARKET_PRINCIPAL_STATS_ROUTE}/${mint}`,
                    method: "GET"
                };
            }
        }),
        maxQuotes: builder.query<OfferQuote[], MaxQuoteRequest>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_MAX_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: convertRawQuote
        }),
        bestQuotes: builder.query<BestQuote[], BestQuoteFilter>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_BEST_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: convertRawBestQuotes
        }),
        loopBestQuote: builder.query<BestLoopQuote | null, BestLoopQuoteQuery>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_BEST_LOOP_QUOTE_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        presetPrincipalByMints: builder.query<Record<string, number>, MinPrincipalFilter>({
            query(body) {
                return {
                    url: GET_PRESET_MIN_PRINCIPAL_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        borrowCaps: builder.query<BorrowCap[], string[]>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(args) {
                return {
                    url: GET_MARKET_BORROW_CAPS_ROUTE,
                    method: "POST",
                    body: args
                };
            }
        }),
        marketStats: builder.query<MarketStratResponse, MarketsFilter>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(args) {
                return {
                    url: GET_MARKET_STRATEGY_STATS_ROUTE,
                    method: "POST",
                    body: args
                };
            },
            transformResponse: (raw: RawMarketStratResponse) => convertToMarketStratResponse(raw)
        }),
        ratesHistory: builder.query<Record<RateMarketType, HistoricalMarketRate[]>, RatesHistoryParams>({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_RATE_HISTORY_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        loopVaultBorrowRateHistory: builder.query<
            HistoricalLoopVaultBorrowRate[],
            { loopVaultIdentifier: string; timeLookback: number }
        >({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query(body) {
                return {
                    url: GET_LOOP_VAULT_BORROW_RATE_HISTORY_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        collateralYieldHistory: builder.query<
            CollateralYieldHistory[],
            { startTime: number; endTime: number; collateralMint: string }
        >({
            providesTags: [NAPOLEON_PUBLIC_TAG],
            query({ collateralMint, ...params }) {
                return {
                    url: `${ROUTE_GET_COLLATERAL_YIELDS_IN_RANGE}/${collateralMint}`,
                    method: "GET",
                    params
                };
            }
        })
    })
});

export const {
    useStrategyPresetsQuery,
    useMarketStatsQuery,
    useMarketQuotesQuery,
    useMarketQuotesForApyQuery,
    useCustodianQuotesQuery,
    useAllMarketPrincipalStatsQuery,
    useMarketPrincipalStatsByMintQuery,
    useRefinanceInfoQuery,
    useMaxQuotesQuery,
    useBestQuotesQuery,
    useBorrowCapsQuery,
    usePresetPrincipalByMintsQuery,
    useRatesHistoryQuery,
    useCollateralYieldHistoryQuery,
    useLoopBestQuoteQuery,
    useLoopVaultBorrowRateHistoryQuery
} = napoleonPublicApi;

export const useNapoleonPublicApi = () => {
    const dispatch = useDispatch();
    const [fetchMarketStats] = napoleonPublicApi.endpoints.marketStats.useLazyQuery();

    return {
        fetchMarketStats,
        resetNapoleonPublicApi: () => dispatch(napoleonPublicApi.util.invalidateTags([NAPOLEON_PUBLIC_TAG]))
    };
};

function convertToMarketStratResponse(input: RawMarketStratResponse): MarketStratResponse {
    const result: MarketStratResponse = {};

    for (const key in input) {
        if (key in input) {
            const totalDepositsData = input[key];
            const durationToMinApy: DurationToMinApyItem[] = [];

            for (const durationKey in totalDepositsData.durationToMinApy) {
                if (durationKey in totalDepositsData.durationToMinApy) {
                    const { duration, durationType } = JSON.parse(durationKey);
                    const apy = totalDepositsData.durationToMinApy[durationKey];
                    durationToMinApy.push({ duration, durationType, apy });
                }
            }

            result[key] = {
                totalDeposits: totalDepositsData.totalDeposits,
                durationToMinApy: durationToMinApy
            };
        }
    }

    return result;
}

function convertRawQuote(raw: Record<string, RawQuote[]>) {
    return Object.entries(raw)
        .map(([collateralMint, quotes]) =>
            quotes.map(
                ([
                    apy,
                    lendingStrategyIdentifier,
                    assetTermsIdentifier,
                    principalAvailable,
                    ltv,
                    liquidationThreshold,
                    minPrice
                ]): OfferQuote => ({
                    apy,
                    lendingStrategyIdentifier,
                    assetTermsIdentifier,
                    principalAvailable,
                    ltv,
                    collateralMint,
                    liquidationThreshold,
                    minPrice
                })
            )
        )
        .flat();
}

function convertRawBestQuotes(raw: Record<string, Omit<BestQuote, "collateralMint">[]>) {
    return Object.entries(raw)
        .map(([collateralMint, quotes]) => quotes.map((bestQuote): BestQuote => ({ ...bestQuote, collateralMint })))
        .flat();
}
