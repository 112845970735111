import { Keypair, PublicKey } from "@solana/web3.js";

export function isNativeWallet(address: string | undefined) {
    if (!address) return false;
    return PublicKey.isOnCurve(address);
}

export function isPDA(address: string | undefined) {
    if (!address) return false;
    return !PublicKey.isOnCurve(address);
}

export function generateNonce() {
    return Keypair.generate().publicKey.toString();
}
