import { Column, QuestionToggleCards, Text } from "@bridgesplit/ui";

import { useLoopFaq } from "./faq";

export default function LoopFaq() {
    const questions = useLoopFaq();

    if (!questions) return null;
    return (
        <Column spacing={2}>
            <Text variant="h3">FAQ</Text>
            <QuestionToggleCards questions={questions} />
        </Column>
    );
}
