import { useMemo, useState } from "react";

import { StrategyExpanded, useBsPrincipalTokens } from "@bridgesplit/abf-react";
import {
    Column,
    EmptyPlaceholder,
    EmptyPlaceholderProps,
    LabelWrapper,
    MEDIA,
    MediaStack,
    Pagination,
    Select,
    Text,
    repeatElement,
    usePopover
} from "@bridgesplit/ui";
import { greaterThan } from "@bridgesplit/utils";
import { SwapVertOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { EmptyPromptType, useEmptyPrompts } from "app/utils";
import { COPY, PORTFOLIO_BASE } from "app/constants";
import { DispatchType } from "@bridgesplit/react";

import StrategyCard from "./StrategyCard";
import { StrategySort } from "./type";
import { FilterButtonPopover, TokenSelect } from "../../common";

const itemsPerPage = 6;
const BREAKPOINT = MEDIA.LG;
export default function StrategyCards({
    strategies: rawStrategies,
    defaultPrincipalMint
}: {
    strategies: StrategyExpanded[] | undefined;
    defaultPrincipalMint?: string;
}) {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(StrategySort.OfferBalance);
    const [principalMintState, setPrincipalMint] = useState<string | null | undefined>();
    const principalMint = principalMintState === undefined ? defaultPrincipalMint : principalMintState;
    const strategies = useMemo(
        () =>
            rawStrategies
                ?.filter(({ principalMetadata }) =>
                    principalMint ? principalMetadata.assetMint === principalMint : true
                )
                .sort((a, b) => {
                    switch (sort) {
                        case StrategySort.OfferBalance:
                            return (
                                (b.totalBalance + b.loanStats.principalDeployed) * b.principalUsdPrice -
                                (a.totalBalance + a.loanStats.principalDeployed) * a.principalUsdPrice
                            );
                        case StrategySort.RecentlyCreated:
                            return b.strategy.createdAt - a.strategy.createdAt;

                        default:
                            return 0;
                    }
                }),
        [principalMint, rawStrategies, sort]
    );

    const startIndex = page * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const data = strategies?.slice(startIndex, endIndex);

    return (
        <Column spacing={2}>
            <MediaStack query={BREAKPOINT} spaceBetweenRow spacing={1}>
                <Filters principalMint={principalMint} setPrincipalMint={setPrincipalMint} />

                <Select
                    selectProps={{
                        startAdornment: (
                            <Text color="disabled">
                                <SwapVertOutlined />
                            </Text>
                        )
                    }}
                    sx={{ [BREAKPOINT.above]: { maxWidth: "200px" } }}
                    value={sort}
                    setValue={(sort) => {
                        setPage(0);
                        setSort(sort);
                    }}
                    options={Object.values(StrategySort).map((value) => ({ value }))}
                />
            </MediaStack>

            {data?.length === 0 && (
                <Empty
                    emptyText={(() => {
                        if (!rawStrategies?.length)
                            return `You don't have any ${COPY.STRATEGY_TERM_PLURAL.toLowerCase()}`;
                        return `No matching ${COPY.STRATEGY_TERM_PLURAL.toLowerCase()}`;
                    })()}
                />
            )}
            {data
                ? data.map((strategy) => (
                      <StrategyCard query={BREAKPOINT} key={strategy.strategy.identifier} strategyExpanded={strategy} />
                  ))
                : repeatElement(<StrategyCard query={BREAKPOINT} strategyExpanded={undefined} />, 3)}

            {greaterThan(strategies?.length, itemsPerPage) && (
                <Pagination count={strategies?.length ?? 0} rowsPerPage={itemsPerPage} page={page} setPage={setPage} />
            )}
        </Column>
    );
}

function Filters({
    principalMint,
    setPrincipalMint
}: {
    principalMint: string | null | undefined;
    setPrincipalMint: DispatchType<string | null | undefined>;
}) {
    const { tokens } = useBsPrincipalTokens();
    const popoverProps = usePopover();

    return (
        <FilterButtonPopover reset={() => setPrincipalMint(null)} popoverProps={popoverProps}>
            <LabelWrapper label="Token">
                <TokenSelect tokens={tokens} value={principalMint ?? undefined} setValue={setPrincipalMint} />
            </LabelWrapper>
        </FilterButtonPopover>
    );
}

function Empty({ emptyText }: { emptyText: string }) {
    const getPrompt = useEmptyPrompts();
    const prompt = getPrompt(EmptyPromptType.Lend, emptyText);

    const location = useLocation();
    const promptWithCta: EmptyPlaceholderProps = {
        ...prompt,
        cta: location.pathname.includes(PORTFOLIO_BASE) ? prompt.cta : undefined
    };

    return <EmptyPlaceholder {...promptWithCta} />;
}
