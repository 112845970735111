import { Transaction } from "@solana/web3.js";
import { AxiosInstance, AxiosResponse } from "axios";

import { SerializedTransactionsWithSetup, TransactionsWithSetup } from "../../types";
import { removeDuplicates } from "../primitives";
import { base64 } from "../bytes";

export function deserializeLegacyTransaction(serializedTransaction: string): Transaction {
    const transaction = Transaction.from(base64.decode(serializedTransaction));
    return transaction;
}

export function serializeAndEncodeLegacyTransaction(transaction: Transaction) {
    const txnBytes = transaction.serialize();
    return base64.encode(txnBytes);
}

export async function fetchLegacyTransaction<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<string> {
    const response = await api.post<BodyType, AxiosResponse<string>>(url, data);
    return response.data;
}

export async function fetchLegacyTransactions<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<string[]> {
    const response = await api.post<BodyType, AxiosResponse<string[]>>(url, data);
    return response.data;
}

export async function fetchLegacyTransactionsWithSetup<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<SerializedTransactionsWithSetup> {
    const response = await api.post<BodyType, AxiosResponse<SerializedTransactionsWithSetup>>(url, data);
    return response.data;
}

export async function fetchAndDeserializeLegacyTransaction<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<Transaction> {
    const serializedTxn = await fetchLegacyTransaction<BodyType>(api, url, data);

    return deserializeLegacyTransaction(serializedTxn);
}

export async function fetchAndDeserializeLegacyTransactions<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<Transaction[]> {
    const serializedTxns = await fetchLegacyTransactions(api, url, data);

    const uniqueTxns = removeDuplicates(serializedTxns);
    return uniqueTxns.map((txn) => deserializeLegacyTransaction(txn));
}

export async function fetchAndDeserializeLegacyTransactionsWithSetup<BodyType>(
    api: AxiosInstance,
    url: string,
    data?: BodyType
): Promise<TransactionsWithSetup> {
    const { setupTransactions, transactions } = await fetchLegacyTransactionsWithSetup(api, url, data);

    const uniqueSetupTxns = removeDuplicates(setupTransactions);

    const uniqueTxns = removeDuplicates(transactions);
    return {
        setupTransactions: uniqueSetupTxns.map((txn) => deserializeLegacyTransaction(txn)),
        transactions: uniqueTxns.map((txn) => deserializeLegacyTransaction(txn))
    };
}
