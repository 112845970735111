import {
    BsMetaUtil,
    DepositParams,
    EscrowTransferParams,
    StrategyExpanded,
    WithdrawParams,
    useActiveEscrow,
    useActiveGroup,
    useActiveWallet,
    useEscrowDepositTransaction,
    useEscrowTransferTransaction,
    useEscrowWithdrawTransaction
} from "@bridgesplit/abf-react";
import { DEFAULT_PUBLIC_KEY, MISSING_PARAM_ERROR, Result } from "@bridgesplit/utils";
import { TrackTransactionEvent } from "app/types";

import { useTransactionSender } from "../../transactions";

export function useStrategyDeposit() {
    const send = useTransactionSender();
    const deposit = useEscrowDepositTransaction();
    const { groupIdentifier } = useActiveGroup();
    const { activeEscrow } = useActiveEscrow();

    const transfer = useEscrowTransferTransaction();

    return async ({
        amount,
        strategy,
        escrowNeeded
    }: {
        amount: number | undefined;
        strategy: StrategyExpanded | undefined;
        escrowNeeded: boolean;
    }) => {
        if (!strategy || !amount || !groupIdentifier || !activeEscrow)
            return Result.errFromMessage(MISSING_PARAM_ERROR);

        if (!strategy.escrowAccount) {
            return Result.errFromMessage("Escrow account not found");
        }

        const mint = strategy?.strategy.principalMint;

        const description = `Depositing ${BsMetaUtil.formatAmount(strategy.principalMetadata, amount)}`;
        if (escrowNeeded) {
            const params: EscrowTransferParams = [
                {
                    mint,
                    uiAmount: amount,
                    senderEscrow: activeEscrow,
                    recipientEscrowNonce: strategy.strategy.identifier
                }
            ];

            return await send(transfer, params, {
                description,
                mixpanelEvent: { key: TrackTransactionEvent.EscrowTransfer, params }
            });
        }

        const params: DepositParams = [
            {
                mint: mint,
                amount: amount,
                escrowNonce: strategy.strategy.identifier,
                organizationIdentifier: groupIdentifier
            }
        ];

        return await send(deposit, params, {
            description,
            mixpanelEvent: { key: TrackTransactionEvent.SubmitStrategyDeposit, params }
        });
    };
}

export function useStrategyWithdraw() {
    const send = useTransactionSender();

    const withdrawTx = useEscrowWithdrawTransaction();

    const { activeWallet } = useActiveWallet();
    const transfer = useEscrowTransferTransaction();
    const { groupIdentifier } = useActiveGroup();

    return async ({
        amount,
        strategy,
        escrowNeeded,
        withdrawAll
    }: {
        amount: number | undefined;
        strategy: StrategyExpanded | undefined;
        escrowNeeded: boolean;
        withdrawAll: boolean;
    }) => {
        if (!strategy || !amount || !activeWallet || !strategy.escrowAccount || !groupIdentifier)
            return Result.errFromMessage(MISSING_PARAM_ERROR);

        const mint = strategy?.strategy.principalMint;

        const description = `Withdrawing ${BsMetaUtil.formatAmount(strategy.principalMetadata, amount)}`;

        if (escrowNeeded) {
            const params: EscrowTransferParams = [
                {
                    mint,
                    uiAmount: amount,
                    senderEscrow: strategy.strategy.escrow,
                    recipientEscrowNonce: DEFAULT_PUBLIC_KEY
                }
            ];

            return await send(transfer, params, {
                description,
                mixpanelEvent: { key: TrackTransactionEvent.EscrowWithdraw, params }
            });
        }

        const withdrawParams: WithdrawParams = [
            {
                mint: mint,
                amount: amount,
                escrowAccount: strategy.strategy.escrow,
                escrowNonce: strategy.strategy.identifier,
                organizationIdentifier: groupIdentifier,
                receiver: activeWallet.wallet,
                withdrawAll
            }
        ];
        return await send(withdrawTx, withdrawParams, {
            description,
            mixpanelEvent: { key: TrackTransactionEvent.SubmitStrategyWithdraw, params: withdrawParams }
        });
    };
}
