import { Transaction, VersionedTransaction } from "@solana/web3.js";

export interface TransactionState {
    identifier: string;
    status: TransactionStatus;
    signature: string;
}

export enum TransactionStatus {
    Rejected,
    Confirmed,
    Signed,
    Error
}

export enum SignatureType {
    Register = "Create account",
    Login = "Login",
    CreatePat = "Generate signature"
}

export interface TransactionInstructionResponse {
    ///sPID
    programId: string;
    ///accounts for ix
    keys: InstructionAccountMeta[];
    /// data
    data: number[];
}

export interface InstructionAccountMeta {
    /// An account's public key.
    pubkey: string;
    /// True if an `Instruction` requires a `Transaction` signature matching `pubkey`.
    isSigner: boolean;
    /// True if the account data or metadata may be mutated during program execution.
    isWritable: boolean;
}

export type SendableTransaction = VersionedTransaction | Transaction;

export interface SerializedVersionedTransaction {
    message: string;
    signatures: Uint8Array[];
}

export interface VersionedTransactionSignature {
    signature: string;
    publicKey: string;
}

// versioned transaction return from apis
export interface VersionedTransactionResponse {
    message: string;
    signatures: VersionedTransactionSignature[];
}

export class Web3WalletSignature {
    user: string;
    txn_data: string;

    constructor(user: string, txn_data: string) {
        this.user = user;
        this.txn_data = txn_data;
    }
    toString(): string {
        return ' {"WalletSign": {"user":"' + this.user + '", "txn_data":"' + this.txn_data + '"}}';
    }
}

export interface SerializedTransactionsWithSetup {
    setupTransactions: string[];
    transactions: string[];
}

export type TransactionsWithSetup = SendableTransactionsWithSetup;

export interface SendableTransactionsWithSetup {
    setupTransactions: SendableTransaction[];
    transactions: SendableTransaction[];
}

export interface SerializedVersionedTransactionsWithSetup {
    setupTransactions: VersionedTransactionResponse[];
    transactions: VersionedTransactionResponse[];
}

export type VersionedTransactionsWithSetup = SendableTransactionsWithSetup;

// Transaction generation type. Determines whether to use priority fees or JIT optimization (Jito).
export enum TransactionGenerationType {
    Priority = 0, // Default transaction built with priority fees
    Jito = 1 // JIT optimization transaction
}

export function isJito(txnGenType: TransactionGenerationType): boolean {
    return TransactionGenerationType.Jito === txnGenType;
}

export type TransactionsWithDataGenerateReturn<T extends object> = { transactions: SendableTransaction[] } & T;
