import { ReactNode, useMemo } from "react";

import {
    Button,
    Column,
    FONT_SIZES,
    Icon,
    IconButton,
    Image,
    ImageProps,
    PopoverProps,
    PopoverWrapper,
    Row,
    SPACING,
    Span,
    Text,
    useAppPalette,
    usePopover
} from "@bridgesplit/ui";
import { Drawer, drawerClasses, useMediaQuery } from "@mui/material";
import { getAvatarFromName, getFirstName, useAuth, useUserProfile } from "@bridgesplit/abf-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { formatAddress } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";
import { COPY } from "app/constants";
import { useUserPublicKey } from "@bridgesplit/react";

import ProfileDropdown from "./ProfileDropdown";
import { NAV_BREAKPOINT, NAV_BUTTON_HEIGHT, NAV_ICON_BUTTON_SX } from "./constants";
import { PointsNested } from "./points";

export default function ProfileButton() {
    const { isAuthenticated, isLoading } = useAuth();

    if (!isAuthenticated || isLoading) {
        return <ConnectButton />;
    }

    return <Connected />;
}

function ConnectButton() {
    const { open } = useAppDialog();
    const { isLoading } = useAuth();

    if (isLoading) {
        return (
            <Button height={NAV_BUTTON_HEIGHT} variant="outlined">
                Connecting...
            </Button>
        );
    }
    return (
        <Button height={NAV_BUTTON_HEIGHT} variant="outlined" onClick={() => open(AppDialog.Connect, undefined)}>
            <Icon type="connect" /> {COPY.CONNECT_PROMPT}
        </Button>
    );
}

function Wrapper({ children, ...props }: PopoverProps & { children: ReactNode }) {
    const isMobile = useMediaQuery(NAV_BREAKPOINT.below);
    const { paperBackground } = useAppPalette();

    if (isMobile) {
        return (
            <Drawer
                anchor="right"
                open={!!props.anchorEl}
                sx={{ [`.${drawerClasses.paper}`]: { background: paperBackground, width: "70vw", maxWidth: "300px" } }}
                onClose={props.close}
            >
                <Row spaceBetween sx={{ p: 2, pb: 0 }}>
                    <Row spacing={1}>
                        <Text>
                            <UserInfo />
                        </Text>
                    </Row>

                    <IconButton border={false} icon="reject" onClick={props.close} />
                </Row>
                <PointsNested />
                {children}
            </Drawer>
        );
    }

    return (
        <PopoverWrapper
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ horizontal: "right", vertical: -SPACING }}
            sx={{ maxHeight: "90vh", width: "300px", overflowY: "auto" }}
            {...props}
        >
            <Column>{children}</Column>
        </PopoverWrapper>
    );
}

function Connected() {
    const { popoverProps, close, open } = usePopover();
    const { image, imageVariant } = useUserInfo();
    const isMobile = useMediaQuery(NAV_BREAKPOINT.below);

    return (
        <>
            <Wrapper {...popoverProps}>
                <ProfileDropdown close={close} />
            </Wrapper>
            {(() => {
                if (isMobile) {
                    return (
                        <IconButton sx={NAV_ICON_BUTTON_SX} border onClick={open}>
                            <Image size={FONT_SIZES.body1 + "px"} variant={imageVariant} src={image} />
                        </IconButton>
                    );
                }

                return (
                    <Button height={NAV_BUTTON_HEIGHT} variant="outlined" onClick={open}>
                        <UserInfo />
                    </Button>
                );
            })()}
        </>
    );
}

function UserInfo() {
    const { name, image, imageVariant } = useUserInfo();

    return (
        <>
            {image && <Image size={FONT_SIZES.body1 + "px"} variant={imageVariant} src={image} />}
            <Span>{name}</Span>
        </>
    );
}

function useUserInfo() {
    const { user } = useUserProfile();
    const { wallet } = useWallet();
    const publicKey = useUserPublicKey();
    const { isWalletBased, isEmailBased } = useAuth();
    const image = useMemo(() => {
        if (isWalletBased) return wallet?.adapter.icon;
        if (!user) return undefined;
        if (user.avatar) return user.avatar;
        return getAvatarFromName(user.name);
    }, [isWalletBased, user, wallet?.adapter.icon]);

    const name = useMemo(() => {
        if (isWalletBased) {
            return formatAddress(publicKey);
        }

        return getFirstName(user);
    }, [isWalletBased, publicKey, user]);

    const imageVariant: ImageProps["variant"] = isWalletBased ? "square" : "circle";

    return { name, image, imageVariant, isEmailBased };
}
