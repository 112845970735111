import { RoleSetupArgs, getUpdateRolesTransactions } from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { Result } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import {
    AbfGeneratorResult,
    AbfTransactionDetails,
    TransactionSenderOptions,
    TransactionMessageOverrides
} from "../types";
import { useAbfGenerateTransaction } from "./common";

export function useUpdateRolesTransaction(type: "fixOwner" | "roleUpdate"): AbfTransactionDetails<RoleSetupArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetMeApi, resetGroupAdminApi } = useAbfFetches();

    async function getTransactionsWithParams(params: RoleSetupArgs): AbfGeneratorResult {
        try {
            const transactions = generate({
                generateFunction: getUpdateRolesTransactions,
                params,
                identifier: type === "fixOwner" ? "Finalize Account" : "Finalize Role"
            });
            return await combineTransactionPromises([transactions], { order: "parallel" });
        } catch (error) {
            return Result.err(error);
        }
    }
    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetMeApi();
            resetGroupAdminApi();
        }
    };

    const messages: TransactionMessageOverrides = {
        loadingMessage: type === "fixOwner" ? "Finalizing your account details" : "Confirming roles",
        onSuccess: type === "fixOwner" ? "Account finalized" : "All roles confirmed",
        onError: type === "fixOwner" ? "Unable to finalize account. Please try again" : "Unable to finalize roles"
    };

    return {
        getTransactionsWithParams,
        sendOptions,
        description: type === "fixOwner" ? "Finalizing account" : "Finalizing roles",
        messages
    };
}
