import { ChainId } from "@bridgesplit/abf-sdk";
import {
    LOGO_URL,
    BackCta,
    Column,
    ConnectionImages,
    IconBenefits,
    SpanBold,
    Text,
    TextNestedLink
} from "@bridgesplit/ui";
import { LockOutlined, SendOutlined, VerifiedOutlined } from "@mui/icons-material";
import { WORMHOLE_URL } from "app/constants";

import { AppButton, CHAIN_METADATA } from "../../common";
import { useCreateEvmWallet } from "../util";

export default function RegisterNewChain({
    chain,
    back,
    onSuccess
}: {
    chain: ChainId;
    back: () => void;
    onSuccess?: () => void;
}) {
    const meta = CHAIN_METADATA[chain];
    const createEvm = useCreateEvmWallet();
    return (
        <>
            <Column textAlign="center" alignItems="center" spacing={2}>
                <ConnectionImages size={40} images={[LOGO_URL, meta.logo]} />
                <Text variant="h2" fontWeight="normal" sx={{ gap: 0.5 }}>
                    Set Up <SpanBold> {meta.name} </SpanBold> Deposits
                </Text>
            </Column>
            <IconBenefits
                benefits={[
                    {
                        icon: <SendOutlined />,
                        text: (
                            <>
                                Bridge assets from Polygon to Loopscale using
                                <TextNestedLink onClick={() => window.open(WORMHOLE_URL)} sx={{ mx: 0.5 }}>
                                    Wormhole
                                </TextNestedLink>
                            </>
                        )
                    },
                    {
                        icon: <LockOutlined />,
                        text: `Your original assets are secured in a ${meta.name} smart contract, then re-minted on Solana`
                    },
                    {
                        icon: <VerifiedOutlined />,
                        text: `You can always exchange Loopscale wrapped assets for the originals on ${meta.name}`
                    }
                ]}
            />
            <Column spacing={1}>
                <AppButton
                    isTransaction
                    asyncCta={{
                        onClickWithResult: async () => {
                            const res = await createEvm(chain);
                            if (res.isOk()) {
                                onSuccess?.();
                            }

                            return res;
                        },
                        options: { alertOnError: true, onSuccessMessage: `Registered for ${meta.name} deposits` }
                    }}
                >
                    Set up
                </AppButton>
                <BackCta back={back} />
            </Column>
        </>
    );
}
