import { ReactNode } from "react";

import { ConnectionProvider, WalletProvider as BaseWalletProvider } from "@solana/wallet-adapter-react";

export default function WalletProvider({ children, connectionUrl }: { children: ReactNode; connectionUrl: string }) {
    return (
        <ConnectionProvider endpoint={connectionUrl}>
            <BaseWalletProvider autoConnect wallets={[]}>
                {children}
            </BaseWalletProvider>
        </ConnectionProvider>
    );
}
