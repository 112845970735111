import { lazy } from "@bridgesplit/ui";

import PrimeWelcomeDialog from "./PrimeWelcomeDialog";
import WalletSetupDialog from "./wallet-setup/WalletSetupDialog";

const AuthenticationAlerts = lazy(() => import("./AuthenticationAlerts"));
const CustodianInviteCode = lazy(() => import("./CustodianInviteCode"));

const SignUp = lazy(() => import("./SignUp"));
const DefaultRoute = lazy(() => import("./DefaultRoute"));

export { AuthenticationAlerts, CustodianInviteCode, WalletSetupDialog, SignUp, DefaultRoute, PrimeWelcomeDialog };

export * from "./dialog";
export * from "./util";
export * from "./unverified";
export * from "./routes";
