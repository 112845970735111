import { lazy } from "@bridgesplit/ui";

import LendDialog from "./LendDialog";
import BorrowDialog from "./BorrowDialog";
import MarketGuideDialog from "./guide/MarketGuideDialog";

const MarketDetail = lazy(() => import("./MarketDetail"));

const CustodianDetail = lazy(() => import("./CustodianDetail"));

export { MarketDetail, CustodianDetail, LendDialog, MarketGuideDialog, BorrowDialog };

export * from "./dialog";
export * from "./borrow";
