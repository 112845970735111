import { useState } from "react";

import { useCreateSphereBusinessCustomerMutation } from "@bridgesplit/abf-react";
import { DEFAULT_COUNTRY_CODE, DEFAULT_ISO3166_VALUE, DispatchType } from "@bridgesplit/react";
import {
    DOB_PATTERN,
    DialogWrapper,
    FormInput,
    Icon,
    IconButton,
    OutlinedCard,
    Row,
    TIN_PATTERN,
    Text,
    TextButton,
    VerticalScrollContainer,
    emailFormAdornments,
    parseDOBFromPatternString,
    parsePhoneNumberIntoE164
} from "@bridgesplit/ui";
import {
    EMAIL_REGEX,
    MISSING_PARAM_ERROR,
    NullableRecord,
    Result,
    allKeysDefined,
    filterNullableRecord,
    getReadableErrorMessage,
    generateNonce
} from "@bridgesplit/utils";
import { Person2Outlined } from "@mui/icons-material";
import { UBOInfo } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { AddressInput, DialogHeader, PhoneNumberInput, REQUIRED_ADDRESS_FIELDS } from "../../common";
import { RegisterCta, SphereIntroWrapper } from "./common";
import { useSphereRefresh, validateSphereAddress } from "../util";

type UBOInput = NullableRecord<UBOInfo> & { nonce: string };
type Form = {
    name?: string;
    email?: string;
    ein?: string;
    website?: string;
    phoneNumber: string | undefined;
    incorporationDate?: string;
    address: AddressInput;
    countryCode: string;
    ubos: UBOInput[];
};
enum Step {
    BasicInformation,
    Address,
    UBOs
}
const STEP_COUNT = Object.values(Step).length / 2;
const REQUIRED_FIELDS: (keyof Form)[] = ["name", "ein", "phoneNumber", "email", "website"];

type FormProps = { form: Form; setForm: DispatchType<Form>; step: Step; setStep: DispatchType<Step> };
export default function RegisterBusinessSphereDialog() {
    const [step, setStep] = useState<Step>(0);
    const [form, setForm] = useState<Form>({
        countryCode: DEFAULT_COUNTRY_CODE.dialCode,
        address: { country: DEFAULT_ISO3166_VALUE },
        phoneNumber: undefined,
        ubos: [addUbo()]
    });
    const props: FormProps = { form, setForm, step, setStep };

    return (
        <SphereIntroWrapper>
            <DialogWrapper>
                {(() => {
                    switch (step) {
                        case Step.BasicInformation:
                            return <BasicInformation {...props} />;
                        case Step.Address:
                            return <Address {...props} />;
                        case Step.UBOs:
                            return <UBOs {...props} />;
                        default:
                            return null;
                    }
                })()}
            </DialogWrapper>
        </SphereIntroWrapper>
    );
}

function BasicInformation(props: FormProps) {
    const { form, setForm } = props;
    return (
        <>
            <DialogHeader
                header={AppDialog.RegisterBusinessSphere}
                description="Sphere requires the following information to register"
            />

            <FormInput
                label="Business name"
                placeholder="Legal name of your business"
                value={form.name}
                setValue={(name) => setForm((prev) => ({ ...prev, name }))}
                variant="string"
            />
            <FormInput
                InputProps={emailFormAdornments({
                    showIcon: false,
                    isValidEmail: !form.email || EMAIL_REGEX.test(form.email)
                })}
                label="Business email"
                value={form.email}
                placeholder="example@domain.com"
                setValue={(email) => setForm((prev) => ({ ...prev, email }))}
                variant="string"
            />
            <FormInput
                label="Website"
                value={form.website}
                placeholder="https://www.domain.com/"
                setValue={(website) => setForm((prev) => ({ ...prev, website }))}
                variant="string"
            />
            <PhoneNumberInput form={form} setForm={setForm} />
            <FormInput
                placeholder="01/01/2000"
                label="Incorporation date"
                value={form.incorporationDate}
                pattern={{ format: DOB_PATTERN }}
                setValue={(incorporationDate) => setForm((prev) => ({ ...prev, incorporationDate }))}
                variant="string"
            />
            <FormInput
                label="Employer identification number (EIN)"
                placeholder="Nine digit identifier from the IRS"
                pattern={{ format: TIN_PATTERN }}
                value={form.ein}
                setValue={(ein) => setForm((prev) => ({ ...prev, ein }))}
                variant="string"
            />
            <Cta disabled={!allKeysDefined(form, REQUIRED_FIELDS)} {...props} />
        </>
    );
}

function Address(props: FormProps) {
    const { form, setForm } = props;

    return (
        <>
            <DialogHeader header={AppDialog.RegisterBusinessSphere} description="Enter your business address" />
            <AddressInput input={form.address} setInput={(address) => setForm((prev) => ({ ...prev, address }))} />
            <Cta disabled={!allKeysDefined(form.address, REQUIRED_ADDRESS_FIELDS)} {...props} />
        </>
    );
}

function UBOs(props: FormProps) {
    const { form, setForm } = props;
    const [focused, setFocused] = useState(0);

    const validUbos = parseUbos(form.ubos);

    function update(nonce: string, update: Partial<UBOInput>) {
        setForm((prev) => ({
            ...prev,
            ubos: prev.ubos.map((ubo) => (ubo.nonce === nonce ? { ...ubo, ...update } : ubo))
        }));
    }

    return (
        <>
            <DialogHeader
                header={AppDialog.RegisterBusinessSphere}
                description="Enter the information for the Ultimate Beneficial Owners (UBOs) of your business. Each UBO will need to complete identity verification through Stripe"
            />
            <VerticalScrollContainer isOverflowing={form.ubos.length > 5} spacing={1} maxHeight={500}>
                {form.ubos.map(({ nonce, fullName, email }, i) => (
                    <OutlinedCard padding={1.5} key={nonce} spacing={1}>
                        {focused === i ? (
                            <>
                                <Row spaceBetween>
                                    <Text>
                                        <Person2Outlined /> UBO {i + 1}
                                    </Text>
                                    {form.ubos.length > 1 && (
                                        <IconButton
                                            onClick={() => {
                                                setForm((prev) => ({
                                                    ...prev,
                                                    ubos: prev.ubos.filter((ubo) => ubo.nonce !== nonce)
                                                }));
                                                setFocused(0);
                                            }}
                                            textVariant="body2"
                                            border={false}
                                            icon="delete"
                                        />
                                    )}
                                </Row>
                                <FormInput
                                    placeholder="Email of UBO"
                                    variant="string"
                                    value={email}
                                    setValue={(email) => update(nonce, { email })}
                                    InputProps={emailFormAdornments({
                                        isValidEmail: !email || EMAIL_REGEX.test(email),
                                        showIcon: false
                                    })}
                                    label="Email"
                                />
                                <FormInput
                                    placeholder="Full Legal Name"
                                    label="Name"
                                    variant="string"
                                    value={fullName}
                                    setValue={(fullName) => update(nonce, { fullName })}
                                />
                            </>
                        ) : (
                            <Row spacing={1}>
                                <Text variant="h4" color="caption" isLink onClick={() => setFocused(i)}>
                                    <Person2Outlined /> UBO {i + 1}
                                </Text>
                                {fullName && (
                                    <Text color="disabled" isLink onClick={() => setFocused(i)}>
                                        {fullName}
                                    </Text>
                                )}
                            </Row>
                        )}
                    </OutlinedCard>
                ))}
            </VerticalScrollContainer>

            <TextButton
                onClick={() => {
                    setForm((prev) => ({ ...prev, ubos: [...prev.ubos, addUbo()] }));
                    setFocused(form.ubos.length);
                }}
            >
                <Icon type="add" /> Add UBO
            </TextButton>
            <Cta disabled={!validUbos.length} {...props} />
        </>
    );
}

function Cta(props: FormProps & { disabled: boolean }) {
    const { form, step, setStep, disabled } = props;
    const [createBusiness] = useCreateSphereBusinessCustomerMutation();

    const { close } = useAppDialog();

    const refresh = useSphereRefresh();
    async function register() {
        const validForm = allKeysDefined(form, REQUIRED_FIELDS);
        const validAddress = allKeysDefined(form.address, REQUIRED_ADDRESS_FIELDS);
        const phoneNumber = parsePhoneNumberIntoE164(form);
        const incorporationDate = parseDOBFromPatternString(form.incorporationDate);
        const uboInfo = parseUbos(form.ubos);

        if (!incorporationDate.isOk()) return incorporationDate;

        if (!validForm || !validAddress) return Result.errFromMessage(MISSING_PARAM_ERROR);
        if (!uboInfo.length || uboInfo.length !== form.ubos.length)
            return Result.errFromMessage("Some UBOs are missing details");
        if (!phoneNumber.isOk()) return phoneNumber;

        const address = validateSphereAddress(form.address);
        if (!address.isOk()) return address;

        const formRequired = form as Required<Form>;

        const res = await createBusiness({
            idempotentKey: generateNonce(),
            businessName: formRequired.name,
            email: formRequired.email,
            ein: formRequired.ein.toString(),
            website: formRequired.website,
            phoneNumber: phoneNumber.unwrap(),
            address: address.unwrap(),
            uboInfo,
            incorporationDate: incorporationDate.unwrap()
        });
        if ("error" in res) return Result.errWithDebug(getReadableErrorMessage("register your business"), res);
        await refresh();
        close();

        return Result.ok();
    }

    return <RegisterCta stepCount={STEP_COUNT} step={step} setStep={setStep} disabled={disabled} register={register} />;
}

function addUbo(): UBOInput {
    return { nonce: generateNonce(), fullName: undefined, email: undefined };
}

function parseUbos(ubos: UBOInput[]) {
    return ubos.filter(filterNullableRecord);
}
