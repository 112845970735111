import { useCallback, useEffect, useMemo } from "react";

import { useBalanceChecker } from "app/components/common";
import { lamportsToUiAmount, Result, roundToDecimals, uiAmountToLamports } from "@bridgesplit/utils";
import BN from "bn.js";
import { useUserAvailableAssets } from "@bridgesplit/abf-react";
import { TokenPositionExpanded } from "@bridgesplit/abf-sdk";

import { useWhirlpoolContext } from "../WhirlpoolContext";
import { getSlippageTolerance, useWhirlpoolLtv } from "../util";
import { orca } from "../orca";
export function useDepositWhirlpoolBalances() {
    const {
        depositForm,
        whirlpoolPosition: { tokenA, tokenB },
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();
    const updateAmount = useUpdateDepositAmount();

    const { data } = useUserAvailableAssets({});

    const getBalance = useCallback(
        (token: TokenPositionExpanded) => {
            if (!data) return undefined;

            const userBalance = data.find((d) => d.mint === token.mint)?.amount ?? 0;

            const slippage = slippagePercentDecimals * userBalance;
            return roundToDecimals(userBalance - slippage, token.decimals);
        },
        [data, slippagePercentDecimals]
    );

    const A = useBalanceChecker({
        mint: tokenA.metadata.assetMint,
        amount: depositForm.tokenAAmount,
        customBalance: getBalance(tokenA),
        setMax: (max) => updateAmount(max, "A")
    });

    const B = useBalanceChecker({
        mint: tokenB.metadata.assetMint,
        customBalance: getBalance(tokenB),
        amount: depositForm.tokenBAmount,
        setMax: (max) => updateAmount(max, "B")
    });

    return { A, B };
}

export function useUpdateDepositAmount() {
    const { setDepositForm } = useWhirlpoolContext();

    return useCallback(
        (amount: number | undefined, side: "A" | "B") => {
            setDepositForm((prev) => {
                const tokenAmountKey = side === "A" ? "tokenAAmount" : "tokenBAmount";
                if (prev[tokenAmountKey] === amount) return prev;
                return { ...prev, [tokenAmountKey]: amount, side, status: "refetch-needed" };
            });
        },
        [setDepositForm]
    );
}

export function useOrcaDepositHooks() {
    useMaintainDepositQuote();
}

function useMaintainDepositQuote() {
    const {
        whirlpoolPosition,
        poolData,
        whirlpoolOffchain,
        depositForm,
        setDepositForm,
        setAddLiquidityQuote,
        slippageController: { slippagePercentDecimals }
    } = useWhirlpoolContext();

    const fetchYieldData = useCallback(async () => {
        try {
            if (!poolData || !whirlpoolOffchain) return;

            const { tokenA, tokenB } = whirlpoolPosition;

            const side = (() => {
                if (depositForm.side) return depositForm.side;

                // pick the side that has more balance if side is not set
                const percentOfAMax = (depositForm.tokenAAmount ?? 0) / tokenA.amount;
                const percentOfBMax = (depositForm.tokenBAmount ?? 0) / tokenB.amount;
                return percentOfAMax > percentOfBMax ? "A" : "B";
            })();
            const token = side === "A" ? tokenA : tokenB;
            const amount = side === "A" ? depositForm.tokenAAmount : depositForm.tokenBAmount;
            if (amount === undefined) return;

            setDepositForm((prev) => ({
                ...prev,
                status: prev.status === "silent-refetch" ? prev.status : "refetching"
            }));
            const quote = await orca.position.getAddLiquidityQuote({
                positionAddress: whirlpoolPosition.position.address,
                tokenMint: token.mint,
                tokenAmount: new BN(uiAmountToLamports(amount, token.decimals)),
                refresh: false,
                slippageTolerance: getSlippageTolerance(slippagePercentDecimals)
            });

            const tokenAAmount = lamportsToUiAmount(quote.estTokenA.toNumber(), tokenA.decimals);
            const tokenBAmount = lamportsToUiAmount(quote.estTokenB.toNumber(), tokenB.decimals);
            setDepositForm((prev) => ({
                ...prev,
                tokenAAmount: side === "A" ? prev.tokenAAmount : tokenAAmount,
                tokenBAmount: side === "B" ? prev.tokenBAmount : tokenBAmount,
                status: "updated"
            }));
            setAddLiquidityQuote(quote);
        } catch (error) {
            Result.err(error);
            setDepositForm((prev) => ({ ...prev, status: "error" }));
        }
    }, [
        depositForm.side,
        depositForm.tokenAAmount,
        depositForm.tokenBAmount,
        poolData,
        setAddLiquidityQuote,
        setDepositForm,
        slippagePercentDecimals,
        whirlpoolOffchain,
        whirlpoolPosition
    ]);

    useEffect(() => {
        if (depositForm.status && ["refetch-needed", "silent-refetch"].includes(depositForm.status)) {
            fetchYieldData();
        }
    }, [fetchYieldData, depositForm.status]);

    return { fetchYieldData };
}

export function useDepositLtv() {
    const wp = useWhirlpoolUsdValue();
    return useWhirlpoolLtv(wp?.positionUsdValue);
}

function useWhirlpoolUsdValue() {
    const {
        depositForm,
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();
    return useMemo(() => {
        if (!depositForm) return undefined;

        const tokenAUsd = ((depositForm?.tokenAAmount ?? 0) + tokenA.amount) * tokenA.usdPrice;
        const tokenBUsd = ((depositForm?.tokenBAmount ?? 0) + tokenB.amount) * tokenB.usdPrice;

        const positionUsdValue = tokenAUsd + tokenBUsd;
        return { positionUsdValue, tokenAUsd, tokenBUsd };
    }, [depositForm, tokenA, tokenB]);
}
