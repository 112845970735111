import { useMemo } from "react";

import { Column, TagWrapper, Text } from "@bridgesplit/ui";
import { BsMetaUtil, isOracleStale, useTurnOracleMutation } from "@bridgesplit/abf-react";
import { mutationIntoResult } from "@bridgesplit/react";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { OracleType } from "@bridgesplit/abf-sdk";

import { AppButton } from "../../common";
import { useMarketBorrowContext } from "./MarketBorrowContext";

export default function BorrowOracleUpdate() {
    const {
        activeUserCollateral,
        form: { collateralMint }
    } = useMarketBorrowContext();

    const symbol = BsMetaUtil.getSymbol(activeUserCollateral?.metadata);
    const [turnOracle] = useTurnOracleMutation();

    const staleOracle = useMemo(
        () => activeUserCollateral?.oracle?.baseQuotes.find((q) => isOracleStale(q)),
        [activeUserCollateral?.oracle?.baseQuotes]
    );

    async function crank() {
        if (!collateralMint || !staleOracle) return Result.errFromMessage(LOADING_ERROR);
        return mutationIntoResult(
            turnOracle,
            { quoteMint: staleOracle.quoteMint, baseMint: collateralMint },
            "update oracle"
        );
    }

    const priceRequestAllowed = staleOracle?.oracleType === OracleType.SwitchboardPull;

    if (!staleOracle || ![OracleType.SwitchboardPull, OracleType.Pyth].includes(staleOracle.oracleType)) return null;

    return (
        <TagWrapper color="warning">
            <Column spacing={2}>
                <Text color="caption" variant="body2">
                    You aren't able to start a loan due to a stale oracle price for {symbol}.
                    {priceRequestAllowed ? " Request a price refresh" : ""}
                </Text>

                {priceRequestAllowed && (
                    <AppButton
                        isTransaction={false}
                        asyncCta={{
                            onClickWithResult: crank,
                            options: {
                                loadingMessage: "Refreshing oracle",
                                alertOnError: true,
                                onSuccessMessage: {
                                    message: "Price update initiated",
                                    description: "Oracle will be ready within a couple minutes"
                                }
                            }
                        }}
                        fullWidth={false}
                        height={30}
                        textProps={{ variant: "body2" }}
                        variant="contained"
                    >
                        Refresh {symbol}
                    </AppButton>
                )}
            </Column>
        </TagWrapper>
    );
}
