import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { PaginatedData, TransactionAuthenticated } from "@bridgesplit/react";
import { ExternalYieldAccrued, WALLET_TXN_HEADER } from "@bridgesplit/abf-sdk";

import {
    ABF_GROUP_HEADER,
    CREATE_STRATEGY_FROM_PRESETS_ROUTE,
    GET_CUSTODIAN_MARKET_STATS_ROUTE,
    GET_LOAN_SALE_PRICE_AND_OFFER_ROUTE,
    PORTFOLIO_STATS_ROUTE,
    ROUTE_GET_EXTERNAL_YIELD_INTEREST,
    ROUTE_INCREASE_CREDIT_QUOTE
} from "../constants";
import { abfSerializeQueryArgs, napoleonBaseQuery } from "./util";
import {
    CreateStrategyFromPresetParams,
    CustodianExpanded,
    CustodianExpandedParams,
    DurationToMinApyItem,
    PortfolioStats,
    RawCustodianResponse,
    SellLoanQuote,
    TransferCreditQuote
} from "../types";
import { transformRawCustodian } from "./custodianApi";

const NAPOLEON_TAG = "Napoleon";

export const napoleonApi = createApi({
    reducerPath: "napoleonApi",
    baseQuery: napoleonBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [NAPOLEON_TAG],
    endpoints: (builder) => ({
        sellQuote: builder.query<SellLoanQuote, string>({
            providesTags: [NAPOLEON_TAG],
            query(loanAddress) {
                return {
                    url: GET_LOAN_SALE_PRICE_AND_OFFER_ROUTE,
                    method: "POST",
                    body: { loan: loanAddress }
                };
            }
        }),
        custodiansWithStats: builder.query<PaginatedData<CustodianExpanded[]>, CustodianExpandedParams>({
            providesTags: [NAPOLEON_TAG],
            query(body) {
                return {
                    url: GET_CUSTODIAN_MARKET_STATS_ROUTE,
                    method: "POST",
                    body: { ...body, pageSize: body.page_size }
                };
            },
            transformResponse: ({
                data,
                ...rest
            }: PaginatedData<{ custodian: RawCustodianResponse; durationToMinApy: Record<string, number> }[]>) => {
                return {
                    data: data.map((r) => {
                        const durationToMinApy = Object.entries(r.durationToMinApy).map(
                            ([key, apy]): DurationToMinApyItem => {
                                const { duration, durationType } = JSON.parse(key);
                                return { duration, durationType, apy };
                            }
                        );

                        return { custodian: transformRawCustodian(r.custodian), durationToMinApy };
                    }),
                    ...rest
                };
            }
        }),
        portfolioStats: builder.query<PortfolioStats, { escrows: string[]; groupIdentifier: string }>({
            providesTags: [NAPOLEON_TAG],
            query(params) {
                return {
                    url: PORTFOLIO_STATS_ROUTE,
                    method: "POST",
                    body: params.escrows,
                    headers: { [ABF_GROUP_HEADER]: params.groupIdentifier }
                };
            }
        }),
        createStrategyFromPresets: builder.mutation<string, TransactionAuthenticated<CreateStrategyFromPresetParams>>({
            invalidatesTags: [NAPOLEON_TAG],
            query({ signedTransaction, ...args }) {
                return {
                    url: CREATE_STRATEGY_FROM_PRESETS_ROUTE,
                    method: "POST",
                    body: args,
                    headers: { [WALLET_TXN_HEADER]: signedTransaction }
                };
            }
        }),
        increasePrincipalQuote: builder.query<TransferCreditQuote, string>({
            providesTags: [NAPOLEON_TAG],
            query(args) {
                return {
                    url: `${ROUTE_INCREASE_CREDIT_QUOTE}/${args}`,
                    method: "GET"
                };
            }
        }),
        externalYieldInterest: builder.query<
            { [escrowAccount: string]: ExternalYieldAccrued[] },
            { escrows: string[] }
        >({
            query(body) {
                return {
                    url: ROUTE_GET_EXTERNAL_YIELD_INTEREST,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {
    useSellQuoteQuery,
    useCustodiansWithStatsQuery,
    useCreateStrategyFromPresetsMutation,
    useIncreasePrincipalQuoteQuery,
    usePortfolioStatsQuery,
    useExternalYieldInterestQuery
} = napoleonApi;

export const useNapoleonApi = () => {
    const dispatch = useDispatch();

    return {
        resetNapoleonApi: () => dispatch(napoleonApi.util.invalidateTags([NAPOLEON_TAG]))
    };
};
