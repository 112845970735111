import { useUserPoints, useUserWhitelistRank } from "@bridgesplit/abf-react";
import { Column, Icon, Row, Text, TextSkeleton } from "@bridgesplit/ui";
import { REWARDS_SLUG } from "app/constants";
import { useAppNavigate } from "@bridgesplit/react";
import { AppDialog, useAppDialog } from "app/utils";

import { Points } from "../navbar/points";
import { AppButton } from "../common";
import { AccessStat } from "./common";

interface AccessWaitlistProps {
    onClose?: () => void;
    showNotifyButton?: boolean;
}

export default function AccessWaitlistContent({ onClose, showNotifyButton = false }: AccessWaitlistProps): JSX.Element {
    const whitelistStats = useAccessWaitlistStat();
    const navigate = useAppNavigate();

    const { open } = useAppDialog();
    function handleNavigation(): void {
        navigate(REWARDS_SLUG);
        onClose?.();
    }

    return (
        <Column spacing={3} alignItems="center">
            <Text svgColor="secondary">
                <Icon type="logo-icon" sx={{ width: "60px", height: "60px" }} />
            </Text>
            <Column alignItems="center" spacing={0.5}>
                <Text variant="h3">You're on the waitlist!</Text>
                <Text variant="body1" color="caption">
                    Move up by earning points and referring friends
                </Text>
            </Column>
            <Row sx={{ width: "100%", justifyContent: "space-evenly" }}>
                {whitelistStats.map((stat, index) => (
                    <AccessStat key={index} {...stat} />
                ))}
            </Row>
            <Column spacing={0.5} width="100%">
                {showNotifyButton && (
                    <AppButton
                        overrideAccess
                        isTransaction={false}
                        variant="outlined"
                        onClick={() => open(AppDialog.Notifications, undefined)}
                    >
                        <Icon type="notification" />
                        Notify me
                    </AppButton>
                )}
                <AppButton overrideAccess onClick={handleNavigation} color="secondary" isTransaction={false}>
                    <Icon type="add" />
                    Earn points
                </AppButton>
            </Column>
        </Column>
    );
}

function useAccessWaitlistStat(): AccessStat[] {
    const { data: whitelist, isLoading } = useUserWhitelistRank();
    const { getPointsAtTime } = useUserPoints();

    const waitlistStats: AccessStat[] = [
        {
            value: (
                <Row spacing={1}>
                    <Text color="secondary">
                        <Icon type="points" />
                    </Text>
                    <Text loading={isLoading}>
                        <Points getPoints={getPointsAtTime} />
                    </Text>
                </Row>
            ),
            caption: "Your points"
        },
        {
            value: whitelist?.userRank ? (
                <Text>#{whitelist?.userRank.toLocaleString()}</Text>
            ) : (
                <TextSkeleton variant="h3" width="50px" />
            ),
            caption: "Your position"
        }
    ];

    return waitlistStats;
}
