import { useState } from "react";

import { BsMetaUtil, StrategyExpanded } from "@bridgesplit/abf-react";
import { Row, ShadowCard, Column, Button, MediaQuery, Text, ToggleChevron, TooltipText } from "@bridgesplit/ui";
import { Collapse, useMediaQuery } from "@mui/material";
import { bsMath, formatUsd } from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { StrategyRateAndRewardsBreakdown } from "../../points";
import { TokenImage } from "../../common";
import StrategyStats from "./StrategyCardStats";
import StrategyCardActions from "./StrategyCardActions";

export default function StrategyCard({
    strategyExpanded,
    query
}: {
    strategyExpanded: StrategyExpanded | undefined;
    query: MediaQuery;
}) {
    const [expandedState, setExpanded] = useState(false);
    const { open } = useAppDialog();
    const isMobile = useMediaQuery(query.below);
    const expanded = expandedState || isMobile;

    const totalPosition = bsMath.add(
        strategyExpanded?.totalBalance,
        strategyExpanded?.loanStats.principalDeployed,
        strategyExpanded?.loanStats.interestAccrued
    );

    const isInactive = strategyExpanded?.escrowAccount?.originationCap === 0 ? true : false;

    return (
        <ShadowCard sx={{ flexGrow: 1, overflow: "visible" }} padding={false}>
            <Row
                sx={{ p: 2, cursor: strategyExpanded ? "pointer" : undefined }}
                onClick={strategyExpanded ? () => setExpanded((prev) => !prev) : undefined}
                spaceBetween
            >
                <Row spacing={1}>
                    <TokenImage size="md" metadata={strategyExpanded?.principalMetadata} />
                    <Text loadingWidth="100px" loading={!strategyExpanded}>
                        {BsMetaUtil.formatAmount(strategyExpanded?.principalMetadata, totalPosition)}
                    </Text>
                    <Text loadingWidth="55px" loading={!strategyExpanded} color="caption">
                        {formatUsd(bsMath.mul(totalPosition, strategyExpanded?.principalUsdPrice))}
                    </Text>
                </Row>
                <Row spacing={1}>
                    {!!totalPosition && strategyExpanded && !isMobile && (
                        <StrategyRateAndRewardsBreakdown strategyExpanded={strategyExpanded} />
                    )}
                    {isInactive && (
                        <TooltipText
                            color="disabled"
                            helpText="New offers are not allowed. Enable offers again in settings"
                        >
                            Inactive
                        </TooltipText>
                    )}

                    {!isMobile && <ToggleChevron disabled={!strategyExpanded} textVariant="h2" expanded={expanded} />}
                </Row>
            </Row>

            <Collapse in={expanded}>
                <Column sx={{ p: 2, pt: 0 }} spacing={2}>
                    <StrategyStats mode="terms" query={query} strategyExpanded={strategyExpanded} />
                    <Row spacing={1}>
                        <Button
                            sx={{ [query.below]: { width: "100%" } }}
                            onClick={() =>
                                !!strategyExpanded &&
                                open(AppDialog.StrategyManageEscrow, { strategy: strategyExpanded, side: "deposit" })
                            }
                            variant="contained"
                        >
                            Deposit/Withdraw
                        </Button>
                        <StrategyCardActions query={query} strategyExpanded={strategyExpanded} />
                    </Row>
                </Column>
            </Collapse>
        </ShadowCard>
    );
}
