import { PointMultiplier, CollateralInfo, LoopscalePointSource, PointsInfo, PointSource } from "../types";

export function buildCollateralSourceInfo(collateralInfo: PointMultiplier[]): Record<string, CollateralInfo> {
    const result: Record<string, CollateralInfo> = {};

    collateralInfo.forEach((info) => {
        if (!result[info.mint]) {
            const initialBonuses = new Map<LoopscalePointSource, number>([
                [LoopscalePointSource.Borrow, 0],
                [LoopscalePointSource.Lend, 0],
                [LoopscalePointSource.IdleCap, 0]
            ]);

            result[info.mint] = {
                pointSource: [],
                collateralPointBonus: initialBonuses,
                name: info.name
            };
        }

        result[info.mint].collateralPointBonus.set(info.reward, info.multiplier);
    });

    return result;
}

export function calculateLoopscaleMultiplier(
    reward: PointsInfo,
    loopscaleActions: PointsInfo[] | undefined,
    baseSources: PointsInfo[] | undefined
): number | undefined {
    if (reward.program !== PointSource.Loopscale || (!loopscaleActions?.length && !baseSources?.length)) {
        return reward.multiplier;
    }

    const allActions = [...(loopscaleActions || []), ...(baseSources || [])];

    return allActions.reduce((total, action) => total * (action.multiplier ?? 1), 1);
}
