export enum AppParams {
    CollateralMint = "collateral",
    Term = "term",
    BorrowLendRole = "role"
}

export enum PortfolioTab {
    Loans = "loans",
    Vaults = "vaults",
    Strategies = "positions",
    Orders = "offers",
    PrimeLenderLoans = "lender-loans",
    Requests = "requests",
    Activity = "activity",
    PastLoans = "past-loans",
    Syndicates = "syndicates"
}
