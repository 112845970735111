import { AlertProgress, AlertVariant, TransactionStatus, generateNonce } from "@bridgesplit/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, enqueueSnackbar } from "notistack";

import { TransactionResult } from "../types";

interface Alert {
    id: string;
    variant: AlertVariant;
    transactionsResults: TransactionResult[];
    message: string | undefined;
    description: string | undefined;
    progress?: AlertProgress;
}

type State = { alerts: Record<string, Alert>; isLoading: boolean; isSignaturePending: boolean };
const initialState: State = { alerts: {}, isLoading: false, isSignaturePending: false };

const alertsSlice = createSlice({
    name: "alertsSlice",
    initialState,
    reducers: {
        resetAlertState: () => initialState,

        resetTransactionsState: (state) => {
            Object.keys(state.alerts).forEach((key) => {
                state.alerts[key].transactionsResults = [];
            });
        },

        updateMessage: (state, action: PayloadAction<Alert>) => {
            const alert = action.payload;
            const id = alert.id;

            if (!(id in state.alerts)) {
                enqueueSnackbar(id, {
                    key: id,
                    autoHideDuration: null // manually close in app sender
                });
            }

            state.alerts[id] = alert;
        },
        setTransactionsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setSignaturePending: (state, action: PayloadAction<boolean>) => {
            state.isSignaturePending = action.payload;
        }
    }
});

// not exported to force usage through `useAlert`
const { resetAlertState, updateMessage, resetTransactionsState, setTransactionsLoading, setSignaturePending } =
    alertsSlice.actions;

const getTransactionStatusSlice = (state: { alertsSlice: State }): State => state.alertsSlice;

export function useAlertState() {
    const { alerts }: State = useSelector(getTransactionStatusSlice);

    return { alerts };
}

export function useTransactionsState() {
    const { isLoading, isSignaturePending }: State = useSelector(getTransactionStatusSlice);
    const dispatch = useDispatch();

    function dispatchResults(
        id: string,
        results: TransactionResult[],
        messages: { onSuccess: string; onError: string }
    ) {
        const failedTransactions = results.filter((r) => r.status !== TransactionStatus.Confirmed).length;
        const message = failedTransactions ? messages.onError : messages.onSuccess;

        dispatch(
            updateMessage({
                id,
                message,
                variant: failedTransactions ? "error" : "success",
                description: undefined,
                transactionsResults: results
            })
        );
    }

    return {
        transactionsInProgress: isLoading,
        isTransactionSignaturePending: isSignaturePending,
        reset: () => dispatch(resetTransactionsState()),
        dispatchResults,
        setTransactionsLoading: (loading: boolean) => dispatch(setTransactionsLoading(loading)),
        setSignaturePending: (pending: boolean) => dispatch(setSignaturePending(pending))
    };
}

export function useAlert() {
    const dispatch = useDispatch();

    function alert(
        message: string,
        variant: AlertVariant,
        options?: {
            description?: string;
            autoHideDuration?: number;
            customSnackbarId?: string;
            progress?: AlertProgress;
        }
    ) {
        const id = options?.customSnackbarId ?? generateNonce();

        dispatch(
            updateMessage({
                id,
                message,
                variant,
                description: options?.description,
                transactionsResults: [],
                progress: options?.progress
            })
        );
    }

    function dismiss(id: string) {
        closeSnackbar(id);
    }

    function reset() {
        dispatch(resetAlertState());
    }

    return { alert, dismiss, reset };
}

export default alertsSlice.reducer;
