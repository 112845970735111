import { useMemo } from "react";

import { LabelWrapper, Select, StatColumn, Text, TextSkeleton } from "@bridgesplit/ui";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { formatDate } from "@bridgesplit/utils";
import { VerifiedUser } from "@mui/icons-material";

import { useMarketBorrowContext } from "../MarketBorrowContext";
import { useBorrowTimeOffsets, useSetBorrowerPreset } from "../util";

export default function Duration() {
    const { form, presets } = useMarketBorrowContext();
    const offsets = useBorrowTimeOffsets();

    const { setPreset } = useSetBorrowerPreset();

    const options = useMemo(() => {
        if (!presets) return Array(4).fill({ value: "", label: <TextSkeleton variant="body2" width="30px" /> });
        return presets.map((preset) => ({
            value: preset.presetStrategyIdentifier,
            label: formatDurationWithType(preset.duration, preset.durationType)
        }));
    }, [presets]);

    return (
        <>
            <LabelWrapper color="body" label="You'll repay in">
                <Select
                    renderValue={
                        !form.preset && presets ? () => <Text color="disabled">Select duration</Text> : undefined
                    }
                    options={options}
                    value={presets ? form.preset?.presetStrategyIdentifier : ""}
                    setValue={setPreset}
                />
            </LabelWrapper>

            {form.preset && (
                <StatColumn
                    stats={[
                        {
                            caption: "Due",
                            value: formatDate(offsets?.loanEndDate)
                        },
                        {
                            caption: "Auto-extend",
                            tooltip: `If you don't repay on time, your loan will automatically extend by ${formatDurationWithType(
                                form.preset.duration,
                                form.preset.durationType
                            )} if an offer is available`,
                            value: (
                                <Text sx={{ gap: 0.5 }} svgColor="secondary">
                                    <VerifiedUser /> Active
                                </Text>
                            )
                        }
                    ]}
                />
            )}
        </>
    );
}
