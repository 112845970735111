import { TagTextAlert } from "@bridgesplit/ui";

import { useMarketCapDetails } from "../util";

export default function BorrowCapWarning() {
    const borrowCap = useMarketCapDetails();

    if (!borrowCap?.warningMessage) return null;
    return (
        <TagTextAlert icon="warning" color="warning">
            {borrowCap.warningMessage}
        </TagTextAlert>
    );
}
