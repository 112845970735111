import { useMemo, useState } from "react";

import {
    BsMetaUtil,
    formatTokens,
    getLoanEndTime,
    isValidPublicKey,
    useTransferDebtTransaction
} from "@bridgesplit/abf-react";
import { CheckboxRow, FONT_SIZES, OverlappingImages, Row, StatColumn, Text } from "@bridgesplit/ui";
import { formatDate, LOADING_ERROR, Result } from "@bridgesplit/utils";
import { ChainId, TransferDebtParams } from "@bridgesplit/abf-sdk";

import { AppButton, ChainAddressInput } from "../../common";
import { ActionProps } from "./types";
import { useTransactionSender } from "../../transactions";

export default function TransferDebt(props: ActionProps) {
    const { loanExpanded } = props;

    const send = useTransactionSender();
    const transferDebt = useTransferDebtTransaction();
    const [recipientEscrow, setRecipientEscrow] = useState<string | undefined>(undefined);
    const [accepted, setAccepted] = useState(false);

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        if (!recipientEscrow) return Result.errFromMessage("No escrow found");
        const params: TransferDebtParams = {
            recipientEscrow,
            loanAddress: loanExpanded.loan.address
        };
        return await send(transferDebt, params);
    }

    const ctaError = useMemo(() => {
        const isValidPubkey = isValidPublicKey(recipientEscrow ?? "");
        if (!recipientEscrow) return "Enter address";
        if (!accepted) return "Accept warning";
        if (!isValidPubkey) return "Invalid address";
        return undefined;
    }, [accepted, recipientEscrow]);

    return (
        <>
            <ChainAddressInput
                label="Escrow account to transfer to"
                chainId={ChainId.Solana}
                value={recipientEscrow}
                setValue={setRecipientEscrow}
            />

            <StatColumn
                stats={[
                    {
                        caption: "Debt",
                        value: BsMetaUtil.formatAmount(loanExpanded?.principalMetadata, loanExpanded?.debt.total)
                    },
                    {
                        caption: "Due",
                        value: formatDate(getLoanEndTime(loanExpanded, true))
                    },
                    {
                        caption: "Collateral",
                        value: (
                            <Row spacing={1}>
                                <OverlappingImages
                                    size={FONT_SIZES.body1 + "px"}
                                    images={loanExpanded?.collateral.map((c) => BsMetaUtil.getImage(c.metadata))}
                                />
                                <Text>{formatTokens(loanExpanded?.collateral)}</Text>
                            </Row>
                        )
                    }
                ]}
            />

            <CheckboxRow
                value={accepted}
                setValue={() => setAccepted(!accepted)}
                label="I understand that I'm irrevocably sending the loan including collateral to this address"
            />

            <AppButton disabled={!!ctaError} isTransaction asyncCta={{ onClickWithResult: submit }}>
                {ctaError ?? "Transfer loan"}
            </AppButton>
        </>
    );
}
