import { useCallback, useMemo, useState } from "react";

import { EmptyPlaceholder } from "@bridgesplit/ui";
import { ALLOWED_FEATURES, LOADING_ERROR, MISSING_PARAM_ERROR, Result, filterTruthy } from "@bridgesplit/utils";
import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    LockboxDepositParams,
    TokenBalanceExpanded,
    isStakedSol,
    useActiveEscrow,
    useActiveWallet,
    useUserAvailableAssets,
    useIsLoanEscrowBased,
    useActiveGroup,
    useLockboxTopUpTransaction,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { SyncProblemOutlined } from "@mui/icons-material";
import { LockboxAssetUpdateArgs, ManageSide, StakeAccountArgs, StakedSolLockboxArgs } from "@bridgesplit/abf-sdk";
import { TrackTransactionEvent } from "app/types";

import { ActionProps } from "./types";
import { AppButton, SelectAssets, getSelectAssetsErrorMessage } from "../../common";
import { useTransactionSender } from "../../transactions";
import { CollateralLoanHealthChangeStats } from "./common";
import { useCalculateNewLoanHealth } from "./util";

export default function TopUp({ loanExpanded }: ActionProps) {
    const containsStakedSol = loanExpanded?.collateral.find((c) => isStakedSol(c.assetTypeDiscriminator));

    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);
    const { data: assets } = useUserAvailableAssets({
        escrowNeeded,
        includeStakedSol: !!containsStakedSol
    });
    const [selected, setSelected] = useState<Map<string, number | undefined>>(new Map());

    const allowedCollateral = useMemo(() => {
        if (!loanExpanded) return new Set<string>();

        const mints = loanExpanded?.collateral
            .map((c) => {
                if (
                    ALLOWED_FEATURES.disableMultiTopUp &&
                    (c.whirlpoolPosition || isStakedSol(c.assetTypeDiscriminator))
                ) {
                    return [];
                }

                return [c.mint, c.whirlpoolPosition?.tokenA.mint, c.whirlpoolPosition?.tokenB.mint].filter(
                    filterTruthy
                );
            })
            .flat();
        return new Set(mints);
    }, [loanExpanded]);

    const allowedUserAssets = assets?.filter((c) => allowedCollateral.has(c.mint));

    if (!allowedCollateral.size)
        return (
            <EmptyPlaceholder
                icon={<SyncProblemOutlined />}
                header="Top up not available"
                description="You don't have any collateral that can be topped up"
            />
        );

    return (
        <>
            <SelectAssets allowTokenInput assets={allowedUserAssets} selected={selected} setSelected={setSelected} />
            <RepayCta assets={allowedUserAssets} loanExpanded={loanExpanded} selected={selected} />
        </>
    );
}

function RepayCta({
    selected,
    loanExpanded,
    assets
}: {
    selected: Map<string, number | undefined>;
    loanExpanded: AbfLoanExpanded | undefined;
    assets: TokenBalanceExpanded[] | undefined;
}) {
    const selectedAssets = assets
        ?.map((asset) => ({ asset, selectedAmount: selected.get(asset.key) ?? 0 }))
        .filter((a) => !!a.selectedAmount);
    const healthChange = useCalculateNewLoanHealth(Array.from(selected.keys()))(
        loanExpanded,
        selectedAssets?.map((c) => ({ collateralMint: c.asset.mint, change: c.selectedAmount }))
    );

    const send = useTransactionSender();
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonce, activeEscrow } = useActiveEscrow();
    const { activeWallet } = useActiveWallet();

    const lockboxTopUp = useLockboxTopUpTransaction();

    const submit = useCallback(async () => {
        if (!groupIdentifier || !escrowNonce || !loanExpanded || !activeEscrow || !activeWallet)
            return Result.errFromMessage(LOADING_ERROR);

        if (!selectedAssets?.length) return Result.errFromMessage(MISSING_PARAM_ERROR);
        const lockbox = loanExpanded.lockboxAddress;

        const stakeAccounts = selectedAssets
            .map(({ asset: { stakeAccount }, selectedAmount }): StakeAccountArgs | undefined =>
                stakeAccount ? { address: stakeAccount.stakeAccount, amountToTransfer: selectedAmount } : undefined
            )
            .filter(filterTruthy);

        const standardTransfers = selectedAssets
            .filter((a) => !a.asset.stakeAccount)
            .map(
                ({ selectedAmount, asset: { mint } }): LockboxAssetUpdateArgs => ({
                    user: activeWallet.wallet,
                    assetMint: mint,
                    amount: selectedAmount,
                    lockbox,
                    escrowDeposit: false,
                    escrowAccount: activeEscrow,
                    side: ManageSide.Deposit,
                    assetType: "SplToken" // User can only top up SPL tokens
                })
            );

        const stakedSolArgs: StakedSolLockboxArgs = {
            stakeAccounts: stakeAccounts,
            lockbox,
            groupIdentifier,
            escrowNonce,
            escrow: false,
            side: ManageSide.Deposit
        };
        const params: LockboxDepositParams = { lockbox: standardTransfers, stakedSol: stakedSolArgs };

        return await send(lockboxTopUp, params, {
            description: "Adding collateral",
            mixpanelEvent: { key: TrackTransactionEvent.SubmitTopup, params }
        });
    }, [activeEscrow, activeWallet, escrowNonce, groupIdentifier, loanExpanded, lockboxTopUp, selectedAssets, send]);

    const assetSelectionError = getSelectAssetsErrorMessage({ selected, assets });

    return (
        <>
            <CollateralLoanHealthChangeStats healthChange={healthChange} />
            <AppButton
                isTransaction
                disabled={!!assetSelectionError}
                asyncCta={{ onClickWithResult: submit }}
                authentication={{
                    permission: AbfPermissionForCustodian.RepayLoan,
                    custodians: getLoanCustodianIdentifiers(loanExpanded)
                }}
                fullWidth
            >
                {assetSelectionError ?? " Top Up"}
            </AppButton>
        </>
    );
}
