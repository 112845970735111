import { useMemo } from "react";

import { LoopPositionExpanded, useLoopsExpanded } from "@bridgesplit/abf-react";
import {
    EmptyPlaceholder,
    EmptyPlaceholderProps,
    Pagination,
    repeatElement,
    Column,
    LabelWrapper,
    usePopover,
    Row,
    MEDIA,
    Select,
    MediaStack
} from "@bridgesplit/ui";
import { removeDuplicatesByProperty, SortDirection } from "@bridgesplit/utils";
import { useLocation } from "react-router-dom";
import { EmptyPromptType, useEmptyPrompts } from "app/utils";
import { COPY, LOOP_DETAIL_SLUG } from "app/constants";
import { useLoopsPagination } from "app/api";
import { LoopPositionSortType } from "@bridgesplit/abf-sdk";

import LoopPositionCard from "./LoopPositionCard";
import { TokenSelect, FilterButtonPopover, PastActiveToggle, SortDirectionButton } from "../../common";

type PaginationType = ReturnType<typeof useLoopsPagination>;
const BREAKPOINT = MEDIA.LG;

export default function LoopCards({
    loopPositions,
    pagination,
    hideTokenFilter
}: {
    loopPositions: LoopPositionExpanded[] | undefined;
    pagination: PaginationType;
    hideTokenFilter?: boolean;
}) {
    const totalData = loopPositions?.length ? loopPositions?.[0].userLoopInfo.totalCount : 0;

    const data = loopPositions?.sort((a, b) => b.userLoopInfo.index - a.userLoopInfo.index);
    return (
        <Column spacing={2}>
            <PaginationControls pagination={pagination} hideTokenFilter={hideTokenFilter} />

            {data
                ? data.map((loop) => <LoopPositionCard query={BREAKPOINT} key={loop.loanAddress} loopPosition={loop} />)
                : repeatElement(
                      <LoopPositionCard query={BREAKPOINT} loopPosition={undefined} />,
                      pagination.params.pageSize
                  )}

            {data?.length === 0 && <Empty />}

            {!!pagination && !!totalData && totalData > pagination.params.pageSize && (
                <Pagination
                    count={totalData}
                    rowsPerPage={pagination.params.pageSize}
                    page={pagination.params.page}
                    setPage={pagination.setPage}
                />
            )}
        </Column>
    );
}

const SORT_OPTIONS = [
    { value: LoopPositionSortType.StartTime, label: "Start date" },
    { value: LoopPositionSortType.NetPositionValue, label: "Position value" },
    { value: LoopPositionSortType.HealthRatio, label: "Health" },
    { value: LoopPositionSortType.LeverageMultiplier, label: "Leverage multiplier" },
    { value: LoopPositionSortType.NetApy, label: "Net APY" }
];
function PaginationControls({
    pagination,
    hideTokenFilter
}: {
    pagination: PaginationType;
    hideTokenFilter?: boolean;
}) {
    const {
        params: { sortType, sortDirection },
        setSortDirection,
        setSortType,
        setPage
    } = pagination;
    const popoverProps = usePopover();

    return (
        <MediaStack spaceBetweenRow query={BREAKPOINT} spacing={1}>
            <Row spacing={1}>
                {!hideTokenFilter && (
                    <FilterButtonPopover reset={pagination.reset} popoverProps={popoverProps}>
                        <Filters pagination={pagination} />
                    </FilterButtonPopover>
                )}
                <PastActiveToggle
                    value={pagination.params.active}
                    setValue={(val) => {
                        setPage(0);
                        pagination.setActiveOnly(!!val);
                    }}
                />
            </Row>

            <Row spacing={1} sx={{ width: "100%", justifyContent: "flex-end" }}>
                <SortDirectionButton
                    direction={sortDirection as SortDirection}
                    setDirection={(sortDirection) => {
                        setPage(0);
                        setSortDirection(sortDirection);
                    }}
                />
                <Select
                    sx={{ [BREAKPOINT.above]: { maxWidth: "230px" } }}
                    value={sortType}
                    setValue={(sort) => {
                        setPage(0);
                        setSortType(sort);
                    }}
                    options={SORT_OPTIONS}
                />
            </Row>
        </MediaStack>
    );
}

function Empty() {
    const getPrompt = useEmptyPrompts();
    const prompt = getPrompt(EmptyPromptType.Loop, `No matching ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`);

    const location = useLocation();
    const promptWithCta: EmptyPlaceholderProps = {
        ...prompt,
        cta: !location.pathname.includes(LOOP_DETAIL_SLUG) ? prompt.cta : undefined
    };

    return <EmptyPlaceholder {...promptWithCta} />;
}

function Filters({ pagination: { setPrincipalMint, setCollateralMint, params } }: { pagination: PaginationType }) {
    const { data: allLoops } = useLoopsExpanded({});

    const supported = useMemo(() => {
        if (!allLoops) return undefined;
        const principalTokens = removeDuplicatesByProperty(
            allLoops.map((loop) => loop.principalToken),
            "assetMint"
        );
        const collateralTokens = removeDuplicatesByProperty(
            allLoops.map((loop) => loop.collateralToken),
            "assetMint"
        );
        return { principalTokens, collateralTokens };
    }, [allLoops]);

    return (
        <>
            <LabelWrapper label="Token">
                <TokenSelect
                    tokens={supported?.collateralTokens}
                    value={params.collateralMint ?? ""}
                    setValue={setCollateralMint}
                />
            </LabelWrapper>
            <LabelWrapper label="Leverage">
                <TokenSelect
                    tokens={supported?.principalTokens}
                    value={params.principalMint ?? ""}
                    setValue={setPrincipalMint}
                />
            </LabelWrapper>
        </>
    );
}
