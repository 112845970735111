import { QuestionToggleProps } from "@bridgesplit/ui";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";
import { JLP_MINT } from "@bridgesplit/utils";
import { isLstLoop } from "@bridgesplit/abf-react";

import { useJlpFaq } from "./jlp";
import { useLoopContext } from "../../LoopContext";
import { useMeteoraFaq } from "./meteora";
import { useLstFaq } from "./lst";

export function useLoopFaq(): QuestionToggleProps[] | undefined {
    const { loopExpanded } = useLoopContext();
    const jlp = useJlpFaq();
    const meteora = useMeteoraFaq();
    const lst = useLstFaq();

    if (!loopExpanded) return undefined;

    if (isLstLoop(loopExpanded)) return lst;
    if (loopExpanded.type === LoopRoutePlatform.Jupiter && loopExpanded.loopVault.collateralMint === JLP_MINT)
        return jlp;
    if (loopExpanded.type === LoopRoutePlatform.Meteora) return meteora;
    return undefined;
}
