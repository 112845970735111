import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { IS_PROD, SENTRY_IGNORE_ERRORS } from "@bridgesplit/utils";

export function initSentry(): void {
    Sentry.init({
        dsn: "https://66abea6f665fcc1af8e1fe3cf9240551@o4507662757330944.ingest.us.sentry.io/4507662768209920",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                networkDetailAllowUrls: ["/^(?!auth.loopscale.com).*loopscale/"],
                networkCaptureBodies: true
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            })
        ],

        ignoreErrors: SENTRY_IGNORE_ERRORS,
        environment: IS_PROD ? "production" : "dev",
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["/^(?!auth.loopscale.com).*loopscale/"],
        sendDefaultPii: true,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

export default initSentry;
