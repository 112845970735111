import { useEffect, useState } from "react";

import { PaginationController, PaginationProps, TableColumn, TableKey, TableRow } from "./types";
import { useTableController } from "./internal";

export function useApiTableController<T>(paginationProps: Omit<PaginationProps, "type">): PaginationController {
    return useTableController<T>({ ...paginationProps, type: "api" });
}

export function useCheckboxTableController(initialData?: Set<TableKey>) {
    const [selected, setSelected] = useState<Set<TableKey>>(new Set());
    const [initialDataIsSet, setInitialDataIsSet] = useState(false);

    useEffect(() => {
        if (!initialData || initialDataIsSet) return;
        setInitialDataIsSet(true);
        setSelected(initialData);
    }, [initialData, initialDataIsSet, selected]);

    return { selected, setSelected };
}

export function createRows<T>(data: T[] | undefined, getKey: (t: T) => TableKey): TableRow<T>[] | undefined {
    return data?.map((d) => ({ data: d, key: getKey(d) }));
}

export function equalWidthColumns<T>(columns: TableColumn<T>[]): TableColumn<T>[] {
    return columns.map((c) => ({ ...c, width: `${Math.round(100 / columns.length)}%` }));
}
