import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import { useAbfTypesToUiConverter } from "../utils";
import { useExternalYieldInfoQuery, useExternalYieldInterestQuery } from "../reducers";
import { StrategyExpanded } from "../types";

export function useExternalYieldInterest(strategies: StrategyExpanded[] | undefined) {
    const { data } = useExternalYieldInterestQuery(
        strategies ? { escrows: strategies.map((s) => s.strategy.escrow) } : skipToken,
        { skip: !strategies?.length }
    );
    const escrowToStrategy = useMemoizedKeyMap(strategies, (s) => s.strategy.escrow);
    const { convertExternalYieldAccrued } = useAbfTypesToUiConverter([]);
    const externalYieldInterestUsd = useMemo(() => {
        if (strategies?.length === 0) return 0;
        if (!data) return undefined;

        let interestUsd = 0;
        for (const [escrow, yieldData] of Object.entries(data)) {
            for (const rawYieldData of yieldData) {
                const strategy = escrowToStrategy?.get(escrow);
                const externalYield = convertExternalYieldAccrued(rawYieldData, strategy?.principalMetadata.decimals);
                interestUsd += externalYield.interestAccrued * (strategy?.principalUsdPrice ?? 0);
            }
        }
        return interestUsd;
    }, [convertExternalYieldAccrued, data, escrowToStrategy, strategies?.length]);

    return { externalYieldInterestUsd };
}

export function useExternalYieldVaults() {
    const { data: rawData, ...query } = useExternalYieldInfoQuery();
    const { convertExternalYieldVault } = useAbfTypesToUiConverter([]);

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return rawData.map(convertExternalYieldVault);
    }, [convertExternalYieldVault, rawData]);

    const principalMintToYieldVault = useMemoizedKeyMap(data, (v) => v.principalMint);

    function getYieldVault(principalMint: string | undefined) {
        if (!principalMint || !principalMintToYieldVault) return undefined;
        return principalMintToYieldVault?.get(principalMint) ?? null;
    }

    return { data, ...query, getYieldVault };
}
