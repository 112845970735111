import { useCallback, useMemo } from "react";

import { Column, TagTextAlert, TextButton } from "@bridgesplit/ui";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";

import { AppButton } from "../../common";
import { usePutStrategySettings } from "./util";
import { StrategySettingsProps } from "./types";

export default function StrategySettingsCta({ strategy, form, initialForm, resetForm }: StrategySettingsProps) {
    const metaChanged = useMemo(() => {
        if (!initialForm || !form) return false;

        return false;
    }, [form, initialForm]);

    const escrowCapChanged = useMemo(() => {
        if (!initialForm || !form) return false;

        return form.yieldSource !== initialForm.yieldSource || form.allowNewOffers !== initialForm.allowNewOffers;
    }, [form, initialForm]);

    const changesMade = metaChanged || escrowCapChanged;

    const putChanges = usePutStrategySettings();

    const submit = useCallback(async () => {
        if (!strategy || !form) return Result.errFromMessage(LOADING_ERROR);
        const res = await putChanges(strategy, form);
        if (res.isOk()) {
            resetForm();
        }
        return res;
    }, [form, putChanges, resetForm, strategy]);

    return (
        <>
            {escrowCapChanged && (
                <TagTextAlert icon="tooltip" color="info">
                    This change will require you to authenticate with your wallet
                </TagTextAlert>
            )}
            <Column spacing={1}>
                <AppButton
                    isTransaction
                    disabled={!changesMade}
                    asyncCta={{ onClickWithResult: submit, closeDialog: "onSuccess" }}
                    variant="contained"
                >
                    Save
                </AppButton>
                {!!changesMade && (
                    <TextButton onClick={resetForm} color="caption" variant="body2">
                        Reset
                    </TextButton>
                )}
            </Column>
        </>
    );
}
