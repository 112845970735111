import { useMemo, useState } from "react";

import { DialogWrapper, Row, ToggleSpacedButtons } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";

import MeteoraWithdraw from "./MeteoraWithdraw";
import MeteoraDeposit from "./MeteoraDeposit";
import { Slippage } from "../../common";

enum Side {
    Deposit = "Deposit",
    Withdraw = "Withdraw"
}
export default function LoopExternalTransfersDialog() {
    const { getData } = useAppDialog();
    const data = getData<AppDialog.LoopExternalTransfers>();
    const [side, setSide] = useState<Side>(Side.Deposit);
    const { slippagePercentUi, slippageWrapperProps, slippageButtonProps } = Slippage.useController();
    const content = useMemo(() => {
        if (!data) return null;
        if (data.loopExpanded.type === LoopRoutePlatform.Meteora) {
            if (side === Side.Withdraw)
                return <MeteoraWithdraw loopExpanded={data.loopExpanded} slippagePercentUi={slippagePercentUi} />;
            return <MeteoraDeposit loopExpanded={data.loopExpanded} slippagePercentUi={slippagePercentUi} />;
        }

        return null;
    }, [data, side, slippagePercentUi]);

    return (
        <DialogWrapper>
            <Slippage.Wrapper {...slippageWrapperProps}>
                <Row alignItems="flex-start" spaceBetween>
                    <ToggleSpacedButtons
                        size="small"
                        value={side}
                        setValue={setSide}
                        options={[{ value: Side.Deposit }, { value: Side.Withdraw }]}
                    />
                    <Slippage.Button {...slippageButtonProps} />
                </Row>
                {content}
            </Slippage.Wrapper>
        </DialogWrapper>
    );
}
