import { AssetTypeIdentifier } from "./request";

export interface Lockbox {
    id: number;
    address: string;
    nonce: string;
    name: string;
    initializationTime: number;
    lastUpdateTime: number;
    nftMint: string;
    state: LockBoxState;
    initializer: string;
    closer?: string;
    lockboxAssetsAddress: string;
}

export enum LockBoxState {
    Unlocked,
    Locked
}

export interface LockboxAsset {
    id: number;
    lockboxAddress: string;
    assetTypeDiscriminator: AssetTypeIdentifier;
    assetKey: string;
    amount: number;
    lockboxAssetsAddress: string;
}

export interface LockboxWithAssets {
    data: Lockbox;
    assets: LockboxAsset[];
}

export interface CollateralStats {
    depositedCollateral: number;
    numCustodians: number;
    supportedAssets: number;
}
