import { useState } from "react";

import { Button, DialogWrapper, LabelWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import {
    DEFAULT_PRESET,
    getLoopSlippage,
    getLoopSlippageTooltip,
    PresetStrategyTerms,
    usePresets
} from "@bridgesplit/abf-react";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { percentDecimalsToUi, percentUiToDecimals } from "@bridgesplit/utils";

import { HeaderWithClose, SelectPresetTerm, Slippage } from "../../common";

export default function WindSettingsDialog() {
    const { getData, close } = useAppDialog();

    const data = getData<AppDialog.WindSettings>();

    const [slippageUi, setSlippageUi] = useState<number | undefined>(
        percentDecimalsToUi(data?.form?.percentSlippageDecimals)
    );
    const [preset, setPreset] = useState<PresetStrategyTerms | undefined>(data?.form?.preset);

    const collateralMint = data?.loopExpanded.collateralToken.assetMint;
    const { presets } = usePresets(collateralMint ? [collateralMint] : undefined);

    const selectedPreset =
        preset ??
        presets?.find((p) => p.duration === DEFAULT_PRESET.duration && p.durationType === DEFAULT_PRESET.durationType);

    return (
        <DialogWrapper>
            <HeaderWithClose header={`${COPY.LOOP_TERM} settings`} close={close} />
            <LabelWrapper
                label="Slippage"
                tooltip={data ? getLoopSlippageTooltip(data?.loopExpanded, "wind") : undefined}
            >
                <Slippage.Input
                    presets={getLoopSlippage(data?.loopExpanded).slippagePresetsUi}
                    value={slippageUi}
                    setValue={(val) => setSlippageUi(val)}
                />
            </LabelWrapper>
            <LabelWrapper
                label="Custom duration"
                tooltip={`Duration to lock your borrow APY for (defaults to ${formatDurationWithTypeShorthand(
                    DEFAULT_PRESET.duration,
                    DEFAULT_PRESET.durationType
                )})`}
            >
                <SelectPresetTerm
                    presets={presets}
                    presetStrategyIdentifier={selectedPreset?.presetStrategyIdentifier}
                    setPreset={(presetStrategyIdentifier) => {
                        const preset = presets?.find(
                            (preset) => preset.presetStrategyIdentifier === presetStrategyIdentifier
                        );
                        if (preset) {
                            setPreset(preset);
                        }
                    }}
                />
            </LabelWrapper>

            <Button
                disabled={!slippageUi || !selectedPreset}
                variant="contained"
                onClick={() => {
                    if (!slippageUi || !selectedPreset) return;
                    data?.setForm({
                        ...data?.form,
                        percentSlippageDecimals: percentUiToDecimals(slippageUi),
                        preset: selectedPreset
                    });
                    close();
                }}
            >
                Save settings
            </Button>
        </DialogWrapper>
    );
}
