import { AbfLoanExpanded, FilterComparator, OfferWithTermsAndFilter, StrategyExpanded } from "@bridgesplit/abf-react";
import { AssetClass } from "@bridgesplit/abf-sdk";
import { DispatchType } from "@bridgesplit/react";
import { generateNonce } from "@bridgesplit/utils";

export type StrategyProps = {
    strategy: StrategyExpanded | undefined;
    isLoading: boolean;
    isFetching: boolean;
    loans: AbfLoanExpanded[] | undefined;
};

export type FilterInput = {
    edited: boolean;
    nonce: string;
    traitName: string | undefined;
    traitValue: string | undefined;
    comparator: FilterComparator;
};

export type CreateAssetFilterForm = {
    assetClass: AssetClass;
    filters: FilterInput[];
    price: number | undefined;
    name?: string;
    description?: string;
    terms: Map<string, AssetTermsInput>;
};

export type AssetTermsInput = {
    modified: boolean;
    ltv: number | undefined;
    apy: number | undefined;
} & AbfLendingStrategyAssetTermsPartial;

export type AbfLendingStrategyAssetTermsPartial = Partial<{
    identifier: string;
    offerIdentifier: string;
    strategyTermsIdentifier: string;
}>;

export function createFilterInput(): FilterInput {
    return {
        edited: false,
        nonce: generateNonce(),
        traitName: undefined,
        traitValue: undefined,
        comparator: "="
    };
}

export type CreateAssetFilterFormProps = {
    strategy: StrategyExpanded | undefined;
    form: CreateAssetFilterForm;
    setForm: DispatchType<CreateAssetFilterForm>;
};

export type ModifyAssetFilterFormProps = CreateAssetFilterFormProps & {
    offerWithTermsAndFilter: OfferWithTermsAndFilter | undefined;
    reset: () => Promise<void>;
};

export const UNCAPPED_STRATEGY_AMOUNT = Number.MAX_SAFE_INTEGER;
