import { useOraclePrices } from "@bridgesplit/abf-react";
import { TextVariant, StatProps, Row, Column, StatColumn, Tooltip, Text, Icon } from "@bridgesplit/ui";
import {
    formatTokenAmount,
    formatUsd,
    lamportsToUiAmount,
    SOL_DECIMALS,
    SOL_SYMBOL,
    WRAPPED_SOL_MINT
} from "@bridgesplit/utils";
import { Divider } from "@mui/material";
import { COPY } from "app/constants";
import { TRANSACTION_FEE_ESTIMATES } from "app/constants/fees";
import { TransactionTypes } from "app/types";

export interface TransactionFees {
    rentAmount: number;
    totalFeeAmount: number;
    networkAmount: number;
    refundAmount: number;
    setupAmount: number;
}

export function useTransactionData() {
    function getTransactionFees(type: TransactionTypes): TransactionFees {
        const fees = TRANSACTION_FEE_ESTIMATES[type];

        return {
            rentAmount: lamportsToUiAmount(fees.rentAmount, SOL_DECIMALS),
            totalFeeAmount: lamportsToUiAmount(fees.totalFeeAmount, SOL_DECIMALS),
            networkAmount: lamportsToUiAmount(fees.networkAmount, SOL_DECIMALS),
            refundAmount: lamportsToUiAmount(fees.refundAmount, SOL_DECIMALS),
            setupAmount: lamportsToUiAmount(fees.setupAmount, SOL_DECIMALS)
        };
    }
    return {
        getTransactionFees
    };
}

export interface TransactionFeeStatProps {
    variant?: TextVariant;
    transactionType: TransactionTypes;
}

export function useTransactionFeeStat({ variant = "body1", transactionType }: TransactionFeeStatProps): StatProps {
    const { getTransactionFees } = useTransactionData();
    const transactionData = getTransactionFees(transactionType);

    const value = (
        <Tooltip
            containerSx={{ width: "300px" }}
            reverseColors
            title={<TransactionFeeBreakdown variant={variant} {...transactionData} />}
        >
            <Row sx={{ justifyContent: "right" }} spacing={1}>
                <Text variant={variant} svgColor="caption">
                    <Icon type="help" />
                    {formatTokenAmount(transactionData.totalFeeAmount, {
                        decimals: SOL_DECIMALS,
                        symbol: SOL_SYMBOL
                    })}
                </Text>
            </Row>
        </Tooltip>
    );

    return {
        value,
        caption: COPY.TRANSACTION_FEE
    };
}
function TransactionFeeBreakdown({
    totalFeeAmount,
    networkAmount,
    refundAmount,
    variant
}: TransactionFees & { variant: TextVariant }) {
    const { getUsdPrice } = useOraclePrices([WRAPPED_SOL_MINT]);
    const usdPrice = getUsdPrice(WRAPPED_SOL_MINT);

    const formatter = {
        decimals: SOL_DECIMALS,
        symbol: SOL_SYMBOL
    };
    const stats: StatProps[] = [
        {
            value: formatTokenAmount(totalFeeAmount, formatter),
            caption: COPY.TRANSACTION_FEE_TOTAL
        },

        {
            value: formatTokenAmount(networkAmount, formatter),
            caption: COPY.RENT_NETWORK
        },
        {
            value: `-${formatTokenAmount(refundAmount, formatter)}`,
            caption: COPY.RENT_REFUNDABLE,
            textColor: "success"
        }
    ];

    const netFees = totalFeeAmount - refundAmount;

    const totalStat: StatProps[] = [
        {
            value: (
                <Row spacing={1}>
                    <Text variant={variant}>{formatTokenAmount(netFees, formatter)}</Text>

                    <Text variant={variant} color="caption" loading={!usdPrice}>
                        ({formatUsd(netFees * (usdPrice ?? 1))})
                    </Text>
                </Row>
            ),
            caption: COPY.TRANSACTION_FEE_NET
        }
    ];

    return (
        <Column spacing={1} sx={{ padding: 1 }}>
            <StatColumn captionVariant={variant} variant={variant} stats={stats} />
            <Divider />
            <StatColumn captionVariant={variant} variant={variant} stats={totalStat} />
        </Column>
    );
}
