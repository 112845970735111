import { Button, Text } from "@bridgesplit/ui";
import { useGroup } from "@bridgesplit/abf-react";
import { useAppDialog } from "app/utils";

export default function WalletNotApprovedByAdmin() {
    const { activeGroup } = useGroup();
    const { close } = useAppDialog();

    return (
        <>
            <Text color="error">
                This wallet hasn't been verified. Please contact the owner of {activeGroup?.groupName}
            </Text>

            <Button variant="outlined" onClick={close}>
                Close
            </Button>
        </>
    );
}
