import { ReactNode, useMemo } from "react";

import { MayString, doNothing } from "@bridgesplit/utils";
import { Box, Skeleton, Stack, styled } from "@mui/material";

import { MediaQuery, SxType } from "../types";
import { useLoadedImageSrc } from "../util/hooks";
import { BORDER_RADIUS, useAppPalette } from "../theme";
import { Row } from "./containers";
import { MEDIA } from "../util";
import { Text, TextVariant } from "./typography";

const StyledImage = styled("img")(() => ({}));

type ImageVariant = "circle" | "square" | "rounded" | "fixed-width" | "fixed-height";
interface BaseImageProps {
    src: MayString;
    sx?: SxType;
    mobileSx?: SxType;
    loading?: boolean;
    hideSkeleton?: boolean;
    border?: boolean;
    variant?: ImageVariant;
    skeletonVariant?: "circular" | "rectangular";
    skeletonSx?: SxType;

    mobileWidth?: string;
    mobileHeight?: string;
    query?: MediaQuery;
}
// force user to specify a width and height if illustration, otherwise specify just a size
interface ImageWithSize extends BaseImageProps {
    size: string;
}
interface ImageWithHeight extends BaseImageProps {
    variant: "fixed-height";
    height: string;
    width?: string;
}
interface ImageWithWidth extends BaseImageProps {
    variant: "fixed-width";
    width: string;
    height?: string;
}
export type ImageProps = ImageWithSize | ImageWithHeight | ImageWithWidth;

export function Image({
    variant = "rounded",
    sx,
    mobileSx,
    src,
    loading,
    hideSkeleton,
    border: showBorder,
    mobileHeight,
    mobileWidth,
    query = MEDIA.LG,
    skeletonSx,
    skeletonVariant,
    ...sizeProps
}: ImageProps) {
    const loadedSrc = useLoadedImageSrc(src);
    const { border } = useAppPalette();
    let width: string | undefined = undefined;
    let height: string | undefined = undefined;
    let maxWidth: string | undefined = undefined;

    if (variant === "fixed-height") {
        const props = sizeProps as ImageWithHeight;
        height = props.height;
        width = props.width ?? "auto";
    } else if (variant === "fixed-width") {
        const props = sizeProps as ImageWithWidth;
        width = props.width;
        height = props.height ?? "auto";
        maxWidth = "max-width";
    } else {
        const props = sizeProps as ImageWithSize;
        width = props.size;
        height = props.size;
    }

    if (loading || !src || !loadedSrc) {
        const skeletonAndNormalSx = { ...sx, ...skeletonSx } as SxType;
        return (
            <Skeleton
                variant={skeletonVariant ?? (variant === "circle" ? "circular" : "rectangular")}
                sx={{
                    opacity: hideSkeleton ? 0 : 1,
                    [query.below]: {
                        minHeight: mobileHeight,
                        height: mobileHeight,
                        minWidth: mobileWidth,
                        width: mobileWidth,
                        ...mobileSx
                    },
                    minHeight: height,
                    minWidth: width,
                    width: width,
                    height: height,
                    borderRadius: variant === "rounded" ? BORDER_RADIUS : undefined,
                    ...skeletonAndNormalSx
                }}
            />
        );
    }

    return (
        <StyledImage
            src={loadedSrc}
            onContextMenu={doNothing} // prevent image saving on android
            sx={{
                overflow: "hidden",
                border: showBorder ? border : undefined,
                borderRadius: variant === "circle" ? "100%" : variant === "rounded" ? BORDER_RADIUS : undefined,
                objectFit: ["fixed-height", "fixed-width"].includes(variant) ? "contain" : "cover",
                width,
                [query.below]: {
                    minHeight: mobileHeight,
                    height: mobileHeight,
                    minWidth: mobileWidth,
                    width: mobileWidth,
                    ...mobileSx
                },
                minWidth: width,
                minHeight: height,
                maxWidth,
                height,
                // prevent image saving on safari
                WebkitUserSelect: "none",
                WebkitTouchCallout: "none",
                ...sx
            }}
        />
    );
}

interface CardImageProps extends LoadingCardImageProps {
    overlay?: ReactNode;

    onClick?: () => void;
    sx?: SxType;
    overlayPosition?: "center" | "right-top";
}

export function CardImage({ onClick, overlayPosition = "center", sx, overlay, ...props }: CardImageProps) {
    const overlaySx: SxType = useMemo(() => {
        if (overlayPosition === "center") return { alignItems: "center", justifyContent: "center" };
        return { alignItems: "flex-end", justifyContent: "flex-start" };
    }, [overlayPosition]);
    return (
        <Box
            sx={{
                position: "relative",
                height: "auto",
                cursor: onClick ? "pointer" : undefined,
                overflow: "hidden",
                aspectRatio: "1/1",
                ...sx
            }}
        >
            {overlay && (
                <Stack
                    sx={{
                        zIndex: 10,
                        position: "absolute",
                        right: 0,
                        left: 0,

                        top: 0,
                        bottom: 0,
                        display: "flex",
                        margin: "auto",
                        height: "100%",
                        ...overlaySx
                    }}
                >
                    <Stack p={1}>{overlay}</Stack>
                </Stack>
            )}
            <LoadingCardImage {...props} />
        </Box>
    );
}

interface LoadingCardImageProps {
    src: MayString;
    loading?: boolean;
    imageSx?: SxType;
    hovered?: boolean;
}
function LoadingCardImage({ src, loading, imageSx, hovered }: LoadingCardImageProps) {
    const loadedSrc = useLoadedImageSrc(src);

    if (loading || !src || !loadedSrc) {
        return (
            <Skeleton
                variant="rectangular"
                sx={{
                    width: "100%",
                    height: "0px",
                    paddingBottom: "100%"
                }}
            />
        );
    }

    return (
        <StyledImage
            src={loadedSrc}
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                aspectRatio: "1/1",
                transition: "transform 0.3s",
                transform: hovered ? "scale(1.05)" : undefined,
                ...imageSx
            }}
        />
    );
}

export type OverlappingImagesProps = {
    images: string[] | { src: string; variant: ImageVariant }[] | undefined;
    size: string;
    sx?: SxType;
    maxLength?: number;
    showPlus?: boolean;
    hideRow?: boolean;
    plusVariant?: TextVariant;
};

export function OverlappingImages({
    images,
    size,
    sx,
    maxLength = 3,
    showPlus = true,
    hideRow,
    plusVariant = "body2"
}: OverlappingImagesProps) {
    if (!images) {
        return <Image sx={sx} size={size} variant="circle" src={undefined} />;
    }

    const sources = images.slice(0, maxLength);

    const content = (
        <>
            <OverlappingImagesBase images={sources} size={size} sx={sx} />
            {images.length > maxLength && showPlus && (
                <Text variant={plusVariant} sx={{ ml: 0.5 }} color="disabled">
                    +{images.length - maxLength}
                </Text>
            )}
        </>
    );

    if (hideRow) return content;

    return <Row>{content}</Row>;
}

export function OverlappingImagesBase({ images, sx, size }: Pick<OverlappingImagesProps, "images" | "size" | "sx">) {
    return (
        <>
            {images?.map((image, i) => {
                const src = typeof image === "string" ? image : image?.src;
                const variant: ImageVariant = typeof image === "string" ? "circle" : image.variant;

                return (
                    <Image
                        sx={{ ml: i === 0 ? 0 : -0.5, zIndex: images?.length - i, ...sx }}
                        size={size}
                        variant={variant}
                        key={i}
                        src={src}
                    />
                );
            })}
        </>
    );
}

export function ConnectionImages({
    images,
    size,
    rounded = [true, true],
    centered = true
}: {
    images: [string, string];
    rounded?: [boolean, boolean];
    size: number;
    centered?: boolean;
}) {
    const { divider } = useAppPalette();
    const fill = divider;
    const sizePx = size + "px";

    return (
        <Row sx={{ width: centered ? "100%" : undefined, justifyContent: centered ? "center" : undefined }} spacing={1}>
            <Image border variant={rounded[0] ? "circle" : "square"} src={images[0]} size={sizePx} />
            <ConnectionCircles fill={fill} />
            <Image border variant={rounded[1] ? "circle" : "square"} src={images[1]} size={sizePx} />
        </Row>
    );
}

function ConnectionCircles({ fill }: { fill: string }) {
    const radius = 2.5;
    const offset = 4;
    return (
        <svg width="56" height="12" viewBox="0 0 56 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19.5" cy={radius} r={radius} fill={fill} />
            <circle cx="36.5" cy={radius} r={radius} fill={fill} />
            <circle cx={radius} cy={radius} r={radius} fill={fill} />
            <circle cx="53.5" cy={radius} r={radius} fill={fill} />
            <circle cx="12.5" cy={radius * offset} r={radius} fill={fill} />
            <circle cx="29.5" cy={radius * offset} r={radius} fill={fill} />
            <circle cx="46.5" cy={radius * offset} r={radius} fill={fill} />
        </svg>
    );
}
