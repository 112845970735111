import { FONT_WEIGHTS, Icon, Row, TextLink } from "@bridgesplit/ui";

import { MOBILE_PADDING, NAV_BREAKPOINT } from "./constants";
import ProfileButton from "./ProfileButton";
import NavbarWallet from "./NavbarWallet";
import MobileMenu from "./MobileMenu";
import DialectNotificationButton from "./DialectNotificationButton";

export default function MobileNavbar() {
    return (
        <Row sx={{ p: MOBILE_PADDING, [NAV_BREAKPOINT.above]: { display: "none" } }} spaceBetween>
            <Row sx={{ width: "100%" }} spacing={1}>
                <MobileMenu />
            </Row>
            <TextLink textSx={{ width: "max-content", fontWeight: FONT_WEIGHTS.logo }} to="/">
                <Icon type="logo" />
            </TextLink>

            <Row sx={{ width: "100%", justifyContent: "flex-end" }} spacing={1}>
                <NavbarWallet />
                <DialectNotificationButton />
                <ProfileButton />
            </Row>
        </Row>
    );
}
