import { useMemo } from "react";

import {
    AbfLoanExpanded,
    AbfTransactionDetails,
    WithdrawLoanParams,
    filterSyndicateUnclaimedLedgers,
    useLoanInfos,
    useLockboxByAddress,
    useLockboxWithdrawTransaction,
    useGroup,
    AbfRole,
    useSyndicatedRedeemTransaction,
    isStakedSol,
    useFetchStakeAccounts,
    LockboxStakeWithdrawParams,
    isCollateralStakedSol,
    useLoanWithdrawTransaction,
    useActiveWallet,
    useLoanEndTransaction,
    isSimpleLoan,
    useIsLoanEscrowBased,
    isLoanLiquidated,
    useActiveEscrow,
    LockboxExpanded,
    useEscrowAccounts,
    useActiveGroup,
    canLenderReclaimCollateral,
    useStakedSolFromWalletAsTokens,
    LoanCollateral,
    useLoanBidAccount,
    formatTokens
} from "@bridgesplit/abf-react";
import {
    AbfLoanInfo,
    LockboxAssetUpdateArgs,
    AbfOrderFundingType,
    SyndicatedOrderRedeemArgs,
    LockboxUnlockArgs,
    LockBoxState,
    LockboxAssetType
} from "@bridgesplit/abf-sdk";
import { bsMath, formatPercent, LOADING_ERROR, Result, SOL_DECIMALS } from "@bridgesplit/utils";
import { combineTransactionPromises } from "@bridgesplit/react";
import { COPY } from "app/constants";

import { TransactionDialogSenderOptions, allTransactionsSucceeded, useTransactionSender } from "../transactions";
import { LoanRepaymentDetails } from "./type";

export function useLoanRepaymentDetails(loanInfo: AbfLoanExpanded | undefined) {
    const standard = useLoanRepaymentDetailsStandard(loanInfo);
    const syndicated = useLoanRepaymentDetailsSyndicated(loanInfo);

    if (loanInfo?.type === "syndicated") return syndicated;
    return standard;
}

function useLoanRepaymentDetailsStandard(loanInfo: AbfLoanExpanded | undefined): LoanRepaymentDetails | undefined {
    if (!loanInfo) return undefined;

    const totalPaid = loanInfo?.ledgerAccounts.reduce((prev, curr) => prev + curr.principalPaid + curr.interestPaid, 0);
    const outstandingBalance = loanInfo?.ledgerAccounts
        .filter((prev) => !prev.isFilled)
        .reduce((prev, curr) => prev + curr.principalDue + curr.interestDue, 0);

    const totalClaimed = Math.max(0, totalPaid - loanInfo.claimablePrincipal);

    return { outstandingBalance, totalClaimed, claimableBalance: loanInfo.claimablePrincipal };
}

function useLoanRepaymentDetailsSyndicated(loanInfo: AbfLoanExpanded | undefined): LoanRepaymentDetails | undefined {
    const { groupIdentifier: activeGroupIdentifier } = useActiveGroup();
    if (loanInfo?.type !== "syndicated") {
        return undefined;
    }

    const contributor = loanInfo.syndicateExpanded.contributors.find(
        ({ groupIdentifier }) => groupIdentifier === activeGroupIdentifier
    );

    if (!contributor) return undefined;

    const unclaimedLedgers = filterSyndicateUnclaimedLedgers(contributor.contribution, loanInfo.ledgerAccounts);

    const claimableBalance = unclaimedLedgers.reduce((prev, curr) => prev + curr.principalPaid + curr.interestPaid, 0);

    const outstandingBalance = loanInfo?.ledgerAccounts.reduce(
        (prev, curr) => prev + (curr.principalDue + curr.interestDue) - (curr.principalPaid + curr.interestPaid),
        0
    );

    const principalDepositShare = contributor?.principalDepositShare;

    return {
        totalClaimed: contributor?.contribution?.principalRedeemed || 0,
        claimableBalance: claimableBalance * principalDepositShare,
        outstandingBalance: outstandingBalance * principalDepositShare
    };
}

export function useLoanFromParams(loanAddress: string | undefined) {
    const {
        data: allLoans,
        isLoading,
        isFetching
    } = useLoanInfos({
        loanFilter: { loanAddresses: loanAddress ? [loanAddress] : [] },
        skip: !loanAddress,
        pagination: null
    });
    const { groupIdentifier } = useActiveGroup();

    const loanExpanded = allLoans?.find((l) => l.loan.address === loanAddress);

    return { isLoading, loanExpanded, notFound: !loanExpanded && !isLoading && !isFetching && !!groupIdentifier };
}

export function useRolesFromLoan(loanInfo: AbfLoanInfo | undefined) {
    const { permissions } = useGroup();
    const { escrowPubkeys } = useEscrowAccounts();

    const userIsLender =
        !!escrowPubkeys &&
        !!loanInfo?.loan.lender &&
        escrowPubkeys.includes(loanInfo?.loan.lender) &&
        !!permissions?.has(AbfRole.Lender);

    const userIsBorrower =
        !!escrowPubkeys &&
        !!loanInfo?.loan.borrower &&
        escrowPubkeys.includes(loanInfo?.loan.borrower) &&
        !!permissions?.has(AbfRole.Borrower);

    return { userIsLender, userIsBorrower };
}

export function useLoanLockboxAssets(loanExpanded: AbfLoanExpanded | undefined) {
    const lockboxAssets = loanExpanded?.collateral.filter((l) => !!l.lockboxAmount).length;
    const { data: lockbox } = useLockboxByAddress(loanExpanded?.lockboxAddress, { skip: !lockboxAssets });

    const lockboxHasStake = loanExpanded?.collateral.find((l) => isStakedSol(l.metadata));
    const { fetchStakes, query: stakeAccounts } = useFetchStakeAccounts(loanExpanded?.lockboxAddress, {
        skip: !lockboxHasStake
    });

    // only claim the assets specified
    const collateralCount = loanExpanded?.collateral.length;
    const claimedAssetsCount = bsMath.sub(collateralCount, lockboxAssets);

    const claimNeeded = !!lockboxAssets && !loanExpanded?.loan.refinancedTo;

    return {
        lockbox: lockbox?.data,
        collateralCount,
        claimedAssetsCount,
        claimNeeded,
        fetchStakeSolAccountsIfNeeded: fetchStakes,
        lockboxHasStake,
        stakeAccounts
    };
}

export function useLoanExpandedWithdrawParams(
    loanExpanded: AbfLoanExpanded | undefined,
    activeWallet: string | undefined
) {
    if (!loanExpanded || !activeWallet) return undefined;

    const creditorEscrow = loanExpanded?.lenderEscrow; // use loan object until tokens are tradeable
    const debtorEscrow = loanExpanded?.borrowerEscrow; // use loan object until tokens are tradeable

    const params: WithdrawLoanParams = {
        loan: loanExpanded.loan,
        order: loanExpanded.order,
        creditorAccount: creditorEscrow,
        debtorAccount: debtorEscrow
    };

    return params;
}

function syndicateRepaymentClaimable(
    loanExpanded: AbfLoanExpanded | undefined,
    activeGroupIdentifier: string | undefined
) {
    if (loanExpanded?.type === "syndicated") {
        const contributor = loanExpanded?.syndicateExpanded.contributors.find(
            ({ groupIdentifier }) => groupIdentifier === activeGroupIdentifier
        )?.contribution;

        if (!contributor) {
            return true;
        }
        const unclaimedLedgers = filterSyndicateUnclaimedLedgers(contributor, loanExpanded.ledgerAccounts);
        const params = unclaimedLedgers.map(
            (ledger): SyndicatedOrderRedeemArgs => ({
                ledgerId: ledger.ledgerId,
                syndicatedOrder: loanExpanded?.syndicateExpanded.syndicate.address,
                fundNftMint: contributor.nftMint
            })
        );

        return params.length === 0;
    }
    return true;
}

export function useLoanCompleteData(loanExpanded: AbfLoanExpanded | undefined) {
    const { groupIdentifier } = useActiveGroup();
    const isSyndicated = loanExpanded?.type === "syndicated";

    const creditClaimed = isSyndicated
        ? syndicateRepaymentClaimable(loanExpanded, groupIdentifier)
        : loanExpanded?.loan.creditClaimed;
    const debtClaimed = isSyndicated ? false : loanExpanded?.loan.debtClaimed;
    return { creditClaimed, debtClaimed, isSyndicated };
}

export function useWithdrawLoanCredit(loanExpanded: AbfLoanExpanded | undefined) {
    const syndicatedRedeem = useSyndicatedRedeemTransaction();
    const loanWithdraw = useLoanWithdrawTransaction();

    const { activeWallet } = useActiveWallet();
    const send = useTransactionSender();
    const { groupIdentifier: activeGroupIdentifier } = useActiveGroup();
    const params = useLoanExpandedWithdrawParams(loanExpanded, activeWallet?.wallet);

    return async function withdraw() {
        if (!loanExpanded || !params) return Result.errFromMessage(LOADING_ERROR);
        const options: TransactionDialogSenderOptions = { description: "Claiming Payments" };
        if (loanExpanded.type === "syndicated") {
            const contributor = loanExpanded.syndicateExpanded.contributors.find(
                ({ groupIdentifier }) => groupIdentifier === activeGroupIdentifier
            )?.contribution;

            if (!contributor) {
                return Result.errFromMessage("Unable to find your syndicate");
            }
            const unclaimedLedgers = filterSyndicateUnclaimedLedgers(contributor, loanExpanded.ledgerAccounts);
            const params = unclaimedLedgers.map(
                (ledger): SyndicatedOrderRedeemArgs => ({
                    ledgerId: ledger.ledgerId,
                    syndicatedOrder: loanExpanded.syndicateExpanded.syndicate.address,
                    fundNftMint: contributor.nftMint
                })
            );
            return await send(syndicatedRedeem, params, options);
        }

        return await send(loanWithdraw, params, options);
    };
}

type LockboxTokenWithdraw = { mint: string; amount: number; assetType: LockboxAssetType };

type MultiLockboxWithdrawParams = {
    unlockLockbox: boolean;
    tokens: LockboxTokenWithdraw[];
    hasStakedSol: boolean;
    description: string;
    lockboxAddress: string | undefined;
    escrowNeeded: boolean;
    escrowAccount: string | null; // null if withdrawn from wallet
    escrowNonce?: string; // fallback on looking up matching nonce from user escrows
};
export function useWithdrawAllLockboxAssets() {
    const send = useTransactionSender();

    const lockboxWithdraw = useLockboxWithdrawTransaction();

    const { activeWallet } = useActiveWallet();
    const { groupIdentifier } = useActiveGroup();
    const { escrows } = useEscrowAccounts();
    const { escrowNonce: activeEscrowNonce, activeEscrow } = useActiveEscrow();
    const { fetchStakes } = useFetchStakeAccounts(undefined);

    async function getWithdrawParams({
        tokens,
        unlockLockbox,
        hasStakedSol,
        escrowAccount,
        lockboxAddress,
        escrowNeeded
    }: MultiLockboxWithdrawParams): Promise<Result<LockboxStakeWithdrawParams>> {
        if (!activeWallet || !groupIdentifier || !lockboxAddress) return Result.errFromMessage(LOADING_ERROR);

        const escrowNonce = escrows?.find((e) => e.address === escrowAccount)?.nonce ?? activeEscrowNonce;

        let stakeArgs: LockboxStakeWithdrawParams["stakedSol"] | null = null;
        if (hasStakedSol) {
            const stakeAccountsRes = await fetchStakes(lockboxAddress);
            if (!stakeAccountsRes.isOk()) return Result.err(stakeAccountsRes);
            const stakeAccounts = stakeAccountsRes.unwrap();

            stakeArgs = {
                groupIdentifier,
                escrowNonce,
                lockbox: lockboxAddress,
                escrow: escrowNeeded,
                stakeAccounts: stakeAccounts.map((s) => ({
                    address: s.stakeAccount,
                    amountToTransfer: bsMath.tokenAdd(s.amount, s.rentExemptReserve, SOL_DECIMALS)
                }))
            };
        }

        const lockboxWithdrawArgs = tokens
            .filter((c) => !!c.amount)
            ?.map(
                (asset): LockboxAssetUpdateArgs => ({
                    assetMint: asset.mint,
                    amount: asset.amount,
                    user: activeWallet.wallet,
                    lockbox: lockboxAddress,
                    escrowDeposit: escrowNeeded,
                    escrowAccount,
                    assetType: asset.assetType
                })
            );

        const unlock: LockboxUnlockArgs | null = unlockLockbox
            ? {
                  user: activeWallet.wallet,
                  escrowDeposit: escrowNeeded,
                  lockbox: lockboxAddress,
                  escrowAccount: escrowAccount ?? activeEscrow ?? ""
              }
            : null;

        const params: LockboxStakeWithdrawParams = { unlock, lockbox: lockboxWithdrawArgs, stakedSol: stakeArgs };
        return Result.ok(params);
    }

    async function withdrawAndSend(params: MultiLockboxWithdrawParams) {
        const body = await getWithdrawParams(params);
        if (!body.isOk()) return body;
        return await send(lockboxWithdraw, body.unwrap(), { description: params.description });
    }

    return { withdrawAndSend, getWithdrawParams };
}

export function useWithdrawLoanToken({
    loanExpanded,
    withdrawTransaction,
    side
}: {
    loanExpanded: AbfLoanExpanded;
    withdrawTransaction: AbfTransactionDetails<WithdrawLoanParams>;
    side: "credit" | "debt";
}) {
    const send = useTransactionSender();
    const { lockbox, lockboxHasStake } = useLoanLockboxAssets(loanExpanded);

    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const { withdrawAndSend: withdraw } = useWithdrawAllLockboxAssets();

    const { activeWallet } = useActiveWallet();
    const { groupIdentifier } = useActiveGroup();

    const withdrawParams = useLoanExpandedWithdrawParams(loanExpanded, activeWallet?.wallet);
    const simpleLoanEnd = useLoanEndTransaction();

    return async () => {
        const escrowAccount = side === "credit" ? loanExpanded.lenderEscrow : loanExpanded.borrowerEscrow;

        if (!activeWallet || !groupIdentifier || !escrowAccount) return Result.errFromMessage(LOADING_ERROR);

        if (
            isSimpleLoan(loanExpanded) &&
            !loanExpanded.loan.creditClaimed &&
            !loanExpanded.loan.debtClaimed &&
            !isLoanLiquidated(loanExpanded) // liquidated loans must be claimed manually
        ) {
            if (!withdrawParams) return Result.errFromMessage(LOADING_ERROR);

            return await send(simpleLoanEnd, withdrawParams);
        }
        const tokens = loanExpanded.collateral
            .filter((c) => !!c.lockboxAmount && !isCollateralStakedSol(c))
            .map((l): LockboxTokenWithdraw => {
                if (l.whirlpoolPosition) {
                    return {
                        amount: l.lockboxAmount,
                        mint: l.mint,
                        assetType: { OrcaPosition: l.whirlpoolPosition.position.address }
                    };
                }
                return { amount: l.lockboxAmount, mint: l.mint, assetType: "SplToken" };
            });
        const unlockLockbox = lockbox?.state === LockBoxState.Locked;

        if (
            side === "credit"
                ? loanExpanded.loan.creditClaimed || isLoanLiquidated(loanExpanded) // if loan was liquidated, creditClaimed wont be claimed
                : loanExpanded.loan.debtClaimed
        ) {
            return await withdraw({
                lockboxAddress: lockbox?.address,
                unlockLockbox,
                tokens,
                hasStakedSol: !!lockboxHasStake,
                description: "Claiming Collateral",
                escrowNeeded,
                escrowAccount
            });
        } else {
            const res = await send(withdrawTransaction, withdrawParams, {
                description: "Closing Loan (1 of 2)",
                sendOptions: { commitmentLevel: "confirmed" }
            });

            if (!allTransactionsSucceeded(res)) return res;

            return await withdraw({
                lockboxAddress: lockbox?.address,
                unlockLockbox,
                tokens,
                hasStakedSol: !!lockboxHasStake,
                description: "Claiming Collateral (2 of 2)",
                escrowNeeded,
                escrowAccount
            });
        }
    };
}

export function getLoanLenderName(loanExpanded: AbfLoanExpanded | undefined) {
    if (loanExpanded?.order.fundingType === AbfOrderFundingType.Syndicated) return "Multiple Lenders";
    return loanExpanded?.lender.groupName;
}

export function calculateNewInterestForLedger(
    newInterestOwed: number,
    totalInterestOutstanding: number,
    interestOutstandingForLedger: number,
    recalculateInterest: boolean,
    decimals: number | undefined
) {
    const totalInterestOutstandingDenom = totalInterestOutstanding || 1;
    const interestRatio = bsMath.tokenAMul(newInterestOwed, 1 / totalInterestOutstandingDenom, decimals);
    const newLedgerInterestOutstanding = recalculateInterest
        ? bsMath.tokenAMul(interestOutstandingForLedger, interestRatio, decimals)
        : interestOutstandingForLedger;

    return newLedgerInterestOutstanding;
}

type WithdrawAllFromLockbox = {
    lockbox: LockboxExpanded | undefined;
    escrowNeeded: boolean;
    escrowAccount: string | undefined | null;
};
export function useWithdrawAllAssetsFromLockbox() {
    const { getWithdrawParams } = useWithdrawAllLockboxAssets();
    const lockboxWithdraw = useLockboxWithdrawTransaction();
    const send = useTransactionSender();

    return async (params: WithdrawAllFromLockbox[]) => {
        const withdrawParams = await Promise.all(
            params.map(
                async ({ lockbox, escrowAccount, escrowNeeded }): Promise<Result<LockboxStakeWithdrawParams>> => {
                    if (!lockbox || escrowAccount === undefined) {
                        return Result.errFromMessage(LOADING_ERROR);
                    }
                    return await getWithdrawParams({
                        lockboxAddress: lockbox?.data.address,
                        unlockLockbox: lockbox.data.state === LockBoxState.Locked,
                        description: "Withdrawing assets",
                        escrowNeeded,
                        hasStakedSol: !!lockbox?.assets.find((s) => isStakedSol(s.assetTypeDiscriminator)),
                        tokens: lockbox.assets
                            .filter((s) => !isStakedSol(s.assetTypeDiscriminator))
                            .map(({ amount, assetKey, whirlpoolPosition }) => {
                                if (whirlpoolPosition) {
                                    return {
                                        amount,
                                        mint: assetKey,
                                        assetType: { OrcaPosition: whirlpoolPosition.position.address }
                                    };
                                }
                                return {
                                    amount,
                                    mint: assetKey,
                                    assetType: "SplToken"
                                };
                            }),
                        escrowAccount
                    });
                }
            )
        );
        const combined = Result.combine(withdrawParams);

        if (!combined.isOk()) return Result.err(combined);

        return await send(
            {
                description: "Withdrawing assets",
                sendOptions: lockboxWithdraw.sendOptions,
                getTransactionsWithParams: async (params) => {
                    return await combineTransactionPromises(
                        params.map(async (param) => {
                            const txnsRes = await lockboxWithdraw.getTransactionsWithParams(param);
                            const txns = txnsRes.unwrapOr([]);
                            return Result.ok(txns.map((t) => t.transactions).flat());
                        }),
                        { order: "parallel" }
                    );
                }
            },
            combined.unwrap()
        );
    };
}

export function useCompletedLoanDescription(loanExpanded: AbfLoanExpanded | undefined) {
    const { claimNeeded } = useLoanLockboxAssets(loanExpanded);
    const { creditClaimed } = useLoanCompleteData(loanExpanded);
    const escrowNeeded = useIsLoanEscrowBased()(loanExpanded);

    const { userIsLender, userIsBorrower } = useRolesFromLoan(loanExpanded);

    if (userIsBorrower) {
        if (claimNeeded) {
            return "You can now reclaim your collateral";
        }
        if (loanExpanded?.loan.refinancedTo) return "Your loan has been refinanced";
        return `All payments have been completed. Your collateral has been returned to ${
            escrowNeeded ? `your ${COPY.ESCROW_TERM_FULL.toLowerCase()}` : "your wallet"
        }  `;
    }
    if (userIsLender) {
        if (creditClaimed) {
            return `All repayments have been sent ${
                escrowNeeded
                    ? `to your ${COPY.ESCROW_TERM_FULL.toLowerCase()}`
                    : `back to your ${COPY.STRATEGY_TERM.toLowerCase()}`
            }`;
        }
        return "You can now claim any remaining payments";
    }

    return null;
}

export function useDefaultedLoanDescription(loanExpanded: AbfLoanExpanded | undefined) {
    const { claimNeeded } = useLoanLockboxAssets(loanExpanded);
    const { isSyndicated } = useLoanCompleteData(loanExpanded);

    const wasLiquidated = isLoanLiquidated(loanExpanded);

    const canLenderReclaim = canLenderReclaimCollateral(loanExpanded);

    const { userIsLender, userIsBorrower } = useRolesFromLoan(loanExpanded);
    const { data, isLoading } = useLoanBidAccount(loanExpanded);

    if (isLoading) return null;

    if (userIsBorrower) {
        const liquidatedText = (() => {
            if (!data?.bidData.closed) return "Your collateral is being liquidated to repay the lender";
            if (data.percentLiquidated === 1) return "Your collateral has been liquidated to repay the loan";
            const collateralLiquidated = `${formatPercent(
                data.percentLiquidated
            )} of your collateral was liquidated to repay the lender`;
            if (!data.collateralRetained.length) return collateralLiquidated;
            return `${collateralLiquidated}. The remaining ${formatTokens(
                data.collateralRetained
            )} was returned to your wallet`;
        })();

        return `You defaulted on the loan. ${liquidatedText}`;
    }

    if (userIsLender) {
        const defaultText = wasLiquidated
            ? "The borrower defaulted on the loan due insufficient collateral"
            : "The borrower failed to repay and has defaulted";
        if (isSyndicated) {
            return `${defaultText}. The seized collateral will be liquidated via a separate process through Loopscale`;
        }
        if (!canLenderReclaim) {
            if (!data?.bidData.closed)
                return "The borrower defaulted on the loan. Their collateral will be used to repay outstanding debt";
            return `${defaultText}. Their outstanding debt has been returned to your ${COPY.STRATEGY_TERM.toLowerCase()}`;
        }
        if (!claimNeeded) {
            return `${defaultText}. You have already seized the collateral`;
        }

        return `${defaultText}. You can seize this asset at any time`;
    }

    return null;
}

export function useBackfillLoan(loanExpanded: AbfLoanExpanded | undefined): AbfLoanExpanded | undefined {
    const lockboxHasStake = loanExpanded?.collateral.find((l) => isStakedSol(l.metadata));
    const { data: stakesRaw, isFetching } = useStakedSolFromWalletAsTokens(loanExpanded?.lockboxAddress, {
        skip: !lockboxHasStake
    });
    const stakes = useMemo(() => (lockboxHasStake ? stakesRaw : []), [lockboxHasStake, stakesRaw]);
    const stakeMap = useMemo(() => (stakes ? new Map(stakes?.map((s) => [s.key, s])) : undefined), [stakes]);

    const collateral = stakes
        ? loanExpanded?.collateral.map((asset): LoanCollateral => {
              if (!isStakedSol(asset.assetTypeDiscriminator)) return asset;

              const stake = stakeMap?.get(asset.key);
              const copy = { ...asset };
              if (stake?.metadata) {
                  copy.metadata = stake.metadata;
              }

              return {
                  ...copy,
                  lockboxAmount: stake?.amount ?? 0,
                  stakeAccount: stake?.stakeAccount
              };
          })
        : undefined;

    if (!loanExpanded || !collateral || isFetching) return undefined;

    return { ...loanExpanded, collateral };
}
