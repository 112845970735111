import { ReactNode } from "react";

import {
    BORDER_RADIUS,
    CARD_WIDTH,
    Column,
    HeaderCardWithAction,
    MEDIA,
    MediaQuery,
    MediaStack,
    Row,
    SPACING,
    ShadowCard,
    SxType,
    useAppPalette
} from "@bridgesplit/ui";

import { CARD_Z_INDEX, NAV_BREAKPOINT, NAV_Z_INDEX, useNavbarHeight } from "../navbar";

export function FullOverlayWrapper({ children, sx }: { children: ReactNode; sx?: SxType }) {
    const { background } = useAppPalette();
    const { fullHeight, height } = useNavbarHeight();
    return (
        <Column
            sx={{
                background,
                width: "100vw",
                top: height,
                height: fullHeight,
                position: "fixed",
                ...sx
            }}
        >
            {children}
        </Column>
    );
}

type CardWrapperProps = {
    breakpoint?: MediaQuery;
    contentSpacing?: number;
    pageSpacing?: number;
    cardSx?: SxType;
    children: ReactNode;
    cardDisplay?: "important" | "checkout";
};
export function ActionCardWrapper({
    breakpoint = MEDIA.XL,
    contentSpacing = 3,
    pageSpacing = 4,
    cardElement,
    cardSx,
    children,
    cardDisplay = "checkout"
}: CardWrapperProps & { cardElement: ReactNode }) {
    const { stickyTop } = useNavbarHeight();
    return (
        <MediaStack
            sxBelow={{ flexDirection: cardDisplay === "important" ? "column-reverse" : undefined }}
            query={breakpoint}
            spacing={pageSpacing}
            sxAbove={{ alignItems: "flex-start" }}
        >
            <Column spacing={contentSpacing} sx={{ width: "100%" }}>
                {children}
            </Column>
            {!!cardElement && (
                <ShadowCard
                    spacing={2}
                    sx={{
                        p: 2,
                        [breakpoint.above]: {
                            width: `calc(100% - ${SPACING * 2}px)`,
                            maxWidth: CARD_WIDTH,
                            minWidth: CARD_WIDTH,
                            borderRadius: BORDER_RADIUS,
                            top: stickyTop,
                            position: "sticky",
                            height: "fit-content",
                            zIndex: CARD_Z_INDEX
                        },
                        ...cardSx
                    }}
                >
                    {cardElement}
                </ShadowCard>
            )}
        </MediaStack>
    );
}

export function SideCardWrapper({
    breakpoint = MEDIA.XL,
    contentSpacing = 4,
    pageSpacing = 4,
    sideCard,
    cardSx,
    children,
    reversed
}: CardWrapperProps & { sideCard: ReactNode; reversed?: boolean }) {
    const { stickyTop } = useNavbarHeight();
    return (
        <MediaStack
            query={breakpoint}
            spacing={pageSpacing}
            sxBelow={{ flexDirection: reversed ? "column-reverse" : "column" }}
            sxAbove={{ alignItems: "flex-start", flexDirection: reversed ? "row-reverse" : "row" }}
        >
            <Column spacing={contentSpacing} sx={{ width: "100%" }}>
                {children}
            </Column>
            <Column
                spacing={2}
                sx={{
                    width: "100%",
                    [breakpoint.above]: {
                        maxWidth: CARD_WIDTH,
                        top: stickyTop,
                        position: "sticky",
                        height: "fit-content",
                        zIndex: CARD_Z_INDEX
                    },
                    ...cardSx
                }}
            >
                {sideCard}
            </Column>
        </MediaStack>
    );
}

export function SideHeaderCard({
    breakpoint = MEDIA.XL,
    actionElement,
    children,
    header
}: {
    breakpoint?: MediaQuery;
    actionElement?: ReactNode;
    children: ReactNode;
    header: string;
}) {
    const { border, cardBackground } = useAppPalette();
    return (
        <Column
            sx={{
                width: "100%",
                [breakpoint.above]: {
                    background: cardBackground,
                    py: 2,
                    border,
                    borderRadius: BORDER_RADIUS
                }
            }}
        >
            <HeaderCardWithAction
                headerVariant="h4"
                childrenSx={{ [breakpoint.above]: { px: 2 } }}
                headerSx={{ [breakpoint.above]: { px: 2 } }}
                spacing={2}
                wrapperSx={{
                    width: "100%"
                }}
                header={header}
                actionElement={actionElement}
            >
                {children}
            </HeaderCardWithAction>
        </Column>
    );
}

export function MobileBottomCta({ children }: { children: ReactNode }) {
    const { navFooter, footer } = useNavbarHeight();
    const { background } = useAppPalette();

    return (
        <>
            <Row
                sx={{
                    zIndex: NAV_Z_INDEX - 1,
                    position: "fixed",
                    bottom: footer,
                    [NAV_BREAKPOINT.below]: { bottom: navFooter + footer },
                    left: 0,
                    width: "100vw",
                    background
                }}
            >
                <Column sx={{ flexGrow: 1 }}>
                    <Row sx={{ m: 2, flexGrow: 1 }} spacing={1}>
                        {children}
                    </Row>
                </Column>
            </Row>
            <Column id="virtual-cta" sx={{ height: "40px" }} />
        </>
    );
}

export function FullPageMobile({ children }: { children: ReactNode }) {
    const { height } = useNavbarHeight();
    return (
        <Column sx={{ position: "fixed", top: height, height: "100vh", width: "100vw", zIndex: NAV_Z_INDEX - 1 }}>
            {children}
        </Column>
    );
}
