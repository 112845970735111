import { useEffect } from "react";

import { DispatchType } from "@bridgesplit/react";
import {
    Button,
    ColorExtension,
    Column,
    Icon,
    OrDivider,
    PrimeSvg,
    Span,
    TagText,
    WalletButtons,
    useAppPalette,
    useWalletUtils
} from "@bridgesplit/ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { OpenInNewOutlined } from "@mui/icons-material";
import { useUserIsPrime } from "@bridgesplit/abf-react";
import { WALLET_HELP_URL } from "app/constants";

import { WalletSetupStep } from "./util";
import { DialogHeader } from "../../common";

export default function WalletSetupSetWalletType({ setStep }: { setStep: DispatchType<WalletSetupStep> }) {
    const { connected } = useWallet();
    const { installedWallets } = useWalletUtils();

    useEffect(() => {
        if (connected) {
            setStep(WalletSetupStep.Wallet);
        }
    }, [connected, setStep]);

    return (
        <>
            <DialogHeader
                header="Register a wallet"
                description="You’ll need to link your account to a wallet to continue"
            />

            <Column spacing={1}>
                {installedWallets.length ? <SolanaWallets setStep={setStep} /> : <NoSolanaWallets setStep={setStep} />}
            </Column>
        </>
    );
}

function SolanaWallets({ setStep }: { setStep: DispatchType<WalletSetupStep> }) {
    return (
        <>
            <WalletButtons />
            <OrDivider />
            <PasskeyButton setStep={setStep} />
        </>
    );
}

function NoSolanaWallets({ setStep }: { setStep: DispatchType<WalletSetupStep> }) {
    return (
        <>
            <WalletButtons />

            <Button
                textProps={{ sx: { gap: 1.5 } }}
                sx={{ justifyContent: "flex-start" }}
                onClick={() => window.open(WALLET_HELP_URL)}
                variant="outlined"
            >
                <OpenInNewOutlined /> Create a Solana wallet
            </Button>
            <OrDivider />
            <PasskeyButton setStep={setStep} />
        </>
    );
}

function PasskeyButton({ setStep }: { setStep: DispatchType<WalletSetupStep> }) {
    const { isPrime } = useUserIsPrime();
    const { textDisabled } = useAppPalette();

    return (
        <Button
            onClick={() => setStep(isPrime ? WalletSetupStep.Mpc : WalletSetupStep.MpcPrimeGated)}
            variant="outlined"
            textProps={{ sx: { width: "100%", justifyContent: "space-between" } }}
        >
            <Span sx={{ gap: 1.5, display: "flex" }}>
                <Icon sx={{ color: textDisabled }} type="passkey" />
                Create a Passkey
            </Span>
            <TagText textVariant="body2" variant="basic" color={ColorExtension.Prime}>
                <PrimeSvg /> Prime only
            </TagText>
        </Button>
    );
}
