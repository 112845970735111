import { AbfCustodian, BsMetadata } from "@bridgesplit/abf-sdk";
import { filterTruthy } from "@bridgesplit/utils";

import { useBsMetadataByMints, useCustodiansByIdentifiers } from "../reducers";

export type ExpandedBsMeta<T> = T & {
    metadata: BsMetadata;
    custodian: AbfCustodian;
};

export function useExpandedBsMetadata<T>(
    data: T[] | undefined,
    metadataKey: (keyof T & string) | ((t: T) => string)
): ExpandedBsMeta<T>[] | undefined {
    const mappedMints =
        typeof metadataKey === "string"
            ? (data?.map((d) => d[metadataKey]).filter((d) => typeof d === "string") as string[] | undefined)
            : data?.map((t) => metadataKey(t));

    const { getMetadata, isLoading: metaLoading } = useBsMetadataByMints(mappedMints);

    const custodianIdentifiers = mappedMints?.map((d) => getMetadata(d)?.assetOriginator).filter(filterTruthy);

    const { cache: custodianMap, isLoading: groupLoading } = useCustodiansByIdentifiers(custodianIdentifiers);

    const dataWithMetadata = data
        ?.map((d) => {
            const metadata =
                typeof metadataKey === "string" ? getMetadata(d[metadataKey] as string) : getMetadata(metadataKey(d));

            const custodian = metadata?.assetOriginator ? custodianMap?.get(metadata.assetOriginator) : undefined;
            return {
                ...d,
                custodian,
                metadata
            };
        })
        .filter((d): d is ExpandedBsMeta<T> => !!d.custodian && !!d.metadata);

    if (metaLoading || groupLoading) return undefined;
    return dataWithMetadata;
}
