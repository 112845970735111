export const ALLOWED_FEATURES = {
    markets: !!process.env.NX_MARKETS_ALLOWED,
    disableFinalized: !!process.env.NX_DISABLE_FINALIZED,
    portfolioGraph: false,
    newPasskeys: !!process.env.NX_NEW_PASSKEYS,
    allowLowLtv: !!process.env.NX_ALLOW_LOW_LTV,
    jitoLongCheck: !!process.env.JITO_LONG_CHECK,
    disableJito: false,
    disableEmailSignup: true,
    disableMultiTopUp: true
};
