import { createApi } from "@reduxjs/toolkit/query/react";
import {
    PublicKeyCredentialCreationOptionsServer,
    CompleteCredentialRegistration,
    LoginResponseServer,
    UserActionData,
    DfnsUserCredentials,
    DfnsActionType,
    CompleteUserLoginRequestWithHistory,
    PasskeyHistory,
    ApproveNewCredentialMetadata,
    CreateMultiChainWalletArgs
} from "@bridgesplit/abf-sdk";
import { useDispatch } from "react-redux";

import {
    CREATE_MULTICHAIN_WALLET_ROUTE,
    MPC_COMPLETE_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE,
    MPC_CREATE_CHALLENGE_ROUTE,
    MPC_CREATE_PAT_ROUTE,
    MPC_START_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE,
    MPC_USER_CREDENTIALS_ROUTE,
    MPC_USER_LOGIN_ROUTE,
    MPC_USER_REGISTRATION_ROUTE,
    PASSKEY_HISTORY_ROUTE
} from "../constants";
import { abfSerializeQueryArgs, mpcBaseQuery } from "./util";

const MPC_CREDENTIALS = "MpcCredentials";
export const mpcApi = createApi({
    reducerPath: "mpcApi",
    tagTypes: [MPC_CREDENTIALS],
    baseQuery: mpcBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        mpcUserCredentials: builder.query<DfnsUserCredentials, void>({
            providesTags: [MPC_CREDENTIALS],
            query() {
                return {
                    url: MPC_USER_CREDENTIALS_ROUTE,
                    method: "GET"
                };
            }
        }),
        passkeyHistory: builder.query<PasskeyHistory[], void>({
            providesTags: [MPC_CREDENTIALS],
            query() {
                return {
                    url: PASSKEY_HISTORY_ROUTE,
                    method: "GET"
                };
            }
        }),
        initiateMpcUserRegistration: builder.mutation<PublicKeyCredentialCreationOptionsServer, void>({
            query() {
                return {
                    url: MPC_USER_REGISTRATION_ROUTE,
                    method: "GET"
                };
            }
        }),
        completeUserRegistration: builder.mutation<void, CompleteCredentialRegistration>({
            invalidatesTags: [MPC_CREDENTIALS],
            query(body) {
                return {
                    url: MPC_USER_REGISTRATION_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        initiateMpcUserLogin: builder.mutation<LoginResponseServer, void>({
            query() {
                return {
                    url: MPC_USER_LOGIN_ROUTE,
                    method: "GET"
                };
            }
        }),
        completeMpcUserLogin: builder.mutation<void, CompleteUserLoginRequestWithHistory>({
            invalidatesTags: [MPC_CREDENTIALS],
            query(body) {
                return {
                    url: MPC_USER_LOGIN_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        initiateNewCredentialRegistration: builder.mutation<PublicKeyCredentialCreationOptionsServer, void>({
            query() {
                return {
                    url: MPC_START_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE,
                    method: "GET"
                };
            }
        }),
        completeNewCredentialRegistration: builder.mutation<void, CompleteCredentialRegistration>({
            invalidatesTags: [MPC_CREDENTIALS],
            query(body) {
                return {
                    url: MPC_START_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        approveNewCredentialRegistration: builder.mutation<void, ApproveNewCredentialMetadata>({
            invalidatesTags: [MPC_CREDENTIALS],
            query(body) {
                return {
                    url: MPC_COMPLETE_CREDENTIAL_REGISTER_CREDENTIAL_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        createMpcChallenge: builder.mutation<LoginResponseServer, DfnsActionType>({
            query(actionType) {
                return {
                    url: `${MPC_CREATE_CHALLENGE_ROUTE}/${actionType}`,
                    method: "GET"
                };
            }
        }),
        createMpcPat: builder.mutation<void, UserActionData>({
            invalidatesTags: [MPC_CREDENTIALS],
            query(body) {
                return {
                    url: MPC_CREATE_PAT_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        createEvmMpcWallet: builder.mutation<void, CreateMultiChainWalletArgs>({
            query(body) {
                return {
                    url: CREATE_MULTICHAIN_WALLET_ROUTE,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {
    usePasskeyHistoryQuery,
    useInitiateMpcUserRegistrationMutation,
    useCompleteUserRegistrationMutation,
    useCreateMpcChallengeMutation,
    useCreateMpcPatMutation,
    useInitiateNewCredentialRegistrationMutation,
    useCompleteNewCredentialRegistrationMutation,
    useApproveNewCredentialRegistrationMutation,
    useMpcUserCredentialsQuery,
    useInitiateMpcUserLoginMutation,
    useCompleteMpcUserLoginMutation,
    useCreateEvmMpcWalletMutation
} = mpcApi;

export const useMpcApi = () => {
    const dispatch = useDispatch();
    // refetch data
    return {
        resetMpcApi: () => dispatch(mpcApi.util.invalidateTags([MPC_CREDENTIALS]))
    };
};
