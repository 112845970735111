import { AbfRole, AbfGroupWithRoles } from "../types";

const ALL_ROLES: AbfRole[] = Object.values(AbfRole);
export class AbfUserPermissions {
    private roles: Set<AbfRole> = new Set();
    private groupCustodianPermissions: Map<string, AbfRole[]> = new Map();
    private groupIdentifier: string | undefined;

    constructor({ roles, group }: AbfGroupWithRoles, groupCustodianPermissions: Record<string, AbfRole[]>) {
        let rolesSet = new Set(roles);
        const isGroupAdmin = rolesSet.has(AbfRole.Admin) || rolesSet.has(AbfRole.SuperAdmin);

        // add any additional non-custodian based roles
        if (isGroupAdmin) {
            rolesSet = new Set(ALL_ROLES);
        }

        this.groupIdentifier = group?.groupIdentifier;
        this.roles = rolesSet;
        this.groupCustodianPermissions = new Map(Object.entries(groupCustodianPermissions));
    }

    private validateRoles(acceptedRoles: AbfRole[], userRoles: Set<AbfRole>): boolean {
        if (acceptedRoles.length === 0) return true; // public
        const validatedRoles = acceptedRoles.filter((role) => userRoles.has(role));
        return !!validatedRoles.length;
    }

    /**
     * Validate that a user has permission from their roles
     * @param permission determine if this user has matching roles
     * @returns user has this permission
     */
    validate(permission: AbfPermission): boolean {
        const acceptedRoles = permissionMap[permission].acceptedRoles;
        return this.validateRoles(acceptedRoles, this.roles);
    }

    /**
     * Validate that a user has permission from their roles
     * @param permission determine if this user has matching roles
     * @returns user has this permission
     */
    validateForCustodian(custodianIdentifier: string, permission: AbfPermissionForCustodian): boolean {
        if (custodianIdentifier === this.groupIdentifier) return true;
        const { acceptedRoles } = permissionCustodianMap[permission];

        // if permissioned, then check that group has permissions first
        const activeGroupPermissions = this.groupCustodianPermissions.get(custodianIdentifier);

        // group doesn't have perms for this custodian
        if (!activeGroupPermissions) return false;

        const groupHasPermissions = this.validateRoles(acceptedRoles, new Set(activeGroupPermissions));
        if (!groupHasPermissions) return false;

        // if custodian policy is public, then validate roles as normal
        return this.validateRoles(acceptedRoles, this.roles);
    }

    validateForCustodians(custodianIdentifiers: string[] | undefined, permission: AbfPermissionForCustodian) {
        if (!custodianIdentifiers?.length) return false;
        let valid = true;
        custodianIdentifiers.forEach((custodianIdentifier) => {
            const validated = this.validateForCustodian(custodianIdentifier, permission);
            if (!validated) {
                valid = false;
            }
        });
        return valid;
    }

    has(role: AbfRole): boolean {
        return this.roles.has(role);
    }

    isAdmin(): boolean {
        return this.roles.has(AbfRole.Admin) || this.roles.has(AbfRole.SuperAdmin);
    }
}

export enum AbfPermissionForCustodian {
    CreateBorrowRequest = "CreateBorrowRequest",
    CreateLendOrder = "CreateLendOrder",
    CancelLendOrder = "CancelLendOrder",
    FillLendOrder = "FillLendOrder",
    RepayLoan = "RepayLoan",
    ClaimRepayment = "ClaimRepayment",
    WithdrawDebtNote = "WithdrawDebtNote",
    WithdrawCreditNote = "WithdrawCreditNote",
    ViewLoans = "ViewLoans"
}

export enum AbfPermission {
    CreateStrategy = "CreateStrategy",
    ViewPortfolioOverview = "ViewPortfolioOverview",
    ViewBorrowPortfolio = "ViewBorrowPortfolio",
    ViewLenderPortfolio = "ViewLenderPortfolio",
    CreateBorrowRequest = "CreateBorrowRequest",
    ModifyBorrowRequest = "ModifyBorrowRequest",
    ViewStrategy = "ViewStrategy",

    ViewLoanPortfolio = "ViewLoanPortfolio",
    ViewAsset = "ViewAsset",
    ViewMakeOffer = "ViewMakeOffer",
    ViewCustodians = "ViewCustodians",
    DepositFunds = "DepositFunds",
    WithdrawFunds = "WithdrawFunds",
    DepositTokens = "DepositTokens",
    ValidateMembers = "ValidateMembers",
    ModifyMembers = "ModifyMembers",
    WithdrawTokens = "WithdrawTokens"
}

/**
 * Used to determine granular permissions from broader Roles
 * @constant acceptedRoles accept ANY of these roles (OR). Empty array is public
 */
const permissionMap: Record<AbfPermission, { acceptedRoles: AbfRole[] }> = {
    [AbfPermission.ViewPortfolioOverview]: { acceptedRoles: [AbfRole.Borrower, AbfRole.Lender] },
    [AbfPermission.ViewStrategy]: { acceptedRoles: [AbfRole.Lender] },
    [AbfPermission.CreateStrategy]: { acceptedRoles: [AbfRole.Lender] },
    [AbfPermission.ViewLoanPortfolio]: { acceptedRoles: [AbfRole.Borrower, AbfRole.Lender] },
    [AbfPermission.ViewBorrowPortfolio]: { acceptedRoles: [AbfRole.Borrower] },
    [AbfPermission.ViewLenderPortfolio]: { acceptedRoles: [AbfRole.Lender] },
    [AbfPermission.ViewAsset]: { acceptedRoles: [] },
    [AbfPermission.ViewCustodians]: { acceptedRoles: [] },
    [AbfPermission.CreateBorrowRequest]: { acceptedRoles: [AbfRole.Borrower] },
    [AbfPermission.ModifyBorrowRequest]: { acceptedRoles: [AbfRole.Borrower] },
    [AbfPermission.ViewMakeOffer]: { acceptedRoles: [AbfRole.Lender] },
    [AbfPermission.DepositFunds]: { acceptedRoles: [AbfRole.EscrowManager] },
    [AbfPermission.WithdrawFunds]: { acceptedRoles: [AbfRole.EscrowManager] },
    [AbfPermission.DepositTokens]: { acceptedRoles: [AbfRole.EscrowManager] },
    [AbfPermission.WithdrawTokens]: { acceptedRoles: [AbfRole.EscrowManager] },
    [AbfPermission.ModifyMembers]: { acceptedRoles: [AbfRole.Admin, AbfRole.SuperAdmin] },
    [AbfPermission.ValidateMembers]: { acceptedRoles: [AbfRole.Admin, AbfRole.SuperAdmin] }
};

/**
 * Used to determine granular custodian and role permissions from broader Roles + custodian perms
 * @constant acceptedRoles accept ANY of these roles (OR). Empty array is public
 * @constant custodianPermission the key for the custodian to check. Must have a value AbfCustodianPermission
 */
const permissionCustodianMap: Record<AbfPermissionForCustodian, { acceptedRoles: AbfRole[] }> = {
    [AbfPermissionForCustodian.CreateBorrowRequest]: {
        acceptedRoles: [AbfRole.Borrower]
    },
    [AbfPermissionForCustodian.CreateLendOrder]: {
        acceptedRoles: [AbfRole.Lender]
    },
    [AbfPermissionForCustodian.ClaimRepayment]: {
        acceptedRoles: [AbfRole.Lender]
    },
    [AbfPermissionForCustodian.CancelLendOrder]: {
        acceptedRoles: [AbfRole.Lender]
    },
    [AbfPermissionForCustodian.FillLendOrder]: {
        acceptedRoles: [AbfRole.Borrower]
    },
    [AbfPermissionForCustodian.RepayLoan]: {
        acceptedRoles: [AbfRole.Borrower]
    },
    [AbfPermissionForCustodian.WithdrawDebtNote]: {
        acceptedRoles: [AbfRole.Borrower]
    },
    [AbfPermissionForCustodian.WithdrawCreditNote]: {
        acceptedRoles: [AbfRole.Lender]
    },
    [AbfPermissionForCustodian.ViewLoans]: {
        acceptedRoles: [AbfRole.Borrower, AbfRole.Member, AbfRole.Lender]
    }
};
