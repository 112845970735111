import { ReactNode, useState } from "react";

import { BackCta, Column, Icon, Row, Text } from "@bridgesplit/ui";
import { HowToRegOutlined, LockOutlined } from "@mui/icons-material";
import { formatSeconds } from "@bridgesplit/utils";
import { MPC_SIG_EXPIRE, useCreateGroup } from "@bridgesplit/abf-react";

import { useWalletSetupGroupToCreate } from "./util";
import { useCreatePasskey } from "../../group";
import { AppButton, DialogHeader } from "../../common";

const BULLETS = [
    {
        icon: <HowToRegOutlined />,
        text: "Authenticate using your fingerprint, face, device password, or hardware key"
    },
    {
        icon: <Icon type="pending" />,
        text: `Your signature will be stored for up to ${formatSeconds(
            MPC_SIG_EXPIRE
        )} before prompting you to authenticate again`
    },
    {
        icon: <LockOutlined />,
        text: "Your credentials are stored natively on your device and cannot be access by anyone besides you"
    }
];

export default function WalletSetupCreateMpc({ back }: { back: () => void }) {
    const { createGroupParams } = useWalletSetupGroupToCreate();

    const [isLoading, setIsLoading] = useState(false);

    const { register, Component: CreatePasskey, isLoading: createLoading } = useCreatePasskey();
    const createGroup = useCreateGroup();

    async function submit() {
        setIsLoading(true);
        const res = await register();
        if (!res.isOk()) return res;

        return await createGroup({ ...createGroupParams, admin_wallet: undefined, signedTransaction: undefined });
    }

    if (isLoading || createLoading) {
        return <CreatePasskey size="regular" />;
    }

    return (
        <>
            <Header />
            <Bullets bullets={BULLETS} />

            <Column spacing={1}>
                <AppButton
                    isTransaction={false}
                    color="secondary"
                    asyncCta={{
                        closeDialog: "onSuccess",
                        onClickWithResult: submit,
                        options: {
                            onFail: () => setIsLoading(false),
                            onSuccess: () => setIsLoading(false),
                            onSuccessMessage: "Passkey created"
                        }
                    }}
                >
                    Create passkey wallet
                </AppButton>
                <BackCta back={back} />
            </Column>
        </>
    );
}

function Bullets({ bullets }: { bullets: { text: string; icon: ReactNode }[] }) {
    return (
        <Column spacing={2}>
            {bullets.map(({ text, icon }, i) => (
                <Row key={i} spacing={2}>
                    <Text color="disabled">{icon}</Text>
                    <Text variant="body2" color="caption">
                        {text}
                    </Text>
                </Row>
            ))}
        </Column>
    );
}

function Header() {
    return (
        <DialogHeader
            header="Create passkey wallet"
            description="Use Loopscale without a Solana wallet"
            spacing={0.5}
        />
    );
}
