import { useCallback, useMemo } from "react";

import { Button } from "@bridgesplit/ui";
import { DispatchType } from "@bridgesplit/react";
import { BsMetaUtil, MarketGuideMode } from "@bridgesplit/abf-react";

import Collateral from "./Collateral";
import Principal from "./Principal";
import { useBorrowBalanceChecker, useMaxBorrowAmount, useMarketBorrowHooks } from "../util";
import { useMarketBorrowContext } from "../MarketBorrowContext";
import BorrowCta from "../BorrowCta";
import { BORROW_STEPS, BorrowStep } from "./types";
import Duration from "./Duration";
import Terms from "./Terms";
import { MarketDialogHeader, useMarketContext } from "../../common";

export default function BorrowStepper({ step, setStep }: { step: BorrowStep; setStep: DispatchType<BorrowStep> }) {
    useMarketBorrowHooks({ setDefaultDuration: true });

    const stepErrorMap = useStepErrorMap();
    const stepIndex = BORROW_STEPS.indexOf(step);
    const { token } = useMarketContext();

    const content = useMemo(() => {
        switch (step) {
            case BorrowStep.Collateral:
                return <Collateral />;
            case BorrowStep.Principal:
                return <Principal />;
            case BorrowStep.Duration:
                return <Duration />;
        }
    }, [step]);

    const nextStep = useCallback(() => {
        if (stepIndex < BORROW_STEPS.length - 1) {
            setStep(BORROW_STEPS[stepIndex + 1]);
        }
    }, [setStep, stepIndex]);

    const activeErrorMessage = stepErrorMap[step];

    return (
        <>
            <MarketDialogHeader
                back={stepIndex > 0 ? () => setStep(BORROW_STEPS[stepIndex - 1]) : undefined}
                mode={MarketGuideMode.Borrow}
                header={`Borrow ${BsMetaUtil.getSymbol(token)}`}
            />

            {content}
            {stepIndex === BORROW_STEPS.length - 1 ? (
                <>
                    <Terms />
                    <BorrowCta />
                </>
            ) : (
                <Button color="secondary" variant="contained" onClick={nextStep} disabled={!!activeErrorMessage}>
                    {activeErrorMessage ?? "Continue"}
                </Button>
            )}
        </>
    );
}

function useStepErrorMap() {
    const { form } = useMarketBorrowContext();

    const { insufficientBalance } = useBorrowBalanceChecker();
    const { maxBorrow } = useMaxBorrowAmount();

    const map = useMemo((): Record<BorrowStep, string | undefined> => {
        return {
            [BorrowStep.Collateral]: (() => {
                if (!form.collateralAmount) return "Enter amount";
                if (insufficientBalance) return "Insufficient balance";
                return undefined;
            })(),
            [BorrowStep.Principal]: (() => {
                if (!form.amount) return "Enter amount";
                if (!!maxBorrow && form.amount > maxBorrow) {
                    return "Exceeds your borrow limit";
                }
                return undefined;
            })(),
            [BorrowStep.Duration]: form.preset ? undefined : "Select duration"
        };
    }, [form.amount, form.collateralAmount, insufficientBalance, maxBorrow, form.preset]);

    return map;
}
