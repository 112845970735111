import { useMemo } from "react";

import { ChainId } from "@bridgesplit/abf-sdk";

import DepositEscrows from "./DepositEscrows";
import { SelectChain } from "../../wormhole";
import BridgeFromEvm from "./BridgeFromEvm";

export default function DepositWithSource({
    specifiedKeys,
    specifiedCustodian
}: {
    specifiedKeys?: string[];
    specifiedCustodian?: string;
}) {
    const { chain } = SelectChain.useState();

    return useMemo(() => {
        if (chain !== ChainId.Solana) {
            return <BridgeFromEvm chain={chain} />;
        }
        return <DepositEscrows specifiedCustodian={specifiedCustodian} specifiedKeys={specifiedKeys} />;
    }, [chain, specifiedCustodian, specifiedKeys]);
}
