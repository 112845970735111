import { EmptyPlaceholderPropsWithCta, Icon } from "@bridgesplit/ui";
import { RoleView, useUserIsPrime } from "@bridgesplit/abf-react";
import { LockOutlined, LoopOutlined } from "@mui/icons-material";
import { COPY, EARN_SLUG } from "app/constants";

import { AppDialog, useAppDialog } from "./dialog";
import { getDefaultMarketPath } from "../components/common";

export enum EmptyPromptType {
    AssetClassAccess,
    Borrow,
    Lend,
    Loop
}
export function useEmptyPrompts() {
    const { open } = useAppDialog();
    const { isPrime } = useUserIsPrime();

    const prompts: Record<EmptyPromptType, Omit<EmptyPlaceholderPropsWithCta, "header">> = {
        [EmptyPromptType.AssetClassAccess]: {
            icon: <LockOutlined />,
            description: isPrime ? "Request access to one of Loopscale’s partners" : "Get Access with Loopscale Prime",
            cta: {
                text: isPrime ? "Request Access" : "Join Prime",
                onClick: () =>
                    isPrime
                        ? open(AppDialog.AirtableInterestForm, undefined)
                        : open(AppDialog.PrimeLearnMore, undefined)
            }
        },
        [EmptyPromptType.Borrow]: {
            icon: <Icon type="borrow" />,
            description: "Start your first fixed-rate loan",
            cta: {
                text: "Borrow",
                to: getDefaultMarketPath(RoleView.Borrow)
            }
        },
        [EmptyPromptType.Lend]: {
            icon: <Icon type="lend" />,
            description: "Start earning fixed interest yield by making your first offer",
            cta: {
                text: "Lend",
                to: getDefaultMarketPath(RoleView.Lend)
            }
        },
        [EmptyPromptType.Loop]: {
            icon: <LoopOutlined />,
            description: `Earn yield with ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`,
            cta: {
                text: `Explore ${COPY.LOOP_TERM_PLURAL.toLowerCase()}`,
                to: EARN_SLUG
            }
        }
    };

    return function (type: EmptyPromptType, emptyDescription: string): EmptyPlaceholderPropsWithCta {
        return { header: emptyDescription, ...prompts[type] };
    };
}
