import { useMemo } from "react";

import { DialogWrapper, ErrorWrapper, Icon, IconHeader, TagTextAlert } from "@bridgesplit/ui";
import { formatPasskeyMetadata, useMpcRegisterNewCredential, useMpcSign, useMpcStatus } from "@bridgesplit/abf-react";

import { AppButton, DialogHeader } from "../../common";

export default function CreateNewPasskeyDialog() {
    const { activeCredential } = useMpcStatus();
    return <DialogWrapper>{activeCredential ? <PromptOtherDevice /> : <Register />}</DialogWrapper>;
}

function PromptOtherDevice() {
    const sign = useMpcSign();

    return (
        <>
            <IconHeader>
                <Icon type="passkey" />
            </IconHeader>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Register a new passkey"
                description="Create a new Passkey on this device. You need to approve this device with your original passkey"
            />
            <TagTextAlert>
                <Icon type="tooltip" /> Keep this device active to enable devices
            </TagTextAlert>
            <AppButton
                isTransaction={false}
                asyncCta={{
                    onClickWithResult: sign,
                    closeDialog: "onSuccess",
                    options: { onSuccessMessage: "Authentication successful. Please continue on your new device" }
                }}
            >
                Authenticate
            </AppButton>
        </>
    );
}

function Register() {
    const registerDevice = useMpcRegisterNewCredential();

    const { pendingPasskeyRegistration, verified, validPasskeys } = useMpcStatus();

    const formattedAuthenticatedDevices = validPasskeys
        ? validPasskeys.map((p) => formatPasskeyMetadata(p.metadata)).join(" or ")
        : "your original device";

    const errorMessage = useMemo(() => {
        if (pendingPasskeyRegistration) return "You can only have one pending Passkey device registration at a time";
        if (!verified)
            return `You must first re-authenticate on ${formattedAuthenticatedDevices}. You can do so in the Passkey section of your account`;
        return undefined;
    }, [formattedAuthenticatedDevices, pendingPasskeyRegistration, verified]);

    return (
        <>
            <IconHeader>
                <Icon type="passkey" />
            </IconHeader>
            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Register a new passkey"
                description="Create a new passkey on this device. You need to approve this device with your original passkey"
            />

            <ErrorWrapper errorMessage={errorMessage}>
                <AppButton
                    isTransaction={false}
                    disabled={!!errorMessage}
                    asyncCta={{
                        closeDialog: "onSuccess",
                        onClickWithResult: registerDevice,
                        options: {
                            onSuccessMessage:
                                "Registration submitted. Please approve on an existing Passkey device in your account"
                        }
                    }}
                    variant="contained"
                >
                    Register device
                </AppButton>
            </ErrorWrapper>
        </>
    );
}
