import { useMemo } from "react";

import {
    NullableRecord,
    SOL_DECIMALS,
    STAKED_SOL_MINT,
    bsMath,
    combineCollections,
    filterNullableRecord,
    filterTruthy,
    removeDuplicates
} from "@bridgesplit/utils";
import { AssetTypeIdentifier } from "@bridgesplit/abf-sdk";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useCustodiansByIdentifiers, useLoanRequestsQuery, useStakeAccountsByOwnersQuery } from "../reducers";
import { formatTokens, isCollateralSplMint, isFilledOffer, isStakedSol, useAbfTypesToUiConverter } from "../utils";
import {
    AbfRole,
    LoanRequestInfo,
    LoanRequestQueryFilter,
    LoanRequestCollateralExpanded,
    LoanRequestExpanded
} from "../types";
import { useEscrowAccounts } from "./escrow";
import { useActiveGroup, useGroup, useGroupsByEscrows } from "./group";
import { useSkipUnauthenticated } from "./auth";

type LoanRequestOptions = { skip?: boolean; filterArchived?: boolean };

export function useLoanRequestsExpanded(params: LoanRequestQueryFilter, options?: LoanRequestOptions) {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const {
        data: rawData,
        isLoading: queryLoading,
        isFetching
    } = useLoanRequestsQuery(params, {
        skip: options?.skip || skipIfUnauthenticated
    });

    const collateralMints = rawData
        ?.map((req) => req.assetInfos.filter((m) => isCollateralSplMint(m)).map((m) => m.assetKey))
        .flat();
    const orderMints = rawData?.map((req) => Object.values(req.orders).map((o) => o.principalMint)).flat();

    const metadataMints = combineCollections([collateralMints, orderMints]).concat([STAKED_SOL_MINT]);

    const { convertLoanRequestInfo, convertOrder, getMetadata, tokensLoading } =
        useAbfTypesToUiConverter(metadataMints);
    const { convertCollateral, isLoading: convertCollateralLoading } = useConvertCollateral(rawData, metadataMints);

    const dataWithTypes = rawData
        ?.map((raw) => convertLoanRequestInfo(raw))
        .filter((raw) => (!raw.loanRequest.archived && !raw.loanRequest.rejected) || !options?.filterArchived);

    const designedTakers = dataWithTypes?.map((req) => req.loanRequest.designatedTaker).filter(filterTruthy);
    const makers = dataWithTypes?.map((req) => req.loanRequest.maker);
    const escrowAccounts = designedTakers && makers ? [...designedTakers, ...makers] : undefined;

    const { cache: groupCache, isLoading: groupsLoading } = useGroupsByEscrows(escrowAccounts);

    const data = dataWithTypes
        ?.map((req): NullableRecord<LoanRequestExpanded> => {
            const { loanRequest, orders, custodianMapping, feeMetadata } = req;
            const makerGroup = groupCache.get(loanRequest.maker)?.group;
            const designedTakerGroup = loanRequest.designatedTaker
                ? groupCache.get(loanRequest.designatedTaker)?.group
                : null;

            const principalMetadata = loanRequest.principalMint ? getMetadata(loanRequest.principalMint) : null;
            const collateral = convertCollateral(req);

            return {
                principalMetadata,
                collateral,
                makerGroup,
                designedTakerGroup,
                loanRequest,
                orders: Object.values(orders).map((o) => convertOrder(o)),
                custodians: Object.values(custodianMapping),
                feeMetadata,
                collateralRaw: req.assetInfos
            };
        })
        .filter(filterNullableRecord);

    const isLoading = queryLoading || convertCollateralLoading || groupsLoading || tokensLoading;

    return { data: isLoading ? undefined : data, isLoading, isFetching };
}

export function useUserBorrowerLoanRequests(params?: { skip?: boolean }) {
    const { groupIdentifier } = useActiveGroup();
    const { escrowNonces } = useEscrowAccounts();

    const { data } = useLoanRequestsExpanded(
        {
            groups: groupIdentifier ? [groupIdentifier] : undefined,
            makerNonces: escrowNonces ? escrowNonces : undefined
        },
        { skip: !groupIdentifier || !escrowNonces || params?.skip, filterArchived: true }
    );
    return data?.filter((d) => !d.orders.find((order) => isFilledOffer({ order })));
}

export function useRequestsByDesignatedTaker(takerNonce: string | string[] | undefined, options?: { skip?: boolean }) {
    const { data } = useLoanRequestsExpanded(
        { designatedTakerNonces: typeof takerNonce === "string" ? [takerNonce] : takerNonce },
        { skip: !takerNonce || options?.skip, filterArchived: true }
    );
    return data?.filter((d) => !d.orders.find((order) => isFilledOffer({ order })));
}

export function useCustodianLoanRequests(custodianIdentifier: string | undefined) {
    const { data: rawData, ...rest } = useLoanRequestsExpanded(
        { custodians: custodianIdentifier ? [custodianIdentifier] : undefined },
        { skip: !custodianIdentifier, filterArchived: true }
    );

    const data = rawData?.filter((req) => {
        const isValidEscrowsMatching = req.collateral.find((c) => c.custodian?.groupIdentifier === custodianIdentifier);

        return isValidEscrowsMatching && isPublicLoanRequest(req);
    });

    return { data, ...rest };
}

export function useViewableLoanRequests() {
    const { custodianPermissions } = useGroup();
    const lendCustodianPermissions = Object.entries(custodianPermissions)
        .filter(([, roles]) => roles.includes(AbfRole.Lender))
        .map(([custodian]) => custodian);

    const { data: rawData, ...rest } = useLoanRequestsExpanded(
        { custodians: lendCustodianPermissions },
        { filterArchived: true, skip: !lendCustodianPermissions }
    );

    const data = rawData?.filter((req) => isPublicLoanRequest(req));

    return { data, ...rest };
}

function useConvertCollateral(rawData: LoanRequestInfo[] | undefined, metadataMints: string[]) {
    const { convertEscrow, getMetadata } = useAbfTypesToUiConverter(metadataMints);

    const custodians = rawData?.map((req) => removeDuplicates(Object.values(req.custodianMapping))).flat();

    const { cache: custodianCache, isLoading } = useCustodiansByIdentifiers(custodians);
    const potentialStakeAccounts = rawData
        ?.map((d) => (d.assetInfos.find((d) => isStakedSol(d.assetTypeDiscriminator)) ? d.loanRequest.maker : null))
        .filter(filterTruthy);
    const { data: stakeAccounts } = useStakeAccountsByOwnersQuery(potentialStakeAccounts ?? skipToken, {
        skip: !potentialStakeAccounts?.length
    });

    const stakeAccountMap = useMemo(
        () => (stakeAccounts ? new Map(Object.entries(stakeAccounts)) : undefined),
        [stakeAccounts]
    );

    function convertCollateral({
        assetInfos,
        escrowAssets,
        loanRequest
    }: LoanRequestInfo): LoanRequestCollateralExpanded[] {
        const splTokens = assetInfos
            .filter((s) => s.assetTypeDiscriminator === AssetTypeIdentifier.SplToken)
            .map((d): NullableRecord<LoanRequestCollateralExpanded> => {
                const escrow = escrowAssets[d.assetKey];
                const escrowedAmount = escrow ? convertEscrow(escrow).amount : 0;
                const metadata = getMetadata(d.assetKey, d.assetTypeDiscriminator);
                const custodian = metadata?.assetOriginator
                    ? custodianCache?.get(metadata?.assetOriginator)
                    : undefined;
                return { ...d, escrowedAmount, metadata, custodian };
            });

        const escrowSolDeposits = stakeAccountMap?.get(loanRequest.maker) ?? [];
        const totalEscrowedSol = escrowSolDeposits?.reduce(
            (prev, curr) => prev + curr.amount + curr.rentExemptReserve,
            0
        );

        const stakedSolDeposits = assetInfos.filter((s) => s.assetTypeDiscriminator === AssetTypeIdentifier.StakedSol);
        const totalStakedSol = stakedSolDeposits.reduce(
            (prev, curr) => bsMath.tokenAdd(prev, curr.amount, SOL_DECIMALS),
            0
        );

        const stakedSolMeta = getMetadata(STAKED_SOL_MINT);
        const summarizedStake: NullableRecord<LoanRequestCollateralExpanded> = {
            amount: totalStakedSol,
            escrowedAmount: totalEscrowedSol / LAMPORTS_PER_SOL,
            custodian: stakedSolMeta ? custodianCache?.get(stakedSolMeta.assetOriginator) : undefined,
            metadata: stakedSolMeta,
            assetKey: STAKED_SOL_MINT,
            assetTypeDiscriminator: AssetTypeIdentifier.StakedSol
        };

        if (summarizedStake.amount) {
            splTokens.push(summarizedStake);
        }

        return splTokens.filter(filterNullableRecord);
    }

    return { convertCollateral, isLoading };
}

export function useLoanRequestByLockboxMint(lockboxNftMint: string | undefined) {
    const { data } = useLoanRequestsExpanded({ lockboxes: lockboxNftMint ? [lockboxNftMint] : undefined });

    return data?.find((l) => l.loanRequest.lockboxNftMint === lockboxNftMint);
}

function isPublicLoanRequest(req: LoanRequestExpanded) {
    const allAssetsDeposited = req.collateral.every((c) => !isRequestCollateralMissingEscrow(c));

    const hasFilledOrders = req.orders.find((order) => isFilledOffer({ order }));

    return req.loanRequest.lockboxCreated && allAssetsDeposited && !hasFilledOrders;
}

export function isRequestCollateralMissingEscrow(collateral: LoanRequestCollateralExpanded) {
    return collateral.amount > collateral.escrowedAmount + (collateral.lockboxAmount || 0);
}

export function formatRequestCollateral(collateral: LoanRequestCollateralExpanded[] | undefined) {
    return formatTokens(collateral?.map((c) => ({ amount: c.amount, metadata: c.metadata })));
}
