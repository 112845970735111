import { useMemo } from "react";

import { BsMetaUtil, MAX_SQRT_PRICE, MAX_TICK_INDEX, MIN_SQRT_PRICE, MIN_TICK_INDEX } from "@bridgesplit/abf-react";
import { WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { Image, ORCA_LOGO, Row, Text, TextColor, TextVariant, TooltipText } from "@bridgesplit/ui";
import { formatAddress, formatUsd, Result } from "@bridgesplit/utils";
import { CheckCircleOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { PositionUtil, PositionStatus, sqrtPriceX64ToPrice, getNearestValidTickIndex } from "@orca-so/whirlpool-sdk";
import BN from "bn.js";

export function WhirlpoolPositionInfo({ position }: { position: WhirlpoolPositionExpanded | undefined }) {
    return (
        <Row spacing={1}>
            <Text>{BsMetaUtil.formatAmount(position?.tokenA?.metadata, position?.tokenA?.amount)} </Text>
            <Text color="disabled">/</Text>
            <Text>{BsMetaUtil.formatAmount(position?.tokenB?.metadata, position?.tokenB?.amount)} </Text>
        </Row>
    );
}

export function WhirlpoolPositionSummarized({
    position,
    spaceBetween
}: {
    position: WhirlpoolPositionExpanded | undefined;
    spaceBetween?: boolean;
}) {
    return (
        <Row sx={{ width: "100%" }} spaceBetween={spaceBetween} spacing={1}>
            <Row spacing={1}>
                <Image src={ORCA_LOGO} size="16px" variant="circle" />
                <Text>{formatAddress(position?.position.positionMint)}</Text>
            </Row>
            <Text color="caption">{formatUsd(position?.totalPrice)}</Text>
        </Row>
    );
}

export function PriceRangeStatus({
    tickCurrentIndex,
    tickLower,
    tickUpper,
    variant,
    iconOnly
}: {
    tickCurrentIndex: number | undefined;
    tickLower: number | undefined;
    tickUpper: number | undefined;
    variant?: TextVariant;
    iconOnly?: boolean;
}) {
    const details = useMemo(() => {
        if (tickCurrentIndex === undefined || tickLower === undefined || tickUpper === undefined) return null;

        const status = PositionUtil.getPositionStatus(tickCurrentIndex, tickLower, tickUpper);
        const color: TextColor = status === PositionStatus.InRange ? "success" : "error";
        const icon = status === PositionStatus.InRange ? <CheckCircleOutlined /> : <ErrorOutlineOutlined />;
        const caption = status === PositionStatus.InRange ? "In range" : "Out of range";
        return { color, icon, caption };
    }, [tickCurrentIndex, tickLower, tickUpper]);

    if (!details) return null;

    const { color, icon, caption } = details;

    return (
        <TooltipText
            icon={false}
            helpText={iconOnly ? caption : undefined}
            sx={{ gap: 0.5 }}
            variant={variant}
            color={color}
        >
            {icon}
            {iconOnly ? null : caption}
        </TooltipText>
    );
}

export function parseOrcaTicks(whirlpoolPosition: WhirlpoolPositionExpanded | undefined) {
    if (!whirlpoolPosition) return undefined;
    try {
        const { position, whirlpool } = whirlpoolPosition;
        const tokenADecimals = whirlpoolPosition.tokenA.decimals;
        const tokenBDecimals = whirlpoolPosition.tokenB.decimals;

        const convert = (sqrtPrice: string) => {
            if (sqrtPrice === MAX_SQRT_PRICE) return MAX_TICK_INDEX;
            if (sqrtPrice === MIN_SQRT_PRICE) return MIN_TICK_INDEX;

            const bn = new BN(sqrtPrice);
            const price = sqrtPriceX64ToPrice(bn, tokenADecimals, tokenBDecimals);

            return getNearestValidTickIndex(price, tokenADecimals, tokenBDecimals, whirlpool.tickSpacing);
        };

        return {
            currentTick: convert(whirlpool.sqrtPrice),
            tickLower: convert(position.lowerSqrtPrice),
            tickUpper: convert(position.upperSqrtPrice)
        };
    } catch (error) {
        Result.err(error);
        return undefined;
    }
}
