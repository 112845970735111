import { Result, getReadableErrorMessage, tsApi } from "@bridgesplit/utils";

export enum AirtableCustomerAccountType {
    Individual = "Individual",
    Company = "Company"
}
export enum AirtableCustomerInterestType {
    Borrower = "Borrower",
    Lender = "Lender",
    Custodian = "Asset Custodian"
}

export enum AirtableCustomerInterestedAssetClass {
    Spirits = "Spirits",
    FinancialDigitalAssets = "Financial Digital Assets",
    RealEstate = "Real Estate",
    ClimateAssets = "Climate Assets",
    FineArt = "Fine Art",
    SportsCollectibles = "Sports Collectibles"
}
export interface AirtableInterestForm {
    "First Name": string;
    "Last Name": string;
    "Account Type": AirtableCustomerAccountType;
    Custodian: string;
    "Wallet Address": string;
    "Linked Contact": string[];
    "Asset Tags": AirtableCustomerInterestedAssetClass[];
    "Interest Type": AirtableCustomerInterestType;
    Source: "Loopscale App";
    Email: string;
    Comment: string;
}
export const requiredLoopscaleTableFields: (keyof AirtableInterestForm)[] = ["First Name", "Account Type", "Email"];

export async function submitAirtableInterestForm(form: Partial<AirtableInterestForm>): Promise<Result<void>> {
    try {
        const missingFields = requiredLoopscaleTableFields.filter((field) => !form[field]);
        if (missingFields.length) {
            return Result.errFromMessage(`Missing ${missingFields.join(", ")}`);
        }
        const res = await tsApi.post("airtable/submit", form);
        if (res.status === 200) return Result.ok();
        return Result.err(res.data);
    } catch (error) {
        return Result.errWithDebug(getReadableErrorMessage("request access"), error);
    }
}
