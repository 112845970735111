import { ReactNode } from "react";

import { Column, SCROLL_WIDTH } from "@bridgesplit/ui";
import { useLogoutOnError, useSetAuth0Bearer, useSetCustodianCode, useTokenListQuery } from "@bridgesplit/abf-react";
import { useMaintainCssTheme, useMaintainDialectTheme } from "@bridgesplit/react";

import AppAlertBanner from "./AppAlertBanner";
import { useSetMixpanel } from "../../hooks";

export default function AppWrapper({ children }: { children: ReactNode }) {
    //mixpanel setup
    useSetMixpanel();

    // store auth0 credentials in our own cookie
    useSetAuth0Bearer();
    useSetCustodianCode();
    useLogoutOnError();

    // prefetch token list on app load
    useTokenListQuery();
    useMaintainCssTheme();
    useMaintainDialectTheme();

    return (
        <Column
            id="app-wrapper"
            sx={{
                height: "100%",
                minHeight: "100vh",
                width: `calc(100vw - ${SCROLL_WIDTH})`
            }}
        >
            <AppAlertBanner />
            {children}
        </Column>
    );
}
