import { DialogWrapper, ExpandContent, Icon, IconHeader, ProgressBar, Row, Text, TooltipText } from "@bridgesplit/ui";
import { useUserSphere } from "@bridgesplit/abf-react";
import { UboStatus } from "@bridgesplit/abf-sdk";

import { DialogHeader } from "../../common";

export default function SphereUBOStatusesDialog() {
    const { kyb } = useUserSphere();

    if (!kyb?.uboStatuses) return null;

    const verifiedStatuses = kyb.uboStatuses.filter((s) => s.status === UboStatus.Added).length;

    return (
        <DialogWrapper>
            <IconHeader>
                <Icon type="kyc" />
            </IconHeader>
            <DialogHeader
                centerAlign
                header={` ${verifiedStatuses} of ${kyb.uboStatuses.length} UBOs Verified`}
                description="All UBOs must submit identity verification before you can add a bank. All UBOs received an email from Loopscale/Stripe prompting them to verify their identity"
            />
            <ProgressBar value={(verifiedStatuses / kyb.uboStatuses.length) * 100} />

            <ExpandContent textColor="caption" header="Statuses" defaultExpanded spacing={1}>
                {kyb.uboStatuses.map(({ email, status }) => (
                    <Row spaceBetween key={email}>
                        <Text> {email} </Text>
                        <StatusDisplay status={status} />
                    </Row>
                ))}
            </ExpandContent>
        </DialogWrapper>
    );
}

function StatusDisplay({ status }: { status: UboStatus }) {
    switch (status) {
        case UboStatus.Added:
            return (
                <TooltipText icon={false} color="success" helpText="Verified">
                    <Icon type="accept" />
                </TooltipText>
            );

        // NotAdded should be shown as Pending
        // case UboStatus.NotAdded:
        //     return (
        //         <TooltipText icon={false} color="error" helpText="Verification failed">
        //             <Icon type="reject" />
        //         </TooltipText>
        //     );

        default:
            return (
                <TooltipText icon={false} color="disabled" helpText="Pending">
                    <Icon type="pending" />
                </TooltipText>
            );
    }
}
