import { useMemo } from "react";

import {
    LoopInfoParams,
    JupiterQuoteParams,
    JupiterSwapMode,
    AbfOrderFundingType,
    LoopRoutePlatform,
    UserLoopFilter,
    LoopRouteType,
    BsMetadata,
    TokenListTag
} from "@bridgesplit/abf-sdk";
import {
    bigNumberStringToUiAmount,
    decimalsToBps,
    filterNullableRecord,
    formatNum,
    getUnixTs,
    greaterThan,
    NullableRecord,
    percentDecimalsToUi,
    percentUiToDecimals,
    roundDownToDecimals,
    stringLamportsToUiAmount,
    TIME,
    uiAmountToLamports,
    WRAPPED_SOL_MINT
} from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemoizedKeyMap } from "@bridgesplit/ui";
import { Meteora } from "@bridgesplit/bs-protos";

import {
    useBsMetadataByMints,
    useLoopBestQuoteQuery,
    useLoopInfosQuery,
    useJupiterSwapQuoteQuery,
    useUserLoopsQuery,
    useAllMarketPrincipalStatsQuery,
    useMeteoraDepositQuoteQuery,
    useMeteoraWithdrawQuoteQuery,
    useJlpPerpsQuery
} from "../reducers";
import {
    AbfLoanExpanded,
    BestLoopQuoteQuery,
    ExternalQuoteResponse,
    ExternalUnwindQuote,
    ExternalUnwindQuoteParams,
    ExternalWindQuote,
    ExternalWindQuoteParams,
    LoopTransferType,
    LoopExpanded,
    LoopPositionExpanded,
    LoopQuoteExpanded,
    StrategyDuration,
    LoopExpandedBase
} from "../types";
import { useOraclePrices } from "./pricing";
import { isLoopLoan, useAbfTypesToUiConverter } from "../utils";
import { isLoanActive, useLoanInfos } from "./loans";
import { useActiveEscrow } from "./escrow";
import { getBorrowCapDetails, useMarketBorrowCaps } from "./markets";
import { JUPITER_POLL_INTERVAL, METEORA_POLL_INTERVAL } from "../constants";
import { useActiveGroup } from "./group";

export function useLoopsExpanded(params: LoopInfoParams, options?: { skip?: boolean }) {
    const query = useLoopInfosQuery(params, { skip: options?.skip });

    const collateralMints = useMemo(
        () => Object.values(query.data ?? {}).map((loop) => loop.collateralMint),
        [query.data]
    );
    const principalMints = useMemo(
        () => Object.values(query.data ?? {}).map((loop) => loop.principalMint),
        [query.data]
    );

    const allMints = useMemo(() => [...collateralMints, ...principalMints], [collateralMints, principalMints]);
    const { getMetadata, isLoading: metadataLoading } = useBsMetadataByMints(allMints);
    const { getOracle, isLoading: marketLoading } = useOraclePrices(allMints);
    const { convertLoopVault, convertMarketPrincipalStats } = useAbfTypesToUiConverter(allMints);
    const { getBorrowCap, isLoading: borrowCapsLoading } = useMarketBorrowCaps(principalMints);

    const marketStatsQuery = useAllMarketPrincipalStatsQuery(undefined, {
        skip: !principalMints.length || options?.skip
    });

    const principalStatsMap = useMemo(
        () => (marketStatsQuery.data ? new Map(Object.entries(marketStatsQuery.data)) : undefined),
        [marketStatsQuery]
    );

    const isLoading =
        query.isLoading || metadataLoading || marketLoading || marketStatsQuery.isLoading || borrowCapsLoading;

    const data = useMemo(() => {
        if (!query.data || isLoading) return undefined;

        return Object.entries(query.data)
            .map(([vaultIdentifier, loopVaultRaw]): NullableRecord<LoopExpanded> => {
                const loopVault = convertLoopVault(loopVaultRaw);
                const borrowCap = getBorrowCap(loopVault.principalMint);
                let principalStats = principalStatsMap?.get(loopVault.principalMint);
                if (principalStats) {
                    principalStats = convertMarketPrincipalStats(loopVault.principalMint, principalStats);
                }

                const common: NullableRecord<LoopExpandedBase> = {
                    vaultIdentifier,
                    loopVault,
                    collateralToken: getMetadata(loopVault.collateralMint),
                    principalToken: getMetadata(loopVault.principalMint),
                    collateralOracle: getOracle(loopVault.collateralMint),
                    principalOracle: getOracle(loopVault.principalMint),
                    principalStats,
                    borrowCap
                };

                if (loopVault.metadata && "lpMint" in loopVault.metadata) {
                    const tokenA = getMetadata(loopVault.metadata.tokenAMint);
                    const tokenB = getMetadata(loopVault.metadata.tokenBMint);
                    return {
                        ...common,
                        type: LoopRoutePlatform.Meteora,
                        depositType: LoopTransferType.CollateralOnly,
                        withdrawType: LoopTransferType.PrincipalOnly,
                        meteoraPool: loopVault.metadata,
                        tokenA,
                        tokenB
                    };
                }
                return {
                    ...common,
                    type: LoopRoutePlatform.Jupiter,
                    depositType: LoopTransferType.CollateralOnly,
                    withdrawType: LoopTransferType.CollateralOnly
                };
            })
            .filter(filterNullableRecord);
    }, [
        query.data,
        isLoading,
        convertLoopVault,
        getBorrowCap,
        principalStatsMap,
        getMetadata,
        getOracle,
        convertMarketPrincipalStats
    ]);

    return { data, isLoading, isFetching: query.isFetching };
}

export function useUserLoops({
    loopsExpanded,
    filter,
    skip
}: {
    loopsExpanded: LoopExpanded[] | undefined;
    filter: UserLoopFilter;
    skip?: boolean;
}) {
    const { groupIdentifier } = useActiveGroup();
    const query = useUserLoopsQuery(
        groupIdentifier
            ? {
                  userLoopInfo: {
                      loopVaults: loopsExpanded?.map((loop) => loop.vaultIdentifier) ?? [],
                      ...filter
                  },
                  groupIdentifier
              }
            : skipToken,
        {
            skip: !loopsExpanded?.length || skip || !groupIdentifier
        }
    );
    const { activeEscrow } = useActiveEscrow();
    const loanAddresses = useMemo(
        () => (query.data ? query.data.map((userLoop) => userLoop.loanAddress) : []),
        [query.data]
    );
    const {
        data: loanInfos,
        isLoading: loanInfosLoading,
        isFetching: loanInfosFetching
    } = useLoanInfos({
        skip: !activeEscrow || !loanAddresses.length,
        pagination: null,
        loanFilter: {
            loanAddresses,
            fundingTypes: [AbfOrderFundingType.FlashLoan],
            borrowerEscrow: activeEscrow
        }
    });
    const loanExpandedMap = useMemoizedKeyMap(loanInfos, (l) => l.loan.address);
    const loopExpandedMap = useMemoizedKeyMap(loopsExpanded, (l) => l.vaultIdentifier);

    const isLoading = query.isLoading || loanInfosLoading || query.isFetching || loanInfosFetching;
    const { convertUserLoopInfo } = useAbfTypesToUiConverter([]);

    const data = useMemo(() => {
        if (!query.data || isLoading) return undefined;

        return query.data
            .map((userLoop): NullableRecord<LoopPositionExpanded> => {
                const loanExpanded = loanExpandedMap?.get(userLoop.loanAddress);
                const loopExpanded = loopExpandedMap?.get(userLoop.loopVaultIdentifier);
                const userLoopInfo = loopExpanded ? convertUserLoopInfo(userLoop, loopExpanded) : undefined;
                return {
                    loanAddress: userLoop.loanAddress,
                    userLoopInfo,
                    loanExpanded,
                    loopExpanded
                };
            })
            .filter(filterNullableRecord);
    }, [query.data, isLoading, loopExpandedMap, convertUserLoopInfo, loanExpandedMap]);

    return { data, isLoading };
}

export function useJlpPerps(options?: { skip?: boolean }) {
    return useJlpPerpsQuery(undefined, { skip: options?.skip });
}

export function useUserLoopByLoan(loanExpanded: AbfLoanExpanded | undefined, options?: { skip?: boolean }) {
    const isLoanRefinanced = !!loanExpanded?.loan.refinancedTo; // refinanced loans shouldn't query loop position
    const { groupIdentifier } = useActiveGroup();

    const positionQuery = useUserLoopsQuery(
        groupIdentifier
            ? {
                  userLoopInfo: {
                      page: 0,
                      pageSize: 1,
                      active: isLoanActive(loanExpanded),
                      loanVaults: loanExpanded ? [loanExpanded.loan.address] : []
                  },
                  groupIdentifier
              }
            : skipToken,
        {
            skip: options?.skip || !loanExpanded || !isLoopLoan(loanExpanded) || isLoanRefinanced
        }
    );

    const loopPosition = positionQuery.data?.find((userLoop) => userLoop.loanAddress === loanExpanded?.loan.address);
    const loopQuery = useLoopsExpanded(
        { loopVaults: loopPosition ? [loopPosition.loopVaultIdentifier] : undefined },
        { skip: !loopPosition }
    );
    const loopExpanded = loopQuery.data ? Object.values(loopQuery.data)[0] : undefined;

    const isLoading = positionQuery.isLoading || loopQuery.isLoading;
    const isFetching = positionQuery.isFetching || loopQuery.isFetching;

    const { convertUserLoopInfo } = useAbfTypesToUiConverter([]);

    const data = useMemo((): LoopPositionExpanded | undefined => {
        if (!loanExpanded || !loopPosition || isLoading || !loopExpanded) return undefined;

        const userLoopInfo = convertUserLoopInfo(loopPosition, loopExpanded);
        return {
            loanAddress: loanExpanded.loan.address,
            userLoopInfo,
            loanExpanded,
            loopExpanded
        };
    }, [loanExpanded, loopPosition, isLoading, loopExpanded, convertUserLoopInfo]);

    return { data, isLoading, isFetching };
}

export function formatLeverage(leverage: number) {
    return `${formatNum(leverage, { customDecimals: 1 })}x`;
}

export function useWindExternalQuote(params: ExternalWindQuoteParams) {
    const isMeteora = params.loopExpanded?.type === LoopRoutePlatform.Meteora;
    const isJupiter = params.loopExpanded?.type === LoopRoutePlatform.Jupiter;
    const meteoraQuote = useMeteoraWindQuote({ ...params, skip: !isMeteora || params.skip });
    const jupiterQuote = useJupiterWindQuote({ ...params, skip: !isJupiter || params.skip });

    return useMemo((): ExternalQuoteResponse<ExternalWindQuote> => {
        if (isMeteora) {
            return {
                data: meteoraQuote.data ? { type: LoopRoutePlatform.Meteora, quote: meteoraQuote.data } : undefined,
                isLoading: meteoraQuote.isLoading,
                isFetching: meteoraQuote.isFetching
            };
        }
        if (isJupiter) {
            return {
                data: jupiterQuote.data ? { type: LoopRoutePlatform.Jupiter, quote: jupiterQuote.data } : undefined,
                isLoading: jupiterQuote.isLoading,
                isFetching: jupiterQuote.isFetching
            };
        }
        return {
            data: undefined,
            isLoading: true,
            isFetching: false
        };
    }, [meteoraQuote, jupiterQuote, isMeteora, isJupiter]);
}

function useMeteoraWindQuote({
    loopExpanded,
    slippagePercentDecimals,
    principalAmount,
    skip
}: ExternalWindQuoteParams) {
    const depositParams = useMemo((): Meteora.DynamicPoolDepositQuoteParams | undefined => {
        if (
            !principalAmount ||
            !loopExpanded ||
            slippagePercentDecimals === undefined ||
            loopExpanded.type !== LoopRoutePlatform.Meteora
        )
            return undefined;
        const depositAmount = uiAmountToLamports(principalAmount, loopExpanded.principalToken.decimals).toString();
        const { poolAddress, tokenAMint, tokenBMint } = loopExpanded.meteoraPool;

        return {
            pool: poolAddress,
            tokenAInAmount: tokenAMint === loopExpanded.principalToken.assetMint ? depositAmount : "0",
            tokenBInAmount: tokenBMint === loopExpanded.principalToken.assetMint ? depositAmount : "0",
            balance: false,
            slippage: percentDecimalsToUi(slippagePercentDecimals)
        };
    }, [loopExpanded, principalAmount, slippagePercentDecimals]);

    return useMeteoraDepositQuoteQuery(depositParams ?? skipToken, {
        skip: !depositParams || skip,
        pollingInterval: METEORA_POLL_INTERVAL
    });
}

function useJupiterWindQuote({
    loopExpanded,
    principalAmount,
    slippagePercentDecimals,
    skip
}: ExternalWindQuoteParams) {
    const swapParams = useMemo((): JupiterQuoteParams | undefined => {
        if (!principalAmount || !loopExpanded) return undefined;

        return {
            inputMint: loopExpanded.principalToken.assetMint,
            outputMint: loopExpanded.collateralToken.assetMint,
            swapMode: JupiterSwapMode.ExactIn,
            amount: uiAmountToLamports(principalAmount, loopExpanded.principalToken.decimals),
            dexes: getDexesFromRouteType(loopExpanded.loopVault.routeType),
            restrictIntermediateTokens: true,
            onlyDirectRoutes: true,
            slippageBps: slippagePercentDecimals ? decimalsToBps(slippagePercentDecimals, "bps") : undefined
        };
    }, [loopExpanded, principalAmount, slippagePercentDecimals]);

    return useJupiterSwapQuoteQuery(swapParams ?? skipToken, {
        skip: !swapParams || skip,
        pollingInterval: JUPITER_POLL_INTERVAL
    });
}

export function useUnwindExternalQuote(params: ExternalUnwindQuoteParams) {
    const isMeteora = params.loopPositionExpanded?.loopExpanded?.type === LoopRoutePlatform.Meteora;
    const isJupiter = params.loopPositionExpanded?.loopExpanded?.type === LoopRoutePlatform.Jupiter;
    const meteoraQuote = useMeteoraUnwindQuote({ ...params, skip: !isMeteora || params.skip });
    const jupiterQuote = useJupiterUnwindQuote({ ...params, skip: !isJupiter || params.skip });

    return useMemo((): ExternalQuoteResponse<ExternalUnwindQuote> => {
        if (isMeteora) {
            return {
                data: meteoraQuote.data ? { type: LoopRoutePlatform.Meteora, quote: meteoraQuote.data } : undefined,
                isLoading: meteoraQuote.isLoading,
                isFetching: meteoraQuote.isFetching,
                isError: meteoraQuote.isError
            };
        }
        if (isJupiter) {
            return {
                data: jupiterQuote.data ? { type: LoopRoutePlatform.Jupiter, quote: jupiterQuote.data } : undefined,
                isLoading: jupiterQuote.isLoading,
                isFetching: jupiterQuote.isFetching,
                isError: jupiterQuote.isError
            };
        }
        return {
            data: undefined,
            isLoading: true,
            isFetching: false
        };
    }, [meteoraQuote, jupiterQuote, isMeteora, isJupiter]);
}

function useMeteoraUnwindQuote({
    loopPositionExpanded,
    slippagePercentDecimals,
    skip,
    disablePoll
}: ExternalUnwindQuoteParams) {
    const withdrawParams = useMemo((): Meteora.DynamicPoolWithdrawQuoteParams | undefined => {
        if (!loopPositionExpanded || slippagePercentDecimals === undefined || skip) return undefined;

        const { loopExpanded, loanExpanded, userLoopInfo } = loopPositionExpanded;

        // incorrectly called. silently fail
        if (loopExpanded.type !== LoopRoutePlatform.Meteora) {
            return undefined;
        }

        const lpAmount = uiAmountToLamports(
            userLoopInfo.totalCollateralDepositedAmount,
            loopExpanded.collateralToken.decimals
        );

        const { poolAddress } = loopExpanded.meteoraPool;

        return {
            pool: poolAddress,
            tokenMint: loanExpanded.principalMetadata.assetMint,
            withdrawTokenAmount: lpAmount.toString(),
            slippage: percentDecimalsToUi(slippagePercentDecimals)
        };
    }, [loopPositionExpanded, slippagePercentDecimals, skip]);

    return useMeteoraWithdrawQuoteQuery(withdrawParams ?? skipToken, {
        skip: !withdrawParams || skip,
        pollingInterval: disablePoll ? undefined : METEORA_POLL_INTERVAL
    });
}

function useJupiterUnwindQuote({
    loopPositionExpanded,
    slippagePercentDecimals,
    skip,
    disablePoll
}: ExternalUnwindQuoteParams) {
    const isExactOut = loopPositionExpanded?.loopExpanded?.loopVault.routeType === LoopRouteType.Orca;

    const baseParams = useMemo((): Omit<JupiterQuoteParams, "amount"> | undefined => {
        if (!loopPositionExpanded || slippagePercentDecimals === undefined || skip) return undefined;
        const { loopExpanded } = loopPositionExpanded;

        return {
            inputMint: loopExpanded.collateralToken.assetMint,
            outputMint: loopExpanded.principalToken.assetMint,
            swapMode: isExactOut ? JupiterSwapMode.ExactOut : JupiterSwapMode.ExactIn,
            dexes: getDexesFromRouteType(loopExpanded.loopVault.routeType),
            restrictIntermediateTokens: true,
            onlyDirectRoutes: true,
            slippageBps: slippagePercentDecimals ? decimalsToBps(slippagePercentDecimals, "bps") : undefined
        };
    }, [loopPositionExpanded, slippagePercentDecimals, skip, isExactOut]);

    const swapParams1 = useMemo((): JupiterQuoteParams | undefined => {
        if (!loopPositionExpanded || slippagePercentDecimals === undefined || skip || !baseParams) return undefined;
        const { loopExpanded, loanExpanded, userLoopInfo } = loopPositionExpanded;

        let amount = uiAmountToLamports(loanExpanded.debt.total, loopExpanded.principalToken.decimals);
        if (!isExactOut) {
            const collateralUsd = loopExpanded.collateralOracle.getUsdAmount(
                userLoopInfo.totalCollateralDepositedAmount
            );
            const principalUsd = loopExpanded.principalOracle.getUsdAmount(loanExpanded.debt.total);
            const collateralReturnedUsd = Math.max(collateralUsd - principalUsd, 0);
            const collateralSwappedUsd = collateralUsd - collateralReturnedUsd;
            amount = uiAmountToLamports(
                collateralSwappedUsd / loopExpanded.collateralOracle.usdPrice,
                loopExpanded.collateralToken.decimals
            );
        }

        return {
            ...baseParams,
            amount
        };
    }, [loopPositionExpanded, slippagePercentDecimals, skip, baseParams, isExactOut]);

    const swapRoute1 = useJupiterSwapQuoteQuery(swapParams1 ?? skipToken, {
        skip: !swapParams1 || skip,
        pollingInterval: disablePoll ? undefined : JUPITER_POLL_INTERVAL
    });

    // since we can't use exact out on certain routes, calculate the worst case swap using route 1 min out amount
    const swapParams2 = useMemo((): JupiterQuoteParams | undefined => {
        if (isExactOut || !swapRoute1.data || skip || !baseParams || !loopPositionExpanded) return undefined;
        const { loopExpanded, loanExpanded } = loopPositionExpanded;

        // use min out amount to calc worst swap
        const lamportsRatio = parseInt(swapRoute1.data.otherAmountThreshold) / parseInt(swapRoute1.data.inAmount);
        const desiredOutAmount = uiAmountToLamports(loanExpanded.debt.total, loopExpanded.principalToken.decimals);

        const amount = Math.ceil(desiredOutAmount / lamportsRatio);

        return {
            ...baseParams,
            amount
        };
    }, [loopPositionExpanded, skip, baseParams, isExactOut, swapRoute1.data]);

    const swapRoute2 = useJupiterSwapQuoteQuery(swapParams2 ?? skipToken, {
        skip: !swapParams2 || skip
    });

    if (isExactOut) return swapRoute1;

    return swapRoute2;
}

export function calculateLtvFromLeverage(leverageMultiplier: number) {
    return 1 - 1 / leverageMultiplier;
}

function getLoopLeveragedAmounts({
    loopExpanded,
    leverageMultiplier,
    collateralAmount
}: {
    loopExpanded: LoopExpanded;
    leverageMultiplier: number;
    collateralAmount: number;
}) {
    const leveragedCollateralAmount = collateralAmount * leverageMultiplier;
    const collateralUsd = loopExpanded.collateralOracle.getUsdAmount(leveragedCollateralAmount);
    const principalUsd = collateralUsd * calculateLtvFromLeverage(leverageMultiplier);
    const principalAmount = roundDownToDecimals(
        principalUsd / loopExpanded.principalOracle.usdPrice,
        loopExpanded.principalToken.decimals
    );

    return {
        principalAmount,
        leveragedCollateralAmount
    };
}

type LoopBestQuoteParams = {
    loopExpanded: LoopExpanded | undefined;
    collateralAmountUi: number | undefined;
    leverageMultiplier: number | undefined;
    duration: StrategyDuration | undefined;
};
export function useLoopBestQuote(params: LoopBestQuoteParams) {
    const { convertOfferQuote } = useAbfTypesToUiConverter([]);
    const quoteParams = useMemo((): BestLoopQuoteQuery | undefined => {
        if (
            !params.loopExpanded ||
            !params.collateralAmountUi ||
            !isValidLeverage(params.leverageMultiplier) ||
            !params.duration
        )
            return undefined;
        return {
            loopVault: params.loopExpanded.vaultIdentifier,
            collateralAmount: uiAmountToLamports(
                params.collateralAmountUi,
                params.loopExpanded.collateralToken.decimals
            ),
            duration: params.duration,
            leverageMultiplier: params.leverageMultiplier
        };
    }, [params]);

    const { data: rawData, ...query } = useLoopBestQuoteQuery(quoteParams ?? skipToken, {
        skip: !quoteParams
    });

    const data = useMemo((): LoopQuoteExpanded | null | undefined => {
        if (!params.loopExpanded || !quoteParams || !params.collateralAmountUi) return undefined;
        if (!rawData) return rawData;
        const collateralAmount = params.collateralAmountUi;
        const bestQuote = convertOfferQuote(rawData.bestQuote, params.loopExpanded.principalToken.assetMint);
        const { principalAmount, leveragedCollateralAmount } = getLoopLeveragedAmounts({
            loopExpanded: params.loopExpanded,
            leverageMultiplier: quoteParams.leverageMultiplier,
            collateralAmount
        });
        return {
            ...rawData,
            netApyPct: percentUiToDecimals(rawData.netApyPct),
            bestQuote,
            leverageMultiplier: quoteParams.leverageMultiplier,
            collateralAmount,
            principalAmount,
            leveragedCollateralAmount
        };
    }, [rawData, params.loopExpanded, params.collateralAmountUi, quoteParams, convertOfferQuote]);

    return { data, ...query };
}

export function calculateLoopNetApy({
    collateralYield,
    leverageMultiplier,
    principalBorrowRate,
    ltv
}: {
    collateralYield: number;
    leverageMultiplier: number;
    principalBorrowRate: number;
    ltv: number;
}) {
    const collateralWeighted = leverageMultiplier;
    const principalWeighted = collateralWeighted * ltv;
    const netApy = collateralWeighted * collateralYield - principalWeighted * principalBorrowRate;
    return netApy;
}

export function getLoopBorrowCapDetails(loopExpanded: LoopExpanded | undefined) {
    if (!loopExpanded) return undefined;
    return getBorrowCapDetails({
        borrowCap: loopExpanded.borrowCap,
        token: loopExpanded.principalToken,
        principalUtilized: loopExpanded.principalStats.principalUtilized
    });
}

export function useAllUserLoops(filter: UserLoopFilter) {
    const { data: loops } = useLoopsExpanded({});
    return useUserLoops({ loopsExpanded: loops, filter, skip: !loops });
}

// leverage must be at least 1 to start a loan
export const isValidLeverage = (leverageMultiplier: number | undefined): leverageMultiplier is number =>
    greaterThan(leverageMultiplier, 1);

function getDexesFromRouteType(routeType: LoopRouteType | null) {
    switch (routeType) {
        case LoopRouteType.Orca:
            return ["Whirlpool", "Perps"];
        case LoopRouteType.JlpPerps:
            return ["Perps"];
        case LoopRouteType.Meteora:
            return [];
        default:
            return ["Whirlpool"];
    }
}

interface UnwindTokenStats {
    receiveTokenAmount: number;
    receiveUsdAmount: number;
    tokenToReceive: BsMetadata | undefined;
}

interface ProfitLossStats {
    profitLossUsd: number;
    profitLossTokenAmount: number | undefined;
    contributions: ReturnType<typeof sumLoopPositionUsdContributions>;
    tokenStats: UnwindTokenStats | null;
}

export function calculateUnwindStats({
    loopPosition,
    externalQuote
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: ExternalUnwindQuote;
}): ProfitLossStats {
    const { loopExpanded } = loopPosition;
    const contributions = sumLoopPositionUsdContributions(loopPosition);

    const tokenStats = (() => {
        if (loopExpanded.withdrawType === LoopTransferType.CollateralOnly) {
            return calcUnwindStatsCollateral({ loopPosition, externalQuote });
        }
        return calcUnwindStatsPrincipal({ loopPosition, externalQuote });
    })();

    const profitLossUsd = tokenStats.receiveUsdAmount - contributions.collateralContributionUsd;
    let profitLossTokenAmount = undefined;
    if (loopExpanded.withdrawType !== LoopTransferType.PrincipalOnly) {
        profitLossTokenAmount = tokenStats.receiveTokenAmount - contributions.collateralContribution;
    }

    return { tokenStats, profitLossUsd, profitLossTokenAmount, contributions };
}

function calcUnwindStatsPrincipal({
    loopPosition,
    externalQuote
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: ExternalUnwindQuote;
}): UnwindTokenStats {
    const { loopExpanded, loanExpanded } = loopPosition;

    const principalSwapped = (() => {
        if (loopExpanded.type !== LoopRoutePlatform.Meteora || externalQuote.type !== LoopRoutePlatform.Meteora) {
            return 0;
        }
        const { tokenAMint } = loopExpanded.meteoraPool;

        const tokenAIsPrincipal = tokenAMint === loopPosition.loopExpanded.principalToken.assetMint;
        const rawPrincipalAmount = tokenAIsPrincipal
            ? externalQuote.quote.tokenAOutAmount
            : externalQuote.quote.tokenBOutAmount;

        return bigNumberStringToUiAmount(rawPrincipalAmount, loopPosition.loopExpanded.principalToken.decimals);
    })();

    const receiveTokenAmount = Math.max(principalSwapped - loanExpanded.debt.total, 0);
    const receiveUsdAmount = loopExpanded.principalOracle.getUsdAmount(receiveTokenAmount);

    const tokenToReceive = loopExpanded.principalToken;

    return {
        receiveTokenAmount,
        receiveUsdAmount,
        tokenToReceive
    };
}

function calcUnwindStatsCollateral({
    loopPosition,
    externalQuote
}: {
    loopPosition: LoopPositionExpanded;
    externalQuote: ExternalUnwindQuote;
}): UnwindTokenStats {
    const { userLoopInfo, loopExpanded } = loopPosition;

    const inputCollateral = (() => {
        if (externalQuote.type === LoopRoutePlatform.Jupiter) {
            return stringLamportsToUiAmount(
                externalQuote.quote.inAmount,
                loopPosition.loopExpanded.collateralToken.decimals
            );
        }
        return 0;
    })();

    const receiveTokenAmount = Math.max(userLoopInfo.totalCollateralDepositedAmount - inputCollateral, 0);
    const receiveUsdAmount = loopExpanded.collateralOracle.getUsdAmount(receiveTokenAmount);

    const tokenToReceive = loopExpanded.collateralToken;

    return {
        receiveTokenAmount,
        receiveUsdAmount,
        tokenToReceive
    };
}

const HISTORICAL_PRICE_CRON_INTERVAL = TIME.MINUTE * 15;
export function calculateHistoricalLoopProfitLoss(position: LoopPositionExpanded): ProfitLossStats {
    const { userLoopInfo, loopExpanded } = position;

    const collateralPrice =
        isLoanActive(position.loanExpanded) || !userLoopInfo.endCollateralUsdPrice
            ? loopExpanded.collateralOracle.usdPrice
            : userLoopInfo.endCollateralUsdPrice;

    const netPositionUsd = position.userLoopInfo.netPositionValue;
    const contributions = sumLoopPositionUsdContributions(position);
    const netPositionCollateralAmount = collateralPrice ? position.userLoopInfo.netPositionValue / collateralPrice : 0;
    const profitLossTokenAmount = netPositionCollateralAmount - contributions.collateralContribution;

    const profitLossUsd = netPositionUsd - contributions.collateralContributionUsd;

    return { profitLossUsd, contributions, profitLossTokenAmount, tokenStats: null };
}

export function sumLoopPositionUsdContributions({ userLoopInfo, loopExpanded, loanExpanded }: LoopPositionExpanded) {
    // cron updates rates every 15 minutes so oracle price might be more recent than userLoopInfo.initialCollateralPrice
    const recentStartTime = Math.max(getUnixTs() - loanExpanded.loan.loanStartTime, 0) < HISTORICAL_PRICE_CRON_INTERVAL;
    const startingCollateralPrice = recentStartTime
        ? loopExpanded.collateralOracle.usdPrice
        : userLoopInfo.initialCollateralPrice;

    const initialDepositAmount = userLoopInfo.initialCollateralAmount;

    const initialCollateralUsd = initialDepositAmount * startingCollateralPrice;
    const collateralContributionUsd = initialCollateralUsd + userLoopInfo.additionalCollateralDepositsUsd;
    const additionalDepositsUsd = userLoopInfo.additionalCollateralDepositsUsd + userLoopInfo.totalPaidUsd;

    const totalContributionsUsd = collateralContributionUsd + additionalDepositsUsd;
    const collateralContribution = initialDepositAmount + userLoopInfo.additionalCollateralDepositedAmount;

    return {
        totalContributionsUsd,
        initialCollateralUsd,
        collateralContributionUsd,
        additionalDepositsUsd,
        collateralContribution
    };
}

export function isLstLoop(loop: LoopExpanded) {
    return loop.collateralToken.tags?.includes(TokenListTag.LST) && loop.principalToken.assetMint === WRAPPED_SOL_MINT;
}
