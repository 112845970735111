import { Column, Image, Row, SxType, Text } from "@bridgesplit/ui";

export const ASSET_SIDEBAR_PADDING = 3;
export function AssetHeader({
    image,
    name,
    description,
    circleImage,
    sx
}: {
    image: string | undefined;
    name: string | undefined;
    description: string | undefined;
    circleImage?: boolean;
    sx?: SxType;
}) {
    const loading = !image || !name || !description;
    return (
        <Row sx={sx} spacing={1}>
            <Image loading={loading} size="30px" variant={circleImage ? "circle" : "rounded"} src={image} />
            <Column>
                <Text loading={loading}>{name}</Text>
                <Text loadingWidth="50px" loading={loading} color="caption" variant="body2">
                    {description}
                </Text>
            </Column>
        </Row>
    );
}
