import { useMemo } from "react";

import { ALLOWED_FEATURES } from "@bridgesplit/utils";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { BsMetaUtil, useOraclePrices } from "@bridgesplit/abf-react";
import { TagTextAlert } from "@bridgesplit/ui";

import { AppButton } from "../../common";
import { useMarketBorrow, useMarketCapDetails, useNapoleonErrors, useNapoleonFetching } from "../util";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useBorrowBalanceChecker, useBorrowStats } from "./util";
import { useMarketContext } from "../common";

export default function BorrowCta() {
    const borrow = useMarketBorrow();

    const alertError = useAlertError();
    const ctaError = useCtaError();

    return (
        <>
            {!!alertError && (
                <TagTextAlert color="error" icon="warning">
                    {alertError}
                </TagTextAlert>
            )}
            <AppButton
                isTransaction
                asyncCta={{
                    onClickWithResult: borrow,
                    options: { alertOnError: true }
                }}
                color="secondary"
                disabled={!!alertError || !!ctaError}
            >
                {ctaError || "Borrow"}
            </AppButton>
        </>
    );
}

function useCtaError() {
    const {
        form: { amount, collateralAmount, preset }
    } = useMarketBorrowContext();
    const isFetching = useNapoleonFetching();

    return useMemo(() => {
        if (isFetching) return "Loading offers...";
        if (!amount) return "Enter an amount to borrow";
        if (!preset) return "Select duration";

        if (!collateralAmount) return "No collateral selected";

        return null;
    }, [amount, collateralAmount, isFetching, preset]);
}

function useAlertError() {
    const { form, bestQuote, allDurationQuotes, activeUserCollateral, maxQuote, prices, quoteFetching } =
        useMarketBorrowContext();
    const queryError = useQueryError();
    const { insufficientBalance } = useBorrowBalanceChecker();

    const borrowCapDetails = useMarketCapDetails();
    const { insufficientCollateral } = useBorrowStats();
    const { token } = useMarketContext();
    const isFetching = useNapoleonFetching();
    const { isLoading: oracleLoading } = useOraclePrices(undefined);

    const principalSymbol = BsMetaUtil.getSymbol(token);
    const collateralSymbol = BsMetaUtil.getSymbol(activeUserCollateral?.metadata);
    return useMemo(() => {
        if (queryError) return queryError;
        if (isFetching || quoteFetching) return undefined;
        if (borrowCapDetails?.remainingGlobalCapacity === 0) return `${principalSymbol} borrow cap exceeded`;
        if (!oracleLoading && token && (form.collateralAmount || form.amount)) {
            if (prices.principalOracle === null) return `No price found for ${principalSymbol}`;
            if (!prices.collateralOracle && !!activeUserCollateral) return `No price found for ${collateralSymbol}`;
        }

        if (!form.amount || !form.preset) return undefined;
        if (!!activeUserCollateral?.amount && !maxQuote?.maxBorrow) return "No available offers";
        if (!bestQuote) {
            if (!!maxQuote?.maxBorrow && form.amount > maxQuote?.maxBorrow) {
                if (form.amount > maxQuote.principalAvailable)
                    return `Market doesn't have enough ${principalSymbol} supplied`;
                return "Exceeds your borrow limit";
            }
            if (allDurationQuotes?.length === 0)
                return `No offers for ${formatDurationWithTypeShorthand(
                    form.preset.duration,
                    form.preset.durationType
                )} duration`;
            return "No matching offers";
        }

        if (!form.collateralAmount) return undefined;
        if (insufficientBalance) return "Insufficient collateral balance";
        if (borrowCapDetails) {
            const { exceedsGlobalCap, exceedsPerLoanCap, borrowCap } = borrowCapDetails;
            if (exceedsPerLoanCap(form.amount))
                return `Exceeds ${BsMetaUtil.formatAmount(token, borrowCap?.perLoan)} per loan`;
            if (exceedsGlobalCap(form.amount)) return "Exceeds borrow cap";
        }

        if (insufficientCollateral && !ALLOWED_FEATURES.allowLowLtv) return "Exceeds your borrow limit";
        return null;
    }, [
        activeUserCollateral,
        allDurationQuotes?.length,
        bestQuote,
        borrowCapDetails,
        collateralSymbol,
        form.amount,
        form.collateralAmount,
        form.preset,
        insufficientBalance,
        insufficientCollateral,
        isFetching,
        maxQuote?.maxBorrow,
        maxQuote?.principalAvailable,
        oracleLoading,
        prices.collateralOracle,
        prices.principalOracle,
        principalSymbol,
        queryError,
        quoteFetching,
        token
    ]);
}

function useQueryError() {
    const quoteErrors = useNapoleonErrors();
    const { isError: pricingError } = useOraclePrices(undefined);

    if (pricingError) {
        return "Unable to fetch the latest prices";
    }

    if (quoteErrors) {
        return "Unable to fetch quote";
    }

    return null;
}
