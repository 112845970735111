import { useUserEscrowedPrincipalByMint } from "@bridgesplit/abf-react";
import { Column, StatRow } from "@bridgesplit/ui";
import { USDC_MINT, USDC_SYMBOL } from "@bridgesplit/utils";

export default function BankOverviewHeader() {
    const balance = useUserEscrowedPrincipalByMint(USDC_MINT);
    return (
        <Column spacing={1}>
            <StatRow
                direction="column-reverse"
                stats={[{ value: balance, caption: "Account Balance", symbol: USDC_SYMBOL }]}
                variant="h3"
            />
        </Column>
    );
}
