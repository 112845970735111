import { LoopRouteType } from "@bridgesplit/abf-sdk";
import { JLP_MINT } from "@bridgesplit/utils";

import { isLstLoop } from "../api";
import { LoopExpanded } from "../types";
import { BsMetaUtil } from "../utils";

export const METEORA_POLL_INTERVAL = 15_000;
export const JUPITER_POLL_INTERVAL = 15_000;
export const getLoopSlippageTooltip = (loopExpanded: LoopExpanded, side: "wind" | "unwind") => {
    const principalSymbol = BsMetaUtil.getSymbol(loopExpanded.principalToken);
    const collateralSymbol = BsMetaUtil.getSymbol(loopExpanded.collateralToken);
    if (side === "wind") {
        return `Max slippage you're willing to accept on the swap from ${principalSymbol} to ${collateralSymbol}`;
    }
    return `Max slippage you're willing to accept on the swap from ${collateralSymbol} to ${principalSymbol}`;
};

export function getLoopSlippage(loop: LoopExpanded | undefined): {
    defaultSlippageUi: number;
    slippagePresetsUi: number[];
} {
    if (loop) {
        if (isLstLoop(loop)) {
            return { defaultSlippageUi: 0.02, slippagePresetsUi: [0.02, 0.1, 0.5] };
        }
        if (loop.collateralToken.assetMint === JLP_MINT) {
            return { defaultSlippageUi: 0.25, slippagePresetsUi: [0.25, 0.5, 1] };
        }

        if (loop.loopVault.routeType === LoopRouteType.Meteora) {
            return { defaultSlippageUi: 0.1, slippagePresetsUi: [0.1, 0.5, 1] };
        }
    }

    return { defaultSlippageUi: 0.5, slippagePresetsUi: [0.5, 1, 2] };
}

export const FEATURED_LOOPS = [
    "HubSo1YpgLbthmxb8W7MHuorE9GbNv7JaMas72PrZ3BQ",
    "J1PFHoYpgLbthmxb8W7MHuorE9GbNv7JaMas72PrZ3BQ",
    "METUSDCpgLbthmxb8W7MHuorE9GbNv7JaMas72PrZ3BQ"
];
