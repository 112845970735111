import { ReactNode } from "react";

import { AppDialog } from "app/utils";

import { useMarketContext } from "../common";
import { SelectTokenDialogInternal } from "./SelectTokenDialog";
import { MultiSelectCollateralInternal } from "./MultiSelectCollateralDialog";
import { BorrowSelectCollateralInternal } from "./BorrowSelectCollateralDialog";
import { MarketOnboardingDialogInternal } from "../guide/MarketGuideDialog";
import { TemplateSelectDialogInternal } from "./TemplateSelectDialog";
import { LendApyDialogInternal } from "./LendApyDialog";

export default function MarketDialogWrapper({ children }: { children: ReactNode }) {
    const { internalOpenDialog } = useMarketContext();
    switch (internalOpenDialog?.type) {
        case AppDialog.SelectToken:
            return <SelectTokenDialogInternal />;
        case AppDialog.MultiSelectCollateral:
            return <MultiSelectCollateralInternal />;
        case AppDialog.BorrowSelectCollateral:
            return <BorrowSelectCollateralInternal />;
        case AppDialog.MarketGuide:
            return <MarketOnboardingDialogInternal />;
        case AppDialog.TemplateSelect:
            return <TemplateSelectDialogInternal />;
        case AppDialog.LendApy:
            return <LendApyDialogInternal />;
        default:
            return <>{children}</>;
    }
}
