import { BRIDGESPLIT_TS_API } from "@bridgesplit/utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const TRANSACTIONS_TAG = "Transactions";

export const typescriptApi = createApi({
    reducerPath: "typescriptApi",
    baseQuery: fetchBaseQuery({ baseUrl: BRIDGESPLIT_TS_API }),
    tagTypes: [TRANSACTIONS_TAG],
    endpoints: (builder) => ({
        domainLookup: builder.query<string, string>({
            query(domain) {
                return {
                    responseHandler: "text",
                    url: `/dns/email/${domain}`,
                    method: "GET"
                };
            }
        })
    })
});

export const { useDomainLookupQuery } = typescriptApi;
