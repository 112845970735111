import { KycStatus } from "./identity";

export interface SphereUserInfo {
    id: number;
    identifier: string;
    kycStatus: KycStatus;
    sphereCustomerId: string;
    userType: SphereUserType;
    tosStatus: SphereTOSStatus;
}

export enum SphereUserType {
    Individual,
    Business
}
export enum SphereTOSStatus {
    NotStarted,
    Pending,
    Accepted
}

export interface SphereUserBankAccount {
    id: number;
    userIdentifier: string;
    sphereBankAccountId: string;
    last4: string;
    nickname: string;
    bankName: string;
    tellerBankAccountId?: string;
    // the status with our provider (currently just Teller)
    providerBankAccountStatus: ProviderBankAccountStatus;
    // the status with Sphere's provider (ex. Bridge)
    sphereBankAccountStatus: SphereBankAccountStatus;
}

export enum ProviderBankAccountStatus {
    Connected,
    Disconnected,
    AccountLocked,
    EnrollmentInactive,
    CredentialsInvalid,
    CaptchaRequired,
    MfaRequired,
    WebLoginRequired
}

export enum SphereBankAccountStatus {
    Pending,
    Rejected,
    Accepted
}

export interface SphereUserWallets {
    id: number;
    userIdentifier: string;
    sphereWalletId: string;
    nickname: string;
    pubkey: string;
    network: string;
}

export interface SpherePayout {
    id: number;
    identifier: string;
    spherePayoutId: string;
    payoutStatus: PayoutStatus;
    payoutType: PayoutType;
    createdTime: number;
    updatedTime: number;
    sourceNetwork: Network;
    destinationNetwork: Network;
    amount: number;
    sourceCurrency: Currency;
    destinationCurrency: Currency;
    wallet: string;
    bankAccountId: string;
}

export interface SpherePayoutInfo {
    payout: SpherePayout;
    fundingWallet: SpherePayoutFundingWallet | null;
}

export interface SpherePayoutFundingWallet {
    fundingWallet: string;
    spherePayoutId: string;
    id: number;
}

export interface SpherePayoutExpanded extends SpherePayout {
    fundingWallet: string | null;
    bankAccount: SphereUserBankAccount;
}

export enum PayoutType {
    OnRamp,
    Offramp
}

export enum PayoutStatus {
    Pending,
    Success,
    Funded,
    Failed,
    Created,
    Processing,
    Dispatched
}

export enum Network {
    Sol,
    Wire,
    ACH,
    ACHPush,
    ACHPull
}

export enum Currency {
    USD,
    USDC
}

export interface CreateWalletInput {
    pubkey: string;
    nickname: string;
}

export type CreateBankAccountInput = {
    nickname: string;
    bankAccountInfo: BankAccountInfo;
};

type BankAccountInfo = RawBankAccountInfo | TellerInfo;

export interface RawBankAccountInfo {
    accountName: string;
    accountNumber: string;
    routingNumber: string;
    accountType: SphereBankAccountType;
    bankName: string;
}
export interface TellerInfo {
    tellerAccessToken: string;
    tellerBankAccountId: string;
}

export interface CreateCustomerInput {
    solanaPubKey?: string;
    email: string;
    phoneNumber: string;
    discord?: string;
    twitter?: string;
    telegram?: string;
    address: NewAddress;
    dob: DOB;
    ssn: string;
}

export interface CreateBusinessCustomerInput {
    businessName: string;
    email: string;
    ein: string;
    website: string;
    phoneNumber: string;
    address: NewAddress;
    uboInfo: UBOInfo[];
    incorporationDate: DOB;
}

export interface NewAddress {
    line1: string;
    line2?: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
}

export interface UBOInfo {
    fullName: string;
    email: string;
}

export interface DOB {
    day: number;
    month: number;
    year: number;
}

export interface CreatePayoutInput {
    walletAddress: string;
    bankAccountId: string;
    uiAmount: string;
}

export interface NewUboInput {
    ssn: string;
    dob: DOB;
    address: NewAddress;
}

export interface SpherePayoutFilter {
    payoutIds?: string[];
    payoutStatuses?: PayoutStatus[];
    payoutTypes?: PayoutType[];
    createdBefore?: number;
    createdAfter?: number;
    updatedBefore?: number;
    updatedAfter?: number;
    sourceNetwork?: Network;
    destinationNetwork?: Network;
    sourceCurrency?: Currency;
    destinationCurrency?: Currency;
}

export enum SphereBankAccountType {
    Checking = "Checking",
    Savings = "Savings"
}

export interface SphereCustomerToken {
    customer: Customer;
    url: string;
    customer_id: string;
    application_id: string;
    token: string;
}

export interface Customer {
    id: string;
    address: NewAddress;
    email: string;
    name: string;
    phone: string;
    kyc: string;
    meta: Record<string, string>;
    customerType?: string;
    wallets: SphereWallet[];
    kycRequirements: string[];
    tos: string;
    bankAccounts: string[];
}

interface SphereWallet {
    id: string;
    address: string;
    network: string;
    nickname: string;
    primary: boolean;
    signed: boolean;
    customer?: string;
    created: string;
    updated: string;
}

export interface BusinessKybStatus {
    uboDocumentUploaded: boolean;
    incorporationDocumentUploaded: boolean;
    uboStatuses: { status: UboStatus; email: string }[];
}

export enum UboStatus {
    NotAdded,
    Added
}
