import { useState } from "react";

import { BsMetaUtil, TokenBalanceExpandedWithPrice, useCustodiansByIdentifiers } from "@bridgesplit/abf-react";
import { Column, Image, Row, Text, TextButton, repeatElement } from "@bridgesplit/ui";
import { AbfCustodian } from "@bridgesplit/abf-sdk";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { AssetHeader } from "./common";

type CustodianWithDeposits = { deposits: TokenBalanceExpandedWithPrice[]; custodian: AbfCustodian };
export default function CustodianAssets({ deposits }: { deposits: TokenBalanceExpandedWithPrice[] | undefined }) {
    const { cache } = useCustodiansByIdentifiers(deposits?.map((d) => d.metadata.assetOriginator));
    const custodiansMap = deposits
        ?.filter((d) => BsMetaUtil.isNonFungible(d.metadata))
        .reduce((map, curr) => {
            const custodianIdentifier = curr.metadata.assetOriginator;
            const prevDeposits = map.get(custodianIdentifier)?.deposits ?? [];
            const custodian = cache?.get(custodianIdentifier);
            if (!custodian) return map;
            map.set(custodianIdentifier, { custodian, deposits: [...prevDeposits, curr] });
            return map;
        }, new Map<string, CustodianWithDeposits>());
    const custodianWithDeposits = custodiansMap ? Array.from(custodiansMap.values()) : custodiansMap;

    if (!custodiansMap?.size) return null;
    return (
        <>
            {!custodianWithDeposits &&
                repeatElement(<AssetHeader name={undefined} description={undefined} image={undefined} />)}

            {custodianWithDeposits?.map((c) => (
                <CustodianDisplay key={c.custodian.groupIdentifier} {...c} />
            ))}
        </>
    );
}

function CustodianDisplay({ custodian, deposits }: CustodianWithDeposits) {
    const [expanded, setExpanded] = useState(false);
    return (
        <Column spacing={1}>
            <Row sx={{ cursor: "pointer" }} onClick={() => setExpanded((prev) => !prev)} spaceBetween>
                <AssetHeader image={custodian.logo} name={custodian.name} description={`${deposits.length} assets`} />
                <TextButton color="disabled" variant="h2">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </TextButton>
            </Row>

            <Collapse in={expanded}>
                {deposits.map((deposit) => (
                    <Row sx={{ my: 1 }} key={deposit.mint} spacing={1}>
                        <Image size="20px" variant="rounded" src={BsMetaUtil.getImage(deposit.metadata)} />
                        <Text>{BsMetaUtil.formatAmount(deposit.metadata, deposit.amount)} </Text>
                    </Row>
                ))}
            </Collapse>
        </Column>
    );
}
