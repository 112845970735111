import { BsMetaUtil, LoanRequestExpanded, isRequestFromStrategy, useEscrowAccounts } from "@bridgesplit/abf-react";
import { BreadcrumbsInterface, Breadcrumbs, StatProps, MenuItemProps } from "@bridgesplit/ui";
import { AbfCustodian, DEFAULT_DURATION, LoanRequest, getDurationDetails } from "@bridgesplit/abf-sdk";
import { formatMinMax, removeDuplicates } from "@bridgesplit/utils";
import { Edit, Tune, Delete } from "@mui/icons-material";
import { COPY, CUSTODIANS_SLUG, PORTFOLIO_REQUESTS_SLUG, REQUEST_SLUG } from "app/constants";
import { useAppDialog, AppDialog } from "app/utils";

export const getLoanRequestPath = (loanRequest: LoanRequest | undefined) => {
    if (!loanRequest) return REQUEST_SLUG;
    return `${REQUEST_SLUG}/${loanRequest.nonce}`;
};

export function getCustodianIdentifiersFromRequest(request: LoanRequestExpanded | undefined) {
    return request?.custodians;
}

export function getCustodiansFromRequest(request: LoanRequestExpanded | undefined) {
    if (!request) return undefined;
    return removeDuplicates(request.collateral.map((c) => c.custodian).filter((c): c is AbfCustodian => !!c));
}

export function RequestBreadCrumbs({
    loanRequest,
    lastPathText,
    isLoading
}: {
    loanRequest: LoanRequestExpanded | undefined;
    lastPathText?: string;
    isLoading?: boolean;
}) {
    const loading = !loanRequest || isLoading;
    const { escrowPubkeys } = useEscrowAccounts();

    const paths: BreadcrumbsInterface[] = [
        { text: loanRequest?.loanRequest?.name, path: getLoanRequestPath(loanRequest?.loanRequest) }
    ];

    if (loanRequest?.loanRequest?.maker && escrowPubkeys?.includes(loanRequest?.loanRequest?.maker)) {
        paths.unshift({
            text: "Funding Requests",
            path: PORTFOLIO_REQUESTS_SLUG
        });
    } else {
        paths.unshift({ text: "Requests", path: CUSTODIANS_SLUG });
    }

    if (lastPathText) {
        paths.push({ text: lastPathText });
    }
    return <Breadcrumbs loading={loading} paths={paths} />;
}

export function useRequestStats(request: LoanRequestExpanded | undefined) {
    const symbol = BsMetaUtil.getSymbol(request?.principalMetadata ?? undefined);

    const stats: StatProps[] = [
        {
            caption: "Principal",
            value: formatMinMax({
                fallback: COPY.REQUEST_DEFAULT,
                min: request?.loanRequest.principalAmountMin,
                max: request?.loanRequest.principalAmountMax,
                symbol: symbol
            })
        },
        {
            caption: "Term",
            value: formatMinMax({
                fallback: COPY.REQUEST_DEFAULT,
                min: request?.loanRequest.durationMin,
                max: request?.loanRequest.durationMax,
                symbol: getDurationDetails(request?.loanRequest.durationType ?? DEFAULT_DURATION).unit + "s"
            })
        },
        {
            caption: "APY",
            value: formatMinMax({
                fallback: COPY.REQUEST_DEFAULT,
                min: request?.loanRequest.apyMin,
                max: request?.loanRequest.apyMax,
                symbol: "%"
            })
        }
    ];
    return stats;
}

export function useRequestActionItems(request: LoanRequestExpanded | undefined) {
    const { open: openDialog } = useAppDialog();

    const openLoanDialog = (type: AppDialog) => () => openDialog(type, request);

    const items: MenuItemProps[] = [
        {
            icon: <Edit />,
            text: AppDialog.ModifyLoanRequestDetails,
            onClick: openLoanDialog(AppDialog.ModifyLoanRequestDetails)
        },
        {
            icon: <Tune />,
            text: AppDialog.ModifyLoanRequestPreferences,
            onClick: openLoanDialog(AppDialog.ModifyLoanRequestPreferences),
            hide: isRequestFromStrategy(request?.loanRequest)
        },
        {
            icon: <Delete />,
            text: AppDialog.CancelLoanRequest,
            onClick: openLoanDialog(AppDialog.CancelLoanRequest)
        }
    ];
    return items;
}
