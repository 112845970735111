import { TextLink, useAppPalette, BORDER_RADIUS_ROUNDED, SPACING } from "@bridgesplit/ui";
import { useLocation } from "react-router-dom";
import { colorToAlpha } from "@bridgesplit/utils";

import { NAV_BREAKPOINT } from "./constants";
import { getTabPath } from "../common";
import { NavOption } from "./types";

export default function NavOptions({ options }: { options: NavOption[] }) {
    const location = useLocation();
    const { hoverBackground, textSecondary, secondary, primary, isDarkMode } = useAppPalette();

    const selectedColor = isDarkMode ? primary : secondary;

    return (
        <>
            {options
                .filter((o) => !o.mobileOnly)
                .map((item, j) => {
                    const active = (function () {
                        if (location.pathname === "/") return item.path === "/";
                        const pathMatches = item.pathRegex
                            ? item.pathRegex.test(location.pathname)
                            : item.path.includes(location.pathname);
                        if (!pathMatches) return false;
                        if (item.tab) return location.search.includes(item.tab);

                        const pathSearch = item.path.split("?")[1];
                        if (!pathSearch) return true;

                        return location.search.includes(pathSearch);
                    })();

                    return (
                        <TextLink
                            key={j}
                            sx={{
                                [NAV_BREAKPOINT.below]: {
                                    width: `calc(100% - ${4 * SPACING}px)`,
                                    px: 2,
                                    py: 1
                                },
                                [NAV_BREAKPOINT.above]: {
                                    width: "100%",
                                    py: 1,
                                    px: 2,
                                    borderRadius: BORDER_RADIUS_ROUNDED,
                                    background: active ? colorToAlpha(selectedColor, 0.1) : "transparent"
                                },
                                ":hover": { background: active ? undefined : hoverBackground }
                            }}
                            textSx={{
                                width: "max-content",
                                color: active ? selectedColor : textSecondary,

                                ":hover": { color: active ? selectedColor : undefined }
                            }}
                            to={item.tab ? getTabPath(item.path, item.tab) : item.path}
                        >
                            {item.icon} {item.title}
                        </TextLink>
                    );
                })}
        </>
    );
}
