import { ReactNode, useMemo, useState } from "react";

import {
    AbfGroupType,
    AbfPermission,
    AbfPermissionForCustodian,
    AbfUserGroup,
    deriveLoanVaultAddress,
    isFilledOffer,
    useGroupByAdminEmailsQuery,
    useLoanInfos,
    useSyndicatedOrders,
    useGroup,
    useUserIdentity,
    useActiveGroup,
    useUserIsPrime,
    useUserMe,
    useAccessLevel
} from "@bridgesplit/abf-react";
import { AsyncHandlerOptions, useAsyncResultHandler, useTransactionsState, useUserPublicKey } from "@bridgesplit/react";
import {
    BORDER_RADIUS,
    Button,
    ButtonProps,
    Column,
    ErrorWrapper,
    FormDebouncedStringInput,
    FormInput,
    FormLabel,
    Icon,
    Image,
    PAGE_CARD_WIDTH,
    RandomAvatarSvg,
    Row,
    SupportedKey,
    TagText,
    Text,
    TextVariant,
    Tooltip,
    VerifiedIcon,
    WalletDisplay,
    WalletSelectToggle,
    emailFormAdornments,
    useAppPalette,
    useKeyDetecter
} from "@bridgesplit/ui";
import { EMAIL_REGEX, Result, colorToAlpha, getDeterministicIndexFromSeed } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { AppDialog, useAppDialog } from "app/utils";
import { AbfOrderFundingType } from "@bridgesplit/abf-sdk";

import { AccessWaitlistCard, LoopscaleBetaCard } from "../access";

export function UserTableDisplay({
    textVariant,
    group
}: {
    textVariant?: TextVariant;
    group?: AbfUserGroup | undefined;
}) {
    const { activeGroup } = useGroup();
    const isYou = group?.groupIdentifier === activeGroup?.groupIdentifier;

    return (
        <Text variant={textVariant}>
            {group?.groupName} {isYou && <TagText color="info"> You </TagText>}
        </Text>
    );
}

export function GroupRequiredWrapper({
    children,
    action,
    skip
}: {
    children: ReactNode;
    action: string | null;
    skip?: boolean;
}) {
    const { isNotConnected, isBeta, isLoading } = useAccessLevel();
    const { activeGroup } = useGroup({ skip });
    // const { open } = useAppDialog();

    if (isLoading) return null;

    if ((!!activeGroup && isBeta) || skip) {
        return <> {children} </>;
    }

    if (isNotConnected) {
        return (
            <Column
                spacing={2}
                sx={{
                    maxWidth: PAGE_CARD_WIDTH,
                    width: "100%"
                }}
            >
                <LoopscaleBetaCard action={action} />
            </Column>
        );
    }

    return (
        <Column
            spacing={2}
            sx={{
                maxWidth: PAGE_CARD_WIDTH,
                width: "100%"
            }}
        >
            <AccessWaitlistCard />
        </Column>
    );
}

export function UserStatDisplay({
    group,
    caption,
    loading
}: {
    group?: AbfUserGroup | undefined;
    caption: string;
    loading?: boolean;
}) {
    const { activeGroup } = useGroup();

    const isYou = group?.groupIdentifier === activeGroup?.groupIdentifier;

    return (
        <Row spacing={1}>
            <Image loading={loading} size="35px" variant="circle" src={group?.groupImage} />
            <Column>
                <Text loadingWidth="60px" loading={loading} variant="body2" color="caption">
                    {caption}
                </Text>
                <Row spacing={1}>
                    <Text loading={!group || loading}>{group?.groupName}</Text>
                    {isYou && !loading && (
                        <TagText textVariant="caption" color="info">
                            You
                        </TagText>
                    )}
                </Row>
            </Column>
        </Row>
    );
}

interface AsyncCtaOptions {
    options?: AsyncHandlerOptions;
    closeDialog?: "always" | "onSuccess" | "never";
}
interface AsyncCtaOptionsResult<T> extends AsyncCtaOptions {
    onClickWithResult: () => Promise<Result<T>>;
}
interface AsyncCtaOptionsNonResult<T> extends AsyncCtaOptions {
    onClick: () => Promise<T>;
}

export interface AppButtonProps<ResultType> extends ButtonProps {
    authentication?:
        | { permission: AbfPermission | undefined }
        | { permission: AbfPermissionForCustodian | undefined; custodian: string | undefined }
        | { permission: AbfPermissionForCustodian | undefined; custodians: string[] | undefined };
    handleUnauthorized?: "hide" | "message";
    tooltip?: string;
    asyncCta?: AsyncCtaOptionsResult<ResultType> | AsyncCtaOptionsNonResult<ResultType>;
    syncCta?: () => Result<ResultType>;
    errorMessage?: string;
    keyListener?: SupportedKey;
    kyc?: { required: boolean; actionDescription: string };
    isTransaction: boolean;
    overrideAccess?: boolean;
    requiresBeta?: boolean;
}
export function AppButton<ResultType>({
    authentication,
    handleUnauthorized = "message",
    tooltip,
    disabled: propsDisabled,
    onClick,
    asyncCta,
    syncCta,
    children,
    keyListener,
    errorMessage,
    fullWidth = true,
    variant = "contained",
    kyc,
    isTransaction,
    overrideAccess = false,
    ...props
}: AppButtonProps<ResultType>) {
    const { close, open } = useAppDialog();
    const { permissions } = useGroup();
    const { handler, resultHandler, syncHandler, isLoading } = useAsyncResultHandler<ResultType>();
    const { isTransactionSignaturePending } = useTransactionsState();
    const { isVerified, isLoading: statusLoading } = useUserIdentity();
    const { data: user } = useUserMe();
    const { isUnregistered, isNotConnected, isBeta, isWaitlist } = useAccessLevel();
    const transactionsInProgress = isTransaction && isTransactionSignaturePending;
    const authorized = useMemo(() => {
        if (!authentication) return true;

        if ("custodian" in authentication) {
            // loading
            if (!authentication.permission || !authentication.custodian) return false;

            return permissions?.validateForCustodian(authentication.custodian, authentication.permission);
        }

        if ("custodians" in authentication) {
            // loading
            if (!authentication.permission || !authentication.custodians) return false;

            return permissions?.validateForCustodians(authentication.custodians, authentication.permission);
        }

        // loading
        if (!authentication.permission) return false;

        return permissions?.validate(authentication.permission);
    }, [authentication, permissions]);

    const buttonTooltip = useMemo(() => {
        if (transactionsInProgress) return "Transaction in progress";
        return tooltip;
    }, [tooltip, transactionsInProgress]);

    const disabled = !authorized || propsDisabled || props.loading || !!errorMessage || transactionsInProgress;

    useKeyDetecter(keyListener ?? "Enter", handleClick, !keyListener || disabled);

    async function handleClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e && onClick?.(e);
        if (!overrideAccess || isNotConnected) {
            if (isNotConnected) return open(AppDialog.AccessLevelNotConnected, undefined);
            if (isWaitlist || (isUnregistered && !isBeta)) return open(AppDialog.AccessLevelWaitlist, undefined);
            if (isUnregistered && isBeta) return open(AppDialog.AccessLevelUnregistered, undefined);
        }

        if (asyncCta) {
            if (asyncCta.closeDialog === "always") {
                close();
            }

            const options: AsyncHandlerOptions = {
                ...asyncCta.options,
                alertOnError: asyncCta.options?.alertOnError ?? true
            };
            const isResult = "onClick" in asyncCta;
            const getResult = isResult
                ? handler(asyncCta.onClick, options)
                : resultHandler(asyncCta.onClickWithResult, options);
            const result = await getResult;
            if (result.isOk() && asyncCta.closeDialog === "onSuccess") {
                close();
            }
        }
        if (syncCta) {
            syncHandler(syncCta);
        }
    }

    if (user?.wallets.length === 0 && isTransaction && isBeta) {
        return <WalletRequiredConnect fullWidth={fullWidth} />;
    }

    if (!statusLoading && kyc?.required && !isVerified) {
        return (
            <Button
                variant="default"
                color="info"
                onClick={() => open(AppDialog.KYC, { ctaText: kyc.actionDescription })}
                fullWidth={fullWidth}
            >
                <VerifiedUserOutlined /> Identity Verification Required
            </Button>
        );
    }

    if (handleUnauthorized === "hide" && !authorized) {
        return null;
    }

    const button = (
        <Button
            fullWidth={fullWidth}
            width={fullWidth ? undefined : "fit-content"}
            variant={variant}
            loading={props.loading || isLoading}
            onClick={handleClick}
            disabled={disabled}
            {...props}
        >
            {handleUnauthorized === "message" && !authorized ? "Insufficient Permissions" : children}
        </Button>
    );

    if (!buttonTooltip) {
        if (!errorMessage) {
            return button;
        }
        return (
            <ErrorWrapper fullWidth={fullWidth} errorMessage={errorMessage}>
                {button}
            </ErrorWrapper>
        );
    }

    return (
        <ErrorWrapper fullWidth={fullWidth} errorMessage={errorMessage}>
            <Tooltip
                fullWidth={fullWidth}
                containerSx={{ cursor: disabled ? "not-allowed" : undefined }}
                title={buttonTooltip}
            >
                {button}
            </Tooltip>
        </ErrorWrapper>
    );
}

export function WalletRequiredConnect({ fullWidth }: { fullWidth?: boolean }) {
    const { open } = useAppDialog();

    return (
        <Button variant="contained" onClick={() => open(AppDialog.WalletSetup, undefined)} fullWidth={fullWidth}>
            <Icon type="connect" /> Connect Wallet
        </Button>
    );
}

export function WalletConnect({ connectedLabel }: { connectedLabel: string }) {
    const userPublicKey = useUserPublicKey();

    return (
        <Column spacing={1}>
            <Text color="caption" variant="body2">
                {userPublicKey ? connectedLabel : "Connect your self-custodial wallet"}
            </Text>
            {!userPublicKey ? <WalletSelectToggle /> : <WalletDisplay address={userPublicKey} />}
        </Column>
    );
}

export function useUserSyndicatedLoans(options?: { skip?: boolean }) {
    const { groupIdentifier } = useActiveGroup();
    const { isPrime } = useUserIsPrime();

    const skip = !groupIdentifier || !!options?.skip || !isPrime;
    const { data: syndicates } = useSyndicatedOrders(
        { contributors: groupIdentifier ? [groupIdentifier] : undefined },
        { skip }
    );

    const loanAddresses = syndicates
        ?.filter(({ placedOrder }) => (placedOrder ? isFilledOffer({ order: placedOrder }) : false))
        .map(({ syndicate }) => deriveLoanVaultAddress(syndicate.order));

    const loans = useLoanInfos({
        loanFilter: { loanAddresses, fundingTypes: [AbfOrderFundingType.Syndicated] },
        skip: !loanAddresses?.length || skip,
        pagination: null,
        overrideSyndicates: syndicates
    });
    if (skip || !loanAddresses?.length) return { data: [], isLoading: false, isFetching: false };

    return loans;
}

export function GroupLookup({
    label,
    group,
    setGroup,
    labelTooltip
}: {
    label?: string;
    labelTooltip?: string;
    group: AbfUserGroup | undefined;
    setGroup: (group: AbfUserGroup | undefined) => void;
}) {
    const [email, setEmail] = useState<string>();

    const { hoverBackground, background, border } = useAppPalette();
    const isValidEmail = email ? EMAIL_REGEX.test(email) : false;
    const emailSearch = email && EMAIL_REGEX.test(email) ? email : undefined;

    const { data: adminEmailMap, isLoading: userQueryLoading } = useGroupByAdminEmailsQuery(
        emailSearch ? [emailSearch] : skipToken,
        {
            skip: !emailSearch
        }
    );

    const groups =
        adminEmailMap && emailSearch && emailSearch in adminEmailMap ? adminEmailMap[emailSearch] : undefined;

    if (group) {
        return (
            <Column>
                {label && <FormLabel tooltip={labelTooltip} label={label} />}
                <Row
                    onClick={() => {
                        setGroup(undefined);
                        setEmail("");
                    }}
                    spaceBetween
                    sx={{ cursor: "pointer", border, borderRadius: BORDER_RADIUS, p: 1 }}
                >
                    <Row spacing={1}>
                        <Image size="20px" src={group.groupImage} />
                        <Text loading={!group}>{group.groupName}</Text>
                    </Row>
                    <Text color="disabled" isLink>
                        <Icon type="reject" />
                    </Text>
                </Row>
            </Column>
        );
    }

    return (
        <Column sx={{ position: "relative" }}>
            {label && <FormLabel tooltip={labelTooltip} label={label} />}

            <FormDebouncedStringInput
                InputProps={
                    userQueryLoading
                        ? { endAdornment: <CircularProgress size={10} /> }
                        : emailFormAdornments({ isValidEmail: isValidEmail || !email })
                }
                fullWidth
                placeholder="Email of organization owner"
                variant="string"
                value={email}
                setValue={setEmail}
            />

            {!!groups?.length && (
                <Column
                    sx={{
                        border,
                        width: "100%",
                        position: "absolute",
                        top: "70px",
                        zIndex: 2,
                        background,
                        borderRadius: BORDER_RADIUS,
                        overflow: "hidden"
                    }}
                >
                    {groups?.map((group, i) => (
                        <Row
                            onClick={() => setGroup(group)}
                            sx={{ cursor: "pointer", p: 1, background, ":hover": { background: hoverBackground } }}
                            spacing={1}
                            key={i}
                        >
                            <Image size="20px" src={group.groupImage} />
                            <Text loading={!group}>{group.groupName}</Text>
                        </Row>
                    ))}
                </Column>
            )}
        </Column>
    );
}

export function EmailInvite({
    emails,
    setEmail,
    placeHolder
}: {
    emails: Set<string>;
    setEmail: (email: string) => void;
    placeHolder?: string;
}) {
    const [inputEmail, setInputEmail] = useState<string>();

    const isValidEmail = inputEmail ? EMAIL_REGEX.test(inputEmail) : false;
    const emailAlreadyAdded = inputEmail ? emails?.has(inputEmail) : false;

    function addEmail() {
        if (!inputEmail || !isValidEmail) return;
        if (emailAlreadyAdded) {
            return;
        }
        setEmail(inputEmail);
        setInputEmail(undefined);
    }

    useKeyDetecter("Enter", addEmail);

    return (
        <FormInput
            errorMessage={emailAlreadyAdded ? "Email already added" : undefined}
            InputProps={emailFormAdornments({ isValidEmail: isValidEmail || !inputEmail })}
            fullWidth
            placeholder={placeHolder ?? "Email"}
            variant="string"
            key={emails?.toString()}
            value={inputEmail}
            setValue={setInputEmail}
        />
    );
}

export function GroupHeader() {
    const { activeGroup: group } = useGroup();

    return (
        <Row spacing={2}>
            <Image variant="circle" size={"50px"} src={group?.groupImage} />
            <Column>
                <Text loadingWidth="200px" loading={!group} variant="h2">
                    {group?.groupName}
                </Text>
                <Text loadingWidth="100px" loading={!group} color="caption">
                    {group?.groupType === AbfGroupType.Individual ? "Personal Account" : "Organization"}
                </Text>
            </Column>
        </Row>
    );
}

export function VerifiedGroupDisplay({ group, roleName }: { group: AbfUserGroup | undefined; roleName: string }) {
    return (
        <Row spacing={1}>
            <Image loading={!group} variant="circle" size="35px" src={group?.groupImage} />

            <Column>
                <Text loading={!group}> {group?.groupName}</Text>
                <Row spacing={0.5}>
                    <Text loading={!group} variant="body2" color="caption">
                        Verified {roleName}
                    </Text>
                    {group && <VerifiedIcon variant="body2" />}
                </Row>
            </Column>
        </Row>
    );
}

const AVATAR_COLORS = ["#11A8D7", "#2E36F6", "#BD37D3", "#D33737", "#D38237", "#7CD337"];

export function UserAvatar({ size }: { size: number }) {
    const { data } = useUserMe();

    if (!data) return <Skeleton variant="circular" width={size} height={size} />;

    const seed = data.user.identifier;
    const avatar = data.user.avatar;
    const colorIndex = getDeterministicIndexFromSeed(seed.charAt(seed.length - 1), AVATAR_COLORS.length);
    const color = AVATAR_COLORS[colorIndex];

    return (
        <Box
            sx={{
                color,
                background: avatar ? `url(${avatar})` : colorToAlpha(color, 0.1),
                backgroundSize: "cover",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: size + "px",
                height: size + "px",
                borderRadius: "100%"
            }}
        >
            {!avatar && <RandomAvatarSvg size={`${size / 2}px`} seed={seed} />}
        </Box>
    );
}
