import {
    LedgerAccountFilter,
    LedgerSorting,
    PaymentSorting,
    useLedgerAccounts,
    usePaymentEvents
} from "@bridgesplit/abf-react";
import { ApiPaginationProps, useApiTableController } from "@bridgesplit/ui";
import { SortOrder } from "@bridgesplit/utils";

const PAGE_SIZE = 5;
export function useLedgerAccountsWithPagination(
    filter: Omit<LedgerAccountFilter, "pagination">,
    params?: { skip?: boolean }
) {
    const baseProps: Partial<ApiPaginationProps> = {
        firstPage: 0,
        defaultSortKey: LedgerSorting.RepaymentTime,
        defaultOrder: SortOrder.Desc
    };
    const tableController = useApiTableController(baseProps);

    const { page, order, orderBy } = tableController;
    const { data, total, query } = useLedgerAccounts(
        {
            ...filter,
            pagination: { page: page, page_size: PAGE_SIZE },
            sorting: {
                direction: order,
                sorting_type: orderBy ? (orderBy as LedgerSorting) : LedgerSorting.RepaymentTime
            }
        },
        { skip: params?.skip }
    );

    const paginationProps: ApiPaginationProps = {
        ...baseProps,
        type: "api",
        totalDataCount: total,
        controller: tableController
    };

    return { data, total, paginationProps, query };
}

export function usePaymentEventsWithPagination(
    principalMint: string | undefined,
    loans: string[],
    params?: { skip?: boolean }
) {
    const baseProps: Partial<ApiPaginationProps> = {
        firstPage: 0,
        defaultSortKey: LedgerSorting.RepaymentTime,
        defaultOrder: SortOrder.Desc
    };
    const tableController = useApiTableController(baseProps);

    const { page, order, orderBy } = tableController;
    const { data, total, query } = usePaymentEvents(
        principalMint,
        {
            loans,
            pagination: { page: page, page_size: PAGE_SIZE },
            sorting: {
                direction: order,
                sorting_type: orderBy ? (orderBy as PaymentSorting) : PaymentSorting.RepaymentTime
            }
        },
        { skip: params?.skip }
    );

    const paginationProps: ApiPaginationProps = {
        ...baseProps,
        type: "api",
        totalDataCount: total,
        controller: tableController
    };

    return { data, total, paginationProps, query };
}
