import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";

import { ParsedWei, WeiBigNumberJson } from "../types";
import { roundToDecimals } from "./math";
import { EVM_NATIVE_DECIMALS } from "../constants";

export function parseWeiHex(raw: WeiBigNumberJson): ParsedWei {
    const wei = BigNumber.from(raw.hex);
    const uiAmount = parseFloat(formatEther(wei));
    return { uiAmount, wei };
}

export function parseGWeiHex(raw: WeiBigNumberJson): ParsedWei {
    const wei = BigNumber.from(raw.hex);
    const uiAmount = parseFloat(formatUnits(wei, "gwei"));
    return { uiAmount, wei };
}

export function roundToEther(ether: number | undefined = 0): number {
    return roundToDecimals(ether, EVM_NATIVE_DECIMALS);
}

function formatEther(wei: BigNumberish): string {
    return formatUnits(wei, 18);
}
