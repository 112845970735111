import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import {
    RawStakedSolByWalletResponse,
    RawTokenByWalletResponse,
    StakedSolByWalletResponse,
    TokenByWalletResponse
} from "../types";
import { GET_STAKED_SOL_ROUTE, GET_TOKENS_ROUTE } from "../constants";
import { abfBaseQueryPublic, abfSerializeQueryArgs, convertParsedBsMetadataToBsMetadata } from "./util";

export const ESCROWS_PUBLIC_TAG = "EscrowsPublic";
export const abfEscrowedAssetPublicApi = createApi({
    reducerPath: "abfEscrowedAssetPublicApi",
    baseQuery: abfBaseQueryPublic,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ESCROWS_PUBLIC_TAG],
    endpoints: (builder) => ({
        tokensByWallet: builder.query<TokenByWalletResponse, string>({
            providesTags: [ESCROWS_PUBLIC_TAG],
            query(wallet) {
                return {
                    url: `${GET_TOKENS_ROUTE}/${wallet}`,
                    method: "GET"
                };
            },
            transformResponse: (res: RawTokenByWalletResponse) => {
                return {
                    tokens: res.tokens,
                    metadataMap: Object.fromEntries(
                        Object.entries(res.metadataMap).map(([key, parsedMetadata]) => [
                            key,
                            convertParsedBsMetadataToBsMetadata(parsedMetadata)
                        ])
                    )
                };
            }
        }),
        stakedSolByWallet: builder.query<StakedSolByWalletResponse, { wallet: string; force: boolean }>({
            providesTags: [ESCROWS_PUBLIC_TAG],
            query({ wallet }) {
                return {
                    url: `${GET_STAKED_SOL_ROUTE}/${wallet}`,
                    method: "GET",
                    params: { force: true } // override the params since escrow db isn't accurate
                };
            },
            transformResponse: (res: RawStakedSolByWalletResponse) => {
                return {
                    stakedSol: res.stakedSol,
                    metadata: convertParsedBsMetadataToBsMetadata(res.metadata)
                };
            }
        })
    })
});

export const { useTokensByWalletQuery, useStakedSolByWalletQuery } = abfEscrowedAssetPublicApi;

export const useAbfEscrowedAssetPublicApi = () => {
    const dispatch = useDispatch();

    return {
        resetEscrowedPublicApi: () => dispatch(abfEscrowedAssetPublicApi.util.invalidateTags([ESCROWS_PUBLIC_TAG]))
    };
};
