import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { OracleQuoteInfo } from "@bridgesplit/abf-sdk";
import { getUnixTs } from "@bridgesplit/utils";

import { BID_ACCOUNT_ROUTE, FETCH_ORACLE_PRICES_ROUTE, TURN_ORACLE_CRANK_ROUTE } from "../constants";
import { abfSerializeQueryArgs, oracleBaseQuery, unauthenticatedSerializeQueryArgs } from "./util";
import { BidAccount, TurnOracleParams } from "../types";

const ORACLE_PRICES = "OraclePrices";
export const oraclePricesApi = createApi({
    reducerPath: "oraclePricesApi",
    baseQuery: oracleBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ORACLE_PRICES],
    endpoints: (builder) => ({
        oraclePrices: builder.query<Record<string, OracleQuoteInfo>, void>({
            serializeQueryArgs: unauthenticatedSerializeQueryArgs,
            providesTags: [ORACLE_PRICES],
            queryFn: async (args, _, options, baseQuery) => {
                const res = await baseQuery({ url: FETCH_ORACLE_PRICES_ROUTE, method: "GET" });
                const rawData = res.data as Record<string, Omit<OracleQuoteInfo, "nowTimestamp">>;
                const now = getUnixTs();
                const data = Object.fromEntries(
                    Object.entries(rawData).map(([key, value]): [string, OracleQuoteInfo] => [
                        key,
                        { ...value, nowTimestamp: now }
                    ])
                );

                return { data };
            }
        }),
        loanBidAccount: builder.query<BidAccount | null, string>({
            query(loanAddress) {
                return {
                    url: `${BID_ACCOUNT_ROUTE}/${loanAddress}`,
                    method: "GET"
                };
            }
        }),
        turnOracle: builder.mutation<void, TurnOracleParams>({
            invalidatesTags: [ORACLE_PRICES],
            query(params) {
                return {
                    url: TURN_ORACLE_CRANK_ROUTE,
                    body: params,
                    method: "POST"
                };
            }
        })
    })
});

export const { useOraclePricesQuery, useLoanBidAccountQuery, useTurnOracleMutation } = oraclePricesApi;

export function useOraclePricesApi() {
    const dispatch = useDispatch();

    return {
        resetOraclePricesApi: () => dispatch(oraclePricesApi.util.invalidateTags([ORACLE_PRICES]))
    };
}
