import { useMemo } from "react";

import { useSpherePlaid } from "@spherelabs/react-plaid";
import {
    SPHERE_CONVERSION_FEE_BPS,
    getEscrowAccountForGroup,
    sphereApi,
    useAbfFetches,
    useActiveEscrow,
    useActiveGroup,
    useActiveWallet,
    useCreateSphereOfframpMutation,
    useCreateSphereOnrampMutation,
    useCreateSphereWalletMutation,
    useEscrowWithdrawTransaction,
    usePageLoadIdempotentKey,
    useSphereCustomerTokenQuery,
    useGroup,
    useUserSphere,
    useUserWallets
} from "@bridgesplit/abf-react";
import {
    CreatePayoutInput,
    CreateWalletInput,
    NewAddress,
    SphereUserBankAccount,
    SphereUserWallets
} from "@bridgesplit/abf-sdk";
import {
    Result,
    LOADING_ERROR,
    filterUndefined,
    filterNullableRecord,
    NullableRecord,
    removeDuplicatesByProperty,
    DEFAULT_PUBLIC_KEY,
    sleep,
    getReadableErrorMessage,
    allKeysDefined,
    USDC_MINT,
    WALLET_ERROR,
    roundDownToDecimals,
    bpsToUiDecimals,
    generateNonce
} from "@bridgesplit/utils";
import { IdempotentRequest, useAlert, useAsyncResultHandler } from "@bridgesplit/react";
import { useDialog, useTriggerHook } from "@bridgesplit/ui";
import { AppDialog } from "app/utils";

import { AddressInput, REQUIRED_ADDRESS_FIELDS } from "../common";
import { useTransactionSender } from "../transactions";
import { PayoutInput, TransferStats } from "../transfers/types";

export const SPHERE_PROCESS_TIME = "1-2 business days";

export function useRegisterIndividualSphereWallets() {
    const [getUserSphereInfo] = sphereApi.endpoints.sphereUserInfo.useLazyQuery();
    const [getUserWallets] = sphereApi.endpoints.sphereUserWallets.useLazyQuery();

    const [createWallet] = useCreateSphereWalletMutation();

    const { groups } = useGroup();
    const { wallets } = useUserWallets();
    const idempotentKey = usePageLoadIdempotentKey();

    const groupsMetadata = useMemo(() => groups?.map((g) => g.group).filter(filterUndefined), []);

    const groupIdentifierToNames = useMemo(() => {
        if (!groupsMetadata) return undefined;

        return new Map(groupsMetadata.map((group) => [group.groupIdentifier, group.groupName]));
    }, [groupsMetadata]);

    async function register(wallets: CreateWalletInput[]) {
        if (!wallets.length) return Result.errFromMessage(LOADING_ERROR);

        const existingWalletsSet = await getExistingSphereWalletsSet();

        const uniqueWallets = removeDuplicatesByProperty(wallets, "pubkey").filter(
            ({ pubkey }) => !existingWalletsSet.has(pubkey)
        );
        const res = await Promise.all(
            uniqueWallets.map((w) => createWallet({ ...w, idempotentKey: idempotentKey(w.pubkey) }))
        );
        const foundError = res.find((r) => "error" in r);

        if (foundError) {
            return Result.err(foundError);
        }
        return Result.ok();
    }

    async function registerAll() {
        if (!wallets?.length || !groupsMetadata?.length || !groupIdentifierToNames)
            return Result.errFromMessage(LOADING_ERROR);

        try {
            const existingWalletsSet = await getExistingSphereWalletsSet();

            const userWallets = wallets
                .map(
                    ({ wallet, groupIdentifier }): NullableRecord<CreateWalletInput> => ({
                        pubkey: wallet,
                        nickname: groupIdentifierToNames.get(groupIdentifier)
                            ? `${groupIdentifierToNames.get(groupIdentifier)} Self-Custodial`
                            : undefined
                    })
                )
                .filter(filterNullableRecord);

            const escrowWallets = groupsMetadata.map(
                ({ groupName, groupIdentifier }): CreateWalletInput => ({
                    nickname: `${groupName} Escrow`,
                    pubkey: getEscrowAccountForGroup(groupIdentifier, DEFAULT_PUBLIC_KEY)
                })
            );

            const newWallets = [...userWallets, ...escrowWallets].filter(
                ({ pubkey }) => !existingWalletsSet.has(pubkey)
            );

            if (newWallets.length) {
                const customerRes = await register(newWallets);
                if (customerRes.isErr()) {
                    return customerRes;
                }
            }

            return Result.ok();
        } catch (error) {
            return Result.errWithDebug(getReadableErrorMessage("register wallets to Sphere"), error);
        }
    }

    async function getExistingSphereWalletsSet() {
        const sphereInfo = await getUserSphereInfo(undefined, true).unwrap();

        const hasSphereAccount = !!sphereInfo?.sphereCustomerId;
        let existingWallets: SphereUserWallets[] = [];
        if (hasSphereAccount) {
            existingWallets = await getUserWallets(undefined, true).unwrap();
        }

        const existingWalletsSet = new Set(existingWallets.map((w) => w.pubkey));

        return existingWalletsSet;
    }

    return { registerAll, register, isLoading: !wallets };
}

export function useSphereRefresh() {
    const [refetchUser] = sphereApi.endpoints.sphereUserInfo.useLazyQuery();
    const [refetchBusiness] = sphereApi.endpoints.businessKybStatus.useLazyQuery();
    const [refetchBanks] = sphereApi.endpoints.sphereUserBankAccount.useLazyQuery();

    const user = async () => {
        await refetchUser();
    };
    const business = async () => {
        await refetchBusiness();
    };
    const banks = async () => {
        await refetchBanks();
    };

    return async (fetches?: { kyb?: boolean; banks?: boolean }, delay = 3_000) => {
        await sleep(delay);
        const promises: (() => Promise<void>)[] = [user];

        if (fetches?.kyb) {
            promises.push(business);
        }
        if (fetches?.banks) {
            promises.push(banks);
        }

        await Promise.all(promises.map((execute) => execute()));
    };
}

export function useOpenSphereTermsDialog() {
    const [fetchTerms] = sphereApi.endpoints.sphereCustomerToken.useLazyQuery();
    const { open: openDialog } = useDialog();
    const { resultHandler, isLoading } = useAsyncResultHandler();

    const open = () =>
        resultHandler(
            async () => {
                try {
                    const terms = (await fetchTerms(undefined, true).unwrap()).data;
                    if (!terms.url) return Result.errFromMessage(getReadableErrorMessage("find your Sphere account"));
                    openDialog(AppDialog.SphereAcceptTerms, terms);
                    return Result.ok(terms);
                } catch (error) {
                    return Result.errWithDebug(getReadableErrorMessage("fetch credentials from Sphere"), error);
                }
            },
            { alertOnError: true }
        );

    return { open, isLoading };
}

export function useOpenPlaid() {
    const { data } = useSphereCustomerTokenQuery();
    const { close: closeDialog } = useDialog();
    const { resetSphere } = useAbfFetches();

    const { data: sphere } = useUserSphere();
    const { alert } = useAlert();
    const token = data?.data.token ?? "";
    const {
        connectBankAccount,
        isReady,
        error: errorMessage,
        isLoading: sphereLoading
    } = useSpherePlaid({
        token,
        metadata: { identifier: sphere?.identifier },
        onSuccess: () => {
            alert("Bank account connected", "success");
            closeDialog();
            resetSphere();
        }
    });

    const { isLoading: triggerLoading, trigger } = useTriggerHook(connectBankAccount, { skip: !isReady || !token });

    return { open: trigger, isLoading: sphereLoading || triggerLoading, errorMessage };
}

export function formatBank(bank: SphereUserBankAccount | undefined) {
    if (!bank) return "";
    return `${bank.bankName} (${bank.last4})`;
}

const BLOCKED_SPHERE_STATES = ["NY", "FL", "AK", "LA"];
export function validateSphereAddress(addressInput: AddressInput): Result<NewAddress> {
    if (!allKeysDefined(addressInput, REQUIRED_ADDRESS_FIELDS)) return Result.errFromMessage("Incomplete address");
    const address = addressInput as NewAddress;

    if (address.country === "USA" && BLOCKED_SPHERE_STATES.includes(address.state))
        return Result.errFromMessage(
            `Sphere does not accept customers from ${address.state}. Please contact Loopscale for more information`
        );
    return Result.ok(address);
}

export function useOfframpPayout() {
    const [offramp] = useCreateSphereOfframpMutation();

    const payoutSetupAndGetParams = usePayoutSetupAndGetParams();
    const send = useTransactionSender();
    const escrowWithdraw = useEscrowWithdrawTransaction();

    const { activeEscrow, escrowNonce } = useActiveEscrow();
    const { groupIdentifier } = useActiveGroup();

    const { activeWallet } = useActiveWallet();
    return async function submit(input: PayoutInput) {
        if (!activeEscrow || !activeWallet || !escrowNonce || !groupIdentifier)
            return Result.errFromMessage(LOADING_ERROR);
        const payoutParams = await payoutSetupAndGetParams(input);
        if (!payoutParams.isOk()) return payoutParams;
        const res = await offramp(payoutParams.unwrap());

        if ("error" in res) {
            return Result.errWithDebug(getReadableErrorMessage("transfer funds"), res);
        }
        const postInstructions = res.data;
        return await send(escrowWithdraw, [
            {
                receiver: activeWallet.wallet,
                mint: USDC_MINT,
                amount: input.amount ?? 0,
                escrowAccount: activeEscrow,
                extraInstructions: { postInstructions },
                escrowNonce,
                organizationIdentifier: groupIdentifier
            }
        ]);
    };
}

export function useOnrampPayout() {
    const [onramp] = useCreateSphereOnrampMutation();

    const payoutSetupAndGetParams = usePayoutSetupAndGetParams();

    return async function submit(input: PayoutInput) {
        const payoutParams = await payoutSetupAndGetParams(input);
        if (!payoutParams.isOk()) return payoutParams;
        const res = await onramp(payoutParams.unwrap());
        if ("error" in res) {
            return Result.errWithDebug(getReadableErrorMessage("transfer funds"), res);
        }

        return Result.ok();
    };
}

function usePayoutSetupAndGetParams() {
    const { register } = useRegisterIndividualSphereWallets();
    const { activeWallet } = useActiveWallet();
    const { activeGroup } = useGroup();
    const { activeEscrow } = useActiveEscrow();

    return async function ({ amount, bank }: PayoutInput): Promise<Result<IdempotentRequest<CreatePayoutInput>>> {
        if (!amount) return Result.errFromMessage("No amount selected");
        if (!bank) return Result.errFromMessage("Invalid bank");
        if (!activeWallet || !activeEscrow) return Result.errFromMessage(WALLET_ERROR);

        const registerWallets = await register([
            { pubkey: activeEscrow, nickname: `${activeGroup?.groupName} Loopscale Account` },
            { pubkey: activeWallet.wallet, nickname: "Wallet" }
        ]);
        if (!registerWallets.isOk()) return Result.err(registerWallets);
        const body: IdempotentRequest<CreatePayoutInput> = {
            idempotentKey: generateNonce(),
            bankAccountId: bank.sphereBankAccountId,
            walletAddress: activeWallet.wallet,
            uiAmount: amount.toString()
        };

        return Result.ok(body);
    };
}

const SPHERE_FEE_PERCENT = bpsToUiDecimals(SPHERE_CONVERSION_FEE_BPS);
export function calcSphereOfframpFees(inputAmount: number | undefined = 0): TransferStats {
    const fee = roundDownToDecimals(inputAmount * SPHERE_FEE_PERCENT, 2);
    const sphereInputAmount = inputAmount;

    // from bank
    const withdrawAmount = inputAmount;

    // to loopscale
    const receiveAmount = inputAmount - fee;

    return { side: "withdraw", fee, sphereInputAmount, withdrawAmount, receiveAmount, inputAmount };
}

export function calcSphereOnrampFees(inputAmount: number | undefined = 0): TransferStats {
    const fee = roundDownToDecimals(inputAmount * SPHERE_FEE_PERCENT, 2);
    const sphereInputAmount = inputAmount + fee;

    // from bank
    const withdrawAmount = inputAmount + fee;

    // to loopscale
    const receiveAmount = inputAmount;

    return { side: "deposit", fee, sphereInputAmount, withdrawAmount, receiveAmount, inputAmount };
}
