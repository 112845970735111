import { encode as utf8Encode, decode as utf8Decode } from "utf8";

function base64encode(data: Buffer): string {
    return data.toString("base64");
}

function base64Decode(data: string): Buffer {
    return Buffer.from(data, "base64");
}

/// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export function parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export const base64 = { encode: base64encode, decode: base64Decode };

export const utf = { encode: utf8Encode, decode: utf8Decode };
