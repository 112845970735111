import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { ABF_API, NullableRecord, filterNullableRecord, parseJwt } from "@bridgesplit/utils";
import { AppCookie, clearBearerToken, getBearerToken, setBearerToken } from "@bridgesplit/react";
import { USER_WALLET_HEADER } from "@bridgesplit/abf-sdk";
import axios, { AxiosError } from "axios";
import { getCurrentScope } from "@sentry/react";

import {
    AbfUser,
    AbfUserGroup,
    AbfUserWalletVerification,
    AbfUserWithPermissionsAndWallets,
    MultiChainWallets,
    RoleView,
    UserGroupViewReturn,
    AbfGroupWithRoles,
    PrimeRoleStatusParams,
    PrimeApplicationData,
    AbfGroupType
} from "../types";
import {
    ABF_GROUP_COOKIE_PREFIX,
    ABF_GROUP_HEADER,
    APPLY_FOR_PRIME_ROUTE,
    AUTH0_COOKIES_PREFIX,
    CHECK_USER_MFI_ACCOUNT_ROUTE,
    CREATE_USER_MPC_WALLET_ROUTE,
    EMAIL_EXISTS_ROUTE,
    GET_MULTICHAIN_WALLETS_ROUTE,
    GET_USER_METADATA_BY_EMAILS_ROUTE,
    SELF_USER_INFO_ROUTE,
    USER_GROUP_VIEW_ROUTE,
    USER_ID_COOKIE,
    USER_PRIME_STATUS_ROUTE,
    USER_WALLET_COOKIE,
    VERIFY_USER_WALLET_SETUP_ROUTE
} from "../constants";
import { abfBaseQuery } from "./util";
import { setAuthGroupIdentifier } from "./authSlice";

const USER_ME_TAG = "UserMe";
const USER_VIEW_TAG = "UserGroupViews";
const USER_MARGINFI_TAG = "UserMarginFi";

export const abfUserApi = createApi({
    reducerPath: "abfUserApi",
    tagTypes: [USER_ME_TAG, USER_VIEW_TAG, USER_MARGINFI_TAG],
    baseQuery: abfBaseQuery,
    keepUnusedDataFor: 0, // force there to only ever be a userMe call in the cache
    endpoints: (builder) => ({
        userMe: builder.query<AbfUserWithPermissionsAndWallets, { walletPubkey?: string; idToken?: string }>({
            providesTags: [USER_ME_TAG],
            queryFn: async ({ walletPubkey, idToken }, { dispatch }) => {
                try {
                    // use raw query to prevent cached headers
                    const res = await axios.get(SELF_USER_INFO_ROUTE, {
                        baseURL: ABF_API,
                        headers: {
                            [USER_WALLET_HEADER]: walletPubkey,
                            Authorization: idToken ? `Bearer ${idToken}` : undefined
                        }
                    });

                    const rawData = res.data as Omit<AbfUserWithPermissionsAndWallets, "groups"> & {
                        groups: Record<string, AbfUserGroup>;
                    };

                    const userId = rawData.user.identifier;
                    const jwt = idToken ? (parseJwt(idToken) as { email: string }) : undefined;

                    getCurrentScope().setUser({ id: userId, wallet: walletPubkey, email: jwt?.email });
                    AppCookie.set(USER_ID_COOKIE, userId);

                    let groups = Object.entries(rawData.permissions)
                        .map(([groupIdentifier, roles]): NullableRecord<AbfGroupWithRoles> => {
                            const group = rawData.groups[groupIdentifier];
                            return { group, roles };
                        })
                        .filter(filterNullableRecord);

                    let wallets = rawData.wallets;

                    if (walletPubkey) {
                        wallets = wallets.filter((w) => w.wallet === walletPubkey);
                        const validGroups = new Set(wallets.map((w) => w.groupIdentifier));
                        groups = groups.filter(({ group }) =>
                            group
                                ? validGroups.has(group?.groupIdentifier) && group.groupType === AbfGroupType.Individual
                                : undefined
                        );
                        AppCookie.set(USER_WALLET_COOKIE, walletPubkey);
                        clearBearerToken(AUTH0_COOKIES_PREFIX);
                    }

                    const cachedGroup = getBearerToken(ABF_GROUP_COOKIE_PREFIX);
                    const isGroupInvalid = !cachedGroup || !groups.some((g) => g.group.groupIdentifier === cachedGroup);

                    if (isGroupInvalid) {
                        const defaultGroupId = groups.find((g) => g.group?.groupType === AbfGroupType.Individual)?.group
                            ?.groupIdentifier;
                        if (defaultGroupId) {
                            setBearerToken(ABF_GROUP_COOKIE_PREFIX, defaultGroupId);
                            dispatch(setAuthGroupIdentifier(defaultGroupId));
                        } else {
                            clearBearerToken(ABF_GROUP_COOKIE_PREFIX);
                            dispatch(setAuthGroupIdentifier(undefined));
                        }
                    }

                    const data = { ...rawData, groups, wallets };
                    return { data };
                } catch (axiosError) {
                    const err = axiosError as AxiosError;
                    const error: FetchBaseQueryError = {
                        status: "FETCH_ERROR",
                        error: typeof err.response?.data === "string" ? err.response?.data : err.message
                    };
                    return { error };
                }
            }
        }),
        usersByEmails: builder.query<Record<string, AbfUser>, string[]>({
            query(emails) {
                return {
                    url: GET_USER_METADATA_BY_EMAILS_ROUTE,
                    method: "POST",
                    body: emails
                };
            }
        }),
        emailExists: builder.query<boolean, string>({
            query(email) {
                return {
                    url: EMAIL_EXISTS_ROUTE,
                    method: "POST",
                    body: { email }
                };
            }
        }),

        verifyUserWalletSetup: builder.query<AbfUserWalletVerification, string>({
            providesTags: [USER_ME_TAG],
            query(wallet: string) {
                return {
                    url: `${VERIFY_USER_WALLET_SETUP_ROUTE}/${wallet}`,
                    method: "GET"
                };
            }
        }),
        userMultichainWallets: builder.query<MultiChainWallets[], void>({
            providesTags: [USER_ME_TAG],
            query() {
                return {
                    url: GET_MULTICHAIN_WALLETS_ROUTE,
                    body: JSON.stringify(null),
                    headers: { "Content-Type": "application/json" },
                    method: "POST"
                };
            }
        }),

        userGroupViews: builder.query<UserGroupViewReturn[], void>({
            providesTags: [USER_VIEW_TAG],
            query(body) {
                return {
                    url: USER_GROUP_VIEW_ROUTE,
                    method: "GET",
                    body
                };
            }
        }),
        userPrimeStats: builder.query<PrimeApplicationData, void>({
            query() {
                return {
                    url: USER_PRIME_STATUS_ROUTE,
                    method: "GET"
                };
            }
        }),
        userHasMarginFiAccount: builder.query<boolean, void>({
            providesTags: [USER_MARGINFI_TAG],
            query() {
                return {
                    url: CHECK_USER_MFI_ACCOUNT_ROUTE,
                    method: "GET"
                };
            }
        }),
        putUserGroupView: builder.mutation<void, { view: RoleView; groupIdentifier: string }>({
            invalidatesTags: [USER_VIEW_TAG],
            query({ view, groupIdentifier }) {
                return {
                    headers: { "Content-Type": "application/json", [ABF_GROUP_HEADER]: groupIdentifier },
                    url: USER_GROUP_VIEW_ROUTE,
                    method: "PUT",
                    body: JSON.stringify(view)
                };
            }
        }),
        createSolanaMpcWallet: builder.mutation<void, { isPrimary: boolean }>({
            query({ isPrimary }) {
                return {
                    headers: { "Content-Type": "application/json" },
                    url: CREATE_USER_MPC_WALLET_ROUTE,
                    method: "POST",
                    body: JSON.stringify(isPrimary)
                };
            }
        }),
        applyForPrime: builder.mutation<void, PrimeRoleStatusParams & { bearer: string }>({
            invalidatesTags: [USER_VIEW_TAG],
            query({ bearer, ...body }) {
                return {
                    url: APPLY_FOR_PRIME_ROUTE,
                    headers: { Authorization: `Bearer ${bearer}` },
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {
    useUserMeQuery,
    useUsersByEmailsQuery,
    useEmailExistsQuery,
    useVerifyUserWalletSetupQuery,
    useUserMultichainWalletsQuery,
    useUserGroupViewsQuery,
    useUserPrimeStatsQuery,
    useUserHasMarginFiAccountQuery,
    usePutUserGroupViewMutation,
    useCreateSolanaMpcWalletMutation,
    useApplyForPrimeMutation
} = abfUserApi;

export const useAbfUserApi = () => {
    const api = abfUserApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchUserMe] = api.userMe.useLazyQuery();
    const [fetchUserMultichainWallets] = api.userMultichainWallets.useLazyQuery();

    return {
        resetMeApi: () => dispatch(abfUserApi.util.invalidateTags([USER_ME_TAG, USER_VIEW_TAG, USER_MARGINFI_TAG])),
        resetMarginFiApi: () => dispatch(abfUserApi.util.invalidateTags([USER_MARGINFI_TAG])),
        fetchUserMe,
        fetchUserMultichainWallets
    };
};
