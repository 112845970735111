import { useMemo } from "react";

import { MediaQuery, MediaStats, StatProps } from "@bridgesplit/ui";
import { BsMetaUtil, StrategyExpanded } from "@bridgesplit/abf-react";
import { bsMath, formatPercent, formatPercentArray } from "@bridgesplit/utils";
import { COPY } from "app/constants";
import { useMediaQuery } from "@mui/material";

import { TokenAmountWithUsd } from "../../common";
import { StrategyRateAndRewardsBreakdown } from "../../points";

const variant = "body1" as const;
export default function StrategyCardStats({
    strategyExpanded,
    query,
    mode
}: {
    strategyExpanded: StrategyExpanded | undefined;
    query: MediaQuery;
    mode: "loans" | "terms";
}) {
    const isLoading = !strategyExpanded;
    const isMobile = useMediaQuery(query.below);

    const { utilization, totalDeployed } = useMemo(() => {
        if (!strategyExpanded) return { apyWeighted: 0, utilization: 0, totalDeployed: 0 };
        const { loanStats } = strategyExpanded;

        const totalDeployed = loanStats.principalDeployed + loanStats.interestAccrued;

        const utilization = totalDeployed / (strategyExpanded.totalBalance + totalDeployed);

        return { utilization, totalDeployed };
    }, [strategyExpanded]);

    const flatApy = useMemo(
        () =>
            strategyExpanded?.offerWithTermsAndFilter
                .map(({ offerTerms }) => offerTerms.map((t) => t.offerAssetTerms.terms.apy))
                .flat(),
        [strategyExpanded?.offerWithTermsAndFilter]
    );
    const interestEarned = bsMath.add(
        strategyExpanded?.loanStats?.interestAccrued,
        strategyExpanded?.loanStats.totalInterestEarned
    );

    const stats: StatProps[] = [
        {
            value: strategyExpanded ? (
                <StrategyRateAndRewardsBreakdown strategyExpanded={strategyExpanded} isStat />
            ) : undefined,
            caption: "APY",
            hide: !isMobile && mode !== "loans"
        },

        {
            value: formatPercentArray(flatApy),
            caption: COPY.STRATEGY_APY_TERM,
            hide: mode !== "terms"
        },
        {
            value: formatPercent(utilization),
            caption: "Utilization",
            tooltip: `${BsMetaUtil.formatAmount(strategyExpanded?.principalMetadata, totalDeployed)} deployed in loans`
        },

        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(
                        strategyExpanded?.loanStats.principalDeployed,
                        strategyExpanded?.principalUsdPrice
                    )}
                    amount={strategyExpanded?.loanStats.principalDeployed}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Deployed",
            tooltip: "Funds that are unused in loans and available with withdraw",
            hide: mode !== "loans"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(strategyExpanded?.totalBalance, strategyExpanded?.principalUsdPrice)}
                    amount={strategyExpanded?.totalBalance}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Available",
            tooltip: "Funds that are unused in loans and available with withdraw"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amount={interestEarned}
                    amountUsd={bsMath.mul(interestEarned, strategyExpanded?.principalUsdPrice)}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: "Interest earned"
        },
        {
            value: (
                <TokenAmountWithUsd
                    amountUsd={bsMath.mul(
                        strategyExpanded?.loanStats.totalPrincipalOriginated,
                        strategyExpanded?.principalUsdPrice
                    )}
                    amount={strategyExpanded?.loanStats.totalPrincipalOriginated}
                    token={strategyExpanded?.principalMetadata}
                    condensedOnMobile
                    display="symbol"
                />
            ),
            caption: COPY.ORIGINATED_ALL_TIME_TERM,
            hide: mode !== "loans"
        }
    ];

    return <MediaStats variant={variant} loading={isLoading} stats={stats} />;
}
