import { SphereBankAccountStatus, SphereUserBankAccount } from "@bridgesplit/abf-sdk";
import { createRows, TableColumn, Table, Text, Button, Icon, TooltipText } from "@bridgesplit/ui";
import { useUserSphere } from "@bridgesplit/abf-react";
import { AppDialog, useAppDialog } from "app/utils";

export default function BankAccountsTable({ bankAccounts }: { bankAccounts: SphereUserBankAccount[] | undefined }) {
    const { open } = useAppDialog();
    return (
        <Table
            searchRowElement={
                <Button
                    disabled={!bankAccounts}
                    width="max-content"
                    variant="outlined"
                    onClick={() => open(AppDialog.AddBank, undefined)}
                >
                    <Icon type="add" /> {AppDialog.AddBank}
                </Button>
            }
            paginationProps={{
                pageSize: 5,
                getSearchFromRow: ({ data: { bankName, nickname } }) => [bankName, nickname],
                type: "managed",
                defaultSortKey: "account"
            }}
            emptyPlaceholder="No bank accounts added"
            loading={!bankAccounts}
            rows={createRows(bankAccounts, (b) => b.id)}
            columns={columns}
        />
    );
}

const columns: TableColumn<SphereUserBankAccount>[] = [
    {
        dataIndex: "bank",
        title: "Bank",
        width: "20%",
        render: ({ data }) => <> {data.bankName} </>,
        sortBy: ({ data }) => data.bankName
    },
    {
        dataIndex: "account",
        title: "Account",
        width: "20%",
        render: ({ data }) => <Text> ...{data.last4} </Text>,
        sortBy: ({ data }) => data.last4
    },

    {
        dataIndex: "status",
        title: "Status",
        width: "20%",
        render: ({ data }) => <Status data={data} />,
        sortBy: ({ data }) => data.sphereBankAccountStatus
    }
];

function Status({ data }: { data: SphereUserBankAccount }) {
    const { isVerified } = useUserSphere();

    if (!isVerified) {
        return (
            <TooltipText helpText="Your personal information is still being verified" color="disabled">
                Pending
            </TooltipText>
        );
    }

    switch (data.sphereBankAccountStatus) {
        case SphereBankAccountStatus.Accepted:
            return <Text color="success"> Active </Text>;
        case SphereBankAccountStatus.Rejected:
            return (
                <TooltipText
                    helpText="Sphere was unable to verify your bank. Please contact Loopscale for additional information"
                    color="error"
                >
                    Rejected
                </TooltipText>
            );

        default:
            return <Text color="disabled">Pending verification</Text>;
    }
}
