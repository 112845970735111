import { SyndicatedOrder } from "@bridgesplit/abf-sdk";
import { Row, IconButton, Tooltip, Text, TableColumn, Image } from "@bridgesplit/ui";
import { BsMetaUtil, SyndicatedContributorExpanded } from "@bridgesplit/abf-react";
import { formatPercent } from "@bridgesplit/utils";
import { SYNDICATED_OFFER_SLUG } from "app/constants";

export function getSyndicatedSlug(syndicated: SyndicatedOrder) {
    return `${SYNDICATED_OFFER_SLUG}/${syndicated.address}`;
}

export function SyndicateInvite({ email, onClick }: { email: string; onClick: () => void }) {
    return (
        <Row spaceBetween>
            <Text>{email}</Text>

            <Tooltip title="Cancel invite">
                <IconButton sx={{ p: 0 }} onClick={onClick} color="error" icon="reject" />
            </Tooltip>
        </Row>
    );
}

export const syndicateContributorsColumns: TableColumn<SyndicatedContributorExpanded>[] = [
    {
        dataIndex: "lender",
        title: "Lender",
        width: "30%",
        render: ({ data }) => (
            <Row spacing={1}>
                <Image variant="circle" size="35px" src={data.groupImage} />
                <Text> {data.groupName} </Text>
            </Row>
        ),
        sortBy: ({ data }) => data.groupName
    },
    {
        dataIndex: "allocation",
        title: "Allocation",
        width: "20%",
        align: "right",
        render: ({ data }) => <>{BsMetaUtil.formatAmount(data.principalMetadata, data.principalDeposited)}</>,
        sortBy: ({ data }) => data.groupName
    },
    {
        dataIndex: "share",
        title: "Share",
        width: "20%",
        align: "right",
        render: ({ data }) => <>{formatPercent(data.principalDepositShare)}</>,
        sortBy: ({ data }) => data.principalDepositShare
    }
];
