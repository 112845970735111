import { ReactNode } from "react";

import { useMediaQuery } from "@mui/material";
import { useAppPalette, Row, CARD_WIDTH, Column, OutlinedCard, ToggleButtons } from "@bridgesplit/ui";
import { RoleView, useAccessLevel } from "@bridgesplit/abf-react";

import { MARKET_DETAIL_BREAKPOINT } from "../util";
import { MarketStats, RequestPrompt, useMarketContext } from "../common";
import MarketDetailMobile from "./MarketDetailMobile";
import { DESKTOP_PADDING } from "../../navbar";
import { Page } from "../../wrappers";
import TokenHeader from "./TokenHeader";
import MarketLayoutAdvanced from "./MarketLayoutAdvanced";
import MarketPositions from "./MarketPositions";

export default function MarketCardWrapper({ card }: { card: ReactNode }) {
    const isMobile = useMediaQuery(MARKET_DETAIL_BREAKPOINT.below);

    if (isMobile) {
        return <MarketDetailMobile card={card} />;
    }

    return <MarketDesktop card={card} />;
}

function MarketDesktop({ card }: { card: ReactNode }) {
    const { border } = useAppPalette();
    const { isBeta } = useAccessLevel();

    return (
        <Page sx={{ flex: 1 }} maxWidthSx={{ flex: 1 }} maxWidth="100vw" permission allowNullGroup>
            <Row
                sx={{
                    flexGrow: 1,
                    m: -DESKTOP_PADDING,
                    display: "grid",
                    alignItems: "stretch",
                    gridTemplateColumns: `1fr ${CARD_WIDTH}`
                }}
            >
                <Column>
                    <Row sx={{ borderBottom: border, px: DESKTOP_PADDING, py: 1.5 }} spacing={3}>
                        <TokenHeader />

                        <MarketStats />
                    </Row>
                    <MarketLayoutAdvanced />
                    <Column sx={{ p: DESKTOP_PADDING }}>
                        <MarketPositions />
                    </Column>
                </Column>

                <Column
                    sx={{
                        borderLeft: border,
                        p: 3
                    }}
                    spacing={3}
                >
                    <OutlinedCard spacing={3} hideCard>
                        <RoleSwitch />
                        {card}
                    </OutlinedCard>

                    {isBeta && <RequestPrompt />}
                </Column>
            </Row>
        </Page>
    );
}

function RoleSwitch() {
    const { view, setView } = useMarketContext();
    if (!setView) return null;

    return (
        <ToggleButtons
            value={view}
            setValue={setView}
            options={[
                { value: RoleView.Borrow, label: "Borrow" },
                { value: RoleView.Lend, label: "Lend" }
            ]}
        />
    );
}
