import { OptimisticEvmUploadArgs, getOptimisticEvmUploadTransaction } from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";
import { Result } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails } from "../types";
import { useAbfGenerateTransaction } from "./common";

export function useOptimisticEvmBsMetadataTransaction(): AbfTransactionDetails<OptimisticEvmUploadArgs[]> {
    const generate = useAbfGenerateTransaction();
    const { resetBsMetadataApi } = useAbfFetches();

    async function getTransactionsWithParams(uploadArgs: OptimisticEvmUploadArgs[]): AbfGeneratorResult {
        try {
            const upload = generate({
                generateFunction: getOptimisticEvmUploadTransaction,
                identifier: "Create Metadata",
                params: uploadArgs
            });

            const transactions = await combineTransactionPromises([upload], { order: "parallel" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetBsMetadataApi();
        }
    };
    return { getTransactionsWithParams, sendOptions, description: "Upload Metadata" };
}
