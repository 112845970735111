import { AppCookie } from "./cookie";

const BEARER_TOKEN_KEY = "bearer_token";

function getBearerTokenKey(bearerTokenKeyPrefix: string) {
    return bearerTokenKeyPrefix + "_" + BEARER_TOKEN_KEY;
}

export function setBearerToken(bearerTokenKeyPrefix: string, value: string, expiresAt?: Date) {
    AppCookie.set(getBearerTokenKey(bearerTokenKeyPrefix), value, { expires: expiresAt });
}

export function getBearerToken(bearerTokenKeyPrefix: string): string | undefined {
    return AppCookie.get(getBearerTokenKey(bearerTokenKeyPrefix));
}

export function clearBearerToken(bearerTokenKeyPrefix: string) {
    return AppCookie.remove(getBearerTokenKey(bearerTokenKeyPrefix));
}
