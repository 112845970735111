import { useMemo } from "react";

import {
    usePortfolioStatsQuery,
    LendStats,
    PortfolioStats,
    BorrowStats,
    useExternalYieldInterest,
    useLenderStrategies,
    StrategyExpanded,
    useActiveEscrow,
    useLoopPositionStatsQuery,
    LoopPositionStats,
    useAccessLevel,
    useActiveGroup
} from "@bridgesplit/abf-react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { bpsToUiDecimals, percentUiToDecimals } from "@bridgesplit/utils";

import { BorrowStatsExpanded, LendStatsExpanded, OverviewStats, SummarizedStats } from "./type";

export function usePortfolioStats() {
    const { activeEscrow } = useActiveEscrow();
    const { isBeta } = useAccessLevel();
    const { groupIdentifier } = useActiveGroup();

    const { data: portfolioStats } = usePortfolioStatsQuery(
        activeEscrow && groupIdentifier && isBeta
            ? { escrows: [activeEscrow], groupIdentifier: groupIdentifier }
            : skipToken
    );

    const { data: loopPositionStats } = useLoopPositionStatsQuery(groupIdentifier ?? skipToken, {
        skip: !groupIdentifier
    });
    const { data: strategies } = useLenderStrategies();

    const { externalYieldInterestUsd } = useExternalYieldInterest(strategies);

    const stats = useMemo((): SummarizedStats | undefined => {
        if (!portfolioStats || externalYieldInterestUsd === undefined || !strategies || !loopPositionStats)
            return undefined;

        const lendStats = calculateLendStats({ externalYieldInterestUsd, strategies });
        const borrowStats = calculateBorrowStats(portfolioStats);
        const loopStats = calculateLoopsStats(loopPositionStats);
        const overviewStats = summarizeStats({ lendStats, borrowStats, loopStats });
        return { lendStats, borrowStats, loopStats, overviewStats };
    }, [portfolioStats, externalYieldInterestUsd, strategies, loopPositionStats]);

    return stats;
}
function calculateLendStats({
    externalYieldInterestUsd,
    strategies
}: {
    strategies: StrategyExpanded[];
    externalYieldInterestUsd: number;
}): LendStatsExpanded {
    const strategyBalanceUsd = strategies.reduce(
        (acc, strategy) => acc + strategy.totalBalance * (strategy.principalUsdPrice ?? 0),
        0
    );
    let summary: LendStats = {
        principalDeployedUsd: 0,
        wAvgApy: 0,
        interestAccruedUsd: 0,
        interestEarnedAllTimeUsd: 0
    };
    let totalWeight = 0;
    for (const strategy of strategies) {
        const usdPrice = strategy.principalUsdPrice ?? 0;

        // wAvgApy needs to include external yield + deployed principal * deployed apy
        const deployedUsd = strategy.loanStats.principalDeployed * usdPrice;
        let wAvgApy = strategy.loanStats.wAvgApy * deployedUsd;
        totalWeight += deployedUsd;

        // add weighted external yield
        if (strategy.externalYieldInfo) {
            const yieldDepositsUsd = strategy.externalYieldInfo.balance * usdPrice;
            totalWeight += yieldDepositsUsd;
            wAvgApy += strategy.externalYieldInfo.apy * yieldDepositsUsd;
        } else {
            // if no external yield, add the total balance as idle
            totalWeight += strategy.totalBalance * usdPrice;
        }

        summary = {
            principalDeployedUsd: summary.principalDeployedUsd + strategy.loanStats.principalDeployed * usdPrice,
            interestAccruedUsd: summary.interestAccruedUsd + strategy.loanStats.interestAccrued * usdPrice,
            interestEarnedAllTimeUsd:
                summary.interestEarnedAllTimeUsd + strategy.loanStats.totalInterestEarned * usdPrice,
            wAvgApy: summary.wAvgApy + wAvgApy
        };
    }

    summary.wAvgApy = summary.wAvgApy / (totalWeight || 1);
    summary.interestEarnedAllTimeUsd += externalYieldInterestUsd;
    const totalSupplyUsd = summary.principalDeployedUsd + strategyBalanceUsd + summary.interestAccruedUsd;

    return { ...summary, strategyBalanceUsd, totalSupplyUsd };
}

function calculateBorrowStats(portfolioStats: PortfolioStats): BorrowStatsExpanded {
    let summary: BorrowStats = {
        principalBorrowedUsd: 0,
        interestAccruedUsd: 0,
        wAvgApy: 0,
        lockboxUsd: 0
    };
    let totalWeight = 0;
    for (const stats of Object.values(portfolioStats.borrowStats)) {
        const weight = stats.principalBorrowedUsd;
        totalWeight += weight;

        summary = {
            principalBorrowedUsd: summary.principalBorrowedUsd + stats.principalBorrowedUsd,
            interestAccruedUsd: summary.interestAccruedUsd + stats.interestAccruedUsd,
            wAvgApy: summary.wAvgApy + bpsToUiDecimals(stats.wAvgApy) * weight,
            lockboxUsd: summary.lockboxUsd + stats.lockboxUsd
        };
    }
    summary.wAvgApy = summary.wAvgApy / (totalWeight || 1);

    const totalBorrowedUsd = summary.principalBorrowedUsd + summary.interestAccruedUsd;
    const netPositionUsd = Math.max(summary.lockboxUsd - totalBorrowedUsd, 0);

    return { ...summary, netPositionUsd, totalBorrowedUsd };
}

function summarizeStats({ lendStats, borrowStats, loopStats }: Omit<SummarizedStats, "overviewStats">): OverviewStats {
    const borrowNetValue = borrowStats.lockboxUsd - borrowStats.principalBorrowedUsd - borrowStats.interestAccruedUsd;
    const lendNetValue = lendStats.strategyBalanceUsd + lendStats.principalDeployedUsd + lendStats.interestAccruedUsd;
    const loopNetValue = loopStats.netPositionValue;

    const netValue = lendNetValue + borrowNetValue + loopNetValue;

    const totalInterestEarned = lendStats.interestEarnedAllTimeUsd + lendStats.interestAccruedUsd;
    return {
        netValue,
        totalInterestEarned
    };
}

function calculateLoopsStats(stats: LoopPositionStats): LoopPositionStats {
    return {
        ...stats,
        wAvgApy: percentUiToDecimals(stats.wAvgApy)
    };
}
