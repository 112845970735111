import { createApi } from "@reduxjs/toolkit/query/react";
import { ChainId } from "@bridgesplit/abf-sdk";
import { ParsedWei, WeiBigNumberJson, parseGWeiHex } from "@bridgesplit/utils";

import { EVM_GAS_ESTIMATION_ROUTE } from "../constants";
import { abfSerializeQueryArgs, tygrisBaseQuery } from "./util";
import { EvmGasQuote, EvmTransactionType } from "../types";

const GAS_ESTIMATION = "TygrisGas";

export const tygrisApi = createApi({
    reducerPath: "tygrisApi",
    tagTypes: [GAS_ESTIMATION],
    baseQuery: tygrisBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        estimateEvmGas: builder.query<EvmGasQuote, ChainId>({
            providesTags: [GAS_ESTIMATION],
            query(chainId) {
                return {
                    url: EVM_GAS_ESTIMATION_ROUTE,
                    method: "POST",
                    body: { chainId }
                };
            },
            transformResponse(raw: {
                maxFeePerGas: WeiBigNumberJson;
                evmTransactionToGas: Record<string, WeiBigNumberJson>;
            }) {
                return {
                    maxFeePerGas: parseGWeiHex(raw.maxFeePerGas),
                    evmTransactionToGas: Object.fromEntries(
                        Object.entries(raw.evmTransactionToGas).map(([type, val]) => [
                            parseInt(type),
                            parseGWeiHex(val)
                        ])
                    ) as Record<EvmTransactionType, ParsedWei>
                };
            }
        })
    })
});

export const { useEstimateEvmGasQuery } = tygrisApi;

export function useTygrisApi() {
    return { resetEvmGas: () => tygrisApi.util.invalidateTags([GAS_ESTIMATION]) };
}
