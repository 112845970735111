import { MarketExpanded, useCollateralStatsQuery } from "@bridgesplit/abf-react";
import { StatProps, StatRow } from "@bridgesplit/ui";
import { bsMath, formatUsd } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useMarketSummaryStats } from "./util";

export default function EarnStats({ markets }: { markets: MarketExpanded[] | undefined }) {
    const { data } = useCollateralStatsQuery();
    const marketStats = useMarketSummaryStats(markets);

    const stats: StatProps[] = [
        { value: formatUsd(marketStats?.principalOriginated), caption: COPY.ORIGINATED_ALL_TIME_TERM },
        {
            value: formatUsd(bsMath.add(data?.depositedCollateral, marketStats?.availableDeposits)),
            caption: "Total value locked"
        }
    ];

    return <StatRow loading={!data || !marketStats} stats={stats} />;
}
