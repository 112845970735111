import { ExternalYieldSource } from "./external-yield";
import { AbfOrder } from "./order";

export interface AbfEscrowInfo {
    escrowedAsset: AbfEscrowedAsset;
    orders: AbfOrder[];
    stakeAccounts?: SplitStakeAccount[];
}

export interface AbfEscrowedAsset {
    id: number;
    mint: string;
    escrowAccountAddress: string;
    escrower: string;
    lastInteractedTime: number;
    amount: number;
}

export interface AbfEscrowAccount {
    id: number;
    organizationIdentifier: string;
    nonce: string;
    txn: string;
    address: string;
    originationCap: number;
    cumulativeAmountOriginated: number;
    externalYieldSource: ExternalYieldSource;
}

export interface AbfEscrowedAssetsHistory {
    id: number;
    escrowAccountAddress: string;
    mint: string;
    escrower: string;
    amount: number;
    txn: string;
    txnBlocktime: number;
    side: EscrowSide | null;
}
export interface AbfEscrowedEvent {
    id: number;
    signer: string;
    address: string;
    amount: number;
    mint: string;
    signature: string;
    timestamp: number;
    action: EscrowSide | undefined;
}

export interface SplitStakeAccount {
    id: number;
    amount: number;
    stakeAccount: string;
    owner: string;
    rentExemptReserve: number;
    delegationActivationEpoch: number;
    authorizedStaker: string;
    authorizedWithdrawer: string;
    lockupPubkey: string;
    delegationVoterPubkey: string;
}

export interface StakeAccountWithAmount {
    stakeAccount: string;
    amount: number; // lamports
    stakeAccountAuthority: string;
    stakeAccountValidator: string;
}

export interface SanctumRoute {
    stakePool: { outputToken: string; label: string };
    inAmount: string;
    outAmount: string;
    additionalRentLamports: string;
}

export enum EscrowSide {
    Deposit,
    Withdraw
}
