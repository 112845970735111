import { useState } from "react";

import { SortOrder } from "@bridgesplit/utils";

import {
    ApiPaginationProps,
    ManagedPaginationProps,
    PaginationController,
    PaginationProps,
    TableColumn,
    TableRow
} from "./types";

export function splitPaginationProps<T>(paginationProps: PaginationProps) {
    const managedPaginationProps =
        paginationProps?.type === "managed" ? (paginationProps as ManagedPaginationProps<T>) : undefined;
    const apiPaginationProps = paginationProps?.type === "api" ? (paginationProps as ApiPaginationProps) : undefined;

    return { apiPaginationProps, managedPaginationProps };
}

export function useTableController<T>(paginationProps: PaginationProps): PaginationController {
    const firstPage = paginationProps.firstPage ?? 0;
    const [order, setOrder] = useState<SortOrder>(paginationProps.defaultOrder ?? SortOrder.Desc);
    const [orderBy, setOrderBy] = useState<string>(paginationProps.defaultSortKey ?? "");
    const [page, setPage] = useState<number>(firstPage);
    const [search, setSearch] = useState<string>("");
    const { apiPaginationProps, managedPaginationProps } = splitPaginationProps<T>(paginationProps);

    const searchEnabled =
        paginationProps.type === "managed"
            ? !!managedPaginationProps?.getSearchFromRow
            : apiPaginationProps?.searchEnabled;

    const resetPage = () => setPage(firstPage);

    return {
        order,
        setOrder,
        orderBy,
        setOrderBy,
        page,
        setPage,
        search: searchEnabled ? search : undefined,
        setSearch: searchEnabled ? setSearch : undefined,
        resetPage
    };
}

export function sortRows<T>(props: {
    rows: TableRow<T>[];
    columns: TableColumn<T>[];
    orderBy: string;
    order: SortOrder;
}) {
    const sortByFunction = props.columns.find((c) => c.dataIndex === props.orderBy)?.sortBy;

    if (!sortByFunction || !props.rows.length) {
        return props.rows;
    }

    return [...props.rows].sort((a, b) => {
        const sortableA = sortByFunction(a);
        const sortableB = sortByFunction(b);

        if (typeof sortableA === "number" && typeof sortableB === "number") {
            return props.order === SortOrder.Desc ? sortableB - sortableA : sortableA - sortableB;
        }
        if (props.order === SortOrder.Desc) {
            return sortableB.toString().localeCompare(sortableA.toLocaleString());
        }

        return sortableA.toString().localeCompare(sortableB.toLocaleString());
    });
}
