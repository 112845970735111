import { DialogWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";

import WithdrawFromWallet from "./withdraw/WithdrawFromWallet";
import { DialogHeaderWithBack } from "../common";

export default function WithdrawFromWalletDialog() {
    const { getData } = useAppDialog();

    const data = getData<AppDialog.WithdrawFromWallet>();

    return (
        <DialogWrapper>
            <DialogHeaderWithBack back={data?.back} header="Send" />
            <WithdrawFromWallet mints={data?.mints} />
        </DialogWrapper>
    );
}
