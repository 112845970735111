import { MayString, abbreviateString, colorToAlpha, formatDate } from "@bridgesplit/utils";

import { Button, ConditionalLink, IconButton } from "../buttons";
import { Column, Row } from "../containers";
import { Image } from "../images";
import { Tooltip } from "../misc";
import { Text, TextVariant } from "../typography";
import { MenuItemProps, MenuItems, PopoverWrapper, usePopover } from "../info";
import { useAppPalette } from "../../theme";
import { Icon, UnreadDot } from "../icons";

export function TableTimeDisplay({
    time,
    includeDate = true
}: {
    time: Date | number | string;
    includeDate?: boolean;
}) {
    return (
        <Column>
            <Text>{formatDate(time, "relative")} </Text>
            {includeDate && <Text color="caption">{formatDate(time, "both")}</Text>}
        </Column>
    );
}

export function TableDateDisplay({ time }: { time: Date | number | string }) {
    return (
        <Column>
            <Text>{formatDate(time, "both")} </Text>
            <Text variant="body2" color="caption">
                {formatDate(time, "relative")}
            </Text>
        </Column>
    );
}

export function TableAssetDisplay({
    name,
    description,
    image,
    variant: propsVariant,
    loading,
    unreadWithTooltip,
    showImage,
    unreadIcon = "warning",
    unreadColor = "error",
    abbreviateHeader = true
}: {
    loading?: boolean;
    name: MayString;
    description?: string;
    image?: string;
    variant?: TextVariant;
    unreadWithTooltip?: string;
    unreadIcon?: "dot" | "warning";
    unreadColor?: "error" | "info" | "success";
    showImage?: boolean;
    abbreviateHeader?: boolean;
}) {
    const variant: TextVariant = propsVariant ?? !!unreadWithTooltip ? "h4" : "body1";
    return (
        <Row spacing={1}>
            {showImage && <Image src={image} size="40px" />}
            <Column>
                <Tooltip placement="right" title={unreadWithTooltip}>
                    <Row spacing={0.5}>
                        <Text loading={loading} loadingWidth="100px" variant={variant}>
                            {abbreviateHeader ? abbreviateString(name, 18) : name}
                        </Text>

                        {(() => {
                            if (!unreadWithTooltip) return null;
                            if (unreadIcon === "dot") return <UnreadDot color={unreadColor} />;
                            return (
                                <Text color={unreadColor}>
                                    <Icon type="warning" />
                                </Text>
                            );
                        })()}
                    </Row>
                </Tooltip>

                {description && (
                    <Text loading={loading} loadingWidth="50px" variant="body2" color="caption">
                        {description}
                    </Text>
                )}
            </Column>
        </Row>
    );
}

export function TableButton({ cta, to }: { cta: string; to?: string }) {
    return (
        <ConditionalLink to={to}>
            <Button width="max-content" fullWidth>
                {cta}
            </Button>
        </ConditionalLink>
    );
}

export function TableMenuPopup({
    items,
    menuWidth = 200,
    warning
}: {
    items: MenuItemProps[];
    menuWidth?: number;
    warning?: boolean;
}) {
    const { open, close, popoverProps } = usePopover();

    const { error, border } = useAppPalette();

    return (
        <>
            <Row spacing={-2}>
                <IconButton
                    sx={{
                        p: 0.5,
                        border: warning ? `1px solid ${colorToAlpha(error, 0.5)}` : border
                    }}
                    color={warning ? "error" : "primary"}
                    textVariant="h3"
                    icon="more"
                    onClick={open}
                />
            </Row>
            <PopoverWrapper {...popoverProps} transformOrigin={{ horizontal: "right", vertical: -10 }}>
                <MenuItems sx={{ width: menuWidth }} px={1} close={close} items={items} />
            </PopoverWrapper>
        </>
    );
}
