import { createContext, useContext, ReactNode, useState, useEffect, useMemo } from "react";

import { DispatchType, FormInputType } from "@bridgesplit/react";
import { OrderbookQuote, PresetStrategyTerms, useOrderbookQuotes, usePresets } from "@bridgesplit/abf-react";
import { doNothing, removeDuplicatesWithFunction } from "@bridgesplit/utils";
import { TokenListMetadata, formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";
import { useSearchParams } from "react-router-dom";
import { AppParams } from "app/utils";

import { isPresetSame, useMarketCollateral } from "../util";
import { useMarketContext } from "./MarketContext";
import { ALL_COLLATERAL_VALUE, ALL_PRESET_COLLATERAL_VALUE } from "./constants";

type QuoteContextState = {
    preset: PresetStrategyTerms | undefined;
    setPreset: FormInputType<PresetStrategyTerms | undefined>;
    collateralMints: string[];
    collateralMintsForQueries: string[] | undefined; // for fetching using collateral mints
    setCollateralMints: DispatchType<string[]>;
    presets: PresetStrategyTerms[] | undefined;
    orderbookQuotes: OrderbookQuote[] | undefined;
    collateralToken: TokenListMetadata | undefined;
};

const QuotesContext = createContext<QuoteContextState | null>(null);

export function useQuotesContext() {
    const context = useContext(QuotesContext);
    if (!context) {
        throw new Error("useQuotesContext must be used within a QuotesProvider");
    }
    return context;
}

export function HiddenQuotesProvider({ children }: { children: ReactNode }) {
    return (
        <QuotesContext.Provider
            value={{
                preset: undefined,
                setPreset: doNothing,
                collateralMints: [],
                setCollateralMints: doNothing,
                presets: undefined,
                orderbookQuotes: undefined,
                collateralToken: undefined,
                collateralMintsForQueries: undefined
            }}
        >
            {children}
        </QuotesContext.Provider>
    );
}

export function QuotesProvider({ children }: { children: ReactNode }) {
    const { principalMint, isCustodian, custodianIdentifier } = useMarketContext();

    const [preset, setPreset] = useState<PresetStrategyTerms>();
    const [collateralMints, setCollateralMints] = useState<string[]>(ALL_COLLATERAL_VALUE);
    const [collateralInitialized, setCollateralInitialized] = useState(false);

    const [search] = useSearchParams();

    const allTokens = useMarketCollateral();
    const allMints = allTokens?.map((t) => t.assetMint);
    const collateralToken =
        collateralMints?.length === 1 ? allTokens?.find((c) => c.assetMint === collateralMints?.[0]) : undefined;

    const collateralMintsForQueries = useMemo(() => {
        if (isCustodian) return [ALL_PRESET_COLLATERAL_VALUE];
        if (collateralMints === ALL_COLLATERAL_VALUE) return allMints;
        return collateralMints;
    }, [allMints, collateralMints, isCustodian]);

    // no need to filter all presets since we are only passing in one collateral mint
    const { allPresets } = usePresets();

    const presets = useMemo(() => {
        if (!allPresets) return undefined;
        return removeDuplicatesWithFunction(allPresets, (p) => p.strategyTerms.presetStrategyIdentifier).map(
            (p) => p.strategyTerms
        );
    }, [allPresets]);

    const { data: orderbookQuotes, isFetching } = useOrderbookQuotes({
        preset,
        principalMint,
        collateralMints: isCustodian ? [] : collateralMintsForQueries,
        custodianIdentifier
    });

    const termShortHand = search.get(AppParams.Term);
    const specifiedMint = search.get(AppParams.CollateralMint);

    // set collateral to params
    useEffect(() => {
        if (collateralInitialized) return;
        if (specifiedMint) {
            setCollateralMints([specifiedMint]);
        } else {
            setCollateralMints(ALL_COLLATERAL_VALUE);
        }
        setCollateralInitialized(true);
    }, [collateralInitialized, collateralMints, specifiedMint]);

    // Switch selected collateral on token switch
    useEffect(() => {
        if (!!principalMint && collateralMints?.includes(principalMint)) {
            setCollateralMints(ALL_COLLATERAL_VALUE);
        }
    }, [collateralMints, principalMint]);

    // prevents empty preset when collateral is modified
    useEffect(() => {
        if (!presets?.length) return;

        if (!preset || !presets.some((p) => isPresetSame(preset, p))) {
            const defaultPreset =
                presets.find((p) => formatDurationWithTypeShorthand(p.duration, p.durationType) === termShortHand) ??
                presets?.[0];
            setPreset(defaultPreset);
        }
    }, [preset, presets, termShortHand]);

    return (
        <QuotesContext.Provider
            value={{
                preset,
                setPreset,
                collateralMints,
                collateralMintsForQueries,
                setCollateralMints,
                presets,
                orderbookQuotes: isFetching ? undefined : orderbookQuotes,
                collateralToken
            }}
        >
            {children}
        </QuotesContext.Provider>
    );
}
