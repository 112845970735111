import { Fragment, ReactElement, ReactNode, useState } from "react";

import {
    Box,
    Divider,
    Skeleton,
    SkeletonProps,
    styled,
    Tooltip as MuiTooltip,
    TooltipProps,
    tooltipClasses,
    TablePagination,
    useMediaQuery,
    SxProps
} from "@mui/material";
import { InboxOutlined, TravelExploreOutlined } from "@mui/icons-material";
import { doNothing } from "@bridgesplit/utils";
import { FormInputType } from "@bridgesplit/react";

import { Button, ConditionalLink, Link, ButtonProps, IconButton } from "./buttons";
import { AbsoluteCenterWrapper, Column, Row, ShadowCard } from "./containers";
import { Text, TextColor, TextVariant, TooltipText } from "./typography";
import { Image } from "./images";
import { CARD_WIDTH } from "../constants";
import { SxType } from "../types";
import { BORDER_RADIUS, FONT_SIZES, HEAVY_SHADOW, INPUT_HEIGHT, LIGHT_SHADOW, useAppPalette } from "../theme";
import { Icon, IconHeader, IconWithBackground } from "./icons";
import { MEDIA } from "../util";

export interface CustomTooltipProps extends TooltipProps {
    fullWidth?: boolean;
    containerSx?: SxType;
    reverseColors?: boolean;
}

export const Tooltip = ({
    className,
    containerSx,
    reverseColors,
    fullWidth,
    placement,
    ...props
}: CustomTooltipProps) => {
    const { paperBackground, primary } = useAppPalette();
    const isMobile = useMediaQuery(MEDIA.MD.below);
    return (
        <MuiTooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        color: reverseColors ? primary : paperBackground,
                        backgroundColor: reverseColors ? paperBackground : primary,
                        boxShadow: HEAVY_SHADOW,
                        "& .MuiTooltip-arrow": {
                            color: reverseColors ? paperBackground : primary
                        }
                    }
                }
            }}
            {...props}
            placement={isMobile ? "bottom" : placement}
            enterTouchDelay={300}
            arrow={!isMobile}
            classes={{ popper: className }}
        >
            <Box sx={{ width: fullWidth ? "100%" : "fit-content", ...containerSx }}>{props.children}</Box>
        </MuiTooltip>
    );
};

export const TooltipInfo = styled(({ className, containerSx, fullWidth, ...props }: CustomTooltipProps) => (
    <MuiTooltip {...props} enterTouchDelay={0} arrow classes={{ popper: className }}>
        <Box sx={{ width: fullWidth ? "100%" : "fit-content", ...containerSx }}>{props.children}</Box>
    </MuiTooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.default
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: 2,
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.background.default,
        backgroundColor: theme.palette.background.default,
        boxShadow: LIGHT_SHADOW
    }
}));

export const SkeletonRounded = (props: SkeletonProps) => (
    <Skeleton {...props} variant="rectangular" sx={{ borderRadius: BORDER_RADIUS, ...props.sx }} />
);

export const ButtonSkeleton = (props: SkeletonProps) => (
    <Skeleton
        {...props}
        variant="rectangular"
        sx={{ borderRadius: BORDER_RADIUS, height: INPUT_HEIGHT + "px", width: "100%", ...props.sx }}
    />
);

export function repeatElement(node: ReactNode, length = 5) {
    return Array(length)
        .fill(null)
        .map((_, i) => <Fragment key={i}> {node} </Fragment>);
}

export function Error404Page() {
    return (
        <AbsoluteCenterWrapper>
            <ShadowCard sx={{ width: CARD_WIDTH }} padding>
                <NotFoundCard header="Page not found" description="We can’t find the page you are looking for" />
            </ShadowCard>
        </AbsoluteCenterWrapper>
    );
}

export function NotFoundCard({ header, description }: { header: string; description: string }) {
    return (
        <Column sx={{ width: "100%", alignItems: "center", textAlign: "center" }} spacing={2}>
            <IconHeader>
                <TravelExploreOutlined />
            </IconHeader>
            <Column sx={{ alignItems: "center" }}>
                <Text variant="h3">{header}</Text>
                <Text color="caption" variant="body2">
                    {description}
                </Text>
            </Column>
            <Link sx={{ width: "100%" }} to="/">
                <Button variant="contained" fullWidth>
                    Back home
                </Button>
            </Link>
        </Column>
    );
}

type PromptCtaType = { text: ReactNode; to?: string; onClick?: () => void; buttonProps?: ButtonProps };
export type EmptyPlaceholderPropsWithCta = {
    cta?: PromptCtaType | PromptCtaType[];
    header: string;
    description?: string;
    icon?: JSX.Element;
};
export type EmptyPlaceholderProps = EmptyPlaceholderPropsWithCta;

export function EmptyPlaceholder(props: EmptyPlaceholderProps) {
    const { icon = <InboxOutlined />, header, description, cta } = props;
    return (
        <Column
            spacing={3}
            sx={{
                height: "100%",
                p: 2,
                my: 2,
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Column spacing={2} sx={{ maxWidth: "500px", alignItems: "center", justifyContent: "center" }}>
                <IconWithBackground variant="h1" size={50}>
                    {icon}
                </IconWithBackground>

                <Column alignItems="center" spacing={0.5}>
                    <Text>{header}</Text>
                    {!!description && (
                        <Text variant="body2" color="caption">
                            {description}
                        </Text>
                    )}
                </Column>
                {cta && !Array.isArray(cta) && <PromptCta cta={cta} />}
                <Row spacing={1}>
                    {cta &&
                        Array.isArray(cta) &&
                        cta.map((c, i) => (
                            <PromptCta variant={i === cta.length - 1 ? "contained" : "outlined"} key={i} cta={c} />
                        ))}
                </Row>
            </Column>
        </Column>
    );
}

function PromptCta({ cta, variant = "contained" }: { cta: PromptCtaType; variant?: "contained" | "outlined" }) {
    return (
        <ConditionalLink to={cta.to}>
            <Button {...cta.buttonProps} variant={variant} onClick={cta.onClick}>
                {cta.text}
            </Button>
        </ConditionalLink>
    );
}

export function OrDivider() {
    return (
        <Row sx={{ width: "100%" }} spacing={1}>
            <Divider sx={{ flex: 1, alignSelf: "center" }} flexItem />
            <Text color="disabled"> or </Text>
            <Divider sx={{ flex: 1, alignSelf: "center" }} flexItem />
        </Row>
    );
}

export interface CheckListProps {
    icon: ReactNode;
    title: string;
    description: string;
    completed: boolean;
    cta?: (() => void) | string; // string if it's an internal path
    ctaText?: string;
    docsLink?: string;
    disabled?: boolean;
    buttonProps?: ButtonProps;
    key?: string | number; // by default title is used as key
}

function CheckListItem({
    item,
    active,
    setActive
}: {
    item: CheckListProps | undefined;
    active: boolean;
    setActive: () => void;
}) {
    const { border, hoverBackground } = useAppPalette();
    const clickable = !item?.disabled && !active;
    return (
        <Column
            onClick={item?.disabled ? undefined : setActive}
            sx={{
                cursor: clickable ? "pointer" : "default",
                opacity: active ? 1 : 0.5,
                border,
                borderRadius: BORDER_RADIUS,
                p: 2,
                ":hover": { background: clickable ? hoverBackground : undefined }
            }}
            spacing={2}
        >
            <Row spaceBetween>
                <Row spacing={1}>
                    {!item ? (
                        <Image size="30px" variant="circle" src={undefined} />
                    ) : (
                        <Text sx={{ width: "30px" }} variant="h1">
                            {item?.icon}
                        </Text>
                    )}
                    <Column>
                        <Row spacing={0.5}>
                            <Text variant="h4" loading={!item}>
                                {item?.title}
                            </Text>
                            {item?.completed && (
                                <TooltipText helpText="Complete" icon={false} color="success">
                                    <Icon type="accept" />
                                </TooltipText>
                            )}
                        </Row>

                        <Text color="caption" variant="body2" loading={!item}>
                            {item?.description}
                        </Text>
                    </Column>
                </Row>
                {!active && <IconButton icon="more" />}
            </Row>

            {active && (
                <Row spacing={1} sx={{ pl: 4 }}>
                    {item?.cta && (
                        <ConditionalLink to={typeof item?.cta === "string" ? item.cta : undefined}>
                            <Button
                                {...item?.buttonProps}
                                variant="contained"
                                onClick={typeof item?.cta === "string" ? undefined : item?.cta}
                            >
                                {item?.ctaText}
                            </Button>
                        </ConditionalLink>
                    )}
                    {item?.docsLink && (
                        <Button {...item.buttonProps} variant="outlined" onClick={() => window.open(item.docsLink)}>
                            View docs
                        </Button>
                    )}
                </Row>
            )}
        </Column>
    );
}

export function CheckList({
    items,
    loading,
    expanded,
    order = "any"
}: {
    items: CheckListProps[];
    loading: boolean;
    expanded: boolean;
    order?: "sequential" | "any" | "fixed";
}) {
    const [activeIndex, setActiveIndex] = useState<number>();
    const firstIncompleteIndex = items.findIndex((i) => !i.completed);
    const defaultIndex = firstIncompleteIndex === -1 ? items.length - 1 : firstIncompleteIndex;

    const completedItems = items.filter((i) => i.completed).length;
    const currentIndex = order === "fixed" ? defaultIndex : activeIndex ?? defaultIndex;

    if (!expanded) {
        return (
            <Text color="caption">
                {completedItems} of {items.length} completed
            </Text>
        );
    }

    function lastItemComplete(index: number, items: CheckListProps[]) {
        if (index === 0) return true;
        return items[index - 1].completed;
    }

    return (
        <Column spacing={1}>
            {loading
                ? repeatElement(<CheckListItem active={false} item={undefined} setActive={doNothing} />, 4)
                : items.map((item, i) => (
                      <CheckListItem
                          setActive={() => setActiveIndex(i)}
                          active={currentIndex === i}
                          item={{
                              ...item,
                              disabled: (() => {
                                  if (item.disabled) return true;
                                  if (order === "any") return false;
                                  if (order === "fixed") return currentIndex !== i;
                                  return lastItemComplete(i, items);
                              })()
                          }}
                          key={item.key ?? item.title}
                      />
                  ))}
        </Column>
    );
}

export type IconBenefit = { icon: ReactElement; text: ReactNode };
export function IconBenefits({ benefits }: { benefits: IconBenefit[] }) {
    return (
        <Column spacing={1.5}>
            {benefits.map(({ icon, text }, i) => (
                <Row key={i} spacing={1}>
                    <Text variant="h3" color="disabled">
                        {icon}
                    </Text>
                    <Text color="caption" sx={{ display: "inline-block" }}>
                        {text}
                    </Text>
                </Row>
            ))}
        </Column>
    );
}

export function Pagination({
    count,
    page,
    setPage,
    rowsPerPage
}: {
    page: number;
    setPage: FormInputType<number>;
    count: number;
    rowsPerPage: number;
}) {
    return (
        <TablePagination
            sx={{ py: 0, minHeight: "fit-content", overflow: "visible" }}
            labelDisplayedRows={({ page, count }) => (count ? `${page + 1} of ${Math.ceil(count / rowsPerPage)}` : "")}
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => {
                setPage(newPage);
            }}
        />
    );
}

export const dottedUnderline = (): SxProps => ({
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    textDecorationColor: "divider",
    textDecorationThickness: "1px",
    textUnderlineOffset: "4px"
});

interface StatWithIconProps {
    value: string | number | undefined;
    variant?: TextVariant;
    captionColor?: TextColor;
    icon?: React.ReactNode;
}
export function StatWithIcon({
    value,
    variant = "body1",
    captionColor = "caption",
    icon
}: StatWithIconProps): JSX.Element {
    return (
        <Row spacing={1} sx={{ width: "100px", justifyContent: "flex-end" }}>
            {icon}
            <Column>
                <Text variant={variant} color={captionColor}>
                    {value !== undefined ? value : ""}
                </Text>
            </Column>
        </Row>
    );
}

export function RewardImage({ src }: { src: string }) {
    return <Image hideSkeleton size={FONT_SIZES.body1 + "px"} variant="circle" src={src} />;
}

export function RewardIcon({
    hasMultiplier = false,
    isNotEarningPoints
}: {
    hasMultiplier?: boolean;
    isNotEarningPoints?: boolean;
}) {
    const { prime, textDisabled, secondary } = useAppPalette();

    const color = isNotEarningPoints ? textDisabled : hasMultiplier ? prime : secondary;
    return (
        <Text
            sx={{
                color: color
            }}
        >
            <Icon type="points" />
        </Text>
    );
}
