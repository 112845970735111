import { useMemo } from "react";

import {
    calculateUnwindStats,
    ExternalUnwindQuote,
    getLoopSlippage,
    LoopPositionExpanded,
    LoopTransferType,
    LoopUnwindParams,
    useActiveWallet,
    useLoopUnwindTransaction,
    useUnwindExternalQuote
} from "@bridgesplit/abf-react";
import {
    Result,
    LOADING_ERROR,
    formatUsd,
    stringUiPercentToDecimals,
    formatPercent,
    decimalsToBps,
    MISSING_WALLET_ERROR
} from "@bridgesplit/utils";
import { Column, Row, StatColumn, StatProps, TagTextAlert, Text } from "@bridgesplit/ui";
import { JupiterSwapMode, LoopRoutePlatform, UnwindRoute } from "@bridgesplit/abf-sdk";
import { COPY } from "app/constants";
import { TrackTransactionEvent } from "app/types";

import { ActionProps } from "./types";
import {
    AppButton,
    LoopInitialDeposit,
    LoopProfitLoss,
    Slippage,
    TokenAmountWithUsd,
    useLoopUnwindAlerts
} from "../../common";
import { useTransactionSender } from "../../transactions";

export default function LoopUnwind({ loopPosition }: ActionProps) {
    const { slippagePresetsUi, defaultSlippageUi } = getLoopSlippage(loopPosition?.loopExpanded);
    const { slippagePercentDecimals, slippageWrapperProps, slippageButtonProps } = Slippage.useController({
        defaultSlippageUi,
        presetsUi: slippagePresetsUi
    });

    const unwind = useLoopUnwind();

    const { data: externalQuote, isError } = useUnwindExternalQuote({
        loopPositionExpanded: loopPosition,
        slippagePercentDecimals
    });

    const summary = useMemo(() => {
        if (!loopPosition || !externalQuote) return undefined;
        return calculateUnwindStats({ loopPosition, externalQuote });
    }, [loopPosition, externalQuote]);

    const { unwindWarningMessage } = useLoopUnwindAlerts({
        loopPositionExpanded: loopPosition,
        profitLossUsd: summary?.profitLossUsd,
        variant: "route"
    });

    return (
        <Slippage.Wrapper {...slippageWrapperProps}>
            <Row spaceBetween>
                <Text color="caption">{COPY.UNWIND_TERM}</Text>
                <Slippage.Button {...slippageButtonProps} />
            </Row>

            <ReceiveStats summary={summary} />
            <SecondaryStats summary={summary} externalQuote={externalQuote} loopPosition={loopPosition} />
            {unwindWarningMessage ? (
                <TagTextAlert color="warning" icon="warning">
                    {unwindWarningMessage}
                </TagTextAlert>
            ) : null}
            {isError && (
                <TagTextAlert color="error" icon="warning">
                    Unable to find a swap route in Jupiter. Please check back later
                </TagTextAlert>
            )}
            <AppButton
                disabled={!summary}
                isTransaction
                asyncCta={{ onClickWithResult: () => unwind({ slippagePercentDecimals, loopPosition, externalQuote }) }}
            >
                {COPY.UNWIND_TERM}
            </AppButton>
        </Slippage.Wrapper>
    );
}

type Summary = ReturnType<typeof calculateUnwindStats> | undefined;
function ReceiveStats({ summary }: { summary: Summary }) {
    const tokenStats = summary?.tokenStats;
    return (
        <Column spacing={0.5}>
            <Text variant="body2" color="caption">
                Receive
            </Text>
            <TokenAmountWithUsd
                variant="h3"
                tokenSize="md"
                amount={tokenStats?.receiveTokenAmount}
                amountUsd={tokenStats?.receiveUsdAmount}
                token={tokenStats?.tokenToReceive}
            />
        </Column>
    );
}

function SecondaryStats({
    summary,
    externalQuote,
    loopPosition
}: {
    summary: Summary;
    loopPosition: LoopPositionExpanded | undefined;
    externalQuote: ExternalUnwindQuote | undefined;
}) {
    const stats: StatProps[] = [
        {
            value: <LoopInitialDeposit loopPosition={loopPosition} />,
            caption: "Original deposit"
        },
        {
            value: formatUsd(summary?.contributions.additionalDepositsUsd),
            caption: COPY.LOOP_ADDITIONAL_DEPOSITS_TERM,
            tooltip: COPY.LOOP_ADDITIONAL_DEPOSITS_TOOLTIP,
            hide: !summary?.contributions.additionalDepositsUsd
        },
        {
            value: <LoopProfitLoss gainSummary={summary} loopPosition={loopPosition} />,
            caption: "Estimated P&L"
        }
    ];

    if (externalQuote?.type === LoopRoutePlatform.Jupiter) {
        stats.push({
            caption: "Price impact",
            value: formatPercent(stringUiPercentToDecimals(externalQuote.quote.priceImpactPct), {
                maxPrecisionUi: 0.01
            })
        });
    }

    return <StatColumn stats={stats} loading={!summary} />;
}

function useLoopUnwind() {
    const unwind = useLoopUnwindTransaction();
    const send = useTransactionSender();
    const { activeWallet } = useActiveWallet();
    return async ({
        slippagePercentDecimals,
        loopPosition,
        externalQuote
    }: {
        slippagePercentDecimals: number | undefined;
        loopPosition: LoopPositionExpanded | undefined;
        externalQuote: ExternalUnwindQuote | undefined;
    }) => {
        if (!loopPosition || !loopPosition.loanExpanded || !externalQuote) return Result.errFromMessage(LOADING_ERROR);
        if (!activeWallet) return Result.errFromMessage(MISSING_WALLET_ERROR);
        const { loopExpanded, loanExpanded } = loopPosition;

        const unwindRoute = ((): UnwindRoute => {
            if (externalQuote.type === LoopRoutePlatform.Jupiter) {
                return {
                    [LoopRoutePlatform.Jupiter]: {
                        amountIn:
                            externalQuote.quote.swapMode === JupiterSwapMode.ExactIn
                                ? parseInt(externalQuote.quote.inAmount)
                                : null
                    }
                };
            }
            return { [LoopRoutePlatform.Meteora]: externalQuote.quote };
        })();

        const receiveToken =
            loopExpanded.withdrawType === LoopTransferType.CollateralOnly
                ? loopExpanded.collateralToken
                : loopExpanded.principalToken;

        const params: LoopUnwindParams = {
            loanAddress: loanExpanded.loan.address,
            unwindRoute,
            collateralSlippageCbps: decimalsToBps(slippagePercentDecimals),
            principalMint: loopExpanded.principalToken.assetMint,
            collateralMint: loopExpanded.collateralToken.assetMint,
            order: loanExpanded.order.address,
            mintToReceive: receiveToken.assetMint,
            lockboxMint: loanExpanded.lockboxAddress,
            userPublicKey: activeWallet.wallet
        };

        return await send(unwind, params, {
            alerter: {
                generationEstimateSeconds: 8,
                showProgress: true
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitCloseEarnOrder,
                params
            }
        });
    };
}
