import { useState } from "react";

import {
    DOB_PATTERN,
    DialogWrapper,
    FormInput,
    SSN_PATTERN,
    parseDOBFromPatternString,
    parsePhoneNumberIntoE164,
    parseSSN
} from "@bridgesplit/ui";
import { DEFAULT_COUNTRY_CODE, DEFAULT_ISO3166_VALUE, DispatchType } from "@bridgesplit/react";
import { useActiveWallet, useCreateSphereIndividualCustomerMutation, useUserProfile } from "@bridgesplit/abf-react";
import { CreateCustomerInput } from "@bridgesplit/abf-sdk";
import {
    Result,
    NullableRecord,
    MISSING_PARAM_ERROR,
    getReadableErrorMessage,
    generateNonce,
    allKeysDefined
} from "@bridgesplit/utils";
import { AppDialog, useAppDialog } from "app/utils";

import { AddressInput, DialogHeader, PhoneNumberInput, REQUIRED_ADDRESS_FIELDS } from "../../common";
import { RegisterCta, SphereIntroWrapper } from "./common";
import { useSphereRefresh, validateSphereAddress } from "../util";

type Form = NullableRecord<{ dob: string; ssn: string; phoneNumber: string }> & {
    countryCode: string;
    address: AddressInput;
};
enum Step {
    BasicInformation,
    Address
}
const STEP_COUNT = Object.values(Step).length / 2;

type FormProps = { form: Form; setForm: DispatchType<Form>; step: Step; setStep: DispatchType<Step> };
export default function RegisterIndividualSphereDialog() {
    const [step, setStep] = useState<Step>(0);

    const [form, setForm] = useState<Form>({
        phoneNumber: undefined,
        dob: undefined,
        ssn: undefined,
        countryCode: DEFAULT_COUNTRY_CODE.dialCode,
        address: { country: DEFAULT_ISO3166_VALUE }
    });

    const props = { form, setForm, step, setStep };

    return (
        <SphereIntroWrapper>
            <DialogWrapper>
                <DialogHeader
                    header={AppDialog.RegisterIndividualSphere}
                    description="Sphere requires the following information to register"
                />
                {(() => {
                    switch (step) {
                        case Step.BasicInformation:
                            return <BasicInformation {...props} />;
                        case Step.Address:
                            return <Address {...props} />;
                        default:
                            return null;
                    }
                })()}
            </DialogWrapper>
        </SphereIntroWrapper>
    );
}

function BasicInformation(props: FormProps) {
    const { form, setForm } = props;
    return (
        <>
            <FormInput
                placeholder="01/01/2000"
                label="Date of Birth"
                value={form.dob}
                pattern={{ format: DOB_PATTERN }}
                setValue={(dob) => setForm((prev) => ({ ...prev, dob }))}
                variant="string"
            />
            <PhoneNumberInput form={form} setForm={setForm} />
            <FormInput
                placeholder="XXX-XX-XXXX"
                label="Social security number (SSN)"
                value={form.ssn}
                pattern={{ format: SSN_PATTERN }}
                setValue={(ssn) => setForm((prev) => ({ ...prev, ssn }))}
                variant="string"
            />
            <Cta disabled={!form.phoneNumber || !form.dob || !form.ssn} {...props} />
        </>
    );
}

function Address(props: FormProps) {
    const { form, setForm } = props;

    return (
        <>
            <AddressInput input={form.address} setInput={(address) => setForm((prev) => ({ ...prev, address }))} />
            <Cta disabled={!allKeysDefined(form.address, REQUIRED_ADDRESS_FIELDS)} {...props} />
        </>
    );
}

function Cta({ form, disabled, step, setStep }: FormProps & { disabled?: boolean }) {
    const [createSphereCustomer] = useCreateSphereIndividualCustomerMutation();
    const { user } = useUserProfile();
    const { activeWallet } = useActiveWallet();

    const { close } = useAppDialog();
    const refresh = useSphereRefresh();
    async function register() {
        if (!activeWallet) return Result.errFromMessage("You have a registered wallet to setup cash transfers");

        const ssn = parseSSN(form.ssn);
        if (!ssn.isOk()) return Result.errFromMessage(MISSING_PARAM_ERROR);

        const dob = parseDOBFromPatternString(form.dob);
        if (!dob.isOk()) return dob;

        const phoneNumber = parsePhoneNumberIntoE164(form);
        if (!phoneNumber.isOk()) return phoneNumber;

        const address = validateSphereAddress(form.address);
        if (!address.isOk()) return address;

        const param: CreateCustomerInput = {
            phoneNumber: phoneNumber.unwrap(),
            solanaPubKey: activeWallet.wallet,
            ssn: ssn.unwrap().toString(),
            email: user.email,
            address: address.unwrap(),
            dob: dob.unwrap()
        };
        const res = await createSphereCustomer({ ...param, idempotentKey: generateNonce() });

        if ("error" in res) return Result.errWithDebug(getReadableErrorMessage("register with Sphere"), res);
        await refresh();
        close();
        return Result.ok();
    }

    return <RegisterCta stepCount={STEP_COUNT} step={step} setStep={setStep} disabled={disabled} register={register} />;
}
