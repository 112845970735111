import { AbfRole } from "@bridgesplit/abf-react";
import { HeaderCard, HeaderCardWithAction, Text, TextButton } from "@bridgesplit/ui";
import { useAppDialog, AppDialog } from "app/utils";

import { RolesDisplay } from "./common";
import ManagePermissionsTable from "./permissions/ManagePermissionsTable";
import { useOrganizationMembers } from "./util";

const ALLOWED_ROLES = [AbfRole.EscrowManager, AbfRole.Borrower, AbfRole.Lender, AbfRole.Admin];

export default function GroupRolesAdmin() {
    const members = useOrganizationMembers();
    const { open } = useAppDialog();

    const addMember = () => open(AppDialog.AddMember, undefined);

    return (
        <>
            <HeaderCardWithAction
                actionElement={<TextButton onClick={addMember}>Add Member</TextButton>}
                header="Members"
                spacing={3}
            >
                <Text color="caption">View and control roles for existing organization members</Text>

                <ManagePermissionsTable
                    emptyPlaceholder={{
                        header: "Your team is empty",
                        description: "You haven't added anyone to your organization",
                        cta: { text: " Add Member", onClick: addMember }
                    }}
                    searchPlaceholder="Search by email or name"
                    members={members}
                />
            </HeaderCardWithAction>
            <HeaderCard spacing={2} header="Roles">
                <Text color="caption">Learn about each role before assigning permissions</Text>
                <RolesDisplay roles={ALLOWED_ROLES} />
            </HeaderCard>
        </>
    );
}
