import { useMemo, useState } from "react";

import { getLoanOrcaCollateral } from "@bridgesplit/abf-react";
import { Column, TabsSelect, usePolling } from "@bridgesplit/ui";
import { Slippage } from "app/components/common";
import { IS_LOCAL_NX_DEV } from "@bridgesplit/utils";

import { ActionProps } from "../types";
import { useWhirlpoolContext, WhirlpoolProvider } from "./WhirlpoolContext";
import { OpenPosition } from "./open";
import WhirlpoolInfo from "./WhirlpoolInfo";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { useInitPositionData, useInitPoolData } from "./util";
import { useOrcaDepositHooks } from "./deposit/util";
import { useOpenPositionHooks } from "./open/util";
import { useWithdrawOrcaHooks } from "./withdraw/util";

enum Open {
    Deposit = "Deposit",
    Withdraw = "Withdraw",
    OpenPosition = "Re-initialize"
}

export default function ManageWhirlpool({ loanExpanded }: ActionProps) {
    const whirlpoolCollateral = getLoanOrcaCollateral(loanExpanded);
    const [open, setOpen] = useState<Open>(Open.Deposit);

    const Section = useMemo(() => {
        switch (open) {
            case Open.OpenPosition:
                return OpenPosition;
            case Open.Deposit:
                return Deposit;
            case Open.Withdraw:
                return Withdraw;
            default:
                return null;
        }
    }, [open]);

    if (!whirlpoolCollateral?.whirlpoolPosition || !loanExpanded || !Section) return null;

    return (
        <WhirlpoolProvider value={{ whirlpoolPosition: whirlpoolCollateral.whirlpoolPosition, loanExpanded }}>
            <SlippageProvider>
                <InitPositionData />
                <Column spacing={4} flexGrow={1} justifyContent="space-between">
                    <Column spacing={3}>
                        <WhirlpoolInfo />
                        <TabsSelect
                            wrapperSx={{ mx: -2, width: "auto" }}
                            value={open}
                            setValue={setOpen}
                            options={Object.values(Open).map((o) => ({ value: o }))}
                        />
                        <Section.Top />
                    </Column>
                    <Section.Bottom />
                </Column>
            </SlippageProvider>
        </WhirlpoolProvider>
    );
}

function SlippageProvider({ children }: { children: React.ReactNode }) {
    const { slippageController } = useWhirlpoolContext();
    return <Slippage.Wrapper {...slippageController.slippageWrapperProps}>{children}</Slippage.Wrapper>;
}

function InitPositionData() {
    const { poll } = useWhirlpoolContext();
    usePolling({ callback: poll, interval: 15_000, skip: IS_LOCAL_NX_DEV });
    useInitPositionData();
    useInitPoolData();
    useOpenPositionHooks();
    useOrcaDepositHooks();
    useWithdrawOrcaHooks();

    return null;
}
