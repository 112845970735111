import { createApi } from "@reduxjs/toolkit/query/react";
import { SanctumRoute, SplitStakeAccount, StakeAccountWithAmount } from "@bridgesplit/abf-sdk";
import { useDispatch } from "react-redux";

import { ValidatorMetadata } from "../types";
import {
    GET_STAKE_ACCOUNTS_BY_OWNERS_ROUTE,
    GET_UNSTAKE_SOL_QUOTE_ROUTE,
    GET_VALIDATOR_INFO_ROUTE
} from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const STAKE_TAG = "Staked";
export const stakedSolApi = createApi({
    reducerPath: "stakedSolApi",
    tagTypes: [STAKE_TAG],
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        sanctumUnstakeQuotes: builder.query<
            { route: SanctumRoute; stakeAccount: StakeAccountWithAmount }[],
            StakeAccountWithAmount[]
        >({
            queryFn: async (stakeAccounts, _, options, baseQuery) => {
                const quotes = await Promise.all(
                    stakeAccounts.map(async (account) => {
                        const res = await baseQuery({
                            url: GET_UNSTAKE_SOL_QUOTE_ROUTE,
                            body: account,
                            method: "POST"
                        });
                        const rawData = res.data as SanctumRoute;

                        return { stakeAccount: account, route: rawData };
                    })
                );

                return {
                    data: quotes
                };
            }
        }),

        stakeAccountsByOwners: builder.query<Record<string, SplitStakeAccount[]>, string[]>({
            providesTags: [STAKE_TAG],
            query(owners) {
                return {
                    url: GET_STAKE_ACCOUNTS_BY_OWNERS_ROUTE,
                    method: "POST",
                    body: owners
                };
            }
        }),
        validatorMetadata: builder.query<ValidatorMetadata[], string[]>({
            query: (voteIdentifiers) => ({
                url: GET_VALIDATOR_INFO_ROUTE,
                body: voteIdentifiers,
                method: "POST"
            })
        })
    })
});

export const useAbfStakeApi = () => {
    const dispatch = useDispatch();

    return {
        resetStakeApi: () => dispatch(stakedSolApi.util.invalidateTags([STAKE_TAG]))
    };
};

export const { useSanctumUnstakeQuotesQuery, useValidatorMetadataQuery, useStakeAccountsByOwnersQuery } = stakedSolApi;
