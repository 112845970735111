import { PaletteMode, Shadows, ThemeOptions } from "@mui/material";

import { getDarkPalette } from "./getDarkPalette";
import { getLightPalette } from "./getLightPalette";
import { SPACING } from "./theme";
import { getThemeTypography } from "./themeTypography";

export function createThemeOptions(mode: PaletteMode): ThemeOptions {
    const palette = mode === "light" ? getLightPalette() : getDarkPalette();
    return {
        shadows: ["none", ...Array(24).fill("0px 0px 10px 0px rgba(0, 0, 0, 0.12)")] as Shadows,
        spacing: SPACING,
        palette: { mode, ...palette },
        typography: getThemeTypography(" PP Neue Montreal")
    };
}

export * from "./theme";
export * from "./extensions";
