import {
    FONT_SIZES,
    Icon,
    Image,
    Row,
    Text,
    TextButton,
    TextColor,
    TextVariant,
    Tooltip,
    TooltipText,
    useCopyAddress
} from "@bridgesplit/ui";
import { LinkOffOutlined, LinkOutlined } from "@mui/icons-material";
import { useActiveWallet, useUserProfile } from "@bridgesplit/abf-react";
import { formatAddress } from "@bridgesplit/utils";
import { useWallet } from "@solana/wallet-adapter-react";
import { AppDialog, useAppDialog } from "app/utils";

export default function WalletInfo({
    variant = "body1",
    color = "body"
}: {
    variant?: TextVariant;
    color?: TextColor;
}) {
    const { user } = useUserProfile();

    const { activeWallet: registeredWallet, isMpcActive } = useActiveWallet();
    const { wallet: connectedWallet, disconnect } = useWallet();
    const { open } = useAppDialog();
    const copy = useCopyAddress();

    if (!registeredWallet) return null;

    const addressToCopy = isMpcActive ? registeredWallet.wallet : undefined;
    if (addressToCopy) {
        return (
            <Row onClick={() => copy(addressToCopy)} spacing={1}>
                <Text isLink underlineLink="hover" variant={variant} color={color}>
                    {formatAddress(addressToCopy)}
                </Text>
                <TextButton variant={variant} color={color}>
                    <Icon type="copy" />
                </TextButton>
            </Row>
        );
    }

    if (!connectedWallet?.adapter.publicKey) {
        return (
            <Row spacing={1}>
                <Text
                    isLink
                    underlineLink="hover"
                    onClick={() => copy(registeredWallet.wallet)}
                    variant={variant}
                    color={color}
                    loadingWidth="120px"
                >
                    {formatAddress(registeredWallet.wallet)}
                </Text>
                <Tooltip title="Connect wallet">
                    <TextButton
                        variant={variant}
                        onClick={() => open(AppDialog.WalletConnect, undefined)}
                        color={color}
                    >
                        <LinkOutlined />
                    </TextButton>
                </Tooltip>
            </Row>
        );
    }
    const { icon, publicKey } = connectedWallet.adapter;
    const isNonRegisteredWallet = publicKey.toString() !== registeredWallet.wallet;

    return (
        <Row spacing={1}>
            <Image src={icon} size={FONT_SIZES.body2 + "px"} variant="rounded" />

            <TooltipText
                isLink
                underlineLink="hover"
                onClick={() => copy(registeredWallet.wallet)}
                variant={variant}
                helpText={
                    isNonRegisteredWallet
                        ? `You registered wallet for ${user.email} is ${formatAddress(registeredWallet.wallet)}`
                        : undefined
                }
                icon={false}
                color={isNonRegisteredWallet ? "error" : color}
                loading={!user}
                loadingWidth="120px"
            >
                {formatAddress(publicKey?.toString())}
            </TooltipText>
            <Tooltip title="Disconnect wallet">
                <TextButton variant={variant} onClick={disconnect} color={color}>
                    <LinkOffOutlined />
                </TextButton>
            </Tooltip>
        </Row>
    );
}
