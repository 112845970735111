import { createContext, useContext } from "react";

import { LoopExpanded } from "@bridgesplit/abf-react";

interface LoopContextState {
    loopExpanded: LoopExpanded | undefined;
}

const LoopContext = createContext<LoopContextState | null>(null);

export function useLoopContext(): LoopContextState {
    const context = useContext(LoopContext);
    if (context === null) {
        throw new Error("useLoopContext must be used within a LoopProvider");
    }
    return context;
}

export function LoopProvider({
    children,
    loopExpanded
}: {
    children: React.ReactNode;
    loopExpanded: LoopExpanded | undefined;
}) {
    return (
        <LoopContext.Provider
            value={{
                loopExpanded
            }}
        >
            {children}
        </LoopContext.Provider>
    );
}
