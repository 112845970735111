import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { CollateralStats, Lockbox, LockboxAsset, LockboxWithAssets } from "@bridgesplit/abf-sdk";

import { COLLATERAL_STATS_ROUTE, GET_LOCKBOXES_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";
import { LockboxFilter } from "../types";

const LOCKBOX_TAG = "Lockbox";
export const lockboxApi = createApi({
    reducerPath: "lockboxApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [LOCKBOX_TAG],
    endpoints: (builder) => ({
        lockboxes: builder.query<LockboxWithAssets[], LockboxFilter | void>({
            providesTags: [LOCKBOX_TAG],
            query(body) {
                return {
                    url: GET_LOCKBOXES_ROUTE,
                    method: "POST",
                    body
                };
            },
            transformResponse: (raw: Record<string, { data: Lockbox; assets: Record<string, LockboxAsset> }>) => {
                return Object.values(raw).map((l) => ({ ...l, assets: Object.values(l.assets) }));
            }
        }),
        collateralStats: builder.query<CollateralStats, void>({
            query() {
                return {
                    url: COLLATERAL_STATS_ROUTE,
                    method: "GET"
                };
            }
        })
    })
});

export const { useLockboxesQuery, useCollateralStatsQuery } = lockboxApi;

export const useLockboxApi = () => {
    const dispatch = useDispatch();
    const [fetchLockboxes] = lockboxApi.endpoints.lockboxes.useLazyQuery();
    return { fetchLockboxes, resetLockboxApi: () => dispatch(lockboxApi.util.invalidateTags([LOCKBOX_TAG])) };
};
