import { Column, Icon, MediaStack, TagWrapper, Text, TextButton, useAppPalette } from "@bridgesplit/ui";
import { useAuthStorage } from "@bridgesplit/abf-react";

import { DESKTOP_PADDING, MOBILE_PADDING, NAV_BREAKPOINT, useNavbarHeight } from "../navbar";

export default function AppAlertBanner() {
    const { height } = useNavbarHeight();
    const { background } = useAppPalette();

    const { state } = useAuthStorage();

    if (!state.apiVersionUpdateNeeded) return null;

    return (
        <Column sx={{ mt: height + "px", mb: -height + "px", background }}>
            <TagWrapper sx={{ px: DESKTOP_PADDING, [NAV_BREAKPOINT.below]: { px: MOBILE_PADDING } }} color="error">
                <MediaStack spacing={1}>
                    <Text svgColor="error" variant="h4">
                        <Icon type="warning" /> Update available
                    </Text>
                    <Text color="caption">
                        Please refresh the page at your convenience to ensure everything works smoothly
                    </Text>
                    <TextButton onClick={() => window.location.reload()} underlineLink>
                        Refresh now
                    </TextButton>
                </MediaStack>
            </TagWrapper>
        </Column>
    );
}
