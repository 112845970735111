import { useMemo, useState } from "react";

import { InvisibleSelect, RewardIcon, Row, SelectOption, StatProps, Text, TextVariant } from "@bridgesplit/ui";
import { formatNum, TIME } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import {
    LoopscalePointSource,
    POINTS_BASIS,
    useCollateralPoints,
    useCollateralPointsSourcesUtil,
    useLoopscalePointsPerUsd
} from "@bridgesplit/abf-react";

export interface UsePointsStat {
    variant?: TextVariant;
    actions: LoopscalePointSource[];
    showSelect?: boolean;
    totalAmount: number | undefined;
    metadata: BsMetadata[] | undefined;
}

const TIME_OPTIONS: SelectOption<number>[] = [
    { label: "per day", value: TIME.DAY },
    { label: "per week", value: TIME.WEEK },
    { label: "per month", value: TIME.MONTH },
    { label: "per year", value: TIME.YEAR }
];

interface UseCalculatePointsProps {
    actions: LoopscalePointSource[];
    estimateSeconds: number;
    totalAmount?: number;
    metadata?: BsMetadata[];
}

export function useCalculatePoints({
    actions,
    estimateSeconds,
    totalAmount = 0,
    metadata = []
}: UseCalculatePointsProps): number {
    const getCollateralPoints = useCollateralPoints(metadata);
    const { pointsSourceMapping, truePointsPerSecond } = useLoopscalePointsPerUsd();
    const { getLoopscaleMultipliers } = useCollateralPointsSourcesUtil();

    return useMemo(() => {
        if (!metadata.length || !pointsSourceMapping || !truePointsPerSecond) return 0;

        const additionalPoints = metadata.map((item) => {
            const collateralData = getCollateralPoints(item.assetMint);
            if (!collateralData) return 1;

            return collateralData.getAdditionalPoints(actions);
        });

        const totalBasePointsPerSecond = additionalPoints.reduce((product, points) => product * points, 1);
        const baseSources = getLoopscaleMultipliers(actions);

        const baseSourceMultiplier =
            baseSources?.reduce((product, points) => product * (points.multiplier ?? 1), 1) ?? 1;

        const totalPoints = actions.reduce((total: number) => {
            const pointsPerSecond = truePointsPerSecond[LoopscalePointSource.IdleCap] ?? 0;
            const actionPoints =
                (totalAmount / POINTS_BASIS) *
                pointsPerSecond *
                totalBasePointsPerSecond *
                baseSourceMultiplier *
                estimateSeconds;

            return total + actionPoints;
        }, 0);

        return totalPoints;
    }, [
        metadata,
        pointsSourceMapping,
        truePointsPerSecond,
        getLoopscaleMultipliers,
        actions,
        getCollateralPoints,
        totalAmount,
        estimateSeconds
    ]);
}
export function useLoopscalePoints({
    variant = "body2",
    actions,
    showSelect = true,
    totalAmount,
    metadata
}: UsePointsStat) {
    const [estimateSeconds, setEstimateSeconds] = useState(TIME_OPTIONS[0].value);

    const calculatedPoints = useCalculatePoints({
        actions,
        estimateSeconds,
        totalAmount,
        metadata
    });

    const { hasLoopPointMultiplierAboveBase } = useCollateralPointsSourcesUtil();

    const hasMultiplier = hasLoopPointMultiplierAboveBase(metadata, actions);

    const caption = (
        <Row>
            <Text sx={{ mr: showSelect ? -0.5 : 0 }} variant={variant} color="caption">
                {showSelect ? "Rewards" : "Rewards per day"}
            </Text>
            {showSelect && (
                <InvisibleSelect
                    variant={variant}
                    color="caption"
                    value={estimateSeconds}
                    setValue={setEstimateSeconds}
                    options={TIME_OPTIONS}
                />
            )}
        </Row>
    );

    const value = (
        <Row spacing={1}>
            <RewardIcon hasMultiplier={hasMultiplier} />
            <Text variant={variant} loading={!calculatedPoints}>
                {formatNum(calculatedPoints)}
            </Text>
        </Row>
    );

    return {
        value,
        caption
    };
}
export function useLoopPoints({
    borrowed,
    variant,
    metadata
}: {
    borrowed: number | undefined;
    variant: TextVariant;
    metadata: BsMetadata[] | undefined;
}): StatProps[] {
    const pointsStat = useLoopscalePoints({
        actions: [LoopscalePointSource.Borrow],
        totalAmount: borrowed,
        variant,
        metadata
    });

    return [pointsStat];
}
