import { QuestionToggleProps } from "@bridgesplit/ui";
import { formatPercent } from "@bridgesplit/utils";

import { useBaseFaq } from "./base";
import { useLoopContext } from "../../LoopContext";

export function useJlpFaq(): QuestionToggleProps[] | undefined {
    const base = useBaseFaq();
    const { loopExpanded } = useLoopContext();
    const baseApy = formatPercent(loopExpanded?.loopVault.collateralApyPct);

    const collateral: QuestionToggleProps = {
        label: "What is JLP and how does this vault earn yield?",
        answer: [
            `JLP is Jupiter's liquidity provider token, composed of SOL, ETH, WBTC, USDC, and USDT. It powers Jupiter's perps product and currently earns about ${baseApy} APY from fees.`,
            "You'll earn yield from JLP price increases due to earned fees or from appreciation of its underlying tokens. This vault allows you to amplify your JLP exposure with up to 5x leverage for higher potential returns."
        ]
    };
    const risks: QuestionToggleProps = {
        label: "What are the risks?",
        answer: [
            "Market volatility: JLP's value can swing based on its underlying assets and Jupiter's trading activity.",
            "Changing rates: While our rates are fixed short-term, they do adjust. If borrowing costs outpace JLP yields for an extended period, you could lose a portion of your original deposit.",
            "Insufficient liquidity: If the amount of capital becomes limited, it may affect your ability to manage your position.",
            "Smart contract risk: As with any DeFi product, there's always some level of smart contract risk."
        ]
    };

    if (!base) return undefined;
    const { intro, uniqueFeatures, management } = base;

    return [intro, collateral, risks, uniqueFeatures, management];
}
