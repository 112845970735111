import { useEffect } from "react";

import {
    ErrorType,
    SendableTransaction,
    Result,
    WRAPPED_SOL_MINT,
    SOL_NATIVE_MINT,
    getReadableErrorMessage
} from "@bridgesplit/utils";
import { Commitment, LAMPORTS_PER_SOL } from "@solana/web3.js";

import {
    TransactionWithIdentifier,
    OrderedTransactions,
    AccountWithOwner,
    AccountChangeSummary,
    TransactionBalanceChange,
    ParallelTransactionsBatch
} from "../types";
import { useWeb3User } from "./context";
import { estimateBalanceChangesForTransactions } from "./transaction";
import { useAsyncResultHandler } from "./errors";
import { useUserPublicKey } from "./user";

export const EMPTY_TRANSACTION: Promise<Result<TransactionWithIdentifier[]>> = new Promise((resolve) =>
    resolve(Result.ok([]))
);

export type TransactionCombineOptions = {
    ignoreFails?: boolean;
    order: "parallel" | "sequential";
    commitmentLevel?: Commitment;
};

const MAX_SEQUENTIAL_TRANSACTIONS = 10;
export async function combineTransactionPromises(
    transactions: Promise<Result<TransactionWithIdentifier[]>>[],
    options: TransactionCombineOptions
): Promise<Result<OrderedTransactions>> {
    try {
        const generatedTxns = await Promise.all(transactions);

        const fail = generatedTxns.find((t) => !t.isOk());
        if (fail && !options.ignoreFails) {
            return Result.err(fail);
        }

        const validTransactions = generatedTxns.filter((t) => t.isOk());
        if (!validTransactions.length) {
            return Result.errFromMessage("Unable to generate any transactions", {
                errorType: ErrorType.TransactionSendError
            });
        }

        const unwrapped = validTransactions.map((t) => t.unwrap());
        if (options.order === "parallel") {
            return Result.ok([
                {
                    transactions: unwrapped.flat(),
                    commitmentLevel: options.commitmentLevel
                }
            ]);
        }

        return Result.ok(
            unwrapped.slice(0, MAX_SEQUENTIAL_TRANSACTIONS).map((txn) => {
                return {
                    transactions: txn,
                    commitmentLevel: options.commitmentLevel
                };
            })
        );
    } catch (error) {
        return Result.err(error);
    }
}

export async function parallelIntoSequentialTransactions(
    transactions: Promise<Result<TransactionWithIdentifier[]>>,
    commitmentLevel?: Commitment
): Promise<Result<OrderedTransactions>> {
    try {
        const generatedTxns = await transactions;

        if (generatedTxns.isErr()) {
            return Result.err(generatedTxns);
        }

        const validTransactions = generatedTxns.unwrapOr([]);
        if (!validTransactions.length) {
            return Result.errFromMessage(getReadableErrorMessage("generate any transactions"), {
                errorType: ErrorType.TransactionSendError
            });
        }

        return Result.ok(
            validTransactions.slice(0, MAX_SEQUENTIAL_TRANSACTIONS).map((t) => {
                return {
                    transactions: [t],
                    commitmentLevel
                };
            })
        );
    } catch (error) {
        return Result.err(error);
    }
}

export function useSimulateTransactions(
    transactions: SendableTransaction[] | undefined,
    accountWithOwners: AccountWithOwner[],
    options?: { skip?: boolean }
) {
    const { connection } = useWeb3User();
    const { resultHandler, skipHook, ...rest } = useAsyncResultHandler<{
        accountChangeSummary: AccountChangeSummary[];
        transactionBalanceChange: TransactionBalanceChange[];
    }>();

    useEffect(() => {
        async function send() {
            const balanceChanges = await estimateBalanceChangesForTransactions(
                connection,
                transactions || [],
                accountWithOwners
            );

            return balanceChanges;
        }
        if (skipHook || options?.skip || !transactions?.length || !accountWithOwners.length) return;
        resultHandler(send);
    }, [accountWithOwners, connection, options?.skip, resultHandler, skipHook, transactions]);

    return rest;
}

export function useSimulateSolBalanceChange(transactions: SendableTransaction[] | undefined) {
    const user = useUserPublicKey();
    const account = { account: user ?? "", owner: SOL_NATIVE_MINT, mint: WRAPPED_SOL_MINT };
    const { data } = useSimulateTransactions(transactions, [account], { skip: !user });

    const changeSummary = data?.accountChangeSummary.find(
        (change) => change.owner === account.owner && change.mint === account.mint
    );
    const change = changeSummary ? changeSummary.change / LAMPORTS_PER_SOL : undefined;

    return { change };
}

export function combineOrderedTransactionsParallel(
    orderedTransactionsToCombine: OrderedTransactions[],
    options?: Omit<ParallelTransactionsBatch, "transactions">
): OrderedTransactions {
    const transactions: TransactionWithIdentifier[] = [];
    for (const orderedTransactions of orderedTransactionsToCombine) {
        for (const transactionBatch of orderedTransactions) {
            transactions.push(...transactionBatch.transactions);
        }
    }
    return [
        {
            ...options,
            transactions
        }
    ];
}

export function combineOrderedTransactionsSequential(
    orderedTransactionsToCombine: OrderedTransactions[]
): OrderedTransactions {
    const transactionsBatches: ParallelTransactionsBatch[] = [];
    for (const orderedTransactions of orderedTransactionsToCombine) {
        transactionsBatches.push(...orderedTransactions);
    }
    return transactionsBatches;
}
