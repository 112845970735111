import { Column, SwitchRow, Text } from "@bridgesplit/ui";
import { ExternalYieldSource } from "@bridgesplit/abf-sdk";

import { StrategySettingsProps } from "./types";
import { COPY } from "../../../constants";

export default function StrategySettingsToggles({ form, setForm, strategy }: StrategySettingsProps) {
    return (
        <>
            <SwitchRow
                label={
                    <SwitchLabel header="Allow new loans" description="Invest loan repayments into funding new loans" />
                }
                value={!!form?.allowNewOffers}
                setValue={(allowNewOffers) => {
                    if (!form) return;
                    setForm({ ...form, allowNewOffers });
                }}
            />
            <SwitchRow
                label={<SwitchLabel header={COPY.YIELD_SOURCE_LABEL} description={COPY.YIELD_SOURCE_TOOLTIP} />}
                value={form?.yieldSource === ExternalYieldSource.MarginFi}
                setValue={(lendIdleCapital) => {
                    if (!form) return;
                    setForm({
                        ...form,
                        yieldSource: lendIdleCapital ? ExternalYieldSource.MarginFi : ExternalYieldSource.None
                    });
                }}
            />
        </>
    );
}

function SwitchLabel({ header, description, disabled }: { header: string; description: string; disabled?: boolean }) {
    return (
        <Column>
            <Text color={disabled ? "caption" : "body"}> {header}</Text>
            <Text color={disabled ? "disabled" : "caption"} variant="body2">
                {description}
            </Text>
        </Column>
    );
}
