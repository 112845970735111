// all in terms of seconds
const SECOND = 1;
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = 365 * DAY;
const MONTH = YEAR / 12;

export const TIME = { SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR };
