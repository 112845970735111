import { useMemo, useState } from "react";

import { Column, DialogWrapper, TabsSelect, Text } from "@bridgesplit/ui";
import {
    TokenBalanceExpandedWithPrice,
    isStakedSol,
    useActiveWallet,
    useOraclePrices,
    useUserEscrowAssets,
    useUserIsPrime,
    useWalletAvailableAssets
} from "@bridgesplit/abf-react";
import { formatUsd } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import CustodianAssets from "./CustodianAssets";
import FungibleAssets from "./FungibleAssets";
import UnrecognizedAssets from "./UnrecognizedAssets";
import WalletInfo from "./WalletInfo";
import { TransferButtons } from "../common";

enum Side {
    Wallet,
    Escrow
}

export default function WalletDialog() {
    return (
        <DialogWrapper>
            <Content />
        </DialogWrapper>
    );
}

function Content() {
    const { activeWallet } = useActiveWallet();

    const [side, setSide] = useState(Side.Wallet);
    const { isPrime } = useUserIsPrime();
    const wallet = useWalletAvailableAssets(activeWallet?.wallet, {
        skip: !activeWallet,
        includeStakedSol: true,
        fullSolBalance: true,
        includeLpPositions: true
    });
    const escrows = useUserEscrowAssets({ includeStakedSol: true, includeLpPositions: true });

    const isEscrowsOpen = side === Side.Escrow;
    const tokens = isEscrowsOpen ? escrows : wallet;

    const { getOracle } = useOraclePrices(tokens?.map((t) => t.mint));
    const tokensWithPrices = useMemo(
        () =>
            tokens
                ?.map((t): TokenBalanceExpandedWithPrice => {
                    const oracle = getOracle(t.mint);
                    return { ...t, usdAmount: oracle?.getUsdAmount(t.amount), usdPrice: oracle?.usdPrice };
                })
                .sort((a, b) => (b.usdAmount || 0) - (a.usdAmount || 0)),
        [getOracle, tokens]
    );

    const totalValue = tokensWithPrices?.reduce((prev, curr) => prev + (curr.usdAmount ?? 0), 0);

    return (
        <>
            {isPrime || escrows?.length ? (
                <Column sx={{ mx: -2, mt: -1 }}>
                    <TabsSelect
                        textVariant="body1"
                        value={side}
                        setValue={setSide}
                        options={[
                            { value: Side.Wallet, label: "Wallet" },
                            { value: Side.Escrow, label: COPY.ESCROW_TERM }
                        ]}
                    />
                </Column>
            ) : null}
            <Column spacing={0.5} textAlign="center" alignItems="center">
                <Text loading={!tokens} variant="h1">
                    {formatUsd(totalValue)}
                </Text>
                {isEscrowsOpen ? (
                    <Text variant="body2" color="caption">
                        Escrow
                    </Text>
                ) : (
                    <WalletInfo variant="body2" color="caption" />
                )}
            </Column>

            <TransferButtons
                includeStakedSol={tokensWithPrices?.some((t) => isStakedSol(t.mint))}
                type={isEscrowsOpen ? "escrowItems" : "walletItems"}
            />

            {isEscrowsOpen ? <Escrows assets={tokensWithPrices} /> : <WalletAssets assets={tokensWithPrices} />}
        </>
    );
}

function Escrows({ assets }: { assets: TokenBalanceExpandedWithPrice[] | undefined }) {
    return (
        <Column spacing={0.5}>
            {assets?.length === 0 && <Text color="disabled">No deposits</Text>}
            <FungibleAssets deposits={assets} />
            <CustodianAssets deposits={assets} />
        </Column>
    );
}

function WalletAssets({ assets }: { assets: TokenBalanceExpandedWithPrice[] | undefined }) {
    return (
        <Column spacing={0.5}>
            {assets?.length === 0 && <Text color="disabled">You don't own any supported assets</Text>}
            <FungibleAssets deposits={assets} />
            <CustodianAssets deposits={assets} />
            <UnrecognizedAssets />
        </Column>
    );
}
