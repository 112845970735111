import { useCallback, useEffect, useMemo } from "react";

import {
    BestQuote,
    BsMetaUtil,
    CollateralWithPreset,
    CreateStrategyFromPresetParams,
    FillStrategyOrderArgs,
    LiquidationType,
    MarketGuideMode,
    OnboardingStep,
    OrderbookQuote,
    PresetStrategyTerms,
    PresetTerms,
    RoleView,
    StrategyDuration,
    getBorrowCapDetails,
    isStakedSol,
    isWhirlpoolMetadata,
    useBsPrincipalTokens,
    useCollateralWithPresets,
    useCreateStrategyFromPresetsMutation,
    useCreateStrategyTransaction,
    useFillStrategyOfferTransaction,
    useGetTransactionGenerationType,
    useSupportedCollateral,
    useUserOnboardingStep
} from "@bridgesplit/abf-react";
import {
    JITO_SOL_MINT,
    LOADING_ERROR,
    Result,
    USDC_MINT,
    bpsToUiDecimals,
    decimalsToBps,
    filterNullableRecord,
    generateNonce,
    percentDecimalsToUi,
    uiAmountToLamports
} from "@bridgesplit/utils";
import {
    AssetTypeIdentifier,
    BsMetadata,
    formatDurationWithType,
    StrategyCollateralInfoParams,
    TokenListTag
} from "@bridgesplit/abf-sdk";
import { MEDIA } from "@bridgesplit/ui";
import { TransactionAuthenticated, mutationIntoResult } from "@bridgesplit/react";
import { createSelector } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { AppDialog, AppDialogData, useAppDialog } from "app/utils";
import { COPY } from "app/constants";
import { TrackEventWithProps, TrackTransactionEvent } from "app/types";
import { FormattedOfferTerms, trackSubmitMarketBorrowOrder, trackSubmitMarketLend, useTrack } from "app/hooks";

import {
    allTransactionsSucceeded,
    useDbAndTransactionSender,
    useTrackFromTransactions,
    useTransactionSender
} from "../transactions";
import { MarketProps, RateTick } from "./types";
import { useMarketContext, useMarketContextOptional } from "./common";
import { initialLendForm, MarketLendForm, useMarketLendContext } from "./lend";
import { getMarketPath, getTokenTagMetadata } from "../common";
import { UNCAPPED_STRATEGY_AMOUNT } from "../strategy/types";
import { BorrowMode, useBorrowBalanceChecker, useMarketBorrowContext } from "./borrow";
import { RootState } from "../../reducers";

export const MARKET_DETAIL_BREAKPOINT = MEDIA.XL;

export function useMarketLend() {
    const [createDbStrategy] = useCreateStrategyFromPresetsMutation();
    const createStrategyTransaction = useCreateStrategyTransaction();
    const { token } = useMarketContext();

    const { setForm, offerPresets } = useMarketLendContext();
    const trackMixpanelEvent = useTrackFromTransactions();

    const dbAndTransactionSender = useDbAndTransactionSender();

    /**
     * 1. generate strat escrow create / sign in txns in parallel
     * 2. sign all txns
     * 3. create db strategy
     * 4. send txn (only strat escrow create not sign in)
     */
    return async function lend({ form, escrowNeeded }: { form: MarketLendForm; escrowNeeded: boolean }) {
        if (!token) return Result.errFromMessage(LOADING_ERROR);
        const description = `Creating your ${COPY.STRATEGY_TERM.toLowerCase()}`;

        const offerTermsToApy = Object.fromEntries(
            Array.from(form.presetToApy.entries()).map(([preset, apy]) => [preset, decimalsToBps(apy)])
        );
        const strategyIdentifier = generateNonce();
        const offerTermsMixpanel = presetFormToMixpanelFormat(form.presetToApy, offerPresets);

        const depositAmountLamports = uiAmountToLamports(form.amount, token.decimals);

        const dbParams: Omit<TransactionAuthenticated<CreateStrategyFromPresetParams>, "signedTransaction"> = {
            strategyIdentifier,
            offerTermsToApy,
            liquidationTypes: [LiquidationType.OutstandingPayment],
            principalMint: token.assetMint
        };

        const transactionResults = await dbAndTransactionSender({
            transactionDetails: createStrategyTransaction,
            dbCallback: {
                type: "before",
                callback: async (signedTransaction) => {
                    const res = await mutationIntoResult(
                        createDbStrategy,
                        { ...dbParams, signedTransaction },
                        "create offer"
                    );

                    return res;
                },
                timeEstimateTotal: 3
            },
            transactionParams: {
                strategyIdentifier,
                amountFromWallet: escrowNeeded ? 0 : depositAmountLamports,
                amountFromEscrow: escrowNeeded ? depositAmountLamports : 0,
                originationCap: UNCAPPED_STRATEGY_AMOUNT,
                principalMint: token.assetMint,
                externalYieldSource: form.yieldSource
            },
            description
        });

        trackMixpanelEvent(transactionResults, {
            key: TrackTransactionEvent.SubmitMarketLend,
            params: trackSubmitMarketLend(
                { ...dbParams, signedTransaction: "" },
                escrowNeeded ? 0 : depositAmountLamports,
                escrowNeeded ? depositAmountLamports : 0,
                offerTermsMixpanel
            )
        });
        if (allTransactionsSucceeded(transactionResults)) {
            setForm(initialLendForm);
        }

        return transactionResults;
    };
}

const BORROW_ERRORS = {
    "Rate limit": "You're starting loans too quickly - please wait a few seconds and try again",
    "Offer is no longer available": "Quote is no longer available",
    "Borrow cap": "Your request exceeds the available borrow capacity for this market",
    "You can only borrow": "Your quote exceeds the global borrow limit per loan",
    "You have insufficient collateral": "Your quote exceeds your borrow limit"
};

export function useMarketBorrow() {
    const send = useTransactionSender();
    const fillOrder = useFillStrategyOfferTransaction();
    const { token, isDialog } = useMarketContext();

    const getTransactionGenerationType = useGetTransactionGenerationType();
    const { escrowNeeded } = useBorrowBalanceChecker();
    const { form, activeUserCollateral, bestQuote: quote, setForm } = useMarketBorrowContext();

    return async () => {
        if (!token || !form.amount || !form.collateralMint || !form.collateralAmount || !quote || !activeUserCollateral)
            return Result.errFromMessage(LOADING_ERROR);

        const transactionGenerationType = await getTransactionGenerationType();

        const isStaked = isStakedSol(form.collateralMint);
        const isWhirlpool = isWhirlpoolMetadata(activeUserCollateral.metadata);

        const amount = (() => {
            // wp positions always are balance of 1
            if (isWhirlpool) return 1;
            return form.collateralAmount;
        })();

        const collateral: StrategyCollateralInfoParams = {
            amountFromWallet: escrowNeeded ? 0 : amount,
            amountFromEscrow: escrowNeeded ? amount : 0,
            assetTermsIdentifier: quote.assetTermsIdentifier,
            assetTypeDiscriminator: (() => {
                if (isStaked) {
                    return AssetTypeIdentifier.StakedSol;
                }
                if (isWhirlpool) return AssetTypeIdentifier.OrcaPosition;
                return AssetTypeIdentifier.SplToken;
            })(),
            assetKey: (() => {
                const { stakeAccount, collateralMint } = form;
                if (isStaked && stakeAccount) {
                    return stakeAccount.stakeAccount;
                }
                if (isWhirlpool && form.orcaPosition) {
                    return form.orcaPosition.position.positionMint;
                }
                return collateralMint;
            })()
        };

        const params: FillStrategyOrderArgs = {
            strategyIdentifier: quote.lendingStrategyIdentifier,
            collateral: [collateral],
            principalDecimals: token.decimals,
            principalRequested: form.amount,
            apy: decimalsToBps(quote.apy),
            ltv: decimalsToBps(quote.ltv),
            liquidationThreshold: decimalsToBps(quote.liquidationThreshold),
            transactionGenerationType,
            useFillerEscrow: escrowNeeded,
            principalMint: token.assetMint,
            // not used for new orders
            lockboxAddress: null,
            refinancedOrder: null
        };

        return await send(fillOrder, params, {
            alerter: { generationEstimateSeconds: 8, showProgress: true },
            messageOverrides: { errorMessageMap: BORROW_ERRORS },
            sendOptions: {
                onSuccess: () =>
                    setForm((prev) => ({
                        ...prev,
                        amount: undefined,
                        collateralAmount: undefined,
                        mode: BorrowMode.InputCollateral,
                        ltvMultiplier: 0
                    }))
            },
            mixpanelEvent: {
                key: TrackTransactionEvent.SubmitMarketBorrowOrder,
                params: trackSubmitMarketBorrowOrder({
                    params,
                    duration: quote,
                    source: isDialog ? "dialog" : "detail"
                })
            }
        });
    };
}

const MAX_TOTAL_TICKS = 50;
const MAX_FILL_TICKS = 20;
const MIN_TICK_DENSITY = 10;
export function useRatesTicks({
    orderbookQuotes,
    tickSizeBps = 1
}: {
    orderbookQuotes: OrderbookQuote[] | undefined;
    tickSizeBps?: number;
}) {
    if (!orderbookQuotes) return undefined;

    const allTicksMap = orderbookQuotes.reduce((map, curr) => {
        if (map.size > MAX_TOTAL_TICKS) return map;

        // use bps to prevent JS underflows
        const apyBps = decimalsToBps(curr.apy);
        const tickBps = Math.floor(apyBps / tickSizeBps) * tickSizeBps;

        const prev = map.get(tickBps);

        const totalPrincipal = (prev?.totalPrincipal ?? 0) + curr.sumPrincipalAvailable;
        const maxPrincipal = Math.max(prev?.maxPrincipal ?? 0, curr.maxPrincipalAvailable);

        map.set(tickBps, { totalPrincipal, maxPrincipal });
        return map;
    }, new Map<number, { totalPrincipal: number; maxPrincipal: number }>());

    const minTick = Math.min(...allTicksMap.keys());
    const maxTick = Math.max(...allTicksMap.keys());

    const estimatedAdditionalTicks = (maxTick - minTick) / tickSizeBps;

    if (orderbookQuotes.length > MIN_TICK_DENSITY && estimatedAdditionalTicks < MAX_FILL_TICKS) {
        // fill any 0 amount ticks
        for (let tick = minTick; tick < maxTick; tick += tickSizeBps) {
            if (allTicksMap.size > MAX_TOTAL_TICKS) break;
            const prev = allTicksMap.get(tick);
            if (prev) continue;
            allTicksMap.set(tick, { totalPrincipal: 0, maxPrincipal: 0 });
        }
    }

    let cumulativePrincipal = 0;
    return Array.from(allTicksMap.entries())
        .sort((a, b) => a[0] - b[0])
        .map(([apy, { totalPrincipal, maxPrincipal }]): RateTick => {
            cumulativePrincipal += totalPrincipal;
            return {
                apy: bpsToUiDecimals(apy),
                totalPrincipal,
                maxPrincipal,
                cumulativePrincipal
            };
        })
        .filter((a) => !!a.maxPrincipal)
        .sort((a, b) => a.apy - b.apy);
}

export function isPresetSame(a: PresetStrategyTerms | undefined, b: PresetStrategyTerms | undefined) {
    if (!a || !b) return false;

    return a.presetStrategyIdentifier === b.presetStrategyIdentifier;
}

export function isStrategyDurationSame(a: StrategyDuration | undefined, b: StrategyDuration | undefined) {
    if (!a || !b) return false;

    return a.duration === b.duration && a.durationType === b.durationType;
}

export function getDefaultCollateral(principalMint: string | undefined) {
    return principalMint === JITO_SOL_MINT ? USDC_MINT : JITO_SOL_MINT;
}

export function useBestOffersByFromQuotes({
    allQuotes,
    collateralMint,
    presets
}: {
    allQuotes: BestQuote[] | undefined;
    collateralMint?: string;
    presets: PresetStrategyTerms[] | undefined;
}) {
    const quotes = useMemo(
        () => (collateralMint ? allQuotes?.filter((q) => q.collateralMint === collateralMint) : allQuotes),
        [collateralMint, allQuotes]
    );

    return useMemo(() => {
        if (!presets || !quotes) return undefined;
        return presets.map((preset) => {
            // not possible to use hashmap since there isn't a common key between presets/offers
            const matchingQuotes = quotes?.filter((q) => isStrategyDurationSame(q, preset));

            if (!matchingQuotes.length) return { preset, quote: undefined };
            const quote = matchingQuotes[0];

            return { quote, preset };
        });
    }, [presets, quotes]);
}

export function useMarketCapDetails() {
    const { borrowCap, principalStats: data, token } = useMarketContext();

    return useMemo(() => {
        if (!borrowCap || !token || !data) return undefined;
        const { principalUtilized } = data.principalStats;

        return getBorrowCapDetails({ borrowCap, token, principalUtilized });
    }, [borrowCap, data, token]);
}

export function useCollateralTokensByTag(tokens: BsMetadata[] | undefined) {
    const allCollateralWithPresets = useCollateralWithPresets(tokens);

    const tokensByTags = useMemo(() => {
        if (!allCollateralWithPresets) return undefined;
        const tagMap = new Map<TokenListTag, CollateralWithPreset[]>();
        const usedTokenMints = new Set();
        for (const asset of allCollateralWithPresets) {
            for (const tag of asset.metadata.tags ?? []) {
                if (!getTokenTagMetadata(tag)) continue;
                const prev = tagMap.get(tag) ?? [];
                usedTokenMints.add(asset.metadata.assetMint);
                tagMap.set(tag, [...prev, asset]);
            }
        }

        if (usedTokenMints.size !== allCollateralWithPresets.length) {
            tagMap.set(
                TokenListTag.Misc,
                allCollateralWithPresets.filter((t) => !usedTokenMints.has(t.metadata.assetMint))
            );
        }

        return Array.from(tagMap.entries())
            .map(([tag, tokens]) => ({
                tokens: tokens.sort((a, b) =>
                    BsMetaUtil.getSymbol(a.metadata).localeCompare(BsMetaUtil.getSymbol(b.metadata))
                ),
                tag: getTokenTagMetadata(tag)
            }))
            .filter(filterNullableRecord)
            .filter(({ tag }) => !tag.hideInCategories)
            .sort((a, b) => a.tag.sortPosition - b.tag.sortPosition);
    }, [allCollateralWithPresets]);

    return { tokensByTags, allCollateralWithPresets };
}

export function useMarketCollateral() {
    const marketProps = useMarketContext();
    return useMarketCollateralFromProps(marketProps);
}

export function useMarketCollateralFromProps({ principalMint, custodianIdentifier, isCustodian }: MarketProps) {
    const collateral = useSupportedCollateral(principalMint);

    if (isCustodian) {
        if (!custodianIdentifier) return undefined;
        return collateral?.filter((c) => c.assetOriginator === custodianIdentifier);
    }

    return collateral;
}

const selectNapoleonApiQueries = (state: RootState) => state.napoleonPublicApi.queries;

const allowedEndpoints = ["maxQuotes", "bestQuotes"];
const selectFetches = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some(
        (e) => e?.status !== QueryStatus.fulfilled && e?.endpointName && allowedEndpoints.includes(e?.endpointName)
    )
);

const selectMaxQuotes = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some((e) => e?.status !== QueryStatus.fulfilled && e?.endpointName === "maxQuotes")
);

export function useNapoleonFetching() {
    return useSelector(selectFetches);
}

export function useMaxQuotesFetching() {
    return useSelector(selectMaxQuotes);
}

const selectErrors = createSelector([selectNapoleonApiQueries], (queries) =>
    Object.values(queries).some((e) => e?.error && allowedEndpoints.includes(e.endpointName))
);
export function useNapoleonErrors() {
    return useSelector(selectErrors);
}

export function useOpenGuideOnLoad({ view }: { view: RoleView }) {
    const { open } = useMarketDialog();
    const { isDialog } = useMarketContext();
    const { steps, isLoading } = useUserOnboardingStep([OnboardingStep.Borrow, OnboardingStep.Lend]);

    useEffect(() => {
        // if the dialog is already open, don't open it again

        if (isLoading) return;

        if (view === RoleView.Borrow && !steps.includes(OnboardingStep.Borrow)) {
            return open(AppDialog.MarketGuide, { mode: MarketGuideMode.Borrow, isForced: true });
        }

        if (view === RoleView.Lend && !steps.includes(OnboardingStep.Lend)) {
            return open(AppDialog.MarketGuide, { mode: MarketGuideMode.Lend, isForced: true });
        }
    }, [isDialog, isLoading, open, steps, view]);
}

// Wrap app dialog to support seamless internal dialogs
export function useMarketDialog() {
    const marketContext = useMarketContextOptional();
    const appDialog = useAppDialog();
    const isDialog = !!marketContext?.isDialog;

    function open<T extends AppDialog>(type: T, data: AppDialogData<T>) {
        if (marketContext?.isDialog) {
            return marketContext.setInternalOpenDialog({ type, data });
        }
        return appDialog.open(type, data);
    }

    function close() {
        if (marketContext?.isDialog) {
            return marketContext.setInternalOpenDialog(null);
        }
        return appDialog.close();
    }

    function getData<T extends AppDialog>(): AppDialogData<T> | undefined {
        if (marketContext?.isDialog) {
            if (!marketContext.internalOpenDialog) return undefined;
            return marketContext.internalOpenDialog.data as AppDialogData<T>;
        }
        return appDialog.getData<T>();
    }

    return { open, close, getData, isDialog };
}

export function useSelectMarketPrincipal() {
    const { open } = useMarketDialog();
    const { selectToken, view } = useMarketContext();
    const { tokens } = useBsPrincipalTokens();
    const { trackWithProps } = useTrack();

    if (!selectToken || !tokens) return null;

    const selectTokenWithTracking = (token: BsMetadata): void => {
        trackWithProps(TrackEventWithProps.SelectBorrowPrincipal, {
            tokenSymbol: BsMetaUtil.getSymbol(token)
        });
        selectToken(token);
    };

    return () =>
        open(AppDialog.SelectToken, {
            header: `Select token to ${view.toLowerCase()}`,
            tokens,
            setToken: selectTokenWithTracking
        });
}

export function usePreserveMarketData() {
    const { token, view } = useMarketContext();
    const { open, close } = useAppDialog();

    const detailPath = getMarketPath({ token, view, navigateTo: "detail" });
    const marketsPath = getMarketPath({ token, view, navigateTo: "markets" });

    const onDetailNavigate = useCallback(() => {
        close();
    }, [close]);

    const onMarketsNavigate = useCallback(() => {
        if (!token) return;
        if (view === RoleView.Borrow) {
            open(AppDialog.Borrow, { token });
        } else {
            open(AppDialog.Lend, { token });
        }
    }, [open, token, view]);

    return { detailPath, marketsPath, onDetailNavigate, onMarketsNavigate };
}

// id: number;
// presetStrategyIdentifier: string;
// duration: number;
// durationType: number;
// repaymentRrule: string;
// maxOutstandingPayments: number;
// allowEarlyRepayments: boolean;
function presetFormToMixpanelFormat(
    presetToApy: Map<string, number | undefined>,
    allPresets: PresetTerms[] | undefined
): FormattedOfferTerms {
    const filteredPresets: PresetTerms[] = [];
    for (const [preset] of presetToApy) {
        const currPresets = allPresets?.filter((v) => v.offerTerms.presetOfferIdentifier === preset);
        if (currPresets) {
            filteredPresets.push(...currPresets);
        }
    }

    const collateral: string[] = [];
    const terms: { [key: string]: number } = {};

    filteredPresets.forEach((preset) => {
        if (!collateral.includes(preset.offerTerms.collateralMint)) {
            collateral.push(preset.offerTerms.collateralMint);
        }

        const formattedDuration = formatDurationWithType(
            preset.strategyTerms.duration,
            preset.strategyTerms.durationType
        );

        const apy = presetToApy.get(preset.offerTerms.presetOfferIdentifier);
        if (apy !== undefined && !terms[formattedDuration]) {
            terms[formattedDuration] = percentDecimalsToUi(apy);
        }
    });

    return {
        collateral,
        terms
    };
}
