import { useState } from "react";

import { BsMetaUtil, MarketGuideMode, RoleView } from "@bridgesplit/abf-react";
import { DialogWrapper } from "@bridgesplit/ui";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";

import { MarketDialogHeader, MarketProvider, QuotesProvider } from "./common";
import { LendCard, MarketLendProvider } from "./lend";
import { MarketDialogWrapper } from "./dialog";

export default function LendDialog() {
    const { getData } = useAppDialog();
    const data = getData<AppDialog.Lend>();

    const [tokenOverride, setToken] = useState<BsMetadata>();

    const token = tokenOverride ?? data?.token;
    const principalMint = token?.assetMint;

    return (
        <MarketProvider
            marketProps={{
                isDialog: true,
                token,
                selectToken: setToken,
                symbol: BsMetaUtil.getSymbol(token),
                loading: false,
                principalMint,
                view: RoleView.Lend
            }}
        >
            <QuotesProvider>
                <MarketLendProvider>
                    <DialogWrapper>
                        <MarketDialogWrapper>
                            <MarketDialogHeader mode={MarketGuideMode.Lend} header="Lend" />
                            <LendCard />
                        </MarketDialogWrapper>
                    </DialogWrapper>
                </MarketLendProvider>
            </QuotesProvider>
        </MarketProvider>
    );
}
