import { BsMetaUtil, useMaxQuotesForCollateral, CollateralWithMaxQuote } from "@bridgesplit/abf-react";
import { createRows, TableColumn, Row, Table, Text, Column } from "@bridgesplit/ui";
import { formatPercent, formatUsd } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { Radio } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { AppParams } from "app/utils";

import { TokenImage } from "../../common";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { useMarketContext } from "../common";
import { useMarketBorrowSetCollateral } from "./util";

type CollateralRow = CollateralWithMaxQuote & {
    principalMetadata: BsMetadata | undefined;
    isSelected: boolean;
    select: () => void;
};
export default function BorrowCollateralOverview() {
    const { userCollateral, form } = useMarketBorrowContext();
    const { principalMint, token } = useMarketContext();
    const [params] = useSearchParams();

    const { data: collateralWithMaxQuotes } = useMaxQuotesForCollateral({
        principalMint,
        duration: form.preset?.duration,
        durationType: form.preset?.durationType,
        collateral: userCollateral
    });
    const setCollateral = useMarketBorrowSetCollateral();

    const data = collateralWithMaxQuotes
        ?.map(
            (q): CollateralRow => ({
                ...q,
                principalMetadata: token,
                select: () => setCollateral(q),
                isSelected: form.collateralMint === q.mint || params.get(AppParams.CollateralMint) === q.mint
            })
        )
        .filter((d) => d.maxQuote);

    return (
        <Table
            onRowClick={({ data }) => data.select()}
            paginationProps={{
                pageSize: 5,
                type: "managed",
                defaultSortKey: "limit"
            }}
            emptyPlaceholder="You don't own any eligible collateral"
            loading={!data}
            rows={createRows(data, (s) => s.metadata.assetMint)}
            columns={columns}
        />
    );
}

const columns: TableColumn<CollateralRow>[] = [
    {
        dataIndex: "select",
        title: "",
        width: "10%",
        render: ({ data: { isSelected, select } }) => (
            <Radio checked={isSelected} onChange={isSelected ? select : undefined} />
        )
    },
    {
        dataIndex: "asset",
        title: "Asset",
        width: "30%",
        render: ({ data: { metadata, maxQuote } }) => (
            <Row spacing={2}>
                <TokenImage metadata={metadata} size="lg" />
                <Column>
                    <Text>{BsMetaUtil.getSymbol(metadata)}</Text>
                    <Text color="caption" variant="body2">
                        {formatPercent(maxQuote?.ltv)} LTV
                    </Text>
                </Column>
            </Row>
        ),
        sortBy: ({ data: { metadata } }) => BsMetaUtil.getSymbol(metadata)
    },
    {
        dataIndex: "balance",
        title: "Balance",
        align: "right",
        width: "30%",
        render: ({ data: { metadata, amount, oracle } }) => {
            return (
                <Column alignItems="flex-end">
                    <Text>{BsMetaUtil.formatAmount(metadata, amount, { hideSymbol: true })}</Text>
                    <Text color="caption" variant="body2">
                        {formatUsd(oracle?.getUsdAmount(amount))}
                    </Text>
                </Column>
            );
        },
        sortBy: ({ data: { amount, oracle } }) => oracle?.getUsdAmount(amount) ?? 0
    },

    {
        dataIndex: "limit",
        title: "Borrow limit",
        align: "right",
        width: "30%",
        render: ({ data: { principalMetadata, maxQuote } }) => (
            <Column alignItems="flex-end">
                <Text>{BsMetaUtil.formatAmount(principalMetadata, maxQuote?.maxBorrow)}</Text>
                <Text color="caption" variant="body2">
                    {formatPercent(maxQuote?.apy)} APY
                </Text>
            </Column>
        ),
        sortBy: ({ data: { maxQuote } }) => maxQuote?.maxBorrow ?? 0
    }
];
