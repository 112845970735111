import { useMemo } from "react";

import { AbfRole, useActiveWallet, useMpcStatus, useGroup, useVerifyWalletSetup } from "@bridgesplit/abf-react";
import { useUserPublicKey } from "@bridgesplit/react";

import { WalletConnectError } from "./type";

export function useConnectingWalletErrors() {
    const { permissions } = useGroup();
    const { activeWallet, activePubkeysSet, isLoading, isMpcActive } = useActiveWallet();
    const { verified, activeCredential } = useMpcStatus();

    const pubkey = useUserPublicKey();
    const { data: walletVerification } = useVerifyWalletSetup(pubkey ?? activeWallet?.wallet);

    const error: WalletConnectError = useMemo(() => {
        if (isLoading) return WalletConnectError.None;
        if (isMpcActive) {
            if (!activeCredential && !verified) return WalletConnectError.PasskeyError;
            return WalletConnectError.None;
        }
        if (!pubkey) return WalletConnectError.NotConnected;

        if (!activePubkeysSet.has(pubkey)) return WalletConnectError.NotRegistered;
        if (
            walletVerification &&
            !permissions?.has(AbfRole.SuperAdmin) &&
            (!walletVerification.isVerified || !walletVerification.onChainRoles?.length)
        )
            return WalletConnectError.NotApprovedByAdmin;
        return WalletConnectError.None;
    }, [isLoading, isMpcActive, pubkey, activePubkeysSet, walletVerification, permissions, activeCredential, verified]);

    return { error, isLoading };
}
