import { BS_METADATA_API, fetchAndDeserializeLegacyTransactions } from "@bridgesplit/utils";

import { AbfTransactionAuthentication, BS_METADATA_EVM_UPLOAD_ENDPOINT, OptimisticEvmUploadArgs } from "../types";
import { createAbfInstance } from "./auth";

export async function getOptimisticEvmUploadTransaction(
    authentication: AbfTransactionAuthentication,
    args: OptimisticEvmUploadArgs[]
) {
    return await fetchAndDeserializeLegacyTransactions(
        createAbfInstance(authentication, BS_METADATA_API),
        BS_METADATA_EVM_UPLOAD_ENDPOINT,
        args
    );
}
