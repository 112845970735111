import { useMemo } from "react";

import {
    DEFAULT_PRESET,
    MarketExpanded,
    RoleView,
    StrategyDuration,
    getBorrowCapDetails
} from "@bridgesplit/abf-react";
import { bsMath } from "@bridgesplit/utils";
import { Column, DynamicGrid, MEDIA, MediaWrapper, repeatElement } from "@bridgesplit/ui";

import PrincipalMarketsTable from "./PrincipalMarketsTable";
import PrincipalMarketCard from "./PrincipalMarketCard";
import { TableCardWrapper } from "../../common";

const QUERY = MEDIA.LG;
export default function PrincipalMarkets({ markets, view }: { markets: MarketExpanded[] | undefined; view: RoleView }) {
    const selectedDuration = DEFAULT_PRESET;
    const marketStats = usePrincipalMarketStats(markets, selectedDuration);

    return (
        <Column spacing={2}>
            <MediaWrapper query={QUERY.below}>
                <DynamicGrid minImageWidth="300px">
                    {!marketStats &&
                        repeatElement(
                            <PrincipalMarketCard
                                selectedDuration={selectedDuration}
                                marketStats={undefined}
                                view={view}
                            />
                        )}
                    {marketStats?.map((market) => (
                        <PrincipalMarketCard
                            key={market.metadata.assetMint}
                            selectedDuration={selectedDuration}
                            marketStats={market}
                            view={view}
                        />
                    ))}
                </DynamicGrid>
            </MediaWrapper>
            <MediaWrapper query={QUERY.above}>
                <TableCardWrapper>
                    <PrincipalMarketsTable view={view} marketStats={marketStats} />
                </TableCardWrapper>
            </MediaWrapper>
        </Column>
    );
}

export function usePrincipalMarketStats(markets: MarketExpanded[] | undefined, selectedDuration: StrategyDuration) {
    return useMemo(() => {
        if (!markets) return undefined;

        return markets.map(({ principalStats, strategyStats, metadata, usdPrice, yieldVault, borrowCap }) => {
            const { principalUtilized } = principalStats;
            const { totalDeposits, durationToMinApy } = strategyStats;

            const apy = durationToMinApy.find(
                (d) => d.duration === selectedDuration?.duration && d.durationType === selectedDuration.durationType
            )?.apy;

            const available = totalDeposits;
            const borrowCapWarning = getBorrowCapDetails({
                borrowCap,
                token: metadata,
                principalUtilized
            }).warningMessage;

            return {
                metadata: metadata,
                available: available,
                availableUsd: bsMath.mul(available, usdPrice),
                borrowed: principalUtilized,
                borrowedUsd: bsMath.mul(principalUtilized, usdPrice),
                apy,
                yieldVault,
                borrowCapWarning
            };
        });
    }, [markets, selectedDuration?.duration, selectedDuration?.durationType]);
}
