import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { LoopInfoParams, LoopVaultInfo } from "@bridgesplit/abf-sdk";

import { abfSerializeQueryArgs, napoleonPublicBaseQuery } from "./util";
import { ROUTE_GET_LOOP_VAULTS_INFO } from "../constants";

const LOOP_POSITIONS_TAG = "LoopPositions";

export const loopPublicApi = createApi({
    reducerPath: "loopApiPublic",
    tagTypes: [LOOP_POSITIONS_TAG],
    baseQuery: napoleonPublicBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        loopInfos: builder.query<{ [loopVaultAddress: string]: LoopVaultInfo }, LoopInfoParams>({
            query: (body) => ({
                url: ROUTE_GET_LOOP_VAULTS_INFO,
                method: "POST",
                body
            }),
            transformResponse: (response: {
                [loopVaultAddress: string]: Omit<LoopVaultInfo, "tags"> & { tags: string };
            }) => {
                return Object.fromEntries(
                    Object.entries(response).map(([key, value]) => {
                        return [key, { ...value, tags: value.tags ? JSON.parse(value.tags) : [] }];
                    })
                );
            }
        })
    })
});

export const { useLoopInfosQuery } = loopPublicApi;

export const useLoopPublicApi = () => {
    const dispatch = useDispatch();

    return {
        resetLoopApiPublic: () => dispatch(loopPublicApi.util.invalidateTags([LOOP_POSITIONS_TAG]))
    };
};
