import { useMemo, useState } from "react";

import {
    BsMetaUtil,
    getLoanStrategyIdentifier,
    parseOrcaAmounts,
    useWhirlpoolClaimFeeTransaction
} from "@bridgesplit/abf-react";
import {
    Column,
    Icon,
    OutlinedCard,
    PopoverWrapper,
    Row,
    StatColumn,
    StatProps,
    Text,
    TextButton,
    TextVariant,
    usePopover
} from "@bridgesplit/ui";
import {
    AppButton,
    PriceRangeStatus,
    RefreshButton,
    Slippage,
    TokenAmountWithUsd,
    TokenImage
} from "app/components/common";
import { bpsToUiDecimals, formatPercent, formatUsd, LOADING_ERROR, Result } from "@bridgesplit/utils";
import { ExpandLess, ExpandMore, GrassOutlined, SwapHorizOutlined } from "@mui/icons-material";
import { useTransactionSender } from "app/components/transactions";
import { Collapse, Divider } from "@mui/material";

import { useWhirlpoolContext } from "./WhirlpoolContext";
import { AddressCopy, TickWithPercentChange } from "./common";
import { getYield, useWhirlpoolFormatPrice } from "./util";

export default function WhirlpoolInfo() {
    return (
        <>
            <Header />
            <Column spacing={1}>
                <WhirlpoolFlipPrice />
                <ManageWhirlpool />
            </Column>
            <PricesStats />
        </>
    );
}

function Header() {
    const { whirlpoolPosition, slippageController } = useWhirlpoolContext();

    return (
        <Row spaceBetween spacing={2}>
            <Row spacing={1}>
                <TokenImage size="md" metadata={whirlpoolPosition.whirlpoolMetadata} />
                <Text variant="h4">
                    {BsMetaUtil.getSymbol(whirlpoolPosition.tokenA.metadata)}-
                    {BsMetaUtil.getSymbol(whirlpoolPosition.tokenB.metadata)}
                </Text>
                <Text color="caption">
                    {formatPercent(bpsToUiDecimals(whirlpoolPosition.whirlpool.feeRate), {
                        minimumSignificantDigits: 1
                    })}
                </Text>
                <Addresses />
            </Row>
            <Row spacing={1}>
                <Slippage.Button {...slippageController.slippageButtonProps} />
                <RefreshWhirlpoolButton />
            </Row>
        </Row>
    );
}

function useYieldData() {
    const { whirlpoolPosition, positionData, poolData, whirlpoolOffchain } = useWhirlpoolContext();
    const positionUsdValue = whirlpoolPosition.totalPrice;
    return useMemo(() => {
        if (!positionData || !poolData || !whirlpoolOffchain) return undefined;
        return getYield({
            positionLiquidity: positionData.liquidity,
            poolLiquidity: poolData.liquidity,
            whirlpoolOffchain,
            positionUsdValue
        });
    }, [poolData, positionData, positionUsdValue, whirlpoolOffchain]);
}

function ManageWhirlpool() {
    const yieldData = useYieldData();

    const stats: StatProps[] = [
        {
            caption: "Pending yield",
            value: <ClaimFeesButton />
        }
    ];
    if (yieldData) {
        stats.push({
            caption: "Estimated 24h yield",
            value: formatPercent(yieldData.positionYieldOverTvl24h)
        });
    }

    return (
        <>
            <DepositAmounts />
            <StatColumn stats={stats} />
        </>
    );
}

function PricesStats() {
    const { positionData, poolData, isFlippedPrice } = useWhirlpoolContext();
    const formatPrice = useWhirlpoolFormatPrice();

    const variant: TextVariant = "body2";
    const stats: StatProps[] = [
        {
            caption: isFlippedPrice ? "Max price" : "Min price",
            value: (
                <TickWithPercentChange
                    variant={variant}
                    tick={positionData?.tickLowerIndex}
                    tickLower={positionData?.tickLowerIndex}
                    tickUpper={positionData?.tickUpperIndex}
                />
            )
        },
        {
            caption: "Current price",
            value: (
                <>
                    <Text loadingWidth="40px" loading={!poolData} variant={variant}>
                        {formatPrice(poolData?.tickCurrentIndex, 6)}
                    </Text>
                    <PriceRangeStatus
                        variant="caption"
                        tickLower={positionData?.tickLowerIndex}
                        tickUpper={positionData?.tickUpperIndex}
                        tickCurrentIndex={poolData?.tickCurrentIndex}
                    />
                </>
            )
        },
        {
            caption: isFlippedPrice ? "Min price" : "Max price",
            value: (
                <TickWithPercentChange
                    variant={variant}
                    tick={positionData?.tickUpperIndex}
                    tickLower={positionData?.tickLowerIndex}
                    tickUpper={positionData?.tickUpperIndex}
                />
            )
        }
    ];
    if (isFlippedPrice) {
        stats.reverse();
    }

    return (
        <OutlinedCard>
            <Row sx={{ alignItems: "flex-start" }}>
                {stats.map((s, i) => (
                    <Column
                        flexGrow={1}
                        key={i}
                        spacing={0.5}
                        alignItems={(() => {
                            if (i === 0) return "flex-start";
                            if (i === 2) return "flex-end";
                            return "center";
                        })()}
                    >
                        <Text color="caption" variant="caption">
                            {s.caption}
                        </Text>
                        {s.value}
                    </Column>
                ))}
            </Row>
        </OutlinedCard>
    );
}

function DepositAmounts() {
    const { whirlpoolPosition } = useWhirlpoolContext();
    const [expanded, setExpanded] = useState(false);

    return (
        <Column>
            <Row spaceBetween>
                <Text color="caption">Deposits</Text>
                <Row sx={{ cursor: "pointer" }} onClick={() => setExpanded((prev) => !prev)}>
                    <Text>{formatUsd(whirlpoolPosition.totalPrice)}</Text>
                    <TextButton color="disabled">{expanded ? <ExpandLess /> : <ExpandMore />}</TextButton>
                </Row>
            </Row>
            <Collapse in={expanded}>
                <Column mt={1}>
                    <StatColumn
                        stats={[whirlpoolPosition.tokenA, whirlpoolPosition.tokenB].map((t) => ({
                            caption: BsMetaUtil.getSymbol(t.metadata),
                            value: <TokenAmountWithUsd amount={t.amount} token={t.metadata} amountUsd={t.usdAmount} />
                        }))}
                    />
                </Column>
            </Collapse>
        </Column>
    );
}

function Addresses() {
    const { open, popoverProps } = usePopover();
    const { whirlpoolPosition } = useWhirlpoolContext();

    const variant: TextVariant = "body2";
    const positionStats: StatProps[] = [
        {
            caption: "Position address",
            value: <AddressCopy variant={variant} address={whirlpoolPosition?.position.address} />
        },
        {
            caption: "Whirlpool NFT",
            value: <AddressCopy variant={variant} address={whirlpoolPosition?.position.positionMint} />
        }
    ];

    const poolStats: StatProps[] = [
        {
            caption: "Pool address",
            value: <AddressCopy variant={variant} address={whirlpoolPosition?.whirlpoolAddress} />
        },
        {
            caption: BsMetaUtil.getSymbol(whirlpoolPosition.tokenA.metadata),
            value: <AddressCopy variant={variant} address={whirlpoolPosition?.tokenA.mint} />
        },
        {
            caption: BsMetaUtil.getSymbol(whirlpoolPosition.tokenB.metadata),
            value: <AddressCopy variant={variant} address={whirlpoolPosition?.tokenB.mint} />
        }
    ];

    return (
        <>
            <TextButton color="caption" onClick={open}>
                <Icon type="tooltip" />
            </TextButton>
            <PopoverWrapper {...popoverProps}>
                <Column sx={{ minWidth: "250px" }} spacing={1} p={2}>
                    <StatColumn variant={variant} captionVariant={variant} stats={positionStats} />
                    <Divider />
                    <StatColumn variant={variant} captionVariant={variant} stats={poolStats} />
                </Column>
            </PopoverWrapper>
        </>
    );
}

function ClaimFeesButton() {
    const claimFees = useWhirlpoolClaimFeeTransaction();
    const send = useTransactionSender();

    const { whirlpoolPosition, loanExpanded } = useWhirlpoolContext();

    const { position } = whirlpoolPosition;

    const claimableFeesUsd = parseOrcaAmounts(whirlpoolPosition, position.feeAmounts).reduce(
        (prev, curr) => prev + curr.usdAmount,
        0
    );

    async function submit() {
        if (!loanExpanded) return Result.errFromMessage(LOADING_ERROR);
        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Can't claim fees on a Prime loan");
        return await send(claimFees, { loan: loanExpanded.loan.address, strategyIdentifier });
    }

    return (
        <Row spacing={1}>
            <Text>{formatUsd(claimableFeesUsd)}</Text>
            <AppButton
                variant="text"
                sx={{ ":hover": { background: "none", opacity: 0.6 } }}
                color="success"
                isTransaction
                asyncCta={{
                    onClickWithResult: submit
                }}
            >
                Harvest <GrassOutlined />
            </AppButton>
        </Row>
    );
}

function RefreshWhirlpoolButton() {
    const { poll } = useWhirlpoolContext();

    return <RefreshButton refresh={poll} />;
}

function WhirlpoolFlipPrice() {
    const {
        isFlippedPrice,
        setIsFlippedPrice,
        whirlpoolPosition: { tokenA, tokenB }
    } = useWhirlpoolContext();

    const tokens = isFlippedPrice ? [tokenB, tokenA] : [tokenA, tokenB];

    return (
        <Row spaceBetween spacing={1}>
            <Text variant="h4">Your position</Text>
            <TextButton color="caption" onClick={() => setIsFlippedPrice((prev) => !prev)}>
                {BsMetaUtil.getSymbol(tokens[0].metadata)}/{BsMetaUtil.getSymbol(tokens[1].metadata)}
                <SwapHorizOutlined />
            </TextButton>
        </Row>
    );
}
