import React from "react";

import { Error404Page, lazy } from "@bridgesplit/ui";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import {
    ASSET_SLUG,
    CUSTODIANS_SLUG,
    CUSTODIAN_ADMIN_PERMISSIONS_SLUG,
    ORGANIZATION_CREATE_SLUG,
    ORGANIZATION_SETTINGS_SLUG,
    CREATE_BORROW_REQUEST_SLUG,
    LOAN_SLUG,
    SIGNUP_SLUG,
    REQUEST_SLUG,
    SYNDICATED_OFFER_SLUG,
    MAKE_OFFER_SLUG,
    EXPLORE_REQUESTS_SLUG,
    PORTFOLIO_OVERVIEW_SLUG,
    CUSTODIAN_CODE_INVITE_SLUG,
    CUSTODIAN_SPLASH_SLUG,
    STRATEGY_LOANS_SLUG,
    ACCOUNT_SETTINGS_SLUG,
    UBO_REGISTER_SLUG,
    BANK_TRANSFERS_SLUG,
    REWARDS_SLUG,
    TWITTER_SLUG,
    DISCORD_SLUG,
    LOCKBOX_CLAIM_SLUG,
    MARKET_SLUG,
    PRIME_SLUG,
    EXPLORE_COLLATERAL_SLUG,
    LOOP_DETAIL_SLUG,
    BORROW_SLUG,
    EARN_SLUG,
    MARKETS_SLUG,
    LEND_SLUG,
    LOOPS_SLUG
} from "app/constants";

import { NavigateListener, AppFallback } from "./wrappers";
import { CreateGroup, CustodianPermissions } from "./group";
import { PortfolioOverview } from "./portfolio";
import { CustodianInviteCode, SignUp } from "./auth";
import { StrategyLoansDetail } from "./strategy";
import { AccountSettings } from "./account-settings";
import { UboRegister } from "./banks";
import { CustodianDetail } from "./market-detail";
import BankTransfersOverview from "./banks/BankTransfersOverview";
import OrganizationSettings from "./group/OrganizationSettings";
import { Rewards, DiscordCallback, TwitterCallback } from "./points";
import { ExploreMarkets, ExploreCustodians, ExploreRequests, ExploreLoops } from "./explore";
import { LoanDetail } from "./loan";
import { LockboxAssets } from "./assets";
import MarketDetail from "./market-detail/MarketDetail";
import { LoopDetail } from "./loop";

const AppDialogs = lazy(() => import("./dialogs"));
const GlobalDialogs = lazy(() => import("./global-dialogs"));

const Asset = lazy(() => import("./asset"));

const CreateBorrowRequest = lazy(() => import("./create-request"));
const Request = lazy(() => import("./request"));
const Testing = lazy(() => import("./testing"));
const SyndicatedOffer = lazy(() => import("./syndicated"));
const MakeOffer = lazy(() => import("./make-offer"));
const CustodianSplash = lazy(() => import("./custodian-splash"));

export default function Routes() {
    return (
        <React.Suspense fallback={<AppFallback />}>
            <NavigateListener />

            <AppDialogs />
            <GlobalDialogs />

            <RouterRoutes>
                <Route path={`${CUSTODIAN_SPLASH_SLUG}/:slug`} element={<CustodianSplash />} />
                <Route path={SIGNUP_SLUG} element={<SignUp />} />
                <Route path={BORROW_SLUG} element={<Navigate to={MARKETS_SLUG} />} />
                <Route path={EARN_SLUG} element={<Navigate to={LOOPS_SLUG} />} />
                <Route path={LEND_SLUG} element={<Navigate to={MARKETS_SLUG} />} />
                <Route path={EXPLORE_COLLATERAL_SLUG} element={<Navigate to={MARKETS_SLUG} />} />
                <Route path="/" element={<Navigate to={MARKETS_SLUG} />} />

                <Route path={"/prime"} element={<Navigate to={PRIME_SLUG} />} />
                <Route path="/waitlist" element={<Navigate to={REWARDS_SLUG} />} />

                {/* Authenticated */}
                <Route path="testing" element={<Testing />} />
                <Route path={REWARDS_SLUG} element={<Rewards />} />
                <Route path={TWITTER_SLUG} element={<TwitterCallback />} />
                <Route path={DISCORD_SLUG} element={<DiscordCallback />} />

                <Route path={MARKETS_SLUG} element={<ExploreMarkets />} />
                <Route path={LOOPS_SLUG} element={<ExploreLoops />} />

                {/* Explore */}
                <Route path={CUSTODIANS_SLUG} element={<ExploreCustodians />} />
                <Route path={EXPLORE_REQUESTS_SLUG} element={<ExploreRequests />} />
                <Route path={`${CUSTODIANS_SLUG}/:slug`} element={<CustodianDetail />} />
                <Route path={`${MARKET_SLUG}/:symbol`} element={<MarketDetail />} />

                {/* Loops */}
                <Route path={`${LOOP_DETAIL_SLUG}/:address`} element={<LoopDetail />} />

                {/* Portfolio */}
                <Route path={PORTFOLIO_OVERVIEW_SLUG} element={<PortfolioOverview />} />
                <Route path={CREATE_BORROW_REQUEST_SLUG} element={<CreateBorrowRequest />} />
                {/* Asset */}
                <Route path={`${ASSET_SLUG}/:mint`} element={<Asset />} />
                <Route path={`${LOAN_SLUG}/:address`} element={<LoanDetail />} />
                {/* Requests */}
                <Route path={`${REQUEST_SLUG}/:nonce`} element={<Request />} />
                <Route path={`${MAKE_OFFER_SLUG}/:nonce`} element={<MakeOffer />} />
                <Route path={`${SYNDICATED_OFFER_SLUG}/:address`} element={<SyndicatedOffer />} />
                {/* Strategies */}
                <Route path={`${STRATEGY_LOANS_SLUG}/:escrow`} element={<StrategyLoansDetail />} />
                {/* Accounts */}
                <Route path={CUSTODIAN_ADMIN_PERMISSIONS_SLUG} element={<CustodianPermissions />} />
                <Route path={ORGANIZATION_CREATE_SLUG} element={<CreateGroup />} />
                <Route path={ORGANIZATION_SETTINGS_SLUG} element={<OrganizationSettings />} />
                <Route path={ACCOUNT_SETTINGS_SLUG} element={<AccountSettings />} />
                <Route path={`${UBO_REGISTER_SLUG}/:group/:user`} element={<UboRegister />} />
                <Route path={`${BANK_TRANSFERS_SLUG}`} element={<BankTransfersOverview />} />
                <Route path={`${CUSTODIAN_CODE_INVITE_SLUG}/:code`} element={<CustodianInviteCode />} />
                <Route path={LOCKBOX_CLAIM_SLUG} element={<LockboxAssets />} />
                <Route path={"*"} element={<Error404Page />} />
            </RouterRoutes>
        </React.Suspense>
    );
}
