import {
    AbfLoanExpanded,
    AbfPermissionForCustodian,
    formatTokens,
    getLoanCompletedDate,
    getLoanTotalFees,
    useLoanWithdrawTransaction,
    getLoanCustodianIdentifiers
} from "@bridgesplit/abf-react";
import { StatColumn, Text } from "@bridgesplit/ui";
import { formatDate } from "@bridgesplit/utils";

import { ActionProps } from "./types";
import { AppButton } from "../../common";
import {
    useCompletedLoanDescription,
    useLoanCompleteData,
    useLoanLockboxAssets,
    useWithdrawLoanCredit,
    useWithdrawLoanToken
} from "../util";

export default function CompleteLoan({ view, loanExpanded }: ActionProps) {
    const { claimNeeded } = useLoanLockboxAssets(loanExpanded);
    const { creditClaimed } = useLoanCompleteData(loanExpanded);

    const description = useCompletedLoanDescription(loanExpanded);

    return (
        <>
            {description && (
                <Text variant="body2" color="success">
                    {description}
                </Text>
            )}

            <StatColumn
                stats={[
                    {
                        caption: "Repaid",
                        value: formatDate(getLoanCompletedDate(loanExpanded), "relative")
                    },
                    {
                        caption: "Collateral",
                        value: formatTokens(
                            loanExpanded?.collateral.filter((c) => ({ amount: c.lockboxAmount, metadata: c.metadata }))
                        )
                    },
                    { caption: "Repayments", value: creditClaimed ? "Claimed" : "Unclaimed", hide: view === "borrower" }
                ]}
            />

            {view === "borrower" && claimNeeded && loanExpanded && (
                <BorrowerClaimCollateral loanExpanded={loanExpanded} />
            )}
            {view === "lender" && !creditClaimed && loanExpanded && (
                <LenderClaimRepayment loanExpanded={loanExpanded} />
            )}
        </>
    );
}

function LenderClaimRepayment({ loanExpanded }: { loanExpanded: AbfLoanExpanded }) {
    const claim = useWithdrawLoanCredit(loanExpanded);

    return (
        <AppButton
            isTransaction
            authentication={{
                permission: AbfPermissionForCustodian.ClaimRepayment,
                custodians: getLoanCustodianIdentifiers(loanExpanded)
            }}
            fullWidth
            asyncCta={{ onClickWithResult: claim }}
            variant="contained"
        >
            Claim repayments
        </AppButton>
    );
}

function BorrowerClaimCollateral({ loanExpanded }: { loanExpanded: AbfLoanExpanded }) {
    const loanWithdraw = useLoanWithdrawTransaction();
    const withdraw = useWithdrawLoanToken({ loanExpanded, withdrawTransaction: loanWithdraw, side: "debt" });

    const totalFees = getLoanTotalFees(loanExpanded);

    return (
        <AppButton
            isTransaction
            disabled={totalFees > 0}
            authentication={{
                permission: AbfPermissionForCustodian.WithdrawDebtNote,
                custodians: getLoanCustodianIdentifiers(loanExpanded)
            }}
            fullWidth
            asyncCta={{ onClickWithResult: withdraw }}
            variant="contained"
        >
            Claim collateral
        </AppButton>
    );
}
