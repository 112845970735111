import Routes from "./components/Routes";
import { AppWrapper, Providers } from "./components/wrappers";
import { initMixpanel } from "./hooks";

initMixpanel();
export function App() {
    return (
        <Providers>
            <AppWrapper>
                <Routes />
            </AppWrapper>
        </Providers>
    );
}

export default App;
