import { memo, useMemo } from "react";

import { getLoanStrategyIdentifier, useWhirlpoolDecreaseLiquidityTransaction } from "@bridgesplit/abf-react";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { GetDecreaseLiquidityTxnArgs } from "@bridgesplit/abf-sdk";

import { AppButton } from "../../../../common";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useTransactionSender } from "../../../../transactions";
import { useWithdrawLtv } from "./util";

export default memo(function WithdrawCta() {
    const { withdrawForm, poolData, withdrawQuote } = useWhirlpoolContext();
    const removeLiquidity = useRemoveLiquidity();
    const withdrawLtv = useWithdrawLtv();

    const ctaError = useMemo(() => {
        if (!withdrawForm.percentToWithdraw) return "Enter an amount";
        if (withdrawForm.percentToWithdraw > 1) return "Invalid withdraw amount";
        if (!poolData || !withdrawQuote) return "Loading";

        if (withdrawForm.status === "error") return "No quote found";
        if (withdrawLtv.insufficientCollateral) return "Insufficient collateral";

        return undefined;
    }, [
        withdrawForm.percentToWithdraw,
        withdrawForm.status,
        poolData,
        withdrawQuote,
        withdrawLtv.insufficientCollateral
    ]);

    return (
        <AppButton
            asyncCta={{ onClickWithResult: removeLiquidity }}
            isTransaction
            color="secondary"
            disabled={!!ctaError}
        >
            {ctaError ?? "Withdraw"}
        </AppButton>
    );
});

function useRemoveLiquidity() {
    const { withdrawQuote, loanExpanded, reset } = useWhirlpoolContext();
    const send = useTransactionSender();
    const removeLiquidity = useWhirlpoolDecreaseLiquidityTransaction();
    return async () => {
        if (!withdrawQuote) return Result.errFromMessage(LOADING_ERROR);

        const strategyIdentifier = getLoanStrategyIdentifier(loanExpanded);
        if (!strategyIdentifier) return Result.errFromMessage("Updates are not available for this loan");

        const params: GetDecreaseLiquidityTxnArgs = {
            loan: loanExpanded.loan.address,
            strategyIdentifier,
            liquidityAmount: withdrawQuote.liquidity.toNumber(),
            tokenMinA: withdrawQuote.minTokenA.toNumber(),
            tokenMinB: withdrawQuote.minTokenB.toNumber()
        };

        return await send(removeLiquidity, params, { sendOptions: { onSuccess: reset } });
    };
}
