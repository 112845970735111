import { WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { retryAsync, RPC_URL } from "@bridgesplit/utils";
import { OrcaWhirlpoolClient, OrcaNetwork } from "@orca-so/whirlpool-sdk";
import { Connection } from "@solana/web3.js";

import { getSlippageTolerance } from "./util";

export const orca = new OrcaWhirlpoolClient({
    network: OrcaNetwork.MAINNET,
    connection: new Connection(RPC_URL, "processed")
});

export async function getPoolData(whirlpoolPosition: WhirlpoolPositionExpanded) {
    try {
        const pool = await orca.data.getPool(whirlpoolPosition.whirlpoolAddress, true);
        return pool;
    } catch (error) {
        return null;
    }
}

export async function getPositionData(whirlpoolPosition: WhirlpoolPositionExpanded) {
    try {
        return await retryAsync(async () => {
            const positionData = await orca.data.getPosition(whirlpoolPosition.position.address, true);
            // throw error if null to trigger retry
            if (!positionData) throw new Error("Position not found");

            const quote = await orca.position.getRemoveLiquidityQuote({
                positionAddress: whirlpoolPosition.position.address,
                liquidity: positionData.liquidity,
                refresh: false,
                slippageTolerance: getSlippageTolerance(0.01)
            });

            return { positionData, quote };
        }, 3);
    } catch (error) {
        return null;
    }
}
