import { AbfSdkMiddleware, AbfSdkReducers } from "@bridgesplit/abf-react";
import { InternalSdkMiddleware, InternalSdkReducers } from "@bridgesplit/react";
import { Dispatch, PayloadAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { TypedUseSelectorHook, useSelector } from "react-redux";

enableMapSet();

const appReducer = combineReducers({
    ...InternalSdkReducers,
    ...AbfSdkReducers
});

export type RootState = ReturnType<typeof appReducer>;

export const resetAppState = () => (dispatch: Dispatch) => {
    return dispatch({ type: "RESET_APP" });
};

const rootReducer = (state: RootState | undefined, action: PayloadAction) => {
    if (action.type === "RESET_APP") {
        return appReducer(undefined, { type: undefined });
    }
    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    // don't use sentry enhancer to prevent leak of data in redux
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(...InternalSdkMiddleware, ...AbfSdkMiddleware)
});
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
