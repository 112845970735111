import { useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { WalletReadyState } from "@solana/wallet-adapter-base";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";

import { FONT_SIZES, useAppPalette } from "../../theme";
import { Column, Row } from "../containers";
import { Image } from "../images";
import { Span, Text } from "../typography";
import { useWalletUtils } from "./util";
import { Button, ToggleButtons } from "../buttons";

export function WalletSelectToggle() {
    const { select, wallet } = useWallet();
    const { installedWallets } = useWalletUtils();

    if (!installedWallets.length) {
        return <Text color="caption"> You don't have a supported Solana wallet </Text>;
    }

    return (
        <ToggleButtons
            value={wallet?.adapter.name}
            setValue={(val) => select(val)}
            options={installedWallets.map((wallet) => ({
                value: wallet.adapter.name,
                label: (
                    <Row spacing={1}>
                        <Image size="16px" src={wallet.adapter.icon} />
                        <Span> {wallet.adapter.name} </Span>
                    </Row>
                )
            }))}
        />
    );
}

export function WalletButtons({ loading, onSelect }: { loading?: boolean; onSelect?: () => void }) {
    const { allWallets } = useWalletUtils();

    const { wallet: selectedWallet, select, connecting } = useWallet();

    return (
        <Column spacing={1}>
            {allWallets.map((wallet) => (
                <Button
                    key={wallet.adapter.name}
                    loading={wallet.adapter.name === selectedWallet?.adapter.name ? loading || connecting : false}
                    variant="outlined"
                    textProps={{ sx: { gap: 1.5 } }}
                    sx={{ justifyContent: "flex-start" }}
                    onClick={() => {
                        if (wallet.readyState === WalletReadyState.Installed) {
                            select(wallet.adapter.name);
                            onSelect?.();
                        } else {
                            window.open(wallet.adapter.url);
                        }
                    }}
                >
                    <Image size={FONT_SIZES.body1 + "px"} src={wallet.adapter.icon} />
                    <Span> {wallet.adapter.name} </Span>
                </Button>
            ))}
        </Column>
    );
}

export function WalletSelectDropdown({ onSelect }: { onSelect?: () => void }) {
    const { otherWallets, installedWallets } = useWalletUtils();

    const noWalletsInstalled = installedWallets.length === 0;
    const [isExpanded, setIsExpanded] = useState<boolean>(noWalletsInstalled);

    return (
        <Column sx={{ width: "100%", alignItems: "center", textAlign: "center" }}>
            <Text sx={{ paddingX: 2 }} variant="body2" color="caption">
                Select your wallet
            </Text>
            {installedWallets.map((w) => (
                <WalletRow onSelect={onSelect} wallet={w} key={w.adapter.name} />
            ))}
            {isExpanded && otherWallets.map((w) => <WalletRow wallet={w} key={w.adapter.name} />)}
            {noWalletsInstalled ? (
                <Column sx={{ p: 2 }} />
            ) : otherWallets.length ? (
                <Row spaceBetween onClick={() => setIsExpanded(!isExpanded)} sx={{ px: 2, cursor: "pointer" }}>
                    <Text variant="body2" color="caption">
                        All options
                    </Text>
                    <Text variant="body2" color="caption">
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </Text>
                </Row>
            ) : (
                <Column />
            )}
        </Column>
    );
}

function WalletRow({ wallet, onSelect }: { wallet: Wallet; onSelect?: () => void }) {
    const { select } = useWallet();
    const { hoverBackground } = useAppPalette();
    function handleSelect() {
        select(null);
        select(wallet.adapter.name);
        onSelect?.();
    }

    return (
        <Row
            spaceBetween
            sx={{ width: "100%", cursor: "pointer", px: 2, py: 1, "&:hover": { backgroundColor: hoverBackground } }}
            onClick={handleSelect}
        >
            <Row spacing={1}>
                <Image size="25px" src={wallet.adapter.icon} />
                <Text variant="h4">{wallet.adapter.name}</Text>
            </Row>
            {wallet.readyState === WalletReadyState.Installed && (
                <Text variant="body2" color="caption">
                    Detected
                </Text>
            )}
        </Row>
    );
}

export function useWalletConnecting() {
    const { wallet, connected } = useWallet();

    return !connected && !!wallet;
}
