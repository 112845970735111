import { useCallback, useEffect, useMemo } from "react";

import {
    Image,
    InputWrapper,
    LabelWrapper,
    Row,
    SPACING,
    Select,
    Span,
    Text,
    useAppPalette,
    useMemoizedKeyMap
} from "@bridgesplit/ui";
import {
    BsMetaUtil,
    checkStakeInputErrors,
    isStakedSol,
    isWhirlpoolMetadata,
    useActiveGroup,
    useBsMetadataByMint,
    useUserWhirlpoolPositions
} from "@bridgesplit/abf-react";
import { SOL_DECIMALS, SOL_SYMBOL, abbreviateString, formatTokenAmount } from "@bridgesplit/utils";
import { BsMetadata, WhirlpoolPositionExpanded } from "@bridgesplit/abf-sdk";
import { ExpandMore } from "@mui/icons-material";
import { AppDialog } from "app/utils";

import { useBorrowBalanceChecker, useMarketBorrowSetCollateral, useStakeCollateral } from "./util";
import { useMarketBorrowContext } from "./MarketBorrowContext";
import { TokenImage, TokenInputWithPrice, WhirlpoolPositionSummarized } from "../../common";
import { useMarketContext } from "../common";
import { BorrowMode } from "./type";
import { useMarketDialog } from "../util";

const LABEL = "Your collateral";
export default function BorrowCollateral() {
    return (
        <>
            <AmountInput />
            <LpPositionSelect />
            <StakeAccountSelect />
        </>
    );
}

function AmountInput() {
    const { form, setForm, userCollateral, durationForQueries } = useMarketBorrowContext();
    const { open: openDialog } = useMarketDialog();
    const marketProps = useMarketContext();

    const setToken = useMarketBorrowSetCollateral();

    const metadata = useBsMetadataByMint(form.collateralMint);
    const { BalanceDisplay } = useBorrowBalanceChecker();

    const selectToken = () => {
        openDialog(AppDialog.BorrowSelectCollateral, {
            setToken,
            marketProps,
            strategyDuration: durationForQueries
        });
    };
    if (userCollateral?.length === 0) {
        return (
            <LabelWrapper label={LABEL} color="body">
                <Text color="disabled" variant="body2">
                    You don't own any supported collateral
                </Text>
            </LabelWrapper>
        );
    }

    if (!!metadata && BsMetaUtil.isNonFungible(metadata)) {
        return (
            <LabelWrapper label={LABEL} color="body">
                <NftSelect metadata={metadata} onClick={selectToken} />
            </LabelWrapper>
        );
    }

    return (
        <TokenInputWithPrice
            label={LABEL}
            labelColor="body"
            metadata={metadata}
            selectToken={selectToken}
            value={form.collateralAmount}
            setValue={(collateralAmount) =>
                setForm((prev) => ({
                    ...prev,
                    collateralAmount,
                    refetchOutAmount: true,
                    mode: BorrowMode.InputCollateral
                }))
            }
            belowInput={<BalanceDisplay />}
        />
    );
}

function NftSelect({ metadata, onClick }: { onClick: () => void; metadata: BsMetadata }) {
    const { hoverBackground } = useAppPalette();
    return (
        <InputWrapper
            onClick={onClick}
            sx={{
                height: SPACING * 6.5,
                py: 0,
                cursor: "pointer",
                ":hover": { background: hoverBackground },
                justifyContent: "space-between"
            }}
        >
            <Row spacing={1}>
                <TokenImage metadata={metadata} size="sm" />
                <Text>{BsMetaUtil.getName(metadata)}</Text>
            </Row>
            <Text variant="h1" color="caption">
                <ExpandMore />
            </Text>
        </InputWrapper>
    );
}

function StakeAccountSelect() {
    const {
        form: { collateralAmount, stakeAccount, collateralMint, stakeAccountAdjusted },
        setForm
    } = useMarketBorrowContext();

    const { stakedSol, findMinStakeAmount } = useStakeCollateral();

    const { validStakes, minStakeAmount, minStakeAccount } = findMinStakeAmount(collateralAmount);

    const firstValidStake = stakedSol?.find((s) => !checkStakeInputErrors(s, collateralAmount));

    const selectedStake = stakedSol?.find((s) => s.stakeAccount === stakeAccount?.stakeAccount);

    const stakeError = useMemo(() => {
        if (!stakedSol || firstValidStake || !collateralAmount) return null;

        if (stakedSol.length === 0) return "You don't have any stake accounts";

        if (!validStakes?.length || !minStakeAmount) {
            return `You don't have any stakes with ${formatTokenAmount(collateralAmount, {
                symbol: SOL_SYMBOL,
                decimals: SOL_DECIMALS
            })}`;
        }

        return (
            <>
                Invalid amount: You must
                <Span
                    sx={{ cursor: "pointer", mx: 0.5, textDecoration: "underline", ":hover": { opacity: 0.6 } }}
                    onClick={() => setForm((prev) => ({ ...prev, collateralAmount: minStakeAmount }))}
                >
                    collateralize at least {BsMetaUtil.formatAmount(minStakeAccount?.metadata, minStakeAmount)}
                </Span>
            </>
        );
    }, [
        collateralAmount,
        firstValidStake,
        minStakeAccount?.metadata,
        minStakeAmount,
        setForm,
        stakedSol,
        validStakes?.length
    ]);

    useEffect(() => {
        if (!firstValidStake || !!stakeAccount) return;
        setForm((prev) => ({ ...prev, stakeAccount: firstValidStake }));
    }, [firstValidStake, setForm, stakeAccount]);

    if (!isStakedSol(collateralMint) || stakedSol?.length === 0 || !collateralAmount) return null;

    return (
        <LabelWrapper
            color="body"
            tooltip={
                stakeAccountAdjusted
                    ? `The Solana staking contract requires a min transfer size of ${BsMetaUtil.formatAmount(
                          minStakeAccount?.metadata,
                          collateralAmount
                      )}`
                    : undefined
            }
            label="Stake account"
        >
            {stakeError && (
                <Text sx={{ display: "inline" }} variant="body2" color="error">
                    {stakeError}
                </Text>
            )}

            {!stakeError && (
                <Select
                    renderValue={() => (
                        <Row spacing={1}>
                            <Image src={BsMetaUtil.getImage(selectedStake?.metadata)} size="16px" variant="circle" />
                            <Text>{BsMetaUtil.getName(selectedStake?.metadata)} </Text>
                        </Row>
                    )}
                    value={stakeAccount?.stakeAccount}
                    loading={!stakedSol}
                    setValue={(address) =>
                        setForm((prev) => ({
                            ...prev,
                            stakeAccount: stakedSol?.find((s) => s.stakeAccount === address)
                        }))
                    }
                    options={(stakedSol ?? []).map((acc) => {
                        const { metadata, stakeAccount, amount } = acc;
                        const inputError = checkStakeInputErrors(acc, collateralAmount);
                        return {
                            disabled: !!inputError,
                            label: (
                                <Row sx={{ width: "100%" }} spaceBetween spacing={1}>
                                    <Row spacing={1}>
                                        <Image src={BsMetaUtil.getImage(metadata)} size="16px" variant="circle" />
                                        <Text>{abbreviateString(BsMetaUtil.getName(metadata))} </Text>
                                    </Row>

                                    <Text color="caption">
                                        {BsMetaUtil.formatAmount(metadata, amount, { hideSymbol: true })}
                                    </Text>
                                </Row>
                            ),
                            value: stakeAccount
                        };
                    })}
                />
            )}
        </LabelWrapper>
    );
}

function LpPositionSelect() {
    const { form, setForm } = useMarketBorrowContext();

    const metadata = useBsMetadataByMint(form.collateralMint);
    const { groupIdentifier } = useActiveGroup();

    const isOrcaPosition = isWhirlpoolMetadata(metadata);
    const { data: wp } = useUserWhirlpoolPositions({ skip: !isOrcaPosition });

    const positions = useMemo(
        () =>
            groupIdentifier
                ? wp
                      ?.filter((w) => w.whirlpoolAddress === form.collateralMint)
                      .sort((a, b) => b.totalPrice - a.totalPrice)
                : [],
        [form.collateralMint, groupIdentifier, wp]
    );

    const mintToPosition = useMemoizedKeyMap(positions, (p) => p.position.positionMint);

    const setPosition = useCallback(
        (orcaPosition: WhirlpoolPositionExpanded) =>
            setForm((prev) => ({
                ...prev,
                orcaPosition,
                collateralAmount: orcaPosition.position.totalLiquidity,
                refetchOutAmount: true
            })),
        [setForm]
    );

    useEffect(() => {
        if (!isOrcaPosition || !positions?.length || form.orcaPosition?.whirlpoolAddress === form.collateralMint)
            return;
        setPosition(positions[0]);
    }, [form.collateralMint, form.orcaPosition, isOrcaPosition, positions, setPosition]);

    if (!isOrcaPosition) return null;

    if (positions?.length === 0) {
        return (
            <LabelWrapper color="body" label="Position">
                <Text color="disabled" variant="body2">
                    You don't have any {BsMetaUtil.getName(metadata)} positions
                </Text>
            </LabelWrapper>
        );
    }

    return (
        <LabelWrapper color="body" label="Position">
            <Select
                renderValue={() => <WhirlpoolPositionSummarized position={form.orcaPosition} />}
                value={positions ? form.orcaPosition?.position.positionMint : ""}
                loading={!positions}
                setValue={(positionMint) => {
                    const orcaPosition = mintToPosition?.get(positionMint);
                    if (!orcaPosition) return;
                    setPosition(orcaPosition);
                }}
                options={(positions ?? []).map((position) => {
                    return {
                        label: <WhirlpoolPositionSummarized spaceBetween position={position} />,
                        value: position.position.positionMint
                    };
                })}
            />
        </LabelWrapper>
    );
}
