import { useLoopscaleTrackingContext } from "./LoopscaleTrackingContext";
import { TrackEventFunction } from "./types";

// Internal tracking function for UI components. Do not use this directly in app
export function useUiTrack() {
    const trackContext = useLoopscaleTrackingContext();
    return (...params: Parameters<TrackEventFunction>) => {
        trackContext?.trackEvent(...params);
    };
}
