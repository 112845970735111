import { createApi } from "@reduxjs/toolkit/query/react";
import { IS_LOCAL_NET, ALLOWED_FEATURES, TransactionGenerationType } from "@bridgesplit/utils";

import { GET_JITO_HEALTH_ROUTE, GET_PRIO_FEE_HEALTH_ROUTE } from "../constants";
import { abfSerializeQueryArgs, mpcBaseQuery } from "./util";
import { HealthStatus } from "../types";

const HEALTH_TAG = "Health";

export const transactionHealthApi = createApi({
    reducerPath: "transactionHealthApi",
    baseQuery: mpcBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [HEALTH_TAG],
    keepUnusedDataFor: 20,
    endpoints: (builder) => ({
        jitoHealth: builder.query<HealthStatus, void>({
            providesTags: [HEALTH_TAG],
            query() {
                return {
                    url: GET_JITO_HEALTH_ROUTE,
                    method: "GET"
                };
            }
        }),
        priorityFeeHealth: builder.query<HealthStatus, void>({
            providesTags: [HEALTH_TAG],
            query() {
                return {
                    url: GET_PRIO_FEE_HEALTH_ROUTE,
                    method: "GET"
                };
            }
        })
    })
});

const FAILURE_THRESHOLD = 0.1; //10% failure rate in last 10 mins

function isHealthy(healthStatus: HealthStatus | undefined) {
    if (!healthStatus) {
        return false;
    }

    if (ALLOWED_FEATURES.jitoLongCheck) {
        return healthStatus?.fiveMinutesFailureRate < FAILURE_THRESHOLD;
    }

    return healthStatus?.oneMinuteFailureRate < FAILURE_THRESHOLD;
}

export function useGetTransactionGenerationType() {
    const [fetchJitoHealth] = transactionHealthApi.useLazyJitoHealthQuery();
    const [fetchPriorityFeeHealth] = transactionHealthApi.useLazyPriorityFeeHealthQuery();

    return async function (): Promise<TransactionGenerationType> {
        // Local net can't use jito
        if (IS_LOCAL_NET || !!ALLOWED_FEATURES.disableJito) {
            return TransactionGenerationType.Priority;
        }

        const [jito, priorityFee] = await Promise.all([fetchJitoHealth(), fetchPriorityFeeHealth()]);

        if (!isHealthy(jito.data)) {
            return TransactionGenerationType.Priority;
        }

        if (!isHealthy(priorityFee.data)) return TransactionGenerationType.Jito;

        return TransactionGenerationType.Priority;
    };
}
