import { ReactNode, createContext, useContext, useState } from "react";

import { MayString } from "@bridgesplit/utils";
import { Dialog, Drawer, drawerClasses, useMediaQuery } from "@mui/material";
import { FormInputType } from "@bridgesplit/react";

import { Column } from "../components";
import { MediaQuery, SxType } from "../types";
import { DIALOG_WINDOW_CENTER_MIN_HEIGHT, SPACING, useAppPalette } from "../theme";
import { MEDIA } from "../util";

/**
 * -- Overview --
 * This context allows for exactly 1 dialog (modal) to be `open` in the app
 * Each dialog must have a unique identifier and wrapped in a DialogWrapper
 * Dialogs should be globally managed in a single component to ensure that duplicate dialogs aren't open
 *
 * -- Data --
 * If a dialog needs specific data (props), it should receive it through the `data` property in context
 * The `data` property is initialized to undefined and force typed to maximize flexibility
 * To pass in data, pass in the data typing into useDialog and pass in the optional newData param
 *
 * -- Registering a Dialog --
 * Add dialog identifier to enum of identifiers
 * Create a dialog component that is wrapped in DialogWrapper
 * Register dialog component in the app's global dialog component
 * Open the dialog using useDialog (passing in data if necessary)
 */
interface DialogState {
    openIdentifier: string | undefined;
    setOpenIdentifier: ((identifier: string | undefined) => void) | undefined;
    setData: ((data: Record<string, unknown>) => void) | undefined;
    preventClose: boolean;
    setPreventClose: FormInputType<boolean> | undefined;
    data: Record<string, unknown>;
}
const ModalContext = createContext<DialogState>({
    openIdentifier: undefined,
    setOpenIdentifier: undefined,
    setData: undefined,
    preventClose: false,
    setPreventClose: undefined,
    data: {}
});

export function DialogProvider({ children }: { children: ReactNode }) {
    const [openIdentifier, setOpenIdentifier] = useState<MayString>(undefined);
    const [data, setData] = useState<Record<string, unknown>>({});
    const [preventClose, setPreventClose] = useState<boolean>(false);

    return (
        <ModalContext.Provider
            value={{
                openIdentifier,
                setOpenIdentifier,
                data,
                setData,
                preventClose,
                setPreventClose
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

const DIALOG_ERROR = new Error("Loopscale UI Error: Dialog must be wrapped in a DialogProvider");
export function useDialog() {
    const {
        setOpenIdentifier,
        openIdentifier,
        data: rawData,
        setData,
        preventClose,
        setPreventClose
    } = useContext(ModalContext);

    function getData<DataType>(identifier: string | undefined): DataType | undefined {
        if (!identifier || !(identifier in rawData)) return undefined;

        return rawData?.[identifier] as DataType;
    }

    function open<DataType>(identifier: string, newData?: DataType) {
        if (!setData || !setOpenIdentifier) {
            throw DIALOG_ERROR;
        }
        setData({ [identifier]: newData });
        setOpenIdentifier(identifier);
    }

    function close() {
        if (!setData || !setOpenIdentifier) {
            throw DIALOG_ERROR;
        }
        setData?.({});
        setOpenIdentifier?.(undefined);
        setPreventClose?.(false);
    }

    function _setPreventClose(preventClose: boolean) {
        if (!setPreventClose) {
            throw DIALOG_ERROR;
        }
        setPreventClose?.(preventClose);
    }

    return { open, close, openIdentifier, getData, preventClose, setPreventClose: _setPreventClose };
}

type DialogWrapperProps = {
    children: ReactNode;
    sx?: SxType;
    spacing?: number;
    preventClose?: boolean;
    onClose?: () => void;
    maxWidth?: number;
    padding?: number;
    forceOpen?: boolean;
    drawerQuery?: MediaQuery;
};

export function DialogWrapper({ isDrawer, ...props }: DialogWrapperProps & { isDrawer?: boolean }) {
    if (isDrawer) return <DialogDrawer {...props} />;
    return <DialogModalWrapper {...props} />;
}

function DialogModalWrapper({
    children,
    sx,
    spacing = 2,
    preventClose,
    onClose,
    maxWidth = 400,
    padding = 2,
    forceOpen
}: DialogWrapperProps) {
    const { paperBackground } = useAppPalette();
    const { close, openIdentifier, preventClose: preventCloseState } = useDialog();

    return (
        <Dialog
            sx={{
                // use top align to prevent vertical jumping
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    height: "100%"
                },
                "& .MuiDialog-paper": {
                    background: paperBackground,
                    maxWidth: `${maxWidth}px`,
                    width: `calc(100vw - ${SPACING * 4}px)`,
                    my: 4,
                    [`@media (min-height: ${DIALOG_WINDOW_CENTER_MIN_HEIGHT}px)`]: {
                        my: SPACING * 16 + "px",
                        maxHeight: `calc(100vh - ${SPACING * 16 * 2}px)`
                    }
                }
            }}
            onClose={() => {
                if (preventClose || preventCloseState) return;
                close();
                onClose?.();
            }}
            open={forceOpen ?? !!openIdentifier}
        >
            <Column spacing={spacing} sx={{ p: padding, ...sx }}>
                {children}
            </Column>
        </Dialog>
    );
}

function DialogDrawer({
    children,
    sx,
    spacing = 2,
    preventClose,
    onClose,
    maxWidth = 400,
    padding = 2,
    forceOpen,
    drawerQuery = MEDIA.LG
}: DialogWrapperProps) {
    const { paperBackground } = useAppPalette();
    const { close, openIdentifier, preventClose: preventCloseState } = useDialog();
    const isMobile = useMediaQuery(drawerQuery.below);

    return (
        <Drawer
            anchor={isMobile ? "top" : "right"}
            sx={{
                [`.${drawerClasses.paper}`]: {
                    background: paperBackground,
                    [drawerQuery.above]: { width: "70vw", maxWidth: `${maxWidth}px` },
                    [drawerQuery.below]: { maxHeight: "90vh" }
                }
            }}
            onClose={() => {
                if (preventClose || preventCloseState) return;
                close();
                onClose?.();
            }}
            open={forceOpen ?? !!openIdentifier}
        >
            <Column spacing={spacing} sx={{ p: padding, flexGrow: 1, ...sx }}>
                {children}
            </Column>
        </Drawer>
    );
}
