import {
    AbfGroupType,
    getAvatarFromName,
    useUserProfile,
    AbfCreateUserGroupParams,
    getFirstName
} from "@bridgesplit/abf-react";
import { NullableRecord } from "@bridgesplit/utils";

export function useWalletSetupGroupToCreate() {
    const { user } = useUserProfile();

    const name = getFirstName(user);
    const createGroupParams: Omit<NullableRecord<AbfCreateUserGroupParams>, "admin_wallet"> = {
        group_name: name,
        group_image: getAvatarFromName(name),
        group_description: "",
        group_type: AbfGroupType.Individual
    };

    return { createGroupParams };
}

export enum WalletSetupStep {
    Select,
    Wallet,
    Mpc,
    MpcPrimeGated
}
