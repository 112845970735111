import { ReactNode, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import {
    AbsoluteCenterWrapper,
    Button,
    CARD_WIDTH,
    Column,
    createThemeOptions,
    LoopscaleSvg,
    OutlinedCard,
    Row,
    TagTextAlert,
    Text
} from "@bridgesplit/ui";
import { createTheme, ThemeProvider } from "@mui/material";
import { SUPPORT_URL } from "app/constants";
import { useAppTheme } from "@bridgesplit/react";

export default function ErrorBoundary({ children }: { children: ReactNode }) {
    return <Sentry.ErrorBoundary fallback={<Fallback />}>{children}</Sentry.ErrorBoundary>;
}

// uses default styling since mui isn't in tree
export function Fallback() {
    const { mode } = useAppTheme();

    const errorMessage = useErrorMessage();
    return (
        <ThemeProvider theme={createTheme(createThemeOptions(mode))}>
            <AbsoluteCenterWrapper>
                <OutlinedCard
                    spacing={3}
                    padding={3}
                    sx={{
                        maxWidth: CARD_WIDTH,
                        alignItems: "center",
                        textAlign: "center"
                    }}
                >
                    {errorMessage && (
                        <TagTextAlert sx={{ width: "100%" }} icon="warning" color="error">
                            {errorMessage}
                        </TagTextAlert>
                    )}
                    <LoopscaleSvg />
                    <Column spacing={0.5} alignItems="center">
                        <Text variant="h3" fontWeight="normal">
                            Something went wrong
                        </Text>
                        <Text color="caption">
                            We're sorry for the inconvenience. Please try refreshing the page. If the problem persists,
                            please contact support
                        </Text>
                    </Column>
                    <Row sx={{ width: "100%" }} spacing={1}>
                        <Button onClick={() => window.open(SUPPORT_URL)} variant="outlined" fullWidth>
                            Contact support
                        </Button>
                        <Button onClick={() => window.location.reload()} variant="contained" fullWidth>
                            Refresh page
                        </Button>
                    </Row>
                </OutlinedCard>
            </AbsoluteCenterWrapper>
        </ThemeProvider>
    );
}

function useErrorMessage() {
    const isEdgeTranslate = useIsEdgeTranslate();

    if (isEdgeTranslate) {
        return "Support for Microsoft Translator is limited. Please use a different browser or disable translation";
    }
    return undefined;
}

// Only runs once when component mounts
function useIsEdgeTranslate() {
    const [isEdge, setIsEdge] = useState(false);

    useEffect(() => {
        const titleElement = document.querySelector("title");
        if (!titleElement) return;

        setIsEdge(titleElement.hasAttribute("_msttexthash") || titleElement.hasAttribute("_msthash"));
    }, []);

    return isEdge;
}
