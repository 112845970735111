import { NullableRecord } from "../types";

export function removeDuplicates<T>(arrayWithDuplicates: T[]): T[] {
    return Array.from(new Set(arrayWithDuplicates));
}

export function removeDuplicatesOrUndefined<T>(arrayWithDuplicates: T[] | undefined): T[] | undefined {
    if (!arrayWithDuplicates) return undefined;
    return removeDuplicates(arrayWithDuplicates);
}

export function splitArray<T>(
    elements: T[] | undefined,
    filter: (element: T) => boolean
): [T[] | undefined, T[] | undefined] {
    const filtered: T[] = [];
    const filteredOut: T[] = [];

    if (!elements) return [undefined, undefined];

    for (const element of elements) {
        const isWith = filter(element);
        if (isWith) {
            filtered.push(element);
        } else {
            filteredOut.push(element);
        }
    }
    return [filtered, filteredOut];
}

export function removeDuplicatesByProperty<T>(arrayWithDuplicates: T[], key: keyof T): T[] {
    return Array.from(new Map(arrayWithDuplicates.map((d) => [d[key], d])).entries()).map(([, data]) => data);
}

export function removeDuplicatesWithFunction<T>(arrayWithDuplicates: T[], getKey: (t: T) => string | number): T[] {
    return Array.from(new Map(arrayWithDuplicates.map((d) => [getKey(d), d])).entries()).map(([, data]) => data);
}

export function combineCollections<T>(collections: (T[] | Set<T> | undefined)[]): T[] {
    const combined: T[] = [];

    for (const collection of collections) {
        if (collection === undefined) continue;
        if (Array.isArray(collection)) {
            combined.push(...collection);
        } else if (collection instanceof Set) {
            combined.push(...collection);
        }
    }

    return combined;
}

export function deepCompareArrays<T extends string | number>(array1: T[], array2: T[]) {
    return deepCompareSets(new Set(array1), new Set(array2));
}

type ValidType = string | number | undefined;
export function deepCompareMaps<K extends string | number, T extends ValidType>(m1: Map<K, T>, m2: Map<K, T>) {
    if (m1.size !== m2.size) {
        return false;
    }

    for (const [key, val] of m1.entries()) {
        if (!m2.has(key)) return false;
        if (val !== m2.get(key)) return false;
    }
    return true;
}

export function deepCompareArraysWithFunction<T>(array1: T[], array2: T[], getKey: (t: T) => string | number) {
    if (array1.length !== array2.length) {
        return false;
    }
    const array1Map = new Map(array1.map((item) => [getKey(item), item]));

    for (const item of array2) {
        const key = getKey(item);
        if (!array1Map.has(key)) {
            return false;
        }
    }

    return true;
}

export function deepCompareSets<T extends string | number>(set1: Set<T>, set2: Set<T>): boolean {
    if (set1.size !== set2.size) {
        return false;
    }

    for (const item of set1) {
        if (!set2.has(item)) {
            return false;
        }
    }

    return true;
}

// empty fn used as placeholder
export function doNothing() {
    return;
}

export const emptyPromise = new Promise<undefined>((resolve) => resolve(undefined));

export function customEmptyPromise<T>(resolveType: T) {
    return new Promise<T>((resolve) => resolve(resolveType));
}

export function allKeysTruthy<T>(obj: NullableRecord<T>, keys: (keyof T)[]): boolean {
    return keys.every((key) => !!obj[key]);
}

export function allKeysDefined<T>(obj: NullableRecord<T>, keys: (keyof T)[]): boolean {
    return keys.every((key) => obj[key] !== undefined);
}

export function filterAllKeysDefined<T>(obj: NullableRecord<T>): obj is T {
    return allKeysDefined(obj, Object.keys(obj) as (keyof T)[]);
}

export function filterUndefined<T>(val: T | undefined): val is T {
    return val !== undefined;
}

export function filterTruthy<T>(val: T | undefined | null): val is T {
    return !!val;
}

// Filter out entries with missing fields
export function filterNullableRecord<T>(obj: NullableRecord<T>): obj is T {
    return Object.values(obj).every((value) => value !== undefined);
}

// Used exclusively for force asserting a type from a partial object
export function assetPartialRecord<T>(obj: Partial<T>): obj is T {
    return Object.values(obj).every((value) => value !== undefined);
}

export function findLastIndex<T>(array: T[], condition: (element: T) => boolean): number {
    for (let i = array.length - 1; i >= 0; i--) {
        if (condition(array[i])) {
            return i;
        }
    }
    return -1; // Return -1 if no element satisfies the condition
}

export function groupArrayElementsBy<T, K>(array: T[], keySelector: (item: T) => K): Map<K, T[]> {
    return array.reduce((resultMap, item) => {
        const key = keySelector(item);
        const prevValue = resultMap.get(key) || [];
        resultMap.set(key, [...prevValue, item]);
        return resultMap;
    }, new Map<K, T[]>());
}

export function filterMap<KeyType, ValueType>(
    map: Map<KeyType, ValueType>,
    filter: (val: ValueType) => boolean
): Map<KeyType, ValueType> {
    const entries = Array.from(map.entries()).filter(([, val]) => filter(val));
    return new Map(entries);
}

export function filterMapByKey<KeyType, ValueType>(
    map: Map<KeyType, ValueType>,
    filter: (val: KeyType) => boolean
): Map<KeyType, ValueType> {
    const entries = Array.from(map.entries()).filter(([key]) => filter(key));
    return new Map(entries);
}

export function filterMapValues<KeyType, ValueType>(
    map: Map<KeyType, ValueType>,
    filter: (val: ValueType) => boolean
): ValueType[] {
    return Array.from(map.values()).filter((val) => filter(val));
}

export async function asyncForEach<Item, Params, T>(
    params: Params[],
    asyncFn: (param: Params) => Promise<T>,
    getParams?: (raw: Item) => Params
): Promise<T[]> {
    const results: T[] = [];
    for (const param of params) {
        const result = await asyncFn(param);

        results.push(result);
    }
    return results;
}

export async function retryAsync<T>(fn: () => Promise<T>, maxRetries: number, intervalMs = 1000): Promise<T> {
    let lastError: Error | undefined;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
            const result = await fn();
            return result;
        } catch (error) {
            lastError = error as Error;
            if (attempt < maxRetries - 1) {
                // Linear backoff: wait intervalMs * (attempt + 1) milliseconds
                await new Promise((resolve) => setTimeout(resolve, intervalMs * (attempt + 1)));
            }
        }
    }

    throw lastError || new Error("Operation failed after max retries");
}
