import { filterNullableRecord, parseStringArray } from "@bridgesplit/utils";

import { useCustodiansByIdentifiers, useCustodiansWithStatsQuery } from "../reducers";
import { useAbfTypesToUiConverter } from "../utils";
import { useGroup } from "./group";
import { CustodianExpandedParams, AbfRole } from "../types";

export function useCustodiansWithStats(params: CustodianExpandedParams & { skip?: boolean }) {
    // skip on user loading to force a render when this changes
    const { isLoading } = useGroup();
    const { data: paginatedData, ...rest } = useCustodiansWithStatsQuery(params, {
        skip: isLoading || params.skip
    });

    const { convertCustodianWithStats, tokensLoading } = useAbfTypesToUiConverter([]);

    return {
        hasMore: paginatedData?.hasMore,
        total: paginatedData?.total,
        data:
            paginatedData?.data && !tokensLoading
                ? paginatedData?.data.map((o) => convertCustodianWithStats(o))
                : undefined,
        ...rest
    };
}

export function splitCustodianTags(custodianTags: string | undefined) {
    return parseStringArray(custodianTags);
}

export function useUserCustodianPermissions() {
    const { custodianPermissions } = useGroup();
    const { cache, isLoading } = useCustodiansByIdentifiers(Object.keys(custodianPermissions));

    if (isLoading) return undefined;
    return Object.entries(custodianPermissions)
        .map(([identifier, roles]) => {
            const custodian = cache?.get(identifier);
            return { custodian, roles: roles.filter((r) => r !== AbfRole.Member) };
        })
        .filter(filterNullableRecord);
}
