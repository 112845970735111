import { Button, Column, DialogWrapper, ErrorMessage, Icon, IconHeader, Text, TextNestedLink } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { PLAID_WEBSITE_URL } from "app/constants";

import { useOpenPlaid } from "../util";

export default function AddBankDialog() {
    const { open } = useAppDialog();
    const { open: openPlaid, isLoading: plaidFetching, errorMessage } = useOpenPlaid();
    return (
        <DialogWrapper>
            <IconHeader>
                <Icon type="bank" />
            </IconHeader>

            <Column spacing={1} sx={{ alignItems: "center" }}>
                <Text variant="h3">{AppDialog.AddBank}</Text>

                <Text color="caption" sx={{ display: "block" }}>
                    Link a bank account to convert cash to and from USDC (digital dollars). Loopscale uses{" "}
                    <TextNestedLink onClick={() => window.open(PLAID_WEBSITE_URL)}>Plaid</TextNestedLink> to verify your
                    bank account info
                </Text>
            </Column>

            <Column spacing={1}>
                <Button loading={plaidFetching} variant="contained" onClick={openPlaid}>
                    Continue
                </Button>
                <Button onClick={() => open(AppDialog.ManualAddBank, undefined)}>{AppDialog.ManualAddBank}</Button>
                <ErrorMessage errorMessage={errorMessage ?? undefined} />
            </Column>
        </DialogWrapper>
    );
}
