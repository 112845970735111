import { ReactNode, useState } from "react";

import { Button, Column, DialogWrapper, Icon, IconHeader, TagTextAlert, Text, TextNestedLink } from "@bridgesplit/ui";
import { DispatchType } from "@bridgesplit/react";
import { Result } from "@bridgesplit/utils";
import { AppDialog } from "app/utils";
import { SPHERE_WEBSITE_URL } from "app/constants";

import { AppButton } from "../../common";

enum Step {
    Intro,
    Register
}

export function SphereIntroWrapper({ children }: { children: ReactNode }) {
    const [step, setStep] = useState<Step>(Step.Intro);

    if (step === Step.Register) {
        return <> {children}</>;
    }
    return <SphereIntroScreen next={() => setStep(Step.Register)} />;
}

function SphereIntroScreen({ next }: { next: () => void }) {
    return (
        <DialogWrapper>
            <IconHeader>
                <Icon type="bank" />
            </IconHeader>

            <Column spacing={1} sx={{ alignItems: "center" }}>
                <Text variant="h3">{AppDialog.RegisterIndividualSphere}</Text>
                <Text color="caption" sx={{ display: "block", textAlign: "center" }}>
                    Loopscale uses{" "}
                    <TextNestedLink onClick={() => window.open(SPHERE_WEBSITE_URL)}>Sphere</TextNestedLink> to transfer
                    between cash in your bank account to USDC (digital dollars)
                </Text>
            </Column>
            <TagTextAlert>
                <Icon type="tooltip" /> Currently, only U.S. banks are supported
            </TagTextAlert>

            <Button onClick={next} variant="contained">
                Continue
            </Button>
        </DialogWrapper>
    );
}

export function RegisterCta<T extends number>({
    step,
    setStep,
    disabled,
    stepCount,
    register
}: {
    step: T;
    setStep: DispatchType<T>;
    disabled?: boolean;
    register: () => Promise<Result<unknown>>;
    stepCount: number;
}) {
    return (
        <Column spacing={1}>
            {step === stepCount - 1 ? (
                <AppButton
                    isTransaction={false}
                    disabled={disabled}
                    asyncCta={{
                        onClickWithResult: register,
                        closeDialog: "onSuccess",
                        options: { onSuccessMessage: "Registration submitted" }
                    }}
                    variant="contained"
                >
                    Register
                </AppButton>
            ) : (
                <Button disabled={disabled} onClick={() => setStep((step + 1) as T)} variant="contained">
                    Continue
                </Button>
            )}

            {step !== 0 && (
                <Text isLink underlineLink="never" color="caption" onClick={() => setStep((step - 1) as T)}>
                    <Icon type="back" /> Back
                </Text>
            )}
        </Column>
    );
}
