import { useWhirlpoolContext } from "../WhirlpoolContext";
import { BalanceAndMax, TokenInputs } from "../common";
import { useInitialWhirlpoolRatio } from "../util";
import { useDepositWhirlpoolBalances, useUpdateDepositAmount } from "./util";

export default function DepositAmounts() {
    const { depositForm } = useWhirlpoolContext();
    const { A, B } = useDepositWhirlpoolBalances();

    const handleChange = useUpdateDepositAmount();
    const ratio = useInitialWhirlpoolRatio();

    return (
        <TokenInputs
            handleChange={handleChange}
            form={depositForm}
            ratio={ratio}
            isFetching={depositForm.status === "refetching"}
            getBelowInput={(side) => {
                return (
                    <BalanceAndMax
                        side={side}
                        depositRatio={ratio}
                        inputAmount={side === "A" ? depositForm.tokenAAmount : depositForm.tokenBAmount}
                        setAmount={handleChange}
                        balanceA={A.balance ?? 0}
                        balanceB={B.balance ?? 0}
                    />
                );
            }}
        />
    );
}
