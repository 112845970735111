import { ChainId } from "./metadata";

export enum WalletType {
    SelfCustodial,
    MPC
}

export enum DfnsActionType {
    Pat,
    RegisterNewDevice
}

export interface PublicKeyCredentialCreationOptionsServer
    extends Omit<PublicKeyCredentialCreationOptions, "challenge" | "user" | "excludeCredentials"> {
    challenge: string;
    user: {
        id: string;
        name: string;
        displayName: string;
    };
    excludeCredentials: {
        type: "public-key";
        id: string;
        transports?: AuthenticatorTransport;
    }[];
}

export interface DfnsChallenge {
    challenge: Uint8Array;
    challengeIdentifier: string;
    allowCredentials: {
        key: {
            id: Uint8Array;
            type: "public-key";
        }[];
        webauthn: {
            id: Uint8Array;
            type: "public-key";
            transports?: AuthenticatorTransport[];
        }[];
    };
    externalAuthenticationUrl?: string;
}

export interface DfnsCredential {
    id: number;
    createdAt: number;
    userIdentifier: string;
    name: string;
    credentialId: string;
    dfnsUuid: string;
    metadata: string | null;
    registered: boolean;
}

export enum DfnsRegistrationStatus {
    NotStarted = "notStarted",
    Initiated = "initiated",
    Completed = "completed"
}

export interface DfnsUserCredentials {
    passkeys: DfnsCredential[];
    currentPasskey: DfnsCredential | null;
    login: boolean;
    registration: DfnsRegistrationStatus;
}

export interface PasskeyHistory {
    id: number;
    passkeyId: number;
    timestamp: number;
    location: string | null;
}

export interface AllowCredential {
    id: Uint8Array;
    type: "public-key";
}

export interface SignData {
    firstFactorCredential: {
        credentialKind: "Fido2";
        credentialInfo: {
            credId: string;
            clientData: string;
            attestationData: string;
            signature: string;
            userHandle: string;
        };
    };
}

export interface LoginSignData {
    firstFactor: {
        kind: "Fido2";
        credentialAssertion: {
            credId: string;
            clientData: string;
            signature: string;
            userHandle: string;
            authenticatorData: string;
        };
    };
    challengeIdentifier: string;
}

export interface CompleteUserLoginRequestWithHistory {
    req: LoginSignData;
    location: string | null;
    passkeyId: number;
}

export interface CompleteCredentialRegistration {
    name: string;
    signData: SignData;
    metadata: string | null;
}

export interface NewCredentialMetadata {
    name: string;
    metadata: string | null;
}

export interface ApproveNewCredentialMetadata {
    challengeData: UserActionData;
    passkeyId: number;
}

export interface UserActionData {
    challenge: LoginResponseServer;
    signData: SignData;
}

export interface LoginResponseServer {
    supportedCredentialKinds: {
        kind: string;
        factor: string;
        requiresSecondFactor: boolean;
    }[];
    challenge: string;
    challengeIdentifier: string;
    externalAuthenticationUrl?: string;
    allowCredentials: AllowCredentials;
}

export interface AllowCredentials {
    key: KeyCredential[];
    webauthn: WebAuthnCredential[];
}

export interface KeyCredential {
    type_: string;
    id: string;
}

export interface WebAuthnCredential {
    type_: string;
    id: string;
    transports?: string;
}

export type CredentialWithHistory = DfnsCredential & { history: PasskeyHistory[]; lastSignIn: number | undefined };

export interface CreateMultiChainWalletArgs {
    userIdentifier: string;
    email: string;
    chainId: ChainId;
}
