import { BsMetaUtil, TokenBalanceExpandedWithPrice } from "@bridgesplit/abf-react";
import { Column, Row, Text, repeatElement } from "@bridgesplit/ui";
import { formatUsd } from "@bridgesplit/utils";

import { TokenImage } from "../common";

export default function FungibleAssets({ deposits }: { deposits: TokenBalanceExpandedWithPrice[] | undefined }) {
    const fungibleAssets = deposits?.filter((d) => BsMetaUtil.isFungible(d.metadata));

    return (
        <>
            {!deposits && repeatElement(<TokenDisplay deposit={undefined} />)}
            {fungibleAssets?.map((deposit) => (
                <TokenDisplay deposit={deposit} key={deposit.key} />
            ))}
        </>
    );
}

function TokenDisplay({ deposit }: { deposit: TokenBalanceExpandedWithPrice | undefined }) {
    return (
        <Row sx={{ py: 0.5 }} spaceBetween>
            <Row spacing={1}>
                <TokenImage size="lg" metadata={deposit?.metadata} />
                <Column>
                    <Text>{BsMetaUtil.getSymbolUnique(deposit?.metadata)}</Text>
                    {deposit?.usdPrice && (
                        <Text loadingWidth="50px" loading={!deposit} color="caption" variant="body2">
                            {formatUsd(deposit.usdPrice)}
                        </Text>
                    )}
                </Column>
            </Row>

            <Column alignItems="flex-end">
                <Text loading={!deposit}>
                    {BsMetaUtil.formatAmount(deposit?.metadata, deposit?.amount, { hideSymbol: true })}
                </Text>
                {deposit?.usdAmount && (
                    <Text loadingWidth="50px" loading={!deposit} color="caption" variant="body2">
                        {formatUsd(deposit.usdAmount)}
                    </Text>
                )}
            </Column>
        </Row>
    );
}
