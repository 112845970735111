import { useCallback, useMemo } from "react";

import { createApi } from "@reduxjs/toolkit/query/react";
import {
    BsMetadata,
    ParsedBsMetadata,
    AssetClassInfo,
    JSONSchema,
    AssetTypeIdentifier,
    OrcaPositionInfo
} from "@bridgesplit/abf-sdk";
import { STAKED_SOL_MINT, filterTruthy, removeDuplicates, safeJSONParse } from "@bridgesplit/utils";
import { useDispatch } from "react-redux";

import { abfSerializeQueryArgs, bsMetadataBaseQuery, convertParsedBsMetadataToBsMetadata } from "./util";
import { GET_ASSET_CLASS_INFO_ROUTE, GET_BS_METADATA_ROUTE, GET_ORCA_POSITION_INFO_ROUTE } from "../constants";
import { isStakedSol } from "../utils";
import { useTokenListQuery } from "./tokenListApi";

const BS_METADATA_TAG = "bsMetadata";
const PRINCIPAL_METADATA_TAG = "princpalMetadata";
const ASSET_CLASSES_TAG = "assetClasses";
const WHIRLPOOL_TAG = "whirlpool";

export const bsMetadataApi = createApi({
    reducerPath: "bsMetadataApi",
    baseQuery: bsMetadataBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [BS_METADATA_TAG, PRINCIPAL_METADATA_TAG, ASSET_CLASSES_TAG, WHIRLPOOL_TAG],
    endpoints: (builder) => ({
        bsMetadataByMints: builder.query<BsMetadata[], string[]>({
            query(mints) {
                return {
                    url: GET_BS_METADATA_ROUTE,
                    method: "POST",
                    body: mints
                };
            },
            transformResponse: (raw: ParsedBsMetadata[]) => {
                return raw.map((meta) => convertParsedBsMetadataToBsMetadata(meta));
            },
            providesTags: [BS_METADATA_TAG]
        }),

        assetClasses: builder.query<AssetClassInfo[], string[]>({
            query(custodians) {
                return {
                    url: GET_ASSET_CLASS_INFO_ROUTE,
                    method: "POST",
                    body: custodians
                };
            },
            transformResponse: (raw: { info: Omit<AssetClassInfo, "schema">; schema: { schema: string } }[]) => {
                return raw.map(({ info, schema: { schema } }) => {
                    const parsedSchema = safeJSONParse(schema, {}) as JSONSchema;
                    return { ...info, schema: parsedSchema };
                });
            },
            providesTags: [ASSET_CLASSES_TAG]
        }),
        whirlpoolPositions: builder.query<OrcaPositionInfo, string[]>({
            query(mints) {
                return {
                    url: GET_ORCA_POSITION_INFO_ROUTE,
                    method: "POST",
                    body: mints,
                    responseHandler: async (response) => {
                        const text = await response.text();

                        // Replace large numbers with strings to preserve precision
                        const keysToPreserve = ["upperSqrtPrice", "lowerSqrtPrice", "sqrtPrice"];

                        const preservedText = keysToPreserve.reduce((acc, key) => {
                            const pattern = new RegExp(`"${key}"\\s*:\\s*(\\d+)`, "g");
                            return acc.replace(pattern, `"${key}":"$1"`);
                        }, text);

                        return JSON.parse(preservedText);
                    }
                };
            },

            providesTags: [WHIRLPOOL_TAG]
        })
    })
});

export const { useAssetClassesQuery, useWhirlpoolPositionsQuery } = bsMetadataApi;

export function useBsMetadataByMints(mints: (string | undefined)[] | undefined) {
    const uniqueMints = removeDuplicates(mints?.filter(filterTruthy) ?? []);
    const tokenList = useTokenListQuery();

    const mintToToken = useMemo(() => {
        if (!tokenList.data) return undefined;
        return new Map(tokenList.data.map((t) => [t.assetMint, t]));
    }, [tokenList.data]);

    const nonPrincipalMints = useMemo(
        () => uniqueMints.filter((m) => (mintToToken ? !mintToToken.has(m) : false)),
        [mintToToken, uniqueMints]
    );

    const metadata = bsMetadataApi.endpoints.bsMetadataByMints.useQuery(nonPrincipalMints, {
        skip: !nonPrincipalMints?.length || !tokenList.data?.length
    });

    const mintToMetadata = useMemo(() => {
        if (!metadata.data) return undefined;
        return new Map(metadata.data.map((t) => [t.assetMint, t]));
    }, [metadata.data]);

    const getMetadata = useCallback(
        (
            mint: string | undefined,
            assetType: AssetTypeIdentifier = AssetTypeIdentifier.SplToken
        ): BsMetadata | undefined => {
            if (!mint) return undefined;
            if (isStakedSol(assetType)) {
                return mintToToken?.get(STAKED_SOL_MINT) ?? mintToMetadata?.get(STAKED_SOL_MINT);
            }
            return mintToToken?.get(mint) ?? mintToMetadata?.get(mint);
        },
        [mintToMetadata, mintToToken]
    );

    return {
        getMetadata,
        isLoading: tokenList.isLoading || metadata.isFetching || metadata.isLoading,
        isFetching: tokenList.isFetching || metadata.isFetching
    };
}

export const useBsMetadataApi = () => {
    const api = bsMetadataApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchBsMetadataByMints] = api.bsMetadataByMints.useLazyQuery();
    const [fetchAssetClasses] = api.assetClasses.useLazyQuery();

    return {
        resetBsMetadataApi: () => dispatch(bsMetadataApi.util.invalidateTags([BS_METADATA_TAG])),
        resetPrincipalTokenMetadata: () => dispatch(bsMetadataApi.util.invalidateTags([PRINCIPAL_METADATA_TAG])),
        resetAssetClasses: () => dispatch(bsMetadataApi.util.invalidateTags([ASSET_CLASSES_TAG])),
        resetWhirlpoolPositions: () => dispatch(bsMetadataApi.util.invalidateTags([WHIRLPOOL_TAG])),
        fetchBsMetadataByMints,
        fetchAssetClasses
    };
};

export function useBsMetadataByMint(identifier: string | undefined) {
    const { getMetadata } = useBsMetadataByMints(identifier ? [identifier] : []);
    return getMetadata(identifier);
}
