export enum AbfRole {
    Member = "Member",
    EscrowManager = "EscrowManager", // can withdraw assets from escrow
    Borrower = "Borrower", // can borrow on behalf of org
    Lender = "Lender", // can lend on behalf of org
    Admin = "Admin", // can edit roles for all users in an org, except other admins
    SuperAdmin = "SuperAdmin" // can edit roles for all users in an org, including admins
}

export enum AbfRoleNumber {
    Member,
    EscrowManager,
    Borrower,
    Lender,
    Admin,
    SuperAdmin
}

export interface AbfRolesTransaction {
    role: AbfRole;
    user: string;
    transaction: string;
    direction: "Add" | "Remove";
}
