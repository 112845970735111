import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { TransactionAuthenticated } from "@bridgesplit/react";
import { WALLET_TXN_HEADER } from "@bridgesplit/abf-sdk";

import {
    EditLendingStrategyInput,
    LendingStrategyFilter,
    StrategyInfo,
    EditAssetTermsInput,
    CreateOfferFromPresetParams,
    StrategyTemplateInfo
} from "../types";
import { abfSerializeQueryArgs, napoleonBaseQuery } from "./util";
import {
    ROUTE_UPDATE_LENDING_STRATEGY,
    ROUTE_GET_LENDING_STRATEGY_INFOS,
    ROUTE_DELETE_LENDING_OFFER,
    ROUTE_UPDATE_ASSET_TERMS,
    ROUTE_PUT_OFFER_FROM_PRESETS,
    ROUTE_GET_PRESET_TEMPLATES
} from "../constants";

const LENDING_STRATEGY_TAG = "LendingStrategy";

export const abfLendingStrategyApi = createApi({
    reducerPath: "abfLendingStrategyApi",
    baseQuery: napoleonBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [LENDING_STRATEGY_TAG],
    endpoints: (builder) => ({
        lendingStrategyInfos: builder.query<Record<string, StrategyInfo>, LendingStrategyFilter>({
            providesTags: [LENDING_STRATEGY_TAG],
            query(filter) {
                return {
                    url: ROUTE_GET_LENDING_STRATEGY_INFOS,
                    method: "POST",
                    body: filter
                };
            }
        }),
        strategyTemplates: builder.query<StrategyTemplateInfo[], void>({
            query() {
                return {
                    url: ROUTE_GET_PRESET_TEMPLATES,
                    method: "POST",
                    body: { principalMints: [] }
                };
            }
        }),
        editLendingStrategy: builder.mutation<void, TransactionAuthenticated<EditLendingStrategyInput>>({
            invalidatesTags: [LENDING_STRATEGY_TAG],
            query({ signedTransaction, ...input }) {
                return {
                    url: ROUTE_UPDATE_LENDING_STRATEGY,
                    method: "PUT",
                    body: input,
                    headers: { [WALLET_TXN_HEADER]: signedTransaction }
                };
            }
        }),

        deleteLendingStrategyOffers: builder.mutation<void, TransactionAuthenticated<{ identifiers: string[] }>>({
            invalidatesTags: [LENDING_STRATEGY_TAG],
            query({ signedTransaction, identifiers }) {
                return {
                    url: ROUTE_DELETE_LENDING_OFFER,
                    method: "DELETE",
                    body: identifiers,
                    headers: { [WALLET_TXN_HEADER]: signedTransaction }
                };
            }
        }),

        editLendingStrategyAssetTerms: builder.mutation<void, TransactionAuthenticated<EditAssetTermsInput>>({
            invalidatesTags: [LENDING_STRATEGY_TAG],
            query({ signedTransaction, ...body }) {
                return {
                    url: ROUTE_UPDATE_ASSET_TERMS,
                    method: "PUT",
                    body,
                    headers: { [WALLET_TXN_HEADER]: signedTransaction }
                };
            }
        }),
        putOfferFromPresets: builder.mutation<string, TransactionAuthenticated<CreateOfferFromPresetParams>>({
            invalidatesTags: [LENDING_STRATEGY_TAG],
            query({ signedTransaction, ...body }) {
                return {
                    url: ROUTE_PUT_OFFER_FROM_PRESETS,
                    method: "PUT",
                    body,
                    headers: { [WALLET_TXN_HEADER]: signedTransaction }
                };
            }
        })
    })
});

export const {
    useLendingStrategyInfosQuery,
    useStrategyTemplatesQuery,
    useEditLendingStrategyMutation,
    useDeleteLendingStrategyOffersMutation,
    useEditLendingStrategyAssetTermsMutation,
    usePutOfferFromPresetsMutation
} = abfLendingStrategyApi;

export const useLendingStrategyApi = () => {
    const dispatch = useDispatch();

    return {
        resetLendingStrategyApi: () => dispatch(abfLendingStrategyApi.util.invalidateTags([LENDING_STRATEGY_TAG]))
    };
};
