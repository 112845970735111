import { memo } from "react";

import {
    Column,
    Dot,
    formatChartPercent,
    Icon,
    PriceLineChartWithAxis,
    Row,
    Text,
    TooltipText,
    useAppPalette
} from "@bridgesplit/ui";
import { filterNullableRecord, filterTruthy, formatDate, formatPercent, percentUiToDecimals } from "@bridgesplit/utils";
import { RateMarketType, RoleView, useRatesHistoryQuery } from "@bridgesplit/abf-react";
import { formatDurationWithTypeShorthand } from "@bridgesplit/abf-sdk";

import { useMarketContext } from "./MarketContext";
import { useQuotesContext } from "./QuotesContext";
import { isStrategyDurationSame } from "../util";
import { DurationSelect, RoleViewSelect } from "./util";
import { RatesComparisonPeriodSelect, useRatesComparisonPeriodSelect } from "../../common";

type RatesInfo = {
    date: number;
    borrowApy: number | undefined;
    lendApy: number | undefined;
    loopscaleApy: number | undefined;
};

export default memo(function RatesComparison({
    height = 270,
    includeHeader = true,
    isMobile,
    customPeriod
}: {
    height?: number;
    includeHeader?: boolean;
    isMobile?: boolean;
    customPeriod?: number;
}) {
    const periodController = useRatesComparisonPeriodSelect("RatesComparisonPeriodSelect");
    const period = customPeriod ?? periodController.period;
    const history = useRateHistory({ period });
    const { view } = useMarketContext();
    const colors = useBorrowRateColors();

    const content = (
        <PriceLineChartWithAxis
            height={height}
            loading={!history?.length}
            keys={[
                view === RoleView.Lend
                    ? { key: "lendApy", color: colors.lendApy }
                    : { key: "borrowApy", color: colors.borrowApy },
                { key: "loopscaleApy", color: colors.loopscaleApy }
            ]}
            tooltip={Tooltip}
            formatXAxis={(seconds) => formatDate(seconds, "date", { showTime: "never" })}
            formatYAxis={formatChartPercent}
            minTickGap={250}
            data={history}
        />
    );

    if (includeHeader) {
        return (
            <Column width="100%" spacing={1}>
                <TooltipText helpText="Historical APY on Loopscale and other lending protocols" variant="h4">
                    Rate history
                </TooltipText>
                <Row spaceBetween>
                    <Row spacing={1} sx={{ mx: -1 }}>
                        {isMobile && <RoleViewSelect label="Rates" />}
                        <DurationSelect label="Term" />
                    </Row>
                    {!customPeriod && <RatesComparisonPeriodSelect {...periodController} />}
                </Row>

                {content}
            </Column>
        );
    }

    return <>{content}</>;
});

function Tooltip(rates: RatesInfo | undefined) {
    const colors = useBorrowRateColors();
    const { preset } = useQuotesContext();
    const { view } = useMarketContext();
    if (!rates) return null;
    const { borrowApy, lendApy, loopscaleApy, date } = rates;

    return (
        <Column sx={{ minWidth: "150px" }} spacing={1.5}>
            <Text color="caption">
                <Icon type="date" /> {formatDate(date)}
            </Text>

            <Column spacing={1}>
                <Text variant="body2">Loopscale</Text>
                <RateDisplay
                    label={`${formatDurationWithTypeShorthand(preset?.duration, preset?.durationType)} APY`}
                    apy={loopscaleApy}
                    color={colors.loopscaleApy}
                />
                <Text variant="body2">Market (other apps)</Text>
                {view === RoleView.Lend ? (
                    <RateDisplay label="Lend APY" apy={lendApy} color={colors.lendApy} />
                ) : (
                    <RateDisplay label="Borrow APY" apy={borrowApy} color={colors.borrowApy} />
                )}
            </Column>
        </Column>
    );
}

function RateDisplay({ apy, color, label }: { apy: number | undefined; color: string; label: string }) {
    return (
        <Row spaceBetween spacing={2}>
            <Text color="caption" variant="body2">
                {label}
            </Text>
            <Text variant="body2">
                <Dot color={color} /> {formatPercent(apy)}
            </Text>
        </Row>
    );
}

function useBorrowRateColors() {
    const { textDisabled, secondary } = useAppPalette();

    return { borrowApy: textDisabled, lendApy: textDisabled, loopscaleApy: secondary };
}

type RatesInfoWithCount = RatesInfo & {
    dataCount: number;
};
function useRateHistory({ period }: { period: number }) {
    const { principalMint } = useMarketContext();
    const { data, isFetching } = useRatesHistoryQuery(
        { timeLookback: period, principalMints: principalMint ? [principalMint] : [] },
        { skip: !principalMint }
    );
    const { preset } = useQuotesContext();

    if (!data || !preset || isFetching) return undefined;

    const timeToRates = new Map<number, RatesInfoWithCount>();

    for (const moment of Object.values(data).flat()) {
        const isLoopscale = moment.marketType === RateMarketType.Loopscale;

        if (isLoopscale && !isStrategyDurationSame(moment, preset)) continue;

        const prev = timeToRates.get(moment.timestamp);
        const borrowApy = percentUiToDecimals(moment.borrowApy);
        const lendApy = percentUiToDecimals(moment.lendApy);
        const prevDataCount = prev?.dataCount ?? 0;

        const newRate: RatesInfoWithCount = {
            date: moment.timestamp,
            loopscaleApy: isLoopscale ? borrowApy : prev?.loopscaleApy,
            borrowApy: borrowApy + (prev?.borrowApy ?? 0),
            lendApy: lendApy + (prev?.lendApy ?? 0),
            dataCount: isLoopscale ? prevDataCount : prevDataCount + 1
        };
        timeToRates.set(moment.timestamp, newRate);
    }

    return Array.from(timeToRates.values())
        .filter(filterNullableRecord)
        .filter(filterTruthy)
        .filter((r) => !!r.dataCount && r.loopscaleApy !== undefined)
        .map(({ borrowApy, lendApy, dataCount, ...rates }) => ({
            ...rates,
            borrowApy: borrowApy / (dataCount || 1),
            lendApy: lendApy / (dataCount || 1)
        }));
}
