import { BsMetaUtil, formatLeverage, LoopExpanded } from "@bridgesplit/abf-react";
import { EventAvailableOutlined, TuneOutlined, AutoGraphOutlined, AutoModeOutlined } from "@mui/icons-material";

import { OnboardingBullet } from "./constants";

export function generateMarketGuideBullets(loopExpanded: LoopExpanded | undefined): OnboardingBullet[] {
    if (!loopExpanded) return [];
    const collateralSymbol = BsMetaUtil.getSymbol(loopExpanded.collateralToken);
    const principalSymbol = BsMetaUtil.getSymbol(loopExpanded.principalToken);
    const multiplier = loopExpanded?.loopVault.maxLeverage;

    const leverageFormatted = formatLeverage(multiplier ?? 0);

    return [
        {
            Icon: TuneOutlined,
            header: "Select leverage and deposit",
            text: `Choose up to ${leverageFormatted} leverage and deposit ${collateralSymbol}`
        },
        {
            Icon: AutoModeOutlined,
            header: `Increase your ${collateralSymbol} exposure`,
            text: `Borrow ${principalSymbol} against your ${collateralSymbol} and swap it for more ${collateralSymbol}`
        },
        {
            Icon: AutoGraphOutlined,
            header: `Earn yield when ${collateralSymbol} outperforms ${principalSymbol}`,
            text: `Your position earns leveraged ${collateralSymbol} fees while borrowing ${principalSymbol} at fixed rates`
        },
        {
            Icon: EventAvailableOutlined,
            header: "Close position at any time",
            text: `To close, you'll swap your ${collateralSymbol} back to ${principalSymbol}, repay the loan, and keep any remaining ${collateralSymbol} as profit`
        }
    ];
}
