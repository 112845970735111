import { percentDecimalsToUi } from "@bridgesplit/utils";
import { RoleView } from "@bridgesplit/abf-react";

import { BorrowMode, useMarketBorrowContext } from "../borrow";
import { useQuotesContext, RatesOrderbook, useMarketContext } from "../common";
import { useMarketLendContext } from "../lend";

export default function MarketOrderbook() {
    const { view } = useMarketContext();

    if (view === RoleView.Lend) return <LendOrderbook />;
    return <BorrowOrderbook />;
}

function LendOrderbook() {
    const { form, setForm } = useMarketLendContext();
    const { preset } = useQuotesContext();

    return (
        <RatesOrderbook
            selectedApy={preset ? form.presetToApy.get(preset?.presetStrategyIdentifier) : undefined}
            setSelectedApy={(val) =>
                setForm((prev) => {
                    const presetToApy = new Map(prev.presetToApy);
                    for (const key of form.presetToApy?.keys() ?? []) {
                        presetToApy.set(key, val.apy);
                    }

                    return { ...prev, presetToApy };
                })
            }
        />
    );
}

function BorrowOrderbook() {
    const {
        bestQuote,
        form: { collateralMint },
        setForm
    } = useMarketBorrowContext();
    const { collateralMints } = useQuotesContext();

    // only show highlighting if collateral exactly matches
    const isCollateralSame = collateralMints.length === 1 && collateralMints[0] === collateralMint;

    return (
        <RatesOrderbook
            selectedApy={isCollateralSame ? percentDecimalsToUi(bestQuote?.apy) : undefined}
            setSelectedApy={(val) =>
                setForm((prev) => ({
                    ...prev,
                    amount: val.maxBorrow,
                    refetchOutAmount: true,
                    mode: BorrowMode.InputCollateral // don't use Principal type to prevent debounced input from being displayed
                }))
            }
        />
    );
}
