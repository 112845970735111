import { StatColumn } from "@bridgesplit/ui";

import { useHealthChangeStats, useTokenAmountStats } from "../common";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useWithdrawAmounts, useWithdrawLtv } from "./util";

export default function WithdrawCtaStats() {
    const { withdrawForm } = useWhirlpoolContext();
    const withdrawLtv = useWithdrawLtv();
    const healthChangeStats = useHealthChangeStats(withdrawLtv);
    const { tokenAAmount, tokenBAmount } = useWithdrawAmounts();
    const tokenAmountStats = useTokenAmountStats({
        tokenAChange: tokenAAmount ? tokenAAmount * -1 : 0,
        tokenBChange: tokenBAmount ? tokenBAmount * -1 : 0
    });

    if (!withdrawForm.percentToWithdraw || withdrawForm.percentToWithdraw > 1) return null;

    return <StatColumn loading={withdrawLtv.isLoading} stats={[...tokenAmountStats, ...healthChangeStats]} />;
}
