export enum TransactionTypes {
    MarketBorrow = "Borrow",
    MarketLend = "Lend",
    Refinance = "Refinance",
    BorrowMore = "Borrow More",
    Repay = "Repay",
    Topup = "Topup",
    LoanWithdraw = "Withdraw",
    StrategyDeposit = "Strategy Deposit",
    StrategyWithdraw = "Strategy Withdraw",
    EarnBorrow = "Earn Borrow"
}
