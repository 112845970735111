import { useState } from "react";

import {
    AbfRole,
    REGISTER_SIGNATURES,
    useActiveGroup,
    useActiveWallet,
    useGroupMembersQuery,
    useMpcRegisterOrSign,
    useMpcStatus,
    useGroup,
    useUserWallets,
    useAbfFetches,
    useVerifyAbfUserWallet,
    useUserProfile,
    useCreateGroup,
    useAuthStorage,
    getAvatarFromName,
    AbfGroupType,
    getFirstName
} from "@bridgesplit/abf-react";
import { WalletType } from "@bridgesplit/abf-sdk";
import { CheckboxRow, Column, EllipsesText, Icon, OutlinedCard, Row, Text } from "@bridgesplit/ui";
import { useUserPublicKey } from "@bridgesplit/react";
import { Result, SignatureType, formatAddress } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { CheckCircleOutline } from "@mui/icons-material";

import { WalletConnect } from "../common";
import { useGetSignedTransactionAuth } from "../transactions";

export function useWalletTypeForm() {
    const { wallets } = useUserWallets();
    const { isMpcAvailable } = useActiveWallet();
    const publicKey = useUserPublicKey();
    const defaultWalletType = isMpcAvailable || wallets?.length === 0 ? WalletType.MPC : WalletType.SelfCustodial;

    const [walletTypeState, setWalletType] = useState<WalletType>();

    const walletType = walletTypeState ?? defaultWalletType;
    const mpc = useMpcStatus({ skip: walletType !== WalletType.MPC });
    const requiredWalletToRegister = useRequiredWalletToRegister();

    const { register, Component: PasskeyInfo } = useCreatePasskey();

    async function setupWallet(options?: { isNewGroup?: boolean }) {
        if (walletType === WalletType.MPC && !mpc.verified) {
            return await register();
        }
        if (
            !options?.isNewGroup &&
            walletType === WalletType.SelfCustodial &&
            requiredWalletToRegister &&
            publicKey !== requiredWalletToRegister
        ) {
            return Result.errFromMessage(
                `Wallet mismatch. You must register with the wallet you created this account with (${formatAddress(
                    requiredWalletToRegister
                )})`
            );
        }
        return Result.ok();
    }

    const buttonDisabled = walletType === WalletType.SelfCustodial && !publicKey;

    const component = (
        <>
            {defaultWalletType === WalletType.SelfCustodial && (
                <CheckboxRow
                    textProps={{
                        helpText:
                            "Use a Passkey (MPC) wallet to approve changes using biometrics instead of a self-custodial wallet"
                    }}
                    value={walletTypeState === WalletType.MPC}
                    setValue={(val) => setWalletType(val ? WalletType.MPC : WalletType.SelfCustodial)}
                    label="Use a passkey wallet"
                />
            )}

            {walletType === WalletType.MPC && !mpc.verified && <PasskeyInfo size="small" />}

            {defaultWalletType === WalletType.MPC && (
                <CheckboxRow
                    textProps={{
                        helpText:
                            "Use a self-custodial wallet (Phantom, Backpack, etc.) to approve changes instead of a Passkey"
                    }}
                    value={walletTypeState === WalletType.SelfCustodial}
                    setValue={(val) => setWalletType(val ? WalletType.SelfCustodial : WalletType.MPC)}
                    label="Use a self-custodial wallet"
                />
            )}

            {walletType === WalletType.SelfCustodial && <WalletConnect connectedLabel="Wallet to register" />}
        </>
    );

    return { component, walletType, setupWallet, setWalletType, buttonDisabled };
}

export function useVerifyWalletFromForm({ setupWallet, walletType }: ReturnType<typeof useWalletTypeForm>) {
    const verifyWallet = useVerifyAbfUserWallet();
    const { resetMeApi } = useAbfFetches();
    const getSignedTransaction = useGetSignedTransactionAuth();
    const { user } = useUserProfile();
    const { groupIdentifier } = useActiveGroup();
    const createGroup = useCreateGroup();
    const { setGroupIdentifier } = useAuthStorage();

    async function verify() {
        const setupRes = await setupWallet();
        if (!setupRes.isOk()) return setupRes;

        if (walletType === WalletType.MPC) {
            // handle case where group isn't created
            if (!groupIdentifier) {
                const name = getFirstName(user);
                const createGroupRes = await createGroup({
                    signedTransaction: undefined,
                    group_name: name,
                    group_image: getAvatarFromName(name),
                    group_description: "",
                    admin_wallet: undefined,
                    group_type: AbfGroupType.Individual
                });
                if (!createGroupRes.isOk()) return createGroupRes;

                setGroupIdentifier(createGroupRes.unwrap().groupIdentifier);
            }
            return await verifyWallet({ signedTxn: undefined, isMpc: true, dfnsId: user.mpcIdentifier });
        }

        const signedTransactionRes = await getSignedTransaction();
        if (!signedTransactionRes.isOk()) return Result.err(signedTransactionRes);

        return await verifyWallet({ isMpc: false, signedTxn: signedTransactionRes.unwrap() });
    }

    return async () => {
        const res = await verify();
        if (res.isOk()) {
            resetMeApi();
        }
        return res;
    };
}

// Edge case where the first admin is created but verify fails. Need user to verify the original wallet
function useRequiredWalletToRegister() {
    const { permissions } = useGroup();
    const { groupIdentifier } = useActiveGroup();
    const { data } = useGroupMembersQuery(groupIdentifier ?? skipToken, {
        skip: !groupIdentifier || !permissions?.isAdmin()
    });

    if (!data) return undefined;
    const adminUsers = data.existing.filter((user) =>
        groupIdentifier ? user.permissions?.[groupIdentifier]?.includes(AbfRole.SuperAdmin) : false
    );

    // only relevant for first admin
    if (adminUsers.length > 1) return undefined;

    // only a single wallet should exist on-chain
    const adminWallet = Object.entries(data.on_chain).find(([, perms]) => perms.includes(AbfRole.SuperAdmin))?.[0];

    return adminWallet;
}

export function useCreatePasskey() {
    const { passkeyRegistered } = useMpcStatus();

    const [signaturesCompleted, setSignaturesCompleted] = useState<SignatureType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const onSign = (sig: SignatureType) => setSignaturesCompleted((prev) => [...prev, sig]);
    const setSignaturesNeeded = (sigs: SignatureType[]) => {
        const previouslyCompletedSigs = REGISTER_SIGNATURES.filter((s) => !sigs.includes(s));
        setSignaturesCompleted(previouslyCompletedSigs);
    };

    const registerMpc = useMpcRegisterOrSign();

    async function register() {
        setIsLoading(true);
        const res = await registerMpc({ onSign, setSignaturesNeeded });
        setIsLoading(false);
        return res;
    }

    const firstIncomplete = REGISTER_SIGNATURES.filter((s) => !signaturesCompleted.includes(s))[0];

    const showProgress = isLoading || signaturesCompleted.length === REGISTER_SIGNATURES.length;

    // sort by incomplete first
    const sortedStatuses = REGISTER_SIGNATURES.sort((a, b) => {
        const bComplete = signaturesCompleted.includes(b);
        const aComplete = signaturesCompleted.includes(a);
        if (aComplete && bComplete) return 0;
        return aComplete ? -1 : 1;
    });

    const statuses = (
        <>
            {sortedStatuses.map((sig) => {
                const isComplete = signaturesCompleted.includes(sig);
                const isActive = isLoading && firstIncomplete === sig;
                return (
                    <Row key={sig} spacing={0.5}>
                        <Text color={isComplete ? "success" : "disabled"}>
                            <CheckCircleOutline />
                        </Text>
                        <Text color={isActive ? "body" : "caption"} variant="body2">
                            {sig}
                        </Text>
                    </Row>
                );
            })}
        </>
    );

    const Component = ({ size = "regular" }: { size?: "small" | "regular" }) => (
        <OutlinedCard
            hideCard={size === "regular"}
            padding={size === "regular" ? 1.5 : 1}
            spacing={size === "regular" ? 2 : 1}
        >
            <Column>
                {size === "regular" && (
                    <EllipsesText variant="h3" loading={isLoading}>
                        {isLoading ? "Creating Passkey Wallet" : "Create Passkey Wallet"}
                    </EllipsesText>
                )}
                <Text variant="body2" color="caption">
                    {size === "small" && !isLoading && <Icon type="tooltip" />}{" "}
                    {isLoading
                        ? "Please authenticate with your device"
                        : `You will need to sign with your device ${
                              passkeyRegistered ? REGISTER_SIGNATURES.length - 1 : REGISTER_SIGNATURES.length
                          } times`}
                </Text>
            </Column>
            {showProgress && <Column spacing={1}>{statuses}</Column>}
        </OutlinedCard>
    );

    return { Component, register, isLoading };
}
