import { BsMetaUtil } from "@bridgesplit/abf-react";
import { MediaStats, StatProps } from "@bridgesplit/ui";
import { bsMath, formatPercent, formatStatNum } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { useMarketContext } from "../common/MarketContext";
import { MARKET_DETAIL_BREAKPOINT, useMarketCapDetails } from "../util";
import { BorrowCapWarning } from "../../common";

export default function MarketStats() {
    const { token, borrowCap, principalStats: data } = useMarketContext();
    const symbol = BsMetaUtil.getSymbol(token);

    const principalUtilized = data?.principalStats.principalUtilized;

    const totalDeposits = data?.strategyStats.totalDeposits;
    const borrowCapDetails = useMarketCapDetails();

    const borrowedAmount = principalUtilized;

    const totalSupplied = bsMath.add(totalDeposits, borrowedAmount);
    const utilization = bsMath.div(borrowedAmount, totalSupplied);

    const stats: StatProps[] = [
        { value: formatStatNum(totalSupplied, { symbol }), caption: "Supplied" },
        { value: formatStatNum(borrowedAmount, { symbol }), caption: "Borrowed" },
        {
            value: formatPercent(utilization),
            caption: "Utilization"
        },
        {
            value: (
                <BorrowCapWarning borrowCapWarning={borrowCapDetails?.warningMessage}>
                    {formatStatNum(borrowCap?.global, { symbol })}
                </BorrowCapWarning>
            ),
            caption: "Borrow cap"
        },
        {
            value: formatStatNum(data?.principalStats.principalOriginated, { symbol }),
            caption: COPY.ORIGINATED_ALL_TIME_TERM
        }
    ];

    return (
        <MediaStats
            query={MARKET_DETAIL_BREAKPOINT}
            variant="body1"
            loading={!data || borrowCap === undefined}
            stats={stats}
        />
    );
}
