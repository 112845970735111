import { createApi } from "@reduxjs/toolkit/query/react";
import { IS_LOCAL_NX_DEV } from "@bridgesplit/utils";

import { ROUTE_FETCH_DISCORD, ROUTE_VERIFY_DISCORD } from "../constants/routes";
import { abfSerializeQueryArgs, rubyBaseQuery } from "./util";

const DISCORD_POINTS = "DiscordPoints";

export const discordPointsApi = createApi({
    reducerPath: "discordPointsApi",
    tagTypes: [DISCORD_POINTS],
    baseQuery: rubyBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        discordUsername: builder.query<string | null, string | undefined>({
            providesTags: [DISCORD_POINTS],
            query: () => ({
                url: ROUTE_FETCH_DISCORD,
                method: "GET"
            })
        }),
        verifyDiscordJoin: builder.mutation<void, string>({
            invalidatesTags: [DISCORD_POINTS],
            query: (param) => ({
                url: ROUTE_VERIFY_DISCORD + "/" + param,
                method: "POST"
            })
        })
    })
});

export const { useVerifyDiscordJoinMutation, useDiscordUsernameQuery } = discordPointsApi;

export function useDiscordPointsApi() {
    return { resetDiscordInfo: () => discordPointsApi.util.invalidateTags([DISCORD_POINTS]) };
}

export function getDiscordAuthUrl() {
    if (IS_LOCAL_NX_DEV)
        return `https://discord.com/oauth2/authorize?client_id=1245733161657307186&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fdiscord&scope=guilds+identify`;
    else {
        return `https://discord.com/oauth2/authorize?client_id=1245733161657307186&response_type=code&redirect_uri=https%3A%2F%2Fapp.loopscale.com%2Fdiscord&scope=guilds+identify`;
    }
}
