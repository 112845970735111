import { useState } from "react";

import { BsMetaUtil, ExternalPrice, PricingDataWithQuote, TransactionType } from "@bridgesplit/abf-react";
import {
    Column,
    SkeletonRounded,
    DatePriceDataPoint,
    Stat,
    ToggleTextButtons,
    Text,
    PriceLineChartWithAxis,
    Row,
    Tooltip,
    Image,
    TextVariant,
    FONT_SIZES,
    S3_PATH,
    TextProps,
    MEDIA
} from "@bridgesplit/ui";
import { formatDate, formatTokenAmount, formatUsd } from "@bridgesplit/utils";
import { BsMetadata, OracleQuote, OracleType } from "@bridgesplit/abf-sdk";

import { TokenImage, TokenSize } from "./asset";

export function AssetPriceChart({ data: allQuotes }: { data: PricingDataWithQuote[] }) {
    const [quoteIndex, setQuoteIndex] = useState<number>(0);

    const data = allQuotes?.[quoteIndex];

    if (!data) {
        return (
            <Column spacing={2}>
                <Stat variant="h2" loading value="" caption="" />
                <SkeletonRounded width="100%" height="300px" />
            </Column>
        );
    }

    const { prices, quote } = data;
    const simplePrices = prices.map(({ price, timestamp }): DatePriceDataPoint => ({ date: timestamp, price }));

    const pricePointByTime = new Map(prices.map((t) => [t.timestamp, t]));

    return (
        <Column spacing={2}>
            {allQuotes.length > 1 && (
                <ToggleTextButtons
                    value={quoteIndex}
                    setValue={setQuoteIndex}
                    options={allQuotes.map(({ quote: { symbol } }, i) => ({ label: symbol, value: i }))}
                />
            )}
            <PriceLineChartWithAxis
                keys={[{ key: "price" }]}
                formatYAxis={(price) => formatTokenAmount(price, quote)}
                tooltip={({ price, date }) => {
                    const pricePoint = pricePointByTime.get(date);
                    return (
                        <Column spacing={1}>
                            <Column>
                                <Text variant="h3">{formatTokenAmount(price, quote)}</Text>
                                <Text color="caption">{formatExternalPricingType(pricePoint)}</Text>
                            </Column>
                            <Text color="caption">
                                {formatDate(date, "date")} ({formatDate(date, "relative")})
                            </Text>
                        </Column>
                    );
                }}
                aspectRatio={3}
                data={simplePrices}
            />
        </Column>
    );
}

export function formatExternalPricingType(pricingData: ExternalPrice | undefined) {
    const pricingType = pricingData?.transactionType;

    if (pricingType === undefined) {
        return "Unknown";
    }

    if ([TransactionType.AMMBuy, TransactionType.AmmSell].includes(pricingType)) return "Native Sale";

    switch (pricingType) {
        case TransactionType.DutchAuction:
            return "Dutch Auction";
        case TransactionType.EnglishAuction:
            return "English Auction";
        case TransactionType.OTC:
            return "OTC Sale";
        default:
            return "Unknown";
    }
}

export function OraclePrice({ oracle, variant = "body1" }: { oracle: OracleQuote | undefined; variant?: TextVariant }) {
    return (
        <Text loading={!oracle} loadingWidth="" variant={variant}>
            {formatUsd(oracle?.usdPrice)}
        </Text>
    );
}

export function OracleIcon({ oracle, variant }: { oracle: OracleQuote | undefined; variant: TextVariant }) {
    return (
        <Row>
            {oracle?.baseQuotes.map((o, i) => {
                const meta = ORACLE_META[o.oracleType];
                return (
                    <Tooltip
                        sx={{ ml: i === 0 ? 0 : -0.5, zIndex: oracle.baseQuotes?.length - i }}
                        key={i}
                        title={meta?.name}
                    >
                        <Image
                            variant="circle"
                            size={FONT_SIZES[variant] + "px"}
                            src={`${S3_PATH}/oracles/${meta?.iconName}`}
                        />
                    </Tooltip>
                );
            })}
        </Row>
    );
}

const ORACLE_META: Record<OracleType, { iconName: string; name: string }> = {
    [OracleType.Pyth]: { name: "Pyth", iconName: "pyth.png" },
    [OracleType.PythLegacy]: { name: "Pyth Legacy", iconName: "pyth.png" },
    [OracleType.MeteoraLP]: { name: "Meteora LP", iconName: "met-lp.png" },
    [OracleType.MeteoraVault]: { name: "Meteora Vault", iconName: "met-vault.png" },
    [OracleType.OrcaWhirlpool]: { name: "Orca Whirlpool", iconName: "orca.png" },
    [OracleType.StakePoolLST]: { name: "Staking Program", iconName: "stake.png" },
    [OracleType.SwitchboardPull]: { name: "Switchboard", iconName: "switchboard.png" }
};

export function TokenAmountWithUsd({
    amount,
    amountUsd,
    token,
    fontWeight,
    tokenSize = "sm",
    loading: propsLoading = false,
    display = "logo",
    variant,
    condensedOnMobile
}: {
    amount: number | undefined;
    amountUsd: number | undefined;
    token: BsMetadata | undefined;
    tokenSize?: TokenSize;
    loading?: boolean;
    display?: "logo" | "symbol" | "both";
    condensedOnMobile?: boolean;
} & Pick<TextProps, "variant" | "fontWeight">) {
    const loading = propsLoading || amount === undefined || amountUsd === undefined;

    return (
        <Row spacing={0.5}>
            {display !== "symbol" && <TokenImage size={tokenSize} metadata={token} />}
            <Text loadingWidth="60px" loading={loading} fontWeight={fontWeight} variant={variant}>
                {BsMetaUtil.formatAmount(token, amount, {
                    hideSymbol: display === "logo",
                    decimals: condensedOnMobile ? 2 : undefined
                })}
            </Text>
            {!loading && (
                <Text
                    sx={condensedOnMobile ? { [MEDIA.SM.below]: { display: "none" } } : undefined}
                    fontWeight="normal"
                    color="caption"
                    variant={variant}
                >
                    {formatUsd(amountUsd)}
                </Text>
            )}
        </Row>
    );
}
