import {
    useOraclePrices,
    useAbfTypesToUiConverter,
    AbfLoanExpanded,
    summarizeLedgerAccounts,
    calculateHealthRatio,
    calculateLoanHealth
} from "@bridgesplit/abf-react";
import { bsMath } from "@bridgesplit/utils";

import { CollateralChange, CollateralLoanHealthChange } from "./types";

export function useCalculateNewLoanHealth(mints: string[] | undefined) {
    const { getOracle } = useOraclePrices(mints);
    const { getDecimals } = useAbfTypesToUiConverter(mints);

    return function (loanExpanded: AbfLoanExpanded | undefined, collateralChanges: CollateralChange[] | undefined) {
        if (!loanExpanded || !collateralChanges) return undefined;
        const { totalOutstanding } = summarizeLedgerAccounts(loanExpanded);

        const originalCollateralValueUsd = loanExpanded.collateral
            .map((collateral) =>
                bsMath.tokenAMul(collateral.amount, collateral.usdPrice ?? 0, collateral.metadata.decimals)
            )
            .reduce((acc, value) => bsMath.add(acc, value) ?? 0, 0);

        let newLoanCollateralValueUsd = originalCollateralValueUsd;

        for (const { collateralMint, change } of collateralChanges) {
            const usdValueCollateralChange =
                getOracle(collateralMint)?.getUsdAmount(change, getDecimals(collateralMint)) ?? 0;
            if (change > 0) {
                newLoanCollateralValueUsd += usdValueCollateralChange;
            } else {
                newLoanCollateralValueUsd -= usdValueCollateralChange;
            }
        }

        const totalOwedValue = getOracle(loanExpanded.principalMetadata.assetMint)?.getUsdAmount(
            totalOutstanding,
            loanExpanded.principalMetadata.decimals
        );

        const { health: originalHealth } = calculateLoanHealth(loanExpanded);

        const newHealth = calculateHealthRatio(
            totalOwedValue,
            newLoanCollateralValueUsd,
            loanExpanded.order.liquidationThreshold
        );

        const result: CollateralLoanHealthChange = {
            newHealth,
            originalHealth,
            originalCollateralValueUsd,
            newLoanCollateralValueUsd
        };

        return result;
    };
}
