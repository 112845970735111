import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { AbfFeeAccount, FeeScheduleInfo } from "@bridgesplit/abf-sdk";

import { LoanFilterApi } from "../types";
import { GET_FEES_ROUTE, GET_FEE_INFOS_FOR_LOANS, GET_POTENTIAL_FEES_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";

const FEES_TAG = "Fees";
export const feesApi = createApi({
    reducerPath: "feesApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [FEES_TAG],
    endpoints: (builder) => ({
        fees: builder.query<Record<string, AbfFeeAccount[]>, LoanFilterApi>({
            providesTags: [FEES_TAG],
            query(params) {
                return {
                    url: GET_FEES_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        }),
        potentialFees: builder.query<Record<string, AbfFeeAccount[]>, LoanFilterApi>({
            query(params) {
                return {
                    url: GET_POTENTIAL_FEES_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        }),

        getFeeScheduleInfosForLoans: builder.query<Record<string, FeeScheduleInfo>, LoanFilterApi>({
            query(input) {
                return {
                    url: GET_FEE_INFOS_FOR_LOANS,
                    method: "POST",
                    body: input
                };
            }
        })
    })
});

export const { useFeesQuery, usePotentialFeesQuery, useGetFeeScheduleInfosForLoansQuery } = feesApi;

export const useFeesApi = () => {
    const api = feesApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchFees] = api.fees.useLazyQuery();
    const [fetchPotentialFees] = api.potentialFees.useLazyQuery();

    return {
        resetFeesApi: () => dispatch(feesApi.util.invalidateTags([FEES_TAG])),
        fetchFees,
        fetchPotentialFees
    };
};
