import { DEFAULT_PRINCIPAL_MINT, NullableRecord, filterNullableRecord } from "@bridgesplit/utils";

import { useLedgerAccountsQuery, usePaymentEventsQuery } from "../reducers";
import { useAbfTypesToUiConverter } from "../utils";
import { LedgerAccountFilter, PaymentEventsFilter, AbfLedgerExpanded } from "../types";
import { useSkipUnauthenticated } from "./auth";

export function useLedgerAccounts(filter: LedgerAccountFilter, params?: { skip?: boolean }) {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const { data: rawData, ...rest } = useLedgerAccountsQuery(filter, {
        skip: params?.skip || skipIfUnauthenticated
    });
    const principalMints = Object.values(rawData?.data.orders ?? {}).map((o) => o.principalMint);
    const { getMetadata, convertLedgerAccount, convertLoan, convertOrder, tokensLoading } =
        useAbfTypesToUiConverter(principalMints);

    const isLoading = tokensLoading || rest.isLoading || rest.isFetching;

    const data = rawData?.data.ledgerAccounts
        .map((l): NullableRecord<AbfLedgerExpanded> => {
            const rawOrder = rawData.data.orders?.[l.orderAddress];
            const rawLoan = rawData.data.loans?.[l.orderAddress];

            const order = rawOrder ? convertOrder(rawOrder) : undefined;
            const loan = rawLoan ? convertLoan(rawLoan) : rawLoan;

            const ledgerAccount = convertLedgerAccount(l, order?.principalMint ?? DEFAULT_PRINCIPAL_MINT);
            const principalMetadata = getMetadata(order?.principalMint);
            return { loan, ledgerAccount, order, principalMetadata };
        })
        .filter(filterNullableRecord);

    return { data: isLoading ? undefined : data, total: rawData?.total, query: { isLoading } };
}

export function usePaymentEvents(
    principalMint: string | undefined,
    filter: PaymentEventsFilter,
    params?: { skip?: boolean }
) {
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const { data: rawData, ...rest } = usePaymentEventsQuery(filter, {
        skip: params?.skip || skipIfUnauthenticated
    });
    const { convertPaymentEvent, tokensLoading } = useAbfTypesToUiConverter([principalMint]);

    const isLoading = tokensLoading || rest.isLoading || rest.isFetching || !principalMint;

    const data =
        principalMint && !tokensLoading ? rawData?.data.map((l) => convertPaymentEvent(l, principalMint)) : undefined;

    return { data: tokensLoading ? undefined : data, total: rawData?.total, query: { isLoading }, isLoading };
}
