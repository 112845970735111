import {
    TransactionGenerationType,
    TransactionInstructionResponse,
    VersionedTransactionResponse
} from "@bridgesplit/utils";
import { VersionedTransaction } from "@solana/web3.js";

import { FeeType, NewFeeSchedule } from "./fee";
import { AbfOrderSide, CompoundingFrequency } from "./order";
import { SanctumRoute, StakeAccountWithAmount } from "./escrow";
import { AssetTypeIdentifier } from "./request";
import { DurationUnit } from "./time";
import { ExternalYieldSource } from "./external-yield";

export interface AbfTransactionAuthentication {
    groupIdentifier: string | undefined;
    bearerToken: string | undefined;
    userWallet: string | undefined;
}

export interface CreateOrderArgs {
    designatedTaker: string; // intended filler of the order, default key for any filler
    designatedTakerEscrowNonce: string | undefined; // if intender filler is present, escrow nonce for filler account to create LUT in BE
    principalMint: string; // principal for order
    principalAmount: number; // initial amount for order
    collateralMint: string; // collateral for order
    collateralAmount: number; // amount collateral to borrow against
    makerRole: number; // 0 = borrower, 1 = lender
    maxOutstandingPayments: number;
    frequencyRrule: string;
    apy: number; // bps, 1 = 0.01% = 1bps
    duration: number;
    durationType: string;
    syndicateNonce?: string;
    makerEscrow: string;
    nonce?: string;
    feeSchedules: NewFeeSchedule[];
    allowEarlyRepayments: boolean;
    liquidationThreshold?: number; // defaults to u64.max if null
    compoundingFrequency?: CompoundingFrequency; // defaults to simple if null
}

export interface DepositAssetArgs {
    deposit_mint: string;
    amount: number;
    escrow_nonce: string;
    organization_identifier: string;
}

export interface StakeAccountArgs {
    address: string;
    amountToTransfer: number;
}

export interface StakedSolAssetArgs {
    stakeAccounts: StakeAccountArgs[];
    escrowNonce: string;
    groupIdentifier: string;
    recipient?: string;
}

export interface SplitStakeIxParams {
    parentStakePubkey: string;
    amount: number;
    recipient: string;
}

export interface WithdrawAssetArgs {
    deposit_mint: string;
    amount: number;
    escrow_account: string;
    recipient: string;
    extra_instructions: ExtraInstructionArgs;
    withdraw_all?: boolean;
}

export interface EditEscrowArgs {
    nonce: string | undefined;
    origination_cap: number;
    external_yield_source: ExternalYieldSource;
    principal_mint: string;
}

export interface ExtraInstructionArgs {
    /// ixs to prepend
    preInstructions?: TransactionInstructionResponse[];
    /// ixs to postpend
    postInstructions?: TransactionInstructionResponse[];
    /// total compute units for txn
    computeUnits?: number;
}

export interface EscrowTransferAssetArgs {
    sender_escrow_account: string;
    recipient_escrow_nonce: string;
    deposit_mint: string;
    amount: number;
    extra_instructions?: ExtraInstructionArgs;
}

export interface TransferAssetArgs {
    sender: string;
    receiver: string;
    mint: string;
    amount: number;
    payer: string;
}

export interface IncreaseCreditTransactionArgs {
    loan: string;
    strategyIdentifier: string;
    increaseAmount: number;
}

export interface UnstakeRequestWithRoute {
    stakeAccount: StakeAccountWithAmount;
    route: SanctumRoute;
}

export interface RepayLoanArgs {
    loan_vault: string;
    principal_mint: string;
    order: string;
    repayment_args: RepaymentArgs[];
}

export interface RepaymentArgs {
    amount_from_wallet: number;
    amount_from_escrow: number;
    ledger_id: number;
    overpay: boolean;
    repayment_type: RepaymentType;
    full_repay: FullRepayArgs;
}

export interface ZcFeeRepayArgs {
    amount_from_wallet: number;
    amount_from_escrow: number;
}

export interface FullRepayArgs {
    full_repay: boolean;
    repay_from_wallet: boolean;
}

export interface RepayZcLoanArgs extends RepayLoanArgs {
    full_repay: FullRepayArgs;
    // for now, only early repayments are supported
    fee_repayment_args: { [FeeType.EarlyRepayment]: ZcFeeRepayArgs } | undefined;
}

export interface SyncLedgersArgs {
    orderAddress: string;
}

export enum RepaymentType {
    Principal,
    Interest
}

export interface RepayAllLedgersArgs {
    loan_vault: string;
    amount: number;
}

export interface SetupLoanArgs {
    order: string;
}

export interface LoanWithdrawArgs {
    order: string;
    loan_vault: string;
    principal_mint: string;
    collateral_mint: string;
    credit_note: string;
    creditor_account: string;
    debt_note: string;
    debtor_account: string;
}

export interface CancelOrderArgs {
    order: string;
    maker_role: number;
}

export interface CheckDefaultArgs {
    loan_vault: string;
}

export interface FillOrderArgs {
    order: string;
    order_maker: string;
    collateral_mint: string;
    principal_mint: string;
    loan_request_nft: string;
    maker_role: AbfOrderSide;
    filler_escrow: string;
    use_filler_escrow: boolean;
    self_generate_lut: boolean;
}

export interface RoleSetupArgs {
    user?: string;
}

export interface LockboxInitArgs {
    user: string;
    lockboxNftMint: string;
    name: string;
    escrowDeposit: boolean;
    groupIdentifier: string;
    escrowNonce: string;
}

export interface LockboxAssetUpdateArgs {
    user: string;
    assetMint: string;
    amount: number;
    lockbox: string;
    name?: string;
    escrowDeposit: boolean;
    escrowAccount?: string | null;
    side?: ManageSide;
    assetType: LockboxAssetType;
}

export enum ManageSide {
    Deposit,
    Withdraw
}

// Define a type that includes both the simple 'SplToken' and a structured 'OrcaPosition'
export type LockboxAssetType = "SplToken" | OrcaPositionLbAssetType;

// Define the OrcaPosition type as an object with a single string property
export type OrcaPositionLbAssetType = { OrcaPosition: string };

export interface LockboxCloseArgs {
    lockboxAddress: string;
    escrowAccount: string | null;
}

export interface StakedSolLockboxArgs {
    stakeAccounts: StakeAccountArgs[];
    lockbox: string;
    groupIdentifier: string;
    escrowNonce: string;
    escrow: boolean;
    side?: ManageSide;
}

export interface LockboxUnlockArgs {
    user: string;
    lockbox: string;
    escrowDeposit: boolean;
    escrowAccount: string;
}

export interface SyndicatedOrderFundArgs {
    syndicatedOrder: string;
    amount: number;
}

export interface SyndicatedOrderWithdrawArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    amount: number;
}

export interface SyndicatedOrderUpdateArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    amount: number;
    side: SyndicatedOrderUpdateArgsSide;
}

export interface SyndicatedOrderRedeemArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    ledgerId: number;
}

export interface SyndicatedOrderPlaceArgs {
    syndicatedOrder: string;
}

export interface SyndicatedOrderCancelArgs {
    syndicatedOrder: string;
}

export interface SyndicatedOrderFundArgs {
    syndicatedOrder: string;
    amount: number;
}

export interface SyndicatedOrderWithdrawArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    amount: number;
}

export interface SyndicatedOrderUpdateArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    amount: number;
    side: SyndicatedOrderUpdateArgsSide;
}

export enum SyndicatedOrderUpdateArgsSide {
    Withdraw,
    Deposit
}

export interface SyndicatedOrderRedeemArgs {
    syndicatedOrder: string;
    fundNftMint: string;
    ledgerId: number;
}

export interface SyndicatedOrderCancelArgs {
    syndicatedOrder: string;
}

export interface CreateStrategyTxnParams {
    strategyIdentifier: string;
    principalMint: string;
    amountFromEscrow?: number;
    amountFromWallet?: number;
    originationCap?: number;
    externalYieldSource?: ExternalYieldSource;
}

export interface CloseStrategyTxnParams {
    strategyIdentifier: string;
}

export interface UpdateLiquidationManagerParams {
    strategyIdentifier: string;
}

export interface UpdateOrderRefinanceTermsParams {
    strategyIdentifier: string;
    order: string;
    refinanceEnabled: boolean;
    maxRefinanceApy: number;
    refinanceDuration: number;
    refinanceDurationType: DurationUnit;
}

export interface FillStrategyOrderParams {
    strategyIdentifier: string;
    mintToCollateralInfo: Record<string, StrategyCollateralInfoParams>;
    principalRequested: number;
    principalMint: string;
    apy: number;
    ltv: number;
    lockboxAddress: string | null; // if lockbox was already created
    refinancedOrder: string | null;
    liquidationThreshold: number | null; // defaults to u64.max if null
    useFillerEscrow: boolean;
    transactionGenerationType: TransactionGenerationType;
}

export interface FillStrategyTransactions<T extends VersionedTransactionResponse | VersionedTransaction> {
    lockboxTransactions: T[];
    orderLutTransactions: T[] | null;
    orderFillTransactions: T[];
}

export interface StrategyCollateralInfoParams {
    amountFromEscrow: number;
    amountFromWallet: number;
    assetTermsIdentifier: string;
    assetTypeDiscriminator: AssetTypeIdentifier;
    assetKey: string;
}

export interface TransactionGenerationQuery {
    transaction_generation_type: TransactionGenerationType;
}

export interface SellLoanParams {
    expectedSalePrice: number;
    slippage?: number;
    loanAddress: string;
    keepInEscrow: boolean;
}

export interface TransferDebtParams {
    recipientEscrow: string;
    loanAddress: string;
}
