import { useMemo } from "react";

import {
    StrategyExpanded,
    useBestQuotes,
    usePresets,
    isOfferSameAsPreset,
    usePutOfferFromPresetsMutation,
    useMinPrincipalDeposit,
    useSupportedCollateral,
    CollateralWithPreset,
    StrategyDuration
} from "@bridgesplit/abf-react";
import { useMemoizedGroupedMap } from "@bridgesplit/ui";
import { decimalsToBps, findMinElement } from "@bridgesplit/utils";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { mutationIntoResult } from "@bridgesplit/react";

import { PutTermsParams, TermsForStrategy } from "./type";

export function usePutTerms() {
    const [createOffers] = usePutOfferFromPresetsMutation();

    return async (
        signedTransaction: string | undefined,
        strategyIdentifier: string,
        terms: PutTermsParams[],
        collateral: BsMetadata[]
    ) => {
        const selectedCollateral = new Set(collateral.map((c) => c.assetMint));

        const offerTermsToApy = new Map<string, number>();
        for (const { presetOffers, newApy } of terms) {
            for (const offer of presetOffers) {
                if (selectedCollateral.has(offer.collateralMint) && newApy !== undefined) {
                    offerTermsToApy.set(offer.presetOfferIdentifier, decimalsToBps(newApy));
                }
            }
        }

        return await mutationIntoResult(
            createOffers,
            {
                signedTransaction,
                strategyIdentifier,
                offerTermsToApy: Object.fromEntries(offerTermsToApy.entries())
            },
            "update terms"
        );
    };
}

function serializeDuration(assetTerms: StrategyDuration) {
    return JSON.stringify({ duration: assetTerms.duration, durationType: assetTerms.durationType });
}

export function usePutStrategyCollateral() {
    const [createOffers] = usePutOfferFromPresetsMutation();

    return async (
        signedTransaction: string | undefined,
        strategyExpanded: StrategyExpanded,
        collateral: CollateralWithPreset[]
    ) => {
        const { offerWithTermsAndFilter, strategy } = strategyExpanded;
        const durationToApy = new Map(
            offerWithTermsAndFilter
                .map(({ offerTerms }) =>
                    offerTerms.map(({ offerAssetTerms: { terms } }): [string, number] => [
                        serializeDuration(terms),
                        terms.apy
                    ])
                )
                .flat()
        );

        const offerTermsToApy = new Map<string, number>();
        for (const { presets } of collateral) {
            for (const preset of presets) {
                const serializedDuration = serializeDuration(preset.strategyTerms);
                const apy = durationToApy.get(serializedDuration);
                if (apy) {
                    offerTermsToApy.set(preset.offerTerms.presetOfferIdentifier, decimalsToBps(apy));
                }
            }
        }

        return await mutationIntoResult(
            createOffers,
            {
                signedTransaction,
                strategyIdentifier: strategy.identifier,
                offerTermsToApy: Object.fromEntries(offerTermsToApy.entries())
            },
            "update terms"
        );
    };
}

export function useStrategyTerms(strategy: StrategyExpanded | undefined, collateralMints: string[] | undefined) {
    const allCollateral = useSupportedCollateral(strategy?.principalMetadata.assetMint);
    const { allPresets } = usePresets(allCollateral?.map((c) => c.assetMint));

    const { allPresets: collateralAllowedPresets } = usePresets(collateralMints);

    const allowedPresetIds = useMemo(
        () => new Set(collateralAllowedPresets?.map((p) => p.strategyTerms.presetStrategyIdentifier)),
        [collateralAllowedPresets]
    );

    const { lamportAmount } = useMinPrincipalDeposit(strategy?.principalMetadata);

    const { data: bestQuotes } = useBestQuotes({
        minPrincipalAmountLamports: lamportAmount,
        collateralMints,
        principalMint: strategy?.principalMetadata.assetMint,
        presets: allPresets?.map((p) => p.strategyTerms),
        showSelf: true
    });
    const allTerms = strategy?.offerWithTermsAndFilter
        .map(({ offerTerms }) => offerTerms.map(({ offerAssetTerms: { terms } }) => terms))
        .flat();

    const presetsGroupedByTerms = useMemoizedGroupedMap(allPresets, (p) => p.strategyTerms.presetStrategyIdentifier);

    if (!presetsGroupedByTerms || !bestQuotes || !collateralAllowedPresets) return undefined;

    const terms = Array.from(presetsGroupedByTerms.values())?.map((presets): TermsForStrategy => {
        const presetTerms = presets[0].strategyTerms;
        const presetOffers = presets.map((p) => p.offerTerms);
        const strategyAssetTerms = allTerms?.filter((offer) => isOfferSameAsPreset(offer, presetTerms)) ?? [];

        const minApy = findMinElement(strategyAssetTerms, (t) => t.apy)?.apy;

        const allMatchingQuotes = bestQuotes.filter(
            (q) => q.duration === presetTerms.duration && q.durationType === presetTerms.durationType
        );
        const bestQuote = findMinElement(allMatchingQuotes, (q) => q.apy);

        return {
            key: presetTerms.presetStrategyIdentifier,
            allowedForCollateral: allowedPresetIds.has(presetTerms.presetStrategyIdentifier),
            presetOffers,
            presetTerms,
            strategyAssetTerms,
            bestQuote,
            minApy
        };
    });

    return terms;
}
