import { useLenderStrategies } from "@bridgesplit/abf-react";

import { StrategyCards } from "../../strategy";
import { useMarketContext } from "../common";

export default function LendExistingStrategies() {
    const { data: strategies } = useLenderStrategies();
    const { principalMint } = useMarketContext();

    return <StrategyCards strategies={strategies} defaultPrincipalMint={principalMint} />;
}
