import { AbfLoanExpanded, LoanInterestDueInfo, LoanRepaymentParam } from "@bridgesplit/abf-react";
import { AbfLoanVault, AbfOrder } from "@bridgesplit/abf-sdk";

export enum RepaymentActionType {
    SinglePayment = "Next due",
    RepayPrincipal = "Repay principal",
    // PayInFull = "Pay in full",
    PayFees = "Pay fees"
}

export interface MakeRepaymentParams {
    payment: LoanInterestDueInfo;
    loanExpanded: AbfLoanExpanded | undefined;
    isFullRepay: boolean;
}

export type MakeRepaymentProps = {
    loanExpanded: AbfLoanExpanded | undefined;
};

export interface SubmitRepayParams {
    loan: AbfLoanVault;
    order: AbfOrder;
    repayerEscrowAccount: string;
    fullRepay: boolean;
    escrowNeeded: boolean;
    payments: LoanRepaymentParam[];
}

export interface SubmitZcRepayParams {
    loan: AbfLoanVault;
    order: AbfOrder;
    repayerEscrowAccount: string;
    fullRepay: boolean;
    feeAmount: number;
    escrowNeeded: boolean;
    payments: LoanRepaymentParam[];
}
