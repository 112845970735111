import { ReactNode, useEffect } from "react";

import { IconLink, Row, TextVariant } from "@bridgesplit/ui";
import { useCustodianBySlugQuery } from "@bridgesplit/abf-react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "@bridgesplit/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AbfCustodian } from "@bridgesplit/abf-sdk";
import {
    AppsOutlined,
    LiquorOutlined,
    MapsHomeWorkOutlined,
    CurrencyBitcoinOutlined,
    AssuredWorkloadOutlined,
    HourglassBottomOutlined,
    ShowChartOutlined,
    AutoAwesome
} from "@mui/icons-material";
import { CUSTODIANS_SLUG } from "app/constants";

export const getCustodianPath = (custodian: AbfCustodian | undefined) => {
    if (!custodian?.slug) return CUSTODIANS_SLUG;
    return `${CUSTODIANS_SLUG}/${custodian.slug}`;
};

/**
 * Keep track of the last seen custodian explore page
 * Used to show breadcrumbs for the user to improve navigation UX
 */
export function useCustodianHistory() {
    const path = useLocation();
    const isCustodianPath = path.pathname.includes(CUSTODIANS_SLUG);
    const custodianSlug = path.pathname.replace(CUSTODIANS_SLUG + "/", "");
    const [history, setHistory] = useLocalStorage<string[]>("custodian_history", []);

    const { data: visitedCustodian } = useCustodianBySlugQuery(custodianSlug ?? skipToken, {
        skip: !isCustodianPath || !custodianSlug
    });
    const custodianIdentifier = visitedCustodian?.groupIdentifier;
    const lastItem = history?.[0];

    const skip = !custodianIdentifier || custodianIdentifier === lastItem;

    useEffect(() => {
        if (skip) return;
        setHistory([custodianIdentifier, ...history]);
    }, [custodianIdentifier, history, setHistory, skip]);

    const historyIndexes = new Map(history.map((custodian, index) => [custodian, index]));

    function getMostRecentCustodian<T extends string | AbfCustodian>(
        custodianIdentifiers: T[] | undefined
    ): T | undefined {
        if (!custodianIdentifiers) return undefined;
        let bestIndex = Infinity;
        let bestIdentifier: T | undefined = undefined;

        custodianIdentifiers.forEach((custodian) => {
            const identifier = typeof custodian === "string" ? custodian : custodian.groupIdentifier;
            const foundIndex = historyIndexes.get(identifier);

            if (foundIndex !== undefined && foundIndex < bestIndex) {
                bestIndex = foundIndex;
                bestIdentifier = custodian;
            }
        });

        return bestIdentifier;
    }

    return { recentCustodianIdentifiers: history, getMostRecentCustodian };
}

export enum CustodianTag {
    DeFi = "DeFi",
    Bridged = "Bridged",
    Governance = "Governance",
    Utility = "Utility",
    Meme = "Meme",
    VestingToken = "Vesting Token",
    StakedToken = "Staked Token",
    Cashflows = "Cashflows",
    PublicEquities = "Public Equities",
    Stablecoins = "Stablecoins",
    Commodities = "Commodities",
    Climate = "Climate",
    LuxuryGoods = "Luxury Goods",
    Collectibles = "Collectibles",
    Deeds = "Deeds",
    Debt = "Debt",
    Securities = "Securities",
    PrivateCredit = "Private Credit",
    PrivateEquity = "Private Equity",
    Treasuries = "Treasuries",
    MoneyMarket = "Money Market"
}

export enum CustodianCategory {
    AllAssets = "All",
    Featured = "Featured",
    NativelyIssued = "Natively-Issued",
    Locked = "Locked",
    Backed = "Backed",
    Custodied = "Custodied",
    RealEstate = "Real Estate",
    TokenizedFund = "Tokenized Fund"
}
interface CustodianCategoryInfo {
    icon: ReactNode;
    category: CustodianCategory;
    tags: CustodianTag[];
    name: string;
}

export const ASSET_CLASS_TAGS: CustodianCategoryInfo[] = [
    { category: CustodianCategory.Featured, icon: <AutoAwesome />, tags: [], name: "Featured" },
    { category: CustodianCategory.AllAssets, icon: <AppsOutlined />, tags: [], name: "All" },
    {
        category: CustodianCategory.NativelyIssued,
        icon: <CurrencyBitcoinOutlined />,
        tags: [
            CustodianTag.DeFi,
            CustodianTag.Bridged,
            CustodianTag.Governance,
            CustodianTag.Utility,
            CustodianTag.Meme
        ],
        name: "Natively-issued"
    },
    {
        category: CustodianCategory.Locked,
        icon: <HourglassBottomOutlined />,
        tags: [CustodianTag.VestingToken, CustodianTag.StakedToken, CustodianTag.Cashflows],
        name: "Locked"
    },
    {
        category: CustodianCategory.Backed,
        icon: <AssuredWorkloadOutlined />,
        tags: [CustodianTag.PublicEquities, CustodianTag.Stablecoins, CustodianTag.Commodities],
        name: "Backed"
    },
    {
        category: CustodianCategory.Custodied,
        icon: <LiquorOutlined />,
        tags: [CustodianTag.Climate, CustodianTag.LuxuryGoods, CustodianTag.Collectibles],
        name: "Custodied"
    },
    {
        category: CustodianCategory.RealEstate,
        icon: <MapsHomeWorkOutlined />,
        tags: [CustodianTag.Deeds, CustodianTag.Debt, CustodianTag.Securities],
        name: "Real Estate"
    },
    {
        category: CustodianCategory.TokenizedFund,
        icon: <ShowChartOutlined />,
        tags: [
            CustodianTag.PrivateCredit,
            CustodianTag.PrivateEquity,
            CustodianTag.Treasuries,
            CustodianTag.MoneyMarket
        ],
        name: "Tokenized Funds"
    }
];

export function CustodianSocial({
    custodian,
    variant,
    iconPadding = true
}: {
    custodian: AbfCustodian | undefined;
    variant: TextVariant;
    iconPadding?: boolean;
}) {
    return (
        <Row onClick={(e) => e.preventDefault()}>
            {custodian?.websiteUrl && (
                <IconLink padding={iconPadding} href={custodian?.websiteUrl} variant={variant} type="website" />
            )}
            {custodian?.linkedinUrl && (
                <IconLink padding={iconPadding} href={custodian?.linkedinUrl} variant={variant} type="linked-in" />
            )}
            {custodian?.twitterUsername && (
                <IconLink
                    padding={iconPadding}
                    href={`https://twitter.com/${custodian.twitterUsername}`}
                    variant={variant}
                    type="twitter"
                />
            )}
        </Row>
    );
}
