import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { AbfUserInvite } from "@bridgesplit/abf-sdk";

import { GET_USER_INVITES_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";
import { UserInviteFilter } from "../types";

const INVITES_TAG = "Invites";
export const abfUserInvitesApi = createApi({
    reducerPath: "abfUserInvitesApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    tagTypes: [INVITES_TAG],
    endpoints: (builder) => ({
        userInvites: builder.query<AbfUserInvite[], UserInviteFilter>({
            providesTags: [INVITES_TAG],
            query(body) {
                return {
                    url: GET_USER_INVITES_ROUTE,
                    method: "POST",
                    body
                };
            }
        }),
        acceptInvite: builder.mutation<null, string>({
            invalidatesTags: [INVITES_TAG],
            query(syndicateAddress) {
                return {
                    url: `${GET_USER_INVITES_ROUTE}/${syndicateAddress}`,
                    responseHandler: "text",
                    method: "POST"
                };
            }
        })
    })
});

export const { useUserInvitesQuery, useAcceptInviteMutation } = abfUserInvitesApi;

export const useAbfUserInvitesApi = () => {
    const api = abfUserInvitesApi.endpoints;
    const dispatch = useDispatch();
    // refetch data
    const [fetchUserInvites] = api.userInvites.useLazyQuery();

    return {
        resetUserInvites: () => dispatch(abfUserInvitesApi.util.invalidateTags([INVITES_TAG])),
        fetchUserInvites
    };
};
