export const SENTRY_IGNORE_ERRORS = [
    // occurs on Android devices + Amplify
    "Method not found",

    // Handled load chunk errors
    "Cannot read properties of undefined (reading 'default')",
    "Cannot read properties of undefined (reading 'map')",
    "Cannot read property 'default' of undefined",

    // Errors from Emotion
    /evaluating 't._result.default'/,
    "undefined is not an object (evaluating 't._result.default')",

    // Occurs on Android devices + Amplify
    /Java object is gone/,

    // Errors from Metamask/Phantom conflicts
    /property 'ethereum'/,
    /Object captured as promise rejection with keys: code, message/,

    // Silent error from iOS
    /AbortError: Request signal is aborted/
];
