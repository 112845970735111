import { useState } from "react";

import { Button, Column, RefreshButtonWithLoading, Row, Text, TextButton } from "@bridgesplit/ui";
import {
    useAbfFetches,
    useActiveWallet,
    useOraclePrices,
    useTokensByWalletQuery,
    useUserProfile,
    useVerifyWalletSetup,
    useWalletBalanceByMint
} from "@bridgesplit/abf-react";
import { RECOMMENDED_SOL_BALANCE, SOL_SYMBOL, WRAPPED_SOL_MINT, formatUsd } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ChainId } from "@bridgesplit/abf-sdk";

import { DialogHeader, QrCodeCopyAddress } from "../common";

export default function useMinTransactionBalance() {
    const [completeState, setComplete] = useState<boolean>();

    const { insufficientBalance, isLoading } = useBalance();

    const balanceCheckNeeded = completeState ?? (!isLoading && insufficientBalance);

    const Component = () => <BalancePrompt next={() => setComplete(false)} />;

    return { balanceCheckNeeded, BalancePrompt: Component };
}

function BalancePrompt({ next }: { next: () => void }) {
    const { activeWallet, solBalance, insufficientBalance } = useBalance();
    const solUsdPrice = useOraclePrices([WRAPPED_SOL_MINT]).getUsdPrice(WRAPPED_SOL_MINT);

    // show diff copy when user is funding their wallet for first time
    const { data } = useVerifyWalletSetup(activeWallet?.wallet);
    const isFreshSetup = !data?.onChainRoles.length;

    return (
        <>
            {isFreshSetup ? (
                <DialogHeader
                    header="Fund your Solana wallet"
                    description="Loopscale is built on the Solana blockchain, which requires you to pay network fees in SOL"
                />
            ) : (
                <DialogHeader
                    header="Low wallet balance"
                    description="Your transaction is likely to fail due to insufficient funds for network fees"
                />
            )}
            <Text color="caption" variant="body2">
                {`Send SOL from an exchange or another wallet. We recommend depositing ${RECOMMENDED_SOL_BALANCE} ${SOL_SYMBOL} ${
                    solUsdPrice ? `(${formatUsd(solUsdPrice * RECOMMENDED_SOL_BALANCE)})` : ""
                }`}
            </Text>

            <QrCodeCopyAddress
                label="Your Solana address"
                chainId={ChainId.Solana}
                size="normal"
                specifiedMint={WRAPPED_SOL_MINT}
                address={activeWallet?.wallet}
            />
            <Column spacing={1}>
                <Row spacing={0.5}>
                    <Text color="caption" variant="body2">
                        Balance: {solBalance} {SOL_SYMBOL}
                    </Text>

                    <RefreshBalance />
                </Row>
                <Button variant="contained" disabled={insufficientBalance} onClick={next}>
                    Continue
                </Button>

                {!!solBalance && (
                    <TextButton variant="body2" color="caption" onClick={next}>
                        Continue with low balance
                    </TextButton>
                )}
            </Column>
        </>
    );
}

function RefreshBalance() {
    const { resetEscrowedApi } = useAbfFetches();

    return <RefreshButtonWithLoading variant="body2" isFetching={false} callback={resetEscrowedApi} />;
}

function useBalance() {
    const { user } = useUserProfile();

    const { activeWallet } = useActiveWallet();
    const { data, isLoading } = useWalletBalanceByMint(activeWallet?.wallet, WRAPPED_SOL_MINT, {
        fullSolBalance: true,
        skip: !!user.bsFunding
    });

    // poll sol balance
    useTokensByWalletQuery(activeWallet?.wallet ?? skipToken, {
        skip: !activeWallet?.wallet,
        pollingInterval: 3_000
    });

    const solBalance = data?.amount || 0;
    const insufficientBalance = user.bsFunding ? false : solBalance < RECOMMENDED_SOL_BALANCE;

    return { solBalance, insufficientBalance, activeWallet, isLoading };
}
