import { useMemo, useEffect, useState, useCallback } from "react";

import { filterTruthy, getUnixTs } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BsMetadata } from "@bridgesplit/abf-sdk";
import { useMemoizedKeyMap } from "@bridgesplit/ui";

import {
    useCommunityPointMultipliersQuery,
    useCommunityRewardsQuery,
    usePointMultipliersQuery,
    usePointsMultiplierSumForRewardQuery,
    useRewardsQuery,
    useUserPointsQuery,
    useUserReferralInfoQuery,
    useUserWhitelistInfoQuery
} from "../reducers";
import {
    CollateralInfo,
    CollateralPointsData,
    LoopscalePointSource,
    PointsInfo,
    PointSource,
    PointsRewardType,
    RewardsCalculationResult,
    TokenSourceData,
    UseRewardsCalculationProps,
    UserPointsCalculationInfo
} from "../types";
import { useSkipUnauthenticated, useUserMe } from "./auth";
import { buildCollateralSourceInfo, calculateLoopscaleMultiplier } from "../utils/points";
import { LOOPSCALE_POINT_CAPTIONS, LOOPSCALE_POINTS_SOURCE_INFO, POINTS_SOURCE_INFO } from "../constants";

export function useSkipPoints() {
    return useSkipUnauthenticated("bearer");
}

export function useUserId() {
    const { data: user } = useUserMe();
    return user?.user.identifier ?? skipToken;
}

export function useRewards() {
    const { data, isLoading } = useRewardsQuery({ feVisible: true, activeOnly: true });

    const rewardToInfo = useMemoizedKeyMap(data, (t) => t.reward);

    function getRewardInfo(type: PointsRewardType) {
        return rewardToInfo?.get(type);
    }

    return { data, getRewardInfo, isLoading };
}

function isLoopscalePointSource(value: number): value is LoopscalePointSource {
    return Object.values(LoopscalePointSource).includes(value as LoopscalePointSource);
}

export function useLoopscalePointsPerUsd() {
    const { data, isLoading } = useRewards();

    const { pointsSourceMapping, truePointsPerSecond } = useMemo(() => {
        if (!data)
            return {
                pointsSourceMapping: undefined,
                truePointsPerSecond: undefined
            };

        const mapping: Record<LoopscalePointSource, number> = {
            [LoopscalePointSource.Borrow]: 0,
            [LoopscalePointSource.Lend]: 0,
            [LoopscalePointSource.IdleCap]: 0
        };

        const truePoints: Record<LoopscalePointSource, number> = {
            [LoopscalePointSource.Borrow]: 0,
            [LoopscalePointSource.Lend]: 0,
            [LoopscalePointSource.IdleCap]: 0
        };

        let idleCapPoints = 0;
        data.forEach((reward) => {
            const rewardValue = reward.reward as number;
            if (isLoopscalePointSource(rewardValue)) {
                // Store the true points per second
                truePoints[rewardValue] = reward.pointsPerSecond;

                if (rewardValue === LoopscalePointSource.IdleCap) {
                    idleCapPoints = reward.pointsPerSecond;
                }
            }
        });

        data.forEach((reward) => {
            const rewardValue = reward.reward as number;
            if (isLoopscalePointSource(rewardValue)) {
                mapping[rewardValue] = idleCapPoints > 0 ? reward.pointsPerSecond / idleCapPoints : 0;
            }
        });

        return {
            pointsSourceMapping: mapping,
            truePointsPerSecond: truePoints
        };
    }, [data]);

    return {
        pointsSourceMapping,
        truePointsPerSecond,
        isLoading
    };
}
export function useUserBetaAccess() {
    const { data, ...query } = useUserMe();
    const isLoading = useMemo(
        () => !data || query.isLoading || query.isFetching,
        [data, query.isFetching, query.isLoading]
    );
    const isBorrower = !!data?.beta_borrow_access;
    const isLender = !!data?.beta_lend_access;
    const hasPermission = isBorrower || isLender;

    return { hasPermission, isBorrower, isLender, isLoading };
}

export function useUserReferral(options?: { skip?: boolean }) {
    const userId = useUserId();
    const skip = useSkipPoints();
    return useUserReferralInfoQuery(userId, {
        skip: skip || !!options?.skip
    });
}

export function useUserWhitelistRank() {
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();
    const { data: rawData, ...reset } = useUserWhitelistInfoQuery(userId, { skip: skipIfUnauthenticated });

    const data = useMemo(() => {
        if (!rawData) return undefined;
        return { ...rawData, userRank: rawData.userRank || rawData.totalUsers };
    }, [rawData]);

    return { ...reset, data };
}

export function usePointMultipliers() {
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();
    const { data, isLoading } = useCommunityPointMultipliersQuery(userId, { skip: skipIfUnauthenticated });

    const totalMultiplier = useMemo(() => {
        if (!data) return 1;
        return data.reduce((acc, curr) => acc * curr.multiplier, 1);
    }, [data]);

    return { data, totalMultiplier, isLoading };
}

export type UserPointsFunction = ReturnType<typeof useUserPoints>["getPointsAtTime"];
export function useUserPoints(options?: { skip?: number }) {
    // await for referral info or invite code endpoints before calling points to catch point creation endpoints
    const { hasPermission } = useUserBetaAccess();
    const { data: referrals } = useUserReferral({ skip: hasPermission });
    const accountCreated = hasPermission || !!referrals?.userReferralCode;
    const userId = useUserId();
    const skipIfUnauthenticated = useSkipPoints();

    const {
        data: rawData,
        isLoading,
        isUninitialized
    } = useUserPointsQuery(userId, { skip: !accountCreated || skipIfUnauthenticated || !!options?.skip });

    return {
        getPointsAtTime: (timestamp: number) => getPointsAtTime(rawData, timestamp),
        isLoading: isUninitialized || isLoading
    };
}

function getPointsAtTime(userInfo: UserPointsCalculationInfo | undefined, now: number) {
    if (!userInfo) return userInfo;

    const { timestamp, points, pointsPerSecond } = userInfo;

    const secondsElapsed = Math.max(now - timestamp, 0);
    const basePoints = points.basePoints + pointsPerSecond.basePointsPerSecond * secondsElapsed;
    const referralPoints = points.referralBonusPoints + pointsPerSecond.referralPointsPerSecond * secondsElapsed;
    const totalPoints = basePoints + referralPoints;
    return { basePoints, referralPoints, totalPoints };
}

export function useCurrentTime(pollingInterval = 50) {
    const [now, setNow] = useState<number>(getUnixTs());

    useEffect(() => {
        if (!pollingInterval) return;
        const interval = setInterval(() => {
            setNow(getUnixTs());
        }, pollingInterval);

        return () => clearInterval(interval);
    }, [pollingInterval]);

    return { now };
}

export function useCommunityRewards() {
    const userId = useUserId();
    return useCommunityRewardsQuery(userId ?? skipToken);
}

export function usePointsSumForReward(pointsReward: PointsRewardType) {
    const { getRewardInfo, isLoading: rewardLoading } = useRewards();
    const userId = useUserId();
    const { data: totalMultiplier, isLoading: multiplierLoading } = usePointsMultiplierSumForRewardQuery(
        typeof userId === "string"
            ? {
                  pointsReward,
                  userId
              }
            : skipToken
    );

    const totalPoints = useMemo(() => {
        const rewardInfo = getRewardInfo(pointsReward);
        if (!rewardInfo || !totalMultiplier) return 0;
        return rewardInfo.pointsPerSecond * totalMultiplier;
    }, [getRewardInfo, pointsReward, totalMultiplier]);

    return { totalPoints, isLoading: rewardLoading || multiplierLoading };
}

function useCollateralPointSources() {
    const { data: multiplierData, isLoading, error } = usePointMultipliersQuery();
    return useMemo(
        () => ({
            sources: multiplierData ? buildCollateralSourceInfo(multiplierData) : undefined,
            isLoading,
            error
        }),
        [multiplierData, isLoading, error]
    );
}

export function useCollateralPointsSourcesUtil() {
    const { sources, isLoading, error } = useCollateralPointSources();
    const { pointsSourceMapping, truePointsPerSecond, isLoading: pointsLoading } = useLoopscalePointsPerUsd();

    return {
        getPoints: useCallback(
            (metadata: BsMetadata[] | undefined, pointSource: LoopscalePointSource) =>
                getPointsByCollateralMint(sources, metadata, pointSource),
            [sources]
        ),
        getPointSources: useCallback((mint: string) => getCollateralPointSources(sources, mint), [sources]),
        hasPointSource: useCallback(
            (mint: string, pointSource: LoopscalePointSource) => hasPointSource(sources, mint, pointSource),
            [sources]
        ),
        getTruePointsPerDollarPerHour: useCallback(
            (pointSource: LoopscalePointSource) => getTruePointsPerDollarPerHour(truePointsPerSecond, pointSource),
            [truePointsPerSecond]
        ),
        hasLoopPointMultiplierAboveBase: useCallback(
            (mint: BsMetadata[] | undefined, pointSources: LoopscalePointSource[]) =>
                hasLoopPointMultiplierAboveBase(sources, mint, pointSources, pointsSourceMapping),
            [sources, pointsSourceMapping]
        ),
        getLoopscaleMultipliers: useCallback(
            (pointSources: LoopscalePointSource[] | undefined) =>
                getLoopscalePointsPerUsd(pointSources, pointsSourceMapping),
            [pointsSourceMapping]
        ),
        hasAnyPointSourceAboveBase: useCallback(
            (mints: BsMetadata[] | undefined) => hasAnyPointSourceAboveBase(sources, mints, pointsSourceMapping),
            [sources, pointsSourceMapping]
        ),
        getSpecificMultipliers: useCallback(
            (mint: string) => getSpecificCollateralMultipliers(sources, mint),
            [sources]
        ),
        isLoading: isLoading || pointsLoading,
        error
    };
}

// Utils
export function getLoopscaleCaption(pointSource: LoopscalePointSource, short = false): string {
    return short ? LOOPSCALE_POINT_CAPTIONS[pointSource].short : LOOPSCALE_POINT_CAPTIONS[pointSource].full;
}

export function getLoopscalePointsPerUsd(
    pointSources: LoopscalePointSource[] | undefined,
    pointsSourceMapping: Record<LoopscalePointSource, number> | undefined,
    useShortCaption = false
): PointsInfo[] | undefined {
    if (!pointsSourceMapping || !pointSources || !pointSources.length) return undefined;

    return pointSources.map((source) => ({
        program: PointSource.Loopscale,
        caption: getLoopscaleCaption(source, useShortCaption),
        multiplier: pointsSourceMapping[source]
    }));
}

export function getSpecificCollateralMultipliers(
    sources: Record<string, CollateralInfo> | undefined,
    mint: string
): Map<LoopscalePointSource, number> {
    if (!sources || !sources[mint]) {
        return new Map();
    }

    return sources[mint].collateralPointBonus;
}

export function getTruePointsPerDollarPerHour(
    truePointsPerSecond: Record<LoopscalePointSource, number> | undefined,
    pointSource: LoopscalePointSource
): number | undefined {
    if (!truePointsPerSecond) return undefined;
    return truePointsPerSecond[pointSource];
}

// Gets all available point sources for a specific collateral mint
export function getCollateralPointSources(
    sources: Record<string, CollateralInfo> | undefined,
    mint: string
): LoopscalePointSource[] {
    if (!sources || !sources[mint]) {
        return [];
    }
    return Array.from(sources[mint].collateralPointBonus.keys());
}

// Checks if a collateral mint has a specific point source type
export function hasPointSource(
    sources: Record<string, CollateralInfo> | undefined,
    mint: string,
    pointSource: LoopscalePointSource
): boolean {
    if (!sources || !sources[mint]) {
        return false;
    }
    return sources[mint].collateralPointBonus.has(pointSource);
}

export function hasLoopPointMultiplierAboveBase(
    sources: Record<string, CollateralInfo> | undefined,
    mints: BsMetadata[] | undefined,
    pointSources: LoopscalePointSource[],
    pointsSourceMapping: Record<LoopscalePointSource, number> | undefined
): boolean {
    if (!sources || !mints || !pointsSourceMapping) {
        return false;
    }

    return mints.some((mint) => {
        const assetMint = mint.assetMint;
        if (!sources[assetMint]) {
            return false;
        }

        return pointSources.some((pointSource) => {
            const multiplier = sources[assetMint].collateralPointBonus.get(pointSource);
            const baseMultiplier = pointsSourceMapping[pointSource];

            return (multiplier ?? 0) > baseMultiplier;
        });
    });
}
export function hasAnyPointSourceAboveBase(
    sources: Record<string, CollateralInfo> | undefined,
    mints: BsMetadata[] | undefined,
    pointsSourceMapping: Record<LoopscalePointSource, number> | undefined
): boolean {
    if (!sources || !mints || !pointsSourceMapping || mints.length === 0) {
        return false;
    }

    return mints.some((metadata) => {
        if (!metadata?.assetMint) return false;

        const collateralInfo = sources[metadata.assetMint];
        if (!collateralInfo) return false;

        // Check each point source against its base value
        return Array.from(collateralInfo.collateralPointBonus.entries()).some(([pointSource, pointInfo]) => {
            const baseMultiplier = pointsSourceMapping[pointSource as LoopscalePointSource];
            return pointInfo > baseMultiplier;
        });
    });
}

export function getPointsByCollateralMint(
    sources: Record<string, CollateralInfo> | undefined,
    metadata: BsMetadata[] | undefined,
    pointSource: LoopscalePointSource
): number[] {
    if (!sources || !metadata?.length) {
        return [];
    }

    return metadata.map((meta) => {
        if (!meta?.assetMint || !sources[meta.assetMint]) {
            return 0;
        }
        return sources[meta.assetMint].collateralPointBonus.get(pointSource) ?? 0;
    });
}

export function useCollateralPoints(
    metadata: BsMetadata[] | undefined
): (mint: string) => CollateralPointsData | undefined {
    const { getPointSources, getPoints, hasPointSource } = useCollateralPointsSourcesUtil();

    return useMemo(() => {
        if (!metadata?.length) {
            return () => undefined;
        }

        const pointsDataMap = new Map<string, CollateralPointsData>();

        metadata.forEach((meta) => {
            if (!meta?.assetMint) return;

            const mint = meta.assetMint;
            const eligibleMultipliers = getPointSources(mint);
            const showRewards = !hasPointSource(mint, eligibleMultipliers[0]);

            const getAdditionalPoints = (pointSources: LoopscalePointSource[]): number => {
                if (!pointSources?.length || !hasPointSource(mint, pointSources[0])) return 1;

                const metadataForMint: BsMetadata[] = [meta];
                const points = pointSources.reduce((sum, pointSource) => {
                    const pointsArray = getPoints(metadataForMint, pointSource);
                    return sum + (pointsArray[0] ?? 0);
                }, 0);

                return points === 0 ? 1 : points;
            };

            pointsDataMap.set(mint, {
                eligibleMultipliers,
                showRewards,
                getAdditionalPoints
            });
        });

        return (mint: string) => pointsDataMap.get(mint);
    }, [metadata, getPointSources, getPoints, hasPointSource]);
}
export function getCollateralSourceData(
    token: BsMetadata,
    hasPointSource: (mint: string, pointSource: LoopscalePointSource) => boolean,
    allowedPointSources: LoopscalePointSource[]
): TokenSourceData {
    const hasAnyBonus =
        hasPointSource(token.assetMint, LoopscalePointSource.Borrow) ||
        hasPointSource(token.assetMint, LoopscalePointSource.Lend) ||
        hasPointSource(token.assetMint, LoopscalePointSource.IdleCap);

    const eligibleMultipliers = Object.values(LoopscalePointSource).filter(
        (source): source is LoopscalePointSource =>
            typeof source === "number" &&
            hasPointSource(token.assetMint, source as LoopscalePointSource) &&
            allowedPointSources.includes(source as LoopscalePointSource)
    );

    const showRewards = !hasAnyBonus;

    return {
        eligibleMultipliers,
        showRewards
    };
}

export function useRewardsCalculation({
    pointSources,
    loopscalePointSources,
    metadata = [],
    loopLeverage
}: UseRewardsCalculationProps): RewardsCalculationResult {
    const getCollateralPoints = useCollateralPoints(metadata);
    const { getLoopscaleMultipliers } = useCollateralPointsSourcesUtil();

    const rewards = useMemo(
        () =>
            pointSources.map((point) => {
                const pointInfo = POINTS_SOURCE_INFO[point];
                return {
                    ...pointInfo,
                    multiplier: undefined
                } as PointsInfo;
            }),
        [pointSources]
    );

    const loopscaleActions = useMemo(
        () =>
            loopscalePointSources
                ?.map((point) => {
                    const pointInfo = LOOPSCALE_POINTS_SOURCE_INFO[point];
                    return {
                        ...pointInfo,
                        multiplier: undefined
                    } as PointsInfo;
                })
                .filter(filterTruthy) ?? [],
        [loopscalePointSources]
    );

    const totalAdditionalPoints = useMemo(() => {
        if (!loopscalePointSources || metadata.length === 0) {
            return 0;
        }

        const additionalPointsArray = metadata.map((item) => {
            const collateralData = getCollateralPoints(item.assetMint);
            if (!collateralData) return 1;
            return collateralData.getAdditionalPoints(loopscalePointSources);
        });

        return additionalPointsArray.reduce((product, points) => product * points, 1);
    }, [metadata, getCollateralPoints, loopscalePointSources]);

    const baseSources = getLoopscaleMultipliers(loopscalePointSources);

    const finalRewards = useMemo(
        () =>
            rewards.map((reward) => {
                const baseMultiplier = calculateLoopscaleMultiplier(reward, loopscaleActions, baseSources) ?? 1;
                const multiplier =
                    loopLeverage !== undefined
                        ? baseMultiplier * loopLeverage
                        : baseMultiplier * (totalAdditionalPoints ?? 1);

                return {
                    ...reward,
                    multiplier
                };
            }),
        [rewards, loopscaleActions, baseSources, loopLeverage, totalAdditionalPoints]
    );

    return {
        finalRewards
    };
}

export function useCollateralMultipliers(
    metadata: BsMetadata[] | undefined,
    loopscalePointSources: LoopscalePointSource[] | undefined,
    hideZeroMultipliers = false
) {
    const { sources } = useCollateralPointSources();
    const { getLoopscaleMultipliers } = useCollateralPointsSourcesUtil();

    const multipliers = useMemo(() => {
        if (!loopscalePointSources?.length || !metadata?.length) {
            return [];
        }

        const baseMultipliersInfo = getLoopscaleMultipliers(loopscalePointSources);

        if (!baseMultipliersInfo) return [];

        const baseMultiplierMap = Object.fromEntries(
            baseMultipliersInfo.map((info, index) => [loopscalePointSources[index], info.multiplier])
        );

        // Process each metadata item
        return metadata.flatMap((meta) => {
            if (!meta?.assetMint) return [];

            return loopscalePointSources
                .map((point) => {
                    if (!point) return undefined;

                    const baseMultiplier = baseMultiplierMap[point] ?? 0;
                    const bonus = sources?.[meta.assetMint]?.collateralPointBonus.get(point) ?? 0;

                    const finalMultiplier = bonus;

                    // Skip if base multiplier is 0 since it can never result in a non-zero final multiplier
                    if (baseMultiplier === 0 || bonus === 0) {
                        return undefined;
                    }

                    return {
                        program: PointSource.Loopscale,
                        multiplier: finalMultiplier,
                        assetMint: meta.assetMint,
                        caption: `${getLoopscaleCaption(point, true)} boost`
                    };
                })
                .filter((multiplier): multiplier is NonNullable<typeof multiplier> => {
                    if (!multiplier) return false;
                    if (hideZeroMultipliers && multiplier.multiplier === 0) return false;
                    return true;
                });
        });
    }, [metadata, loopscalePointSources, sources, hideZeroMultipliers, getLoopscaleMultipliers]);

    return multipliers;
}
