import {
    getDepositAssetTransaction,
    getWithdrawAssetTransaction,
    ExtraInstructionArgs,
    getDepositStakedSolAssetTransactions,
    StakeAccountArgs,
    getWithdrawStakedSolAssetTransactions,
    getEscrowTransferAssetTransaction,
    getSplTransferTransaction,
    TransferAssetArgs,
    getWalletTransferStakedSolTransactions,
    SplitStakeIxParams
} from "@bridgesplit/abf-sdk";
import { TransactionWithIdentifier, combineTransactionPromises } from "@bridgesplit/react";
import { LOADING_ERROR, Result, SOL_DECIMALS, filterTruthy, uiAmountToLamports } from "@bridgesplit/utils";

import { useAbfFetches } from "../reducers";
import { AbfGeneratorResult, AbfTransactionDetails, TransactionSenderOptions } from "../types";
import { isStakedSol, useDecimalsByMint } from "../utils";
import { useAbfGenerateTransaction } from "./common";
import { useActiveWallet, useActiveEscrow, useActiveGroup } from "../api";

export type EscrowManageParams = { mint: string; amount: number; skipUiConversion: boolean };

export function useManageEscrowTransactions() {
    const { activeWallet } = useActiveWallet();
    const { escrowNonce, activeEscrow } = useActiveEscrow();

    const getDeposit = useGetEscrowDepositTransactions();
    const getWithdraw = useGetEscrowWithdrawTransactions();
    const { groupIdentifier: organizationIdentifier } = useActiveGroup();

    async function escrowDeposit(params: EscrowManageParams[]): Promise<Result<TransactionWithIdentifier[]>> {
        if (!escrowNonce || !organizationIdentifier) return Result.errFromMessage(LOADING_ERROR);
        const nonZeroAmounts = params.filter((p) => !!p.amount);

        if (!nonZeroAmounts.length) return Result.ok([]);

        const transactions = await getDeposit(
            params.map(({ mint, amount, skipUiConversion }) => ({
                amount,
                mint,
                escrowNonce,
                organizationIdentifier,
                skipUiConversion
            }))
        );
        const txns = await transactions.depositEscrow;
        if (!txns) return Result.ok([]);
        return txns;
    }

    async function escrowWithdraw(params: EscrowManageParams[]): Promise<Result<TransactionWithIdentifier[]>> {
        if (!activeEscrow || !escrowNonce || !activeWallet || !organizationIdentifier)
            return Result.errFromMessage(LOADING_ERROR);

        const nonZeroAmounts = params.filter((p) => !!p.amount);

        if (!nonZeroAmounts.length) return Result.ok([]);

        const transactions = await getWithdraw(
            nonZeroAmounts.map(({ mint, amount, skipUiConversion }) => ({
                amount,
                mint,
                escrowNonce,
                escrowAccount: activeEscrow,
                organizationIdentifier,
                receiver: activeWallet.wallet,
                skipUiConversion
            }))
        );
        const txns = await transactions.withdrawEscrow;
        if (!txns) return Result.ok([]);
        return txns;
    }

    return { escrowDeposit, escrowWithdraw };
}

export type DepositParams = {
    mint: string;
    amount: number;
    escrowNonce: string;
    organizationIdentifier: string;
    stakeAccount?: string;
    extraInstructions?: ExtraInstructionArgs; // only used for non-staked sol deposits
    skipUiConversion?: boolean;
}[];

// internal function for fetching deposit transactions (including staked sol)
export function useGetEscrowDepositTransactions() {
    const generate = useAbfGenerateTransaction();

    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    return async (deposits: DepositParams) => {
        // deposit staked accounts separately
        const stakedDeposits = deposits.filter(
            (d): d is DepositParams[0] & { stakeAccount: string } => isStakedSol(d.mint) && !!d.stakeAccount
        );
        let stakedDeposit: Promise<Result<TransactionWithIdentifier[]>> | undefined;
        if (stakedDeposits.length) {
            const firstStakedDeposit = stakedDeposits[0];
            stakedDeposit = generate({
                generateFunction: getDepositStakedSolAssetTransactions,
                identifier: "Deposit Stake",
                params: {
                    groupIdentifier: firstStakedDeposit.organizationIdentifier,
                    escrowNonce: firstStakedDeposit.escrowNonce,
                    stakeAccounts: stakedDeposits.map(
                        ({ amount, stakeAccount }): StakeAccountArgs => ({
                            amountToTransfer: uiAmountToLamports(amount, SOL_DECIMALS),
                            address: stakeAccount
                        })
                    )
                }
            });
        }

        const escrowDeposits = deposits.filter((d) => !isStakedSol(d.mint) && !d.stakeAccount);
        const getDecimals = await setupFetchMultipleDecimals(
            escrowDeposits.filter((d) => !d.skipUiConversion).map((d) => d.mint)
        );

        const depositEscrow = generate({
            generateFunction: getDepositAssetTransaction,
            identifier: "Deposit Asset",
            params: escrowDeposits.map(
                ({ mint, amount, escrowNonce, organizationIdentifier, extraInstructions, skipUiConversion }) => ({
                    deposit_mint: mint,
                    organization_identifier: organizationIdentifier,
                    amount: skipUiConversion ? amount : uiAmountToLamports(amount, getDecimals(mint)),
                    escrow_nonce: escrowNonce,
                    extra_instructions: extraInstructions ?? {}
                })
            )
        });

        return { depositEscrow: escrowDeposits.length ? depositEscrow : undefined, stakedDeposit };
    };
}

export function useEscrowDepositTransaction(): AbfTransactionDetails<DepositParams> {
    const {
        resetEscrowedApi,
        resetOrderApi,
        resetLoanRequests,
        resetLendingStrategyApi,
        resetStakeApi,
        resetGroupEscrowsApi,
        resetNapoleonPublicApi
    } = useAbfFetches();
    const getTransactions = useGetEscrowDepositTransactions();

    async function getTransactionsWithParams(deposits: DepositParams): AbfGeneratorResult {
        try {
            const { depositEscrow, stakedDeposit } = await getTransactions(deposits);
            const transactions = [depositEscrow, stakedDeposit].filter(filterTruthy);
            return await combineTransactionPromises(transactions, {
                order: "parallel"
            });
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        refetch: () => {
            resetOrderApi();
            resetEscrowedApi();
            resetLoanRequests();
            resetLendingStrategyApi();
            resetStakeApi();
            resetGroupEscrowsApi();
            resetNapoleonPublicApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Depositing Asset" };
}

export type WithdrawParams = {
    mint: string;
    amount: number;
    escrowAccount: string;
    escrowNonce: string;
    organizationIdentifier: string;
    receiver: string;
    extraInstructions?: ExtraInstructionArgs;
    skipUiConversion?: boolean;
    stakeAccount?: string;
    withdrawAll?: boolean;
}[];
export function useGetEscrowWithdrawTransactions() {
    const generate = useAbfGenerateTransaction();

    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    return async function getTransactionsWithParams(withdraws: WithdrawParams) {
        // withdraw staked accounts separately
        const stakedSolWithdraws = withdraws.filter(
            (d): d is WithdrawParams[0] & { stakeAccount: string } => isStakedSol(d.mint) && !!d.stakeAccount
        );
        let stakedWithdraw: Promise<Result<TransactionWithIdentifier[]>> | undefined;
        if (stakedSolWithdraws.length) {
            const firstStakeAccount = stakedSolWithdraws[0];
            stakedWithdraw = generate({
                generateFunction: getWithdrawStakedSolAssetTransactions,
                identifier: "Withdraw Stake",
                params: {
                    groupIdentifier: firstStakeAccount.organizationIdentifier,
                    escrowNonce: firstStakeAccount.escrowNonce,
                    recipient: firstStakeAccount.receiver,
                    stakeAccounts: stakedSolWithdraws.map(
                        ({ amount, stakeAccount, skipUiConversion }): StakeAccountArgs => ({
                            amountToTransfer: skipUiConversion ? amount : uiAmountToLamports(amount, SOL_DECIMALS),
                            address: stakeAccount
                        })
                    )
                }
            });
        }

        const escrowWithdraws = withdraws.filter((d) => !isStakedSol(d.mint) && !d.stakeAccount);
        const getDecimals = await setupFetchMultipleDecimals(
            escrowWithdraws.filter((e) => !e.skipUiConversion).map((d) => d.mint)
        );

        const withdrawEscrow = generate({
            generateFunction: getWithdrawAssetTransaction,
            identifier: "Withdraw Asset",
            params: withdraws.map(
                ({ mint, receiver, amount, escrowAccount, extraInstructions, skipUiConversion, withdrawAll }) => ({
                    deposit_mint: mint,
                    amount: skipUiConversion ? amount : uiAmountToLamports(amount, getDecimals(mint)),
                    recipient: receiver,
                    escrow_account: escrowAccount,
                    extra_instructions: extraInstructions ?? {},
                    withdraw_all: withdrawAll
                })
            )
        });

        return { withdrawEscrow: escrowWithdraws.length ? withdrawEscrow : undefined, stakedWithdraw };
    };
}

export function useEscrowWithdrawTransaction(): AbfTransactionDetails<WithdrawParams> {
    const { resetEscrowedApi, resetLockboxApi, resetGroupEscrowsApi, resetLendingStrategyApi, resetNapoleonPublicApi } =
        useAbfFetches();
    const getWithdrawTransactions = useGetEscrowWithdrawTransactions();
    async function getTransactionsWithParams(withdraws: WithdrawParams): AbfGeneratorResult {
        try {
            const { withdrawEscrow, stakedWithdraw } = await getWithdrawTransactions(withdraws);
            const transactions = [withdrawEscrow, stakedWithdraw].filter(filterTruthy);

            const withdrawTxns = await combineTransactionPromises(transactions, {
                order: "parallel"
            });

            return withdrawTxns;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetEscrowedApi();
            resetLockboxApi();
            resetGroupEscrowsApi();
            resetLendingStrategyApi();
            resetNapoleonPublicApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdrawing Asset" };
}

export type EscrowTransferParams = {
    senderEscrow: string;
    recipientEscrowNonce: string;
    uiAmount: number;
    mint: string;
    extraInstructions?: ExtraInstructionArgs; // only used for non-staked sol deposits
}[];
export function useEscrowTransferTransaction(): AbfTransactionDetails<EscrowTransferParams> {
    const generate = useAbfGenerateTransaction();

    const { resetEscrowedApi, resetOrderApi, resetLoanRequests, resetLendingStrategyApi, resetGroupEscrowsApi } =
        useAbfFetches();
    const { setupFetchMultipleDecimals } = useDecimalsByMint();

    async function getTransactionsWithParams(transfers: EscrowTransferParams): AbfGeneratorResult {
        try {
            const getDecimals = await setupFetchMultipleDecimals(transfers.map((d) => d.mint));

            const transferEscrow = generate({
                generateFunction: getEscrowTransferAssetTransaction,
                identifier: "Transfer Asset",
                params: transfers.map(({ mint, uiAmount, senderEscrow, recipientEscrowNonce, extraInstructions }) => ({
                    deposit_mint: mint,
                    sender_escrow_account: senderEscrow,
                    recipient_escrow_nonce: recipientEscrowNonce,
                    amount: uiAmountToLamports(uiAmount, getDecimals(mint)),
                    extra_instructions: extraInstructions
                }))
            });
            const depositTxns = await combineTransactionPromises([transferEscrow], {
                order: "parallel"
            });

            return depositTxns;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetOrderApi();
            resetEscrowedApi();
            resetLoanRequests();
            resetLendingStrategyApi();
            resetGroupEscrowsApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Transferring Asset" };
}

type WalletTransferProps = {
    spl: TransferAssetArgs[];
    stake?: SplitStakeIxParams[];
};
export function useWalletTransfer(): AbfTransactionDetails<WalletTransferProps> {
    const generate = useAbfGenerateTransaction();

    const { resetEscrowedApi } = useAbfFetches();

    async function getTransactionsWithParams({ spl, stake }: WalletTransferProps): AbfGeneratorResult {
        try {
            const transferEscrow = spl.length
                ? generate({
                      generateFunction: getSplTransferTransaction,
                      identifier: "Send Asset",
                      params: spl // don't convert ui amount since decimals fetched will be unknown
                  })
                : null;

            const stakeWithdraw = stake?.length
                ? generate({
                      generateFunction: getWalletTransferStakedSolTransactions,
                      identifier: "Send Staked SOL",
                      params: stake.map(({ amount, ...rest }) => ({
                          ...rest,
                          amount: uiAmountToLamports(amount, SOL_DECIMALS)
                      }))
                  })
                : null;

            const depositTxns = await combineTransactionPromises([transferEscrow, stakeWithdraw].filter(filterTruthy), {
                order: "parallel"
            });

            return depositTxns;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions: TransactionSenderOptions = {
        delayMethod: "sleep", // since abf doesn't interact with abf program delay should be manual
        refetch: () => {
            resetEscrowedApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Transferring Asset" };
}
