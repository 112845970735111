import { StatColumn } from "@bridgesplit/ui";

import { useHealthChangeStats, useTokenAmountStats } from "../common";
import { useWhirlpoolContext } from "../WhirlpoolContext";
import { useDepositLtv } from "./util";

export default function WithdrawCtaStats() {
    const { depositForm } = useWhirlpoolContext();
    const depositLtv = useDepositLtv();
    const healthChangeStats = useHealthChangeStats(depositLtv);
    const tokenAmountStats = useTokenAmountStats({
        tokenAChange: depositForm.tokenAAmount ?? 0,
        tokenBChange: depositForm.tokenBAmount ?? 0
    });
    if (!depositForm.tokenAAmount || !depositForm.tokenBAmount) return null;

    return <StatColumn loading={depositLtv.isLoading} stats={[...tokenAmountStats, ...healthChangeStats]} />;
}
