import { Result } from "@bridgesplit/utils";
import {
    GetClaimFeeTxnArgs,
    GetDecreaseLiquidityTxnArgs,
    GetIncreaseLiquidityTxnArgs,
    GetUpdateLiquidityTxnArgs,
    GetUpdatePositionTxnArgs,
    getWhirlpoolDecreaseLiquidityTransaction,
    getWhirlpoolClaimFeeTransaction,
    getWhirlpoolIncreaseLiquidityTransaction,
    getWhirlpoolUpdateLiquidityTransaction,
    getWhirlpoolUpdatePositionTransaction
} from "@bridgesplit/abf-sdk";
import { combineTransactionPromises } from "@bridgesplit/react";

import { AbfTransactionDetails, AbfGeneratorResult } from "../types";
import { useAbfGenerateTransaction } from "./common";
import { useAbfFetches } from "../reducers";

export function useWhirlpoolClaimFeeTransaction(): AbfTransactionDetails<GetClaimFeeTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetLockboxApi } = useAbfFetches();

    async function getTransactionsWithParams(args: GetClaimFeeTxnArgs): AbfGeneratorResult {
        try {
            const transaction = generate({
                generateFunction: getWhirlpoolClaimFeeTransaction,
                identifier: "Claim fees",
                params: args
            });

            const transactions = await combineTransactionPromises([transaction], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetLockboxApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Claim fees" };
}

export function useWhirlpoolUpdateLiquidityTransaction(): AbfTransactionDetails<GetUpdateLiquidityTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetLockboxApi } = useAbfFetches();

    async function getTransactionsWithParams(args: GetUpdateLiquidityTxnArgs): AbfGeneratorResult {
        try {
            const transaction = generate({
                generateFunction: getWhirlpoolUpdateLiquidityTransaction,
                identifier: "Whirlpool Update Liquidity",
                params: args
            });

            const transactions = await combineTransactionPromises([transaction], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetLockboxApi();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Whirlpool Update Liquidity" };
}

export function useWhirlpoolUpdatePositionTransaction(): AbfTransactionDetails<GetUpdatePositionTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetLockboxApi, resetWhirlpoolPositions } = useAbfFetches();

    async function getTransactionsWithParams(args: GetUpdatePositionTxnArgs): AbfGeneratorResult {
        try {
            const transaction = generate({
                generateFunction: getWhirlpoolUpdatePositionTransaction,
                identifier: "Update position",
                params: args
            });

            const transactions = await combineTransactionPromises([transaction], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetLockboxApi();
            resetWhirlpoolPositions();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Update position" };
}

export function useWhirlpoolIncreaseLiquidityTransaction(): AbfTransactionDetails<GetIncreaseLiquidityTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetLockboxApi, resetWhirlpoolPositions } = useAbfFetches();

    async function getTransactionsWithParams(args: GetIncreaseLiquidityTxnArgs): AbfGeneratorResult {
        try {
            const transaction = generate({
                generateFunction: getWhirlpoolIncreaseLiquidityTransaction,
                identifier: "Increase liquidity",
                params: args
            });

            const transactions = await combineTransactionPromises([transaction], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetLockboxApi();
            resetWhirlpoolPositions();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Increase liquidity" };
}

export function useWhirlpoolDecreaseLiquidityTransaction(): AbfTransactionDetails<GetDecreaseLiquidityTxnArgs> {
    const generate = useAbfGenerateTransaction();
    const { resetLoanApi, resetLockboxApi, resetWhirlpoolPositions } = useAbfFetches();

    async function getTransactionsWithParams(args: GetDecreaseLiquidityTxnArgs): AbfGeneratorResult {
        try {
            const transaction = generate({
                generateFunction: getWhirlpoolDecreaseLiquidityTransaction,
                identifier: "Withdraw liquidity",
                params: args
            });

            const transactions = await combineTransactionPromises([transaction], { order: "sequential" });
            return transactions;
        } catch (error) {
            return Result.err(error);
        }
    }

    const sendOptions = {
        refetch: () => {
            resetLoanApi();
            resetLockboxApi();
            resetWhirlpoolPositions();
        }
    };

    return { getTransactionsWithParams, sendOptions, description: "Withdraw liquidity" };
}
