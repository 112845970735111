import {
    BsMetadata,
    JupiterSwapQuote,
    LoopRoutePlatform,
    LoopVaultInfo,
    MeteoraLoopMetadata,
    OracleQuote,
    UserLoopInfo
} from "@bridgesplit/abf-sdk";
import { Meteora } from "@bridgesplit/bs-protos";

import { BestQuote, BorrowCap, MarketPrincipalStats } from "./market";
import { StrategyDuration } from "./preset";
import { AbfLoanExpanded } from "./loan";

export interface LoopExpandedBase {
    collateralToken: BsMetadata;
    principalToken: BsMetadata;
    vaultIdentifier: string;
    loopVault: LoopVaultInfo;
    principalOracle: OracleQuote;
    collateralOracle: OracleQuote;
    principalStats: MarketPrincipalStats;
    borrowCap: BorrowCap;
}

export interface LoopExpandedMeteora extends LoopExpandedBase {
    type: LoopRoutePlatform.Meteora;
    depositType: LoopTransferType.CollateralOnly;
    withdrawType: LoopTransferType.PrincipalOnly;
    meteoraPool: MeteoraLoopMetadata;
    tokenA: BsMetadata;
    tokenB: BsMetadata;
}

export interface LoopExpandedJupiter extends LoopExpandedBase {
    type: LoopRoutePlatform.Jupiter;
    depositType: LoopTransferType.CollateralOnly;
    withdrawType: LoopTransferType.CollateralOnly;
}

export type LoopExpanded = LoopExpandedMeteora | LoopExpandedJupiter;

export enum LoopTransferType {
    CollateralOnly,
    PrincipalOnly
}

export interface LoopPositionExpanded {
    loanAddress: string;
    userLoopInfo: UserLoopInfo;
    loanExpanded: AbfLoanExpanded;
    loopExpanded: LoopExpanded;
}

export interface BestLoopQuoteQuery {
    loopVault: string;
    collateralAmount: number;
    leverageMultiplier: number;
    duration: StrategyDuration;
}

export interface BestLoopQuote {
    bestQuote: BestQuote;
    netApyPct: number;
}

export interface LoopQuoteExpanded extends BestLoopQuote {
    collateralAmount: number;
    leveragedCollateralAmount: number;
    principalAmount: number;
    leverageMultiplier: number;
}

export type ExternalWindQuoteParams = {
    loopExpanded: LoopExpanded | undefined;
    slippagePercentDecimals: number;
    principalAmount: number | undefined;
    skip?: boolean;
};

export type ExternalUnwindQuoteParams = {
    loopPositionExpanded: LoopPositionExpanded | undefined;
    slippagePercentDecimals: number;
    skip?: boolean;
    disablePoll?: boolean;
};

type JupiterWindQuote = {
    type: LoopRoutePlatform.Jupiter;
    quote: JupiterSwapQuote;
};
type MeteoraWindQuote = {
    type: LoopRoutePlatform.Meteora;
    quote: Meteora.DynamicPoolDepositQuote;
};

export type ExternalWindQuote = JupiterWindQuote | MeteoraWindQuote;
export type ExternalQuoteResponse<T> = {
    data: T | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isError?: boolean;
};

type JupiterUnwindQuote = {
    type: LoopRoutePlatform.Jupiter;
    quote: JupiterSwapQuote;
};
type MeteoraUnwindQuote = {
    type: LoopRoutePlatform.Meteora;
    quote: Meteora.DynamicPoolWithdrawQuote;
};

export type ExternalUnwindQuote = JupiterUnwindQuote | MeteoraUnwindQuote;

export interface LoopPositionStats {
    // Both of these are unused
    // profitLossActiveUsd: number;
    // profitLossPastUsd: number;

    netPositionValue: number;
    wAvgApy: number;
}
