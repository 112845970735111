import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CookieAttributes } from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import { AppCookie } from "../utils";

type LocalStorageState = Record<string, string>;
const initialState: LocalStorageState = {};
const localStorageSlice = createSlice({
    name: "localStorageSlice",
    initialState,
    reducers: {
        setLocalStorageItem: (state, action: PayloadAction<{ key: string; value: string }>) => {
            state[action.payload.key] = action.payload.value;
        },
        clearLocalStorageItem: (state, action: PayloadAction<{ key: string }>) => {
            delete state[action.payload.key];
        }
    }
});

export default localStorageSlice.reducer;

const { setLocalStorageItem } = localStorageSlice.actions;
const getSlice = (state: { localStorageSlice: LocalStorageState }): LocalStorageState => state.localStorageSlice;

export function useLocalStorage<T>(
    localStorageKey: string,
    defaultValue: T,
    attributes?: CookieAttributes
): [T, (param: T) => void] {
    const state: LocalStorageState = useSelector(getSlice);

    const raw = state[localStorageKey] ?? AppCookie.get(localStorageKey);

    const value: T = raw ? JSON.parse(raw) : defaultValue;
    const dispatch = useDispatch();

    function setValue(newVal: T) {
        const stringified = JSON.stringify(newVal);
        AppCookie.set(localStorageKey, stringified, attributes);
        dispatch(setLocalStorageItem({ key: localStorageKey, value: stringified }));
    }

    return [value, setValue];
}
