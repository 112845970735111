import { DialogWrapper } from "@bridgesplit/ui";
import { useAppDialog } from "app/utils";

import AccessWaitlistContent from "./AccessWaitlistContent";

export default function AccessWaitlistDialog() {
    const { close } = useAppDialog();
    return (
        <DialogWrapper sx={{ padding: 2 }}>
            <AccessWaitlistContent onClose={close} showNotifyButton />
        </DialogWrapper>
    );
}
