import { Icons, NotificationTypeStyles } from "@dialectlabs/react-ui";

export const DIALECT_DAPP_ADDRESS = "L1T3Met3JG3EieFx8TTEiFE75ZL3huBdPTQ9CeaA7cK";

NotificationTypeStyles.loan_payment_reminder = {
    Icon: <Icons.Wallet width={12} height={12} />,
    iconColor: "#FFFFFF",
    iconBackgroundColor: "#4CAF50",
    iconBackgroundBackdropColor: "#4CAF501A",
    linkColor: "#45A049"
};

NotificationTypeStyles.liquidation_risk_reminder = {
    Icon: <Icons.Bell width={12} height={12} />,
    iconColor: "#FFFFFF",
    iconBackgroundColor: "#FF0000",
    iconBackgroundBackdropColor: "#FF00001A",
    linkColor: "#FF0000"
};

NotificationTypeStyles.product_updates = {
    Icon: <Icons.Settings width={12} height={12} />,
    iconColor: "#FFFFFF",
    iconBackgroundColor: "#2196F3",
    iconBackgroundBackdropColor: "#2196F31A",
    linkColor: "#1E88E5"
};

NotificationTypeStyles.refinance_reminder = {
    Icon: <Icons.Resend width={12} height={12} />,
    iconColor: "#FFFFFF",
    iconBackgroundColor: "#9C27B0",
    iconBackgroundBackdropColor: "#9C27B01A",
    linkColor: "#8E24AA"
};

NotificationTypeStyles.partial_liquidation_risk_reminder = {
    Icon: <Icons.BellButtonOutline width={12} height={12} />,
    iconColor: "#FFFFFF",
    iconBackgroundColor: "#FFC107",
    iconBackgroundBackdropColor: "#FFC1071A",
    linkColor: "#FFB300"
};
