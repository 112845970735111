import { useMemo } from "react";

import { DialogHeader } from "../../common";
import WalletRequiredWrapper from "../WalletRequiredWrapper";
import WalletNotConnected from "./WalletNotConnected";
import WalletNotRegistered from "./WalletNotRegistered";
import { WalletConnectError } from "./type";
import WalletNotApprovedByAdmin from "./WalletNotApprovedByAdmin";
import PasskeyError from "./PasskeyError";

type TransactionsWalletConnectProps = {
    error: WalletConnectError | undefined;
    isLoading: boolean;
    header?: { description: string };
};
export default function TransactionsWalletConnect(props: TransactionsWalletConnectProps) {
    const content = useMemo(() => {
        switch (props.error) {
            case WalletConnectError.None:
                return null;
            case WalletConnectError.NotConnected:
                return <WalletNotConnected />;
            case WalletConnectError.NotRegistered:
                return <WalletNotRegistered />;
            case WalletConnectError.NotApprovedByAdmin:
                return <WalletNotApprovedByAdmin />;
            case WalletConnectError.PasskeyError:
                return <PasskeyError />;

            default:
                return null;
        }
    }, [props.error]);

    return (
        <WalletRequiredWrapper>
            {props.header && <DialogHeader header="Connect wallet" description={props.header.description} />}
            {content}
        </WalletRequiredWrapper>
    );
}
