import { createApi } from "@reduxjs/toolkit/query/react";

import { UPDATE_NEWSLETTER_ROUTE } from "../constants";
import { abfSerializeQueryArgs, messagingBaseQuery } from "./util";

export interface NewsletterUserInfo {
    email: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    tags?: string;
    source?: string;
    custodians?: string;
}

export const messagingApi = createApi({
    reducerPath: "messagingApi",
    baseQuery: messagingBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,

    endpoints: (builder) => ({
        updateNewsletter: builder.mutation<void, NewsletterUserInfo>({
            query(body) {
                return {
                    url: UPDATE_NEWSLETTER_ROUTE,
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const { useUpdateNewsletterMutation } = messagingApi;
