import { useMemo } from "react";

import debounce from "lodash.debounce";
import { Column, ExternalLink, Row, Text, TextButton } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { BsMetaUtil, LoopExpanded } from "@bridgesplit/abf-react";
import { LoopRoutePlatform } from "@bridgesplit/abf-sdk";
import { ArrowOutwardOutlined } from "@mui/icons-material";

import { TokenInputWithPrice } from "../../common";
import { useLoopSetCollateralAmount, useLoopBalanceChecker } from "./util";
import { useWindContext } from "./WindContext";
import { useLoopContext } from "../LoopContext";

export default function WindAmount() {
    const {
        form: { collateralAmount }
    } = useWindContext();
    const { loopExpanded } = useLoopContext();
    const handleChange = useLoopSetCollateralAmount();

    const { BalanceDisplay } = useLoopBalanceChecker();

    const setValueDebounced = useMemo(() => debounce(handleChange, 500), [handleChange]);

    return (
        <Column spacing={0.5}>
            <Row spaceBetween>
                <Text color="caption" variant="body2">
                    You deposit
                </Text>
                <GetLoopCollateralButton loopExpanded={loopExpanded} />
            </Row>
            <TokenInputWithPrice
                metadata={loopExpanded?.collateralToken}
                selectToken={null}
                value={collateralAmount}
                setValue={setValueDebounced}
            />

            <BalanceDisplay />
        </Column>
    );
}

function GetLoopCollateralButton({ loopExpanded }: { loopExpanded: LoopExpanded | undefined }) {
    const { open } = useAppDialog();

    if (loopExpanded?.type === LoopRoutePlatform.Meteora) {
        return (
            <TextButton
                color="primary"
                variant="body2"
                onClick={() => open(AppDialog.LoopExternalTransfers, { loopExpanded })}
            >
                Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
            </TextButton>
        );
    }

    if (loopExpanded?.type === LoopRoutePlatform.Jupiter) {
        return (
            <ExternalLink
                target="_blank"
                rel="noopener"
                href={`https://jup.ag/swap/${BsMetaUtil.getSymbol(loopExpanded?.principalToken)}-${BsMetaUtil.getSymbol(
                    loopExpanded?.collateralToken
                )}`}
            >
                <TextButton color="primary" variant="body2">
                    Get {BsMetaUtil.getSymbol(loopExpanded?.collateralToken)}
                    <ArrowOutwardOutlined />
                </TextButton>
            </ExternalLink>
        );
    }

    return null;
}
