import { useMemo } from "react";

import {
    AbfRole,
    ROLE_NUM_TO_STR,
    convertRolesNumbersToRole,
    groupAdminApi,
    putCustodianGroupInvites,
    putCustodianGroupRoles,
    putGroupUserRoles,
    putUserGroupInvites,
    useAbfFetches,
    useActiveGroup,
    useAuthStorage,
    useCustodianCodesForAdminQuery,
    useCustodianMembersQuery,
    useGroupMembersQuery,
    useGroupsByIdentifiers,
    useGroup,
    useUserProfile
} from "@bridgesplit/abf-react";
import { LOADING_ERROR, Result } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
    MemberPermission,
    PermissionCreatedGroup,
    PermissionCreatedUser,
    PermissionInvite,
    UserRoleChange
} from "./types";

export function usePutPermissionChange() {
    const putCustodianChange = usePutCustodianChange();
    const putGroupChange = usePutGroupChange();
    const { resetGroupAdminApi } = useAbfFetches();

    return async (
        change: MemberPermission | undefined,
        options?: { skipRefresh?: boolean }
    ): Promise<Result<boolean>> => {
        if (change === undefined) return Result.errFromMessage(LOADING_ERROR);

        const getResult = change.granter === "custodian" ? putCustodianChange(change) : putGroupChange(change);
        const result = await getResult;
        if (result.isOk() && !options?.skipRefresh) {
            resetGroupAdminApi();
        }
        return result;
    };
}

export function editMemberPermission<T extends MemberPermission | undefined>(member: T, newRoles: AbfRole[]): T {
    if (!member) return member;
    member.roles = new Set(newRoles);
    return member;
}

function usePutCustodianChange() {
    const { activeGroup } = useGroup();
    const custodian = activeGroup?.groupIdentifier;
    const { getExisting, getInvites } = useExistingCustodianMembers();

    return async (change: PermissionCreatedGroup | PermissionInvite): Promise<Result<boolean>> => {
        if (!custodian) return Result.errFromMessage("Insufficient permissions");
        let result: Result<boolean>;
        if (change.type === "invite") {
            const exitingInvites = await getInvites();
            if (exitingInvites.isErr()) {
                return Result.err(exitingInvites);
            }
            const existing = exitingInvites.unwrap();
            existing.set(change.email, Array.from(change.roles));
            result = await putCustodianGroupInvites(
                Array.from(existing.entries()).map(([email, roles]) => ({ email, roles }))
            );
        } else {
            const existingMembers = await getExisting();
            if (existingMembers.isErr()) {
                return Result.err(existingMembers);
            }
            const existing = existingMembers.unwrap();
            existing.set(change.group.groupIdentifier, Array.from(change.roles));
            result = await putCustodianGroupRoles(
                custodian,
                Array.from(existing.entries()).map(([groupIdentifier, roles]) => ({ groupIdentifier, roles }))
            );
        }

        return result;
    };
}

function usePutGroupChange() {
    const { activeGroup } = useGroup();
    const groupIdentifier = activeGroup?.groupIdentifier;

    const { getExisting, getInvites } = useExistingGroupMembers();

    return async (change: PermissionCreatedUser | PermissionInvite): Promise<Result<boolean>> => {
        if (!groupIdentifier) return Result.errFromMessage("Invalid permissions");

        let result: Result<boolean>;
        if (change.type === "invite") {
            const exitingInvites = await getInvites();
            if (exitingInvites.isErr()) {
                return Result.err(exitingInvites);
            }
            const existing = exitingInvites.unwrap();
            existing.set(change.email, Array.from(change.roles));
            result = await putUserGroupInvites(
                Array.from(existing.entries()).map(([email, roles]) => ({ email, roles }))
            );
        } else {
            const existingMembers = await getExisting();
            if (existingMembers.isErr()) {
                return Result.err(existingMembers);
            }
            const existing = existingMembers.unwrap();
            existing.set(change.user.identifier, Array.from(change.roles));
            result = await putGroupUserRoles(
                groupIdentifier,
                Array.from(existing.entries()).map(([userIdentifier, roles]) => ({ userIdentifier, roles }))
            );
        }

        return result;
    };
}

export function useCustodianMembers() {
    const { state } = useAuthStorage();
    const { data, isFetching: isLoading } = useCustodianMembersQuery(state.groupIdentifier ?? skipToken, {
        skip: !state.groupIdentifier
    });

    const groupIdentifiers = data?.existing.map((d) => d.groupIdentifier);
    const { cache } = useGroupsByIdentifiers(groupIdentifiers);

    /**
     * Fix for rerenders not getting triggered after group meta loads
     * Assumes that cache is loading if any group identifiers are missing
     * This is a safe assumption unless database was manually manipulated
     */
    const cacheLoading = useMemo(() => {
        if (!groupIdentifiers) return true;
        const missingGroups = groupIdentifiers.filter((group) => !cache.get(group));
        return missingGroups.length;
    }, [cache, groupIdentifiers]);

    const existing = useMemo((): MemberPermission[] => {
        const groupIdentifierToRoles = new Map<string, PermissionCreatedGroup>();
        data?.existing.forEach((c) => {
            const roles = groupIdentifierToRoles.get(c.groupIdentifier)?.roles ?? new Set();

            roles.add(ROLE_NUM_TO_STR[c.role]);
            const group = cache.get(c.groupIdentifier);

            if (group) {
                const newGroup: PermissionCreatedGroup = {
                    granter: "custodian",
                    group,
                    roles,
                    type: "group",
                    key: c.groupIdentifier
                };
                groupIdentifierToRoles.set(c.groupIdentifier, newGroup);
            }
        });
        return Array.from(groupIdentifierToRoles.values()).filter((c) => !!c.group);
    }, [cache, data?.existing]);

    const invited = useMemo((): MemberPermission[] => {
        const emailToRoles = new Map<string, PermissionInvite>();
        data?.invited.forEach((c) => {
            const roles = emailToRoles.get(c.groupAdminEmail)?.roles ?? new Set();
            roles.add(ROLE_NUM_TO_STR[c.role]);
            const newGroup: PermissionInvite = {
                granter: "custodian",
                email: c.groupAdminEmail,
                roles,
                type: "invite",
                key: c.groupAdminEmail
            };
            emailToRoles.set(c.groupAdminEmail, newGroup);
        });
        return Array.from(emailToRoles.values());
    }, [data?.invited]);

    if (isLoading || cacheLoading) return undefined;
    const combined = [...invited, ...existing];

    return Object.fromEntries(combined.map((c) => [c.key, c]));
}

function useExistingGroupMembers() {
    const { groupIdentifier } = useActiveGroup();

    const [getGroupMembers] = groupAdminApi.endpoints.groupMembers.useLazyQuery();

    async function getExisting(): Promise<Result<Map<string, AbfRole[]>>> {
        if (!groupIdentifier) return Result.err(LOADING_ERROR);
        const members = await getGroupMembers(groupIdentifier, true).unwrap();
        const map = new Map(members.existing.map((e) => [e.user.identifier, e.permissions?.[groupIdentifier] || []]));
        return Result.ok(map);
    }

    async function getInvites(): Promise<Result<Map<string, AbfRole[]>>> {
        if (!groupIdentifier) return Result.err(LOADING_ERROR);
        const members = await getGroupMembers(groupIdentifier, true).unwrap();

        const map = new Map<string, AbfRole[]>();
        members.invited.forEach((member) => {
            const roles = map.get(member.userEmail) || [];
            map.set(member.userEmail, [...roles, ROLE_NUM_TO_STR[member.role]]);
        });
        return Result.ok(map);
    }

    return { getExisting, getInvites };
}

function useExistingCustodianMembers() {
    const { groupIdentifier } = useActiveGroup();

    const [getCustodianMembers] = groupAdminApi.endpoints.custodianMembers.useLazyQuery();

    async function getExisting(): Promise<Result<Map<string, AbfRole[]>>> {
        if (!groupIdentifier) return Result.err(LOADING_ERROR);
        const members = await getCustodianMembers(groupIdentifier, true).unwrap();
        const map = new Map<string, AbfRole[]>();
        members.existing.forEach((member) => {
            const roles = map.get(member.groupIdentifier) || [];
            map.set(member.groupIdentifier, [...roles, ROLE_NUM_TO_STR[member.role]]);
        });
        return Result.ok(map);
    }

    async function getInvites(): Promise<Result<Map<string, AbfRole[]>>> {
        if (!groupIdentifier) return Result.err(LOADING_ERROR);
        const members = await getCustodianMembers(groupIdentifier, true).unwrap();

        const map = new Map<string, AbfRole[]>();
        members.invited.forEach((member) => {
            const roles = map.get(member.groupAdminEmail) || [];
            map.set(member.groupAdminEmail, [...roles, ROLE_NUM_TO_STR[member.role]]);
        });
        return Result.ok(map);
    }

    return { getExisting, getInvites };
}

function useGroupMembers() {
    const { activeGroup } = useGroup();
    const groupIdentifier = activeGroup?.groupIdentifier;
    return useGroupMembersQuery(groupIdentifier ?? skipToken, {
        skip: !groupIdentifier
    });
}

export function useOrganizationMembers() {
    const { activeGroup } = useGroup();
    const { user } = useUserProfile();

    const groupIdentifier = activeGroup?.groupIdentifier;
    const { data, isFetching: isLoading } = useGroupMembers();
    const walletToRolesDiff = useRolesDiffMap();

    const existing = useMemo((): MemberPermission[] | undefined => {
        if (!groupIdentifier) return undefined;
        return data?.existing.map((c) => {
            const roles = new Set(c.permissions[groupIdentifier] || []);
            const firstWallet = c.wallets.find((w) => w.groupIdentifier === groupIdentifier);
            const rolesDiff = firstWallet ? walletToRolesDiff?.get(firstWallet.wallet) : undefined;
            const newGroup: PermissionCreatedUser = {
                user: c.user,
                wallet: firstWallet,
                rolesDiff,
                roles,
                granter: "organization",
                type: "user",
                key: c.user.email,
                isSignedInUser: c.user.identifier === user.identifier
            };
            return newGroup;
        });
    }, [data?.existing, groupIdentifier, user.identifier, walletToRolesDiff]);

    const invited = useMemo((): MemberPermission[] => {
        const emailToRoles = new Map<string, PermissionInvite>();
        data?.invited.forEach((c) => {
            const roles = emailToRoles.get(c.userEmail)?.roles ?? new Set();
            roles.add(ROLE_NUM_TO_STR[c.role]);
            const newGroup: PermissionInvite = {
                email: c.userEmail,
                granter: "organization",
                roles,
                type: "invite",
                key: c.userEmail
            };
            emailToRoles.set(c.userEmail, newGroup);
        });
        return Array.from(emailToRoles.values());
    }, [data?.invited]);

    if (isLoading || !existing) return undefined;
    const combined = [...invited, ...existing];

    return Object.fromEntries(combined.map((c) => [c.key, c]));
}

function useRolesDiffMap() {
    const { data } = useGroupMembers();

    const { groupIdentifier } = useActiveGroup();

    if (!data?.existing || !data.on_chain) return undefined;

    // Each wallet's roles can be a combination of multiple users to account for users sharing wallets
    const offChainWalletToRole = new Map<string, AbfRole[]>();
    data?.existing.forEach(({ wallets, permissions }) => {
        const roles =
            groupIdentifier && permissions && groupIdentifier in permissions ? permissions[groupIdentifier] : [];

        wallets.forEach(({ wallet }) => {
            const prevRoles = offChainWalletToRole.get(wallet) || [];
            offChainWalletToRole.set(wallet, [...prevRoles, ...roles]);
        });
    });

    const onChainWalletToRole = new Map<string, AbfRole[]>(Object.entries(data.on_chain));

    const allWallets = [...Array.from(offChainWalletToRole.keys()), ...Array.from(onChainWalletToRole.keys())];

    const walletToDiff = new Map<string, UserRoleChange>();

    allWallets.forEach((w) => {
        const onChainWallets = new Set(onChainWalletToRole.get(w) || []);
        const offChainWallets = new Set(offChainWalletToRole.get(w) || []);

        const diff = getRolesDiff(onChainWallets, offChainWallets);
        walletToDiff.set(w, diff);
    });

    return walletToDiff;
}

function getRolesDiff(onChainRoles: Set<AbfRole>, offChainRoles: Set<AbfRole>): UserRoleChange {
    const addRoles = new Set<AbfRole>([...offChainRoles].filter((role) => !onChainRoles.has(role)));
    addRoles.delete(AbfRole.Member);

    const removeRoles = new Set<AbfRole>([...onChainRoles].filter((role) => !offChainRoles.has(role)));
    removeRoles.delete(AbfRole.Member);

    return {
        changesNeeded: !!addRoles.size || !!removeRoles.size,
        addRoles,
        removeRoles
    };
}

export function useCustodianCodesForAdmin() {
    const { groupIdentifier } = useActiveGroup();
    const { data: codes } = useCustodianCodesForAdminQuery(groupIdentifier ?? skipToken, { skip: !groupIdentifier });
    if (!codes) return codes;
    const codeToRoles = codes.reduce((map, { code, role }) => {
        const prev = map.get(code) ?? [];
        map.set(code, [...prev, ...convertRolesNumbersToRole([role])]);
        return map;
    }, new Map<string, AbfRole[]>());

    return Array.from(codeToRoles.entries()).map(([code, roles]) => ({ code, roles }));
}
