import { useMemo } from "react";

import { ExpandContent, WalletButtons, WalletDisplaySmall } from "@bridgesplit/ui";
import { useActiveWallet } from "@bridgesplit/abf-react";

export default function WalletNotConnected() {
    const { userWalletsForGroup: activeWallets } = useActiveWallet();

    const header = useMemo(() => {
        if (!activeWallets) return "";

        if (activeWallets.length > 1) return "Please connect one of your self-custodial wallets";

        return "Please connect your self-custodial wallet";
    }, [activeWallets]);

    return (
        <>
            <ExpandContent
                hideExpand={activeWallets?.length === 1}
                defaultExpanded
                textVariant="body2"
                textColor="caption"
                spacing={0.5}
                header={header}
            >
                {activeWallets?.map((w) => (
                    <WalletDisplaySmall key={w.wallet} address={w.wallet} />
                ))}
            </ExpandContent>
            <WalletButtons />
        </>
    );
}
