import { AbfRole, RoleView } from "@bridgesplit/abf-react";
import { Icon, IconType } from "@bridgesplit/ui";
import {
    VerifiedUserOutlined,
    AdminPanelSettingsOutlined,
    CreditCardOutlined,
    AccountCircleOutlined
} from "@mui/icons-material";

export const ROLE_METADATA: Record<AbfRole, { name: string; description: string; icon: JSX.Element }> = {
    [AbfRole.SuperAdmin]: {
        name: "Owner",
        description: "Edit roles for all users in your origination, including admins",
        icon: <VerifiedUserOutlined />
    },
    [AbfRole.Admin]: {
        name: "Administrator",
        description: "Edit roles for all users in your origination, except admins",
        icon: <AdminPanelSettingsOutlined />
    },
    [AbfRole.Lender]: {
        name: "Lender",
        description: "Send loan offers, claim loan payments, handle defaulted loans",
        icon: <Icon type="loan" />
    },
    [AbfRole.Borrower]: {
        name: "Borrower",
        description: "Accept loan offers and make loan payments using deposited assets",
        icon: <Icon type="borrow" />
    },
    [AbfRole.EscrowManager]: {
        name: "Asset manager",
        description: "Deposit/withdraw all organization-owned assets (digital tokens, USD,  etc.)",
        icon: <CreditCardOutlined />
    },
    [AbfRole.Member]: { name: "Member", description: "View assets and loans", icon: <AccountCircleOutlined /> }
};

const GROUP_VIEW_META: Record<RoleView, { role: string; action: string; icon: IconType }> = {
    [RoleView.Borrow]: { role: "Borrower", action: "Borrow", icon: "borrow" },
    [RoleView.Lend]: { role: "Lender", action: "Lend", icon: "lend" }
};

export function getUserGroupViewMeta(groupView: RoleView | undefined) {
    return GROUP_VIEW_META[groupView ?? RoleView.Borrow];
}
