import { EmptyPlaceholder, Icon } from "@bridgesplit/ui";
import { BsMetaUtil } from "@bridgesplit/abf-react";

import { useMarketContext } from "./MarketContext";

export function EmptyOrderbook() {
    const { token } = useMarketContext();

    return (
        <EmptyPlaceholder
            header="No available offers"
            description={`There aren't any ${BsMetaUtil.getSymbol(token)} offers for this duration`}
        />
    );
}

export function EmptyChart() {
    return (
        <EmptyPlaceholder
            icon={<Icon type="pending" />}
            header={"No matching offers"}
            description="New offers will show here"
        />
    );
}
