import { Column, SxType, useAppPalette } from "@bridgesplit/ui";
import { Drawer, DrawerProps } from "@mui/material";

import { MOBILE_PADDING, NAV_Z_INDEX, useNavbarHeight } from "../navbar";

export default function AppTopDrawerWrapper({
    sx,
    children,
    wrapperSx,
    ...props
}: Omit<DrawerProps, "anchor"> & { wrapperSx?: SxType }) {
    const { heightInPixels } = useNavbarHeight();
    const { background } = useAppPalette();
    return (
        <Drawer
            sx={{
                ...sx,
                ".MuiDrawer-paper": { background: background },
                zIndex: NAV_Z_INDEX - 1
            }}
            anchor="top"
            {...props}
        >
            <Column sx={{ py: MOBILE_PADDING, mt: heightInPixels, ...wrapperSx }} spacing={1}>
                {children}
            </Column>
        </Drawer>
    );
}
