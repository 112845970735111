import { SxType } from "../types";

export const zoomSx = (hovered: boolean): SxType => ({
    transition: "transform 0.3s",
    transform: hovered ? "scale(1.1)" : undefined
});

export const cardDropSx = (hovered: boolean, hoverAmount = 5): SxType => ({
    transition: "transform 0.3s",
    transform: hovered ? `translateY(-${hoverAmount}px)` : undefined
});

export const verticalScroll = (height: string): SxType => ({
    overflow: "scroll",
    maxHeight: height,
    minHeight: "fit-content",
    ...hideScrollbarSx
});

export const hideScrollbarSx = {
    "::-webkit-scrollbar ": { display: "none" },
    scrollbarWidth: "none"
};
