import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

import { WhirlpoolOffchain } from "../types";

const WHIRLPOOL_TAG = "WhirlpoolList";
export const orcaExternalApi = createApi({
    reducerPath: "orcaExternalApi",
    tagTypes: [WHIRLPOOL_TAG],
    baseQuery: fetchBaseQuery({ baseUrl: "https://api.mainnet.orca.so/" }),
    endpoints: (builder) => ({
        whirlpoolsOffchain: builder.query<{ whirlpools: WhirlpoolOffchain[] }, void>({
            providesTags: [WHIRLPOOL_TAG],
            query: () => ({
                url: "/v1/whirlpool/list",
                method: "GET"
            })
        })
    })
});

export const { useWhirlpoolsOffchainQuery } = orcaExternalApi;

export function useOrcaExternalApi() {
    const dispatch = useDispatch();
    return { resetOrcaExternalApi: () => dispatch(orcaExternalApi.util.invalidateTags([WHIRLPOOL_TAG])) };
}
