import { useAuth } from "@bridgesplit/abf-react";

import { DialectNotificationComponent } from "../dialect";

export default function DialectNotificationButton() {
    const { isAuthenticated, isLoading, isWalletBased } = useAuth();

    if (!isAuthenticated || isLoading || !isWalletBased) return null;

    return <DialectNotificationComponent />;
}
