import { useEffect, useRef } from "react";

import { useTransactionsState } from "@bridgesplit/react";
import { useTransactionWalletAuth } from "@bridgesplit/abf-react";
import { DialogWrapper } from "@bridgesplit/ui";
import { useAppDialog, AppDialog } from "app/utils";

import WalletValidatorWrapper from "./WalletValidatorWrapper";
import useMinTransactionBalance from "./useMinTransactionBalance";

export default function TransactionsDialog() {
    const { getData, close } = useAppDialog();
    const multiPartSender = getData<AppDialog.TransactionSender>();

    const { reset } = useTransactionsState();

    const getAuth = useTransactionWalletAuth();

    const { balanceCheckNeeded, BalancePrompt } = useMinTransactionBalance();

    function reject() {
        close();
        reset();
        multiPartSender?.reject?.();
    }

    if (!multiPartSender) return null;

    if (balanceCheckNeeded) {
        return (
            <DialogWrapper onClose={reject}>
                <BalancePrompt />
            </DialogWrapper>
        );
    }

    // Resolves the transaction promise as soon as this is returned hit
    return (
        <WalletValidatorWrapper
            dialog={{
                description: multiPartSender.description,
                onClose: reject
            }}
        >
            <AsyncSuccess
                resolve={() => {
                    close();
                    const auth = getAuth();
                    if (auth.isOk()) {
                        multiPartSender.resolve?.(auth.unwrap());
                    } else {
                        multiPartSender.reject?.();
                    }
                }}
            />
        </WalletValidatorWrapper>
    );
}

function AsyncSuccess({ resolve }: { resolve: () => void }) {
    // use ref to exactly call once even in strict dev mode
    const queryStarted = useRef<boolean>();

    useEffect(() => {
        if (queryStarted.current) return;
        queryStarted.current = true;
        resolve();
    }, [resolve]);

    return null;
}
