import { AbfOrderSide } from "./order";
import { DurationUnit } from "./time";

export interface LoanRequest {
    id: number;
    maker: string;
    name: string;
    nonce: string;
    lockboxAddress: string;
    lockboxNftMint: string;
    description: string;
    designatedTaker?: string;
    principalAmountMin?: number;
    principalAmountMax?: number;
    principalMint?: string;
    apyMin?: number;
    apyMax?: number;
    durationMin?: number;
    createdAt: number;
    durationMax?: number;
    durationType?: DurationUnit;
    archived: boolean;
    rejected: boolean;
    lockboxCreated: boolean;
    maxOutstandingPayments?: number;
    repaymentRrule?: string;
    side: AbfOrderSide;
    allowEarlyRepayments?: boolean;
    liquidationThreshold?: number;
}

export interface LoanRequestCollateral {
    assetTypeDiscriminator: AssetTypeIdentifier;
    assetKey: string;
    amount: number;
}

export enum AssetTypeIdentifier {
    SplToken = "HFxmxLSwEbB",
    StakedSol = "9uBaD2bCVi5",
    OrcaPosition = "AQBQodNFs6F"
}
