import { Row, Text, TextButton } from "@bridgesplit/ui";
import { RoleView } from "@bridgesplit/abf-react";
import { EXPLORE_REQUESTS_SLUG, CREATE_BORROW_REQUEST_SLUG } from "app/constants";

import { useMarketContext } from "./MarketContext";

export default function RequestPrompt() {
    const { view } = useMarketContext();

    if (view === RoleView.Lend) {
        return (
            <Row wrap spacing={0.5}>
                <Text color="caption" variant="body2">
                    Looking to create offers for custom requests?
                </Text>
                <TextButton to={EXPLORE_REQUESTS_SLUG} variant="body2">
                    Explore open loans
                </TextButton>
            </Row>
        );
    }

    return (
        <Row wrap spacing={0.5}>
            <Text color="caption" variant="body2">
                Looking for more flexible terms or to borrow against multiple assets?
            </Text>
            <TextButton to={CREATE_BORROW_REQUEST_SLUG} variant="body2">
                Create a funding request
            </TextButton>
        </Row>
    );
}
