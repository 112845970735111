import { useState } from "react";

import { AbfPermission, useActiveWallet, useWalletTransfer, useUserUnrecognizedAssets } from "@bridgesplit/abf-react";
import { DialogWrapper } from "@bridgesplit/ui";
import { MISSING_PARAM_ERROR, Result, formatAddress, formatNum } from "@bridgesplit/utils";
import { getAvatarFromString } from "@bridgesplit/react";

import { AppButton, DialogHeader } from "../common";
import { SelectLiteAssets, useSelectAddressController } from "./common";
import { useTransactionSender } from "../transactions";

export default function WithdrawUnrecognizedDialog() {
    const [selectedState, setSelected] = useState<Set<string>>();
    const { activeWallet } = useActiveWallet();
    const { AddressComponent, address, errorMessage } = useSelectAddressController();
    const { data: assets } = useUserUnrecognizedAssets();
    const selected = selectedState ?? new Set(assets?.map((a) => a.mint));
    const selectedAssets = assets?.filter((d) => selected.has(d.mint));
    const transfer = useWalletTransfer();
    const send = useTransactionSender();

    async function onClickWithResult() {
        if (!selectedAssets || !address || !activeWallet) return Result.errFromMessage(MISSING_PARAM_ERROR);
        return send(transfer, {
            spl: selectedAssets.map(({ tokenAmount, mint }) => ({
                sender: activeWallet.wallet,
                payer: activeWallet.wallet,
                receiver: address,
                mint,
                amount: parseInt(tokenAmount.amount)
            }))
        });
    }

    return (
        <DialogWrapper>
            <DialogHeader
                header="Withdraw assets"
                description="You deposited the following assets that aren't recognized by Loopscale"
            />
            <AddressComponent />

            <SelectLiteAssets
                emptyText="No unrecognized assets"
                loading={!assets}
                assets={assets?.map(({ mint, tokenAmount }) => ({
                    image: getAvatarFromString(mint),
                    name: `${formatNum(tokenAmount.uiAmount ?? 0)} ${formatAddress(mint)}`,
                    isFungible: true,
                    mint: mint
                }))}
                selected={selected ?? new Set()}
                setSelected={setSelected}
            />

            <AppButton
                isTransaction
                authentication={{ permission: AbfPermission.WithdrawTokens }}
                fullWidth
                disabled={!selectedAssets?.length || !!errorMessage}
                asyncCta={{ onClickWithResult }}
                variant="contained"
            >
                {errorMessage || "Withdraw"}
            </AppButton>
        </DialogWrapper>
    );
}
