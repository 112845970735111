import {
    AddressText,
    EmptyPlaceholderProps,
    Icon,
    IconType,
    MenuItemProps,
    Row,
    TABLE_CTA_COLUMN_KEY,
    Table,
    TableColumn,
    TableMenuPopup,
    TagColor,
    TagText,
    Text,
    Tooltip,
    TooltipRow,
    TooltipText
} from "@bridgesplit/ui";
import { AdminPanelSettingsOutlined, Delete, PermIdentityOutlined } from "@mui/icons-material";
import { AbfGroupType, AbfRole, useGroup } from "@bridgesplit/abf-react";
import { useTransactionsState } from "@bridgesplit/react";
import { ROLE_METADATA } from "app/constants";
import { AppDialog, useAppDialog } from "app/utils";

import { MemberPermission, PermissionCreatedUser } from "../types";
import { MemberPermissionDisplay, getMemberPermissionName } from "../common";

type PermissionColumnName = "member" | "status" | "wallet" | "roles" | "cta";

type ManagePermissionsTableProps = {
    members: Record<string, MemberPermission> | undefined;
    searchPlaceholder: string;
    emptyPlaceholder: EmptyPlaceholderProps;
    hideColumns?: PermissionColumnName[];
};

export default function ManagePermissionsTable({
    members,
    searchPlaceholder,
    hideColumns,
    emptyPlaceholder
}: ManagePermissionsTableProps) {
    const groupPermissionsArray = members ? Object.values(members) : members;

    const tableData = groupPermissionsArray?.filter((d) => !!d.roles.size).map((data) => ({ data, key: data.key }));

    const filteredColumns = columns.filter((c) => !hideColumns?.includes(c.dataIndex));
    return (
        <Table
            preventEmptyRows
            loading={!members}
            searchPlaceholder={searchPlaceholder}
            paginationProps={{
                type: "managed",
                defaultSortKey: "member",
                getSearchFromRow: ({ data }) => {
                    if (data.type === "group") return [data.group.groupName];
                    if (data.type === "user") return [data.user.name ?? "", data.user.email];
                    return [data.email];
                }
            }}
            emptyPlaceholder={emptyPlaceholder}
            rows={tableData}
            columns={filteredColumns}
        />
    );
}

const columns: (TableColumn<MemberPermission> & { dataIndex: PermissionColumnName })[] = [
    {
        dataIndex: "status",
        title: "",
        width: "0%",
        render: ({ data }) => <Status data={data} />
    },

    {
        dataIndex: "member",
        title: "Member",
        width: "30%",
        render: ({ data }) => <MemberPermissionDisplay member={data} />,
        sortBy: ({ data }) => getMemberPermissionName(data)
    },

    {
        dataIndex: "wallet",
        title: "Wallet",
        width: "20%",
        render: ({ data }) => <Wallet data={data} />
    },
    {
        dataIndex: "roles",
        title: "Roles",
        width: "40%",
        render: ({ data }) => <RolesDisplay data={data} />
    },
    {
        dataIndex: TABLE_CTA_COLUMN_KEY,
        title: "",
        width: "10%",
        render: ({ data }) => <Cta data={data} />
    }
];

function Cta({ data }: { data: MemberPermission }) {
    const { activeGroup } = useGroup();
    const { open: openDialog } = useAppDialog();

    if (data.type === "user" && data.isSignedInUser) return null;

    const txnNeeded = data.type === "user" && !!data.rolesDiff?.changesNeeded && !!data.wallet;
    const items: MenuItemProps[] = [
        {
            isDangerous: true,
            icon: <Icon type="warning" />,
            text: "Finalize Roles",
            onClick: () => openDialog(AppDialog.VerifyMember, data as PermissionCreatedUser),
            hide: !txnNeeded
        },
        {
            icon: <PermIdentityOutlined />,
            text: "Modify roles",
            onClick: () => openDialog(AppDialog.ModifyLoanRoles, data),
            hide: activeGroup?.groupType === AbfGroupType.Custodian && data.granter === "organization"
        },
        {
            icon: <AdminPanelSettingsOutlined />,
            text: "Make admin",
            onClick: () => openDialog(AppDialog.ModifyAdminRoles, data),
            hide: data.granter !== "organization"
        },

        {
            icon: <Delete />,
            text: data.granter === "organization" ? "Remove from team" : "Remove all roles",
            onClick: () => openDialog(AppDialog.DeleteMember, data)
        }
    ];

    return <TableMenuPopup warning={txnNeeded} items={items} />;
}

function Wallet({ data }: { data: MemberPermission }) {
    const wallet = data.type === "user" ? data.wallet : undefined;

    if (wallet?.mpcIdentifier) {
        return (
            <TooltipText helpText={`${getMemberPermissionName(data)} is registered using a Passkey (MPC) wallet`}>
                Passkey
            </TooltipText>
        );
    }
    if (wallet) {
        return <AddressText address={wallet.wallet} />;
    }
    return (
        <TooltipText
            helpText={`${getMemberPermissionName(
                data
            )} has view-only permissions until they set up their Loopscale account`}
            color="disabled"
        >
            Pending
        </TooltipText>
    );
}

const MAX_TAGS = 3;
function RolesDisplay({ data }: { data: MemberPermission }) {
    const onChainRoles = data.type === "user" ? data.rolesDiff : undefined;
    const { transactionsInProgress } = useTransactionsState();

    const name = getMemberPermissionName(data);
    const amountNotShown = data.roles.size - 1 - MAX_TAGS;

    const roles = Array.from(data.roles)
        .filter((r) => r !== AbfRole.Member)
        .slice(0, MAX_TAGS);

    return (
        <Row spacing={0.5}>
            {roles.length === 0 && <Text color="caption"> View-only </Text>}
            {roles.map((r) => {
                const meta = ROLE_METADATA[r];

                const details = (function (): { title: string; icon: IconType; description: string; color: TagColor } {
                    if (data.type !== "user" || !data.wallet) {
                        return {
                            color: "body",
                            title: "View-only",
                            icon: "pending",
                            description: `${name} will have view-only ${meta.name} access since they haven't set up their Loopscale account`
                        };
                    }
                    if (transactionsInProgress) {
                        return {
                            color: "body",
                            title: "View-only",
                            icon: "pending",
                            description: "Role update in progress"
                        };
                    }
                    if (onChainRoles?.addRoles.has(r) || onChainRoles?.removeRoles.has(r)) {
                        return {
                            color: "error",
                            title: "Pending",
                            icon: "pending",
                            description: "This permission will be pending until you verify this member's wallet"
                        };
                    }
                    return {
                        color: "info",
                        title: "Confirmed",
                        icon: "accept",
                        description: `${name} has full permissions for the ${meta.name} role`
                    };
                })();

                return (
                    <Tooltip
                        key={r}
                        title={
                            <TooltipRow
                                sx={{ p: 0.5 }}
                                title={details.title}
                                icon={<Icon type={details.icon} />}
                                description={details.description}
                            />
                        }
                    >
                        <TagText color={details.color} textVariant="body2">
                            {meta.name}
                        </TagText>
                    </Tooltip>
                );
            })}
            {amountNotShown > 0 && (
                <Text color="disabled" variant="body2">
                    +{amountNotShown}
                </Text>
            )}
        </Row>
    );
}

function Status({ data }: { data: MemberPermission }) {
    const name = getMemberPermissionName(data);
    const { transactionsInProgress } = useTransactionsState();

    if (transactionsInProgress) {
        return (
            <TooltipText icon={false} helpText="Update in progress" color="disabled">
                <Icon type="pending" />
            </TooltipText>
        );
    }

    if (data.type !== "user") {
        return (
            <TooltipText icon={false} helpText="User hasn't registered" color="disabled">
                <Icon type="pending" />
            </TooltipText>
        );
    }

    if (!data.wallet) {
        return (
            <TooltipText
                icon={false}
                color="disabled"
                helpText={`${name} has view-only permissions until they register a wallet`}
            >
                <Icon type="view-only" />
            </TooltipText>
        );
    }
    if (data.rolesDiff?.changesNeeded) {
        return (
            <TooltipText
                icon={false}
                color="error"
                helpText={`You have pending permissions for ${name}. This is preventing them from taking actions with their roles`}
            >
                <Icon type="warning" />
            </TooltipText>
        );
    }

    return (
        <TooltipText icon={false} helpText="All roles are confirmed" color="success">
            <Icon type="accept" />
        </TooltipText>
    );
}
