import { useCallback, useMemo } from "react";

import {
    AbfEscrowedAsset,
    AbfEscrowedEvent,
    AbfEscrowInfo,
    AbfFeeAccount,
    AbfFeeSchedule,
    AbfLedgerAccount,
    AbfLoanVault,
    AbfOrder,
    AbfOrderSchedule,
    FeeScheduleInfo,
    PaymentEvent,
    LoanRequest,
    LoanRequestCollateral,
    LockboxAsset,
    LockboxWithAssets,
    SyndicatedOrder,
    SyndicatedOrderContributor,
    BsMetadata,
    AbfEscrowAccount,
    ExternalYieldSource,
    ExternalYieldInfo,
    ExternalYieldAccrued,
    ExternalYieldVault,
    LoopVaultInfo,
    UserLoopInfo
} from "@bridgesplit/abf-sdk";
import {
    SOL_DECIMALS,
    STAKED_SOL_MINT,
    USDC_MINT,
    USDC_SYMBOL,
    bpsToUiDecimals,
    lamportsToUiAmount,
    percentUiToDecimals,
    removeDuplicates
} from "@bridgesplit/utils";

import {
    CustodianExpanded,
    BestQuote,
    LoanRequestInfo,
    LockboxEvent,
    MarketStrategyStats,
    OfferQuote,
    OfferTermsInfo,
    OfferWithTermsAndFilter,
    PresetTerms,
    StrategyInfo,
    MarketPrincipalStats,
    BorrowCap,
    LoanSaleEvent,
    OrderbookQuote,
    LoopExpanded,
    StrategyLoanStats
} from "../types";
import { bsMetadataApi, useBsMetadataByMints, useTokenListQuery } from "../reducers";
import { BsMetaUtil, isStakedSol } from "./metadata";

type GetDecimals = (mint: string) => number;
type MintsInput = (string | undefined)[] | undefined;

// use a hook to allow a later replacement with an api call to a token list
export function useAbfTypesToUiConverter(mints: MintsInput) {
    const { getMetadata, isLoading } = useBsMetadataByMints(mints);

    const getDecimals = (mint: string | undefined) => getMetadata(mint)?.decimals ?? 0;
    return {
        getMetadata,
        getDecimals,
        tokensLoading: isLoading,
        convertEscrow: (escrow: AbfEscrowedAsset) => convertEscrowToUiAmounts(escrow, getDecimals),
        convertEscrowInfo: (escrow: AbfEscrowInfo) => convertEscrowInfoToUiAmounts(escrow, getDecimals),
        convertEscrowHistory: (escrowHistory: AbfEscrowedEvent) => convertEscrowHistory(escrowHistory, getDecimals),
        convertEscrowAccount: (escrowAccount: AbfEscrowAccount, mint: string) =>
            convertEscrowAccount(escrowAccount, mint, getDecimals),

        convertFeeAccount: (feeAccount: AbfFeeAccount, principalMint: string) =>
            convertFeeAccountToUiAmounts(feeAccount, principalMint, getDecimals),

        // orders
        convertOrder: (order: AbfOrder) => convertOrderToUiAmounts(order, getDecimals),
        convertOrderSchedule: (orderSchedule: AbfOrderSchedule) => convertOrderScheduleToUiAmounts(orderSchedule),
        convertOrderFeeSchedule: (orderSchedule: FeeScheduleInfo) => convertOrderFeeScheduleToUiAmounts(orderSchedule),

        convertSyndicateOrder: (order: SyndicatedOrder) => convertSyndicateOrderToUiAmounts(order, getDecimals),
        convertSyndicateContributors: (contributors: SyndicatedOrderContributor, order: SyndicatedOrder) =>
            convertSyndicateContributorToUiAmounts(contributors, order, getDecimals),

        convertCustodianWithStats: (custodian: CustodianExpanded) => convertCustodianWithStats(custodian),

        // loans
        convertLoan: (loan: AbfLoanVault) => convertLoanToUiAmounts(loan),
        convertLedgerAccount: (ledger: AbfLedgerAccount, principalMint: string) =>
            convertLedgerAccount(ledger, principalMint, getDecimals),
        convertPaymentEvent: (event: PaymentEvent, principalMint: string) =>
            convertPaymentEvent(event, principalMint, getDecimals),

        //requests
        convertLoanRequestInfo: (loanRequestInfo: LoanRequestInfo) =>
            convertLoanRequestInfo(loanRequestInfo, getDecimals),
        convertLoanRequest: (loanRequest: LoanRequest) => convertLoanRequest(loanRequest, getDecimals),
        convertLoanRequestCollateral: (mints: LoanRequestCollateral[]) =>
            convertLoanRequestCollateral(mints, getDecimals),

        convertLockboxWithAssets: (lockbox: LockboxWithAssets) => convertLockboxWithAssets(lockbox, getDecimals),
        convertLockboxEvent: (event: LockboxEvent) => convertLockboxEvent(event, getDecimals),
        convertLockboxAssets: (assets: LockboxAsset[]) => convertLockboxAssets(assets, getDecimals),

        convertStrategyInfo: (strategy: StrategyInfo) => convertStrategyInfo(strategy, getDecimals),
        convertStrategyLoanStats: (stats: StrategyLoanStats, principalMint: string) =>
            convertStrategyLoanStats(stats, principalMint, getDecimals),
        convertMarketPrincipalStats: (mint: string, stats: MarketPrincipalStats) =>
            convertMarketPrincipalStats(mint, stats, getDecimals),
        convertStrategyStats: (mint: string, stats: MarketStrategyStats) =>
            convertStrategyStats(mint, stats, getDecimals),
        convertOfferQuote: <T extends OfferQuote | BestQuote>(quote: T, principalMint: string) =>
            convertOfferQuote(quote, principalMint, getDecimals),
        convertOrderbookQuote: (quote: OrderbookQuote, principalMint: string) =>
            convertOrderbookQuote(quote, principalMint, getDecimals),
        convertLoopVault: (loopVault: LoopVaultInfo) => convertLoopVault(loopVault, getDecimals),
        convertBorrowCap: (cap: BorrowCap) => convertBorrowCap(cap, getDecimals),
        convertUserLoopInfo,
        convertLoanSaleEvents,
        convertPreset,
        convertExternalYieldInfo,
        convertExternalYieldAccrued,
        convertExternalYieldVault
    };
}

export function useDecimalsByMint() {
    const { getMetadata: getPrincipalMetadata, isPrincipalMint } = useBsPrincipalTokens();
    const [fetchMetadata] = bsMetadataApi.endpoints.bsMetadataByMints.useLazyQuery();

    async function setupFetchMultipleDecimals(mints: string[] | undefined) {
        const mintsToQuery = removeDuplicates(mints?.filter((m) => !isPrincipalMint(m)) ?? []);
        let metadata: BsMetadata[] = [];
        if (mintsToQuery.length) {
            metadata = await fetchMetadata(mintsToQuery, true).unwrap();
        }

        return function getDecimals(mint: string) {
            if (isStakedSol(mint)) return SOL_DECIMALS;
            if (isPrincipalMint(mint)) return getPrincipalMetadata(mint)?.decimals ?? 0;
            return metadata.find((m) => m.assetMint === mint)?.decimals ?? 0;
        };
    }

    async function fetchDecimals(mint: string | undefined) {
        if (!mint) return 0;
        if (isStakedSol(mint)) return SOL_DECIMALS;
        if (isPrincipalMint(mint)) return getPrincipalMetadata(mint)?.decimals ?? 0;
        const metadata = await fetchMetadata(mint ? [mint] : [], true).unwrap();
        return metadata.find((m) => m.assetMint === mint)?.decimals ?? 0;
    }

    return { fetchDecimals, setupFetchMultipleDecimals };
}

export function useBsPrincipalTokens() {
    const { data, ...query } = useTokenListQuery();
    const tokens = data?.filter((d) => !!d.isPrincipal);

    // mark errors as loading to prevent infinite loads
    const isLoading = query.isError || query.isLoading;

    const mintToToken = useMemo(() => {
        if (!data || isLoading) return undefined;
        return new Map(data.map((t) => [t.assetMint, t]));
    }, [isLoading, data]);

    const getMetadata = useCallback(
        (mint: string | undefined) => {
            if (!mint || !mintToToken || isLoading) return undefined;
            return mintToToken.get(mint);
        },
        [isLoading, mintToToken]
    );

    const isPrincipalMint = useCallback(
        (mint: string | undefined) => {
            if (!mint || isLoading) return false;
            return !!mintToToken?.get(mint)?.isPrincipal;
        },
        [isLoading, mintToToken]
    );

    const options = useMemo(() => {
        if (!tokens) return [{ value: USDC_MINT, label: USDC_SYMBOL }];
        return tokens.map((t) => ({ value: t.assetMint, label: BsMetaUtil.getSymbol(t) }));
    }, [tokens]);

    return { getMetadata, isPrincipalMint, tokens, options, isLoading };
}

function convertCustodianWithStats(custodian: CustodianExpanded): CustodianExpanded {
    return {
        ...custodian,
        durationToMinApy: custodian.durationToMinApy.map((s) => ({ ...s, apy: bpsToUiDecimals(s.apy) }))
    };
}

function convertLedgerAccount(
    ledgerAccount: AbfLedgerAccount,
    principalMint: string,
    getDecimals: GetDecimals
): AbfLedgerAccount {
    const principalDecimals = getDecimals(principalMint);
    const interestDue = lamportsToUiAmount(ledgerAccount.interestDue, principalDecimals);
    const interestPaid = lamportsToUiAmount(ledgerAccount.interestPaid, principalDecimals);
    const principalDue = lamportsToUiAmount(ledgerAccount.principalDue, principalDecimals);
    const principalPaid = lamportsToUiAmount(ledgerAccount.principalPaid, principalDecimals);

    return {
        ...ledgerAccount,
        interestDue,
        interestPaid,
        principalDue,
        principalPaid,
        latePaymentFee: bpsToUiDecimals(ledgerAccount.latePaymentFee),
        earlyPaymentFee: bpsToUiDecimals(ledgerAccount.earlyPaymentFee)
    };
}

function convertLoanToUiAmounts(loan: AbfLoanVault): AbfLoanVault {
    return loan;
}

function convertEscrowInfoToUiAmounts(escrowInfo: AbfEscrowInfo, getDecimals: GetDecimals): AbfEscrowInfo {
    const convertedEscrow = convertEscrowToUiAmounts(escrowInfo.escrowedAsset, getDecimals);
    const orders = escrowInfo.orders.map((o) => convertOrderToUiAmounts(o, getDecimals));

    return {
        escrowedAsset: convertedEscrow,
        orders
    };
}

function convertEscrowAccount(
    escrowAccount: AbfEscrowAccount,
    mint: string,
    getDecimals: GetDecimals
): AbfEscrowAccount {
    const decimals = getDecimals(mint);

    return {
        ...escrowAccount,
        cumulativeAmountOriginated: lamportsToUiAmount(escrowAccount.cumulativeAmountOriginated, decimals),
        externalYieldSource:
            escrowAccount.externalYieldSource === null ? ExternalYieldSource.None : escrowAccount.externalYieldSource

        // originationCap: lamportsToUiAmount(escrowAccount.originationCap, decimals) // don't convert to prevent overflow
    };
}

function convertEscrowToUiAmounts(escrow: AbfEscrowedAsset, getDecimals: GetDecimals): AbfEscrowedAsset {
    const collateralDecimals = getDecimals(escrow.mint);

    return {
        ...escrow,
        amount: lamportsToUiAmount(escrow.amount, collateralDecimals)
    };
}

function convertFeeAccountToUiAmounts(
    feeAccount: AbfFeeAccount,
    principalMint: string,
    getDecimals: GetDecimals
): AbfFeeAccount {
    const decimals = getDecimals(principalMint);
    return {
        ...feeAccount,
        amountDue: lamportsToUiAmount(feeAccount.amountDue, decimals),
        amountPaid: lamportsToUiAmount(feeAccount.amountPaid, decimals)
    };
}

function convertOrderToUiAmounts(order: AbfOrder, getDecimals: GetDecimals): AbfOrder {
    const principalDecimals = getDecimals(order.principalMint);
    const collateralDecimals = getDecimals(order.collateralMint);

    return {
        ...order,
        maxRefinanceApy: order.maxRefinanceApy ? bpsToUiDecimals(order.maxRefinanceApy) : order.maxRefinanceApy,
        liquidationThreshold: bpsToUiDecimals(order.liquidationThreshold),
        principalAmount: lamportsToUiAmount(order.principalAmount, principalDecimals),
        collateralAmount: lamportsToUiAmount(order.collateralAmount, collateralDecimals)
    };
}

function convertOrderScheduleToUiAmounts(orderSchedule: AbfOrderSchedule): AbfOrderSchedule {
    return {
        ...orderSchedule,
        apy: bpsToUiDecimals(orderSchedule.apy)
    };
}

function convertOrderFeeScheduleToUiAmounts({ earlyFees, lateFees }: FeeScheduleInfo): FeeScheduleInfo {
    const earlyFeesUi = earlyFees.map(({ feeMetadata, feeSchedule }) => ({
        feeMetadata,
        feeSchedule: convertAbfFeeSchedule(feeSchedule)
    }));
    const lateFeesUi = lateFees.map(({ feeMetadata, feeSchedule }) => ({
        feeMetadata,
        feeSchedule: convertAbfFeeSchedule(feeSchedule)
    }));

    return { earlyFees: earlyFeesUi, lateFees: lateFeesUi };
}

function convertAbfFeeSchedule(feeSchedule: AbfFeeSchedule): AbfFeeSchedule {
    return { ...feeSchedule, feeAmount: bpsToUiDecimals(feeSchedule.feeAmount) };
}

function convertLoanRequest(loanRequest: LoanRequest, getDecimals: GetDecimals): LoanRequest {
    const principalDecimals = loanRequest.principalMint ? getDecimals(loanRequest.principalMint) : 0;
    return {
        ...loanRequest,
        principalAmountMin: loanRequest.principalAmountMin
            ? lamportsToUiAmount(loanRequest.principalAmountMin, principalDecimals)
            : loanRequest.principalAmountMin,
        principalAmountMax: loanRequest.principalAmountMax
            ? lamportsToUiAmount(loanRequest.principalAmountMax, principalDecimals)
            : loanRequest.principalAmountMax,
        apyMin: loanRequest.apyMin ? bpsToUiDecimals(loanRequest.apyMin) : loanRequest.apyMin,
        apyMax: loanRequest.apyMax ? bpsToUiDecimals(loanRequest.apyMax) : loanRequest.apyMax
    };
}

function convertLoanRequestInfo(loanRequestInfo: LoanRequestInfo, getDecimals: GetDecimals): LoanRequestInfo {
    const loanRequest = convertLoanRequest(loanRequestInfo.loanRequest, getDecimals);

    return {
        ...loanRequestInfo,
        loanRequest,
        assetInfos: convertLoanRequestCollateral(loanRequestInfo.assetInfos, getDecimals)
    };
}

function convertLockboxWithAssets({ data, assets }: LockboxWithAssets, getDecimals: GetDecimals): LockboxWithAssets {
    return { data, assets: convertLockboxAssets(assets, getDecimals) };
}

function convertLockboxAssets(assets: LockboxAsset[], getDecimals: GetDecimals): LockboxAsset[] {
    return assets.map((a): LockboxAsset => {
        const decimals = isStakedSol(a.assetTypeDiscriminator) ? SOL_DECIMALS : getDecimals(a.assetKey);

        return { ...a, amount: lamportsToUiAmount(a.amount, decimals) };
    });
}

function convertLockboxEvent(event: LockboxEvent, getDecimals: GetDecimals): LockboxEvent {
    return {
        ...event,
        amount: lamportsToUiAmount(
            event.amount,
            isStakedSol(event.assetTypeDiscriminator) ? SOL_DECIMALS : getDecimals(event.assetKey)
        )
    };
}

function convertLoanSaleEvents(event: LoanSaleEvent, decimals: number | undefined): LoanSaleEvent {
    return {
        ...event,
        buyerApy: bpsToUiDecimals(event.buyerApy),
        sellerApy: bpsToUiDecimals(event.sellerApy),
        loanSaleAmount: lamportsToUiAmount(event.loanSaleAmount, decimals),
        interestPaid: lamportsToUiAmount(event.interestPaid, decimals),
        principalPaid: lamportsToUiAmount(event.principalPaid, decimals),
        feePaid: lamportsToUiAmount(event.feePaid, decimals)
    };
}

function convertLoanRequestCollateral(
    collateral: LoanRequestCollateral[],
    getDecimals: GetDecimals
): LoanRequestCollateral[] {
    return collateral.map(({ assetTypeDiscriminator, assetKey, amount }) => ({
        assetKey,
        assetTypeDiscriminator,
        amount: isStakedSol(assetTypeDiscriminator)
            ? lamportsToUiAmount(amount, getDecimals(STAKED_SOL_MINT))
            : lamportsToUiAmount(amount, getDecimals(assetKey))
    }));
}

function convertStrategyInfo(
    { strategyEscrowBalance, ...strategy }: StrategyInfo,
    getDecimals: GetDecimals
): StrategyInfo {
    const decimals = getDecimals(strategy.strategy.principalMint);
    const offerWithTermsAndFilter: OfferWithTermsAndFilter[] = strategy.offerWithTermsAndFilter.map(
        ({ offer, offerTerms, ...rest }) => ({
            ...rest,
            offerTerms: offerTerms.map(
                ({ offerAssetTerms: { terms } }): OfferTermsInfo => ({
                    offerAssetTerms: {
                        terms: { ...terms, apy: bpsToUiDecimals(terms.apy), ltv: bpsToUiDecimals(terms.ltv) }
                    }
                })
            ),

            offer: { ...offer, minPrice: lamportsToUiAmount(offer.minPrice, decimals) }
        })
    );
    return {
        ...strategy,
        offerWithTermsAndFilter,
        strategyEscrowBalance: lamportsToUiAmount(strategyEscrowBalance, decimals)
    };
}

function convertStrategyLoanStats(
    strategyLoanStats: StrategyLoanStats,
    principalMint: string,
    getDecimals: GetDecimals
): StrategyLoanStats {
    const decimals = getDecimals(principalMint);

    return {
        ...strategyLoanStats,
        principalDeployed: lamportsToUiAmount(strategyLoanStats.principalDeployed, decimals),
        totalPrincipalOriginated: lamportsToUiAmount(strategyLoanStats.totalPrincipalOriginated, decimals),
        interestAccrued: lamportsToUiAmount(strategyLoanStats.interestAccrued, decimals),
        totalInterestEarned: lamportsToUiAmount(strategyLoanStats.totalInterestEarned, decimals),
        wAvgApy: bpsToUiDecimals(strategyLoanStats.wAvgApy)
    };
}

function convertBorrowCap({ principalMint, perLoan, global }: BorrowCap, getDecimals: GetDecimals): BorrowCap {
    const decimals = getDecimals(principalMint);
    return {
        principalMint,
        perLoan: lamportsToUiAmount(perLoan, decimals),
        global: lamportsToUiAmount(global, decimals)
    };
}

function convertMarketPrincipalStats(
    mint: string,
    stats: MarketPrincipalStats,
    getDecimals: GetDecimals
): MarketPrincipalStats {
    const decimals = getDecimals(mint);
    return {
        principalOriginated: lamportsToUiAmount(stats.principalOriginated, decimals),
        principalUtilized: lamportsToUiAmount(stats.principalUtilized, decimals),
        salesVolume: lamportsToUiAmount(stats.salesVolume ?? 0, decimals)
    };
}

function convertStrategyStats(mint: string, stats: MarketStrategyStats, getDecimals: GetDecimals): MarketStrategyStats {
    const decimals = getDecimals(mint);
    return {
        totalDeposits: lamportsToUiAmount(stats.totalDeposits, decimals),
        durationToMinApy: stats.durationToMinApy.map((s) => ({ ...s, apy: bpsToUiDecimals(s.apy) }))
    };
}

function convertSyndicateOrderToUiAmounts(order: SyndicatedOrder, getDecimals: GetDecimals): SyndicatedOrder {
    const principalDecimals = getDecimals(order.principalMint);
    const collateralDecimals = getDecimals(order.collateralMint);

    return {
        ...order,
        principalDeposited: lamportsToUiAmount(order.principalDeposited, principalDecimals),
        principalRedeemed: lamportsToUiAmount(order.principalRedeemed, principalDecimals),
        principalAmount: lamportsToUiAmount(order.principalAmount, principalDecimals),
        collateralAmount: lamportsToUiAmount(order.collateralAmount, collateralDecimals)
    };
}

function convertSyndicateContributorToUiAmounts(
    contributor: SyndicatedOrderContributor,
    order: SyndicatedOrder,
    getDecimals: GetDecimals
): SyndicatedOrderContributor {
    const decimals = getDecimals(order.principalMint);
    return {
        ...contributor,
        lastClaimedPrincipalAmount: lamportsToUiAmount(contributor.lastClaimedPrincipalAmount, decimals),
        principalDeposited: lamportsToUiAmount(contributor.principalDeposited, decimals),
        principalRedeemed: lamportsToUiAmount(contributor.principalRedeemed, decimals)
    };
}

function convertEscrowHistory(history: AbfEscrowedEvent, getDecimals: GetDecimals): AbfEscrowedEvent {
    const decimals = getDecimals(history.mint);
    return {
        ...history,
        amount: lamportsToUiAmount(history.amount, decimals)
    };
}

function convertPaymentEvent(event: PaymentEvent, principalMint: string, getDecimals: GetDecimals): PaymentEvent {
    const decimals = getDecimals(principalMint);

    if ("Fee" in event) {
        return { Fee: { ...event.Fee, amount: lamportsToUiAmount(event.Fee.amount, decimals) } };
    }
    return {
        Ledger: {
            ...event.Ledger,
            principalPaid: lamportsToUiAmount(event.Ledger.principalPaid, decimals),
            interestPaid: lamportsToUiAmount(event.Ledger.interestPaid, decimals)
        }
    };
}

function convertOfferQuote<T extends OfferQuote | BestQuote>(
    quote: T,
    principalMint: string,
    getDecimals: GetDecimals
): T {
    const decimals = getDecimals(principalMint);
    return {
        ...quote,
        apy: bpsToUiDecimals(quote.apy),
        ltv: bpsToUiDecimals(quote.ltv),
        liquidationThreshold: bpsToUiDecimals(quote.liquidationThreshold),
        principalAvailable: lamportsToUiAmount(quote.principalAvailable, decimals),
        minPrice: quote.minPrice ? lamportsToUiAmount(quote.minPrice, decimals) : quote.minPrice
    };
}

function convertOrderbookQuote(quote: OrderbookQuote, principalMint: string, getDecimals: GetDecimals): OrderbookQuote {
    const decimals = getDecimals(principalMint);
    return {
        ...quote,
        apy: bpsToUiDecimals(quote.apy),
        maxPrincipalAvailable: lamportsToUiAmount(quote.maxPrincipalAvailable, decimals),
        sumPrincipalAvailable: lamportsToUiAmount(quote.sumPrincipalAvailable, decimals)
    };
}

function convertPreset({ offerTerms, strategyTerms }: PresetTerms): PresetTerms {
    return {
        offerTerms: {
            ...offerTerms,
            ltv: bpsToUiDecimals(offerTerms.ltv),
            liquidationThreshold: bpsToUiDecimals(offerTerms.liquidationThreshold)
        },
        strategyTerms
    };
}

function convertExternalYieldInfo(
    externalYieldInfo: ExternalYieldInfo,
    decimals: number | undefined
): ExternalYieldInfo {
    return {
        ...externalYieldInfo,
        apy: bpsToUiDecimals(externalYieldInfo.apy),
        balance: lamportsToUiAmount(externalYieldInfo.balance, decimals)
    };
}

function convertExternalYieldAccrued(
    externalYieldAccrued: ExternalYieldAccrued,
    decimals: number | undefined
): ExternalYieldAccrued {
    return {
        ...externalYieldAccrued,
        interestAccrued: lamportsToUiAmount(externalYieldAccrued.interestAccrued, decimals)
    };
}

function convertExternalYieldVault(externalYieldVault: ExternalYieldVault): ExternalYieldVault {
    return {
        ...externalYieldVault,
        apy: bpsToUiDecimals(externalYieldVault.apy)
    };
}

function convertLoopVault(loopVault: LoopVaultInfo, getDecimals: GetDecimals): LoopVaultInfo {
    return {
        ...loopVault,
        collateralApyPct: percentUiToDecimals(loopVault.collateralApyPct),
        maxLeveragedApyPct: percentUiToDecimals(loopVault.maxLeveragedApyPct),
        collateralDeposited: lamportsToUiAmount(loopVault.collateralDeposited, getDecimals(loopVault.collateralMint)),
        principalAmountAvailable: lamportsToUiAmount(
            loopVault.principalAmountAvailable,
            getDecimals(loopVault.principalMint)
        )
    };
}

function convertUserLoopInfo(userLoopInfo: UserLoopInfo, loopExpanded: LoopExpanded): UserLoopInfo {
    const collateralDecimals = loopExpanded.collateralToken.decimals;
    return {
        ...userLoopInfo,
        initialCollateralAmount: lamportsToUiAmount(userLoopInfo.initialCollateralAmount, collateralDecimals),
        totalCollateralDepositedAmount: lamportsToUiAmount(
            userLoopInfo.totalCollateralDepositedAmount,
            collateralDecimals
        ),
        additionalCollateralDepositedAmount: lamportsToUiAmount(
            userLoopInfo.additionalCollateralDepositedAmount,
            collateralDecimals
        ),
        netApy: userLoopInfo.netApy ? percentUiToDecimals(userLoopInfo.netApy) : userLoopInfo.netApy
    };
}
