import { memo } from "react";

import { useMarketContext } from "../common";
import { useMarketLendContext } from "./MarketLendContext";
import { TokenInputWithPrice, useBalanceChecker } from "../../common";
import { useSelectMarketPrincipal } from "../util";

export default memo(function LendAmount() {
    const { token, principalMint } = useMarketContext();
    const selectPrincipal = useSelectMarketPrincipal();
    const {
        form: { amount },
        setForm
    } = useMarketLendContext();
    const { BalanceDisplay } = useBalanceChecker({
        amount,
        mint: principalMint,
        setMax: (amount) => setForm((prev) => ({ ...prev, amount }))
    });

    return (
        <TokenInputWithPrice
            label="You supply"
            labelColor="body"
            autoFocus
            selectToken={selectPrincipal}
            metadata={token}
            belowInput={<BalanceDisplay />}
            value={amount}
            setValue={(amount) => setForm((prev) => ({ ...prev, amount }))}
        />
    );
});
