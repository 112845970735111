import { useMemo } from "react";

import {
    KycStatus,
    ProviderBankAccountStatus,
    SphereBankAccountStatus,
    SpherePayoutExpanded,
    SpherePayoutFilter,
    SphereTOSStatus,
    SphereUserBankAccount,
    SphereUserType
} from "@bridgesplit/abf-sdk";
import { NullableRecord, filterNullableRecord } from "@bridgesplit/utils";

import {
    useBusinessKybStatusQuery,
    useSphereUserBankAccountQuery,
    useSphereUserInfoQuery,
    useSphereUserPayoutsQuery,
    useSphereUserWalletsQuery
} from "../reducers";
import { useUserIdentity } from "./identity";
import { useUserIsPrime } from "./auth";
import { useActiveGroup } from "./group";

function useSkipSphere() {
    const { isPrime } = useUserIsPrime();
    const { groupIdentifier } = useActiveGroup();

    const skip = !isPrime || !groupIdentifier;
    return skip;
}

export function useUserSphere() {
    const identity = useUserIdentity();

    const skipSphere = useSkipSphere();

    const { data, isLoading: sphereLoading } = useSphereUserInfoQuery(undefined, {
        skip: identity?.status !== KycStatus.Verified || skipSphere
    });
    const { data: kyb, isLoading: kycLoading } = useBusinessKybStatusQuery(undefined, {
        skip: data?.userType !== SphereUserType.Business || !data.sphereCustomerId || skipSphere
    });

    const skipQueries = !data?.sphereCustomerId || sphereLoading || skipSphere;
    const { data: wallets } = useSphereUserWalletsQuery(undefined, { skip: skipQueries });

    const { data: bankAccounts } = useSphereUserBankAccountQuery(undefined, { skip: skipQueries });

    const kycStatus = data?.kycStatus ?? KycStatus.NotSubmitted;
    const isVerified = kycStatus === KycStatus.Verified;
    const termsOfServiceAccepted = data?.tosStatus === SphereTOSStatus.Accepted;

    const accountCreated = !!data?.sphereCustomerId;

    return {
        isVerified,
        wallets,
        kycStatus,
        accountCreated,
        isLoading: sphereLoading || kycLoading,
        identity,
        termsOfServiceAccepted,
        bankAccounts,
        data,
        kyb
    };
}

export function useUserBankAccounts(options?: { skip?: boolean; activeOnly?: boolean }) {
    const sphere = useUserSphere();
    const skipSphere = useSkipSphere();
    const banks = useSphereUserBankAccountQuery(undefined, { skip: options?.skip || skipSphere });

    const isLoading = banks.isLoading || sphere.isLoading;

    if (options?.skip) return { data: [], isLoading: false };
    if (isLoading) return { data: undefined, isLoading };
    if (!options?.activeOnly) return { data: banks.data, isLoading };
    if (!sphere.isVerified) return { data: [], isLoading };
    const data = banks.data?.filter((b) => isBankAccountReadyForTransfers(b));

    return { data, isLoading };
}

export function useUserBankTransfers(filter?: SpherePayoutFilter): SpherePayoutExpanded[] | undefined {
    const { data: bankAccounts } = useUserBankAccounts();
    const { data } = useSphereUserPayoutsQuery(filter ?? {}, { skip: !bankAccounts?.length, pollingInterval: 5_000 });
    const bankAccountMap = useMemo(() => {
        if (!bankAccounts) return undefined;
        return new Map(bankAccounts.map((b) => [b.sphereBankAccountId, b]));
    }, [bankAccounts]);

    if (bankAccounts?.length === 0) return [];

    return data
        ?.map(
            ({ fundingWallet, payout }): NullableRecord<SpherePayoutExpanded> => ({
                ...payout,
                fundingWallet: fundingWallet?.fundingWallet ?? null,
                bankAccount: bankAccountMap?.get(payout.bankAccountId)
            })
        )
        .filter(filterNullableRecord);
}

export function isBankAccountReadyForTransfers(bankAccount: SphereUserBankAccount) {
    const isSphereVerified = bankAccount.sphereBankAccountStatus === SphereBankAccountStatus.Accepted;
    if (!isSphereVerified) return false;
    if (bankAccount.tellerBankAccountId) {
        return bankAccount.providerBankAccountStatus === ProviderBankAccountStatus.Connected;
    }
    return true;
}
