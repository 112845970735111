import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { DispatchType } from "@bridgesplit/react";
import {
    PresetStrategyTerms,
    PresetTerms,
    StrategyTemplateExpanded,
    usePresets,
    useStrategyTemplates
} from "@bridgesplit/abf-react";
import { removeDuplicatesByProperty } from "@bridgesplit/utils";

import { initialLendForm, MarketLendForm } from "./type";
import { useMarketCollateral } from "../util";
import { useMarketContext } from "../common";

type ContextState = {
    form: MarketLendForm;
    setForm: DispatchType<MarketLendForm>;
    offerPresets: PresetTerms[] | undefined;
    presets: PresetStrategyTerms[] | undefined;
    templates: StrategyTemplateExpanded[] | undefined;
    isTemplateBased: boolean;
};
const MarketLendContext = createContext<ContextState | null>(null);

export function useMarketLendContext() {
    const context = useContext(MarketLendContext);
    if (!context) {
        throw new Error("useMarketLendContext must be used within a MarketLendProvider");
    }
    return context;
}

export function MarketLendProvider({ children }: { children: ReactNode }) {
    const { principalMint, isDialog } = useMarketContext();
    const [form, setForm] = useState<MarketLendForm>(initialLendForm);
    const supportedCollateral = useMarketCollateral();

    const { allPresets } = usePresets();
    const { data: templates } = useStrategyTemplates({
        tokens: supportedCollateral,
        presets: allPresets,
        principalMint
    });

    // only include unique presets available for the selected collateral
    const relevantPresets = useMemo(
        () =>
            form.collateral.length
                ? allPresets?.filter((p) => form.collateral.includes(p.offerTerms.collateralMint))
                : allPresets,
        [allPresets, form.collateral]
    );
    const presetTerms = useMemo(() => {
        if (!relevantPresets) return undefined;
        return removeDuplicatesByProperty(
            relevantPresets?.map((p) => p.strategyTerms),
            "presetStrategyIdentifier"
        );
    }, [relevantPresets]);

    return (
        <MarketLendContext.Provider
            value={{
                form,
                setForm,
                presets: presetTerms,
                offerPresets: relevantPresets,
                templates,
                isTemplateBased: isDialog
            }}
        >
            {children}
        </MarketLendContext.Provider>
    );
}
