export interface NftMetadata {
    update_authority: string;
    mint: string;
    image: string;
    data: NftData;
    primary_sale_happened: boolean;
    is_mutable: boolean;
    token_standard: TokenStandard;
    extra_metadata: ExtraMetadata | null;
    programmable_config?: string;
    pools?: Array<string>;
}

export interface NftData {
    name: string;
    symbol: string;
    uri: string;
    seller_fee_basis_points: number;
    creators?: Array<Creator>;
}

export interface Creator {
    address: string;
    verified: boolean;
    share: number;
}

export enum TokenStandard {
    NonFungible = "NonFungible",
    FungibleAsset = "FungibleAsset",
    Fungible = "Fungible",
    NonFungibleEdition = "NonFungibleEdition",
    ProgrammableNonFungible = "ProgrammableNonFungible"
}

export interface ExtraMetadata {
    name: string;
    symbol?: string;
    description: string;
    image: string;
    attributes?: Attribute[];
    collection?: Collection;
    animation_url?: string;
    external_url?: string;
    update_authority?: string;
}

export interface Attribute {
    trait_type: string;
    value: string;
}

export interface Collection {
    name: string;
    family: string;
}
