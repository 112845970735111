//error messages
export const FETCH_TRANSACTION_ERROR = "Unable to fetch transaction";
export const VERSIONED_TRANSACTION_GENERATION_ERROR = "Unable to generate versioned transaction from response";
export const LEGACY_TRANSACTION_GENERATION_ERROR = "Unable to generate transaction from response";
export const USER_LOGIN_ERROR = "Unable to login user";
export const MISSING_PARAM_ERROR = "Missing values";
export const MISSING_WALLET_ERROR = "Missing wallet";
export const AUTH_ERROR = "Not authorized";
export const EMPTY_TRANSACTIONS_ERROR = "No transactions passed in";
export const UNKNOWN_ERROR = "Something went wrong...";
export const CREDENTIAL_ERROR = "Unable to generate valid credentials";
export const PASSKEY_ERROR = "Unable to authenticate with your Passkey";

export const WALLET_ERROR = "Wallet not connected";
export const UNABLE_TO_SEND_RAW_TRANSACTION = "Unable to process your request";
export const LOADING_ERROR = "Unknown error. Please refresh the page and try again";
export const TX_FAILED_TO_CONFIRM = "Transaction failed to confirm";

//error types
export enum ErrorType {
    ResultError = "Result Error",
    ApiFetchError = "API Fetch Error",
    UnknownError = "Unknown Error",
    MissingParamsError = "Missing Params Error",
    DeserializationError = "Deserialization Error",
    ApiLoginError = "API Login Error",
    Web3UserError = "Web3 User Error",
    TransactionSendError = "Transaction send Error",
    AuthError = "Bearer Authorization Error"
}
