import { Button, DialogWrapper, Icon, IconHeader, IconWithBackground, Row, TextNestedLink } from "@bridgesplit/ui";
import { useUserIdentity, useVerifyUserIdentity } from "@bridgesplit/abf-react";
import { KycStatus } from "@bridgesplit/abf-sdk";
import { AppDialog, useAppDialog } from "app/utils";
import { STRIPE_IDENTITY_WEBSITE_URL, SUPPORT_EMAIL } from "app/constants";

import { DialogHeader } from "../../common";

export default function KYCVerifyDialog() {
    const { status } = useUserIdentity();
    const { getData } = useAppDialog();
    const ctaText = getData<AppDialog.KYC>()?.ctaText ?? "start loans";

    return (
        <DialogWrapper>
            <KYCVerify ctaText={ctaText} status={status} />
        </DialogWrapper>
    );
}

export function KYCVerify({ status, ctaText }: { status: KycStatus; ctaText: string }) {
    switch (status) {
        case KycStatus.NotSubmitted:
            return <Unverified ctaText={ctaText} />;
        case KycStatus.Verified:
            return <Verified />;
        case KycStatus.VerificationFailed:
            return <Failed ctaText={ctaText} />;
        case KycStatus.Pending:
            return <Pending ctaText={ctaText} />;
        case KycStatus.SubmissionStarted:
            return <SubmissionStarted ctaText={ctaText} />;
        default:
            return null;
    }
}

function Unverified({ ctaText }: { ctaText: string }) {
    const { startKyc, isLoading } = useVerifyUserIdentity();

    return (
        <>
            <IconHeader>
                <Icon type="kyc" />
            </IconHeader>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Verify identity"
                description={
                    <>
                        You will need to verify your identity before you can {ctaText}. Loopscale uses{" "}
                        <TextNestedLink onClick={() => window.open(STRIPE_IDENTITY_WEBSITE_URL)}>Stripe</TextNestedLink>{" "}
                        for identity verification
                    </>
                }
            />

            <Button loading={isLoading} onClick={startKyc} variant="contained">
                Start verification
            </Button>
        </>
    );
}

function SubmissionStarted({ ctaText }: { ctaText: string }) {
    const { startKyc, isLoading } = useVerifyUserIdentity();

    return (
        <>
            <IconHeader>
                <Icon type="kyc" />
            </IconHeader>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Verify identity"
                description={`Your identity must be verified to ${ctaText}. Resume your previous
                verification session with Stripe`}
            />

            <Button loading={isLoading} onClick={startKyc} variant="contained">
                Resume verification session
            </Button>
        </>
    );
}

function Pending({ ctaText }: { ctaText: string }) {
    const { close } = useAppDialog();

    return (
        <>
            <Row sx={{ width: "100%", justifyContent: "center" }}>
                <IconWithBackground variant="h1" tagColor="info" size={50}>
                    <Icon type="pending" />
                </IconWithBackground>
            </Row>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Identity verification pending"
                description={`Your identity must be verified to ${ctaText}. If verification takes longer than a few minutes, please contact ${SUPPORT_EMAIL}`}
            />

            <Button fullWidth onClick={close}>
                Close
            </Button>
        </>
    );
}

function Failed({ ctaText }: { ctaText: string }) {
    const { close } = useAppDialog();

    return (
        <>
            <Row sx={{ width: "100%", justifyContent: "center" }}>
                <IconWithBackground variant="h1" tagColor="error" size={50}>
                    <Icon type="reject" />
                </IconWithBackground>
            </Row>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Identity verification failed"
                description={`Your identity must be verified to ${ctaText}. Please contact ${SUPPORT_EMAIL} for support`}
            />

            <Button fullWidth onClick={close}>
                Close
            </Button>
        </>
    );
}

function Verified() {
    const { close } = useAppDialog();

    return (
        <>
            <Row sx={{ width: "100%", justifyContent: "center" }}>
                <IconWithBackground variant="h1" tagColor="success" size={50}>
                    <Icon type="kyc" />
                </IconWithBackground>
            </Row>

            <DialogHeader
                spacing={0.5}
                centerAlign
                header="Identity verified"
                description={`Your identity is already verified`}
            />

            <Button fullWidth onClick={close}>
                Close
            </Button>
        </>
    );
}
