import {
    AbfOrder,
    AbfOrderSchedule,
    LoanRequest,
    LoanRequestCollateral,
    BsMetadata,
    FeeScheduleInfo,
    AbfOrderSide,
    DurationUnit,
    NewFeeSchedule,
    CompoundingFrequency
} from "@bridgesplit/abf-sdk";

import { AbfUserGroup } from "./group";
import { AbfSyndicateExpanded } from "./syndicate";

export type ExpandedOrderType = "standard" | "syndicated";

/** Orders */
export interface AbfOrderExpandedBase {
    type: ExpandedOrderType;
    requestMaker: AbfUserGroup;
    custodianIdentifiers: string[];
    order: AbfOrder;
    orderSchedule: AbfOrderSchedule;
    isPrincipalFunded: boolean;
    loanRequest: LoanRequest;
    collateral: LoanRequestCollateral[];
    principalMetadata: BsMetadata;
    orderMaker: AbfUserGroup;
    feeSchedule: FeeScheduleInfo;
}

interface AbfOrderExpandedStandard extends AbfOrderExpandedBase {
    type: "standard";
}
interface AbfOrderExpandedSyndicated extends AbfOrderExpandedBase {
    type: "syndicated";
    syndicateExpanded: AbfSyndicateExpanded;
}

export type AbfOrderExpanded = AbfOrderExpandedStandard | AbfOrderExpandedSyndicated;

export interface OrderFilter {
    makers?: string[];
    designated_takers?: string[];
    collateral?: string;
    custodian?: string;
}

export interface PaymentEvent {
    timeOffset: number;
    amount: number; // interest + principal for this ledger
    totalAmount: number;
    principalDue: number;
    interestDue: number;
}

export interface AbfUiScheduleDetails {
    schedule: PaymentEvent[];
    totalRepayment: number;
    totalInterest: number;
}

export enum AbfUiLoanType {
    PeriodicRepayments = "Simple interest",
    ZeroCoupon = "Zero-coupon"
}

export enum AbfUiOfferFinancingType {
    Standard = "Self-Funded",
    Syndicated = "Syndicated Offer"
}

export enum AbfUiFeeType {
    EarlyFee = "Early payment Fee",
    LateFee = "Late payment Fee"
}

export enum AbfUiFeeRule {
    InterestOnly = "Only Interest Repayments",
    InterestAndPrincipal = "Mixed Repayments",
    PrincipalOnly = "Only Principal Repayments"
}

export interface OrderParams {
    designatedTaker: string; // intended filler of the order, default pubkey for any filler
    designatedTakerEscrowNonce: string;
    makerEscrow: string;
    principalMint: string; // principal for order, default to USDC
    principalAmount: number; // initial amount for order
    collateralMint: string; // collateral for order
    collateralAmount: number; // amount collateral to borrow against
    lend: AbfOrderSide;
    frequencyRrule: string; //frequency rrule for order repayments
    apy: number; //annualized order return in bps
    duration: number; // duration in terms of duration type
    durationType: DurationUnit; //duration type
    maxOutstandingPayments: number; //max outstanding repayments
    syndicated?: boolean; // is order syndicated
    nonce?: string; // nonce for syndicated order;
    initialAmount?: number; // initial amount for syndicated order
    feeSchedules: NewFeeSchedule[];
    allowEarlyRepayments: boolean;
    compoundingFrequency: CompoundingFrequency;
    refinanceEnabled: boolean;
}
