import { useState } from "react";

import { Column, PollingWithProgress, Row, Text, TooltipText } from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import { EvmLiteNft, EvmTransactionType, useEstimateGas, useUserChainWallet } from "@bridgesplit/abf-react";
import { Divider } from "@mui/material";
import { useAppNavigate } from "@bridgesplit/react";
import { AppTab, COPY, PORTFOLIO_ACTIVITY_SLUG } from "app/constants";

import { QrCodeCopyAddress, getChainMeta, getTabPath } from "../../common";
import { useUserEvmNfts } from "../util";
import { AcceptedCustodians, SelectLiteAssets, describeAssetTransfer } from "../common";
import { EvmBalance, useSetupMetaAndBridge } from "../../wormhole";
import { WalletValidatorWrapper } from "../../transactions";

export default function BridgeFromEvm({ chain }: { chain: ChainId }) {
    return (
        <WalletValidatorWrapper>
            <EvmAddress chain={chain} />
            <ReceivedAssets chain={chain} />
        </WalletValidatorWrapper>
    );
}

function EvmAddress({ chain }: { chain: ChainId }) {
    const { wallet } = useUserChainWallet(chain);
    const meta = getChainMeta(chain);

    return (
        <Column spacing={1.5}>
            <Text color="caption">1. Send NFTs over {meta.name}</Text>
            <Divider />

            <QrCodeCopyAddress address={wallet} chainId={chain} />
            <AcceptedCustodians chain={chain} />
        </Column>
    );
}

function ReceivedAssets({ chain }: { chain: ChainId }) {
    const { data: nfts, isFetching, reset } = useUserEvmNfts(chain);

    const [selectedState, setSelected] = useState<Set<string>>();
    const selected = selectedState ?? new Set(nfts?.map((a) => a.solanaMint));

    const selectedAssets = nfts?.filter((n) => selected.has(n.solanaMint));

    return (
        <>
            <Row spaceBetween>
                <TooltipText
                    helpText="Any assets that you send to the address above will appear here"
                    sx={{ gap: 0.5 }}
                    color="caption"
                >
                    2. Confirm Deposit
                </TooltipText>
                <PollingWithProgress helpText="Refresh your received NFTs" isFetching={isFetching} callback={reset} />
            </Row>
            <Divider />
            <SelectLiteAssets
                emptyText="No eligible assets received"
                selected={selected}
                setSelected={setSelected}
                assets={nfts?.map((nft) => ({
                    name: nft.name,
                    image: nft.image,
                    mint: nft.solanaMint,
                    isFungible: false
                }))}
            />

            <Cta chain={chain} selectedAssets={selectedAssets} />
        </>
    );
}

function Cta({ selectedAssets, chain }: { selectedAssets: EvmLiteNft[] | undefined; chain: ChainId }) {
    const bridge = useSetupMetaAndBridge();
    const gasTransactions = Array<EvmTransactionType[]>(selectedAssets?.length || 0)
        .fill([EvmTransactionType.ApproveNft, EvmTransactionType.Bridge])
        .flat();
    const estimatedGas = useEstimateGas(chain)(gasTransactions);

    const assetDescription = describeAssetTransfer(selectedAssets);

    const navigate = useAppNavigate();

    return (
        <EvmBalance.Cta
            isTransaction={false}
            disabled={!selectedAssets?.length}
            asyncCta={{
                closeDialog: "always",
                onClickWithResult: () => bridge(chain, selectedAssets ?? []),
                options: {
                    loadingMessage: {
                        message: `Depositing ${assetDescription}`,
                        description: COPY.ASSET_BRIDGE_TRANSFER_LENGTH
                    },
                    onSuccessMessage: {
                        message: `Deposit of ${assetDescription} initiated`,
                        description: "Track your deposit in the activity tab"
                    },
                    onSuccess: () => navigate(getTabPath(PORTFOLIO_ACTIVITY_SLUG, AppTab.Activity)),
                    alertOnError: true
                }
            }}
            requestedBalance={estimatedGas?.total}
        >
            Deposit
        </EvmBalance.Cta>
    );
}
