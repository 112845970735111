import { convertAnyDate, convertDateToSeconds, getUnixTs, removeDuplicates, TIME } from "@bridgesplit/utils";
import { RRule, RRuleSet } from "rrule";
import {
    CompoundingFrequency,
    DEFAULT_DURATION,
    getDurationInSeconds,
    getRRuleFrequencyDurationDetails
} from "@bridgesplit/abf-sdk";

import { AbfUiScheduleDetails, PaymentEvent, PresetStrategyTerms } from "../types";
import { getRruleFromString } from "./order";

export const MAX_LEDGER_PAYMENTS = 127;

export function getTimeOffsetsFromRRuleSet(ruleSet: RRuleSet) {
    const startDate = ruleSet.rrules()[0]?.options?.dtstart;

    // if no prev time offset, then offset with current time
    const startTime = startDate ? convertDateToSeconds(startDate) : getUnixTs();
    return getTimeOffsetsFromRRuleSetAndStartTime(ruleSet, startTime);
}

export function getTimeOffsetsFromRRuleSetAndStartTime(ruleSet: RRuleSet, startTime: number) {
    const timeOffsets = ruleSet.all().map((t) => Math.max(0, convertDateToSeconds(t) - startTime));

    // remove duplicates to prevent multiple end date
    const uniqueTimeOffsets = removeDuplicates(timeOffsets);

    return uniqueTimeOffsets;
}

export function getPresetOffsets(preset: PresetStrategyTerms) {
    const ruleSet = getRruleFromString(preset.repaymentRrule);
    const durationInSeconds = getDurationInSeconds(preset.duration, preset.durationType);

    if (!ruleSet) {
        return [durationInSeconds];
    }

    const now = getUnixTs();
    const original = ruleSet.rrules()[0]?.options;

    if (!original) {
        return [durationInSeconds];
    }

    const newRule = new RRuleSet();
    newRule.rrule(
        new RRule({
            interval: original.interval,
            freq: original.freq,
            count: (original.count ?? 1) + 1 // count + 1 to prevent today from being included in schedule
        })
    );
    newRule.exdate(convertAnyDate(now));

    const offsets = getTimeOffsetsFromRRuleSetAndStartTime(newRule, now);

    // filter out 0 offset (from adding count)
    return offsets.filter((o) => !!o);
}

export function getOffsetDetailsFromPreset(preset: PresetStrategyTerms) {
    const timeOffsets = getPresetOffsets(preset);
    const lastOffset = timeOffsets[timeOffsets.length - 1];
    const loanEndDate = getUnixTs() + lastOffset;

    return { timeOffsets, lastOffset, loanEndDate };
}

export function getFrequencyFromRruleSet(ruleSet: RRuleSet) {
    const rules = ruleSet._rrule?.[0];

    const rruleFrequency = rules?.options?.freq;
    const freqDetails = getRRuleFrequencyDurationDetails(rruleFrequency ?? DEFAULT_DURATION);

    return freqDetails.type;
}

type ScheduleParams = {
    timeOffsets: number[];
    apy: number; // ui amount ex 1 -> 1%
    principal: number;
    loanDurationSeconds?: number;
    yearConstant?: number;
    compoundingFrequency?: CompoundingFrequency;
};
export function getPaymentScheduleFromDates(params: ScheduleParams): AbfUiScheduleDetails {
    if (params.compoundingFrequency === CompoundingFrequency.Continuous) {
        const { schedule, totalAmount } = getContinuousCompoundingSchedule(params);

        return {
            schedule,
            totalRepayment: totalAmount,
            totalInterest: Math.max(0, totalAmount - params.principal)
        };
    }

    const { schedule, totalAmount } = getSimpleCompoundingSchedule(params);
    return {
        schedule,
        totalRepayment: totalAmount,
        totalInterest: Math.max(0, totalAmount - params.principal)
    };
}

function getSimpleCompoundingSchedule({
    apy,
    loanDurationSeconds,
    timeOffsets,
    yearConstant = TIME.YEAR,
    principal
}: ScheduleParams) {
    const schedule: PaymentEvent[] = [];
    let totalAmount = 0;
    const rate = apy / (yearConstant * 100);

    const loanTerm = (() => {
        if (loanDurationSeconds) return loanDurationSeconds;
        if (timeOffsets.length === 0) return 0;
        return timeOffsets[timeOffsets.length - 1];
    })();

    const totalInterest = rate * loanTerm * principal;

    for (let i = 0; i < timeOffsets.length; i++) {
        const currTimeOffset = timeOffsets[i];
        const prevTimeOffset = i !== 0 ? timeOffsets[i - 1] : 0;

        // ( d`n- d`n-1)
        const timeDifference = currTimeOffset - prevTimeOffset;

        // ( timeDifference ) * r`s * P
        const interestDue = (totalInterest * timeDifference) / loanTerm;
        const principalDue = i === timeOffsets.length - 1 ? principal : 0;
        const amount = interestDue + principalDue;

        totalAmount += amount;
        schedule.push({ amount, timeOffset: currTimeOffset, totalAmount, principalDue, interestDue });
    }
    return { schedule, totalAmount };
}

function getCompoundingInterestConstant({
    apy,
    loanDurationSeconds,
    timeOffsets,
    yearConstant = TIME.YEAR,
    principal
}: {
    apy: number;
    loanDurationSeconds: number | undefined;
    timeOffsets: number[];
    yearConstant: number | undefined;
    principal: number;
}): number {
    let totalLedgerBaseInterest = 0;

    const totalInterestDue = getContinuousCompoundedInterest({
        principal,
        apy: apy / 100,
        loanDuration: loanDurationSeconds ?? timeOffsets[timeOffsets.length - 1],
        yearConstant,
        subtractPrincipal: true
    });

    for (let i = 0; i < timeOffsets.length; i++) {
        const currTimeOffset = timeOffsets[i];
        const prevTimeOffset = i !== 0 ? timeOffsets[i - 1] : 0;
        const timeDifference = currTimeOffset - prevTimeOffset;

        const ledgerBaseinterestDue = getContinuousCompoundedInterest({
            principal: 1,
            apy: apy / 100,
            loanDuration: timeDifference,
            yearConstant,
            subtractPrincipal: true
        });

        totalLedgerBaseInterest += ledgerBaseinterestDue;
    }

    return totalInterestDue / totalLedgerBaseInterest;
}

function getContinuousCompoundingSchedule({
    apy,
    loanDurationSeconds,
    timeOffsets,
    yearConstant = TIME.YEAR,
    principal
}: ScheduleParams) {
    const schedule: PaymentEvent[] = [];

    let totalAmount = 0;

    const compoundingInterestConstant = getCompoundingInterestConstant({
        apy,
        loanDurationSeconds,
        timeOffsets,
        yearConstant,
        principal
    });

    for (let i = 0; i < timeOffsets.length; i++) {
        const currTimeOffset = timeOffsets[i];
        const prevTimeOffset = i !== 0 ? timeOffsets[i - 1] : 0;
        const timeDifference = currTimeOffset - prevTimeOffset;

        const ledgerBaseInterestDue = getContinuousCompoundedInterest({
            principal: 1,
            apy: apy / 100,
            loanDuration: timeDifference,
            yearConstant,
            subtractPrincipal: true
        });

        const ledgerInterestDue = compoundingInterestConstant * ledgerBaseInterestDue;

        const principalDue = i === timeOffsets.length - 1 ? principal : 0;
        const amount = ledgerInterestDue + principalDue;

        totalAmount += amount;

        schedule.push({
            amount,
            timeOffset: currTimeOffset,
            totalAmount,
            principalDue,
            interestDue: ledgerInterestDue
        });
    }

    return { schedule, totalAmount };
}

export function getContinuousCompoundedInterest({
    principal,
    apy,
    loanDuration,
    yearConstant = TIME.YEAR,
    subtractPrincipal = true
}: {
    principal: number;
    apy: number;
    loanDuration: number;
    yearConstant?: number;
    subtractPrincipal: boolean;
}) {
    const loanDurationInYears = loanDuration / yearConstant;

    const totalAmountDue = principal * Math.exp(apy * loanDurationInYears);
    if (subtractPrincipal) {
        return totalAmountDue - principal;
    } else {
        return totalAmountDue;
    }
}

export function getSimpleInterest(principal: number, apy: number, loanDuration: number, yearConstant: number) {
    const loanDurationInYears = loanDuration / yearConstant;
    const numberOfCompoundsPerYear = 1 / loanDurationInYears;
    return getDiscreteCompoundedInterest(principal, apy, loanDurationInYears, numberOfCompoundsPerYear);
}

export function getDiscreteCompoundedInterest(
    principal: number,
    apy: number,
    numberOfPeriods: number,
    compoundsPerPeriod: number
) {
    const totalAmountDue = 1 + (apy / compoundsPerPeriod) ** (compoundsPerPeriod * numberOfPeriods);
    return totalAmountDue - principal;
}
