export type ApiTypeToString = {
    abfApi: string;
    syndicatedApi: string;
    externalPricesApi: string;
    messagingApi: string;
    identityApi: string;
    bsMetadataApi: string;
    zephyrApi: string;
    mpcApi: string;
    cavemanApi: string;
    rubiconApi: string;
    tygrisApi: string;
    napoleonApi: string;
    arrakisApi: string;
    rubyApi: string;
    oraclesApi: string;
    pandoraApi: string;
};

export type EnvDetails = {
    abfPid: string;
    syndicatedPid: string;
    liquidationPid: string;
    authDomain: string;
    authClient: string;
    rpcUrl?: string;
    mixpanelToken: string;
} & ApiTypeToString;

export enum AllowedEnv {
    Main = "MAIN", // ONLY app.loopscale.com
    Sandbox = "SANDBOX", //  ONLY sandbox.loopscale.com
    ProdTesting = "PROD_TESTING", // for simulating production with local FE
    Dev = "DEV", // run sandbox with local rust development server (apis);
    FeatureStaging = "FEATURE_STAGING", // for testing staging features in a deployed FE/BE
    LocalNet = "LOCAL_NET" // testing with Local Network instead of mainnet
}

type EnvBreakdown = { main: string; sandbox: string };

const abfPid: EnvBreakdown = {
    main: "abfcSQac2vK2Pa6UAJb37DzarVxF15bDTdphJzAqYYp",
    sandbox: "sb1dXHpc8itGtDJju3EYprHVERDRJ7EVrTSUThywyRe"
};

const liquidationPid: EnvBreakdown = {
    main: "1iQ4TPe4KpuPhxQ3YbLY8DULco99avi7DVVbhHpKmmf",
    sandbox: "1iq2p3KMKMVe9GgKaZRrtPVxtsHb1yqwXJUcFTUjGUB"
};
const AUTH_0_CLIENT: EnvBreakdown = {
    main: "TUAl3Yf4EDOFgwBHRpEEgsusP5Z1Q0HS",
    sandbox: "q8KJf1rrjYJDO0FqUOV4NHuegqr1jWPW"
};

const AUTH_0_DOMAIN: EnvBreakdown = {
    main: "auth.loopscale.com",
    sandbox: "bs-testing.us.auth0.com"
};
const syndicatedPid: EnvBreakdown = {
    main: "syNdL21zsXmUEwnwkz2vg352EZfDuQTiG5BgTFQy5WD",
    sandbox: "BBuNf5GqeDJVZDBZ9YRkFWweNwnR82yG7nm2HUQuXaRX"
};
const BASE_URL: EnvBreakdown = {
    main: "https://tars.loopscale.com",
    sandbox: "https://case.loopscale.com"
};
const SERVICES: ApiTypeToString = {
    abfApi: "abf",
    syndicatedApi: "syndicated",
    externalPricesApi: "pricing",
    messagingApi: "messaging",
    identityApi: "identity",
    bsMetadataApi: "bs-metadata",
    zephyrApi: "zephyr",
    mpcApi: "mpc",
    cavemanApi: "caveman",
    rubiconApi: "rubicon",
    tygrisApi: "tygris",
    napoleonApi: "napoleon",
    arrakisApi: "arrakis",
    rubyApi: "ruby",
    oraclesApi: "oracles",
    pandoraApi: "pandora"
};

const MAIN: EnvDetails = {
    ...generateApiUrls(BASE_URL.main),
    abfPid: abfPid.main,
    liquidationPid: liquidationPid.main,
    syndicatedPid: syndicatedPid.main,
    authDomain: AUTH_0_DOMAIN.main,
    authClient: AUTH_0_CLIENT.main,
    mixpanelToken: "3056c2182274ceebcdfca5a7a043cc64",
    pandoraApi: "https://ms-pandora-http-109615290061.us-central1.run.app"
};

const SANDBOX: EnvDetails = {
    ...generateApiUrls(BASE_URL.sandbox),
    abfPid: abfPid.sandbox,
    bsMetadataApi: "https://ms-bs-metadata-sandbox-vmyhi2huoa-uc.a.run.app",
    mpcApi: "https://ms-mpc-sandbox-vmyhi2huoa-uc.a.run.app",
    pandoraApi: "https://ms-pandora-http-109615290061.us-central1.run.app",
    liquidationPid: liquidationPid.sandbox,
    syndicatedPid: syndicatedPid.sandbox,
    authDomain: AUTH_0_DOMAIN.sandbox,
    authClient: AUTH_0_CLIENT.sandbox,
    mixpanelToken: "17a35910996fe35e74bf5a72c9bb2e3a"
};

const LOCAL_URLS = {
    abfApi: localHost(8080),
    mpcApi: localHost(8081),
    bsMetadataApi: localHost(8082),
    syndicatedApi: localHost(8084),
    napoleonApi: localHost(8085),
    arrakisApi: localHost(8086),
    rubyApi: localHost(8087),
    pandoraApi: localHost(80)
};

const LOCAL_NET: EnvDetails = {
    ...SANDBOX,
    rpcUrl: "http://34.123.94.113:8899",
    abfPid: "97i9bTHPam32aGqekNk4kFuaqU48bv46nr8yyJdGRayq",
    syndicatedPid: "EVbtHAN9hB6AoKwBgPPBsZk3vEFgD9DJqee1DLbdJ1bS",
    ...LOCAL_URLS
};
const PROD_TESTING: EnvDetails = {
    ...MAIN,
    authClient: "wnXpMFJkYwQEWxkih2lKg0naG3FiRfxr"
};

const FEATURE_STAGING: EnvDetails = {
    ...PROD_TESTING,
    napoleonApi: LOCAL_URLS.napoleonApi
};

const DEV: EnvDetails = {
    ...SANDBOX
};

export const ENVIRONMENTS = {
    [AllowedEnv.Main]: MAIN,
    [AllowedEnv.ProdTesting]: PROD_TESTING,
    [AllowedEnv.Sandbox]: SANDBOX,
    [AllowedEnv.Dev]: DEV,
    [AllowedEnv.FeatureStaging]: FEATURE_STAGING,
    [AllowedEnv.LocalNet]: LOCAL_NET
};

function generateApiUrls(baseUrl: string) {
    const entries = Object.entries(SERVICES).map(([key, serviceName]): [keyof ApiTypeToString, string] => [
        key as keyof ApiTypeToString,
        `${baseUrl}/${serviceName}`
    ]);
    return Object.fromEntries(entries) as ApiTypeToString;
}

export function localHost(port: number) {
    const PORT_FORWARDING = false;

    if (PORT_FORWARDING) return `https://9sfrffzp-${port}.usw2.devtunnels.ms/`;
    return `http://localhost:${port}`;
}

export const DEFAULT_API_VERSION = "1.0.3";
export const SERVICE_API_VERSIONS: Partial<ApiTypeToString> = {
    napoleonApi: "1.0.7",
    abfApi: "1.0.4"
};
