import { useCallback, useState } from "react";

import { LoopPositionSortType, UserLoopFilter } from "@bridgesplit/abf-sdk";
import { SortDirection } from "@bridgesplit/utils";

export function useLoopsPagination() {
    const [activeOnly, setActiveOnly] = useState<boolean>(true);
    const [principalMint, setPrincipalMint] = useState<string>();
    const [collateralMint, setCollateralMint] = useState<string>();
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);
    const [sortType, setSortType] = useState<LoopPositionSortType>(LoopPositionSortType.NetPositionValue);

    const params: UserLoopFilter = {
        active: activeOnly,
        principalMint,
        collateralMint,
        page,
        pageSize,
        sortDirection,
        sortType
    };

    const reset = useCallback(() => {
        setActiveOnly(true);
        setPrincipalMint(undefined);
    }, [setActiveOnly, setPrincipalMint]);

    return {
        params,
        setActiveOnly,
        setPrincipalMint,
        setCollateralMint,
        reset,
        setPage,
        setPageSize,
        setSortDirection,
        setSortType
    };
}
