import { AbfLedgerExpanded, BsMetaUtil, LedgerSorting, PaymentEvent } from "@bridgesplit/abf-react";
import { Column, TableColumn, Text, TooltipText } from "@bridgesplit/ui";
import { formatDate, formatSeconds, getUnixTs, isPastNow, roundToDecimals } from "@bridgesplit/utils";
import { AbfLedgerAccount, AbfLedgerAccountInfo } from "@bridgesplit/abf-sdk";

export type LedgerRowData = AbfLedgerExpanded & { ledgerAccounts: AbfLedgerAccount[]; originalPayment?: PaymentEvent };
export const getDueTime = (ledgerAccountInfo: AbfLedgerAccountInfo) =>
    ledgerAccountInfo.loan.loanStartTime + ledgerAccountInfo.ledgerAccount.dueTimeOffset;

const getTimeLeftUntilPayment = (ledgerAccountInfo: AbfLedgerAccountInfo) =>
    formatSeconds(getDueTime(ledgerAccountInfo) - getUnixTs());

const getTimePassedFromPayment = (ledgerAccountInfo: AbfLedgerAccountInfo) =>
    formatSeconds(getUnixTs() - getDueTime(ledgerAccountInfo));

export const getDueTimeWithGracePeriod = (ledgerAccountInfo: AbfLedgerAccountInfo) =>
    getDueTime(ledgerAccountInfo) + (ledgerAccountInfo.ledgerAccount.gracePeriod ?? 0);

export const ledgerColumns: TableColumn<LedgerRowData>[] = [
    {
        dataIndex: "dueDate",
        title: "Due Date",
        width: "25%",
        render: ({ data }) => {
            const time = getDueTime({ ledgerAccount: data.ledgerAccount, loan: data.loan });
            const timeWithGracePeriod = getDueTimeWithGracePeriod({
                ledgerAccount: data.ledgerAccount,
                loan: data.loan
            });
            const graceSeconds = data.ledgerAccount.gracePeriod ?? 0;
            const gracePeriodString = graceSeconds ? formatSeconds(graceSeconds) : "No";

            const tooltip = (() => {
                if (graceSeconds)
                    return `Payment has a grace period of ${gracePeriodString} until ${formatDate(
                        timeWithGracePeriod,
                        "both"
                    )}`;
                return `This payment does not have a grace period and will immediately count as a missed payment after ${formatDate(
                    time
                )}`;
            })();

            return (
                <TooltipText sx={{ gap: 0.5 }} helpText={tooltip}>
                    {formatDate(time, "date")}
                </TooltipText>
            );
        },
        sortBy: ({ data }) => getDueTime({ ledgerAccount: data.ledgerAccount, loan: data.loan }),
        apiSortKey: LedgerSorting.RepaymentTime
    },
    {
        dataIndex: "status",
        title: "Status",
        width: "20%",
        render: ({ data }) => <LedgerStatus data={data} />
    },
    {
        dataIndex: "principal",
        title: "Principal Due",
        width: "30%",
        align: "right",
        apiSortKey: LedgerSorting.TotalDue,
        render: ({ data }) => <LedgerPayment type="principalDue" data={data} />,
        sortBy: ({ data }) => data.ledgerAccount.principalDue
    },
    {
        dataIndex: "interest",
        title: "Interest Due",
        width: "30%",
        align: "right",
        apiSortKey: LedgerSorting.TotalDue,
        render: ({ data }) => <LedgerPayment type="interestDue" data={data} />,
        sortBy: ({ data }) => data.ledgerAccount.interestDue
    }
];

function LedgerStatus({ data }: { data: LedgerRowData }) {
    const ledgerAccountInfo = { ledgerAccount: data.ledgerAccount, loan: data.loan };
    const timeFromPaymentText = isPastNow(getDueTime(ledgerAccountInfo))
        ? getTimePassedFromPayment(ledgerAccountInfo) + " ago"
        : "Due in " + getTimeLeftUntilPayment(ledgerAccountInfo);

    if (data.ledgerAccount.isFilled) return <Text color="success">Repaid</Text>;

    if (isPastNow(getDueTimeWithGracePeriod(ledgerAccountInfo))) return <Text color="error">Outstanding</Text>;

    if (isPastNow(getDueTime(ledgerAccountInfo))) return <Text color="error">Past due</Text>;

    if (isPartiallyPaid(ledgerAccountInfo.ledgerAccount)) return <Text> Partially paid</Text>;

    return <Text color="caption">{timeFromPaymentText}</Text>;
}

function isPartiallyPaid(ledgerAccount: AbfLedgerAccount) {
    if (ledgerAccount.isFilled) return false;
    const principalPartiallyRepaid = !!ledgerAccount.principalPaid && !!ledgerAccount.principalDue;
    const interestPartiallyRepaid = !!ledgerAccount.interestPaid && !!ledgerAccount.interestDue;

    return principalPartiallyRepaid || interestPartiallyRepaid;
}

function LedgerPayment({ data, type }: { data: LedgerRowData; type: "interestDue" | "principalDue" }) {
    const amountDue = data.ledgerAccount[type];

    const originalPayment = roundToDecimals(data.originalPayment?.[type] ?? 0, data.principalMetadata?.decimals);

    if (!amountDue && !originalPayment) {
        return <Text color="disabled">{BsMetaUtil.formatAmount(data.principalMetadata, amountDue)}</Text>;
    }

    return (
        <Column alignItems="flex-end">
            <Text>{BsMetaUtil.formatAmount(data.principalMetadata, amountDue)}</Text>
            {originalPayment !== amountDue && !!originalPayment && !!data.originalPayment && (
                <TooltipText
                    sx={{ width: "max-content" }}
                    tooltipProps={{ placement: "right" }}
                    helpText={`This payment was originally ${BsMetaUtil.formatAmount(
                        data.principalMetadata,
                        originalPayment
                    )} before principal prepayments`}
                    variant="body2"
                    color="caption"
                >
                    Prev. {BsMetaUtil.formatAmount(data.principalMetadata, originalPayment)}
                </TooltipText>
            )}
        </Column>
    );
}

export function calculateAmountLateFee(ledgerAccountInfo: AbfLedgerAccountInfo) {
    if (!isPastNow(getDueTimeWithGracePeriod(ledgerAccountInfo))) {
        return 0;
    }
    const fee =
        (ledgerAccountInfo.ledgerAccount.latePaymentFee || 0) *
        (ledgerAccountInfo.ledgerAccount.principalDue -
            ledgerAccountInfo.ledgerAccount.principalPaid +
            (ledgerAccountInfo.ledgerAccount.interestDue - ledgerAccountInfo.ledgerAccount.interestPaid));
    return Math.max(fee, 0);
}
