import { isValidPublicKey } from "@bridgesplit/abf-react";
import {
    EthSvg,
    FormInput,
    Icon,
    MaticSvg,
    S3_PATH,
    SolanaSvg,
    Text,
    TextVariant,
    TooltipText,
    useAppPalette,
    useCopyAddress
} from "@bridgesplit/ui";
import { ChainId } from "@bridgesplit/abf-sdk";
import { DispatchType } from "@bridgesplit/react";
import { isAddress } from "web3-validator";

import { ChainMetadata } from "../../types";

export function ChainAddressInput({
    value,
    setValue,
    label = "Address",
    chainId
}: {
    value: string | undefined;
    setValue: DispatchType<string | undefined>;
    label?: string;
    chainId: ChainId;
}) {
    const isValid = isValidAddress(chainId, value);
    const meta = getChainMeta(chainId);
    return (
        <FormInput
            inputProps={{ "data-1p-ignore": true }}
            InputProps={{
                endAdornment: value ? (
                    <TooltipText
                        sx={{ pl: 0.75 }}
                        helpText={`${isValid ? "Valid" : "Invalid"} ${meta.name} address`}
                        icon={false}
                        color={isValid ? "success" : "error"}
                    >
                        <Icon type={isValid ? "accept" : "warning"} />
                    </TooltipText>
                ) : undefined,
                startAdornment: (
                    <Text variant="body2" sx={{ pr: 0.75 }} color="disabled">
                        {meta.svg}
                    </Text>
                )
            }}
            label={label}
            placeholder={`Enter address (e.g. ${chainId === ChainId.Solana ? "" : "0x"}A1b2c...)`}
            variant="string"
            value={value}
            setValue={setValue}
        />
    );
}

export const CHAIN_METADATA: Record<number, ChainMetadata> = {
    [ChainId.Solana]: {
        symbol: "SOL",
        name: "Solana",
        logo: `${S3_PATH}/tokens/sol.png`,
        svg: <SolanaSvg />,
        explorer: "https://solscan.io/",
        chainId: ChainId.Solana
    },
    [ChainId.Polygon]: {
        symbol: "MATIC",
        name: "Polygon",
        svg: <MaticSvg />,
        logo: `${S3_PATH}/tokens/matic.png`,
        explorer: "https://polygonscan.com/",
        chainId: ChainId.Polygon
    },
    [ChainId.Ethereum]: {
        symbol: "ETH",
        name: "Ethereum",
        svg: <EthSvg />,
        logo: `${S3_PATH}/tokens/eth.png`,
        explorer: "https://etherscan.io/",
        chainId: ChainId.Ethereum
    }
};

export function getChainMeta(chainId: ChainId | string) {
    let meta: ChainMetadata | undefined;
    if (typeof chainId === "number") {
        meta = Object.values(CHAIN_METADATA).find((c) => c.chainId === chainId);
    }
    if (typeof chainId === "string") {
        meta = Object.values(CHAIN_METADATA).find((c) => c.name === chainId);
    }
    return meta ?? CHAIN_METADATA[ChainId.Solana];
}

export function isValidAddress(chainId: ChainId, address: string | undefined) {
    if (!address) return false;
    if (chainId === ChainId.Solana) return isValidPublicKey(address);
    return isAddress(address);
}

export function ChainAddress({
    address,
    variant,
    chainId = ChainId.Solana,
    label,
    action = "explore",
    loading
}: {
    address: string | undefined;
    variant?: TextVariant;
    chainId?: ChainId;
    label: string;
    action?: "copy" | "explore";
    loading?: boolean;
}) {
    const chainMetadata = getChainMeta(chainId);
    const { textDisabled: iconColor } = useAppPalette();

    const copy = useCopyAddress();

    return (
        <TooltipText
            loading={loading}
            icon={false}
            helpText={`Click to ${action} ${chainMetadata.name} address`}
            onClick={async () => {
                if (!address) return;
                if (action === "copy") {
                    await copy(address);
                }
                if (action === "explore") {
                    window.open(`${chainMetadata.explorer}token/${address}`);
                }
            }}
            underlineLink="hover"
            variant={variant}
            isLink
        >
            <span style={{ display: "flex", color: iconColor }}>{chainMetadata.svg} </span>
            {label}
            {action === "copy" && <Icon sx={{ color: iconColor }} type="copy" />}
            {action === "explore" && <Icon sx={{ color: iconColor }} type="url" />}
        </TooltipText>
    );
}
