import { createApi } from "@reduxjs/toolkit/query/react";

import {
    ROUTE_VERIFY_TWITTER_FOLLOW,
    ROUTE_FETCH_TWITTER_REQUEST_TOKEN,
    ROUTE_FETCH_TWITTER_OAUTH_TOKEN,
    ROUTE_FETCH_TWITTER_INFO
} from "../constants/routes";
import { abfSerializeQueryArgs, rubyBaseQuery } from "./util";
import {
    ApplyTwitterPointsParams,
    FetchTwitterAccessTokenParams,
    TwitterRequestTokenResponse,
    TwitterAccessTokenResponse,
    TwitterFollower
} from "../types";

const TWITTER_POINTS = "TwitterPoints";

export const twitterPointsApi = createApi({
    reducerPath: "twitterPointsApi",
    tagTypes: [TWITTER_POINTS],
    baseQuery: rubyBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    endpoints: (builder) => ({
        twitterInfo: builder.query<TwitterFollower | undefined, void>({
            providesTags: [TWITTER_POINTS],
            query: () => ({
                url: ROUTE_FETCH_TWITTER_INFO,
                method: "GET"
            })
        }),
        fetchTwitterRequestToken: builder.mutation<TwitterRequestTokenResponse, void>({
            query: () => ({
                url: ROUTE_FETCH_TWITTER_REQUEST_TOKEN,
                method: "GET"
            })
        }),
        verifyTwitterFollow: builder.mutation<void, ApplyTwitterPointsParams>({
            invalidatesTags: [TWITTER_POINTS],
            query: (params) => ({
                url: ROUTE_VERIFY_TWITTER_FOLLOW,
                method: "POST",
                body: params
            })
        }),
        fetchTwitterOAuthToken: builder.mutation<TwitterAccessTokenResponse, FetchTwitterAccessTokenParams>({
            query: (params) => ({
                url: ROUTE_FETCH_TWITTER_OAUTH_TOKEN,
                method: "POST",
                body: params
            })
        })
    })
});

export const {
    useVerifyTwitterFollowMutation,
    useFetchTwitterRequestTokenMutation,
    useFetchTwitterOAuthTokenMutation,
    useTwitterInfoQuery
} = twitterPointsApi;

export function useTwitterPointsApi() {
    return {
        resetTwitterInfo: () => twitterPointsApi.util.invalidateTags([TWITTER_POINTS])
    };
}
