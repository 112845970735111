import {
    NullableRecord,
    STAKED_SOL_MINT,
    convertDateToSeconds,
    filterNullableRecord,
    splitArray
} from "@bridgesplit/utils";
import { useMemoizedKeyMap } from "@bridgesplit/ui";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AssetTypeIdentifier } from "@bridgesplit/abf-sdk";

import {
    useBsMetadataByMints,
    useEscrowedAssetsQuery,
    useLoanRequestsQuery,
    useLockboxesQuery,
    useTokensByWalletQuery
} from "../reducers";
import { isCollateralSplMint, isStakedSol, useAbfTypesToUiConverter } from "../utils";
import { LockboxExpanded, LockboxFilter } from "../types";
import { useActiveWallet } from "./wallet";
import { useWhirlpoolPositions } from "./whirlpool";
import { useEscrowAccounts } from "./escrow";
import { useActiveGroup } from "./group";

export function useLockboxes(filter: LockboxFilter, options?: { skip?: boolean }) {
    const { data, isLoading, isFetching } = useLockboxesQuery(filter, options);

    const assetMints = data?.map((d) => d.assets.filter(isCollateralSplMint).map((a) => a.assetKey)).flat();
    const { convertLockboxWithAssets, tokensLoading } = useAbfTypesToUiConverter(assetMints);

    return {
        data: data && !tokensLoading ? data.map((lockbox) => convertLockboxWithAssets(lockbox)) : data,
        isLoading,
        isFetching
    };
}

export function useLockboxByAddress(address: string | undefined, options?: { skip?: boolean }) {
    const { data, isLoading, isFetching } = useLockboxes(
        { addresses: address ? [address] : [] },
        { skip: options?.skip || !address }
    );

    return { data: data?.find((l) => l.data.address === address), isLoading, isFetching };
}

export function useEscrowedLockboxes() {
    const { groupIdentifier } = useActiveGroup();
    const { escrowPubkeys, escrowNonces } = useEscrowAccounts();

    const { activeWallet } = useActiveWallet();

    const { data: walletAssets } = useTokensByWalletQuery(activeWallet?.wallet ?? skipToken, { skip: !activeWallet });
    const { data: escrowAssets } = useEscrowedAssetsQuery(
        { escrow_accounts: escrowPubkeys },
        { skip: !escrowPubkeys?.length }
    );

    const eligibleWalletAssets = walletAssets?.tokens
        ?.filter((w) => parseInt(w.tokenAmount.amount) === 1)
        .map((w) => w.mint);
    const eligibleEscrowAssets = escrowAssets?.filter((w) => w.amount === 1).map((w) => w.mint);

    const escrowAccountMap = useMemoizedKeyMap(escrowAssets, (e) => e.mint);
    const potentialLockboxes =
        eligibleWalletAssets && eligibleEscrowAssets ? eligibleWalletAssets.concat(eligibleEscrowAssets) : undefined;

    const query = useLockboxes(
        { nftMints: potentialLockboxes, includeEmpty: true },
        { skip: !potentialLockboxes?.length }
    );

    // use raw loan req query to minimize extra reqs from expanded
    const { data: loanRequests } = useLoanRequestsQuery(
        {
            groups: groupIdentifier ? [groupIdentifier] : undefined,
            makerNonces: escrowNonces
        },
        { skip: !groupIdentifier || !escrowNonces }
    );
    const activeLockboxAddresses = new Set(
        loanRequests?.filter((req) => !req.loanRequest.archived).map((req) => req.loanRequest.lockboxAddress)
    );

    const { getMetadata, isLoading: metadataLoading } = useBsMetadataByMints(
        query.data?.map((d) => d.assets.map((a) => a.assetKey).concat(STAKED_SOL_MINT)).flat()
    );

    const { data: wp } = useWhirlpoolPositions(
        query.data
            ?.map(({ assets }) =>
                assets
                    .filter((asset) => asset.assetTypeDiscriminator === AssetTypeIdentifier.OrcaPosition)
                    .map((asset) => asset.assetKey)
            )
            .flat()
    );
    const mintToWhirlpool = useMemoizedKeyMap(wp, (w) => w.position.positionMint);

    const lockboxes = potentialLockboxes?.length
        ? query.data
              ?.map(
                  ({ data, assets }): NullableRecord<LockboxExpanded> => ({
                      data,
                      escrow: escrowAccountMap ? escrowAccountMap.get(data.nftMint) ?? null : undefined,
                      assets: assets
                          .map((d) => {
                              const whirlpoolPosition = mintToWhirlpool?.get(d.assetKey);
                              if (whirlpoolPosition) {
                                  return {
                                      ...d,
                                      metadata: whirlpoolPosition.whirlpoolMetadata,
                                      whirlpoolPosition
                                  };
                              }
                              const metadata = getMetadata(
                                  isStakedSol(d.assetTypeDiscriminator) ? STAKED_SOL_MINT : d.assetKey
                              );
                              return { ...d, metadata };
                          })
                          .filter(filterNullableRecord)
                  })
              )
              .filter(filterNullableRecord)
        : [];

    const isLoading = !potentialLockboxes || !loanRequests || metadataLoading || query.isFetching;
    const adjustableLockboxes = isLoading
        ? undefined
        : lockboxes?.filter(
              (l) =>
                  l.data.initializationTime > convertDateToSeconds(new Date("04/01/2024")) &&
                  !activeLockboxAddresses.has(l.data.address)
          );
    const [lockboxesWithAssets, emptyLockboxes] = splitArray(adjustableLockboxes, (l) =>
        l.assets.some((c) => !!c.amount)
    );

    const claimNeeded = !!lockboxesWithAssets?.length;

    return { lockboxes, lockboxesWithAssets, claimNeeded, emptyLockboxes };
}
