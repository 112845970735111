import { ReactNode, createContext, useContext } from "react";

import { Result, WALLET_ERROR, Web3User } from "@bridgesplit/utils";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Connection } from "@solana/web3.js";

export const DEFAULT_CONNECTION = "https://delicious-melita-fast-mainnet.helius-rpc.com/";
export const LoopscaleWeb3Context = createContext<Partial<Web3User>>({
    connection: new Connection(DEFAULT_CONNECTION),
    wallet: undefined
});

export const LoopscaleWeb3Provider = ({ children, connectionUrl }: { children: ReactNode; connectionUrl: string }) => {
    const { signMessage } = useWallet();
    const wallet = useAnchorWallet();
    return (
        <LoopscaleWeb3Context.Provider
            value={{ connection: new Connection(connectionUrl), wallet: wallet ? { ...wallet, signMessage } : wallet }}
        >
            {children}
        </LoopscaleWeb3Context.Provider>
    );
};

export const useWeb3User = () => {
    const { connection, wallet } = useContext(LoopscaleWeb3Context);

    function getWeb3UserResult(): Result<Web3User> {
        if (!connection || !wallet) {
            return Result.err(WALLET_ERROR);
        }
        return Result.ok({ connection, wallet });
    }

    return { getWeb3UserResult, wallet, connection: connection || new Connection(DEFAULT_CONNECTION) };
};
