import { DialogWrapper } from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";

import { DialogHeaderWithBack } from "../common";
import InstantUnstake from "./unstake/InstantUnstake";

export default function InstantUnstakeDialog() {
    const { getData } = useAppDialog();
    const data = getData<AppDialog.InstantUnstake>();

    return (
        <DialogWrapper>
            <DialogHeaderWithBack back={data?.back} header="Unstake" />
            <InstantUnstake showWallet={data?.balances === "wallet"} />
        </DialogWrapper>
    );
}
