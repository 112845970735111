import { AbfEscrowedAsset, AbfEscrowedEvent } from "@bridgesplit/abf-sdk";
import { createApi } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { Result, TransactionInstructionResponse, getReadableErrorMessage } from "@bridgesplit/utils";

import { EscrowEditIxInput, EscrowedAssetFilter, EscrowedAssetHistoryFilter } from "../types";
import { ESCROW_ASSET_HISTORY_ROUTE, GET_ESCROWED_ASSETS_ROUTE, GET_ESCROW_EDIT_IX_ROUTE } from "../constants";
import { abfBaseQuery, abfSerializeQueryArgs } from "./util";
import { abfEscrowedAssetPublicApi, ESCROWS_PUBLIC_TAG } from "./escrowedAssetPublicApi";

const ESCROWS_TAG = "Escrows";
export const abfEscrowedAssetApi = createApi({
    reducerPath: "abfEscrowedAssetApi",
    baseQuery: abfBaseQuery,
    serializeQueryArgs: abfSerializeQueryArgs,
    tagTypes: [ESCROWS_TAG],
    endpoints: (builder) => ({
        escrowedAssets: builder.query<AbfEscrowedAsset[], EscrowedAssetFilter>({
            providesTags: [ESCROWS_TAG],
            query(params) {
                return {
                    url: GET_ESCROWED_ASSETS_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        }),

        escrowEditIx: builder.mutation<TransactionInstructionResponse, EscrowEditIxInput>({
            query(params) {
                return {
                    url: GET_ESCROW_EDIT_IX_ROUTE,
                    method: "POST",
                    body: params
                };
            }
        }),

        escrowedAssetHistory: builder.query<AbfEscrowedEvent[], EscrowedAssetHistoryFilter>({
            providesTags: [ESCROWS_TAG],
            query(body) {
                return {
                    url: ESCROW_ASSET_HISTORY_ROUTE,
                    body,
                    method: "POST"
                };
            }
        })
    })
});

export const { useEscrowedAssetsQuery, useEscrowedAssetHistoryQuery, useEscrowEditIxMutation } = abfEscrowedAssetApi;

export const useAbfEscrowedAssetApi = () => {
    const dispatch = useDispatch();

    // Resets both the escrowedAssetApi & the escrowedAssetPublicApi.
    const resetEscrowApi = () => {
        dispatch(abfEscrowedAssetApi.util.invalidateTags([ESCROWS_TAG]));
        dispatch(abfEscrowedAssetPublicApi.util.invalidateTags([ESCROWS_PUBLIC_TAG]));
    };

    return {
        resetEscrowedApi: () => resetEscrowApi()
    };
};

export const useGetEscrowEditIx = () => {
    const [getEscrowEditIxMutation] = useEscrowEditIxMutation();

    return async function getEscrowEditIx(
        editParams: EscrowEditIxInput
    ): Promise<Result<TransactionInstructionResponse>> {
        const res = await getEscrowEditIxMutation(editParams);
        if ("error" in res) {
            return Result.errWithDebug(getReadableErrorMessage("edit escrow ix"), res.error);
        } else {
            return Result.ok(res.data);
        }
    };
};
