import { AbfPermission } from "@bridgesplit/abf-react";
import { SMALL_MAX_WIDTH, TabsCard, Text } from "@bridgesplit/ui";
import { AppTab } from "app/constants";

import { Page } from "../wrappers";
import GroupRolesAdmin from "./GroupRolesAdmin";
import AccountPermissions from "../account-settings/AccountPermissions";
import { useUiPermission } from "../navbar";
import AccountEditGroup from "../account-settings/AccountEditGroup";

export default function OrganizationSettings() {
    const { isAdmin } = useUiPermission();

    return (
        <Page permission={AbfPermission.ModifyMembers} maxWidth={SMALL_MAX_WIDTH}>
            <Text variant="h1">Organization</Text>
            <TabsCard
                paramsOptions={{ allowed: true }}
                spacing={4}
                childrenSpacing={4}
                textVariant="h4"
                tabs={[
                    {
                        id: AppTab.GeneralSettings,
                        component: <AccountEditGroup isOrganizationAdmin />,
                        label: "General",
                        hide: !isAdmin
                    },
                    {
                        id: "permissions",
                        component: <AccountPermissions />,
                        label: "Permissions"
                    },
                    { id: "team", component: <GroupRolesAdmin />, label: "Team", hide: !isAdmin }
                ]}
            />
        </Page>
    );
}
