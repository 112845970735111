import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { DispatchType } from "@bridgesplit/react";
import {
    BestQuote,
    Collateral,
    DEFAULT_PRESET,
    MaxQuoteFromCollateralAmount,
    PresetStrategyTerms,
    PresetTerms,
    StrategyDuration,
    isWhirlpoolMetadata,
    useBestQuotes,
    useMaxQuotesForCollateral,
    useOraclePrices,
    usePresets
} from "@bridgesplit/abf-react";
import { bsMath, findMaxElement, removeDuplicatesByProperty, uiAmountToLamports } from "@bridgesplit/utils";
import { BsMetadata, OracleQuote } from "@bridgesplit/abf-sdk";

import { initialBorrowForm, MarketBorrowForm } from "./type";
import { useMarketContext, ALL_PRESET_COLLATERAL_VALUE } from "../common";
import { useUserCollateralForMarket } from "./util";

type Prices = {
    principalAmountUsd: number | undefined;
    collateralAmountUsd: number | undefined;
    principalPriceUsd: number | undefined;
    collateralPriceUsd: number | undefined;
    principalOracle: OracleQuote | undefined;
    collateralOracle: OracleQuote | undefined;
};

type ContextState = {
    form: MarketBorrowForm;
    setForm: DispatchType<MarketBorrowForm>;
    userCollateral: Collateral[] | undefined;
    activeUserCollateral: Collateral | undefined;
    maxQuote: MaxQuoteFromCollateralAmount | null | undefined;
    durationForQueries: StrategyDuration;
    presets: PresetStrategyTerms[] | undefined;
    allCollateral: BsMetadata[] | undefined;
    allPresets: PresetTerms[] | undefined;
    bestQuote: BestQuote | undefined;
    allDurationQuotes: BestQuote[] | undefined;
    quoteFetching: boolean;
    prices: Prices;
};
const MarketBorrowContext = createContext<ContextState | null>(null);

export function useMarketBorrowContext() {
    const context = useContext(MarketBorrowContext);
    if (!context) {
        throw new Error("useMarketBorrowState must be used within a MarketBorrowProvider");
    }
    return context;
}

export function MarketBorrowProvider({ children }: { children: ReactNode }) {
    const marketProps = useMarketContext();
    const { principalMint, token, isCustodian } = marketProps;
    const [form, setForm] = useState<MarketBorrowForm>(initialBorrowForm);

    const { data: userCollateral, tokens } = useUserCollateralForMarket(marketProps);
    const { getOracle } = useOraclePrices([form.collateralMint, principalMint]);

    const activeUserCollateral = useMemo(() => {
        const active = userCollateral?.find((c) => c.mint === form.collateralMint);
        if (isWhirlpoolMetadata(active?.metadata)) {
            if (!form.orcaPosition) return undefined;
            const { position, whirlpoolMetadata, totalPrice, whirlpoolAddress, oracle } = form.orcaPosition;
            const positionAsCollateral: Collateral = {
                mint: whirlpoolAddress,
                // quotes endpoints take in total liquidity as collateral amount
                amount: position.totalLiquidity,
                usdValue: totalPrice,
                metadata: whirlpoolMetadata,
                // price is in USD per 1 unit of collateral
                usdPrice: totalPrice / position.totalLiquidity,
                oracle
            };
            return positionAsCollateral;
        }
        return active;
    }, [form.collateralMint, form.orcaPosition, userCollateral]);

    const inputCollateral = useMemo(() => {
        if (!activeUserCollateral) return [];
        if (isWhirlpoolMetadata(activeUserCollateral.metadata)) {
            return [activeUserCollateral];
        }
        // user collateral max quotes is already cached
        if (!form.collateralAmount) return userCollateral;

        return [{ ...activeUserCollateral, amount: form.collateralAmount ?? activeUserCollateral.amount }];
    }, [activeUserCollateral, form.collateralAmount, userCollateral]);

    // fallback on the default preset if no preset is selected (so that max quotes can be fetched)
    const durationForQueries = form.preset ?? DEFAULT_PRESET;

    const maxQuotes = useMaxQuotesForCollateral({
        collateral: inputCollateral,
        duration: durationForQueries.duration,
        durationType: durationForQueries.durationType,
        principalMint
    });

    const maxQuote = useMemo(() => {
        if (maxQuotes.isLoading || !maxQuotes.data || maxQuotes.isFetching) return undefined;

        const maxQuote = maxQuotes.data?.find((c) => c.mint === form.collateralMint)?.maxQuote ?? null;
        return maxQuote;
    }, [form.collateralMint, maxQuotes]);

    const { allPresets } = usePresets(isCustodian ? [ALL_PRESET_COLLATERAL_VALUE] : tokens?.map((t) => t.assetMint));

    const presetTerms = useMemo(() => {
        if (!allPresets) return undefined;
        return removeDuplicatesByProperty(
            allPresets?.map((p) => p.strategyTerms),
            "presetStrategyIdentifier"
        );
    }, [allPresets]);

    const { data: quotes, isFetching: quoteFetching } = useBestQuotes({
        collateralMints: form.collateralMint ? [form.collateralMint] : undefined,
        principalMint,
        minPrincipalAmountLamports: uiAmountToLamports(form.amount, token?.decimals),
        presets: presetTerms,
        skip: !form.amount
    });

    const activePreset = form.preset;
    const availableQuotes = quotes?.filter(
        (q) =>
            !!form.amount &&
            q.collateralMint === form.collateralMint &&
            q.principalAvailable >= (form.amount || 0) &&
            q.duration === activePreset?.duration &&
            q.durationType === activePreset?.durationType
    );

    // best quotes already guarantee top apy
    const bestQuote = findMaxElement(availableQuotes, (v) => v.ltv);

    const principalOracle = getOracle(principalMint);
    const collateralOracle = activeUserCollateral?.oracle ?? undefined;
    const principalPriceUsd = principalOracle?.usdPrice;

    let collateralPriceUsd = activeUserCollateral?.usdPrice;

    // if best quote has a min price, use it relative to principal USD price
    if (!collateralPriceUsd && bestQuote?.minPrice && principalPriceUsd) {
        collateralPriceUsd = principalPriceUsd * bestQuote.minPrice;
    }
    if (!collateralPriceUsd && maxQuote?.minPrice && principalPriceUsd) {
        collateralPriceUsd = principalPriceUsd * maxQuote.minPrice;
    }

    return (
        <MarketBorrowContext.Provider
            value={{
                form,
                setForm,
                userCollateral,
                activeUserCollateral,
                bestQuote,
                allDurationQuotes: quotes,
                presets: presetTerms,
                allPresets,
                allCollateral: tokens,
                quoteFetching,
                maxQuote,
                durationForQueries,
                prices: {
                    collateralAmountUsd: bsMath.mul(collateralPriceUsd, form.collateralAmount),
                    principalAmountUsd: bsMath.mul(principalPriceUsd, form.amount),
                    collateralPriceUsd,
                    principalPriceUsd,
                    principalOracle,
                    collateralOracle
                }
            }}
        >
            {children}
        </MarketBorrowContext.Provider>
    );
}
