import { Column } from "@bridgesplit/ui";

import WithdrawAmounts from "./WithdrawAmounts";
import WithdrawCta from "./WithdrawCta";
import WithdrawCtaStats from "./WithdrawCtaStats";

function WithdrawTop() {
    return <WithdrawAmounts />;
}

function WithdrawBottom() {
    return (
        <Column spacing={2}>
            <WithdrawCtaStats />
            <WithdrawCta />
        </Column>
    );
}

export const Withdraw = {
    Top: WithdrawTop,
    Bottom: WithdrawBottom
};
