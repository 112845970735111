import { useMemo } from "react";

import {
    Button,
    FONT_SIZES,
    Icon,
    Image,
    InvisibleSelect,
    OverlappingImagesBase,
    Span,
    Text,
    TextButton,
    TextSkeleton,
    useAppPalette
} from "@bridgesplit/ui";
import { formatDurationWithType } from "@bridgesplit/abf-sdk";
import { BsMetaUtil, formatTokenMetadata, MarketGuideMode, RoleView } from "@bridgesplit/abf-react";
import { ExpandMore } from "@mui/icons-material";
import { AppDialog } from "app/utils";
import { DialogHeaderWithBack } from "app/components/common";

import { useQuotesContext } from "./QuotesContext";
import { useMarketCollateral, useMarketDialog } from "../util";
import { useMarketContext } from "./MarketContext";
import { ALL_COLLATERAL_VALUE } from "./constants";

export function DurationSelect({ label = "Duration" }: { label?: string }) {
    const { hoverBackground } = useAppPalette();
    const { presets, preset, setPreset } = useQuotesContext();

    return (
        <InvisibleSelect
            sx={{ height: 30, ":hover": { background: hoverBackground } }}
            renderValue={() => (
                <Text>
                    <Span sx={{ color: ({ palette }) => palette.text.secondary }}>{label} </Span>
                    {preset ? (
                        formatDurationWithType(preset?.duration, preset?.durationType)
                    ) : (
                        <TextSkeleton width="30px" variant="body1" />
                    )}
                </Text>
            )}
            value={preset?.presetStrategyIdentifier}
            setValue={(identifier) => {
                setPreset(presets?.find((p) => p.presetStrategyIdentifier === identifier));
            }}
            options={(presets ?? [])?.map(({ duration, durationType, presetStrategyIdentifier }) => ({
                value: presetStrategyIdentifier,
                label: formatDurationWithType(duration, durationType)
            }))}
        />
    );
}

export function RoleViewSelect({ label }: { label: string }) {
    const { hoverBackground } = useAppPalette();
    const { view, setView } = useMarketContext();

    if (!setView) return null;

    return (
        <InvisibleSelect
            sx={{ height: 30, ":hover": { background: hoverBackground } }}
            renderValue={() => (
                <Text>
                    <Span sx={{ color: ({ palette }) => palette.text.secondary }}>{label}</Span>
                    {view}
                </Text>
            )}
            value={view}
            setValue={setView}
            options={[{ value: RoleView.Borrow }, { value: RoleView.Lend }]}
        />
    );
}

export function CollateralSwitch() {
    const { collateralMints, setCollateralMints } = useQuotesContext();
    const collateral = useMarketCollateral();
    const { principalMint } = useMarketContext();

    const selectedCollateral = useMemo(() => {
        const selected = new Set(collateralMints);
        return collateral?.filter((c) => selected.has(c.assetMint));
    }, [collateral, collateralMints]);

    const { open } = useMarketDialog();

    const isMaxSelected = collateral?.length === collateralMints?.length || collateralMints === ALL_COLLATERAL_VALUE;

    return (
        <Button
            variant="text"
            height={30}
            onClick={() =>
                open(AppDialog.MultiSelectCollateral, {
                    tokens: collateral,
                    header: "Custom filter",
                    collateral: collateralMints,
                    principalMint,
                    isAdvanced: true,
                    setCollateral: (collateral) => setCollateralMints(collateral.map((c) => c.metadata.assetMint))
                })
            }
        >
            <>
                <Span sx={{ color: ({ palette }) => palette.text.secondary }}>Collateral </Span>

                {selectedCollateral ? (
                    <OverlappingImagesBase
                        size={FONT_SIZES.body1 + "px"}
                        images={selectedCollateral?.map((c) => BsMetaUtil.getImage(c)).slice(0, 3)}
                    />
                ) : (
                    <Image variant="circle" src={undefined} size={FONT_SIZES.body1 + "px"} />
                )}
                {(() => {
                    if (!selectedCollateral) return <TextSkeleton width="30px" variant="body1" />;
                    if (isMaxSelected) return "All";
                    return formatTokenMetadata(selectedCollateral);
                })()}
            </>
            <Span sx={{ color: ({ palette }) => palette.text.secondary }}>
                <ExpandMore />
            </Span>
        </Button>
    );
}

export function MarketDialogHeader({
    header,
    mode,
    back
}: {
    header: string;
    mode: MarketGuideMode;
    back?: () => void;
}) {
    const { open } = useMarketDialog();

    return (
        <DialogHeaderWithBack
            rowEnd={
                <TextButton
                    variant="h3"
                    color="disabled"
                    onClick={() => open(AppDialog.MarketGuide, { mode, isNestedDialog: true })}
                >
                    <Icon type="help" />
                </TextButton>
            }
            header={header}
            back={back}
        />
    );
}
