import { useActiveWallet } from "@bridgesplit/abf-react";
import { ChainId } from "@bridgesplit/abf-sdk";
import { TagTextAlert } from "@bridgesplit/ui";

import { QrCodeCopyAddress } from "../../common";

export default function DepositToWallet({ specifiedMint }: { specifiedMint?: string }) {
    const { activeWallet } = useActiveWallet();
    const address = activeWallet?.wallet;

    return (
        <>
            <TagTextAlert sx={{ mx: -2 }} icon="tooltip" color="info">
                This address is only valid for tokens on Solana
            </TagTextAlert>
            <QrCodeCopyAddress
                label="Your Solana wallet address"
                size="large"
                chainId={ChainId.Solana}
                address={address}
                specifiedMint={specifiedMint}
            />
        </>
    );
}
