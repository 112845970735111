import { ReactNode, createContext, useContext as useContextReact, useEffect, useMemo, useState } from "react";

import { ChainId } from "@bridgesplit/abf-sdk";
import { Column, Row, StatColumn, Text, TextButton } from "@bridgesplit/ui";
import { useUserEvmBalance } from "@bridgesplit/abf-react";
import { formatTokenAmount } from "@bridgesplit/utils";
import { DispatchType } from "@bridgesplit/react";

import { AppButton, AppButtonProps, getChainMeta } from "../common";
import ManageBalance from "./balance/ManageBalance";

export enum EvmBalanceContextMode {
    Default,
    Deposit,
    Withdraw
}
type EvmBalanceContextType = {
    chain: ChainId;
    mode: EvmBalanceContextMode;
    setMode: DispatchType<EvmBalanceContextMode>;
    requestedBalance: number | undefined;
    setRequestedBalance: DispatchType<number | undefined>;
};

const EvmBalanceContext = createContext<EvmBalanceContextType | undefined>(undefined);

function useContext() {
    const context = useContextReact(EvmBalanceContext);
    if (!context) {
        throw new Error("useContext must be used within a EvmBalance.Wrapper");
    }
    return context;
}

function Wrapper({ children, chain }: { children: ReactNode; chain: ChainId }) {
    const [mode, setMode] = useState<EvmBalanceContextMode>(EvmBalanceContextMode.Default);
    const [requestedBalance, setRequestedBalance] = useState<number>();

    const content = useMemo(() => {
        if (mode === EvmBalanceContextMode.Default) {
            return <>{children}</>;
        }
        return <ManageBalance />;
    }, [children, mode]);
    return (
        <EvmBalanceContext.Provider value={{ chain, mode, setMode, requestedBalance, setRequestedBalance }}>
            {content}
        </EvmBalanceContext.Provider>
    );
}

// Show just the user's balance with a prompt to manage it (and switch out the provider children)
function Balance({ requestedBalance }: { requestedBalance?: number }) {
    const { chain, setMode, requestedBalance: requestedBalanceState, setRequestedBalance } = useContext();
    const { data } = useUserEvmBalance(chain);
    const meta = getChainMeta(chain);

    // dispatch up to context manually
    useEffect(() => {
        if (!requestedBalance || requestedBalanceState === requestedBalance) return;
        setRequestedBalance(requestedBalance);
    }, [requestedBalance, requestedBalanceState, setRequestedBalance]);

    const insufficientBalance = data && requestedBalance ? requestedBalance > data.uiAmount : false;

    return (
        <Row spacing={1} spaceBetween>
            <Row spacing={0.5}>
                <Text color="caption">Balance:</Text>
                <Text color={insufficientBalance ? "error" : "body"}>
                    {formatTokenAmount(data?.uiAmount, { symbol: meta.symbol })}
                </Text>
            </Row>
            <TextButton variant="body1" underlineLink onClick={() => setMode(EvmBalanceContextMode.Deposit)}>
                Manage
            </TextButton>
        </Row>
    );
}

// Wrap a button with required gas above button and balance below
function Cta<T>({
    requestedBalance,
    disabled,
    children,
    ...buttonProps
}: { requestedBalance: number | undefined } & AppButtonProps<T>) {
    const { chain } = useContext();
    const meta = getChainMeta(chain);
    const { data } = useUserEvmBalance(chain);

    const errorMessage = (() => {
        if (requestedBalance === undefined) return "Estimating Network Fees...";
        if (!data?.uiAmount || requestedBalance > data.uiAmount) return `Insufficient ${meta.symbol} Balance`;
        return undefined;
    })();

    return (
        <Column spacing={1}>
            <StatColumn stats={[{ value: requestedBalance, symbol: meta.symbol, caption: "Est. Network Fee" }]} />
            <AppButton disabled={!!errorMessage || disabled} {...buttonProps}>
                {errorMessage ?? children}
            </AppButton>
            <Balance requestedBalance={requestedBalance} />
        </Column>
    );
}

/**
 * Controls a component that allows the user to adjust their EVM balance
 * Once user has finalized their option, they will return to the children from `Wrapper`
 */
export default { Wrapper, Balance, Cta, useState: useContext };
