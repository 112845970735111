export enum AbfUserInviteType {
    SyndicatedOffer
}

export interface AbfUserInvite {
    id: number;
    maker: string;
    identifier: string;
    inviteType: AbfUserInviteType;
    email: string;
    accepted: boolean;
    organizationIdentifier?: string;
}
