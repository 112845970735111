import { ReactNode } from "react";

import { HeaderCard, MEDIA, Row, StatColumn, StatProps, TabInterface, TabsCard, Text } from "@bridgesplit/ui";
import { useMediaQuery } from "@mui/material";
import { formatPercent } from "@bridgesplit/utils";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { useHealthColor } from "app/components/common";
import { COPY } from "app/constants";

import { CollateralLoanHealthChange, HealthStatProps } from "./types";

export function LoanActionHeader({ header, children }: { children: ReactNode; header: string }) {
    const isMobile = useMediaQuery(ACTION_BREAKPOINT.below);

    return (
        <HeaderCard
            spacing={2}
            wrapperSx={{ [ACTION_BREAKPOINT.above]: { py: 2, width: "auto" } }}
            childrenSx={{ [ACTION_BREAKPOINT.above]: { px: 2 } }}
            header={
                <Text sx={{ [ACTION_BREAKPOINT.above]: { px: 2 } }} variant={isMobile ? "h3" : "h4"}>
                    {header}
                </Text>
            }
        >
            {children}
        </HeaderCard>
    );
}

export function LoanActionTabs({ tabs }: { tabs: TabInterface[] }) {
    const isMobile = useMediaQuery(ACTION_BREAKPOINT.below);
    return (
        <TabsCard
            labelSx={{ [ACTION_BREAKPOINT.above]: { pt: 1 } }}
            bodySx={{ [ACTION_BREAKPOINT.above]: { p: 2, pt: 0 } }}
            childrenSpacing={2}
            variant={isMobile ? "standard" : "spaced-out"}
            textVariant={isMobile ? undefined : "h4"}
            tabs={tabs}
        />
    );
}

export const ACTION_BREAKPOINT = MEDIA.XL;

export function HealthStat({ value }: HealthStatProps) {
    const getColor = useHealthColor();
    return (
        <Row spacing={0.5}>
            <Text sx={{ color: getColor(value[0] as number) }}>{formatPercent(value[0], { hideSymbol: true })}</Text>
            <Text color="disabled" variant="body2">
                <ArrowForwardOutlined />
            </Text>
            <Text sx={{ color: getColor(value[1] as number) }}>{formatPercent(value[1])}</Text>
        </Row>
    );
}

export function CollateralLoanHealthChangeStats({
    healthChange,
    extraStats = []
}: {
    healthChange: CollateralLoanHealthChange | undefined;
    extraStats?: StatProps[];
}) {
    const stats: StatProps[] = [
        {
            value: <HealthStat value={[healthChange?.originalHealth, healthChange?.newHealth]} />,
            caption: COPY.HEALTH_FACTOR_TERM
        },
        {
            value: [healthChange?.originalCollateralValueUsd, healthChange?.newLoanCollateralValueUsd],
            caption: "Collateral value",
            isUsd: true
        },
        ...extraStats
    ];

    return <StatColumn loading={!healthChange} stats={stats} />;
}
