import { Column } from "@bridgesplit/ui";

import DepositAmounts from "./DepositAmounts";
import DepositCta from "./DepositCta";
import DepositCtaStats from "./DepositCtaStats";

function DepositTop() {
    return <DepositAmounts />;
}

function DepositBottom() {
    return (
        <Column spacing={2}>
            <DepositCtaStats />
            <DepositCta />
        </Column>
    );
}

export const Deposit = {
    Top: DepositTop,
    Bottom: DepositBottom
};
