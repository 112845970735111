import { AbfEscrowAccount, ExternalYieldSource } from "@bridgesplit/abf-sdk";
import { DEFAULT_PUBLIC_KEY } from "@bridgesplit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useAuth, useAuthStorage, useGroupEscrowsQuery } from "../reducers";
import { getEscrowAccountForGroup } from "../utils";
import { useUserBetaAccess } from "./points";
import { useGroup } from "./group";
import { AbfGroupType } from "../types";
import { useSkipUnauthenticated } from "./auth";

export function useEscrowAccounts() {
    const { state } = useAuthStorage();
    return useEscrowsForGroup(state.groupIdentifier);
}

export function useEscrowsForGroup(identifier: string | undefined) {
    const { isLender } = useUserBetaAccess();
    const skipIfUnauthenticated = useSkipUnauthenticated();

    const { data: escrows, isFetching } = useGroupEscrowsQuery(identifier ?? skipToken, {
        skip: !identifier || skipIfUnauthenticated || !isLender
    });
    if (!isFetching && !escrows?.length && !!identifier) {
        const address = getEscrowAccountForGroup(identifier, DEFAULT_PUBLIC_KEY);
        const defaultEscrow: AbfEscrowAccount = {
            address,
            id: 0,
            organizationIdentifier: identifier,
            nonce: DEFAULT_PUBLIC_KEY,
            txn: "",
            cumulativeAmountOriginated: 0,
            originationCap: 0,
            externalYieldSource: ExternalYieldSource.None
        };
        return { escrows: [defaultEscrow], escrowPubkeys: [address], escrowNonces: [DEFAULT_PUBLIC_KEY] };
    }
    const escrowPubkeys: string[] | undefined = escrows?.map((escrow) => escrow.address);
    const escrowNonces: string[] | undefined = escrows?.map((escrow) => escrow.nonce);

    return { escrows, escrowPubkeys, escrowNonces };
}

export function useEscrowPreference() {
    const { activeGroup } = useGroup();
    const { isWalletBased } = useAuth();

    const escrowNeeded =
        !isWalletBased &&
        (activeGroup?.groupType === AbfGroupType.Organization || activeGroup?.groupType === AbfGroupType.Custodian);

    return { escrowNeeded };
}

/**
 * Maintains the "active" escrow account for the user
 * For now, this escrow can be derived directly from schemas (loans, orders, etc)
 * This hook allows us to have the option to have multiple escrows exposed in UX
 */
export function useActiveEscrow() {
    const { state, setEscrowNonce } = useAuthStorage();

    const { groupIdentifier, escrowNonce } = state;
    if (!groupIdentifier) return { escrowNonce, activeEscrow: undefined, setEscrowNonce };
    return {
        escrowNonce,
        activeEscrow: getEscrowAccountForGroup(groupIdentifier, DEFAULT_PUBLIC_KEY),
        setEscrowNonce
    };
}
